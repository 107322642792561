import {createStore} from 'effector';


export const $inspectorsList = createStore({data: [], count: 0})

export const $selectedInspectors = createStore(null)

export const $editInspectorsStatus = createStore(false)

export const $createdInspectorsId = createStore(null)
export const $afterCreateAction = createStore(null)
