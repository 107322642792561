import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $beneficiaryBAs, $newBankAccounts, $selectedProvider, deleteBenefBAEv, selectBenefBankAccountEv, setBenefBAModalEv
} from '../../../models/dictionaryProvidersModel/index.js';
import {$info} from '../../../models/infoModel/index.js';
import Card from 'antd/es/card';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import * as styles from './ProviderDefaultBankAccounts.module.css'
import CreateEditBenefBAModal from './CreateEditBenefBAModal.jsx';


export default function ProviderBeneficiaryBankAccounts(props) {
    const {isView} = props
    const {t} = useTranslation()
    const setModal = useUnit(setBenefBAModalEv)

    const lastAllowedDate = useStoreMap($info, i => i.last_exported_day ? dayjs(i.last_exported_day) : dayjs().subtract(1))

    const selectedProvider = useUnit($selectedProvider)
    const newBAs = useUnit($newBankAccounts)
    const benefBAs = useUnit($beneficiaryBAs)
    const deleteBa = useUnit(deleteBenefBAEv)
    const selectBa = useUnit(selectBenefBankAccountEv)


    const onAdd = () => {
        setModal({open: true})
    }

    const editBa = (obj) => {
        setModal({id: obj.id, open: true})
        selectBa(obj.id)
    }

    const columns = [
        {
            title: t('Банк'),
            dataIndex: 'bank_id',
            key: 'bank_id',
            width: '30%',
            render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>
        },
        {
            title: t('Тип Р/С'),
            dataIndex: 'bank_account_type_id',
            key: 'bank_account_type_id',
            width: '30%',
            render: (bank_account_type_id) => <MappedDictItem id={bank_account_type_id} type={'bank-account-type'}/>
        },
        {
            title: t('Номер счета'),
            dataIndex: 'account_number',
            width: '30%',
            key: 'account_number'
        },
        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined/>} type={'primary'} onClick={onAdd}/>
            </Tooltip>,
            hidden: isView,
            width: '5%',
            key: 'actions',
            dataIndex: 'id',
            align: 'center',
            render: (id, obj) => {
                const disabled = !!obj?.in_use
                return <ButtonGroup>
                    <Tooltip title={disabled ? t('Р/С используется') : t('Редактировать')}>
                        <Button onClick={() => editBa(obj)} icon={<EditOutlined/>}
                                disabled={disabled}
                        />
                    </Tooltip>
                    <Popconfirm
                        title={t('Вы уверены, что хотите удалить эту запись')}
                        okText={t('Да')}
                        onConfirm={() => deleteBa(id)}
                        placement={'left'}
                        disabled={disabled}
                    >
                        <Tooltip title={disabled ? t('Р/С используется') : t('Удалить')}>
                            <Button danger icon={<DeleteOutlined/>} disabled={disabled}/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]

    return <>
        <CreateEditBenefBAModal />
        <Card
            title={t('Расчетный счет по умолчанию')} size={'small'}
            className={styles.height100ps}
        >
            <Table
                dataSource={selectedProvider?.id ? benefBAs : newBAs} columns={columns} bordered pagination={false}
                size={'small'}
                rowKey={(r) => `${r.account_number}:${r.bank_account_type_id}:${r.bank_id}:${r.id}:${r.provider_id}`}
                className={styles.table}
            />
        </Card>
    </>
}
