import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import DatePicker from 'antd/es/date-picker';
import Switch from 'antd/es/switch';
import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import * as styles from './ActivitySubForm.module.css'
import Checkbox from 'antd/es/checkbox';
import Tooltip from 'antd/es/tooltip';

export default function ActivitySubForm(props) {
    const {
        form, splitRows, horizontal, prefix = [],
        type = '3h', disabled = false, disableChecks,
        minDate, maxDate, datesRequired = true, dateFromDisabled = false
    } = props
    const {t} = useTranslation()
    const itemLayout = horizontal ? 'horizontal' : 'vertical'
    const isNotAccrued = Form.useWatch([...prefix, 'is_not_accrued'], form)
    const isNotBalanceTransfer = Form.useWatch([...prefix, 'is_not_balance_transfer'], form)
    const isNotPrinted = Form.useWatch([...prefix, 'is_not_printed'], form)

    const changePeriod = disableChecks ? Form.useWatch([...prefix, 'change_period'], form) : true
    const changeNotAccrued = disableChecks ? Form.useWatch([...prefix, 'change_not_accrued'], form) : true
    const changeNotBalanceTransfer = disableChecks ? Form.useWatch([...prefix, 'change_not_balance_transfer'], form) : true
    const changeNotPrinted = disableChecks ? Form.useWatch([...prefix, 'change_not_printed'], form) : true



    const switchIsNotAccrued = (is_not_accrued) => {
        if (is_not_accrued === false) {
            form.setFieldValue([...prefix, 'is_not_balance_transfer'], false)
        }
    }

    const switchIsNotBalanceTransfer = (is_not_balance_transfer) => {
        if (is_not_balance_transfer === true) {
            form.setFieldValue([...prefix, 'is_not_accrued'], true)
        }
    }

    const cPeriod = <Row>
        {disableChecks && <Col span={24}>
            <Form.Item name={[...prefix, 'change_period']} label={t('Изменить период')}
                       valuePropName={'checked'} layout={'horizontal'} colon={false}>
                <Checkbox/>
            </Form.Item>
        </Col>}
        <Card title={t('Период оказания услуг')} size={'small'}>
            <Row gutter={[8, 0]}>
                <Col span={11}>
                    <Form.Item
                        name={[...prefix, 'date_from']} label={null} layout={itemLayout}
                        rules={datesRequired ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={false}
                            disabled={disabled || !changePeriod || dateFromDisabled}
                            style={{width: '100%'}}
                            placeholder={t('с')}
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>&ndash;</Col>
                <Col span={11}>
                    <Form.Item
                        name={[...prefix, 'date_to']} label={null} layout={itemLayout}
                        // rules={datesRequired ? [{required: false, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={true}
                            disabled={disabled || !changePeriod || dateFromDisabled}
                            style={{width: '100%'}}
                            placeholder={t('до')}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    </Row>

    const nNonAccrued = [...prefix, 'is_not_accrued']
    const vNonAccrued = true//Form.useWatch(nNonAccrued, form)
    const cNonAccrued = <Row>
        {disableChecks && <Col span={24}>
            <Form.Item name={[...prefix, 'change_not_accrued']} label={t('Изменить начисление')}
                       valuePropName={'checked'} layout={'horizontal'} colon={false}>
                <Checkbox/>
            </Form.Item>
        </Col>}
        <Card
            size={'small'} title={
            <Form.Item
                name={nNonAccrued} label={t('Не начислять')}
                valuePropName={'checked'}
                initialValue={false}
                colon={false}
                layout={'horizontal'}
                className={styles.horizontalSwitchHeader}
            >
                <Switch onChange={switchIsNotAccrued} disabled={disabled || !changeNotAccrued}/>
            </Form.Item>
        }
        >
            {vNonAccrued ? <Row gutter={[8, 0]}>
                <Col span={11}>
                    <Tooltip title={isNotAccrued ? t('С 1-го числа выбранного месяца') : null}>
                    <Form.Item
                        name={[...prefix, 'not_accrued_from']} label={null}
                        layout={itemLayout}
                        rules={isNotAccrued ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker.MonthPicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={false}
                            style={{width: '100%'}} disabled={!isNotAccrued || disabled || !changeNotAccrued}
                            placeholder={t('с')}
                        />
                    </Form.Item>
                    </Tooltip>
                </Col>
                <Col span={2}>&ndash;</Col>
                <Col span={11}>
                    <Tooltip title={isNotAccrued ? t('По последнее число выбранного месяца') : null}>
                    <Form.Item
                        name={[...prefix, 'not_accrued_to']} label={null}
                        layout={itemLayout}
                        rules={isNotAccrued ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker.MonthPicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={false}
                            style={{width: '100%'}} disabled={!isNotAccrued || disabled || !changeNotAccrued}
                            placeholder={t('по')}
                        />
                    </Form.Item>
                    </Tooltip>
                </Col>
            </Row> : null}
        </Card>
    </Row>

    const nNonTransfer = [...prefix, 'is_not_balance_transfer']
    const vNonTransfer = true//Form.useWatch(nNonTransfer, form)
    const cNonTransfer = <Row>
        {disableChecks && <Col span={24}>
            <Form.Item name={[...prefix, 'change_not_balance_transfer']}
                       label={t('Изменить перевод')} valuePropName={'checked'} layout={'horizontal'}
                       colon={false}>
                <Checkbox/>
            </Form.Item>
        </Col>}
        <Card
            size={'small'} title={<Form.Item
            name={nNonTransfer}
            label={t('Не переводить')}
            valuePropName={'checked'}
            initialValue={false}
            layout={'horizontal'}
            colon={false}
            className={styles.horizontalSwitchHeader}
        >
            <Switch onChange={switchIsNotBalanceTransfer} disabled={disabled || !changeNotBalanceTransfer}/>
        </Form.Item>}
        >
            {vNonTransfer ? <Row gutter={[8, 0]}>
                <Col span={11}>
                    <Tooltip title={isNotBalanceTransfer ? t('С 1-го числа выбранного месяца') : null}>
                    <Form.Item
                        name={[...prefix, 'not_balance_transfer_from']} label={null}
                        layout={itemLayout}
                        rules={isNotBalanceTransfer ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker.MonthPicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={false}
                            style={{width: '100%'}} disabled={!isNotBalanceTransfer || disabled || !changeNotBalanceTransfer}
                            placeholder={t('с')}
                        />
                    </Form.Item>
                    </Tooltip>
                </Col>
                <Col span={2}>&ndash;</Col>
                <Col span={11}>
                    <Tooltip title={isNotBalanceTransfer ? t('По последнее число выбранного месяца') : null}>
                    <Form.Item
                        name={[...prefix, 'not_balance_transfer_to']} label={null}
                        layout={itemLayout}
                        rules={isNotBalanceTransfer ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker.MonthPicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={false}
                            style={{width: '100%'}} disabled={!isNotBalanceTransfer || disabled || !changeNotBalanceTransfer}
                            placeholder={t('по')}
                        />
                    </Form.Item>
                    </Tooltip>
                </Col>
            </Row> : null}
        </Card>
    </Row>

    const nNonPrint = [...prefix, 'is_not_printed']
    const vNonPrint = true//Form.useWatch(nNonPrint, form)
    const cNonPrint = <Row>
        {disableChecks && <Col span={24}>
            <Form.Item name={[...prefix, 'change_not_printed']} label={t('Изменить печать')}
                    valuePropName={'checked'} layout={'horizontal'} colon={false}>
            <Checkbox/>
        </Form.Item>
        </Col>}
        <Card
            size={'small'} title={<Form.Item
            name={nNonPrint}
            label={t('Не печатать')}
            valuePropName={'checked'}
            initialValue={false}
            layout={'horizontal'}
            colon={false}
            className={styles.horizontalSwitchHeader}
        >
            <Switch disabled={disabled || !changeNotPrinted}/>
        </Form.Item>}
        >
            {vNonPrint ? <Row gutter={[8, 0]}>
                <Col span={11}>
                    <Tooltip title={isNotPrinted ? t('С 1-го числа выбранного месяца') : null}>
                    <Form.Item
                        name={[...prefix, 'not_printed_from']} label={null}
                        layout={itemLayout}
                        rules={isNotPrinted ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker.MonthPicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={false}
                            style={{width: '100%'}} disabled={!isNotPrinted || disabled || !changeNotPrinted}
                            placeholder={t('с')}
                        />
                    </Form.Item>
                    </Tooltip>
                </Col>
                <Col span={2}>&ndash;</Col>
                <Col span={11}>
                    <Tooltip title={isNotPrinted ? t('По последнее число выбранного месяца') : null}>
                    <Form.Item
                        name={[...prefix, 'not_printed_to']} label={null}
                        layout={itemLayout}
                        rules={isNotPrinted ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <DatePicker.MonthPicker
                            minDate={minDate} maxDate={maxDate}
                            allowClear={false}
                            style={{width: '100%'}} disabled={!isNotPrinted || disabled || !changeNotPrinted}
                            placeholder={t('по')}
                        />
                    </Form.Item>
                    </Tooltip>
                </Col>
            </Row> : null}
        </Card>
    </Row>

    if (type === '1x3') return <Row gutter={[8, 8]}>
        <Col span={12}>
            <Col span={24}>
                {cPeriod}
            </Col>
        </Col>
        <Col span={12}>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    {cNonAccrued}
                </Col>
                <Col span={24}>
                    {cNonTransfer}
                </Col>
                <Col span={24}>
                    {cNonPrint}
                </Col>
            </Row>
        </Col>
    </Row>

    if (type === '2x2') return <Row gutter={[8, 8]} className={styles.type2x2}>
        <Col span={12}>
            {cPeriod}
        </Col>
        <Col span={12}>
            {cNonAccrued}
        </Col>
        <Col span={12}>
            {cNonTransfer}
        </Col>
        <Col span={12}>
            {cNonPrint}
        </Col>
    </Row>

        if (type === '1x4') return <Row gutter={[8, 8]} className={styles.type2x2}>
        <Col span={24}>
            {cPeriod}
        </Col>
        <Col span={24}>
            {cNonAccrued}
        </Col>
        <Col span={24}>
            {cNonTransfer}
        </Col>
        <Col span={24}>
            {cNonPrint}
        </Col>
    </Row>

    return <Row
        gutter={[8, 8]}
        className={styles.simpleRow}
        wrap={true}
    >
        <Col span={splitRows ? 12 : 6}>
            {cPeriod}
        </Col>
        <Col span={splitRows ? 12 : 6}>
            {cNonAccrued}
        </Col>
        <Col span={splitRows ? 12 : 6}>
            {cNonTransfer}
        </Col>
        <Col span={splitRows ? 12 : 6}>
            {cNonPrint}
        </Col>
    </Row>
}
