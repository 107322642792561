import {createGate} from "effector-react";
import {createEvent} from "effector";

export const CalcTypeAlgorithmsGate = createGate()
export const CalcTypeAlgorithmViewGate = createGate()
export const CalcTypeAlgorithmEditGate = createGate()
export const CalcTypeAlgorithmCreateGate = createGate()

export const submitCreateAlgorithmEv = createEvent()
export const submitEditAlgorithmEv = createEvent()
export const deleteAlgorithmEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
