import {createEffect} from 'effector/compat';
import api from '../../api/api.js';

export const stopTaskFx = createEffect()
.use(async (id) => {
    return (await api().post(`/tasks/revoke/${id}`)).data
})

export const getTasksListFx = createEffect()
    .use(async ({name, filters}) => {
        if (name === 'all') {
            return (await api().get(`/tasks/?${filters}`)).data
        } else {
            return (await api().get(`/tasks/?name=${name}&${filters}`)).data
        }
    })

export const getTaskLogsFx = createEffect()
    .use(async ({id}) => {
        return (await api().get(`/tasks/logs/?id=${id}`)).data
    })
