import {
    $afterCreateAction,
    $createdKnpId,
    $editKnpStatus,
    $selectedKnp,
    $knpList
} from './stores.js';
import {getKnpListFx, getKnpByIdFx} from './effects.js';
import {
    deleteKnpEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    KnpEditGate,
    KnpListGate,
    KnpViewGate,
    submitCreateKnpEv,
    submitEditKnpEv, KnpCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateKnpEv, response: triggerSuccessOpsEv}, reset: KnpCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditKnpEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteKnpEv, response: triggerSuccessOpsEv})

$knpList.on(getKnpListFx.doneData, (_, data) => data)
    .reset(KnpListGate.close)

$selectedKnp.on(getKnpByIdFx.doneData, (_, data) => data)
    .reset([KnpEditGate.close, KnpViewGate.close])
// FIXME
$createdKnpId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "knp")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editKnpStatus.on(editCompleteEv, () => true)
    .reset(KnpEditGate.close)

sample({
    clock: [KnpEditGate.state, KnpViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getKnpByIdFx
})

sample({
    clock: KnpListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getKnpListFx
})

sample({
    source: KnpListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getKnpListFx
})

sample({
    clock: submitCreateKnpEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'knp', value: {...payload, id: parseInt(payload.id)}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedKnp,
    clock: submitEditKnpEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        const value = {
            ...changedFields,
            id: parseInt(changedFields.id) ? parseInt(changedFields.id) : null,
            object_id:  current.id,
        }

        if (value.id === null) {
            delete value.id
        }
        actions.push({operation: 'update', type: 'knp', value: value})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteKnpEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'knp', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
