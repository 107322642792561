import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';

import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import { setCreateTownEmbeddedEv} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import {
    $editRoleStatus,
    $selectedRole,
    RoleEditGate, getRoleByIdFx,
    submitEditRoleEv
} from "../../../models/dictionaryRoleModel/index.js";
import {getRoleListFx} from "../../../models/dictionaryRoleModel/index.js";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryRoleModel/index.js";

export default function EditRole() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedRole)

    const [roleForm] = Form.useForm()

    const submit = useUnit(submitEditRoleEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const loading = useUnit(getRoleByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editRoleStatus)
    const townsOpts = useStoreMap($dictsSelects, sel => sel.towns)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const newTown = useUnit($createdTownIdModal)
    const genitive_form = t(`breadcrumbs.genitive.role`);




    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/role?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            roleForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['town_kz','name_kz', 'name_ru'])

    return <>
        <RoleEditGate id={id}/>
        <Form form={roleForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Свойства Роли')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>

                    </Card>
                </Col>


            </Row>

        </Form>

        <Row>
            <Space>
                <Button danger onClick={() => {
                    roleForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            roleForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            roleForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
