import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';

import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import useFormChanged from "../../../hooks/useFormChanged.js";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {setCreateTownEmbeddedEv} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import {
    $createdPermissionId,
    $selectedPermission, PermissionCreateGate,
    submitCreatePermissionEv, $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
} from "../../../models/dictionaryPermissionModel/index.js";

export default function CreatePermission() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [permissionForm] = Form.useForm()

    const submit = useUnit(submitCreatePermissionEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const selected = useUnit($selectedPermission)


    const createdId = useUnit($createdPermissionId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const formData = permissionForm.getFieldsValue()

    const [check, changedFields] = useFormChanged(selected, ['town_kz','name_kz', 'name_ru'])
    const genitive_form = t(`breadcrumbs.genitive.permission`);

    console.log('formData', formData)


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/permission/edit/${createdId}`)
            } else if (action === 'new') {
                permissionForm.resetFields()
            } else if (action === 'list') {
                permissionForm.resetFields()
                navigate(`/dictionaries/permission?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);



    return <>
        <PermissionCreateGate/>
        <Form form={permissionForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>

            <Row justify={'space-between'} gutter={[24, 24]}>


                <Col span={12}>
                    <Card title={<StarredTitle title={t('Свойства Разрешения')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'permission'}
                                   label={t(`Право в приложении`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_en']}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>

            </Row>

        </Form>

        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    permissionForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            permissionForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующее')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            permissionForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            permissionForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
