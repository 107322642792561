import React from 'react';
import {Button} from 'antd';
import TranslateForModels from "../../components/TranslateForModels/TranslateForModels.jsx";

export const ButtonDublicate = (type, id) => {
    const onClick = () => {
        window.location.href = `${window.location.origin}/dictionaries/${type}/edit/${id}`
    }
    return <div>
        Объект {TranslateForModels(type)} с  Кодом {id} уже существует.
        <br/>
        Для редактирования этого объекта нажмите
        <br/>
        <Button type={"primary"} onClick={onClick}>
            Редактировать
        </Button>
    </div>;
};
