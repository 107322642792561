import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useEffect} from "react";
import {
	CheckCircleOutlined, CheckOutlined,
	CloseCircleOutlined, CloseOutlined, EyeOutlined,
	LoadingOutlined,
	UploadOutlined,
	StopOutlined, DownloadOutlined, FileExclamationOutlined
} from '@ant-design/icons';
import {
	$logsList, acceptFileEv,
	FilesGate, getFilesListFx, rejectFileEv, sendMessageUploadEv,
	setUploadModalEv, stopUploadFileEv, downloadFileEv, downloadErrorFileEv
} from '../../models/logsPaymentsModel/index.js';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Divider from 'antd/es/divider';
import UploadAccountsModal from './components/UploadAccountsModal.jsx';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {$WsSt} from "../../models/webSocketModel/index.js";
import {useWebSocket} from "../../api/WebSocketProvider.jsx";
import ButtonGroup from 'antd/es/button/button-group';
import {Popconfirm, Progress} from 'antd';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";

export default function LogsPaymentsList() {
	const {t} = useTranslation()
	const {data, count} = useUnit($logsList)
	const loading = useUnit(getFilesListFx.pending)
	const setModal = useUnit(setUploadModalEv)
	const { sendMessage } = useWebSocket();

	const [search] = useSearchParams()

	const accept = useUnit(acceptFileEv)
	const reject = useUnit(rejectFileEv)
	const stopUpload = useUnit(stopUploadFileEv)
	const download = useUnit(downloadFileEv)
	const downloadErrorFile = useUnit(downloadErrorFileEv)

	const wsStatus = useUnit($WsSt)

	useEffect(() => {
		const payload = {
			sendMessage: sendMessage,
			payload: {
				object_type: "accrual_calculation",
				action: 'get_list',
				subscribe: true
			}
		}
		sendMessageUploadEv(payload)

	}, [wsStatus]);

	const columns = [
		{
			title: t('Статус создания'),
			dataIndex: 'progress',
			key: 'progress',
			sorter: true,
			align: 'center',
			render: (progress) => progress === 100 ?
				<Tooltip title={'Успешно'}>
					<CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
				</Tooltip> :
				<Tooltip title={'В обработке'}>
					<Progress type="circle" size={30} percent={parseInt(progress)}/>
				</Tooltip>
		},


		{
			title: t('Тип'),
			dataIndex: 'object_type',
			key: 'object_type',
			sorter: true,
			render: (type) => type === 'account' ?
				t('Лицевые счета') : type === 'service' ?
				t('Услуги') : type === 'provider' ?
				t('Поставщики') : type === 'inspector' ?
				t('Контролеры') : type === 'sector' ?
				t('Участки') : type === 'all' ?
				t('Общий') : t('')

		},
		{
			title: t('Дата создания'),
			dataIndex: 'created_at',
			key: 'created_at',
			sorter: true,
			render: (date) => date && <CustomDates data={date} />
		},
		{
			title: t('Кем создан'),
			dataIndex: 'created_by',
			key: 'created_by',
			sorter: true,
			render: (id) => <CreatedBy id={parseInt(id)}/>
			// render: (id) => <p>{id}</p>
		},
	]

	const filterItems = [
		{name: 'account_in', label: t('ЛС'), type: 'input'},
		{name: 'provider_id', label: t('Поставщик'), type: 'select', dict: 'providers'},
		{name: 'service_id', label: t('Услуга'), type: 'select', dict: 'services'},

		{name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
		{name: 'created_at', label: t('Дата создания'), type: 'range'},
		{name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
		{name: 'updated_at', label: t('Дата обновления'), type: 'range'},
	]

	console.log('account data', data)

	return <>
		<UploadAccountsModal />
		<FilesGate search={search}/>
		<FilterBlock items={filterItems}/>
		<Divider />
		<DictionaryProTable
			data={data}
			count={count}
			initColumns={columns}
			loading={loading}
			rowKey={'id'}
			showActions={false}

		/>
	</>
}
