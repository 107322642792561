import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const ServiceClassesListGate = createGate()
export const ServiceClassViewGate = createGate()
export const ServiceClassEditGate = createGate()
export const ServiceClassCreateGate = createGate()

export const submitCreateServiceClassEv = createEvent()
export const submitEditServiceClassEv = createEvent()
export const deleteServiceClassEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
