import {createStore} from 'effector';
import {getPreferenceCategoryListFx} from "./effects.js";

export const $preferenceCategoryList = createStore({data: [], count: 0})

export const $selectedPreferenceCategory = createStore(null)

export const $editPreferenceCategoryStatus = createStore(false)

export const $createdPreferenceCategoryId = createStore(null)
export const $afterCreateAction = createStore(null)
