import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Card from 'antd/es/card';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import Switch from 'antd/es/switch';
import CreateSectorEmbeddedForm
    from '../CreateSectorEmbeddedForm/CreateSectorEmbeddedForm.jsx';
import StarredTitle from '../../StarredTitle/StarredTitle.jsx';
import CreateRealEstateObjTypeEmbeddedForm
    from '../CreateRealEstateObjTypeEmbeddedForm/CreateRealEstateObjTypeEmbeddedForm.jsx';
import {optionRender} from '../../../utils/selectUtils.jsx';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $createReoEmbeddedOpen,
    setCreateRealEstateObjTypeEmbeddedEv, setCreateReoEmbeddedEv,
    setCreateSectorEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {$infoMaps} from "../../../models/infoModel/index.js";
import {
    $createdREO,
    resetCreatedREOEv,
    submitCreateREOEv
} from '../../../models/accountsREOSearchModel/index.js';
import {useEffect} from 'react';

// Эта форма используется ТОЛЬКО в компоненте RealEstateObjSearchForm
// НЕ может пере-использоваться в других, т.к. является самостоятельной формой, а не врезкой
export default function CreateREOEmbeddedFrom({extForm}) {
    const [reoForm] = Form.useForm()
    const {t} = useTranslation()
    const setSectorEmbedded = useUnit(setCreateSectorEmbeddedEv)
    const setRealEstateObjTypeEmbedded = useUnit(setCreateRealEstateObjTypeEmbeddedEv)

    const create = useUnit(submitCreateREOEv)
    const created = useUnit($createdREO)

    const form = extForm ?? reoForm

    const open = useUnit($createReoEmbeddedOpen)
    const setOpen = useUnit(setCreateReoEmbeddedEv)
    const resetCreatedReo = useUnit(resetCreatedREOEv)

    useEffect(() => {
        if (created) {
            // form.resetFields()
            setOpen(false)
        }
    }, [created])

    const onCancel = () => {
        form.resetFields()
        setOpen(false)
        resetCreatedReo()
    }

    const onFinish = (values) => {
        values = {...values, name_kz: values.name_ru}
        const data = {
            ...values,
            name_kz: values.name_ru,
            sector_id: values.sector_id === ' ' || values.sector_id === '-' ? null : values.sector_id,
        }
        return create(data)
    }

    const {sectorOpts, realEstateObjTypeOpts} = useStoreMap($dictsSelects, sel => ({
        sectorOpts: sel.sector,
        realEstateObjTypeOpts: sel.real_estate_object_type
    }))
    const inspectorsMap = useStoreMap($infoMaps, maps => maps.inspectorsMap)
    const sectorWithInspectorOpts = sectorOpts.map(item => {
        if (item.value === ' ' || item.value === '-') {
            return {
                label: item.label,
                value: item.value
            }
        } else {
            return {
                label: `${item.label} (${item.inspector_id ? `${item.inspector_id} ${inspectorsMap[item.inspector_id]?.name}` : 'Без контролера'})`,
                value: item.value
            }
        }
    })



    return open && <Form form={form} layout={'vertical'} size={'small'} onFinish={onFinish}>
        <Card title={t('Новый объект недвижимости')} size={'small'}
              style={{minHeight: '100%'}}>
            <Row justify={'space-between'} gutter={[8, 0]}>
                <Col span={4}>
                    <Card title={<StarredTitle title={t('Тип объекта недвижимости')} required/>}
                          style={{height: '100%'}}
                          size={'small'}>
                        <Form.Item name={'real_estate_object_type_id'}
                                   rules={[{
                                       required: true,
                                       message: t('Обязательное поле')
                                   }]}
                        >
                            <Select
                                options={[...realEstateObjTypeOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setRealEstateObjTypeEmbedded(true)
                                    } else {
                                        form.resetFields(['real_estate_object_type'])
                                        setRealEstateObjTypeEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={optionRender}
                            />

                        </Form.Item>
                        <CreateRealEstateObjTypeEmbeddedForm
                            namespace={'real_estate_object_type'} inCol={true}/>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title={<StarredTitle title={t('Участок')} required={false}/>}
                          style={{height: '100%'}}
                          size={'small'}>
                        <Form.Item name={'sector_id'}>
                            <Select
                                options={[...sectorWithInspectorOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setSectorEmbedded(true)
                                    } else {
                                        form.resetFields(['sector'])
                                        setSectorEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={optionRender}
                            />
                        </Form.Item>
                        <CreateSectorEmbeddedForm namespace={'sector'} inCol={true}/>
                    </Card>
                </Col>
                <Col span={9}>
                    <Card size={'small'} style={{height: '100%'}}>
                        <Row justify={'space-between'} gutter={[8, 0]}>
                            <Col span={14}>
                                <Form.Item
                                    name={'name_ru'}
                                    label={'ЖК'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    name={'cadastral_number'}
                                    label={'РКА'}
                                    rules={[
                                        {required: false, message: t('Обязательное поле')},
                                        {pattern: /^[0-9]{16}$/, message: t('16 цифр')},
                                    ]}
                                >
                                    <Input showCount maxLength={16}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'floor_count'}
                                           label={'Этажей'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'entrance_count'}
                                           label={'Подъездов'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'apartment_count'}
                                           label={'Квартир'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size={'small'} style={{height: '100%'}}>
                        <Row justify={'space-between'} gutter={[8, 0]}>
                            <Col span={6}>
                                <Form.Item name={'is_electricity_network_present'}
                                           label={'Электричество'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                           valuePropName={'checked'}
                                >
                                    <Switch
                                        defaultChecked={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'is_heat_network_present'}
                                           label={'Тепловая сеть'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                           valuePropName={'checked'}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name={'is_elevator_present'}
                                           label={'Лифт'}
                                           valuePropName={'checked'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'is_mailboxes_present'}
                                           label={'Почтовые ящики'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                           valuePropName={'checked'}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={'gis_url'}
                                           label={'2ГИС'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{marginTop: 12}}>
                <ButtonGroup>
                    <Button type={'primary'}
                            onClick={() => form.submit()}>{t('Создать ОН')}</Button>
                    <Button danger onClick={onCancel}>{t('Отмена')}</Button>
                </ButtonGroup>
            </Row>
        </Card>
    </Form>
}
