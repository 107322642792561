import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
	$chosenMainAddress,
	$mainAddressList,
	getMainAddressesListEv,
	MainAddressListGate, resetMainAddressEv, setMainAddressEv
} from "../../../models/dictionaryAddressesModel";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel";
import {prepareValues} from "../../../components/FiltersBlock/FiltersBlock.jsx";
import Form from "antd/es/form";
import {Input, Row, Select} from "antd";
import queryString from "query-string";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Table from "antd/es/table";
import {paginationConfig} from "../../../utils/paginationConfig.js";
import {filterOption} from "../../../utils/selectUtils.jsx";

export default function SelectMainAddress ({chosenTown, chosenDistr}){
	const {t} = useTranslation()
	const {count, data} = useUnit($mainAddressList)
	const maps = useUnit($infoMaps)
	const [searchForm] = Form.useForm()
	const selectOpts = useUnit($dictsSelects)
	const getMainAddressesList = useUnit(getMainAddressesListEv)
	const setMainAddress = useUnit(setMainAddressEv)
	const resetMainAddress = useUnit(resetMainAddressEv)
	const chosenMainAddress = useUnit($chosenMainAddress)

	const onFinish = (values) => {
		getMainAddressesList(queryString.stringify(prepareValues({
			...values,
			town_id: chosenTown,
			town_distr_id: chosenDistr
		})))
	}

	const onReset = () => {
		searchForm.resetFields()
	}

	const selectObj = (value) => {
		setMainAddress(value)
	}

	const resetSelectedAddress = () => {
		resetMainAddress()
	}

	const onTableChange = (pagination) => {
		getMainAddressesList(queryString.stringify(prepareValues({
			...searchForm.getFieldsValue([
				'street_id',
				'house',
				'corpus',
			]),
			town_id: chosenTown,
			town_distr_id: chosenDistr,
			page: pagination.current,
			limit: pagination.pageSize
		})))
	}

	const columns = [
		{
			title: t('Город, район, улица'),
			key: 'address',
			render: (record) => `
              г. ${maps.townsMap[record.town_id]},
              ${record.town_distr_id ? `${maps.districtsMap[record.town_distr_id]},` : ''}
              ${maps.streetsMap[record.street_id]},
              дом ${record.house},
              корпус ${record.corpus}
            `
		},
		{
			key: 'actions',
			align: 'center',
			width: '10%',
			render: (record) => <Button type={'primary'} onClick={() => selectObj(record)}>{t('Выбрать')}</Button>
		}
	]


	return <>
		<MainAddressListGate />
		{
			chosenMainAddress ?
				<div>
					<div>{t('Выбранный')}:</div>
					<div>
						{
							`
						г. ${maps.townsMap[chosenMainAddress.town_id]},
						${chosenMainAddress.town_distr_id ? `${maps.districtsMap[chosenMainAddress.town_distr_id]},` : ''}
						${maps.streetsMap[chosenMainAddress.street_id]},
						дом ${chosenMainAddress.house},
						корпус ${chosenMainAddress.corpus}
						`
						}
						<Button type={"primary"} onClick={resetSelectedAddress} style={{marginLeft: 10}}>{t('Изменить')}</Button>
					</div>
				</div>
				:
				<>
					<Form
						layout={'vertical'}
						form={searchForm}
						onFinish={onFinish}
					>
						<Row style={{gap: 10}}>
							<Form.Item name={'street_id'} label={t('Улица')}>
								<Select
									options={
										selectOpts?.streets?.filter(item => item?.town_id === chosenTown)
									}
									style={{width: '12.8rem'}}
									// onChange={(v) => onChangeFilter(v, i)}
									dropdownStyle={{width: 300}}
									showSearch filterOption={filterOption}
									allowClear
								/>
							</Form.Item>
							<Form.Item name={'house'} label={t('Номер дома')}>
								<Input />
							</Form.Item>
							<Form.Item name={'corpus'} label={t('Номер корпуса')}>
								<Input />
							</Form.Item>
						</Row>
						<Row style={{alignItems: 'center', gap: 16}}>
							<Button type={'primary'} onClick={searchForm.submit}>
								{t('Применить фильтр')}
							</Button>
							<Button onClick={onReset}>
								{t('Очистить фильтр')}
							</Button>
						</Row>
					</Form>
					<Divider />
					{
						data.length > 0 ?
							<Table
								rowKey={'id'}
								dataSource={data}
								columns={columns}
								pagination={{
									...paginationConfig,
									// pageSize: parseInt(limit),
									// current: parseInt(curPage),
									total: count
								}}
								onChange={onTableChange}
								size={'small'}
							/>
							:
							''
					}
				</>
		}
	</>
}
