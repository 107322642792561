import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useEffect, useState} from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import {
    $createdInspectors,
    $createEditChildInspectorsEmbedded,
    $createSectorEmbeddedOpen,
    $InspectorsChildren,
    resetCreateEditChildInspectorsEmbeddedEv,
    selectChildInspectorEv,
    setCreateSectorEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import Col from 'antd/es/col';
import {Select} from "antd";
import {$dictsSelects, $info, $infoMaps} from "../../../models/infoModel/index.js";
import StarredTitle from "../../StarredTitle/StarredTitle.jsx";
import Space from "antd/es/space/index.js";
import {PlusOutlined} from "@ant-design/icons";
import CreateSectorEmbeddedForm from "../CreateSectorEmbeddedForm/CreateSectorEmbeddedForm.jsx";
import {$selectedInspectors} from "../../../models/dictionaryInspectorsModel/index.js";

export default function CreateEditInspectorsEmbeddedForm({onCreate, onEdit}) {
    const embedded = useUnit($createEditChildInspectorsEmbedded)
    const reset = useUnit(resetCreateEditChildInspectorsEmbeddedEv)

    const {t} = useTranslation()
    const [inspectorsForm] = Form.useForm()
    const [selectedTown, setSelectedTown] =useState(null)

    const getOne = useUnit(selectChildInspectorEv)

    const setSectorEmbedded = useUnit(setCreateSectorEmbeddedEv)
    const openCreateSectorEmbedded = useUnit($createSectorEmbeddedOpen)

    const inspectorDict = useStoreMap($infoMaps, maps => maps.inspectorsMap)
    const townsOpts = useStoreMap($dictsSelects, maps => maps.towns)


    const [check, changedFields] = useFormChanged(embedded.item, ['town_id', 'name_kz', 'name_ru'])

    const Opts = useStoreMap($dictsSelects, sel => sel.inspector).filter(item => item.value !== ' ').filter(item => item.value !== null)

    const temp = useUnit($info).inspector





    const newInspectors = useUnit($createdInspectors)
    const {data, count} = useUnit($InspectorsChildren)
    const excludeIds = [...data, ...newInspectors].map(item => item.id)

    const Options = Opts.map(item => {
        return {
            label: `${item.label}`,
            value: item.value
        }
    }).filter(obj => !excludeIds.includes(obj.value)).filter(item => item.value !== '-')



    const selected = useUnit($selectedInspectors)
    const formData = inspectorsForm.getFieldsValue()



    const onClose = () => {
        inspectorsForm.resetFields()
        reset()
    }

    useEffect(() => {
        if (embedded.open && embedded.type === 'edit' && embedded.idx !== null && embedded.item) {
            inspectorsForm.setFieldsValue({
                ...embedded.item,
                // actual_from: embedded?.item?.actual_from ? dayjs(embedded.item.actual_from) : null
            })
        }
    }, [embedded]);

    const onSubmit = (values) => {

        if (values.new_sector) {
            if (values.new_sector === -1) {
                onCreate({
                    ...values,
                    chief_inspector_id: -1,
                    // town_id: selected ? selected.town_id : formData.town_id
                    town_id: values.town_id
                })
            } else {
                getOne(values.new_sector)
            }

        } else if (embedded.type === 'edit') {
            onEdit({
                payload: {
                    ...values,
                    // inspector_id: selected ? selected.id : formData.id,
                    town_id: selected ? selected.town_id : formData.town_id
                }, id: embedded.item.id
            })
        } else if (embedded.type === 'create') {

            onCreate({
                ...values,
                inspector_id: -1,
                town_id: selected ? selected.town_id : formData.new_town_id
            })
        } else if (values.new_sector) {
            getOne(values.new_sector)
        }
        setSectorEmbedded(false)
        onClose()
    }



    return embedded.open && !window.location.href.includes('view') &&
        <Form form={inspectorsForm}
              layout={'vertical'}
              size={'small'}
              onFinish={onSubmit}
              style={{width: '100%', marginBottom: 16}}
              onValuesChange={(_, all) => {
                  if (embedded.type === 'edit') {
                      check(all)
                  }
              }}
        >

            <Card title={t('Свойства контролера')} size={'small'} style={{height: '100%'}}>
                <Row justify={'space-between'} gutter={[16, 16]}>
                    {
                        embedded.open === true && embedded.type === 'create' ? <Col span={8}>
                            <Card title={<StarredTitle title={t('Выбрать контролера')}/>} size={'small'}
                                  style={{height: '100%'}}>
                                <Row justify={'space-between'}>

                                    <Form.Item name={'new_sector'}
                                               label={t('Название (рус)')}
                                               rules={[{required: true, message: t('Обязательное поле')}]}
                                               validateStatus={embedded.type === 'edit' ? changedFields['name_ru'] : undefined}
                                    >
                                        <Select
                                            options={[
                                                ...Options,
                                                {label: 'Создать', value: -1, emoji: true}
                                            ]}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                            }
                                            style={{
                                                width: '320px'
                                            }}
                                            onChange={(v) => {
                                                if (v === -1) {
                                                    setSectorEmbedded(true)
                                                } else {
                                                    // inspectorsForm.resetFields(['new_sector'])
                                                    setSectorEmbedded(false)
                                                }
                                            }}
                                            optionRender={(option) => (
                                                <Space>
                                            <span role="img" aria-label={option.data.label}>
                                              {option?.data?.emoji ?
                                                  <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                            </span>
                                                    {
                                                        option?.data?.emoji ?
                                                            <span style={{color: '#1677ff'}}>
                                                        {option?.data.label}
                                                    </span> :
                                                            <span>
                                                        {option?.data.label}
                                                    </span>
                                                    }
                                                </Space>
                                            )}
                                        />
                                    </Form.Item>
                                </Row>
                            </Card>
                        </Col> : ''
                    }

                    {
                        openCreateSectorEmbedded === true || embedded.open === true && embedded.type === 'edit' ? <Col span={16}>
                            <Card title={<StarredTitle title={t('Создать контролера')}/>} size={'small'}
                                  style={{height: '100%'}}>
                                <Row justify={"space-between"}>

                                    <Form.Item name={'town_id'}
                                               label={t('Населенный пункт')}
                                               rules={[{required: false, message: t('Обязательное поле')}]}
                                               validateStatus={embedded.type === 'edit' ? changedFields['town_id'] : undefined}
                                    >
                                        <Select
                                            options={townsOpts}
                                            showSearch
                                            filterOption={(input, option) =>
                                              (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item name={'name_ru'}
                                               label={t('ФИО контролера')}
                                               rules={[{required: false, message: t('Обязательное поле')}]}
                                               validateStatus={embedded.type === 'edit' ? changedFields['name_ru'] : undefined}
                                    >
                                        <Input
                                        />
                                    </Form.Item>
                                    {/*<Form.Item name={'name_kz'}*/}
                                    {/*           label={t('ФИО контролера (каз)')}*/}
                                    {/*           rules={[{required: false, message: t('Обязательное поле')}]}*/}
                                    {/*           validateStatus={embedded.type === 'edit' ? changedFields['name_kz'] : undefined}*/}
                                    {/*>*/}
                                    {/*    <Input*/}
                                    {/*    />*/}
                                    {/*</Form.Item>*/}
                                </Row>
                            </Card>
                        </Col> : ''
                    }
                </Row>
            </Card>
            <Row style={{gap: 8, marginTop: 16}}>
                <Button onClick={onClose} danger>{t('Закрыть')}</Button>
                <Button type={'primary'} onClick={inspectorsForm.submit}>{t('Добавить')}</Button>
            </Row>
        </Form>
}
