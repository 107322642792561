import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
    $passwordModal,
    $passwordModalErrorData,
    closePasswordModalEv,
    submitChangePasswordEv
} from "../../models/infoModel/index.js";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import {useMemo} from 'react';


export default function ChangePasswordModal() {
    const {t} = useTranslation()
    const [pwdChangeForm] = Form.useForm()
    const open = useUnit($passwordModal)
    const onClose = useUnit(closePasswordModalEv)
    const payloadErrors = useUnit($passwordModalErrorData)
    const submit = useUnit(submitChangePasswordEv)



    const onCloseModal = () => {
        onClose()
        pwdChangeForm.resetFields()
    }

    const oldPassError = useMemo(() => {
        return payloadErrors?.length > 0 && payloadErrors?.[0] === 'Старый пароль не верен'
    }, [payloadErrors])


    return <>
        <Modal
            open={open}
            onCancel={onCloseModal}
            footer={
                <>
                    <Button
                        type={'primary'}
                        onClick={() => pwdChangeForm.submit()}
                    >
                        {t('Сменить пароль')}
                    </Button>
                    <Button onClick={onCloseModal}>
                        Отмена
                    </Button>
                </>
            }
            title={'Смена пароля пользователя'}
        >

            <Form form={pwdChangeForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => submit(v)}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <Card size={'small'} >

                    <Row justify={'space-between'} gutter={[24, 24]}>
                        <Col span={12}>
                            <Form.Item name={'old_password'}
                                       validateStatus={oldPassError ? 'error' : null}
                                       label={t('Старый пароль')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input type={'password'}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify={'space-between'} gutter={[24, 24]}>
                        <Col span={12}>

                            <Form.Item name={'new_password'}
                                       label={t('Новый пароль')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       hasFeedback

                            >
                                <Input type="password" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>

                            <Form.Item name={'confirm_password'}
                                       label={t('Подтверждение пароля')}
                                       dependencies={['new_password']}
                                       validateStatus={payloadErrors?.length > 0 && !oldPassError ? 'error' : null}
                                       hasFeedback
                                       rules={[
                                           {
                                               required: true,
                                               message: 'Пароли отличаются!',
                                           },
                                           ({ getFieldValue }) => ({
                                               validator(_, value) {
                                                   if (!value || getFieldValue('new_password') === value) {
                                                       return Promise.resolve();
                                                   }
                                                   return Promise.reject(new Error('Пароли отличаются!'));
                                               },
                                           }),
                                       ]}
                            >
                                <Input type="password" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
            {
                payloadErrors?.length > 0 && payloadErrors
                    .map((item, index) => <p key={index} style={{color: 'red'}}>{item}</p>)
            }
        </Modal>
    </>;
}
