import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const KBeListGate = createGate()
export const KBeViewGate = createGate()
export const KBeEditGate = createGate()
export const KBeCreateGate = createGate()

export const submitCreateKBeEv = createEvent()
export const submitEditKBeEv = createEvent()
export const deleteKBeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
