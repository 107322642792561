import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $selectedProvider, $srvBAs,
    deleteSrvBankAccountEv, resetBAModalEv,
    selectSrvBankAccountEv, setBAModalEv
} from '../../../models/dictionaryProvidersModel/index.js';
import {$info} from '../../../models/infoModel/index.js';
import Card from 'antd/es/card';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import {DeleteOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import CreateEditBAModal from "./CreateEditBAModal.jsx";
import * as styles from './ProviderDefaultBankAccounts.module.css'
import {useMemo} from 'react';


export default function ProviderServiceBankAccounts({isView, beneficiaryId}) {
    const {t} = useTranslation()

    const selectedProvider = useUnit($selectedProvider)
    const deleteBa = useUnit(deleteSrvBankAccountEv)
    const selectBa = useUnit(selectSrvBankAccountEv)
    const setModal = useUnit(setBAModalEv)
    const resetModal = useUnit(resetBAModalEv)
    const lastAllowedDate = useStoreMap($info, i => i.last_exported_day ? dayjs(i.last_exported_day) : dayjs().subtract(1))


    const onAdd = () => {
        setModal({mode: 'add', open: true, type: 'srv'})
    }
    const closeAdd = () => {
        resetModal()
    }
    const editBa = (obj) => {
        setModal({mode: 'edit', id: obj.id, open: true, type: 'srv'})
        selectBa(obj.id)
    }

    const srvAccs = useUnit($srvBAs)

    const {actualDateObjects, actualIds} = useMemo(() => {
        const actualDateObjects = (srvAccs || []).filter(
            (ba) => dayjs(ba.date_from).startOf('day') <= dayjs() && (dayjs(ba.date_to)?.startOf('day') >= dayjs().startOf('day') || !ba.date_to)
        )
        return {actualDateObjects, actualIds: actualDateObjects.map(x => x.id)}
    }, [srvAccs, lastAllowedDate])

    const columns = [
        {
            title: t('С даты'),
            dataIndex: 'date_from',
            key: 'date_from',
            render: (v) => v ? dayjs(v).format('DD.MM.YYYY') : null,
        },
        {
            title: t('По дату'),
            dataIndex: 'date_to',
            key: 'date_to',
            render: (v) => v ? dayjs(v).format('DD.MM.YYYY') : null,
        },
        {
            title: t('Бенефициар'),
            dataIndex: 'bank_account_provider_id',
            key: 'bank_account_provider_id',
            render: (id) => {
                if (id === selectedProvider?.id) id = null
                return <MappedDictItem id={id} type={'providers'}/>
            }
        },
        {
            title: t('Банк'),
            dataIndex: 'bank_id',
            key: 'bank_id',
            render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>
        },
        // {
        //     title: t('Тип р/с'),
        //     dataIndex: 'bank_account_type_id',
        //     key: 'bank_account_type_id',
        //     render: (bank_account_type_id) => <MappedDictItem id={bank_account_type_id}
        //                                                       type={'bank-account-type'}/>
        // },
        {
            title: t('Номер счета'),
            dataIndex: 'account_number',
            key: 'account_number'
        },
        {
            title: t('%'),
            dataIndex: 'transfer_percent',
            key: 'transfer_percent',
            render: (v) => `${v ?? 0}%`,
        },
        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined/>} type={'primary'} onClick={onAdd}/>
            </Tooltip>,
            hidden: isView,
            width: '5%',
            key: 'actions',
            dataIndex: 'id',
            align: 'center',
            render: (id, obj) => {
                const nonDeletable = dayjs(obj.date_from).startOf('day') <= dayjs(lastAllowedDate)
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button onClick={() => editBa(obj)} icon={<EditOutlined/>}/>
                    </Tooltip>
                    <Popconfirm
                        title={t('Вы уверены, что хотите удалить эту запись')}
                        okText={t('Да')}
                        onConfirm={() => deleteBa(id)}
                        placement={'left'}
                        disabled={nonDeletable}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button danger icon={<DeleteOutlined/>} disabled={nonDeletable}/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]

    // const defaultAccFiltered = (srvAccs || []).filter(
    //     ba => dayjs(ba.date_to) >= dayjs() || !ba.date_to
    // )

    return <>
        <CreateEditBAModal
            beneficiaryId={beneficiaryId}
            afterClose={closeAdd}
            activeAccs={actualDateObjects}
            allAccs={srvAccs}
        />
        <Card
            title={t('Расчетные счета по услуге')} size={'small'}
            className={styles.height100ps}
            styles={{body: {padding: 0}}}
        >
            <Table
                dataSource={srvAccs} columns={columns} bordered pagination={false}
                size={'small'}
                rowKey={(r) => `${r.account_number}:${r.bank_account_type_id}:${r.bank_id}:${r.id}:${r.provider_id}`}
                className={styles.table}
                rowClassName={(r) => actualIds.includes(r.id) ? 'actual' : ''}
            />
        </Card>
    </>
}
