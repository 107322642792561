import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const StreetTypesListGate = createGate()
export const StreetTypeViewGate = createGate()
export const StreetTypeEditGate = createGate()
export const StreetTypeCreateGate = createGate()

export const submitCreateStreetTypeEv = createEvent()
export const submitEditStreetTypeEv = createEvent()
export const deleteStreetTypeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()