import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getDistrictsListFx = createEffect()
    .use(async (filters) => (await api().get(`/town_distr/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getDistrictByIdFx = createEffect()
    .use(async (id) => (await api().get(`/town_distr/${id}`)).data)

// export const getDistrictStreetsFx = createEffect()
//     .use(async (id) => (await api().get(`/street/?town_distr_id=${id}`)).data)