import {createEffect} from 'effector';
import api from '../../api/api.js';


export const getIpAddressesChildrenFx = createEffect()
    .use(async (filters) => (await api().get(`/bank_api_address/?${filters}`)).data)

export const getSectorsChildrenFx = createEffect()
    .use(async (filters) => (await api().get(`/sector/?${filters}`)).data)

export const getOneSectorChildrenFx = createEffect()
    .use(async (sectorId) => {
        return (await api().get(`/sector/${sectorId}`)).data
    })

export const getEPDTemplateFieldChildrenFx = createEffect()
    .use(async (filters) => (await api().get(`/epd_template_fields/?${filters}`)).data)


export const getServicesChildrenFx = createEffect()
    .use(async (filters) => (await api().get(`/services/?${filters}`)).data)

export const getInspectorsChildrenFx = createEffect()
    .use(async (filters) => (await api().get(`/inspectors/?${filters}`)).data)

export const getOneInspectorChildrenFx = createEffect()
    .use(async (sectorId) => {
        return (await api().get(`/inspectors/${sectorId}`)).data
    })

export const getAPSChildrenFx = createEffect()
    .use(async (filters) => (await api().get(`/account_provider_service/?${filters}`)).data)
