import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $createdIpAddresses, $ipAddressEmbeddedFilters,
    $ipAddressesChildren, changeIpAddressesChildrenFiltersEv,
    createChildIpAddressEv,
    deleteChildIpAddressEv,
    deleteNewIpAddressEv,
    editChildIpAddressEv,
    setCreateEditChildIpAddressEmbeddedEv, IpAddressesChildrenGate
} from '../../../models/dictionaryEmbeddedFormsModel';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import {CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group.js';
import Popconfirm from 'antd/es/popconfirm';
import MappedDictItem from "../../MappedDictItem/MappedDictItem.jsx";
import CreateEditIpAddressEmbedded
    from '../CreateEditAddressEmbedded/CreateEditAddressEmbedded.jsx';
import Table from 'antd/es/table';
import Divider from 'antd/es/divider';
import IpAddressEmbeddedFilters from './IpAddressEmbeddedFilters.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import dayjs from 'dayjs';
import {getIpAddressesChildrenFx} from '../../../models/dictionaryEmbeddedFormsModel/effects.js';
import {
    $bankApiAddressList,
    BankApiAddressListGate,
    changeIpAddressFiltersEv
} from "../../../models/dictionaryBankApiAddressModel/index.js";
import {useLocation} from "react-router-dom";
import CreateEditIpAddressEmbeddedForm from "./CreateEditIpAddressEmbedded.jsx";

export default function IpAddressesTable({view, parent, parentId}) {
    const {t} = useTranslation()
    const {data, count} = useUnit($ipAddressesChildren)
    const loading = useUnit(getIpAddressesChildrenFx.pending)
    const newAddresses = useUnit($createdIpAddresses)

    const deleteExisting = useUnit(deleteChildIpAddressEv)
    const deleteNew = useUnit(deleteNewIpAddressEv)

    const onCreate = useUnit(createChildIpAddressEv)
    const onEdit = useUnit(editChildIpAddressEv)

    const setModal = useUnit(setCreateEditChildIpAddressEmbeddedEv)

    const {page, limit, sortField, sortOrder} = useUnit($ipAddressEmbeddedFilters)
    const changeFilters = useUnit(changeIpAddressesChildrenFiltersEv)
    const genitive_form = t(`breadcrumbs.genitive.bank-api-address`);

    const columns = [
        {
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'id',
        },
        // {
        //     title: t('Банк'),
        //     dataIndex: 'bank_id',
        //     width: '15%',
        //     align: 'center',
        //     sorter: true,
        //     key: 'bank_id',
        //     render: (bank_id) => {
        //         if (bank_id === 'Выбранный Банк') {
        //             return bank_id
        //         } else {
        //             return <MappedDictItem id={bank_id} type={'bank'}/>
        //         }
        //     }
        // },
        {
            title: t('IP Адрес'),
            dataIndex: 'ip_address',
            sorter: true,
            // onCell: (is_activee) => ({className: record?.changedFields?.includes('is_actual_from') ? 'table-cell-edited' : ''}),
            render: (ip_address) => ip_address ? ip_address : '-'
        },
        {
            title: t('Актуальный'),
            dataIndex: 'is_active',
            sorter: true,
            // onCell: (is_activee) => ({className: record?.changedFields?.includes('is_actual_from') ? 'table-cell-edited' : ''}),
            render: (is_active) => is_active ? <span><CheckOutlined style={{color: 'green'}}/></span> :
                <span><CloseOutlined style={{color: 'red'}}/></span>
        },
        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined/>} onClick={() => setModal({type: 'create'})} type={'primary'}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            hidden: view,
            key: 'actions',
            render: (id,row) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={
                                    () => {
                                         if (Math.sign(row.id) === 1) {
                                             return deleteExisting(id)
                                        } else {
                                           return  deleteNew(id)
                                        }
                                    }
                                }
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined />} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]

    // const actionsNew = {
    //     title: <Tooltip title={t('Добавить')}>
    //         <Button icon={<PlusOutlined />} onClick={() => setModal({type: 'create'})} type={'primary'}/>
    //     </Tooltip>,
    //     width: '5%',
    //     align: 'center',
    //     dataIndex: 'id',
    //     key: 'actions',
    //     render: (id) => {
    //         return <ButtonGroup>
    //             <Tooltip title={t('Редактировать')}>
    //                 <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
    //             </Tooltip>
    //             <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
    //                         okText={t('Да')}
    //                         onConfirm={() => deleteNew(id)}
    //                         placement={'left'}
    //             >
    //                 <Tooltip title={t('Удалить')}>
    //                     <Button icon={<CloseOutlined />} type={'primary'} danger/>
    //                 </Tooltip>
    //             </Popconfirm>
    //         </ButtonGroup>
    //     }
    // }


    if (view) {
        const idx = columns.findIndex(i => i.key === 'actions')
        if (idx !== -1) {
            columns.splice(idx, 1)
        }
    }
    // const columnsNew = columns.toSpliced(columns.findIndex(i => i.key === 'actions'), 1, actionsNew)

    const rowClassName = (record, index) => {
        const checkNew = Math.sign(record.id) === 1 ? false : true
        if (checkNew === true) {
            return 'custom-new-row'
        } else if (record?.changedFields) {
            return 'custom-edit-old-row'
        } else if (record.new_item) {
            return 'custom-new-select-row'
        } else {
            return 'custom-normal-row'
        }
    };




    return <>
            <IpAddressesChildrenGate parent={parent} parentId={parentId}/>
        <CreateEditIpAddressEmbeddedForm onCreate={onCreate} onEdit={onEdit} />
        <IpAddressEmbeddedFilters/>

        <Table dataSource={[...data, ...newAddresses]}
               columns={columns}
               loading={loading}
               bordered
               size={'small'}
               rowClassName={rowClassName}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(page),
                   total: count
               }}
               onChange={({current, pageSize}, filters, {field, order}) => {
                   const payload = []
                   if (current !== page || pageSize !== limit) {
                       payload.push({key: 'page', value: current})
                       payload.push({key: 'limit', value: pageSize})
                   }

                   if (field !== sortField || order !== sortOrder) {
                       if (!order) {
                           payload.push({key: 'sort_field', value: null})
                           payload.push({key: 'sort_order', value: null})
                       } else {
                           payload.push({key: 'sort_field', value: field})
                           payload.push({
                               key: 'sort_order',
                               value: order === 'ascend' ? 'asc' : 'desc'
                           })
                       }
                   }
                   changeFilters(payload)
               }}
               style={{width: '100%'}}
        />
    </>
}
