import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const RolePermissionListGate = createGate()
export const RolePermissionViewGate = createGate()
export const RolePermissionEditGate = createGate()
export const RolePermissionCreateGate = createGate()

export const submitCreateRolePermissionEv = createEvent()
export const submitEditRolePermissionEv = createEvent()
export const deleteRolePermissionEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
