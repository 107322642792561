import {Result} from "antd";
import Button from "antd/es/button";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {NotFoundGate} from "../../models/notFoundModel/events.js";
import {$objectDictPath} from "../../models/notFoundModel/stores.js";
import {useUnit} from "effector-react";
import {useEffect} from "react";

export default function NotFoundPage() {
	const {t} = useTranslation()
	const objectDictPath = useUnit($objectDictPath)
	const navigate = useNavigate()

	const returnToMain = () => {
		navigate('/')
	}

	const goToDict = () => {
		navigate(objectDictPath)
	}

	// useEffect(() => {
	// 	if(!objectDictPath){
	// 		navigate('/')
	// 	}
	// }, [objectDictPath])

	// if(!objectDictPath){
	// 	return null
	// }

	return <>
		<NotFoundGate />
		<Result
			status="404"
			title="404"
			subTitle={t("Похоже такого объекта не существует")}
			extra={ <>
				<Button type="primary" onClick={returnToMain}>{t('Перейти на главную')}</Button>
				{objectDictPath &&
					<Button type="primary" onClick={goToDict}>
						{t('Перейти к списку справочника данной сущности')}
					</Button>
				}
			</>
			}
		/>
	</>
}
