import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import {$selectedEPDTemplateField, EPDTemplateFieldViewGate, getEPDTemplateFieldByIdFx} from "../../../models/dictionaryEpdTemplateFieldModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {$epdCellTypeDict, $epdFieldNameDict} from "../../../models/infoModel";

export default function ViewEpdTemplateField() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getEPDTemplateFieldByIdFx.pending)
    const data = useUnit($selectedEPDTemplateField)
    const dictCell = useUnit($epdCellTypeDict)
    const dictNameField = useUnit($epdFieldNameDict)

    const genitive_form = t(`breadcrumbs.genitive.epd-template-fields`);

    console.log('ViewEpdTemplateField', data, dictNameField, dictCell)

    const items = [
        {
            key: 'template_id',
            label: t('Шаблон'),
            children: <MappedDictItem id={data?.template_id} type={'templates'}/>,
        },
        {
            key: 'column_number',
            label: t('Номер столбца'),
            children: data?.column_number
        },
        {
            key: 'Название поля',
            label: t('Название поля'),
            children: dictNameField && dictNameField[data?.field_name] ? dictNameField[data?.field_name] : data?.field_name
        },
        {
            key: 'Тип ячейки',
            label: t('Тип ячейки'),
            children: dictCell && dictCell[data?.cell_type] ? dictCell[data?.cell_type] : data?.cell_type
        },
        {
            key: 'Размер шрифта',
            label: t('Размер шрифта'),
            children: data?.font_size
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at} /> : '-'
        }
    ]

    return <>
        <EPDTemplateFieldViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр Начислений')}</span> <MappedDictItem id={id} type={'epd_template_field'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'epd-template-fields'}/>
        </Card>
    </>
}
