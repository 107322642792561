import {createEffect} from "effector";
import api from "../../api/api.js";

export const getServicesColumnsListFx = createEffect()
	.use(async (filters) => (await api().get(`/epd_pdf_settings/${filters?.length > 0 ? `?${filters}` : ''}`)).data)

export const getServicesColumnByIdFx = createEffect()
	.use(async (id) => {
		return (await api().get(`/epd_pdf_settings/${id}`)).data
	})


export const sendEditServicesColumnFx = createEffect()
	.use(async ({id, values}) => {
		return (await api().put(`/epd_pdf_settings/${id}`, values)).data
	})
