import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';

import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import SectorsTable from "../../../components/Embedded/SectorsTable/SectorsTable.jsx";
import {
    $selectedChiefInspector,
    ChiefInspectorViewGate,
    getChiefInspectorByIdFx
} from "../../../models/dictionaryChiefInspectorModel/index.js";
import InspectorsTable from "../../../components/Embedded/InspectorsTable/InspectorsTable.jsx";

export default function ViewChiefInspector() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getChiefInspectorByIdFx.pending)
    const data = useUnit($selectedChiefInspector)
    const genitive_form = t(`breadcrumbs.genitive.chief-inspector`);

    const items = [
        // {
        //     key: 'town_id',
        //     label: t('Населенный пункт'),
        //     children: <MappedDictItem id={data?.town_id} type={'town'}/>,
        // },
        // {
        //     key: 'name_kz',
        //     label: t(`ФИО ${genitive_form} (каз)`),
        //     children: data?.name_kz
        // },
        {
            key: 'name_ru',
            label: t(`ФИО ${genitive_form}`),
            children: data?.name_ru
        },

    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <ChiefInspectorViewGate id={id} />
        <Card
            title={<div><span>{t('Просмотр Старшего Контролера')}</span> <MappedDictItem id={id} type={'chief-inspector'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'chief-inspector'}/>
        </Card>
        <Card title={<div><span>{t('Контролеры')}</span> </div>}>

            <InspectorsTable
                view
                parent={'inspector_id'}
                parentId={+id}
            />

        </Card>
    </>
}
