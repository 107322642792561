import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const PermissionListGate = createGate()
export const PermissionViewGate = createGate()
export const PermissionEditGate = createGate()
export const PermissionCreateGate = createGate()

export const submitCreatePermissionEv = createEvent()
export const submitEditPermissionEv = createEvent()
export const deletePermissionEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
