import {useUnit} from 'effector-react';
import {$currentUser, $userPerms} from '../../models/infoModel/index.js';
import Modal from 'antd/es/modal';
import Space from 'antd/es/space';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import permByUrl from '../../utils/permByUrl.js';

export default function NoPermissionModal() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const currentUser = useUnit($currentUser)
    const perms = useUnit($userPerms)

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (currentUser && !open && !permByUrl(perms, pathname)) {
            setOpen(true)
        }
    }, [currentUser, open, perms, pathname])

    const onClose = () => {
        setOpen(false)
        navigate('/')
    }

    return <Modal open={currentUser && open} width={'60vw'}
                  title={<Space style={{alignItems: 'flex-start'}}>
                      <ExclamationCircleOutlined style={{color: 'red', fontSize: 24}}/>
                      {t('Нет доступа')}
                  </Space>}
                  footer={false}
                  onCancel={onClose}
    >
        <Space direction={'vertical'}>
            <Space>
                <p><strong>{t('У вас нет прав на просмотр или редактирование выбранного объекта.')}</strong></p>
            </Space>
                <p>{t('Для предоставления прав - обратитесь к администратору.')}</p>
            <Button type={'primary'} onClick={() => onClose()}>{t('На главную')}</Button>
        </Space>
    </Modal>
}