import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import {
    $selectedAccount,
    $selectedAccountReEsObjFormatted,
    AccountViewGate,
    getAccountByIdFx,
    setAccLockedModalEv
} from '../../models/dictionaryAccountsModel/index.js';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import CustomDates from '../../components/CustomDates/CustomDates.jsx';
import ViewDictionaryItemCommon
    from '../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import Tabs from 'antd/es/tabs';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import ProviderServices from "./components/ProviderServices.jsx";
import Accruals from "./components/Accruals.jsx";
import Payments from "./components/Payments.jsx";
import ShowAddressCard from '../../components/AddressReoSearchForms/ShowAddressCard.jsx';
import {$createdCorrectionId} from "../../models/dictionaryCorrectionModel/index.js";
import {useEffect} from "react";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import PreferencesTab from './components/PreferencesTab.jsx';
import CashPaymentTab from "./components/CashPaymentTab.jsx";
import AccountLockedModal from '../../components/AccountLockedModal/AccountLockedModal.jsx';
import permitted from "../../utils/permitted.js";
import {$currentUser, $userPerms} from "../../models/infoModel/index.js";
import ProviderAccounts from "./components/ProviderAccounts.jsx";
import {Spin} from 'antd';


function DescrItem({label, value}) {
    return <div style={{display: 'flex', flexDirection: 'column', marginBottom: 16}}>
        <span><strong>{label}</strong></span>
        <span>{value && typeof value === 'boolean' ? (value ? 'Да' : 'Нет') : value ?? '-'}</span>
    </div>
}

export default function ViewAccount() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getAccountByIdFx.pending)

    const perms = useUnit($userPerms)
    const setLockedModal = useUnit(setAccLockedModalEv)

    const account = useUnit($selectedAccount)
    const reEsObj = useUnit($selectedAccountReEsObjFormatted)
    const createdCorrectionId = useUnit($createdCorrectionId)
    const navigate = useNavigate()

    useEffect(() => {
        if (createdCorrectionId) {
            navigate(`/corrections/edit/${createdCorrectionId}`)
        }
    }, [createdCorrectionId]);

    const accountLocked = account?.status === 'closed'

    useEffect(() => {
        if (accountLocked) {
            setLockedModal({open: true, id: account?.id, page: 'view', status_reason: account?.status_reason})
        }
    }, [accountLocked, account]);


    const items = <>
        <ShowAddressCard formated={reEsObj} title={t('Объект недвижимости')}/>
        <Divider/>
        <Row justify={'space-between'} gutter={[32, 16]}>
            <Col span={8}>
                <Card title={t('Основная информация')} size={'small'} style={{minHeight: '100%'}}>
                    {/*<DescrItem label={t('ФИО (каз)')} value={account?.name_kz}/>*/}
                    <DescrItem label={t('ФИО')} value={account?.name_ru}/>
                    <DescrItem label={t('Количество проживающих')}
                               value={account?.people_quantity}/>
                    <DescrItem label={t('ИИН')} value={account?.iin}/>
                    <DescrItem label={t('Статус')} value={account?.status === 'active' ? 'Активен' : 'Закрыт'}/>
                </Card>
            </Col>
            <Col span={8}>
                <Card title={t('Площади')} size={'small'} style={{minHeight: '100%'}}>
                    <DescrItem label={t('Общая')} value={account?.full_square}/>
                    <DescrItem label={t('Жилая')} value={account?.living_square}/>
                    <DescrItem label={t('Отапливаемая')} value={account?.heated_square}/>
                </Card>
            </Col>
            <Col span={8}>
                <Card title={t('Дополнительная информация')} size={'small'}>
                    <Row justify={'space-between'} gutter={[16, 0]}>
                        <Col span={7}>
                            <DescrItem label={t('Квартира')} value={account?.flat}/>
                        </Col>
                        <Col span={5}>
                            <DescrItem label={t('Этаж')} value={account?.floor}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Кол-во комнат')} value={account?.room_count}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Тел.')} value={account?.phone}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Моб. тел.')} value={account?.mobile_phone}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Email')} value={account?.email}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Email рассылка')} value={account?.is_email_delivery === true ?  <CheckOutlined /> : <CloseOutlined />}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Код АЭСбыт')} value={account?.aesbyt_account}/>
                        </Col>
                        <Col span={12}>
                            <DescrItem label={t('Код энергокомп.')} value={account?.energy_account}/>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={account?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),

            children: <CustomDates data={account?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: account?.updated_by ? <CreatedBy id={account.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: account?.updated_at ? <CustomDates data={account?.updated_at}/> : '-'
        }
    ]

    const tabItems = [
        {
            key: 'common',
            label: t('Общая информация'),
            disabled: accountLocked,
            children: <ViewDictionaryItemCommon customItems={items} itemsDates={itemsDates} id={id}
                                                href={'accounts'}/>
        },
        {
            key: 'services',
            label: t('Услуги поставщиков'),
            disabled: accountLocked || !permitted(['account_provider_service::view'], perms),
            children: <ProviderServices isView id={id}/>
        },
        {
            key: 'provider-accounts',
            label: t('Поставщики'),
            disabled: accountLocked || !permitted(['account_provider_service::view'], perms),
            children: <ProviderAccounts isView id={id}/>
        },
        {
            key: 'accruals',
            label: t('Начисления'),
            disabled: accountLocked || !permitted(['accrual::view'], perms),
            children: <Accruals isView/>
        },
        {
            key: 'payments',
            label: t('Оплаты'),
            disabled: accountLocked || !permitted(['payment::view'], perms),
            children: <Payments isView/>
        },
        {
            key: 'cash-payments',
            label: t('Кассовые платежи'),
            disabled: accountLocked || !permitted(['payment::view'], perms),
            children: <CashPaymentTab />
        },
        {
            key: 'preferences',
            label: t('Льготы'),
            disabled: accountLocked || !permitted(['account_provider_service_preference::view'], perms),
            children: <PreferencesTab />
        },
    ]

    return <>
        <AccountLockedModal />
        <AccountViewGate id={id}/>
        {accountLocked ? null : <Spin spinning={loading}>
            <Tabs
            items={tabItems}
            defaultActiveKey={'common'}
            size={'small'}
            type={'card'}
        />
        </Spin>}
    </>
}
