import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
	$servicesColumnsData,
	getServicesColumnsListFx,
	ServiceColumnsGate
} from "../../models/servicesColumnsModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import {useMemo} from "react";
import {Link, useSearchParams} from 'react-router-dom';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import CreatedBy from "../../components/CreatedBy/CreatedBy.jsx";
import ButtonGroup from "antd/es/button/button-group.js";
import Tooltip from "antd/es/tooltip/index.js";
import Button from "antd/es/button/index.js";
import {DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined} from "@ant-design/icons";
import {Popconfirm} from "antd";

export default function ServicesColumns(){
	const {t} = useTranslation()
	const {data, count} = useUnit($servicesColumnsData)
	const loading = useUnit(getServicesColumnsListFx.pending)
	const genitive_form = t(`breadcrumbs.genitive.services-columns`);
	const [search] = useSearchParams()

	const initColumns = useMemo(() => [
		{
			width: '5%',
			title: t(`Код ${genitive_form}`),
			dataIndex: 'id',
			sorter: true,
			key: 'name',
		},
		{
			width: 200,
			title: t(`Название ${genitive_form} (каз)`),
			dataIndex: 'name_kz',
			sorter: true,
			key: 'name',
		},
		{
			width: 200,
			title: t(`Название ${genitive_form} (рус)`),
			dataIndex: 'name_ru',
			sorter: true,
			key: 'name',
		},
		{
			width: 200,
			title: t(`Текст ${genitive_form} (каз)`),
			dataIndex: 'text_kz',
			sorter: true,
			key: 'text_kz',
		},
		{
			width: 200,
			title: t(`Текст ${genitive_form} (рус)`),
			dataIndex: 'text_ru',
			sorter: true,
			key: 'text_ru',
			render: (text, record) => {
				if(text.includes('base64')) return '-'
				return text
			}
		},
		{
			title: t('Обновлен'),
			key: 'updated',
			children: [
				{
					title: t('Дата'),
					dataIndex: 'updated_at',
					width: 100,
					key: 'updated_at',
					sorter: true,
					render: (date, record) => record.updated_at ? <CustomDates data={date} /> : '-'
				},
				{
					title: t('Кем'),
					dataIndex: 'updated_by',
					sorter: true,
					width: 100,
					key: 'updated_by',
					render: (id) => id && <CreatedBy id={id}/>
				}
			]
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id) => <ButtonGroup>
				<Tooltip title={t('Редактировать')} placement={'top'}>
					<Link to={`/services-columns/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
				</Tooltip>
			</ButtonGroup>
		}
	], [])

	return <>
		<ServiceColumnsGate search={search.toString()}/>
		<DictionaryProTable data={data} count={count} initColumns={initColumns} loading={loading} showActions={false}/>
	</>
}
