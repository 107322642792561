import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $correctionPeriod,
    $selectedAcc,
    $selectedAccMeta,
    setSrvCreateModalEv, submitAccountTempCorrEv
} from '../../../models/dictionaryCorrectionModel/index.js';
import {useEffect} from 'react';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Divider from 'antd/es/divider';
import Button from 'antd/es/button';
import AccountServicesTable from './AccountServicesTable.jsx';
import SingleCorrModal from './modals/SingleCorrModal.jsx';
import AddSrvCorrModal from './modals/AddSrvCorrModal.jsx';
import {binValidator} from '../../../utils/validateBIN.js';

export default function SelectedAccForm() {
    const {t} = useTranslation()
    const createSrv = useUnit(setSrvCreateModalEv)

    const selAcc = useUnit($selectedAcc)
    const [accMetaForm] = Form.useForm()
    const accData = useUnit($selectedAccMeta)
    const submitAcc = useUnit(submitAccountTempCorrEv)
    const period = useUnit($correctionPeriod)

    useEffect(() => {
        if (accData) {
            for (const [key, value] of Object.entries(accData)) {
                if (['name_ru', 'iin', 'flat', 'full_square', 'living_square', 'heated_square', 'people_quantity', 'phone', 'mobile_phone', 'email'].includes(key)) {
                    accMetaForm.setFieldValue(key, value)
                }
            }
        }
        if (period === 'current' && accData?.correction_account) {
            for (const [key, value] of Object.entries(accData?.correction_account)) {
                if (value !== accData[key.replace('_new', '').replace('account_', '')] && value) {
                    accMetaForm.setFieldValue(key.replace('_new', '').replace('account_', ''), value)
                }
            }
        }
        if (period === 'next' && accData?.account_changes) {
            for (const [key, value] of Object.entries(accData?.account_changes)) {
                accMetaForm.setFieldValue(key.replace('_new', ''), value)
            }
        }
    }, [accData, period]);

    return <>
        <SingleCorrModal />
        <AddSrvCorrModal />
        <Card title={`${t('Параметры ЛС')} ${selAcc}`}>
            <Form form={accMetaForm} layout={'vertical'} onFinish={submitAcc}>
                <Row gutter={[8, 0]}>
                    <Col span={9}>
                        <Card>
                            <Row gutter={[8, 0]}>
                                <Col span={12}>
                                    <Form.Item name={'name_ru'} label={t('ФИО')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'iin'} label={t('ИИН')}
                                               rules={[
                                                   {validator: (rule, value) => binValidator(rule, value, false)}
                                               ]}
                                    >
                                        <Input showCount maxLength={12} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={'flat'} label={t('Квартира')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card>
                            <Row gutter={[8, 0]}>
                                <Col span={5}>
                                    <Form.Item name={'full_square'} label={t('Общая пл.')}>
                                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name={'living_square'} label={t('Жилая пл.')}
                                               rules={[
                                                   ({getFieldValue}) => ({
                                                       validator(_, value) {
                                                           if (value && value > getFieldValue('full_square')) {
                                                               return Promise.reject(new Error(t('Значение не должно превышать общую площадь')))
                                                           }
                                                           return Promise.resolve()
                                                       }
                                                   })
                                               ]}
                                    >
                                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name={'heated_square'} label={t('Отапл. пл.')}
                                    rules={[
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (value && value > getFieldValue('full_square')) {
                                                    return Promise.reject(new Error(t('Значение не должно превышать общую площадь')))
                                                }
                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                    >
                                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'people_quantity'} label={t('Кол-во прожив.')}>
                                        <InputNumber min={0} style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card>
                            <Row gutter={[8, 0]}>
                                <Col span={6}>
                                    <Form.Item name={'phone'} label={t('Тел.')}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name={'mobile_phone'} label={t('Моб. тел.')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item name={'email'} label={t('Email')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Form.Item>
                    <Button style={{marginTop: 8}} type={'primary'} onClick={() => accMetaForm.submit()}>{t('Сохранить')}</Button>
                </Form.Item>
            </Form>
        </Card>
        <Divider />
        <Card title={`${t('Услуги на ЛС')} ${selAcc}`} extra={<Button type={'primary'} onClick={() => createSrv(true)}>{t('Добавить услугу')}</Button>}>
            <AccountServicesTable />
        </Card>
    </>
}
