import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import './components/style.css'
import {useStoreMap, useUnit} from "effector-react";
import {
	$paymentRetentionDayData, exportPaymentAppliesEv, exportPaymentExportEv, getDeductionPaymentsEv,
	getPaymentElementsEv,
	getPaymentErrorByIdEv, getPaymentRetentionDayDataFx,
	onCancelAppliesSingleEv,
	onConfirmAppliesSingleEv,
	PaymentRetentionDayGate, setDeductionDayEv, setDeductionFx,
} from '../../models/paymentModel/index.js';
import {searchAndActions} from "../../utils/searchAndActions.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import Table from "antd/es/table";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import ButtonGroup from "antd/es/button/button-group";
import Tooltip from "antd/es/tooltip";
import {
	CheckOutlined, CloseOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import {Popconfirm} from "antd";
import ExcelOutlined from "../../components/ExcelIcon/EcxelIcon.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import CreatedBy from "../../components/CreatedBy/CreatedBy.jsx";
import Card from "antd/es/card";
import {paginationConfig} from "../../utils/paginationConfig.js";
import PaymentTableError from "./components/PaymentTableError.jsx";
import PaymentTableApplied from "./components/PaymentTableApplied.jsx";
import FormatNumber from "../../components/FormatNumber/FormatNumber.jsx";
import {$dictsSelects, $infoMaps} from "../../models/infoModel/index.js";
import {Link} from "react-router-dom";
import PaymentTableDeductDay from "./components/PaymentTableDeductDay.jsx";

const options = [{
	label: 'Удержано', value: true,
}, {
	label: 'Не удержано', value: false,
},]






function FormattedDates({date}) {
	const dateStr = date.toString();
	const year = dateStr.slice(0, 4);
	const month = dateStr.slice(4, 6);
	return dayjs(`${year}-${month}-01`).format('MM.YYYY');
}

export default function PaymentRetentionDay() {
	const {t} = useTranslation()
	const {data, count} = useUnit($paymentRetentionDayData)
	const loading = useUnit(getPaymentRetentionDayDataFx.pending)
	console.log('data retention', data)
	const {search} = searchAndActions()

	const exportXlsAction = useUnit(exportPaymentExportEv)

	const keysData = data.map(item => {
		return {
			...item, key: item.export_ids[0]

		}
	})

	const dataPaymentNew = keysData.filter(
		item => (item.export_status === 'prepared' || item.export_status === 'new') &&
							item.is_deduction === false
	)


	// const loading = useUnit(getPaymentDataFx.pending)
	const [selectedRowKey, setSelectedRowKey] = useState(null);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRowData, setSelectedRowData] = useState([]);
	// const [bankDay, setBankDay] = useState(false);
	// const [date, setDate] = useState(null);
	const [activeKey, setActiveKey] = useState('view');

	const [checkStatus, setCheckStatus] = useState(false);
	const [applyId, setApplyId] = useState(false);


	const onConfirmSingleEvent = useUnit(onConfirmAppliesSingleEv)
	const onCancelAppliesSingleEvent = useUnit(onCancelAppliesSingleEv)
	// const getPayment = useUnit(getPaymentByIdEv)
	const getPayment = useUnit(getDeductionPaymentsEv)
	const getPaymentError = useUnit(getPaymentErrorByIdEv)

	const onConfrim = useUnit(setDeductionDayEv)
	const actionLoading = useUnit(setDeductionFx.pending)

	const bankOpts = useStoreMap($dictsSelects, sel => sel.bankBranch)?.filter(item => item.is_contract_signed === true)
	const bankBranch = useStoreMap($infoMaps, sel => sel.bankBranchMap)


	const columns = [
		{
			title: t('Статус удержания'),
			dataIndex: 'is_deduction',
			sorter: true,
			key: 'is_deduction',
			align: 'center',
			width: '10%',
			render: (status) =>  status === true ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
		},
		{
			title: t('Дата'),
			dataIndex: 'bank_day',
			sorter: (a, b) => new Date(a.bank_day) - new Date(b.bank_day),
			defaultSortOrder: 'descend',
			key: 'bank_day',

			render: (date) => date ? dayjs(date).format('DD.MM.YY') : '-'
		},
		{
			title: t('Сумма'),
			dataIndex: 'export_sum',
			sorter: true,
			key: 'export_sum',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Сумма с учетом комиссии'),
			dataIndex: 'export_sum_comm',
			sorter: true,
			key: 'export_sum_comm',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Сумма к удержанию'),
			dataIndex: 'deduction_amount',
			sorter: true,
			key: 'deduction_amount',
			render: (value) => value || value === 0 ? <FormatNumber value={value} type={2}/> : '-'
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id, row) => <ButtonGroup>
				{
					(row.export_status === 'prepared' || row.export_status === 'new') && row.is_deduction === false
						? <Popconfirm
							title={t('Вы уверены?')}
							okText={t('Да')}
							onConfirm={() => onConfirmSingle(row)}
							placement={'left'}
							icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
						>
							{
								<Tooltip title={t('Удержать эту запись')} placement={'top'}>
									<Button
										type={'primary'}
										size={'small'}
										disabled={
											data.some(item =>
												item?.bank_day < row?.bank_day &&
												item?.bank_id === row?.bank_id &&
												(item?.apply_status === 'new' || item?.apply_status === 'error')
											) || row.apply_status === 'not_ready'
										}
									>
										Удержать
									</Button>
								</Tooltip>
							}
						</Popconfirm> :
						(row.export_status === 'prepared' || row.export_status === 'new') && row.is_deduction === true ?
							<Popconfirm
								title={t('Вы уверены?')}
								okText={t('Да')}
								onConfirm={() => onConfirmSingle(row)}
								placement={'left'}
								icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
							>
								{
									<Tooltip title={t('Отменить распределение этой записи')}
											 placement={'top'}>
										<Button type={'primary'}>
											Отменить удержание
										</Button>
									</Tooltip>
								}
							</Popconfirm> : ''
				}
			</ButtonGroup>
		}
	]

	const filters = [
		// {name: 'bank_id', label: t('Банк'), type: 'select', options: bankOpts},
		// {name: 'branch_name', label: t('Отделение'), type: 'input'},
		{name: 'bank_day', label: t('Дата'), type: 'range',},
		// {name: 'bank_day_to', label: t('Дата до'), type: 'date',},
		{name: 'is_deduction', label: t('Статус'), type: 'select', options: options},
	]

	const onSelectChange = (newSelectedRowKeys, newSelectedRowData) => {
		setSelectedRowKeys(newSelectedRowKeys);
		setSelectedRowData(newSelectedRowData)
		checkInBankDates(newSelectedRowData)
	};

	const checkInBankDates = (newSelected) => {
		const sorted = newSelected.sort((a, b) => new Date(a.bank_day) - new Date(b.bank_day));
		const selectedIds = newSelected.map(item => item.id)
		const last = sorted[sorted.length - 1]
		const result = data.some(item => item?.bank_day < last?.bank_day && item?.bank_id === last?.bank_id && (item?.apply_status === 'new' || item?.apply_status === 'error') && !selectedIds.includes(item.id))
		setCheckStatus(result)
	}

	const rowSelection = {
		selectedRowKeys, onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			// disabled: record.confirmation_status,
			disabled: record.export_status === 'prepared' || record.export_status === 'new' ? false : true,
		}),
	};

	const onChangeTab = (payload) => {
		setActiveKey(payload)
	}

	const handleRowClick = (record) => {
		setSelectedRowKey(record.key);
		// getPayment(record.export_ids.map(id => `id=${id}`).join('&'))
		getPayment(`bank_day=${dayjs(record.bank_day).format('YYYY-MM-DD')}`)
		setApplyId(`bank_day=${dayjs(record.bank_day).format('YYYY-MM-DD')}`)

		if (record.apply_status === 'error') {
			getPaymentError(record.apply_ids)
			setActiveKey('error')
		}

	};


	const rowClassName = (record) => {
		return record.key === selectedRowKey ? 'payment-selected-row' : '';
	};

	const tabs = [
		{
			key: 'view', label: t('Платежи'), children: <PaymentTableDeductDay applyId={applyId}/>
		},

	]

	const onConfirmSingle = (row) => {
		const data = {
			bank_days: [dayjs(row.bank_day).format('YYYY-MM-DD')],
			toggle: !row.is_deduction
		}
		onConfrim(data)
	}
	const onCancelSingle = (row) => {
		const data = {
			bank_days: [dayjs(row.bank_day).format('YYYY-MM-DD')],
		}
		onCancelAppliesSingleEvent(data)
	}


	const onConfirmAll = () => {
		const bank_days = dataPaymentNew?.map(item => {
			return dayjs(item.bank_day).format('YYYY-MM-DD')
		})
		const data = {
			bank_days: bank_days,
			toggle: true
		}
		onConfrim(data)
	}

	const onConfirmSelected = () => {
		// const data = {
		// payment_applies_ids: selectedRowData.map(item => dayjs(item.apply_date).format('YYYY-mm-DD')),
		// bank_day: selectedRowData.map(item => dayjs(item.bank_day).format('YYYY-MM-DD')),
		// }

// FIXME
		const data = {
			bank_days: dataPaymentNew.map(item => {
				return dayjs(item.bank_day).format('YYYY-MM-DD')
			})
		}
		onConfirmSingleEvent(data)
	}

	return <>
		<PaymentRetentionDayGate search={search.toString()}/>

		<FilterBlock items={filters}/>
		<Divider/>
		<Row gutter={[8, 0]}>
			<Col>
				<Popconfirm
					title={t('Удержать все?')}
					okText={t('Да')}
					onConfirm={() => onConfirmAll()}
					placement={'left'}
					icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
					disabled={actionLoading}
				>
					<Button type={'primary'}
							loading={actionLoading}
							disabled={dataPaymentNew.length <= 0}
							size={'small'}>
						Удержать все не удержанные
						({dataPaymentNew.length > 0 ? dataPaymentNew.length : 0})
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Popconfirm
					title={t('Удержать отмеченные?')}
					okText={t('Да')}
					onConfirm={() => onConfirmSelected()}
					placement={'left'}
					disabled={actionLoading}
					icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
				>
					{/*<Button type={'primary'} disabled={selectedRowData.length > 0 ? false : true} size={'small'}>*/}
					<Button type={'primary'}
							loading={actionLoading}
							disabled={(!(checkStatus === false && selectedRowData.length > 0))}
							size={'small'}>
						Удержать отмеченные
						({selectedRowData.length > 0 ? selectedRowData.length : 0})
					</Button>
				</Popconfirm>
			</Col>
			<Col>
				<Button onClick={() => exportXlsAction(search)}>
					<ExcelOutlined size={18}/>
					Экспорт
				</Button>
			</Col>


		</Row>
		<br/>
		<Card size={'small'} loading={loading}>
			<div style={{color: 'red', marginLeft: '10px'}}>
				{
					checkStatus === true ? t('Нельзя пропускать дни в распределении') : ''
				}
			</div>
			<Table
				columns={columns}
				dataSource={keysData}
				size={'small'}
				rowSelection={rowSelection}
				rowClassName={rowClassName}
				onRow={(record) => ({
					onClick: () => handleRowClick(record),
				})}
				pagination={{
					...paginationConfig, pageSize: 10, // current: parseInt(page),
					// total: count
				}}
			/>
		</Card>
		<Divider/>
		<Tabs
			defaultActiveKey={'view'}
			items={tabs}
			destroyInactiveTabPane
			activeKey={activeKey}
			onChange={onChangeTab}
			type={'card'}
		/>
	</>
}
