import {createStore} from 'effector';


export const $rolePermissionList = createStore({data: [], count: 0})

export const $selectedRolePermission = createStore(null)

export const $editRolePermissionStatus = createStore(false)

export const $createdRolePermissionId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $permissionList = createStore({data: [], count: 0})
