import React from 'react';
import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {
	$errorSingle,
	changeServiceInErrorEv, exportAppliesErrorEv,
	TableErrorGate
} from "../../../models/paymentModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";
import {Select} from "antd";
import Button from "antd/es/button/index.js";
import Row from "antd/es/row/index.js";
import Col from "antd/es/col/index.js";
import {$infoMaps} from "../../../models/infoModel/index.js";
import Form from "antd/es/form/index.js";
import Card from "antd/es/card/index.js";
import Table from "antd/es/table";
import ExcelOutlined from "../../../components/ExcelIcon/EcxelIcon.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";

export default function PaymentTableError({id}) {
	const {t} = useTranslation()
	const {data, count} = useUnit($errorSingle)

	const changeService = useUnit(changeServiceInErrorEv)


	const [paymentErrorForm] = Form.useForm();

	const servicesMap = useStoreMap($infoMaps, maps => maps.servicesMap)


	const exportXls = useUnit(exportAppliesErrorEv)
	const {search} = searchAndActions()


	const columns = [
		{
			title: t('Дата системы'),
			dataIndex: 'system_payment_date',
			key: 'system_payment_date',
			render: (date) => <CustomDates data={date} />
		},
		{
			title: t('Дата банка'),
			dataIndex: 'bank_payment_date',
			key: 'bank_payment_date',
			render: (date) => <CustomDates data={date} />
		},
		{
			title: t('Лицевой счет'),
			dataIndex: 'account_id',
			key: 'account_id',
			width: '6%',
			render: (account_id) => <MappedDictItem id={account_id} type={'account'}/>
		},
		{
			title: t('Инвойс'),
			dataIndex: 'invoice',
			key: 'invoice',
			width: '4%'
		},
		{
			title: t('sysref'),
			dataIndex: 'sysref',
			key: 'sysref',
			width: '3%'
		},
		{
			title: t('sysbankref'),
			dataIndex: 'sysbankref',
			key: 'sysbankref',
			width: '4%'
		},
		{
			title: t('Услуга'),
			dataIndex: 'service_id',
			key: 'service_id',
			render: (service_id) => <MappedDictItem id={service_id} type={'services'}/>
		},
		{
			title: t('Сумма'),
			dataIndex: 'pay_sum',
			key: 'pay_sum',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Ошибка'),
			dataIndex: 'error',
			key: 'error',
			render: (error) => {
				return t(`payment.errors.${error}`)
			}
		},
		{
			title: t('Изменить услугу'),
			width: '15%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id, row) => {
				const serviceOption = row?.provider_services?.map(item => {
					return {
						label: servicesMap[item.service_id],
						value: item.service_id,
					}
				}).filter((item, index, self) =>
					index === self.findIndex((t) => t.value === item.value)
				);
				return <Row gutter={[16,16]}  justify={'space-between'}>
					<Col span={16} >
						<Form.Item
							name={[row.payment_element_id, 'service_id']}
							// label={'Услуга'}
							// style={noMargin}
						>
							<Select
								size={'small'}
								style={{width: '100%'}}
								options={serviceOption}
							/>
						</Form.Item>

					</Col>
					<Col span={8} >
						<Button
							size={'small'}
							type={'primary'}
							onClick={() => onChangeService(row.payment_element_id)}
						>
							Изменить
						</Button>
					</Col>
				</Row>
			}
		}

	]

	const onChangeService = (id) => {
		const formData = paymentErrorForm.getFieldsValue();
		const data = {
			object_id: id,
			new_service_id: formData[id].service_id,
		}
		changeService(data)
	}


	return (
		<>
			<TableErrorGate />
			<Card>
				{/*<Col>*/}
				{/*	<Button*/}
				{/*		onClick={() => exportXls(search)}*/}
				{/*		disabled={!data.length > 0}*/}
				{/*		size={'small'}*/}
				{/*	>*/}
				{/*		<ExcelOutlined size={18}/>*/}
				{/*		Экспорт*/}
				{/*	</Button>*/}
				{/*</Col>*/}
				<Form
					form={paymentErrorForm}
					onFinish={(values) => onSubmit(values)}
					size={'small'}
					style={{width: '100%'}}
				>
					<Table
						dataSource={data}
						columns={columns}
						showActions={false}
					/>
				</Form>
			</Card>
		</>



	);
}
