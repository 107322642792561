import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const REOSearchGate = createGate()

export const selectAddressEv = createEvent()
export const selectReoEv = createEvent()
export const selectAccountEv = createEvent()

export const searchMultiAddressesEv = createEvent()
export const searchMultiReosEv = createEvent()
export const searchMultiAccountsEv = createEvent()

export const createMissingAddressesEv = createEvent()
export const createMissingReosEv = createEvent()
export const createMissingAccountsEv = createEvent()

export const submitCreateREOEv = createEvent()
export const resetCreatedREOEv = createEvent()


export const resetSelectionEv = createEvent()
export const resetFiltersEv = createEvent()

export const changeMultiSelectedREOsEv = createEvent()
export const resetMultiSelectedREOsEv = createEvent()

// Для серверной пагинации в будущем
// export const setTableParamsEv = createEvent()