import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {useUnit} from 'effector-react';
import {$createStreetTypeEmbedded} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import Card from 'antd/es/card';

export default function CreateStTypeEmbeddedForm({inRow}) {
    const {t} = useTranslation()
    const open = useUnit($createStreetTypeEmbedded)
    // debugger
    return open && <>
        {
            inRow ? <Col span={24} >
                    <Card title={t('Создать тип улицы')} style={{marginBottom: 16, width: '100%'}} size={'small'}>
                        <Row justify={"space-between"}>
                            <Form.Item name={['streetType', 'name_ru']}
                                       label={t('Название (рус)')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name={['streetType', 'name_kz']}
                                       label={t('Название (каз)')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name={['streetType', 'short_name_ru']}
                                       label={t('Краткое Назв. (рус)')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name={['streetType', 'short_name_kz']}
                                       label={t('Краткое Назв. (каз)')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Row>
                    </Card>

                </Col>  :
                <Card title={t('Свойства типа улицы')} style={{marginBottom: 16}} size={'small'}>
                    <Form.Item name={['streetType', 'name_kz']}
                               label={t('Название (каз)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['streetType', 'name_ru']}
                               label={t('Название (рус)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['streetType', 'short_name_kz']}
                               label={t('Краткое Назв. (каз)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['streetType', 'short_name_ru']}
                               label={t('Краткое Назв. (рус)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                </Card>
        }
    </>
}
