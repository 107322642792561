import {createStore} from 'effector';


export const $templatePaymentsList = createStore({data: [], count: 0})

export const $selectedTemplatePayments = createStore(null)

export const $editTemplatePaymentsStatus = createStore(false)

export const $createdTemplatePaymentsId = createStore(null)
export const $afterCreateAction = createStore(null)
