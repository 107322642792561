import {
    $afterCreateAction,
    $createdEPDTemplatesId,
    $editEPDTemplatesStatus,
    $selectedEPDTemplates,
    $epdTemplatesList
} from './stores.js';
import {getEPDTemplatesListFx, getEPDTemplatesByIdFx} from './effects.js';
import {
    deleteEPDTemplatesEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    EPDTemplatesEditGate,
    EPDTemplatesListGate,
    EPDTemplatesViewGate,
    submitCreateEPDTemplatesEv,
    submitEditEPDTemplatesEv, EPDTemplatesCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $createdEPDTemplateField, $createdServices,
    $deletedEPDTemplateField, $deletedServices,
    $EPDTemplateFieldChildren, $ServicesChildren
} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateEPDTemplatesEv, response: triggerSuccessOpsEv}, reset: EPDTemplatesCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditEPDTemplatesEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteEPDTemplatesEv, response: triggerSuccessOpsEv})

$epdTemplatesList.on(getEPDTemplatesListFx.doneData, (_, data) => data)
    .reset(EPDTemplatesListGate.close)

$selectedEPDTemplates.on(getEPDTemplatesByIdFx.doneData, (_, data) => data)
    .reset([EPDTemplatesEditGate.close, EPDTemplatesViewGate.close])
// FIXME
$createdEPDTemplatesId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "epd_templates")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editEPDTemplatesStatus.on(editCompleteEv, () => true)
    .reset(EPDTemplatesEditGate.close)

sample({
    clock: [EPDTemplatesEditGate.state, EPDTemplatesViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getEPDTemplatesByIdFx
})

sample({
    clock: EPDTemplatesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getEPDTemplatesListFx
})

sample({
    source: EPDTemplatesListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getEPDTemplatesListFx
})

sample({
    source: {epd_template_field: $createdEPDTemplateField, services: $createdServices},
    clock: submitCreateEPDTemplatesEv,
    fn: (src, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const template_data = {
            name_kz: payload.name_kz,
            name_ru: payload.name_ru,
        }

        actions.push({operation: 'create', type: 'epd_templates', value: {...template_data, id: idCounter}})

        // debugger

        if (src?.epd_template_field?.length > 0) {
            const created = src?.epd_template_field.map(item => {
                return {
                    ...item,
                    template_id: -1
                }
            })
            for (const newDist of Object.values(created)) {
                idCounter--

                actions.push({operation: 'create', type: 'epd_template_fields', value: {...newDist, id: idCounter}})
            }
        }

        if (src?.services?.length > 0) {
            const created = src?.services.map(item => {
                return {
                    epd_order: item.epd_order ,
                    object_id: item.id,
                    is_counter_service: item.is_counter_service,
                    name_kz: item.name_kz,
                    name_ru: item.name_ru,
                    service_payment_type_id: item.service_payment_type_id,
                    service_unit_id: item.service_unit_id,
                    short_name_kz: item.short_name_kz,
                    short_name_ru: item.short_name_ru,
                    template_id: -1,
                }
            })
            for (const newDist of Object.values(created)) {
                idCounter--

                actions.push({operation: 'update', type: 'services', value: {...newDist}})
            }
        }
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {
        selected: $selectedEPDTemplates,
        created: $createdEPDTemplateField,
        updated: $EPDTemplateFieldChildren,
        deleted: $deletedEPDTemplateField,
        createdServices: $createdServices,
        updatedServices: $ServicesChildren,
        deletedServices: $deletedServices
    },
    clock: submitEditEPDTemplatesEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'epd_templates', value: {object_id: current.selected.id, ...changedFields}})


        if (current?.created?.length > 0) {
            const created = current?.created.map(item => {
                return {
                    ...item,
                    template_id: current.selected.id
                }
            })
            for (const newDist of Object.values(created)) {
                idCounter--

                actions.push({operation: 'create', type: 'epd_template_fields', value: {...newDist, id: idCounter}})
            }
        }

        if (current?.updated?.data?.length > 0) {

            const updated = current?.updated?.data?.filter(item => item.changedFields).map(item => {
                return {
                    cell_type: item.cell_type,
                    column_number: item.column_number,
                    field_name: item.field_name,
                    font_size: item.font_size,
                    object_id: item.id

                }
            })
            for (const newDist of Object.values(updated)) {
                idCounter--

                actions.push({operation: 'update', type: 'epd_template_fields', value: {...newDist}})
            }
        }

        if (current?.deleted?.length > 0) {
            for (const newDist of Object.values(current?.deleted)) {
                idCounter--

                actions.push({operation: 'delete', type: 'epd_template_fields', value: {id: newDist}})
            }
        }


        if (current?.createdServices?.length > 0) {
            const created = current?.createdServices.map(item => {
                return {
                    ...item,
                    object_id: item.id,
                    template_id: current.selected.id
                }
            })
            for (const newDist of Object.values(created)) {
                idCounter--

                actions.push({operation: 'update', type: 'services', value: {...newDist}})
            }
        }

        // debugger
        if (current?.updatedServices?.data?.length > 0) {

            const updated = current?.updatedServices?.data?.filter(item => item.changedFields).map(item => {
                return {
                    epd_order: item.epd_order ,
                    object_id: item.id,
                    is_counter_service: item.is_counter_service,
                    name_kz: item.name_kz,
                    name_ru: item.name_ru,
                    service_payment_type_id: item.service_payment_type_id,
                    service_unit_id: item.service_unit_id,
                    short_name_kz: item.short_name_kz,
                    short_name_ru: item.short_name_ru,
                    template_id: current.selected.id
                }
            })
            for (const newDist of Object.values(updated)) {
                idCounter--

                actions.push({operation: 'update', type: 'services', value: {...newDist}})
            }
        }



        if (current?.deletedServices?.length > 0) {
            for (const newDist of Object.values(current?.deletedServices)) {
                idCounter--

                actions.push({operation: 'update', type: 'services', value: {object_id: newDist, template_id: null}})
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteEPDTemplatesEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'epd_templates', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
