import {
    $afterCreateAction,
    $createdPreferenceCategoryId,
    $editPreferenceCategoryStatus,
    $selectedPreferenceCategory,
    $preferenceCategoryList
} from './stores.js';
import {getPreferenceCategoryListFx, getPreferenceCategoryByIdFx} from './effects.js';
import {
    deletePreferenceCategoryEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    PreferenceCategoryEditGate,
    PreferenceCategoryListGate,
    PreferenceCategoryViewGate,
    submitCreatePreferenceCategoryEv,
    submitEditPreferenceCategoryEv, PreferenceCategoryCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createPreferenceCategoryCompleteEv = combineEvents({events: {ev: submitCreatePreferenceCategoryEv, response: triggerSuccessOpsEv}, reset: PreferenceCategoryCreateGate.state})
const editPreferenceCategoryCompleteEv = combineEvents({ev: submitEditPreferenceCategoryEv, response: triggerSuccessOpsEv})
const deletePreferenceCategoryCompleteEv = combineEvents({ev: deletePreferenceCategoryEv, response: triggerSuccessOpsEv})

$preferenceCategoryList.on(getPreferenceCategoryListFx.doneData, (_, data) => data)
    .reset(PreferenceCategoryListGate.close)

$selectedPreferenceCategory.on(getPreferenceCategoryByIdFx.doneData, (_, data) => data)
    .reset([PreferenceCategoryEditGate.close, PreferenceCategoryViewGate.close])

$createdPreferenceCategoryId.on(createPreferenceCategoryCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "preference_category")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editPreferenceCategoryStatus.on(editPreferenceCategoryCompleteEv, () => true)
    .reset(PreferenceCategoryEditGate.close)

sample({
    clock: [PreferenceCategoryEditGate.state, PreferenceCategoryViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getPreferenceCategoryByIdFx
})

sample({
    clock: PreferenceCategoryListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPreferenceCategoryListFx
})

sample({
    source: PreferenceCategoryListGate.state,
    clock: [createPreferenceCategoryCompleteEv, editPreferenceCategoryCompleteEv, deletePreferenceCategoryCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPreferenceCategoryListFx
})

sample({
    clock: submitCreatePreferenceCategoryEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'preference_category', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedPreferenceCategory,
    clock: submitEditPreferenceCategoryEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'preference_category', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deletePreferenceCategoryEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'preference_category', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
