import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const StreetsListGate = createGate()
export const StreetViewGate = createGate()
export const StreetEditGate = createGate()
export const StreetCreateGate = createGate()

export const submitCreateStreetEv = createEvent()
export const submitEditStreetEv = createEvent()
export const deleteStreetEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
