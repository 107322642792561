import {$chosenClosedMonth, $closeMonthsData, $monthsDict} from "./stores.js";
import {
	getClosedMonthByIdFx,
	getClosedMonthFx,
	sendApproveCloseMonthFx,
	sendCloseMonthFx,
	sendDeclineCloseMonthFx
} from "./effects.js";
import {
	approveCloseMonthEv,
	CloseMonthGate,
	declineCloseMonthEv,
	sendCloseMonthEv,
	ViewCloseMonthGate
} from "./events.js";
import {sample} from "effector";
import {once} from "patronum";
import {prepareMonthOpts} from "./utils.js";

$closeMonthsData.on(getClosedMonthFx.doneData, (_, data) => data)
	.reset(CloseMonthGate.close)

$chosenClosedMonth.on(getClosedMonthByIdFx.doneData, (_, data) => data)
	.reset(ViewCloseMonthGate.close)

sample({
	clock: once(getClosedMonthFx.doneData),
	fn: prepareMonthOpts,
	target: $monthsDict
})

sample({
	clock: CloseMonthGate.state,
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => gate.search,
	target: getClosedMonthFx
})

sample({
	clock: sendCloseMonthEv,
	target: sendCloseMonthFx
})

sample({
	clock: ViewCloseMonthGate.state,
	filter: (gate) => Object.hasOwn(gate, 'id'),
	fn: (gate) => gate.id,
	target: getClosedMonthByIdFx
})

sample({
	clock: approveCloseMonthEv,
	target: sendApproveCloseMonthFx
})
sample({
	clock: declineCloseMonthEv,
	target: sendDeclineCloseMonthFx
})
