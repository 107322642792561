import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import {useUnit} from 'effector-react';
import {
    $createEpdTemplateFieldEmbeddedOpen,
    $createSectorEmbeddedOpen,
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import Card from 'antd/es/card';

export default function CreateEpdTemplateFieldEmbeddedForm() {
    const {t} = useTranslation()
    const open = useUnit($createEpdTemplateFieldEmbeddedOpen)


    return open && <Row justify={'space-between '} >
                       <Form.Item name={'name_ru'}
                                  label={t('Название (рус)')}
                                  rules={[{required: true, message: t('Обязательное поле')}]}
                       >
                           <Input/>
                       </Form.Item>
                       <Form.Item name={'name_kz'}
                                  label={t('Название (каз)')}
                                  style={{marginRight: '10px'}}
                                  rules={[{required: true, message: t('Обязательное поле')}]}
                       >
                           <Input/>
                       </Form.Item>
                   </Row>
    // </Card>
}
