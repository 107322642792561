import {createEvent} from "effector";
import {createGate} from "effector-react";

export const UploadAgreementsGate = createGate()
export const AgreementsFileCellsGate = createGate()

export const setUploadModalEv = createEvent()
export const resetUploadAgreementsModalEv = createEvent()
export const uploadAgreementsFileEv = createEvent()

export const acceptFileEv = createEvent()
export const stopUploadFileEv = createEvent()
export const rejectFileEv = createEvent()
export const deleteFileEv = createEvent()
export const downloadFileEv = createEvent()
export const downloadErrorFileEv = createEvent()

export const sendMessageUploadEv = createEvent()
export const acceptMessageUploadEv = createEvent()


