import {
	$agreementsFileList, $selectedAgreementFile,
	$selectedAgreementFileCells,
	$uploadAgreementsModal,
	$uploadAgreementsStatus
} from "./stores.js";
import {
	acceptFileFx, getAgreementsFileCellsFx, getAgreementsFileInfoFx,
	getAgreementsFileListFx,
	rejectFileFx,
	stopUploadFileFx,
	uploadAgreementsFileFx,
	deleteFileFx,
	downloadFileFx,
	downloadErrorFileFx,
	sendMessageUploadFx

} from "./effects.js";
import {
	acceptFileEv, AgreementsFileCellsGate, rejectFileEv,
	resetUploadAgreementsModalEv, sendMessageUploadEv,
	setUploadModalEv, stopUploadFileEv,
	uploadAgreementsFileEv,
	UploadAgreementsGate,
	deleteFileEv,
	downloadErrorFileEv,
	downloadFileEv, acceptMessageUploadEv
} from "./events.js";
import {sample} from "effector";
import {FilesGate} from "../uploadAccountsModel/index.js";


$agreementsFileList.on(getAgreementsFileListFx.doneData, (_,data) => data)
	.on(acceptMessageUploadEv, (state, msg) => {
		const newList = [...state.data]
		newList.map((item) => {
			if (item.id === msg.id) {
				for (const [key, value] of Object.entries(msg)) {
					item[key] = value
				}
			}
			return item
		})
		return {data: newList, count: state.count}
	})
	.reset(UploadAgreementsGate.close)

$uploadAgreementsStatus.on(uploadAgreementsFileFx.doneData, () => true)
	.reset(resetUploadAgreementsModalEv)

$uploadAgreementsModal.on(setUploadModalEv, () => true)
	.reset(resetUploadAgreementsModalEv)

$selectedAgreementFile.on(getAgreementsFileInfoFx.doneData, (_, file) => file)
	.reset(AgreementsFileCellsGate.close)
$selectedAgreementFileCells.on(getAgreementsFileCellsFx.doneData, (_, data) => data)
	.reset(AgreementsFileCellsGate.close)


sample({
	source: UploadAgreementsGate.state,
	clock: [UploadAgreementsGate.state, uploadAgreementsFileFx.doneData, acceptFileFx.doneData, rejectFileFx.doneData, deleteFileFx.doneData],
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => gate.search,
	target: getAgreementsFileListFx
})


sample({
	clock: uploadAgreementsFileEv,
	target: uploadAgreementsFileFx
})

sample({
	clock: AgreementsFileCellsGate.state.updates,
	filter: (gate) => Object.hasOwn(gate, 'id'),
	fn: (gate) => +gate.id,
	target: getAgreementsFileInfoFx
})
sample({
	clock: AgreementsFileCellsGate.state.updates,
	filter: (gate) => Object.hasOwn(gate, 'id'),
	fn: (gate) => ({id: +gate.id, filters: gate.search}),
	target: getAgreementsFileCellsFx
})

sendMessageUploadEv.watch((data) => {
	sendMessageUploadFx(data)
})

sample({
	clock: acceptFileEv,
	target: acceptFileFx
})

sample({
	clock: rejectFileEv,
	target: rejectFileFx
})

sample({
	clock: stopUploadFileEv,
	target: stopUploadFileFx
})

sample({
	clock: stopUploadFileFx.doneData,
	target: getAgreementsFileListFx
})

sample({
	clock: deleteFileEv,
	target: deleteFileFx
})

sample({
	clock: downloadFileEv,
	target: downloadFileFx
})

sample({
	clock: downloadErrorFileEv,
	target: downloadErrorFileFx
})
