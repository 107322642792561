import queryString from 'query-string';
import {
    $accountSearch,
    $addressSearch,
    $createdMissAddresses, $createdREO,
    $existingReos,
    $missAddresses,
    $missAddrsCreatedStatus,
    $multiSelectedAccounts,
    $multiSelectedAddresses,
    $foundReos,
    $multiSelectedReos,
    $reoSearch,
    $selectedAccount,
    $selectedAddress,
    $selectedReo, $selectedREOFromAddress
} from './stores.js';
import {getAccountsFx, getAddressesFx, getReosFx} from './effects.js';
import {
    createMissingAddressesEv, resetCreatedREOEv,
    resetSelectionEv, searchMultiAccountsEv,
    searchMultiAddressesEv, searchMultiReosEv,
    selectAccountEv,
    selectAddressEv,
    selectReoEv, submitCreateREOEv,
    resetFiltersEv, resetMultiSelectedREOsEv, changeMultiSelectedREOsEv, REOSearchGate
} from './events.js';
import {sample} from 'effector';
import {isId} from '../../utils/helpers.js';
import {parseHouses} from './utils.js';
import {setCreateReoEmbeddedEv} from '../dictionaryEmbeddedFormsModel/index.js';
import {notification} from 'antd';
import i18n from '../../i18n.js';

// Для серверной пагинации, если понадобится в будущем
// $tableParams.on(setTableParamsEv, (state, payload) => {
//     const newState = {...state}
//     for (const {key, value} of Object.values(payload)) {
//         newState[key] = value
//     }
//     return newState
// })
//     .reset([RealEstateObjSearchGate.close, resetFiltersEv])

$addressSearch.on(selectAddressEv, (_, payload) => {
    for (const [key, value] of Object.entries(payload)) {
        if (value === undefined) {
            payload[key] = null
        }
    }
    return payload
})
    .on(searchMultiAddressesEv, (_, payload) => {
        for (const [key, value] of Object.entries(payload)) {
            if (value === undefined) {
                payload[key] = null
            }
        }
        return payload
    })
    .reset(resetFiltersEv)

$reoSearch.on(selectReoEv, (_, payload) => payload)
    .on(searchMultiReosEv, (_, payload) => payload)
    .reset([resetFiltersEv, REOSearchGate.close])

$accountSearch.on(selectAccountEv, (_, payload) => payload)
    .on(searchMultiAccountsEv, (_, payload) => payload)
    .reset(resetFiltersEv)

$selectedAccount.on(getAccountsFx.doneData, (_, payload) => payload?.data?.[0] ?? false)
    .reset([resetSelectionEv, resetFiltersEv])

$selectedReo.on(getReosFx.doneData, (_, payload) => payload?.data?.[0] ?? false)
    .reset([resetSelectionEv, resetFiltersEv])

$selectedAddress.reset([resetSelectionEv, resetFiltersEv])

$missAddresses.reset([resetSelectionEv, resetFiltersEv])

$createdMissAddresses.reset([resetSelectionEv, resetFiltersEv])

$createdREO.reset([resetCreatedREOEv, resetSelectionEv, resetFiltersEv])

$selectedREOFromAddress.reset([resetSelectionEv, resetFiltersEv])

sample({
    source: $addressSearch,
    clock: getAddressesFx.doneData,
    fn: (filters, {data}) => {
        if (data.length > 1) {
            const addr = data.find(addr => addr.house === filters.house_in && (filters.corpus ? addr.corpus === filters.corpus : addr.corpus === ''))
            if (!addr) {
                return false
            } else {
                return addr
            }
        } else if (data.length === 1) {
            return data[0]
        } else if (data.length === 0) {
            return false
        }
    },
    target: $selectedAddress
})

sample({
    clock: $selectedAddress.updates,
    fn: (addr) => {
        if (addr === false || !addr || addr?.real_estate_object_id === null) {
            return false
        } else {
            return {
                town_id: addr.town_id,
                district_id: addr.town_distr_id ?? null,
                street_id: addr.street_id,
                house: addr.house,
                corpus: addr.corpus ?? '',
                address_id: addr.id,
                real_estate_object_id: addr.real_estate_object_id
            }
        }
    },
    target: $selectedREOFromAddress
})

sample({
    source: {selAddr: $selectedAddress, created: $createdMissAddresses},
    clock: submitCreateREOEv,
    fn: ({selAddr, created}, payload) => {
        if (selAddr) {
            return {
                ...payload,
                town_id: selAddr.town_id,
                town_distr_id: selAddr.town_distr_id ?? null,
                street_id: selAddr.street_id,
                house: selAddr.house,
                corpus: selAddr.corpus ?? '',
                address_id: selAddr.id,
                id: -1
            }
        } else if (created.length > 0) {
            const newAddr = created[0]
            return {
                ...payload,
                town_id: newAddr.town_id,
                town_distr_id: newAddr.town_distr_id ?? null,
                street_id: newAddr.street_id,
                house: newAddr.house,
                corpus: newAddr.corpus ?? '',
                address_id: newAddr.id,
                id: newAddr.id - 1
            }
        }
    },
    target: $createdREO
})

$multiSelectedAccounts.on(getAccountsFx.doneData, (_, payload) => payload?.data ?? false)
    .reset(resetFiltersEv)

$multiSelectedAddresses.reset(resetFiltersEv)

$foundReos.on(getReosFx.doneData, (_, payload) => payload?.data ?? false)
    .reset([resetFiltersEv, REOSearchGate.close])

$multiSelectedReos.on(changeMultiSelectedREOsEv, (state, value) => value)
    .reset([resetSelectionEv, resetFiltersEv, resetMultiSelectedREOsEv, REOSearchGate.close])


sample({
    source: $addressSearch,
    clock: getAddressesFx.doneData,
    fn: (filters, {data}) => {
        if (data.length > 0) {
            const houses = parseHouses(filters.house_in)
            return data.filter(addr => houses.includes(addr.house) && (filters.corpus ? addr.corpus === filters.corpus : addr.corpus === ''))
        } else {
            return []
        }
    },
    target: $multiSelectedAddresses
})

$missAddresses.reset([resetFiltersEv])
$missAddrsCreatedStatus.reset([resetSelectionEv, resetFiltersEv])

$existingReos.reset([resetFiltersEv])

sample({
    clock: selectAddressEv,
    filter: (filters) => isId(filters?.town_id) && isId(filters?.street_id) && filters?.house_in?.length > 0,
    fn: (filters) => {
        const checked = {...filters}
        if (!filters.corpus) {
            checked.corpus = ''
        }
        if (!filters.town_distr_id) {
            delete checked.town_distr_id
        }
        return queryString.stringify(checked, {skipNull: false, skipEmptyString: false})
    },
    target: getAddressesFx
})

sample({
    clock: selectReoEv,
    filter: (filters) => isId(filters?.town_id) && isId(filters?.street_id) && filters?.house_in?.length > 0,
    fn: (filters) => {
        const checked = {...filters}
        if (!filters.corpus) {
            checked.corpus = ''
        }
        if (!filters.town_distr_id) {
            delete checked.town_distr_id
        }
        return queryString.stringify(checked, {skipNull: false, skipEmptyString: false})
    },
    target: getReosFx
})

sample({
    clock: selectAccountEv,
    filter: (filters) => isId(filters?.town_id) && isId(filters?.street_id) && filters?.house_in?.length > 0,
    fn: (filters) => queryString.stringify(filters, {skipEmptyString: true}),
    target: getAccountsFx
})

sample({
    clock: searchMultiAddressesEv,
    filter: (filters) => isId(filters?.town_id) && isId(filters?.street_id) && filters?.house_in?.length > 0,
    fn: (filters) => {
        const checked = {...filters}
        if (!filters.corpus) {
            checked.corpus = ''
        }
        if (!filters.town_distr_id) {
            delete checked.town_distr_id
        }
        return queryString.stringify(checked, {skipNull: false, skipEmptyString: false})
    },
    target: getAddressesFx
})

sample({
    clock: searchMultiReosEv,
    filter: (filters) => isId(filters?.town_id) && isId(filters?.street_id),
    fn: (filters) => {
        const checked = {...filters}
        if (!filters.corpus) {
            delete checked.corpus
        }
        if (!filters.town_distr_id) {
            delete checked.town_distr_id
        }
        return queryString.stringify(checked, {skipNull: false, skipEmptyString: false})
    },
    target: getReosFx
})

sample({
    clock: searchMultiAccountsEv,
    filter: (filters) => isId(filters?.town_id) && isId(filters?.street_id) && filters?.house_in?.length > 0,
    fn: (filters) => queryString.stringify(filters, {skipNull: true, skipEmptyString: true}),
    target: getAccountsFx
})

sample({
    clock: $multiSelectedAddresses.updates,
    filter: (addresses) => addresses?.length > 0,
    fn: (addresses) => addresses.filter(a => a.real_estate_object_id !== null).map(a => a.real_estate_object_id),
    target: $existingReos
})

sample({
    source: $addressSearch,
    clock: $multiSelectedAddresses.updates,
    filter: (filters) => isId(filters.town_id) && isId(filters.street_id) && filters.house_in?.length > 0,
    fn: (filters, data) => {
        const houses = parseHouses(filters.house_in)
        const missingAddresses = []
        for (const house of houses) {
            if (!data?.find(address => address.house === house)) {
                missingAddresses.push({town_id: filters.town_id, town_distr_id: filters.town_distr_id ?? null, street_id: filters.street_id, house, corpus: filters?.corpus ?? ''})
            }
        }
        return missingAddresses
    },
    target: $missAddresses
})

sample({
    source: $createdMissAddresses,
    clock: $missAddresses.updates,
    filter: (created, missed) => missed.length > 0 && created.length === 0,
    target: createMissingAddressesEv
})

sample({
    source: $createdMissAddresses,
    clock: $missAddresses.updates,
    filter: (created, missed) => created.length === 0 && missed.length > 0,
    fn: () => false,
    target: $missAddrsCreatedStatus
})

sample({
    source: {searched: $multiSelectedAddresses, created: $createdMissAddresses},
    clock: $missAddresses.updates,
    filter: ({searched, created}, missed) => (searched.length > 0 || created.length > 0) && missed.length === 0,
    fn: () => true,
    target: $missAddrsCreatedStatus
})

sample({
    source: {filters: $addressSearch, missing: $missAddresses},
    clock: createMissingAddressesEv,
    fn: ({filters, missing}) => {
        const newAddresses = []
        let idCounter = -1
        if (filters.house_in.includes('-') || filters.house_in.includes(',')) {
            for (const address of missing) {
                newAddresses.push({
                    ...address,
                    corpus: address?.corpus ?? '',
                    town_distr_id: address?.town_distr_id ?? null,
                    is_actual: true,
                    actual_from: dayjs().format(),
                    id: idCounter
                })
                idCounter--
            }
        } else {
            newAddresses.push({
                ...filters,
                corpus: filters?.corpus ?? '',
                town_distr_id: filters?.town_distr_id ?? null,
                house: filters.house_in,
                is_actual: true,
                actual_from: dayjs().format(),
                id: idCounter
            })
        }
        for (const add of newAddresses) {
            delete add['house_in']
        }
        return newAddresses
    },
    target: $createdMissAddresses
})

sample({
    source: $missAddresses,
    clock: $createdMissAddresses.updates,
    fn: (missed, created) => {
        const newState = [...missed]
        const createdHouses = created.map(c => c.house)
        return newState.filter(i => !createdHouses.includes(i.house))
    },
    target: $missAddresses
})

sample({
    clock: [resetSelectionEv, resetFiltersEv],
    fn: () => false,
    target: setCreateReoEmbeddedEv
})

getReosFx.doneData.watch((response) => {
    if (response.data?.length === 0) {
        notification.info({message: i18n.t('По данным параметрам ничего не найдено')})
    }
})
