import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const BankCommissionListGate = createGate()
export const BankCommissionViewGate = createGate()
export const BankCommissionEditGate = createGate()
export const BankCommissionCreateGate = createGate()

export const submitCreateBankCommissionEv = createEvent()
export const submitEditBankCommissionEv = createEvent()
export const deleteBankCommissionEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
