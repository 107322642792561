import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {InputNumber} from "antd";
import {
    $editBankCommissionStatus,
    $selectedBankCommission,
    BankCommissionEditGate, getBankCommissionByIdFx,
    submitEditBankCommissionEv
} from "../../../models/dictionaryBankCommissionModel/index.js";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryBankCommissionModel/index.js";

export default function EditBankCommission() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedBankCommission)

    const [bankCommissionForm] = Form.useForm()

    const submit = useUnit(submitEditBankCommissionEv)

    const loading = useUnit(getBankCommissionByIdFx.pending)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editBankCommissionStatus)
    const genitive_form = t(`breadcrumbs.genitive.bank-commission`);


    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/bank-commission?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            bankCommissionForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'short_name_kz', 'short_name_ru'])

    return <>
        <BankCommissionEditGate id={id}/>
        <Form form={bankCommissionForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Card loading={loading} title={t('Свойства Комиссии')} size={'small'}>
                <Row justify={'flex-start'} gutter={[16, 16]}>
                    <Col span={5}>
                        <Form.Item name={'commission'}
                                   label={t(`Комиссия банка, %`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <InputNumber style={{width: '100%'}} decimalSeparator={','} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'reimbursement'}
                                   label={t(`Возмещение в ЕРЦ, %`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <InputNumber style={{width: '100%'}} decimalSeparator={','} />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    bankCommissionForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            bankCommissionForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            bankCommissionForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
