import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
	$selectedAgreementFile,
	$selectedAgreementFileCells, acceptFileEv,
	getAgreementsFileCellsFx, rejectFileEv, AgreementsFileCellsGate, downloadErrorFileEv
} from '../../../models/uploadAgreementsModel';
import {usePaginationControl} from '../../../hooks/usePaginationControl.js';
import {useServerSort} from '../../../hooks/useServerSort.js';
import dayjs from 'dayjs';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import Card from 'antd/es/card';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import ViewDictionaryItemCommon
	from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import styles from '../Upload.module.css';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import React, {useState} from 'react';
import Switch from 'antd/es/switch';
import Button from 'antd/es/button';
import {Popconfirm} from "antd";
import Collapse from "antd/es/collapse";
import TextArea from "antd/es/input/TextArea.js";
import {useServerSortPage} from '../../../hooks/useServerSortPage.js';
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";

export default function ViewAccountsFileCells() {
	const navigate = useNavigate()
	const {t} = useTranslation()
	const selectedFile = useUnit($selectedAgreementFile)
	const {data, count} = useUnit($selectedAgreementFileCells)
	const loading = useUnit(getAgreementsFileCellsFx.pending)
	const accept = useUnit(acceptFileEv)
	const reject = useUnit(rejectFileEv)
	const downloadErrorFile = useUnit(downloadErrorFileEv)

	const {id} = useParams()
	const [search, setSearch] = useSearchParams()

	const [curPage, limit, _field, _order, onChangeTable] = useServerSortPage()

	const [valid, setValid] = useState(!!(search.get('validation') && search.get('validation')==='true'))
	const onValidChange = (checked) => {
		setValid(!!checked)
		search.set('validation', checked)
		setSearch(search)
	}

	const confirmDisabled = !(selectedFile?.status === 'file processed')
	const downloadErrors = selectedFile?.error_row_count > 0

	const textError = (data, value) => {
		const e = data || ''
		const se = e.split('|')
		const te = se.shift()
		const ie = se.join(', ')
		const error = t(`import.errors.${te}`)
		if (value === undefined)
			return <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
		return <div>
			<div>{value}</div>
			<div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
		</div>
	}

	const columns = [
		{
			title: t('№ строки'),
			dataIndex: 'row_index',
			align: 'center',
			key: 'row_index',
			sorter: true,
		},
		{
			title: t('Статус'),
			dataIndex: 'row_is_valid',
			align: 'center',
			key: 'row_is_valid',
			sorter: true,
			onCell: (record) => ({className: !record?.row_is_valid && styles.invalid}),
			render: (value, record) => !record?.row_is_valid ?
				[...new Set(((record?.row_error || '').split('\n') || []))].map(e => textError(e)) :
				<CheckOutlined/>
		},
		{
			title: t('Код Услуги'),
			dataIndex: 'service_id_value',
			align: 'center',
			key: 'service_id_value',
			sorter: true,
			onCell: (record) => ({className: record?.service_id_error && styles.invalid}),
			render: (value, record) => record?.service_id_error ?
				textError(record?.service_id_error, value) : value
		},
		{
			title: t('Код ЛС'),
			dataIndex: 'account_id_value',
			align: 'center',
			key: 'account_id_value',
			sorter: true,
			onCell: (record) => ({className: record?.account_id_error && styles.invalid}),
			render: (value, record) => record?.account_id_error ?
				textError(record?.account_id_error, value) : value
		},
		{
			title: t('№ договора поставщика с потребителем'),
			dataIndex: 'external_number_value',
			align: 'center',
			key: 'external_number_value',
			sorter: true,
			onCell: (record) => ({className: record?.external_number_error && styles.invalid}),
			render: (value, record) => record?.external_number_error ?
				textError(record?.external_number_error, value) : value
		},

	]

	const items = [
		{
			key: 'total_rows',
			label: t('Всего строк'),
			children: selectedFile?.total_row_count
		},
		{
			key: 'error_row_count',
			label: t('Ошибочных строк'),
			children: selectedFile?.error_row_count
		},
		{
			key: 'imported_row_count',
			label: t('Импортировано строк'),
			children: selectedFile?.imported_row_count
		},
	]

	const dateItems = [
		{
			key: 'load_date',
			label: t('Дата загрузки'),
			children: selectedFile?.created_at && dayjs(selectedFile?.created_at).format('DD.MM.YYYY HH:mm:ss')
		},
		{
			key: 'to_list',
			children: <Button
				onClick={() => navigate("../..", {relative: "path", replace: true})}
			>{t('К списку')}</Button>
		},
		{
			key: 'confirm',
			children: <Popconfirm
				title={t('Вы уверены, что хотите подтвердить этот файл?')}
				description={'Внесены в Систему будут только валидные строки'}
				onConfirm={() => {
					accept(id)
					navigate("../..", {relative: "path", replace: true})
				}}
				okText={t('Да')}
			>
				<Button type={'primary'} disabled={confirmDisabled}>{t('Подтвердить файл')}</Button>
			</Popconfirm>
		},
		{
			key: 'cancel',
			children: <Popconfirm
				title={t('Вы уверены, что хотите отклонить этот файл?')}
				onConfirm={() => {
					reject(id)
					navigate("../..", {relative: "path", replace: true})
				}}
				okText={t('Да')}
				okButtonProps={{danger: true}}
			>
				<Button danger>{t('Отклонить файл')}</Button>
			</Popconfirm>
		},
		{
			key: 'downloadErrors',
			children: <Button
				title={'В разработке'}
				disabled={!downloadErrors}
				onClick={() => downloadErrorFile(id)}
			>{t('Скачать файл с ошибками')}</Button>
		},
	]

	return <Card title={`${t('Просмотр строк файла')} ${selectedFile?.file_name}`}>
		<AgreementsFileCellsGate id={id} search={search} />
		<ViewDictionaryItemCommon showNav={false} items={items} itemsDates={dateItems}/>
		<Divider/>
		{selectedFile?.error ? <Collapse
			size="small"
			items={[
				{
					key: '1',
					label: t('Лог ошибок обработки файла'),
					children: <TextArea rows={40} value={selectedFile?.error} />,
				},
			]}
		/> : null}
		<Divider />
		<div style={{display: 'flex', gap: 4, marginBottom: 24}}>
			<Switch checked={valid} onChange={onValidChange} checkedChildren={'Валидные'} unCheckedChildren={'Ошибочные'} />
		</div>
		<Table dataSource={data}
			   loading={loading}
			   columns={columns}
			   onChange={(pagination, _filters, sorter) => {
				   onChangeTable({page: pagination.current, limit: pagination.pageSize, sort_field: sorter?.field ?? null, sort_order: sorter?.order ?? null})
			   }}
			   pagination={{
				   ...paginationConfig,
				   pageSize: parseInt(limit),
				   current: parseInt(curPage),
				   total: count
			   }}
			   size={'small'}
			   className={'table-container'}
		/>
	</Card>
}
