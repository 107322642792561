import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import {
    $reoList,
    $selectedReo,
    changeSelectedREOEv,
    getREOListFx,
    $filters, REOFilesGate, downloadFilesPerREOEv, downloadSingleREOFileEv, downloadFilesPerREOFx
} from '../../../models/taskEPDPerRESModel';
import {useUnit} from 'effector-react';
import {Tag} from 'antd';
import Tooltip from 'antd/es/tooltip';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import dayjs from 'dayjs';
import {SaveOutlined} from '@ant-design/icons';
import REOFilters from './REOFilters.jsx';
import {$infoMaps} from '../../../models/infoModel/index.js';
import Space from 'antd/es/space';

function getSelectedObjectAddress (id, data, maps) {
    const obj = data.find(d => d.id === id)
    return `г. ${maps.townsMap[obj.town_id]}, ${obj.towd_distr_id ? `(${maps.districtsMap[obj.towd_distr_id]}),` : ''} ${maps.streetsMap[obj.street_id]}, ${obj.house}, ${obj.corpus ? `корпус ${obj.corpus}` : ''}`
}

export default function FileManager() {
    const {t} = useTranslation()
    const maps = useUnit($infoMaps)

    const {data, count} = useUnit($reoList)
    const loading = useUnit(getREOListFx)
    const onChangeSelect = useUnit(changeSelectedREOEv)
    const selected = useUnit($selectedReo)

    const filters = useUnit($filters)



    const downloadFiles = useUnit(downloadFilesPerREOEv)
    const downloadSingleFile = useUnit(downloadSingleREOFileEv)
    const dloadLoading = useUnit(downloadFilesPerREOFx.pending)

    const onDownload = () => {
        downloadFiles({ids: selected, filters, report_date: dayjs(filters.pdf_month).format()})
    }

    const columns = [
        {
            title: t('Код'),
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: t('Адрес'),
            key: 'address',
            render: (record) => `г. ${maps.townsMap[record.town_id]}, ${record.towd_distr_id ? `(${maps.districtsMap[record.towd_distr_id]}),` : ''} ${maps.streetsMap[record.street_id]}, ${record.house}, ${record.corpus ? `корпус ${record.corpus}` : ''}`
        },
        {
            title: t('Файл'),
            dataIndex: 'has_pdf',
            key: 'file',
            align: 'center',
            render: (has_pdf, record) => has_pdf && <Tooltip title={t('Скачать')}>
                <Button ghost type={'primary'}
                        onClick={() => downloadSingleFile(record.pdf_id)}
                >
                    <SaveOutlined />
                </Button>
            </Tooltip>,
        }
    ]

    const onCloseTag = (e) => {
        const data = selected.filter(item => item !== e)
        onChangeSelect(data)
    }


    return <>
        <REOFilesGate />
        <Space direction={'vertical'} style={{width: '100%'}}>
        <REOFilters forDownload/>
        <Card>
            <Divider orientationMargin={0} orientation={'left'}>{t('Выбранные объекты недвижимости')}</Divider>
            <Row>
                {selected.length > 0
                    ? selected.map((i, idx) => <Tag
                            closable
                            onClose={(e) => {
                                e.preventDefault()
                                onCloseTag(i);
                            }}
                            key={idx}
                        >
                            {getSelectedObjectAddress(i, data, maps)}
                        </Tag>
                    )
                    : filters.select_all === true
                        ? t('Все объекты недвижимости') + ` (${count})`
                        : t('Нет')
                }
            </Row>

        </Card>
        <Card>


            <div style={{position: 'relative'}}>
                <Row
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        top: '6px',
                        left: '25px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    justify={'space-between'}
                >
                    <Tooltip
                        title={!(selected.length > 0) ? t('Необходимо выбрать Объекты недвижимости') : null}>
                        <Button type={'primary'}
                                onClick={onDownload}
                                disabled={!(selected.length > 0)}
                                loading={dloadLoading}
                        >
                            {t('Сформировать ЕПД по выбранным объектам за выбранный месяц')}
                        </Button>
                    </Tooltip>
                </Row>
                <DictionaryProTable
                    style={{paddingTop: '30px'}}
                    data={data}
                    count={count}
                    initColumns={columns}
                    loading={loading}
                    showActions={false}
                    rowKey={'id'}
                    rowSelection={{
                        onChange: onChangeSelect,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selected
                    }}
                    pagination={false}
                />
            </div>
        </Card>
        </Space>
    </>
}
