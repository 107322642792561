import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $correctionPeriod,
    $createSrvModal, $psData,
    $psTarifsSL, $selectedAccMeta,
    $srvCreateEditDone, $tarifAlgoMap, $tarifCreated, createTariffEv, getPSTarifsEv,
    resetSrvCreateModalEv, submitAddSingleCorrEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {$dictsSelects, $info} from '../../../../models/infoModel/index.js';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import Switch from 'antd/es/switch';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import message from 'antd/es/message';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import {isId} from '../../../../utils/helpers.js';
import PrefsFormTable from '../components/PrefsFormTable.jsx';

const squareTypeEnum = {
    'no_square': 'Обслуживаемая площадь',
    'full_square': 'Общая площадь',
    'living_square': 'Жилая площадь',
    'heated_square': 'Отапливаемая площадь',
    'service_square': 'Обслуживаемая площадь',
}

export default function AddSrvCorrModal() {
    const {t} = useTranslation()
    const open = useUnit($createSrvModal)
    const selectedAcc = useUnit($selectedAccMeta)

    const [srvForm] = Form.useForm();

    const provider_id = Form.useWatch('provider_id', srvForm)
    const service_id = Form.useWatch('service_id', srvForm)
    const selectedTarif = Form.useWatch('tarif_id', srvForm)
    const peopleQuant = Form.useWatch('people_quantity', srvForm)

    const maxPeople = useMemo(() => {
        if (peopleQuant && peopleQuant >= 0) {
            return peopleQuant
        } else {
            return 0
        }
    }, [selectedAcc, peopleQuant])

    const selPeriod = useUnit($correctionPeriod)
    const currentDate = useStoreMap($info, i => i.current_date)
    const currMonth = dayjs(currentDate)
    const nextMonth = dayjs(currentDate).endOf('month').add(1)

    const dateFromValue = Form.useWatch('date_from', srvForm)
    const defaultDate = useMemo(() => {
        if (selPeriod === 'current') {
            return currMonth
        } else if (selPeriod === 'next') {
            return nextMonth
        }
    }, [selPeriod, currentDate])

    useEffect(() => {
        if (!dateFromValue) {
            srvForm.setFieldsValue({date_from: defaultDate})
        }
    }, [dateFromValue, defaultDate]);

    const allowedDates = useMemo(() => {
        const min = selPeriod === 'current' ? currMonth.startOf('month') : nextMonth.startOf('month')
        const max = selPeriod === 'current' ? currMonth.endOf('month') : nextMonth.endOf('month')
        return {max, min}
    }, [selPeriod, currentDate])

    const submit = useUnit(submitAddSingleCorrEv)
    const reset = useUnit(resetSrvCreateModalEv)
    const status = useUnit($srvCreateEditDone)

    const getPSTarifs = useUnit(getPSTarifsEv)
    const psInfo = useUnit($psData)
    const psTarifs = useUnit($psTarifsSL)
    const createTariff = useUnit(createTariffEv)
    const createdTariffId = useUnit($tarifCreated)

    const tarAlgoMap = useUnit($tarifAlgoMap)

    const {showPeople, showSquare} = useMemo(() => {
        return {
            showPeople: tarAlgoMap[selectedTarif]?.with_people_quantity,
            showSquare: tarAlgoMap[selectedTarif]?.with_square
        }
    }, [selectedTarif, tarAlgoMap])

    useEffect(() => {
        if (showPeople && selectedAcc && selectedAcc?.people_quantity >= 0) {
            srvForm.setFieldValue('people_quantity', selectedAcc?.people_quantity)
        } else {
            srvForm.resetFields(['people_quantity'])
        }

        if (showSquare && selectedAcc && selectedAcc?.service_square >= 0) {
            srvForm.setFieldValue('service_square', selectedAcc?.service_square)
        } else {
            srvForm.resetFields(['service_square'])
        }
    }, [showPeople, showSquare, selectedAcc]);

    useEffect(() => {
        if (!showPeople) {
            srvForm.setFieldValue('preferences', [])
        }
    }, [showPeople]);

    const {provOpts, srvOpts, srvUnitOpts} = useStoreMap($dictsSelects, sel => ({
        provOpts: sel.providers,
        srvOpts: sel.services,
        srvUnitOpts: sel.service_units
    }))

    const onCancel = () => {
        srvForm.resetFields()
        reset()
    }

    const onFinish = (values) => {
        submit(values)
        onCancel()
    }

    const onSubmitTariff = async () => {
        try {
            let values

            values = await srvForm.validateFields(['new_tarif'])
            const {tarif_value, tarif_service_unit_id} = values['new_tarif']

            if (tarif_value && tarif_service_unit_id) {
                createTariff({tarif_value, tarif_service_unit_id})
            }
        } catch (e) {
            message.error(t('Заполните необходимые поля'))
        }

    }

    useEffect(() => {
        if (status) {
            onCancel()
        }
    }, [status])

    useEffect(() => {
        srvForm.setFieldValue('tarif_id', null)
        if (open && provider_id && service_id) {
            getPSTarifs({provider_id, service_id})
        }
    }, [provider_id, service_id, open]);

    useEffect(() => {

        if (createdTariffId && provider_id && service_id) {
            srvForm.setFieldValue('tarif_id', createdTariffId)
            getPSTarifs({provider_id, service_id})
        }
    }, [createdTariffId, provider_id, service_id]);

    const switchIsNotAccrued = (is_not_accrued) => {
        if (is_not_accrued === false) {
            srvForm.setFieldValue('is_not_balance_transfer', false)
        }
    }

    const switchIsNotBalanceTransfer = (is_not_balance_transfer) => {
        if (is_not_balance_transfer === true) {
            srvForm.setFieldValue('is_not_accrued', true)
        }
    }

    const prefDataSource = useMemo(() => {
        if (selectedAcc && provider_id && service_id) {
            const prefs = selectedAcc?.preferences?.filter(p => p.provider_id === provider_id && p.service_id === service_id)
            return prefs?.length > 0 ? [{id: -1}, ...prefs] : [{id: -1}]
        } else return []
    }, [selectedAcc, provider_id, service_id])

    const onValsChange = (cur) => {
        if (Object.hasOwn(cur, 'service_id') && !isId(cur?.service_id)) {
            srvForm.setFieldValue('date_from', allowedDates.min)
        } else if (Object.hasOwn(cur, 'provider_id') && !isId(cur?.provider_id)) {
            srvForm.setFieldValue('date_from', allowedDates.min)
            srvForm.resetFields(['service_id', 'tarif_id'])
        } else if (Object.hasOwn(cur, 'provider_id') && isId(cur?.provider_id)) {
            srvForm.resetFields(['service_id', 'tarif_id'])
        }
    }

    return <Modal open={!!open}
                  title={t('Добавление услуги')}
                  onCancel={onCancel}
                  onOk={() => srvForm.submit()}
                  okText={t('Сохранить')}
                  okButtonProps={{disabled: selectedTarif === -1}}
                  width={'60vw'}
                  destroyOnClose
    >
        <Form layout={'vertical'} form={srvForm} onFinish={onFinish} onValuesChange={onValsChange}>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item name={'provider_id'} label={t('Поставщик')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select
                            options={provOpts}
                            style={{width: '100%'}}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'service_id'} label={t('Услуга')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select
                            options={provider_id ? srvOpts.filter(s => s?.providers?.includes(provider_id)) : []}
                            disabled={!isId(provider_id)}
                            style={{width: '100%'}}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item name={'tarif_id'} label={t('Тариф')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                               tooltip={!(provider_id && service_id) ? t('Выберите поставщика и услугу') : null}
                    >
                        <Select
                            options={[...psTarifs, {label: t('Создать'), value: -1, emoji: true}]}
                            style={{width: '100%'}}
                            disabled={!(provider_id && service_id)}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
                {showPeople && <Col span={6}>
                    <Form.Item name={'people_quantity'} label={t('Кол-во проживающих')}>
                        <InputNumber placeholder={'-'} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>}
                {showSquare && <Col span={6}>
                    <Form.Item name={'service_square'} label={t(squareTypeEnum[psInfo?.square_type] ?? 'Обслуживаемая площадь')}>
                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>}
                <Col span={6}>
                    <Form.Item name={'sum_saldo_begin'} label={t('Начальное cальдо')} initialValue={0}>
                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>
            {selectedTarif === -1 && <>
                <Form.Item name={['new_tarif', 'tarif_value']} label={t('Значение тарифа')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}} />
                </Form.Item>
                <Form.Item name={['new_tarif', 'tarif_service_unit_id']} label={t('Единица измерения')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={srvUnitOpts}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}/>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type={'primary'} onClick={onSubmitTariff}>{t('Сохранить')}</Button>
                        <Button danger
                                onClick={() => {
                                    srvForm.resetFields(['tarif_id', ['new_tarif', 'tarif_value'], ['new_tarif', 'tarif_service_unit_id']])
                                }}
                        >
                            {t('Отмена')}
                        </Button>
                    </Space>
                </Form.Item>
            </>}
            <Row gutter={[16, 0]}>
                <Col span={6}>
                    <Form.Item name={'date_from'} label={t('Действует с')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <DatePicker minDate={allowedDates.min} maxDate={allowedDates.max} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Row gutter={[16, 0]}>
                        <Col span={8}>
                            <Form.Item name={'is_not_accrued'} label={t('Не начислять')} valuePropName={'checked'}>
                                <Switch onChange={switchIsNotAccrued}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'is_not_balance_transfer'} label={t('Не переводить')} valuePropName={'checked'}>
                                <Switch onChange={switchIsNotBalanceTransfer}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'is_not_printed'} label={t('Не печатать')} valuePropName={'checked'}>
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                {psInfo?.use_external_number && <Col span={6}>
                    <Form.Item name={'external_number'} label={t('№ договора пост-ка с потр-ем')}>
                        <Input/>
                    </Form.Item>
                </Col>}
                {psInfo?.is_iin_provided && <Col span={6}>
                    <Form.Item name={'iin'} label={t('ИИН потребителя')}>
                        <Input/>
                    </Form.Item>
                </Col>}
            </Row>
            <Form.Item hidden name={'preferences'}/>
        </Form>
        {
            (isId(provider_id) && isId(service_id)) && showPeople &&
            <PrefsFormTable prefs={prefDataSource} maxPeople={maxPeople} srvForm={srvForm} srvId={service_id ?? null}/>
        }
    </Modal>
}
