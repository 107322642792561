import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $bankAccountsByProvider,
    $providerServices,
    $selectedProvService,
    deleteSrvEv,
    selectProvServiceEv,
} from '../../../../models/dictionaryProvidersModel/index.js';
import {$info, $userPerms} from '../../../../models/infoModel/index.js';
import Table from 'antd/es/table';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Divider from 'antd/es/divider';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import Space from "antd/es/space";
import Popover from "antd/es/popover";
import ButtonGroup from 'antd/es/button/button-group';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {
    BuildOutlined, CalculatorOutlined,
    CheckOutlined,
    DeleteOutlined,
    EditOutlined,
    MinusOutlined, PrinterOutlined
} from '@ant-design/icons';
import ServiceCreateEdit from "./ServiceCreateEdit.jsx";

function RenderIsNot(p, f, t, current_date, title) {
    if (!p)
        return <div style={{textAlign: 'center'}}><MinusOutlined style={{color: 'gray'}}/></div>

    const data = []
    const fd = dayjs(f)
    const td = dayjs(t)
    const bf = f && fd.isSameOrBefore(current_date)
    const at = t && td.isSameOrAfter(current_date)
    const color = ((bf || !f) && (at || !t)) ? 'red' : 'gold'
    if (f) data.push(`с ${fd.format('DD.MM.YYYY')}`)
    if (t) data.push(`по ${td.format('DD.MM.YYYY')}`)
    return <div style={{textAlign: 'center'}}>
        <Popover content={data.join(' ')} title={title} fresh={true} mouseLeaveDelay={1}>
            <CheckOutlined style={{color}}/>
        </Popover>
    </div>
}

const crossStyle = {
    position: 'absolute',
    width: '32px',
    borderTop: '1px solid red',
    display: 'inline-block',
    zIndex: '1',
    left: '8px',
    top: '12px',
}

export default function ProvServForm({isView}) {
    const {t} = useTranslation()
    const [srvParamsForm] = Form.useForm()
    const providerAccs = useUnit($bankAccountsByProvider)
    const perms = useUnit($userPerms)

    const current_date = dayjs(useStoreMap($info, info => info?.current_date))
    // const service_units = useStoreMap($info, info => info?.service_unit)

    const services = useUnit($providerServices)
    const selectedService = useUnit($selectedProvService)
    const selectService = useUnit(selectProvServiceEv)
    const deleteService = useUnit(deleteSrvEv)

    // useEffect(() => {
    //     if (selectedService) {
    //         const prepared = {...selectedService}
    //         for (const [key, value] of Object.entries(prepared)) {
    //             if (key.includes('from') || key.includes('to')) {
    //                 prepared[key] = value ? dayjs(value) : null
    //             }
    //         }
    //         srvParamsForm.setFieldsValue(prepared)
    //     } else if (!selectedService) {
    //         srvParamsForm.resetFields()
    //     }
    // }, [selectedService]);

    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'service_id',
            sorter: (a, b) => a.service_id - b.service_id,
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'services'}/>,
        },
        {
            title: t('Платн.'),
            width: 50,
            dataIndex: 'is_paid_service',
            sorter: (a, b) => a.is_paid_service - b.is_paid_service,
            render: (val) => <div style={{textAlign: 'center'}}>
                {val ? <CheckOutlined/> : <MinusOutlined/>}
            </div>,
        },
        {
            title: t('Расчетный счет'),
            width: '180px',
            dataIndex: 'bank_account_id',
            sorter: (a, b) => a.bank_account_id - b.bank_account_id,
            render: id => {
                const acc = providerAccs.find(x => x.id === id)
                let data
                if (!acc)
                    data = <div style={{textAlign: 'center'}}><MinusOutlined/></div>
                else {
                    data = <div>
                        <Popover
                            title={t('Расчетный счет') + ' ' + acc.account_number}
                            content={[
                                <MappedDictItem id={acc.bank_id} type={'banks'} link={false}
                                                withID={false}
                                                wrap={false}/>,
                                <br/>,
                                <MappedDictItem id={acc.bank_account_type_id}
                                                type={'bank-account-type'}
                                                link={false} withID={false} wrap={false}/>
                            ]}
                        >
                            {acc.account_number}
                        </Popover>
                    </div>
                }
                return <div>{data}</div>
            }
        },
        // {
        //     title: t('Алгоритм'),
        //     dataIndex: 'algorithm_id',
        //     render: (val) => isId(val) ? <MappedDictItem
        //         id={val} type={'algorithm'} link={false} withID={true} wrap={false}
        //     /> : <div style={{textAlign: 'center'}}><MinusOutlined/></div>,
        // },
        {
            title: t('Опции пени'),
            // dataIndex: 'is_penalty_priority',
            render: (val, r) => <Popover
                title={t('Опции пени')}
                content={<>
                    {r.is_penalty_charge ?
                        t('Пеня начисляется') : t('Пеня не начисляется')
                    }
                    <br/>
                    {r.is_penalty_priority ?
                        t('Пеня приоритетна') : t('Пеня не приоритетна')
                    }
                    <br/>
                    {r.penalty_percent || 0} %
                    {/*<br/>*/}
                    {/*{t('День начисления пени')}: {r.penalty_calculation_date || 1}*/}
                </>}
            >
                <Space style={{textAlign: 'center'}}>
                    {r.is_penalty_charge ? <CheckOutlined/> : <MinusOutlined/>}
                    {r.is_penalty_priority ? <CheckOutlined/> : <MinusOutlined/>}
                    <span>{r.penalty_percent || 0} %</span>
                    {/*<span>{r.penalty_calculation_date || 1}-й</span>*/}
                </Space>
            </Popover>,
        },
        {
            title: t('Тарифы'),
            width: '110px',
            render: (_, r) => {
                const data = (r.tarifs || []).filter(t => !t.is_archived)
                const max = data.reduce((a, b) => a > b.value ? a : b.value, 0)
                const maxData = data.find(item => item.value === max)
                return <div style={{textAlign: 'center'}}>
                    <Popover
                        title={t('Тарифы')}
                        content={data.map(item => <Row gutter={[16, 16]}>
                            <Col>
                                {item.value}
                            </Col>
                            <Col>
                                {
                                    <MappedDictItem id={item?.service_unit_id} withID={false}
                                                    type={'service-units'} link={false}/>
                                }
                            </Col>

                        </Row>)}
                    >
                        <Row gutter={[16, 16]}>
                            <Col>
                                {maxData?.value ?? ''}
                            </Col>
                            <Col>
                                {<MappedDictItem id={maxData?.service_unit_id} withID={false}
                                                 type={'service-units'} link={false}/>}
                            </Col>
                        </Row>

                    </Popover>
                </div>
            }
        },
        {
            title: t('Оказание услуги'),
            dataIndex: 'date_from',
            width: '160px',
            sorter: (a, b) => dayjs(a.date_from) - dayjs(b.date_from),
            render: (date, r) => {
                const data = []
                if (r.date_from) data.push(<Col span={12}>
                    с {dayjs(r.date_from).format('DD.MM.YY')}
                </Col>)
                if (r.date_to) data.push(<Col span={12}>
                    по {dayjs(r.date_to).format('DD.MM.YY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><MinusOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: <Tooltip title={t('Не начислять')}>
                <div style={{textAlign: 'center'}}>
                    <span style={crossStyle}/>
                    {/*<CloseOutlined style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    zIndex: 1,*/}
                    {/*    left: '4px',*/}
                    {/*    color: 'red',*/}
                    {/*    top: '7px',*/}
                    {/*    opacity: 0.5,*/}
                    {/*}}/>*/}
                    <CalculatorOutlined/>
                </div>
            </Tooltip>,
            width: 50,
            dataIndex: 'is_not_accrued',
            render: (v, r) => {
                return RenderIsNot(
                    r.is_not_accrued,
                    r.not_accrued_from,
                    r.not_accrued_to,
                    current_date,
                    t('Не начислять'),
                )
            }
        },
        {
            title: <Tooltip title={t('Не переводить сальдо')}>
                <div style={{textAlign: 'center'}}>
                    <span style={crossStyle}/>
                    {/*<CloseOutlined style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    zIndex: 1,*/}
                    {/*    left: '4px',*/}
                    {/*    color: 'red',*/}
                    {/*    top: '7px',*/}
                    {/*    opacity: 0.5,*/}
                    {/*}}/>*/}
                    <BuildOutlined/>
                </div>
            </Tooltip>,
            dataIndex: 'is_not_balance_transfer',
            width: 50,
            render: (v, r) => {
                return RenderIsNot(
                    r.is_not_balance_transfer,
                    r.not_balance_transfer_from,
                    r.not_balance_transfer_to,
                    current_date,
                    t('Не переводить сальдо'),
                )
            }
        },
        {
            title: <Tooltip title={t('Не печатать')}>
                <div style={{textAlign: 'center'}}>
                    <span style={crossStyle}/>
                    {/*<CloseOutlined style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    zIndex: 1,*/}
                    {/*    left: '4px',*/}
                    {/*    color: 'red',*/}
                    {/*    top: '7px',*/}
                    {/*    opacity: 0.5,*/}
                    {/*}}/>*/}
                    <PrinterOutlined/>
                </div>
            </Tooltip>,
            width: 50,
            dataIndex: 'is_not_printed',
            render: (v, r) => {
                return RenderIsNot(
                    r.is_not_printed,
                    r.not_printed_from,
                    r.not_printed_to,
                    current_date,
                    t('Не печатать'),
                )
            }
        },
        {
            dataIndex: 'service_id',
            width: 50,
            hidden: isView,
            render: id => <ButtonGroup size={'small'}>
                {perms?.provider_service?.change && <Tooltip title={t('Редактировать')}>
                    <Button onClick={() => selectService(id)} icon={<EditOutlined/>}/>
                </Tooltip>}
                {/*<Popconfirm title={t('Вы уверены, что хотите удалить эту запись')}*/}
                {/*            okText={t('Да')}*/}
                {/*            onConfirm={() => deleteService(id)}*/}
                {/*            placement={'left'}*/}
                {/*>*/}
                {/*    <Tooltip title={t('Удалить')}>*/}
                {/*        <Button danger icon={<DeleteOutlined/>}/>*/}
                {/*    </Tooltip>*/}
                {/*</Popconfirm>*/}
            </ButtonGroup>
        }
    ]
    return <>
        {!isView && <>
            <ServiceCreateEdit srvParamsForm={srvParamsForm}/>
            <Divider/>
        </>}
        <Table dataSource={services}
               columns={columns}
               bordered
               rowKey={'service_id'}
               className={'table-container'}
               pagination={false}
               size={'small'}
               rowClassName={(record) => record.id === selectedService?.id ? 'selectedRowObject' : null}
        />
    </>
}
