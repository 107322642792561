import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedAccount,
    submitEditAccountEv,
    $editAccountStatus,
    $selectedAccountReEsObjFormatted,
    $editedExternalNumber,
    $afterCreateAction,
    setAfterCreateActionEv,
    resetAfterCreationAttrs,
    $selectedAccountReEsObj
} from '../../../models/dictionaryAccountsModel/index.js';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {$createdCorrectionId, createCorrectionEv} from "../../../models/dictionaryCorrectionModel/index.js";
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Switch from 'antd/es/switch';
import Divider from 'antd/es/divider';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import useFormChanged from '../../../hooks/useFormChanged.js';
import InputNumber from "antd/es/input-number";
import ShowAddressCard from '../../../components/AddressReoSearchForms/ShowAddressCard.jsx';
import {binValidator} from "../../../utils/validateBIN.js";
import InputMask from "react-input-mask";
import AddressREOSearchForm
    from '../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import CreateREOEmbeddedFrom
    from '../../../components/Embedded/CreateREOEmbeddedForm/CreateREOEmbeddedFrom.jsx';
import {$createReoEmbeddedOpen} from '../../../models/dictionaryEmbeddedFormsModel/index.js';

export default function EditAccount({disabled}) {
    const {id} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const [changeReo, setChangeReo] = useState(false)
    const createReoOpen = useUnit($createReoEmbeddedOpen)

    const createCorrection = useUnit(createCorrectionEv)
    const createdCorrectionId = useUnit($createdCorrectionId)

    const selectedAccount = useUnit($selectedAccount)
    const selectedReSObjRaw = useUnit($selectedAccountReEsObj)
    const selectedReSObj = useUnit($selectedAccountReEsObjFormatted)

    // const onEditExternalNumber = useUnit(editExternalNumberEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)


    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const submit = useUnit(submitEditAccountEv)

    const status = useUnit($editAccountStatus)

    const external = useUnit($editedExternalNumber)

    useEffect(() => {
        if (status) {
            if (action === 'list') {
                resetAttrs()
                navigate(`/dictionaries/accounts?code_name=${selectedAccount.id}`)
            }
            else if (action === 'edit') {
                resetAttrs()
                setChangeReo(false)
            }
        }
    }, [status, action]);

    useEffect(() => {
        if (status) {
            if (createdCorrectionId) {
                resetAttrs()
                navigate(`/corrections/edit/${createdCorrectionId}`)
            }
        }
    }, [status, createdCorrectionId]);

    useEffect(() => {
        if (selectedAccount) {
            const data = {
                ...selectedAccount,
                birthdate: selectedAccount?.birthdate ? dayjs(selectedAccount.birthdate) : null,
                created_at: selectedAccount?.created_at ? dayjs(selectedAccount.created_at) : null,
                status: selectedAccount.status === 'active' ? true : false
            }
            accountForm.setFieldsValue(data)
        }
    }, [selectedAccount]);

    const [check, changedFields] = useFormChanged(
        selectedAccount,
        [
            'name_kz',
            'name_ru',
            'full_square',
            'living_square',
            'heated_square',
            'people_quantity',
            // 'iin', 'name_ru', 'flat', 'full_square', 'living_square', 'heated_square', 'people_quantity', 'room_count', 'floor', 'phone', 'mobile_phone', 'email', 'is_email_delivery', 'aesbyt_account', 'energy_account',
        ]
    )

    const onSubmit = (values) => {
        for (const [key, value] of Object.entries(values)) {
            if (key === 'birthdate' || key === 'created_at') {
                values[key] = dayjs(value).format('YYYY-MM-DD')
            }
        }
        values['name_kz'] = values['name_ru']
        return submit(values)
    }


    const [accountForm] = Form.useForm()

    const buttonConfig = {
        type: 'primary',
        disabled: !!disabled,
        danger: true,
        onClick: () => setChangeReo(true),
        title: t('Изменить объект недвижимости'),
        tooltip: t('Изменение ОН для ЛС следует использовать в случае ошибки или описки. Для ситуации переименования следует воспользоваться модулем переименования.'),

    }

    const CardExtra = useCallback(() => {
        return <Button onClick={() => setChangeReo(false)} type={'primary'} danger>
            {t('Отмена')}
        </Button>
    }, [])


    return <>
        {changeReo
            ? <>
                <AddressREOSearchForm
                    single isCard itemsAsRow withReo cardExtra={<CardExtra />}
                    initialValues={{town_id: selectedReSObjRaw?.town_id, street_id: selectedReSObjRaw?.street_id, town_distr_id: selectedReSObjRaw?.town_distr_id, corpus: selectedReSObjRaw?.corpus, house_in: selectedReSObjRaw?.house}}
                />
                <Divider/>
                <CreateREOEmbeddedFrom/>
            </>
            : <ShowAddressCard formated={selectedReSObj} title={t('Объект недвижимости')} button={true} buttonConfig={buttonConfig}/>}
        {(!changeReo || createReoOpen) && <Divider orientation={'left'} orientationMargin={0}/>}
        <Form form={accountForm}
              disabled={disabled}
              size={'small'}
              layout={'vertical'}
              onFinish={onSubmit}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row gutter={[8,8]}>
                <Col span={7}>
                    <Card size={'small'} style={{minHeight: '100%'}}>
                        <Row justify={'space-between'} gutter={[8, 0]}>
                            <Col span={24}>
                                <Form.Item name={'name_ru'} label={t('ФИО')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['name_ru']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item
                                    name={'iin'} label={t('ИИН')}
                                    validateStatus={changedFields['iin']}
                                    rules={[
                                        {required: false, message: t('Обязательное поле')},
                                        {validator: (rule, value) => binValidator(rule, value, false)}
                                    ]}
                                >
                                    <Input showCount maxLength={12}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'flat'} label={t('Квартира')}
                                           validateStatus={changedFields['flat']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size={'small'} style={{minHeight: '100%'}}>
                        <Row justify={'space-between'} gutter={[8, 0]}>
                            <Col span={8}>
                                <Form.Item name={'full_square'} label={t('Общая пл.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['full_square']}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} decimalSeparator={','}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'living_square'} label={t('Жилая пл.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['living_square']}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} decimalSeparator={','}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'heated_square'} label={t('Отапл. пл.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['heated_square']}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} decimalSeparator={','}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'people_quantity'} label={t('Кол-во прожив.')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                                >
                                    <InputNumber style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'room_count'} label={t('Комнат')}
                                           validateStatus={changedFields['room_count']}>
                                    <InputNumber style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'floor'} label={t('Этаж')}
                                           validateStatus={changedFields['floor']}>
                                    <InputNumber style={{width: '100%'}} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card size={'small'}>
                        <Row gutter={[8,0]}>
                            <Col span={12}>
                                <Form.Item name={'phone'} label={t('Тел.')}
                                           validateStatus={changedFields['phone']}>
                                    <InputMask mask="99-99-99">
                                        {() => <Input />}
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'mobile_phone'} label={t('Моб. тел.')}
                                           validateStatus={changedFields['mobile_phone']}>
                                    <InputMask mask="(999) 999-99-99">
                                        {() => <Input />}
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'email'} label={t('Email')}
                                           validateStatus={changedFields['email']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'is_email_delivery'} label={t('Email рассылка')}
                                           valuePropName={'checked'}
                                           validateStatus={changedFields['is_email_delivery']}
                                >
                                    <Switch/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card size={'small'}>
                        <Row justify={'space-between'} gutter={[16, 0]}>
                            <Col span={24}>
                                <Form.Item name={'aesbyt_account'} label={t('Код АЭСбыт')}
                                           validateStatus={changedFields['aesbyt_account']}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={'energy_account'} label={t('Код энергокомпании')}
                                           validateStatus={changedFields['energy_account']}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/*<Col span={10}>*/}
            {/*    <Card title={t('Табличка')} size={'small'}>*/}
            {/*        {editMode && <div>*/}
            {/*            <span>Номер договора</span>*/}
            {/*            <br/>*/}
            {/*            <Row justify={"space-between"}>*/}
            {/*                <Input*/}
            {/*                    style={{width: '50%'}}*/}
            {/*                    value={editData.external_number}*/}
            {/*                    onChange={(e) => onChangeValue(e.target.value)}*/}
            {/*                />*/}
            {/*                <Button type={"primary"} onClick={onSaveEdit}>*/}
            {/*                    Сохранить*/}
            {/*                </Button>*/}
            {/*            </Row>*/}
            {/*        </div>}*/}
            {/*        <br/>*/}
            {/*        <Table*/}
            {/*            dataSource={data}*/}
            {/*            columns={columns}*/}
            {/*        />*/}
            {/*    </Card>*/}
            {/*</Col>*/}
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    accountForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            setAction('edit')
                            accountForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            setAction('list')
                            accountForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
                {/*<Button loading={submitLoading}*/}
                {/*        onClick={() => {*/}

                {/*            accountForm.submit()*/}
                {/*            createCorrection({*/}
                {/*                mass: false,*/}
                {/*                account_id: id*/}
                {/*            })*/}
                {/*        }}*/}
                {/*        type={'primary'}*/}
                {/*>*/}
                {/*    {t('Сохранить и создать корректировку')}*/}
                {/*</Button>*/}
            </Space>
        </Row>
    </>
}
