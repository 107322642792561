import {
    $afterCreateAction,
    $createdBankAccountTypeId,
    $editBankAccountTypeStatus,
    $selectedBankAccountType,
    $bankAccountTypeList,
} from './stores.js';
import {
    deleteBankAccountTypeEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    BankAccountTypeEditGate,
    BankAccountTypeListGate,
    BankAccountTypeViewGate,
    submitCreateBankAccountTypeEv,
    submitEditBankAccountTypeEv, BankAccountTypeCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {getBankAccountTypeListFx, getBankAccountTypeByIdFx} from "./effects.js";


const createBankAccountTypeCompleteEv = combineEvents({events: {ev: submitCreateBankAccountTypeEv, response: triggerSuccessOpsEv}, reset: BankAccountTypeCreateGate.state})
const editBankAccountTypeCompleteEv = combineEvents({ev: submitEditBankAccountTypeEv, response: triggerSuccessOpsEv})
const deleteBankAccountTypeCompleteEv = combineEvents({ev: deleteBankAccountTypeEv, response: triggerSuccessOpsEv})

$bankAccountTypeList.on(getBankAccountTypeListFx.doneData, (_, bankAccTypes) => bankAccTypes)
    .reset(BankAccountTypeListGate.close)

$selectedBankAccountType.on(getBankAccountTypeByIdFx.doneData, (_, bankAccTypes) => bankAccTypes)
    .reset([BankAccountTypeEditGate.close, BankAccountTypeViewGate.close])

$createdBankAccountTypeId.on(createBankAccountTypeCompleteEv, (_, {response}) => {
    const backAccType = response.find( i => i.type === 'bank_account_type')
    return backAccType ? backAccType?.value.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editBankAccountTypeStatus.on(editBankAccountTypeCompleteEv, () => true)
    .reset(BankAccountTypeListGate.close)

sample({
    clock: [BankAccountTypeEditGate.state, BankAccountTypeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getBankAccountTypeByIdFx
})

sample({
    clock: BankAccountTypeListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankAccountTypeListFx
})

sample({
    source: BankAccountTypeListGate.state,
    clock: [createBankAccountTypeCompleteEv, editBankAccountTypeCompleteEv, deleteBankAccountTypeCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankAccountTypeListFx
})

sample({
    clock: submitCreateBankAccountTypeEv,
    fn: payload => {
        const actions = []
        actions.push({operation: 'create', type: 'bank_account_type', value: {...payload, id: -1}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedBankAccountType,
    clock: submitEditBankAccountTypeEv,
    fn: (current, payload) => {
        const actions = []
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'bank_account_type', value: {object_id: current.id, ...changedFields}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteBankAccountTypeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'bank_account_type', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
