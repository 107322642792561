import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $createSectorEmbeddedOpen,
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import Card from 'antd/es/card';
import {$dictsSelects} from "../../../models/infoModel/index.js";

export default function CreateSectorEmbeddedForm({inCol, namespace}) {
    const {t} = useTranslation()
    const open = useUnit($createSectorEmbeddedOpen)
    const inspectorOpts = useStoreMap($dictsSelects, sel => sel.inspector)
    const inspectorOptions = inspectorOpts.filter(item => item.value !== ' ')

    return open && <>
        {
            inCol ?
                <Col span={24}>
                    <Col span={24}>
                        <Form.Item name={namespace ? [namespace, 'adv_sector'] : 'adv_sector'}
                                   label={t('Рекламный сектор')}
                                   style={{marginRight: '10px'}}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    {
                        !window.location.href.includes('inspector') ?
                            <Col span={24}>
                                <Form.Item name={namespace ? [namespace, 'inspector_id'] : 'inspector_id'}
                                           label={t('Контролер')}
                                           style={{marginRight: '10px'}}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={inspectorOptions}
                                        showSearch
                                        defaultValue={null}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item> </Col>: ''

                    }

                </Col> :
                <Row justify={'space-between '} >
                    <Col span={24}>
                        <Form.Item name={namespace ? [namespace, 'adv_sector'] : 'adv_sector'}
                                   label={t('Рекламный сектор')}
                                   style={{marginRight: '10px'}}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    {
                        !window.location.href.includes('inspector') ?
                            <Col span={6}>
                                <Form.Item name={namespace ? [namespace, 'inspector_id'] : 'inspector_id'}
                                           label={t('Контролер')}
                                           style={{marginRight: '10px'}}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={inspectorOptions}
                                        showSearch
                                        defaultValue={null}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item> </Col>: ''

                    }

                </Row>
        }


    </>
}
