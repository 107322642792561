import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getPreferenceCategoryListFx} from "./effects.js";

export const PreferenceCategoryListGate = createGate()
export const PreferenceCategoryViewGate = createGate()
export const PreferenceCategoryEditGate = createGate()
export const PreferenceCategoryCreateGate = createGate()

export const submitCreatePreferenceCategoryEv = createEvent()
export const submitEditPreferenceCategoryEv = createEvent()
export const deletePreferenceCategoryEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
