import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useEffect} from "react";
import {
    CheckCircleOutlined, CheckOutlined,
    CloseCircleOutlined, CloseOutlined, EyeOutlined,
    LoadingOutlined,
    UploadOutlined,
    StopOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import {
    $fileList, acceptFileEv, downloadFileEv,
    FilesGate, getFilesListFx, rejectFileEv, sendMessageUploadEv,
    setUploadModalEv, stopUploadFileEv
} from '../../models/uploadREOModel/index.js';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Divider from 'antd/es/divider';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {$WsSt} from "../../models/webSocketModel/index.js";
import {useWebSocket} from "../../api/WebSocketProvider.jsx";
import ButtonGroup from 'antd/es/button/button-group';
import {Popconfirm, Progress} from 'antd';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import UploadREOModal from "./components/UploadREOModal.jsx";


export default function UploadREO() {
    const {t} = useTranslation()
    const {data, count} = useUnit($fileList)
    const loading = useUnit(getFilesListFx.pending)
    const setModal = useUnit(setUploadModalEv)
    const { sendMessage } = useWebSocket();

    const [search] = useSearchParams()

    const accept = useUnit(acceptFileEv)
    const reject = useUnit(rejectFileEv)
    const stopUpload = useUnit(stopUploadFileEv)
    const download = useUnit(downloadFileEv)

    const wsStatus = useUnit($WsSt)

    useEffect(() => {
        sendMessageUploadEv({
            sendMessage: sendMessage,
            payload: {
                object_type: "import_reo",
                action: 'get_list',
                subscribe: true
            }
        })
    }, [wsStatus]);

    const columns = [
        {
            title: t('Статус загрузки'),
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (status, record) => <Tooltip
                title={
                    status === 'new'
                        ? t('В обработке')
                        : status === 'file processed' || status === 'file approved' || status === 'adding to the table failed' || status === 'file disapproved' || status === 'adding to the table failed'
                            // ? record.load_error
                            ? t('Успешно')
                            : status === "stopped"
                                ? t('Остановлено')
                                : status === "error" || status === 'processing failed'
                                    ? t('Ошибка')
                                    :  '-'
                }
                placement={'right'}
            >
                {
                    status === 'new'
                    ? <div>
                        <Progress type="circle" size={40} percent={parseInt(record.progress)}/>
                    </div>
                    : status === "file processed" ||  status === 'file approved' || status === 'adding to the table failed' || status === 'file disapproved' || status === 'adding to the table failed'
                        ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
                        : status === "stopped" ? <StopOutlined style={{color: 'red', fontSize: 24}}/>
                            : status === "error" || status === 'processing failed' ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                                : '-'
                }
            </Tooltip>
        },
        {
            title: t('Статус принятия'),
            dataIndex: 'is_approved',
            key: 'is_approved',
            align: 'center',
            width: '5%',
            render: (accepted, record) => record.stage === "Перенос данных"  ?
               <Tooltip title={record.stage}>
                   <LoadingOutlined style={{fontSize: 24}}/>
               </Tooltip> :
                accepted === true ?
                <Tooltip title={'Файл подтвержден'}> <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>  </Tooltip>:
                accepted === false ?
                    <Tooltip title={'Файл отклонен'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                        // accepted === null && record.status === 'adding to the table failed' ?
                        record.status === 'adding to the table failed' ?
                        <Tooltip title={'Ошибка'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                        '-'
        },
        {
            title: t('Название файла'),
            dataIndex: 'file_name',
            sorter: true,
            key: 'filename',
            render: (filename) => filename.length > 20 ?
                <Tooltip title={filename}> {filename.substring(0, 20) + '...'}</Tooltip> :
                <Tooltip title={filename}>{filename}</Tooltip>
        },

        {
            title: t('Дата загрузки'),
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем загружен'),
            dataIndex: 'created_by',
            key: 'created_by',
            sorter: true,
            render: (id) => <CreatedBy id={parseInt(id)}/>
            // render: (id) => <p>{id}</p>
        },
        {
            title: t('Дата решения'),
            dataIndex: 'decided_at',
            key: 'decided_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем решено'),
            dataIndex: 'decided_by',
            key: 'decided_by',
            sorter: true,
            render: (id) => <CreatedBy id={parseInt(id)}/>
        },
        {
            title: t('Всего строк'),
            dataIndex: 'total_row_count',
            key: 'total_row_count',
        },
        {
            title: t('Ошибочных строк'),
            dataIndex: 'error_row_count',
            key: 'error_row_count',
        },
        {
            title: t('Импортировано строк'),
            dataIndex: 'imported_row_count',
            key: 'imported_row_count',
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            key: 'actions',
            dataIndex: 'id',
        render: (id, record) => record.status === "new"  ?
            <Tooltip
                title={t('Остановить загрузку')}
            >
                <Popconfirm title={t('Вы уверены, что хотите остановить загрузку этого файла?')}
                            onConfirm={() => {
                                stopUpload(id, 'stop_import')
                            }}
                            okText={t('Да')}
                >
                    <Button
                        icon={<StopOutlined />}
                        danger
                        type={'primary'}
                        // disabled={record.status === 'processing'}

                    />
                </Popconfirm>
            </Tooltip>
            : record.status === 'file processed' ?
                    <ButtonGroup>
                        <Tooltip title={t('Просмотр строк')}>
                            <Link to={'/upload-real-estate-objects/view/' + id + '?validation=false'}>
                                <Button icon={<EyeOutlined />}/>
                            </Link>
                        </Tooltip>
                        <Tooltip title={t('Подтвердить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите подтвердить этот файл? ')}
                                        description={'Внесены в Систему будут только валидные строки'}
                                        onConfirm={() => accept(id)}
                                        okText={t('Да')}
                            >
                                <Button icon={<CheckOutlined />} type={'primary'}/>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={t('Отклонить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите отклонить этот файл?')}
                                        onConfirm={() => reject(id)}
                                        okText={t('Да')}
                                        okButtonProps={{danger: true}}
                            >
                                <Button icon={<CloseOutlined />} danger/>
                            </Popconfirm>
                        </Tooltip>
                    </ButtonGroup>
                : record.status === "file approved" ?
                    <ButtonGroup>
                        <Tooltip title={t('Просмотр строк')}>
                            <Link to={'/upload-real-estate-objects/view/' + id + '?validation=false'}>
                                <Button icon={<EyeOutlined />}/>
                            </Link>
                        </Tooltip>
                        {/*<Tooltip title={t('Скачать файл')}>*/}
                        {/*    <Button*/}
                        {/*        icon={<DownloadOutlined />}*/}
                        {/*        type={"primary"}*/}
                        {/*        onClick={() => download(id)}*/}
                        {/*    />*/}
                        {/*</Tooltip>*/}
                    </ButtonGroup>
                    : record.status === "stopped" ?
                        ''
                        : record.status === "error" || record.status === 'adding to the table failed' || record.status === 'file disapproved' ?
                            <ButtonGroup>
                                <Tooltip title={t('Просмотр строк')}>
                                    <Link to={'/upload-real-estate-objects/view/' + id + '?validation=false'}>
                                        <Button icon={<EyeOutlined />}/>
                                    </Link>
                                </Tooltip>
                            </ButtonGroup>
                            : ''

}
    ]

    const filterItems = [
        {
            name: 'file_name',
            label: t('Название файла'),
            type: 'input',
        },
        {
            name: 'status',
            type: 'select',
            label: t('Статус загрузки'),
            options: [{label: t('Все'), value: null}, {label: t('В обработке'), value: 'processing'}, {label: t('Успешно'), value: 'processed'}, {label: t('Ошибка'), value: 'error'}]
        },
        {
            name: 'is_accepted',
            type: 'select',
            label: t('Статус принятия'),
            options: [{label: t('Принято'), value: true}, {label: t('Отклонено'), value: false}, {label: t('Все'), value: null}]
        }
    ]
    console.log('data', data)

    return <>
        <UploadREOModal />
        <FilesGate search={search}/>
        <FilterBlock items={filterItems}/>
        <Divider />
        <DictionaryProTable
            data={data}
            count={count}
            initColumns={columns}
            loading={loading}
            showActions={false}
            rowKey={'id'}
            tableExtraRender={() => <Tooltip title={t('Загрузить')}>
                <Button type={'primary'} icon={<UploadOutlined />} onClick={() => setModal()}>
                    {t('Загрузить')}
                </Button>
            </Tooltip>}
        />
    </>
}
