import localesRu from "../../assets/locales/ru/translation.json";
import localesKz from "../../assets/locales/kz/translation.json";
export default function TranslateForModels(text) {
    const lang = localStorage.getItem('lang') ?? 'ru'
    const locale = {
        ru: localesRu.breadcrumbs,
        kz: localesKz.breadcrumbs,
    }
    return locale[lang][text]
}

