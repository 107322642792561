import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {
    $selectedActivityType,
    ActivityTypeViewGate,
    getActivityTypeByIdFx
} from "../../../models/dictionaryActivityTypeModel/index.js";

export default function ViewActivityType() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getActivityTypeByIdFx.pending)
    const data = useUnit($selectedActivityType)
    const genitive_form = t(`breadcrumbs.genitive.activity-type`);

    const items = [
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: data?.name_kz
        },
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: data?.name_ru
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <ActivityTypeViewGate id={id} />
        <Card
            title={<div><span>{t('Просмотр Вида деятельности')}</span> <MappedDictItem id={id} type={'activity-type'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'activity-type'}/>
        </Card>
    </>
}
