import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useEffect} from "react";
import {
    CheckCircleOutlined, CheckOutlined,
    CloseCircleOutlined, CloseOutlined, EyeOutlined,
    LoadingOutlined,
    UploadOutlined,
    StopOutlined, DownloadOutlined, FileExclamationOutlined
} from '@ant-design/icons';
import {
    $fileList, acceptFileEv,
    FilesGate, getFilesListFx, rejectFileEv, sendMessageUploadEv,
    setUploadModalEv, stopUploadFileEv
} from '../../models/fileUploadModel/index.js';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Divider from 'antd/es/divider';
import UploadModal from './components/UploadModal.jsx';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {$WsSt} from "../../models/webSocketModel/index.js";
import {useWebSocket} from "../../api/WebSocketProvider.jsx";
import ButtonGroup from 'antd/es/button/button-group';
import {Popconfirm, Progress} from 'antd';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames} from "../../utils/monthNamesDict.js";
import {downloadErrorFileEv} from "../../models/fileUploadModel";

export default function Upload() {
    const {t} = useTranslation()
    const {data, count} = useUnit($fileList)
    const loading = useUnit(getFilesListFx.pending)
    const setModal = useUnit(setUploadModalEv)
    const { sendMessage } = useWebSocket();

    const [search] = useSearchParams()

    const accept = useUnit(acceptFileEv)
    const stopUpload = useUnit(stopUploadFileEv)
    const reject = useUnit(rejectFileEv)

    const downloadErrorFile = useUnit(downloadErrorFileEv)

    const wsStatus = useUnit($WsSt)

    useEffect(() => {
        const payload = {
            sendMessage: sendMessage,
            payload: {
                object_type: "accruals_load_file",
                action: 'get_list',
                subscribe: true
            }
        }
        sendMessageUploadEv(payload)

    }, [wsStatus]);

    const columns = [
        {
            title: t('Статус загрузки'),
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (status, record) => <Tooltip
                title={
                    status === 'processing'
                        ? t('В обработке')
                        : status === 'processed' || status === 'completed'
                            // ? record.load_error
                            ? t('Успешно')
                            : status === "stopped"
                                ? t('Остановлено')
                                : status === "error"
                                    ? t('Ошибка')
                                    :  '-'
                }
                placement={'right'}
            >
                {
                    status === 'processing'
                    ? <div>
                        <Progress type="circle" size={40} percent={parseInt(record.progress)}/>
                    </div>
                    : status === "processed" ||  status === 'completed'
                        ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
                        : status === "stopped" ? <StopOutlined style={{color: 'red', fontSize: 24}}/>
                            : status === "error" ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                                : '-'
                }
            </Tooltip>
        },
        {
            title: t('Статус принятия'),
            dataIndex: 'is_accepted',
            key: 'is_accepted',
            align: 'center',
            width: '5%',
            render: (accepted, record) => record.stage === "Перенос данных"  ?
               <Tooltip title={record.stage}>
                   <LoadingOutlined style={{fontSize: 24}}/>
               </Tooltip> :
                accepted === true ?
                <Tooltip title={'Файл подтвержден'}> <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>  </Tooltip>:
                accepted === false ?
                    <Tooltip title={'Файл отклонен'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                    '-'
        },
        {
            title: t('Название файла'),
            dataIndex: 'file_name',
            sorter: true,
            key: 'filename',
            render: (filename) => filename.length > 20 ?
                <Tooltip title={filename}> {filename.substring(0, 20) + '...'}</Tooltip> :
                <Tooltip title={filename}>{filename}</Tooltip>
        },
        {
            title: t('Тип'),
            dataIndex: 'file_type',
            key: 'file_type',
            sorter: true,
            render: (type) => type !== '-' ?
                <Tooltip title={`${type} ${t('столбцов')}`}> {type}</Tooltip>:
                <Tooltip title={'Неизвестное кол-во столбцов'}>-</Tooltip>
        },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            key: 'provider_id',
            sorter: true,
            width: '25%',
            render: (provider_id) => <MappedDictItem id={provider_id} type={'providers'}/>,
        },
        {
          title: t('Месяц'),
          dataIndex: 'report_date',
          key: 'report_date',
          sorter: true,
          width: '5%',
            render: (date) => {
              if(date !== '-'){
                  return t(monthNames[new Date(date).getMonth() + 1])
              }
              return date
            }
        },
        {
            title: t('Дата загрузки'),
            dataIndex: 'load_date',
            key: 'date',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем загружен'),
            dataIndex: 'user_id',
            key: 'user_id',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Дата решения'),
            dataIndex: 'decided_at',
            key: 'decided_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем решено'),
            dataIndex: 'decided_by',
            key: 'decided_by',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Всего строк'),
            dataIndex: 'total_rows',
            key: 'total_rows',
        },
        {
            title: t('Всего ошибок'),
            dataIndex: 'total_errors',
            key: 'total_errors',
        },
        {
            title: t('Действия'),
            // width: '200px',
            align: 'center',
            key: 'actions',
            dataIndex: 'id',
        render: (id, record) => record.status === "processing"  ?
            <Tooltip
                title={t('Остановить загрузку')}
            >
                <Popconfirm title={t('Вы уверены, что хотите остановить загрузку этого файла?')}
                            onConfirm={() => stopUpload(id)}
                            okText={t('Да')}
                >
                    <Button
                        icon={<StopOutlined />}
                        danger
                        type={'primary'}
                        // disabled={record.status === 'processing'}

                    />
                </Popconfirm>
            </Tooltip>

            : record.status === 'processed' && record.is_accepted === null
                ?
                    <ButtonGroup size={'small'}>
                        <Tooltip title={t('Просмотр строк')}>
                            <Link to={'/upload/view/' + id + '?validation=false'}>
                                <Button icon={<EyeOutlined />}/>
                            </Link>
                        </Tooltip>
                        <Tooltip title={t('Подтвердить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите подтвердить этот файл? ')}
                                       description={'Внесены в Систему будут только валидные строки'}
                                        onConfirm={() => accept(id)}
                                        okText={t('Да')}
                            >
                                <Button icon={<CheckOutlined />} type={'primary'}/>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={t('Отклонить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите отклонить этот файл?')}
                                        onConfirm={() => reject(id)}
                                        okText={t('Да')}
                                        okButtonProps={{danger: true}}
                            >
                                <Button icon={<CloseOutlined />} danger/>
                            </Popconfirm>
                        </Tooltip>
						{ record?.total_errors > 0 ?
							<Tooltip title={t('Скачать файл с ошибками')}>
								<Button
									icon={<FileExclamationOutlined />}
									onClick={() => downloadErrorFile(id)}
								/>
							</Tooltip> : null }
                    </ButtonGroup>
                : record.status === "completed" ? <ButtonGroup size={'small'}>
                    <Tooltip title={t('Просмотр строк')}>
                        <Link to={'/upload/view/' + id + '?validation=false'}>
                            <Button icon={<EyeOutlined />}/>
                        </Link>
                    </Tooltip>
						{ record?.total_errors > 0 ?
							<Tooltip title={t('Скачать файл с ошибками')}>
								<Button
									icon={<FileExclamationOutlined />}
									onClick={() => downloadErrorFile(id)}
								/>
							</Tooltip> : null }
                    </ButtonGroup>
                    : record.status === "stopped" ?
                        ''
                        : (record.status === "error" || record.status === 'processed') ? <ButtonGroup size={'small'}>
                            <Tooltip title={t('Просмотр строк')}>
                                <Link to={'/upload/view/' + id + '?validation=false'}>
                                    <Button icon={<EyeOutlined />}/>
                                </Link>
                            </Tooltip>
                                { record?.total_errors > 0 ?
							<Tooltip title={t('Скачать файл с ошибками')}>
								<Button
									icon={<FileExclamationOutlined />}
									onClick={() => downloadErrorFile(id)}
								/>
							</Tooltip> : null }
                            </ButtonGroup>
                            : ''

}
    ]

    const filterItems = [
        {
            name: 'provider_id',
            label: t('Поставщик'),
            type: 'select',
            dict: 'providers'
        },
        {
            name: 'file_name',
            label: t('Название файла'),
            type: 'input',
        },
        {
            name: 'status',
            type: 'select',
            label: t('Статус загрузки'),
            options: [{label: t('Все'), value: null}, {label: t('В обработке'), value: 'processing'}, {label: t('Успешно'), value: 'processed'}, {label: t('Ошибка'), value: 'error'}]
        },
        {
            name: 'is_accepted',
            type: 'select',
            label: t('Статус принятия'),
            options: [{label: t('Принято'), value: true}, {label: t('Отклонено'), value: false}, {label: t('Все'), value: null}]
        }
    ]
    console.log('data', data)

    return <>
        <UploadModal />
        {/*<ViewFileModal />*/}
        <FilesGate search={search}/>
        <FilterBlock items={filterItems}/>
        <Divider />
        <DictionaryProTable
            data={data}
            count={count}
            initColumns={columns}
            loading={loading}
            showActions={false}
            tableExtraRender={() => <Tooltip title={t('Загрузить')}>
                <Button type={'primary'} icon={<UploadOutlined />} onClick={() => setModal()}>
                    {t('Загрузить')}
                </Button>
            </Tooltip>}
        />
    </>
}
