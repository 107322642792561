import {createGate} from 'effector-react';
import {createEvent} from "effector";

export const AppGate = createGate()
export const getInfoEv = createEvent()
export const onSelectFilterEv = createEvent()

export const openPasswordModalEv = createEvent()
export const dataPasswordModalEv = createEvent()
export const closePasswordModalEv = createEvent()
export const submitChangePasswordEv = createEvent()

export const signalAccrualCalcDoneEv = createEvent()
