import {useTranslation} from 'react-i18next';
import {useState} from "react";
import dayjs from 'dayjs';
import Table from 'antd/es/table';
import Divider from 'antd/es/divider';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    EyeOutlined,
    CheckOutlined,
    CloseOutlined,
    VerticalAlignBottomOutlined, LoadingOutlined
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import {
    getTownsListFx,
} from '../../models/dictionaryTownsModel';
import {
    ControllersListGate,
    $controllersList,
    ControllersFilterEv,
    $controllersFilter,
    sendMessageControllersEv, ControllersGeneratePdfEv, ControllersSendEmailEv, printControllersPdfEv
} from "../../models/controllersModel";
import { useSearchParams} from 'react-router-dom';
import {Card, Form, Row, Select} from "antd";
import {$WsSt} from "../../models/webSocketModel/index.js";
import {useEffect} from "react";
import {useWebSocket} from "../../api/WebSocketProvider.jsx";

window.day = dayjs

export default function Controllers() {
    const {t} = useTranslation()
    const {data} = useUnit($controllersList) || []
    const filterRawData = useUnit($controllersFilter)
    const loading = useUnit(getTownsListFx.pending)
    const [search, setSearch] = useSearchParams()
    const { sendMessage } = useWebSocket();

    const dataSource = data?.map(item => {
        return {
            ...item,
            key: item.id
        }
    })

    const wsStatus = useUnit($WsSt)

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


        console.log('dataSource', dataSource)



    useEffect(() => {
        const payload = {
            sendMessage: sendMessage,
            payload: {
                object_type: "print_pdf_by_inspector",
                subscribe: true
            }
            // payload: [
            //     {
            //         object_type: "print_pdf_by_inspector",
            //         subscribe: true
            //    },
            //     {
            //         object_type: "inspectors",
            //         subscribe: true
            //     },
            // ]
        }

        const payload2 = {
            sendMessage: sendMessage,
            payload: {
                object_type: "inspectors",
                subscribe: true
            }
        }
        sendMessageControllersEv(payload)
        // sendMessageControllersEv(payload2)

    }, [wsStatus]);








    const [form] = Form.useForm()

    const accountsOptions = filterRawData?.map(item => {
        return {
            value: item,
            label: item
        }
    })


    const onDownloadPdf = (file_id) => {
        window.open(`${window.location.origin}/api/v1/dispatch/download_inspector_file/inspector_file_id?file_id=${file_id}`)
    }




    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (_, row) => {
                const lang = localStorage.getItem('lang') ?? 'ru'
                return row?.[`surname_${lang}`] + ' ' + row?.[`firstname_${lang}`] + ' ' + row?.[`patronymic_${lang}`]
            }
        },

        {
            title: 'Статус генерации ПДФ',
            dataIndex: 'file_status',
            key: 'file_status',
            render: (file_status) => (
                file_status === 'success' ? <CheckOutlined style={{color: 'green'}} /> : file_status === 'processing' ? <LoadingOutlined /> : <CloseOutlined style={{color: 'red'}} />
            )
        },

        // {
        //     title: 'sector',
        //     dataIndex: 'sector',
        //     key: 'sector',
        // },

        {
            title: <Tooltip title={t('Скачать PDF')} placement={'top'}>
                 {/*<Button href={'/pdf/generate/create'} icon={<PlusOutlined />} type={'primary'} size={'large'}/>*/}
                Скачать PDF
             </Tooltip>,
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id, row) => <ButtonGroup>
                <Tooltip title={t('Скачать PDF')} placement={'top'}>
                    <Button
                        icon={<VerticalAlignBottomOutlined />}
                        onClick={() => onDownloadPdf(row.file_id)}
                        disabled={row.file_id ? false : true}
                    />
                </Tooltip>

            </ButtonGroup>
        }
    ]

    const onReset = () => {
        form.resetFields()
        setSearch()
    }

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys', selectedRowKeys)
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };





    const onPrintPdf = () => {
        printControllersPdfEv(selectedRowKeys)
    }

    return <>
        <ControllersListGate search={search.toString()}/>
        <Divider orientation={'left'} orientationMargin={0} style={{marginTop: 0, marginBottom: 32}}>
            {t('Контролеры')}
        </Divider>
        <Card style={{width: '100%'}} id='filter-block'>
            <Row style={{alignItems: 'center', gap: 16}}>


                <Button onClick={onPrintPdf}>
                    {t('Сгенерировать PDF')}
                </Button>


            </Row>

        </Card>
        <Table
               dataSource={dataSource}
               columns={columns}
               loading={loading}
               bordered
               rowClassName={'table-row'}
               className={'table-container'}
               rowSelection={rowSelection}
        />
    </>
}
