import {useTranslation} from "react-i18next";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {$accrualsData, AccrualsGate, getAccrualsListDataFx} from "../../models/accrualsModel/index.js";
import {useUnit} from "effector-react";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import AccrualsListFilters from "./AccrualsListFilters.jsx";
import Divider from "antd/es/divider";
import FormatedNumber from "../../components/FormatedNumber.jsx";
import FormatNumber from "../../components/FormatNumber/FormatNumber.jsx";

export default function AccrualsPage(){
	const {t} = useTranslation()
	const {data, count} = useUnit($accrualsData)
	const {search} = searchAndActions()
	const loading = useUnit(getAccrualsListDataFx.pending)

	const columns = [
		// {
		// 	title: t('Код начисления'),
		// 	dataIndex: 'id',
		// 	key: 'id',
		// 	fixed: 'left',
		// },
		// {
		// 	title: t('Код ЕПД'),
		// 	dataIndex: 'epd_id',
		// 	key: 'epd_id',
		// 	fixed: 'left',
		// },
		{
			title: t('Дата ЕПД'),
			dataIndex: 'epd_report_date',
			key: 'epd_report_date',
			fixed: 'left',
			render: (date) => <CustomDates type='month' data={date} />,
		},
		{
			title: t('Поставщик'),
			dataIndex: 'provider_id',
			key: 'provider_id',
			fixed: 'left',
			render: (provider_id) => <MappedDictItem id={provider_id} type={'providers'}/>
		},
		{
			title: t('Услуга'),
			dataIndex: 'service_id',
			key: 'service_id',
			fixed: 'left',
			render: (service_id) => <MappedDictItem id={service_id} type={'services'}/>
		},
		{
			title: t('ЛС'),
			dataIndex: 'account_id',
			key: 'account_id',
			fixed: 'left',
		},
		{
			title: t('Значение тарифа'),
			dataIndex: 'tarif_value',
			key: 'tarif_value',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={3}/>
		},
		{
			title: t('Сумма начисления'),
			dataIndex: 'sum_calc',
			key: 'sum_calc',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Долг/переплата без учета начислений и перерасчетов текущего месяца'),
			dataIndex: 'sum_saldo_begin',
			key: 'sum_saldo_begin',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Сумма перерасчета'),
			dataIndex: 'sum_recalc',
			key: 'sum_recalc',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Долг/переплата с учетом начислений и перерасчетов текущего месяца'),
			dataIndex: 'sum_saldo_end',
			key: 'sum_saldo_end',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Оплаченные показания'),
			dataIndex: 'pay_count',
			key: 'pay_count',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Флаг ведения сальдо'),
			dataIndex: 'sign_saldo_calc',
			key: 'sign_saldo_calc',
		},
		{
			title: t('Единица измерения'),
			dataIndex: 'measure_name',
			key: 'measure_name',
		},
		{
			title: t('Количество проживающих'),
			dataIndex: 'people_quantity',
			key: 'people_quantity',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={0}/>
		},
		{
			title: t('Общая площадь'),
			dataIndex: 'full_square',
			key: 'full_square',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Последние проверенные показания'),
			dataIndex: 'check_count',
			key: 'check_count',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Дата последней проверки'),
			dataIndex: 'check_date',
			key: 'check_date',
		},
		{
			title: t('Значение минимального тарифа'),
			dataIndex: 'tarif_value_min',
			key: 'tarif_value_min',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={3}/>
		},
		{
			title: t('Значение среднего тарифа'),
			dataIndex: 'tarif_value_avg',
			key: 'tarif_value_avg',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={3}/>
		},
		{
			title: t('Значение максимального тарифа'),
			dataIndex: 'tarif_value_max',
			key: 'tarif_value_max',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={3}/>
		},
		{
			title: t('Минимальная сумма начисления'),
			dataIndex: 'sum_calc_min',
			key: 'sum_calc_min',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Средняя сумма начисления'),
			dataIndex: 'sum_calc_avg',
			key: 'sum_calc_avg',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Максимальная сумма начисления'),
			dataIndex: 'sum_calc_max',
			key: 'sum_calc_max',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Сумма платежей прошлого месяца'),
			dataIndex: 'sum_pay',
			key: 'sum_pay',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Сумма неоплаченной пени'),
			dataIndex: 'sum_fine',
			key: 'sum_fine',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Потребление'),
			dataIndex: 'consumption',
			key: 'consumption',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Минимальное потребление'),
			dataIndex: 'consumption_min',
			key: 'consumption_min',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Среднее потребление'),
			dataIndex: 'consumption_avg',
			key: 'consumption_avg',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Максимальное потребление'),
			dataIndex: 'consumption_max',
			key: 'consumption_max',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Нормативное потребление по минимальному тарифу'),
			dataIndex: 'norm1',
			key: 'norm1',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Нормативное потребление по среднему тарифу'),
			dataIndex: 'norm2',
			key: 'norm2',
			align: 'right',
			render: (value) => <FormatNumber value={value} type={2}/>
		}
	]

	return <>
		<AccrualsGate search={search.toString()}/>
		<AccrualsListFilters />
		<Divider/>
		<DictionaryProTable
			data={data} count={count} initColumns={columns} showActions={false} loading={loading}
			left={[
				'epd_report_date', 'provider_id', 'service_id', 'account_id',
			]}
		/>
	</>
}
