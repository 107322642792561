import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import {
    $controllersList,
    $selectedControllers,
    $filters,
    changeSelectedControllersEv,
    downloadFilesFx,
    getControllersFx,
    InspectorFilesGate,
    downloadSingleFileEv,
    UploadedFilesGate,
    setReportDateEv, setSendToBankEv, downloadFilesEv, resetFiltersEv
} from '../../../models/taskAllPdfModel/index.js';
import {useStoreMap, useUnit} from 'effector-react';
import {Popconfirm, Switch, Tag} from 'antd';
import Tooltip from 'antd/es/tooltip';
import Select from 'antd/es/select';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import dayjs from 'dayjs';
import {
    SaveOutlined,
} from '@ant-design/icons';
import FileManagerUploadModal from "./FileManagerUploadModal.jsx";
import {useEffect, useState} from "react";
import Radio from "antd/es/radio";
import {setRadioFilesEv} from "../../../models/taskAllPdfModel";
import Form from "antd/es/form";
import FilterBlock from "../../../components/FiltersBlock/FiltersBlock.jsx";
import {$dictsSelects, $info, $userPerms} from '../../../models/infoModel/index.js';
import {isId} from "../../../utils/helpers.js";
import Col from "antd/es/col";
import AddressREOSearchForm
    from "../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx";
import AccountSearchForm from "../../../components/AddressReoSearchForms/AccountSearchForm.jsx";
import TableExt from './TableExt.jsx';


export default function FileManager({table_name = null}) {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang') ?? 'ru'
    const perms = useUnit($userPerms)

    const [radioValue, setRadioValue] = useState('sector');
    const [pdfToBank, setPdfToBank] = useState(false);
    const {data, count} = useUnit($controllersList)
    const loading = useUnit(getControllersFx.pending)
    const onChangeSelect = useUnit(changeSelectedControllersEv)
    const selected = useUnit($selectedControllers)
    const setReportDateEvent = useUnit(setReportDateEv)
    const setSendToBankEvent = useUnit(setSendToBankEv)
    const setRadioFiles = useUnit(setRadioFilesEv)
    const filters = useUnit($filters)
    const [search] = useSearchParams()
    const current_date = useStoreMap(
        $info, data => dayjs(data?.current_date).format()
    )
    const closedMonthsOpts = useStoreMap($dictsSelects, sel => {
        const closed_months = sel.closed_months || []
        return [
            ...closed_months.filter(
                i => i.value && i.value !== ' '
            ).map(
                i => ({label: i.label, value: dayjs(i.value).format()})
            ),
            {label: 'Расчетный месяц', value: dayjs(current_date).format()},
        ].toSorted((a, b) => a.value < b.value ? 1 : -1)
    })
    const [reportDateValue, setReportDateValue] = useState(current_date);
    const downloadFiles = useUnit(downloadFilesEv)
    const downloadSingleFile = useUnit(downloadSingleFileEv)
    const dloadLoading = useUnit(downloadFilesFx.pending)
    const resetFilters = useUnit(resetFiltersEv)

    useEffect(() => {
        setReportDateValue(current_date)
        setReportDateEvent(current_date)
    }, [current_date])

    useEffect(() => {
        if (!(perms?.sector?.view || perms?.inspector?.view || perms?.real_estate_objects?.view || perms?.accounts?.view)) {
            setRadioValue(null)
        }
    }, [perms])

    console.log('file manager dates', reportDateValue, current_date, closedMonthsOpts)

    const onDownload = (type) => {
        if (type === 'selected') {
            downloadFiles({
                ids: selected,
                filters: [],
                type: radioValue,
                send_to_bank: pdfToBank,
                report_date: reportDateValue
            })
        } else {
            downloadFiles({
                ids: [],
                filters: filters,
                type: radioValue,
                send_to_bank: pdfToBank,
                report_date: reportDateValue
            })
        }

    }

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value)
        setRadioFiles(e.target.value)
        onChangeSelect([])
        resetFilters()
    };


    const filtersMass = [
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns'},
    ]
    const filtersSector = [
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns',},
        {name: 'sector_id', label: t('Участок'), type: 'numrange', dict: 'sector'},
        {name: 'inspector_id', label: t('Без контролера'), type: 'switch'},
    ]
    const filtersInspector = [
        {name: 'code_name', label: t('Код/ФИО контролера'), type: 'input'},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns',},
        {
            name: 'chief_inspector_id',
            label: t('Старший контролер'),
            type: 'select',
            dict: 'chief_inspector',
            multiple: true
        },
    ]
    const filtersREO = [
        {name: 'custom', type: 'custom', component: AddressREOSearchForm, span: 24,},
        {name: 'code_name', label: t('ЖК'), type: 'input'},
    ]
    const filtersAccounts = [
        {name: 'custom', type: 'custom', component: AccountSearchForm, span: 24,},
        {name: 'is_email_delivery', label: t('В E-mail рассылке'), type: 'switch'},
    ]

    const columns = {
        default: [
            {
                title: t('Код'),
                dataIndex: 'id',
                width: '5%',
                key: 'id',
            },
            {
                title: t('ФИО контролера'),
                dataIndex: 'name_ru',
                key: 'fio',
                width: '15%',
                render: (_, i) => lang === 'kz' && i.name_kz ? i.name_kz : i.name_ru
            },
            {
                title: t('Участок'),
                dataIndex: 'sector_ids',
                sorter: true,
                key: 'sector_ids',
                width: '15%',
            },
            {
                title: t('Город'),
                dataIndex: 'town_id',
                sorter: true,
                key: 'town',
                render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>,
            },
            {
                title: t('Файл'),
                dataIndex: 'has_pdf',
                key: 'file',
                align: 'center',
                render: (has_pdf, record) => has_pdf && <Tooltip title={t('Скачать')}>
                    <Button ghost type={'primary'}
                            onClick={() => downloadSingleFile({
                                pdf_id: record.pdf_id,
                                generate_type: radioValue,
                                send_to_bank: pdfToBank
                            })}
                    >
                        <SaveOutlined/>
                    </Button>
                </Tooltip>,
            }
        ],
        sector: [
            {
                width: '5%',
                title: t(`Код участка`),
                dataIndex: 'id',
                sorter: true,
                key: 'id',
            },
            {
                title: t('Населенный пункт'),
                dataIndex: 'town_id',
                sorter: true,
                key: 'town_id',
                render: (town_id, _) => <MappedDictItem id={town_id} type={'town'}/>
            },
            {
                title: t('Контролер'),
                dataIndex: 'inspector_id',
                sorter: true,
                key: 'inspector_id',
                render: (_, record) => record.inspector_id === null ? 'Без контролера' :
                    <MappedDictItem id={record.inspector_id} type={'inspector'}/>
            },
            {
                title: t('Рекламный сектор'),
                dataIndex: 'adv_sector',
                sorter: true,
                key: 'adv_sector',
                render: (adv_sector, _) => adv_sector
            },

            {
                title: t('Файл'),
                dataIndex: 'has_pdf',
                key: 'file',
                align: 'center',
                render: (has_pdf, record) => isId(record.pdf_id) && <Tooltip title={t('Скачать')}>
                    <Button ghost type={'primary'}
                            onClick={() => downloadSingleFile({
                                pdf_id: record.pdf_id,
                                generate_type: radioValue,
                                send_to_bank: pdfToBank
                            })}
                    >
                        <SaveOutlined/>
                    </Button>
                </Tooltip>,
            }
        ],
        inspector: [
            {
                width: '5%',
                title: t(`Код контролера`),
                dataIndex: 'id',
                sorter: true,
                key: 'id',
            },
            {
                title: t(`Старший контролер`),
                dataIndex: 'chief_inspector_id',
                sorter: true,
                key: 'chief_inspector_id',
                render: (chief_inspector_id, _) => <MappedDictItem id={chief_inspector_id}
                                                                   type={'chief_inspector'}/>
            },
            {
                title: t(`ФИО контролера`),
                dataIndex: 'name_kz',
                sorter: true,
                key: 'name_kz',
                render: (_, record) => <MappedDictItem id={record.id} type={'inspector'}
                                                       withID={false}/>
            },
            {
                title: t(`Участок`),
                dataIndex: 'sector_ids',
                sorter: true,
                key: 'sector_ids',
                render: (sector_ids, record) => record.sector_count > 0 ? <Link
                    to={`/dictionaries/sector/view/${sector_ids[0]}`}>{sector_ids[0]}</Link> : '-'
            },
            {
                title: t('Файл'),
                dataIndex: 'has_pdf',
                key: 'file',
                align: 'center',
                render: (has_pdf, record) => isId(record.pdf_id) && <Tooltip title={t('Скачать')}>
                    <Button ghost type={'primary'}
                            onClick={() => downloadSingleFile({
                                pdf_id: record.pdf_id,
                                generate_type: radioValue,
                                send_to_bank: pdfToBank
                            })}
                    >
                        <SaveOutlined/>
                    </Button>
                </Tooltip>,
            }
        ],
        reo: [
            {
                title: t('Населенный пункт'),
                dataIndex: 'town_id',
                sorter: true,
                key: 'town_id',
                render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>
            },
            {
                title: t('Район города'),
                dataIndex: 'town_distr_id',
                sorter: true,
                key: 'town_distr_id',
                render: (town_distr_id, _) => <MappedDictItem id={town_distr_id}
                                                              type={'town_distr'}/>
            },
            {
                title: t('Улица'),
                dataIndex: 'street_id',
                sorter: true,
                key: 'street_id',
                render: (street_id, _) => <MappedDictItem id={street_id} type={'street'}/>
            },

            {
                title: t('Дом'),
                dataIndex: 'house',
                sorter: true,
                key: 'house',
                render: (house, _) => house
            },
            {
                title: t('Корпус'),
                dataIndex: 'corpus',
                sorter: true,
                key: 'corpus',
                render: (corpus, _) => corpus
            },

            {
                title: t('ЖК'),
                dataIndex: 'name_kz',
                sorter: true,
                key: 'name_kz',
                render: (_, r) => r[`name_${lang}`] || r['name_kz'] || r['name_ru'],
            },
            {
                title: t('Тип ОН'),
                dataIndex: 'real_estate_object_type_id',
                sorter: true,
                key: 'real_estate_object_type_id',
                render: (real_estate_object_type_id, _) => <MappedDictItem
                    id={real_estate_object_type_id} type={'real-estate-object-type'}/>
            },
            {
                title: t('Участок'),
                dataIndex: 'sector_id',
                sorter: true,
                key: 'sector_id',
                render: (sector_id, _) => <MappedDictItem id={sector_id} type={'sector'}/>
            },
            {
                title: t('Контролер'),
                dataIndex: 'inspector_id',
                sorter: true,
                key: 'inspector_id',
                render: (inspector_id, _) => <MappedDictItem id={inspector_id} type={'inspector'}/>
            },
            {
                title: t('Файл'),
                dataIndex: 'has_pdf',
                key: 'file',
                align: 'center',
                render: (has_pdf, record) => isId(record.pdf_id) && <Tooltip title={t('Скачать')}>
                    <Button ghost type={'primary'}
                            onClick={() => downloadSingleFile({
                                pdf_id: record.pdf_id,
                                generate_type: radioValue,
                                send_to_bank: pdfToBank
                            })}
                    >
                        <SaveOutlined/>
                    </Button>
                </Tooltip>,
            }
        ],
        account: [
            {
                title: t(`Номер ЛС`),
                dataIndex: 'id',
                sorter: true,
                width: '3%',
                key: 'id'
            },
            {
                title: t('Населенный пункт'),
                dataIndex: 'town_id',
                key: 'town_id',
                sorter: true,
                render: (id) => <MappedDictItem id={id} type={'town'}/>,
            },
            {
                title: t('Код ОН'),
                dataIndex: 'real_estate_object_id',
                key: 'real_estate_object_id',
                sorter: true,
                render: (id) => <MappedDictItem id={id} type={'real_estate_object'}/>
            },
            {
                title: t('Район'),
                dataIndex: 'town_distr_id',
                key: 'town_distr_id',
                sorter: true,
                render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
            },
            {
                title: t('Улица'),
                dataIndex: 'street_id',
                key: 'street_id',
                sorter: true,
                render: (id) => <MappedDictItem id={id} type={'street'}/>,
            },
            {
                title: t('Дом'),
                dataIndex: 'house',
                key: 'house'
            },
            {
                title: t('Корпус'),
                dataIndex: 'corpus',
                key: 'corpus'
            },
            {
                title: t('Квартира'),
                dataIndex: 'flat',
                key: 'flat'
            },
            {
                title: t('ФИО потребителя'),
                dataIndex: 'name_kz',
                key: 'fio',
                sorter: true,
                render: (_, r) => r[`name_${lang}`] || r['name_kz'] || r['name_ru'],
            },
            {
                title: t('ИИН'),
                dataIndex: 'iin',
                key: 'iin'
            },
            {
                title: t('В E-mail рассылке'),
                dataIndex: 'is_email_delivery',
                key: 'is_email_delivery'
            },
            {
                title: t('Файл'),
                dataIndex: 'has_pdf',
                key: 'file',
                align: 'center',
                render: (has_pdf, record) => isId(record.pdf_id) && <Tooltip title={t('Скачать')}>
                    <Button ghost type={'primary'}
                            onClick={() => downloadSingleFile({
                                pdf_id: record.pdf_id,
                                generate_type: radioValue,
                                send_to_bank: pdfToBank
                            })}
                    >
                        <SaveOutlined/>
                    </Button>
                </Tooltip>,
            }
        ]
    }

    const [cols, setCols] = useState(columns.default)

    useEffect(() => {
        setCols(radioValue ? columns[radioValue] : columns.default)
    }, [radioValue])

    const onCloseTag = (e) => {
        const data = selected.filter(item => item !== e)
        onChangeSelect(data)
    }

    const onChangeReportDate = (value) => {
        setReportDateValue(value)
        setReportDateEvent(value)
    }
    const onChangeSendToBank = (value) => {
        setPdfToBank(value)
        setSendToBankEvent(value)
    }

    return <>
        <InspectorFilesGate search={search} generate_type={radioValue}/>
        <UploadedFilesGate table_name={table_name}/>
        <Card
            size={'small'} title={t('Тип объектов для формирования ЕПД')}
            style={{marginBottom: '8px'}}
        >
            <Row justify={'space-between'} style={{alignItems: 'center'}} gutter={[16, 0]}>
                <Col span={8}>
                    <Radio.Group onChange={onChangeRadio} value={radioValue}>
                        {!(perms?.sector?.view || perms?.inspector?.view || perms?.real_estate_objects?.view || perms?.accounts?.view) && <Radio value={null}>Нет прав</Radio>}
                        {perms?.sector?.view && <Radio value={'sector'}>По участкам</Radio>}
                        {perms?.inspector?.view && <Radio value={'inspector'}>По контролерам</Radio>}
                        {perms?.real_estate_objects?.view && <Radio value={'reo'}>По ОН</Radio>}
                        {perms?.accounts?.view && <Radio value={'account'}>По ЛС</Radio>}
                    </Radio.Group>
                </Col>
                {radioValue === 'account' && <>
                    <Col span={4}>
                        <Form.Item
                            style={{margin: 0, width: '100%'}}
                            name={'report_date'}
                            label={'Месяц'}
                        >
                            <Select
                                size={'small'}
                                options={closedMonthsOpts}
                                value={reportDateValue}
                                defaultValue={reportDateValue}
                                onChange={(v) => onChangeReportDate(v)}
                                // style={{width: '100px'}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            style={{margin: 0}}
                            name={'send_to_bank'}
                            label={'Для БВУ'}
                        >
                            <Switch
                                size={'small'}
                                value={pdfToBank}
                                onChange={(v) => onChangeSendToBank(v)}
                            />
                        </Form.Item>
                    </Col>
                </>}
            </Row>
        </Card>
        <FilterBlock items={
            radioValue === 'all' ?
                filtersMass :
                radioValue === 'sector' ?
                    filtersSector :
                    radioValue === 'inspector' ?
                        filtersInspector :
                        radioValue === 'reo' ?
                            filtersREO : filtersAccounts
        }/>
        <Card
            style={{marginTop: '8px', marginBottom: '8px'}} size={'small'}
            title={t('Выбранные объекты')}
        >
            <Row>
                {selected.length > 0
                    ? selected.map((i, idx) => <Tag
                            closable
                            onClose={(e) => {
                                e.preventDefault()
                                onCloseTag(i);
                            }}
                            key={idx}
                        >
                            {i}
                        </Tag>
                    )
                    : filters.select_all === true
                        ? t('Все Контролеры') + ` (${count})`
                        : t('Нет')
                }
            </Row>
        </Card>
        <Card size={'small'}>
            <div style={{position: 'relative'}}>
                <Row gutter={[8, 0]}
                        style={{
                            marginBottom: '-40px',
                            marginRight: '48px',
                            zIndex: 10,
                            position: 'relative'
                        }}
                >
                    <Col span={8}>
                        <Tooltip
                            title={!(selected.length > 0) ? t('Необходимо выбрать объект') : null}>
                            <Button type={'primary'}
                                    onClick={() => onDownload('selected')}
                                    disabled={!(selected.length > 0)}
                                    loading={dloadLoading}
                            >
                                {t('Скачать архив с файлами выделенных объектов ')}
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col span={8}>
                        {(radioValue === 'account' && count > 1000) ? <Popconfirm
                            title={t('Вы уверены, что хотите скачать архив большим кол-вом объектов')}
                            okText={t('Да')}
                            onConfirm={() => onDownload('all')}
                            placement={'left'}
                        >
                            <Button type={'primary'}
                                    loading={dloadLoading}
                            >
                                {t('Скачать архив с файлами объектов попадающие под фильтр ')}
                            </Button>
                        </Popconfirm> : <Button type={'primary'}
                                                onClick={() => onDownload('all')}
                                                loading={dloadLoading}
                        >
                            {t('Скачать архив с файлами объектов попадающие под фильтр ')}
                        </Button>}
                    </Col>
                </Row>
                    <Row gutter={[8, 0]} justify={'space-between'}>
                        <Col span={24}>
                            <div
                                className={'epd-all-create-entities-table'}
                                style={{minHeight: '44px'}}
                            >
                                <TableExt
                                    columns={cols}
                                    count={count}
                                    data={data}
                                    loading={loading}
                                    onChangeSelect={onChangeSelect}
                                    selected={selected}
                                />
                            </div>
                        </Col>
                    </Row>
            </div>
        </Card>
        <FileManagerUploadModal table_name={table_name}/>
    </>
}
