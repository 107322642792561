import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {InputNumber} from "antd";
import {
    $createdBankCommissionId,
    BankCommissionCreateGate,
    submitCreateBankCommissionEv,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
} from "../../../models/dictionaryBankCommissionModel/index.js";

export default function CreateBankCommission() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [bankCommissionForm] = Form.useForm()

    const submit = useUnit(submitCreateBankCommissionEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdBankCommissionId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.bank-commission`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/bank-commission/edit/${createdId}`)
            } else if (action === 'new') {
                bankCommissionForm.resetFields()
            } else if (action === 'list') {
                bankCommissionForm.resetFields()
                navigate(`/dictionaries/bank-commission?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <BankCommissionCreateGate />
        <Form form={bankCommissionForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Card title={t('Свойства Комиссии')} size={'small'}>
                <Row justify={'flex-start'} gutter={[16, 16]}>
                    <Col span={5}>
                        <Form.Item name={'commission'}
                                   label={t(`Комиссия банка, %`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <InputNumber style={{width: '100%'}} decimalSeparator={','} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'reimbursement'}
                                   label={t(`Возмещение в ЕРЦ, %`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <InputNumber style={{width: '100%'}} decimalSeparator={','} />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    bankCommissionForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            bankCommissionForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            bankCommissionForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            bankCommissionForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
