import {createStore} from 'effector';


export const $bankApiAddressList = createStore({data: [], count: 0})

export const $selectedBankApiAddress = createStore(null)

export const $editBankApiAddressStatus = createStore(false)

export const $createdBankApiAddressId = createStore(null)
export const $afterCreateAction = createStore(null)
