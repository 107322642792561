import {Button, Card, DatePicker, Form, Input, InputNumber, Row, Select, Switch} from 'antd';
import {useLocation, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {useUnit} from 'effector-react';
import {$dictsSelects} from '../../models/infoModel/index.js';
import Divider from 'antd/es/divider';
import {$radioSt} from "../../models/taskAllPdfModel/index.js";
import {saveUserSettingsEv} from "../../models/userSettingsModel/index.js";
import Col from 'antd/es/col';
import {filterOption} from "../../utils/selectUtils.jsx";


const getInput = (key, i, t, selectOpts, checkCity, onCheckCity, form) => {

    if (i.type === 'input') {
        return <Form.Item key={key} name={i.name} label={i.label}>
            <Input />
        </Form.Item>
    } else if (i.type === 'custom') {
        const iProps = {...i}
        delete iProps.component
        const C = i.component
        return <C
            extForm={form}
            extButtons={true}
            onlyItems={true}
            itemsAsRow={true}
            {...iProps}
        />
    } else if (i.object === 'reports_group') {
        return <Form.Item key={key} name={i.name} label={i.label}>
            <Select
                options={i.dict ? selectOpts[i.dict] : i.options ? i.options : []}
                onChange={
                     () => {
                         form.setFieldValue('type', [])
                         form.setFieldValue('subtype', [])
                    }
                }
                dropdownStyle={{width: 300}}
                // mode={'multiple'}
                filterOption={filterOption}
                allowClear
            />

        </Form.Item>
    } else if (i.object === 'reports_type') {
        // FIXME CHECK
        const groupSelected = Form.useWatch('group', form)

        return <Form.Item key={key} name={i.name} label={i.label}>
            <Select
                options={i.options[groupSelected]}
                onChange={() => form.setFieldValue('subtype', [])}
                dropdownStyle={{width: 300}}
                // mode={'multiple'}
                filterOption={filterOption}
                allowClear
            />

        </Form.Item>
    } else if (i.object === 'reports_subtype') {
        // FIXME CHECK
        const typeSelected = Form.useWatch('type', form)
        return <Form.Item key={key} name={i.name} label={i.label}>
                <Select
                    options={i.options[typeSelected]}
                    // style={{width: '12.8rem'}}
                    // onChange={() => onChangeFilter(v, i)}
                    onChange={() => onCheckCity(i)}
                    dropdownStyle={{width: 300}}
                    mode={'multiple'}
                    filterOption={filterOption}
                    // disabled={i.disabled || (i.name === 'town_distr_id' || i.name === 'street_id' && checkCity)  }
                    allowClear
                    disabled={
                        typeSelected === '106e' || typeSelected === '505a'
                            ? false : true
                    }
                />

        </Form.Item>
    } else if (i.object === 'provider-kassa') {
        return <Form.Item key={key} name={i.name} label={i.label}>

            <Select
                options={

                    i.dict ?
                        selectOpts[i.dict] :
                        i.options ?
                            i.options : []

                }
                onChange={() => {
                    onCheckCity(i)
                    form.setFieldValue('bank_id', [])
                }}

                // style={{width: '12.8rem'}}
                // onChange={(v) => onChangeFilter(v, i)}
                dropdownStyle={{width: 300}}
                showSearch filterOption={filterOption}
                // disabled={i.disabled}
                disabled={i.name === 'town_distr_id' || i.name === 'street_id' ? checkCity : i.disabled}
                allowClear
            />
        </Form.Item>
    } else if (i.object === 'bank-kassa') {
        // FIXME CHECK
        const typeSelected = Form.useWatch('provider_id', form)
        // i.map[typeSelected]
        const opts = i?.opts?.filter(item => i?.map[typeSelected]?.includes(item?.value));
        return <Form.Item key={key} name={i.name} label={i.label}>
            <Select
                options={opts || []}
                // onChange={() => onCheckCity(i)}
                onChange={() => {
                    onCheckCity(i)
                    // form.setFieldValue('bank_id', [])
                }}
                dropdownStyle={{width: 300}}
                mode={'multiple'}
                filterOption={filterOption}
                allowClear
            />
        </Form.Item>
    } else if (i.object === 'deduction') {
        // FIXME CHECK
        const dateNow = Date.now()
        console.log('dateNow', dateNow)
        return <Form.Item key={key} name={i.name} label={i.label}>
            <DatePicker.MonthPicker
                style={{width: '100%'}}
                placeholder={t('Выберите месяц')}
                defaultValue={dayjs(Date.now())}
                disabledDate={(current) => current && current > dayjs().endOf('month')}


            />
        </Form.Item>
    } else if (i.type === 'switch') {
        return <Form.Item key={key} name={i.name} label={i.label} valuePropName={'checked'}>
            <Switch defaultChecked={false} className='form-checker'/>
        </Form.Item>
    } else if (i.type === 'date') {

        return <Form.Item key={key} name={i.name} label={i.label}>
            <DatePicker placeholder={t('Укажите дату')}/>
        </Form.Item>
    } else if (i.type === 'range') {
        return <Form.Item key={key} name={i.name} label={i.label}>
            <DatePicker.RangePicker placeholder={t('Укажите период')}/>
        </Form.Item>
    } else if (i.type === 'select') {
        return <Form.Item key={key} name={i.name} label={i.label}>
            {i.multiple
                ? <Select
                    options={i.dict ? selectOpts[i.dict] : i.options ? i.options : []}
                    // style={{width: '12.8rem'}}
                    // onChange={() => onChangeFilter(v, i)}
                    onChange={() => onCheckCity(i)}
                    dropdownStyle={{width: 300}}
                    mode={'multiple'}
                    filterOption={filterOption}
                    // disabled={i.disabled || (i.name === 'town_distr_id' || i.name === 'street_id' && checkCity)  }
                    disabled={i.name === 'town_distr_id' || i.name === 'street_id' ? checkCity : i.disabled}
                    allowClear
                />
                : <Select
                    options={

                        i.dict ?
                            selectOpts[i.dict] :
                            i.options ?
                                i.options : []

                    }
                    onChange={() => onCheckCity(i)}

                    // style={{width: '12.8rem'}}
                    // onChange={(v) => onChangeFilter(v, i)}
                    dropdownStyle={{width: 300}}
                    showSearch filterOption={filterOption}
                    // disabled={i.disabled}
                    disabled={i.name === 'town_distr_id' || i.name === 'street_id' ? checkCity : i.disabled}
                    allowClear
                />
            }
        </Form.Item>
    } else if (i.type === 'divider') {
        return <Divider key={key} style={{marginTop: -8, marginBottom: 0}}/>
    } else if (i.type === 'numrange') {
        return <div key={key}>
            <Form.Item name={`${i.name}_from`} label={`${i.label} от`}>
                <InputNumber/>
            </Form.Item>
            <Form.Item name={`${i.name}_to`} label={`${i.label} до`}>
                <InputNumber/>
            </Form.Item>
        </div>
    }
}

export const prepareValues = (values) => {
    for (const [key, value] of Object.entries(values)) {
        if ((value === undefined || value === ' ' || value === '') && key !== 'sector_id') {
            delete values[key]
            continue
        }

        if (!value && value !== false) {
            if (
                key !== 'town_id' && key !== 'sector_id'
                && key !== 'street_id' && key !== 'street_type_id' && key !== 'town_distr_id'
                && key !== 'algorithm_id'
            ) {
                delete values[key]
                continue
            }
        }

        // if (key.includes('date') && Array.isArray(value) && value.every(v => dayjs(v).isValid())) {
        if (key === 'created_at' || key === 'updated_at' || key === 'current_month' || key === 'bank_day') {
            values[`${key}_from`] = dayjs(value[0]).format('YYYY-MM-DD')
            values[`${key}_to`] = dayjs(value[1]).format('YYYY-MM-DD')
            delete values[key]
        }
        if (key.split('_').at(-1) === 'at' && Array.isArray(value) && value.every(v => dayjs(v).isValid())) {
            values[`${key}_from`] = dayjs(value[0]).format('YYYY-MM-DD')
            values[`${key}_to`] = dayjs(value[1]).format('YYYY-MM-DD')
            delete values[key]
        }
        if (key.includes('actual_from')) {
            values['actual_from_from'] = dayjs(value[0]).format('YYYY-MM-DD')
            values['actual_from_to'] = dayjs(value[1]).format('YYYY-MM-DD')
            delete values['actual_from']
        }
        if (key.split('_').includes('date') && !Array.isArray(value)) {
            values[key] = dayjs(value).format('YYYY-MM-DD')
        }
        if (key.includes('bank_day') && !Array.isArray(value)) {
            values[key] = dayjs(value).format('YYYY-MM-DD')
        }
        if (key === 'prov_acc_current_month') {
            values[key] = dayjs(value).format('YYYY-MM-DD')
        }
        if (key === 'epd_report_date') {
            values[key] = dayjs(value).format('YYYY-MM-DD HH:mm:ss')
        }

        if (key === 'name_ru') {
            values[key] = value
            values['name_kz'] = value
        }
        if (key === 'sector_id') {
            if (value === ' ' || value === null || value === undefined) {
                values[key] = null
            }
            // FIXME HON #695
            delete values[key]
        }

        if (key.includes('year_month')) {
            values[key] = dayjs(value).format('YYYY-MM-DD')
        }

        //FIXME Saldo month input mutation
        // if(key === 'month'){
        //     if(!/\d/.test(value)){
        //         Object.keys(monthNames).forEach(month_id => {
        //             if(monthNames[month_id].toLowerCase().includes( value.toLowerCase())){
        //                 values[key] = month_id
        //             }
        //         })
        //     }
        // }
    }
    return values
}

export default function FilterBlock({items, extForm, onSubmitHook, onResetHook, resetKeysOnTownChange = []}) {
    const {t} = useTranslation()
    const [inForm] = Form.useForm()
    const form = extForm ? extForm : inForm
    const [search, setSearch] = useSearchParams()
    const [checkCity, setCheckCity] = useState(true)
    const [town_id, setTownId] = useState(null)
    const saveUserSettings = useUnit(saveUserSettingsEv)
    const {pathname} = useLocation()
    const selectOpts = useUnit($dictsSelects)
    // for tasks/all
    const radioType = useUnit($radioSt)


    useEffect(() => {
        // console.log('test filter use effect')

        // FIXME if navigate url have filters, then clear filters
        // onReset()
    }, [radioType]);

    useEffect(() => {
        if (search?.size > 0) {
            const parsed = queryString.parse(search.toString(), {
                parseBooleans: true,
                parseNumbers: true
            })
            for (const [key, value] of Object.entries(parsed)) {
                if ((key.split('_').at(-1) === 'from' || key.split('_').at(-1) === 'to') && typeof value === 'string' && dayjs(value).isValid()) {
                    const actualKey = key.split('_').slice(0, -1).join('_')
                    parsed[actualKey] = [dayjs(value), dayjs(parsed[`${actualKey}_to`])]
                    delete parsed[key]
                }
                if (key === 'year_month') {
                    parsed.year_month = dayjs(parsed['year_month'])
                }

                // if (key === 'prov_acc_current_month') {
                //     parsed.prov_acc_current_month = dayjs(parsed.prov_acc_current_month)
                // }
                if (key === 'epd_report_date' || key === 'prov_acc_current_month') {
                    parsed[key] = dayjs(parsed[key])
                }
            }

            const inspectors = Array.isArray(parsed?.inspector_id) ? parsed?.inspector_id?.map(item => {
                if (item === 'null' || item === null) {
                    return '-'
                } else {
                    return item
                }
            }) : parsed?.inspector_id === 'null' || parsed?.inspector_id === null ? '-' : parsed?.inspector_id
            const sectors = Array.isArray(parsed?.sector_id) ? parsed?.sector_id?.map(item => {
                if (item === 'null' || item === null) {
                    return '-'
                    // return undefined
                } else {
                    return item
                }
            }) : parsed?.sector_id === 'null' || parsed?.sector_id === null ? '-' : parsed?.sector_id
            const newParsed = {
                ...parsed,
                inspector_id: inspectors,
                sector_id: sectors,
            }
            form.setFieldsValue(newParsed)
        }
    }, [search])

    const filteredOpts = {
        ...selectOpts,
        // districts: town_id !== null ? selectOpts?.districts?.filter(item => item?.town_id === town_id) : selectOpts?.districts,
        streets: town_id !== null ? selectOpts?.streets?.filter(item => item?.town_id === town_id) : selectOpts?.streets,
        sector: town_id !== null ? selectOpts?.sectors?.filter(item => item?.town_id === town_id) : selectOpts?.sectors,
        inspector: town_id !== null ? selectOpts?.inspector?.filter(item => item?.town_id === town_id) : selectOpts?.inspector,
        districts: town_id !== null ? Array.isArray(town_id) ?
            selectOpts?.districts.filter(item => town_id.includes(item.town_id)) :
            selectOpts?.districts?.filter(item => item?.town_id === town_id) : selectOpts?.districts,
    }

    const onCheckCity = (_value) => {
        const formData = form.getFieldsValue()
        if (formData.town_id !== undefined) {
            setCheckCity(false)
            setTownId(formData.town_id)
        } else {
            setCheckCity(true)
        }
    }

    const onReset = () => {
        form.resetFields()
        setTownId(null)
        setSearch()
        saveUserSettings({
            key: `${pathname.slice(1)}.filters`,
            value: ""
        })
        onResetHook && onResetHook()
    }

    const onSubmit = onSubmitHook ? onSubmitHook : () => {
        const data = form.getFieldsValue()
        const keys = Object.keys(data || {})
        if (keys.includes('inspector_id') && data?.inspector_id !== null) {
            const inspector_id = data?.inspector_id
            if (typeof inspector_id === 'object') {
                const ids = inspector_id.map(it => (it === -1 || it === '-') ? null : it)
                form.setFieldsValue({inspector_id: ids})
            }
            if (inspector_id === -1 || inspector_id === '-') {
                form.setFieldsValue({inspector_id: null})
            }
            if (inspector_id === true) {
                form.setFieldsValue({inspector_id: [null]})
            } else if (inspector_id === false) {
                form.setFieldsValue({inspector_id: undefined})
            }
        }
        if (keys.includes('sector_id') && data?.sector_id !== null) {
            const sector_id = data?.sector_id
            if (typeof sector_id === 'object') {
                const ids = sector_id.map(it => (it === -1 || it === '-') ? null : it)
                form.setFieldsValue({sector_id: ids})
            }
            if (sector_id === -1 || sector_id === '-') {
                form.setFieldsValue({sector_id: null})
            }
        }
        console.log('FB onSubmit', data)
        form.submit()
    }

    const onFinish = (values) => {
        const preparedValues = prepareValues(values)
        console.log('values', preparedValues)
        setSearch(prepareValues(preparedValues))
        saveUserSettings({
            key: `${pathname.slice(1).replaceAll('/', '.')}-filters`,
            value: JSON.stringify(values)
        })
    }

    const onValuesChange = (cur, _all) => {
        if (Object.hasOwn(cur, 'town_id')) {
            form.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus', 'flat_in', ...resetKeysOnTownChange])
        }
    }

    const defSpan = 4;
    return <Card
        style={{width: '100%'}}
        id='filter-block'
        size={'small'}
        title={window.location.href.includes('reports') ? 'Найти отчеты' : ''}
    >
        <Form form={form}
              layout={'vertical'}
              size={'small'}
              onValuesChange={onValuesChange}
            // onFinish={(v) => setSearch(prepareValues(v))}
              onFinish={(v) => onFinish(v)}
        >
            <Row gutter={[8, 0,]}>
                {items?.map((i, key) => {
                    return <Col key={key} span={i.span || defSpan}>
                        {getInput(key, i, t, filteredOpts, checkCity, onCheckCity, form)}
                    </Col>
                })}
            </Row>
        </Form>
        <Row style={{alignItems: 'center', gap: 16}}>
            <Button type={'primary'} onClick={() => onSubmit()} size={'small'}>
                {t('Применить фильтр')}
            </Button>
            <Button onClick={onReset} size={'small'}>
                {t('Очистить фильтр')}
            </Button>
        </Row>
    </Card>
}
