import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import React, {useMemo} from 'react';
import {
    $accsFilters, $correctionHistoryTariffs,
    $selectedAcc,
    $srvsByAcc, selectAccSrvEv
} from '../../../models/dictionaryCorrectionModel/index.js';
import {$infoMaps} from '../../../models/infoModel/index.js';
import Table from 'antd/es/table';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import {EditOutlined} from '@ant-design/icons';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import {isId} from '../../../utils/helpers.js';
import * as styles from './ServicesEditTable.module.css';
import FormatedNumber from "../../../components/FormatedNumber.jsx";

export default function AccountServicesTable() {
    const {t} = useTranslation()
    const srvUnitMap = useStoreMap($infoMaps, (m) => m.serviceUnitsMap)
    const tariffsMap = useUnit($correctionHistoryTariffs)

    const filters = useUnit($accsFilters)

    const selectedAcc = useUnit($selectedAcc)
    const srvsAndCorssByAcc = useUnit($srvsByAcc)

    const selectSrvToEdit = useUnit(selectAccSrvEv)

    const dataSource = useMemo(() => {
        if (isId(selectedAcc) && srvsAndCorssByAcc) {
            let services = [...(srvsAndCorssByAcc.services || [])]
            if (filters.provider_id && filters.service_id) {
                services = services.filter(s => s.provider_id === filters.provider_id && s.service_id === filters.service_id)
            } else if (filters.provider_id) {
                services = services.filter(s => s.provider_id === filters.provider_id)
            }
            return services
        }
    }, [srvsAndCorssByAcc, selectedAcc, filters])

    const columns = [
        {
            className: styles.topCol,
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            width: '15%',
            key: 'provider_id',
            sorter: (a, b) => a.provider_id - b.provider_id,
            render: (provider_id) => <MappedDictItem id={provider_id} type={'providers'} link={false}/>
        },
        {
            className: styles.topCol,
            title: t('Услуга'),
            dataIndex: 'service_id',
            width: '15%',
            key: 'service_id',
            sorter: (a, b) => a.service_id - b.service_id,
            render: (service_id) => <MappedDictItem id={service_id} type={'services'} link={false}/>
        },
        {
            title: t('Тариф'),
            dataIndex: 'tariffs',
            key: 'tariffs',
            render: (tariffs, record) => {
                // FIXME what to do, when tarif_id present, but map empty?
                if (record?.tarif_id && tariffsMap?.[record?.tarif_id]) {
                    return `${tariffsMap?.[record?.tarif_id]?.value ?? '-'} ${tariffsMap?.[record?.tarif_id]?.service_unit_id ? srvUnitMap[tariffsMap?.[record?.tarif_id]?.service_unit_id] ?? '' : ''}`
                } else {
                    // const tars = selPeriod === 'current' ? tariffs?.filter(t => dayjs(t.tarif_date_from).isBefore(dayjs(curDate).endOf('month'))) : tariffs?.filter(t => dayjs(t.tarif_date_from).isAfter(dayjs(curDate).endOf('month')))
                    return tariffs?.length > 0 ? <Space direction={'vertical'}>
                        {tariffs.map(t => <div key={t.id}>{`${t.value} ${srvUnitMap[t?.service_unit_id]}`}</div>)}
                    </Space> : ''
                }
            }
        },
        {
            title: t('Начисления'),
            dataIndex: ['accrual', 'sum_calc'],
            key: 'sum_calc',
            sorter: (a, b) => a.sum_calc - b.sum_calc,
            render: (sum_calc) => <FormatedNumber value={sum_calc ?? 0}/>
        },
        {
            key: 'actions',
            dataIndex: 'provider_service_id',
            width: '5%',
            align: 'center',
            render: (id) => <Button icon={<EditOutlined />} onClick={() => selectSrvToEdit(id)}/>
        }
    ]

    return <Table
        dataSource={dataSource ?? []}
        columns={columns}
        pagination={false}
        bordered
        rowKey={(r) => `${r.account_provider_service_id}-${r.provider_service_id}-${r.provider_id}-${r.service_id}`}
    />
}
