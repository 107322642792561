import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getPermissionListFx = createEffect()
    .use(async (filters) => (await api().get(`/permission/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getPermissionByIdFx = createEffect()
    .use(async (id) => (await api().get(`/permission/${id}`)).data)

export const createPermissionFx = createEffect()
    .use(async (data) => (await api().post(`/permission/`, data)))
