import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Filters from './Filters.jsx';
import {
    $controllersList,
    $controllersMap,
    $selectedControllers,
    $filters,
    changeSelectedControllersEv,
    downloadFilesPerControllersEv, downloadFilesPerControllersFx, getControllersFx,
    InspectorFilesGate, downloadSingleFileEv, setUploadFileModalEv, $uploadedFilesData, getFileListFx, UploadedFilesGate
} from '../../../models/taskPdfToControllersModel/index.js';
import {useUnit} from 'effector-react';
import {Popconfirm, Progress, Tag} from 'antd';
import Tooltip from 'antd/es/tooltip';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useLocation, useSearchParams} from 'react-router-dom';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import dayjs from 'dayjs';
import {
    CheckCircleOutlined, CheckOutlined,
    CloseCircleOutlined, CloseOutlined,
    EyeOutlined,
    LoadingOutlined,
    SaveOutlined,
    StopOutlined
} from '@ant-design/icons';
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion.jsx";
import FileManagerUploadModal from "./FileManagerUploadModal.jsx";
import Table from "antd/es/table";
import {monthNames} from "../../../utils/monthNamesDict.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import CreatedBy from "../../../components/CreatedBy/CreatedBy.jsx";
import ButtonGroup from "antd/es/button/button-group.js";

export default function FileManager({table_name = null}) {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang') ?? 'ru'

    const {data, count} = useUnit($controllersList)
    const {data: filesData, count: filesCount} = useUnit($uploadedFilesData)
    const contMap = useUnit($controllersMap)
    const loading = useUnit(getControllersFx.pending)
    const onChangeSelect = useUnit(changeSelectedControllersEv)
    const selected = useUnit($selectedControllers)
    const setUploadEmailFileModal = useUnit(setUploadFileModalEv)
    const filesLoading = useUnit(getFileListFx.pending)

    const filters = useUnit($filters)
    const [search] = useSearchParams()
    const {pathname} = useLocation()

    const downloadFiles = useUnit(downloadFilesPerControllersEv)
    const downloadSingleFile = useUnit(downloadSingleFileEv)
    const dloadLoading = useUnit(downloadFilesPerControllersFx.pending)

    const onDownload = () => {
        downloadFiles({ids: selected, filters, report_date: dayjs(filters.pdf_month).format()})
    }

    const columns = [
        {
            title: t('Код'),
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: t('ФИО контролера'),
            dataIndex: 'name_ru',
            key: 'fio',
            width: '15%',
            render: (_, i) => lang === 'kz' && i.name_kz ? i.name_kz : i.name_ru
        },
        {
            title: t('Участок'),
            dataIndex: 'sector_ids',
            sorter: true,
            key: 'sector_ids',
            width: '15%',
            render: (sector_ids, row) => <CustomAccordion ids={sector_ids} count={row?.sector_count} type={'sector'}/>
        },
        // {
        //     title: t('Район города'),
        //     dataIndex: 'district_id',
        //     sorter: true,
        //     key: 'district_name',
        // },
        {
            title: t('Город'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town',
            render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>,
        },
        {
            title: t('Файл'),
            dataIndex: 'has_pdf',
            key: 'file',
            align: 'center',
            render: (has_pdf, record) => has_pdf && <Tooltip title={t('Скачать')}>
                <Button ghost type={'primary'}
                        onClick={() => downloadSingleFile(record.pdf_id)}
                >
                    <SaveOutlined />
                </Button>
            </Tooltip>,
        }
    ]

    const filesColumns = [
        {
            title: t('Статус загрузки'),
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (status, record) => <Tooltip
              title={
                  status === 'processing'
                    ? t('В обработке')
                    : status === 'file processed' || status === 'completed'
                      // ? record.load_error
                      ? t('Успешно')
                      : status === "stopped"
                        ? t('Остановлено')
                        : status === "error"
                          ? t('Ошибка')
                          :  '-'
              }
              placement={'right'}
            >
                {
                    status === 'processing'
                      ? <div>
                          <Progress type="circle" size={40} percent={parseInt(record.progress)}/>
                      </div>
                      : status === "file processed" ||  status === 'completed'
                        ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
                        : status === "stopped" ? <StopOutlined style={{color: 'red', fontSize: 24}}/>
                          : status === "error" ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                            : '-'
                }
            </Tooltip>
        },
        {
            title: t('Статус принятия'),
            dataIndex: 'is_approved',
            key: 'is_approved',
            align: 'center',
            width: '5%',
            render: (approved, record) =>
              approved === true ?
                <Tooltip title={'Файл подтвежден'}> <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>  </Tooltip>:
                approved === false ?
                  <Tooltip title={'Файл отклонен'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                  '-'
        },
        {
            title: t('Название файла'),
            dataIndex: 'file_name',
            sorter: true,
            key: 'filename',
            render: (filename) => filename.length > 20 ?
              <Tooltip title={filename}> {filename.substring(0, 20) + '...'}</Tooltip> :
              <Tooltip title={filename}>{filename}</Tooltip>
        },
        {
            title: t('Дата загрузки'),
            dataIndex: 'created_at',
            key: 'date',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем загружен'),
            dataIndex: 'created_by',
            key: 'created_by',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Дата решения'),
            dataIndex: 'decided_at',
            key: 'decided_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем решено'),
            dataIndex: 'decided_by',
            key: 'decided_by',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Всего строк'),
            dataIndex: 'total_row_count',
            key: 'total_row_count',
        },
        // {
        //     title: t('Действия'),
        //     width: '5%',
        //     align: 'center',
        //     key: 'actions',
        //     dataIndex: 'id',
        //     render: (id, record) => record.status === "processing"  ?
        //       <Tooltip
        //         title={t('Остановить загрузку')}
        //       >
        //           <Popconfirm title={t('Вы уверены, что хотите остановить загрузку этого файла?')}
        //                       onConfirm={() => stopUpload(id)}
        //                       okText={t('Да')}
        //           >
        //               <Button
        //                 icon={<StopOutlined />}
        //                 danger
        //                 type={'primary'}
        //                 // disabled={record.status === 'processing'}
        //
        //               />
        //           </Popconfirm>
        //       </Tooltip>
        //
        //       : record.status === 'file processed' ?
        //         <ButtonGroup>
        //             <Tooltip title={t('Просмотр строк')}>
        //                 <Link to={`${pathname}/view/${id}`} replace={true}>
        //                     <Button icon={<EyeOutlined /> }/>
        //                 </Link>
        //             </Tooltip>
        //             <Tooltip title={t('Подтвердить файл')}>
        //                 <Popconfirm title={t('Вы уверены, что хотите подтвердить этот файл?')}
        //                             onConfirm={() => accept(id)}
        //                             okText={t('Да')}
        //                 >
        //                     <Button icon={<CheckOutlined />} type={'primary'}/>
        //                 </Popconfirm>
        //             </Tooltip>
        //             <Tooltip title={t('Отклонить файл')}>
        //                 <Popconfirm title={t('Вы уверены, что хотите отклонить этот файл?')}
        //                             onConfirm={() => reject(id)}
        //                             okText={t('Да')}
        //                             okButtonProps={{danger: true}}
        //                 >
        //                     <Button icon={<CloseOutlined />} danger/>
        //                 </Popconfirm>
        //             </Tooltip>
        //         </ButtonGroup>
        //         : record.status === "completed" ?
        //           ''
        //           : record.status === "stopped" ?
        //             ''
        //             : record.status === "error" ?
        //               <Tooltip title={t('Просмотр строк')}>
        //                   <Button icon={<EyeOutlined />} href={`/${pathname}/view/` + id} />
        //               </Tooltip>
        //               : ''
        //
        // }
    ]

    const onCloseTag = (e) => {
        const data = selected.filter(item => item !== e)
        onChangeSelect(data)
    }


    return <>
        <InspectorFilesGate search={search}/>
        <UploadedFilesGate table_name={table_name}/>
        <Card>
            <Filters forDownload/>
        </Card>
        {
            table_name ?
              <Card>
                  <Button type={'primary'} onClick={setUploadEmailFileModal}>
                      {t('Загрузить файл')}
                  </Button>
                  <DictionaryProTable
                    style={{paddingTop: '30px'}}
                    loading={filesLoading}
                    initColumns={filesColumns}
                    data={filesData}
                    count={filesCount}
                    showActions={false}
                  />
              </Card>
              :
              ''
        }
        <Card>
            <Divider orientationMargin={0} orientation={'left'}>{t('Выбранные контролеры')}</Divider>
            <Row>
                {selected.length > 0
                    ? selected.map((i, idx) => <Tag
                            closable
                            onClose={(e) => {
                                e.preventDefault()
                                onCloseTag(i);
                            }}
                            key={idx}
                        >
                            {contMap[i]}
                        </Tag>
                    )
                    : filters.select_all === true
                        ? t('Все Контролеры') + ` (${count})`
                        : t('Нет')
                }
            </Row>

        </Card>
        <Card>
            <div style={{position: 'relative'}}>
                <Row
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        top: '6px',
                        left: '25px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        columnGap: '20px'
                    }}
                    justify={'space-between'}
                >
                    <Tooltip
                      title={!(selected.length > 0) ? t('Необходимо выбрать Контролеров') : null}>
                        <Button type={'primary'}
                                onClick={onDownload}
                                disabled={!(selected.length > 0)}
                                loading={dloadLoading}
                        >
                            {t('Скачать PDF по выбранным Контролерам за выбранный месяц')}
                        </Button>
                    </Tooltip>
                </Row>
                <DictionaryProTable
                    style={{paddingTop: '30px'}}
                    data={data}
                    count={count}
                    initColumns={columns}
                    loading={loading}
                    showActions={false}
                    rowKey={'id'}
                    rowSelection={{
                        onChange: onChangeSelect,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selected
                    }}
                />


            </div>
        </Card>
        <FileManagerUploadModal table_name={table_name}/>
    </>
}
