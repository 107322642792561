import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const EPDTemplatesListGate = createGate()
export const EPDTemplatesViewGate = createGate()
export const EPDTemplatesEditGate = createGate()
export const EPDTemplatesCreateGate = createGate()

export const submitCreateEPDTemplatesEv = createEvent()
export const submitEditEPDTemplatesEv = createEvent()
export const deleteEPDTemplatesEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
