import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Col from 'antd/es/col'
import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {useStoreMap} from "effector-react";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import DatePicker from "antd/es/date-picker";
import AddressREOSearchForm from "../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx";
import {useState} from "react";

export default function ReportComponentAddress({form}) {
    const {t} = useTranslation()
    return <Col span={24}>
        <AddressREOSearchForm
            extForm={form}
            extButtons={true}
            onlyItems={true}
            itemsAsRow={true}
        />
    </Col>
}
