import {useTranslation} from "react-i18next";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {useUnit} from "effector-react";
import {$saldoData, getSaldoListFx, SaldoGate} from "../../models/saldoModel/index.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames} from "../../utils/monthNamesDict.js";
import TESTPAGENotification from "../../components/TESTPAGENotification/TESTPAGENotification.jsx";

export default function SaldoPage(){
	const {t} = useTranslation()
	const {data, count} = useUnit($saldoData)
	const {search} = searchAndActions()
	const loading = useUnit(getSaldoListFx.pending)

	const columns = [
		{
			dataIndex: 'account_id',
			title: 'ЛС',
			key: 'account_id',
		},
		{
			dataIndex: 'provider_id',
			title: 'Поставщик',
			key: 'provider_id',
			render: (provider_id) => <MappedDictItem id={provider_id} type={'provider'} link={false}/>
		},
		{
			dataIndex: 'service_id',
			title: 'Услуга',
			key: 'service_id',
			render: (service_id) => <MappedDictItem id={service_id} type={'services'} link={false}/>
		},
		{
			dataIndex: 'year',
			title: 'Год',
			key: 'year',
		},
		{
			dataIndex: 'month',
			title: 'Месяц',
			key: 'month',
			render: (month) => t(monthNames[month])
		},
		{
			dataIndex: 'debit',
			title: 'ДО',
			key: 'debit',
		},
		{
			dataIndex: 'credit',
			title: 'КО',
			key: 'credit',
		},
		{
			dataIndex: 'opening',
			title: 'ВО',
			key: 'opening',
		},
		{
			dataIndex: 'outgoing',
			title: 'ИО',
			key: 'outgoing',
		}
	]

	const filters = [
		{name: 'account_id', label: t('Лицевой счет'), type: 'input'},
		{name: 'provider_ids', label: t('Поставщик'), type: 'select', dict: 'providers', multiple: true},
		{name: 'service_ids', label: t('Услуга'), type: 'select', dict: 'services', multiple: true},
		{name: 'year', label: t('Год'), type: 'input'},
		{name: 'month', label: t('Месяц'), type: 'input'},
		// {name: 'is_for_bank', label: t('Для БВУ'), type: 'switch'}
	]

	return <>
		<SaldoGate search={search.toString()}/>
        <TESTPAGENotification />

		<FilterBlock items={filters}/>
		<DictionaryProTable data={data} count={count} initColumns={columns} loading={loading} showActions={false} />
	</>
}
