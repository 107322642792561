import {
    $afterCreateAction,
    $createdConsumerCategoryId,
    $editConsumerCategoryStatus,
    $selectedConsumerCategory,
    $consumerCategoryList
} from './stores.js';
import {getConsumerCategoryListFx, getConsumerCategoryByIdFx} from './effects.js';
import {
    deleteConsumerCategoryEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ConsumerCategoryEditGate,
    ConsumerCategoryListGate,
    ConsumerCategoryViewGate,
    submitCreateConsumerCategoryEv,
    submitEditConsumerCategoryEv, ConsumerCategoryCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createConsumerCategoryCompleteEv = combineEvents({events: {ev: submitCreateConsumerCategoryEv, response: triggerSuccessOpsEv}, reset: ConsumerCategoryCreateGate.state})
const editConsumerCategoryCompleteEv = combineEvents({ev: submitEditConsumerCategoryEv, response: triggerSuccessOpsEv})
const deleteConsumerCategoryCompleteEv = combineEvents({ev: deleteConsumerCategoryEv, response: triggerSuccessOpsEv})

$consumerCategoryList.on(getConsumerCategoryListFx.doneData, (_, data) => data)
    .reset(ConsumerCategoryListGate.close)

$selectedConsumerCategory.on(getConsumerCategoryByIdFx.doneData, (_, data) => data)
    .reset([ConsumerCategoryEditGate.close, ConsumerCategoryViewGate.close])
// FIXME
$createdConsumerCategoryId.on(createConsumerCategoryCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "consumer_category")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editConsumerCategoryStatus.on(editConsumerCategoryCompleteEv, () => true)
    .reset(ConsumerCategoryEditGate.close)

sample({
    clock: [ConsumerCategoryEditGate.state, ConsumerCategoryViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getConsumerCategoryByIdFx
})

sample({
    clock: ConsumerCategoryListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getConsumerCategoryListFx
})

sample({
    source: ConsumerCategoryListGate.state,
    clock: [createConsumerCategoryCompleteEv, editConsumerCategoryCompleteEv, deleteConsumerCategoryCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getConsumerCategoryListFx
})

sample({
    clock: submitCreateConsumerCategoryEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'consumer_category', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedConsumerCategory,
    clock: submitEditConsumerCategoryEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1



        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'consumer_category', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteConsumerCategoryEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'consumer_category', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
