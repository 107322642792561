import {createEvent} from 'effector';
import {createGate} from 'effector-react';

// Ивенты для CRUD таблицы вложенных улиц
export const StreetsChildrenGate = createGate()
export const changeStreetsChildrenFiltersEv = createEvent()

export const setCreateEditChildStreetEmbeddedEv = createEvent()
export const resetCreateEditChildStreetEmbeddedEv = createEvent()
export const createChildStreetEv = createEvent()
export const deleteNewStreetEv = createEvent()
export const editChildStreetEv = createEvent()
export const deleteChildStreetEv = createEvent()