import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const KnpListGate = createGate()
export const KnpViewGate = createGate()
export const KnpEditGate = createGate()
export const KnpCreateGate = createGate()

export const submitCreateKnpEv = createEvent()
export const submitEditKnpEv = createEvent()
export const deleteKnpEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
