import {$emailSubApproveList, $selectedFile} from './stores.js';
import {approveEmailSubFx, getEmailApproveFileFx, getEmailSubApproveListFx, rejectEmailSubFx} from './effects.js';
import {
    approveEmailSubEv,
    EmailSubApproveListGate,
    rejectEmailSubEv, ViewEmailApproveFileGate,
} from './events.js';
import {sample} from 'effector';

$emailSubApproveList.on(getEmailSubApproveListFx.doneData, (_, payload) => payload)
    .reset(EmailSubApproveListGate.close)

$selectedFile.on(getEmailApproveFileFx.doneData, (_, payload) => payload)
    .reset(ViewEmailApproveFileGate.close)

sample({
    source: EmailSubApproveListGate.state,
    clock: [EmailSubApproveListGate.state.updates, approveEmailSubFx.doneData, rejectEmailSubFx.doneData],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search.toString(),
    target: getEmailSubApproveListFx
})

sample({
    clock: approveEmailSubEv,
    target: approveEmailSubFx
})

sample({
    clock: rejectEmailSubEv,
    target: rejectEmailSubFx
})

sample({
    clock: ViewEmailApproveFileGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => gate.id,
    target: getEmailApproveFileFx
})
