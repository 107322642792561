import {
    $afterCreateAction,
    $createdBankAccountId,
    $bankAccountsList, $editBankAccountStatus, $selectedBankAccount
} from './stores.js';
import {
    getBankAccountByIdFx,
    getBankAccountsListFx,
} from './effects.js';
import {
    deleteBankAccountEv,
    BankAccountCreateGate,
    BankAccountEditGate,
    BankAccountsListGate,
    BankAccountViewGate,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    submitCreateBankAccountEv,
    submitEditBankAccountEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createBankAccountCompleteEv = combineEvents({events: {ev: submitCreateBankAccountEv, response: triggerSuccessOpsEv}, reset: BankAccountCreateGate.state})
const editBankAccountCompleteEv = combineEvents({ev: submitEditBankAccountEv, response: triggerSuccessOpsEv})
const deleteBankAccountCompleteEv = combineEvents({ev: deleteBankAccountEv, response: triggerSuccessOpsEv})

$bankAccountsList.on(getBankAccountsListFx.doneData, (_, bankAccounts) => bankAccounts)
    .reset(BankAccountsListGate.close)

$selectedBankAccount.on(getBankAccountByIdFx.doneData, (_, bankAcc) => bankAcc)
    .reset([BankAccountViewGate.close, BankAccountEditGate.close])

$createdBankAccountId.on(createBankAccountCompleteEv, (_, {response}) => {
    const bank_account = response.find(i => i.type === 'bank_account')
    return bank_account ? bank_account?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editBankAccountStatus.on(editBankAccountCompleteEv, () => true)
    .reset(BankAccountEditGate.close)

sample({
    clock: BankAccountsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankAccountsListFx
})

sample({
    source: BankAccountsListGate.state,
    clock: [createBankAccountCompleteEv, editBankAccountCompleteEv, deleteBankAccountCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankAccountsListFx
})

sample({
    clock: BankAccountEditGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getBankAccountByIdFx
})

sample({
    clock: BankAccountViewGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getBankAccountByIdFx
})

sample({
    clock: submitCreateBankAccountEv,
    fn: (streets, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.provider_id < 0) {
            actions.push({operation: 'create', type: 'provider', value: {...payload.provider, id: idCounter}})
            payload.provider_id = idCounter
            idCounter--
            delete payload.provider
        }
        if (payload.bank_id < 0) {
            actions.push({operation: 'create', type: 'bank', value: {...payload.bank, id: idCounter}})
            payload.bank_id = idCounter
            idCounter--
            delete payload.bank
        }
        if (payload.bank_account_type_id < 0) {
            actions.push({operation: 'create', type: 'bank', value: {...payload.bank_account_type, id: idCounter}})
            payload.bank_account_type_id = idCounter
            idCounter--
            delete payload.bank_account_type
        }
        if (payload.currency_id < 0) {
            actions.push({operation: 'create', type: 'bank', value: {...payload.currency, id: idCounter}})
            payload.currency_id = idCounter
            idCounter--
            delete payload.currency
        }
        actions.push({operation: 'create', type: 'bank_account', value: {...payload, id: idCounter}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedBankAccount,
    clock: submitEditBankAccountEv,
    fn: (src, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.town_id < 0) {
            actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            payload.town_id = idCounter
            idCounter--
            delete payload.town
        }

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (src[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'bank_account', value: {object_id: src.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteBankAccountEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'bank_account', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
