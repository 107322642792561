import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $finalAction,
    $redirectPath,
    $selectedAcc,
    $selectedCorrection, $uploadConfirmDone,
    $uploadCorrectionMetadata,
    cancelCorrectionEv,
    cancelCorrectionFx,
    finishCorrectionEv,
    finishCorrectionFx,
    ProvSrvAccCorrectionGate,
    resetFinalActionEv,
    setFinalActionEv
} from '../../../models/dictionaryCorrectionModel/index.js';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import CorrectionTypeSettings from './CorrectionTypeSettings.jsx';
import CorrectionFilters from './CorrectionFilters.jsx';
import CorrectionCompareTable from './CorrectionCompareTable.jsx';
import AccountsTable from './AccountsTable.jsx';
import SelectedAccForm from './SelectedAccForm.jsx';
import UploadCorrectionModal from './components/UploadCorrectionModal.jsx';
import UploadCorrectionMeta from './components/UploadCorrectionMeta.jsx';
import Space from 'antd/es/space';


export default function CorrectionProvAccServ() {
    const {t} = useTranslation()
    const {id} = useParams()
    const navigate = useNavigate()

    const url = useUnit($redirectPath)
    const setAfterAction = useUnit(setFinalActionEv)
    const resetAfterAction = useUnit(resetFinalActionEv)
    const cancelCorrection = useUnit(cancelCorrectionEv)
    const finishCorrection = useUnit(finishCorrectionEv)
    const finishLoading = useUnit(finishCorrectionFx.pending)
    const cancelLoading = useUnit(cancelCorrectionFx.pending)

    const selAcc = useUnit($selectedAcc)
    const uploadMeta = useUnit($uploadCorrectionMetadata)
    const uploadConfirmDone = useUnit($uploadConfirmDone)

    const afterAction = useUnit($finalAction)
    const currentCorrection = useUnit($selectedCorrection)

    useEffect(() => {
        if (url && afterAction === 'close') {
            navigate(url)
        }
        return () => {
            if (afterAction === 'close') {
                resetAfterAction()
            }
        }
    }, [url, afterAction]);


    return <>
        <UploadCorrectionModal id={id}/>
        <ProvSrvAccCorrectionGate id={id}/>
        <CorrectionTypeSettings/>
        <Divider/>
        {uploadMeta
            ? (uploadConfirmDone
                ? null
                : <>
                    <UploadCorrectionMeta/>
                    <Divider/>
                </>)
            : <>
                <CorrectionFilters/>
                <Divider/>
                <AccountsTable />
                <Divider/>
            </>
        }
        {
            selAcc && <>
            <SelectedAccForm />
            <Divider />
            </>
        }
        <Row style={{gap: 16}}>
            <Button size={'small'} onClick={() => navigate('/corrections')}>
                {t('Вернуться к списку')}
            </Button>
            <Popconfirm title={t('Вы уверены, что хотите отменить корректировку?')}
                        onConfirm={() => {
                            setAfterAction('close')
                            cancelCorrection()
                        }}
                        okText={t('Да')}
                        cancelText={t('Нет')}
            >
                <Button danger size={'small'} loading={cancelLoading}>
                    {t('Отменить и закрыть')}
                </Button>
            </Popconfirm>
            {/*<Popconfirm title={t('Вы уверены, что хотите применить изменения и продолжить редактирование?')}*/}
            {/*            onConfirm={() => {*/}
            {/*                setAfterAction('resume')*/}
            {/*                finishCorrection()*/}
            {/*            }}*/}
            {/*            disabled={!currentCorrection?.data?.justification || currentCorrection?.data?.justification?.length < 1}*/}
            {/*            okText={t('Да')}*/}
            {/*            cancelText={t('Нет')}*/}
            {/*>*/}
            {/*    <Tooltip title={(!currentCorrection?.data?.justification || currentCorrection?.data?.justification?.length < 1) ? t('Введите обоснование') : null}>*/}
            {/*        <Button type={'primary'} size={'small'} loading={finishLoading} disabled={!currentCorrection?.data?.justification || currentCorrection?.data?.justification?.length < 1}>*/}
            {/*            {t('Сохранить и продолжить корректировку')}*/}
            {/*        </Button>*/}
            {/*    </Tooltip>*/}
            {/*</Popconfirm>*/}
            <Popconfirm
                title={t('Внимание!')}
                description={<Space direction={'vertical'}>
                    <span>{t('Вы уверены, что хотите применить изменения и закрыть корректировку?')}</span>
                    <span>{t('Были внесены изменения, влияющие на расчеты начислений. Расчет начислений будет перезапущен.')}</span>
                </Space>
                }
                onConfirm={() => {
                    setAfterAction('close')
                    finishCorrection()
                }}
                disabled={!currentCorrection?.data?.justification || currentCorrection?.data?.justification?.length < 1}
                okText={t('Да')}
                cancelText={t('Нет')}
            >
                <Tooltip title={(!currentCorrection?.data?.justification || currentCorrection?.data?.justification?.length < 1) ? t('Введите обоснование') : null}>
                    <Button size={'small'} type={'primary'} loading={finishLoading} disabled={!currentCorrection?.data?.justification || currentCorrection?.data?.justification?.length < 1}>
                        {t('Сохранить и закрыть')}
                    </Button>
                </Tooltip>
            </Popconfirm>
        </Row>
        {currentCorrection?.data?.has_corrections === true && <CorrectionCompareTable/>}
    </>
}
