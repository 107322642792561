import {
    $afterCreateAction,
    $createdStreetId,
    $editStreetStatus,
    $selectedStreet,
    $streetsList
} from './stores.js';
import {getStreetByIdFx, getStreetsListFx} from './effects.js';
import {
    deleteStreetEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, StreetCreateGate,
    StreetEditGate,
    StreetsListGate,
    StreetViewGate, submitCreateStreetEv, submitEditStreetEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $addressesChildren,
    $createdAddresses,
    $deletedAddresses
} from '../embeddedFormsModels/embeddedAddressesModel';

const createStreetCompleteEv = combineEvents({events: {ev: submitCreateStreetEv, response: triggerSuccessOpsEv}, reset: StreetCreateGate.state})
const editStreetCompleteEv = combineEvents({ev: submitEditStreetEv, response: triggerSuccessOpsEv})
const deleteStreetCompleteEv = combineEvents({ev: deleteStreetEv, response: triggerSuccessOpsEv})

$streetsList.on(getStreetsListFx.doneData, (_, streets) => streets)
    .reset(StreetsListGate.close)

$selectedStreet.on(getStreetByIdFx.doneData, (_, street) => street)
    .reset([StreetEditGate.close, StreetViewGate.close])

$createdStreetId.on(createStreetCompleteEv, (_, {response}) => {
    const street = response.find(i => i.type === 'street')
    return street ? street?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editStreetStatus.on(editStreetCompleteEv, () => true)
    .reset(StreetEditGate.close)

sample({
    clock: [StreetEditGate.state, StreetViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getStreetByIdFx
})

sample({
    clock: StreetsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getStreetsListFx
})

sample({
    source: StreetsListGate.state,
    clock: [createStreetCompleteEv, editStreetCompleteEv, deleteStreetCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getStreetsListFx
})

sample({
    source: $createdAddresses,
    clock: submitCreateStreetEv,
    fn: (addresses, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.town_id < 0) {
            actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            payload.town_id = idCounter
            idCounter--
            delete payload.town
        }

        if (payload.town_distr_id < 0) {
            actions.push({operation: 'create', type: 'town_distr', value: {...payload.district, town_id: payload.town_id, id: idCounter}})
            idCounter--
            delete payload.district
        }

        if (payload.street_type_id < 0) {
            actions.push({operation: 'create', type: 'street_type', value: {...payload.streetType, id: idCounter}})
            payload.street_type_id = idCounter
            idCounter--
            delete payload.streetType
        }

        actions.push({operation: 'create', type: 'street', value: {...payload, id: idCounter}})
        const street_id = idCounter
        idCounter--

        if (addresses.length > 0) {
            for (const address of addresses) {
                actions.push({operation: 'create', type: 'address', value: {...address, street_id, id: idCounter}})
                idCounter--
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {
        current: $selectedStreet,
        newAddresses: $createdAddresses,
        addresses: $addressesChildren.map(state => state.data),
        delAddresses: $deletedAddresses
    },
    clock: submitEditStreetEv,
    fn: ({current, newAddresses, addresses, delAddresses}, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.town_id < 0) {
            actions.push({operation: 'create', type: 'town', value: {...payload.city, id: idCounter}})
            payload.town_id = idCounter
            idCounter--
            delete payload.city
        }

        if (payload.town_distr_id < 0) {
            actions.push({operation: 'create', type: 'town_distr', value: {...payload.district, town_id: payload.town_id, id: idCounter}})
            idCounter--
            delete payload.district
        }

        if (payload.street_type_id < 0) {
            actions.push({operation: 'create', type: 'street_type', value: {...payload.streetType, id: idCounter}})
            idCounter--
            delete payload.streetType
        }

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        if (Object.keys(changedFields).length > 0) {
            actions.push({operation: 'update', type: 'street', value: {object_id: current.id, ...changedFields}})
        }

        if (newAddresses?.length > 0) {
            for (const address of newAddresses) {
                actions.push({operation: 'create', type: 'address', value: {...address, street_id: current.id, id: idCounter}})
                idCounter--
            }
        }

        for (const address of Object.values(addresses)) {
            if (address.id > 0 && address.changedFields?.length > 0) {
                const payload = {}
                for (const key of Object.values(address.changedFields)) {
                    payload[key] = address[key]
                }
                actions.push({operation: 'update', type: 'address', value: {object_id: address.id, ...payload}})
            }
        }

        if (delAddresses?.length > 0 ) {
            for (const id of delAddresses) {
                actions.push({operation: 'delete', type: 'address', value: {id}})
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteStreetEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'street', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})