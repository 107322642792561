import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const ConsumerCategoryListGate = createGate()
export const ConsumerCategoryViewGate = createGate()
export const ConsumerCategoryEditGate = createGate()
export const ConsumerCategoryCreateGate = createGate()

export const submitCreateConsumerCategoryEv = createEvent()
export const submitEditConsumerCategoryEv = createEvent()
export const deleteConsumerCategoryEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
