import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const OrgTypesListGate = createGate()
export const OrgTypeViewGate = createGate()
export const OrgTypeEditGate = createGate()
export const OrgTypeCreateGate = createGate()

export const submitCreateOrgTypeEv = createEvent()
export const submitEditOrgTypeEv = createEvent()
export const deleteOrgTypeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()