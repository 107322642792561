import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Tag from 'antd/es/tag';
import Filters from './Filters.jsx';
import {
    $accountsList, $filters,
    $selectedAccounts,
    changeSelectedAccountsEv,
    createTaskEv, getAccountsFx, TaskEmailAccGate, resetFiltersEv, changeFiltersEv,
} from '../../../models/taskEmailPerAccountsModel';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {useSearchParams} from 'react-router-dom';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import {Popconfirm, Switch} from 'antd';


export default function CreateTaskPerAccounts() {
    const {t} = useTranslation()

    const {data, count} = useUnit($accountsList)
    const loading = useUnit(getAccountsFx.pending)
    const onChangeSelect = useUnit(changeSelectedAccountsEv)
    const selected = useUnit($selectedAccounts)
    const createTask = useUnit(createTaskEv)
    const resetFilters = useUnit(resetFiltersEv)


    const filters = useUnit($filters)
    const changeFilters = useUnit(changeFiltersEv)




    const [search] = useSearchParams()


    const columns = [
        {
            title: 'Лицевой счет',
            dataIndex: 'id',
            sorter: true,
            key: 'account',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
            key: 'fio',
            render: (name_ru, i) => localStorage.getItem('lang') === 'kz' && i.name_kz ? i.name_kz : name_ru
        },
        {
            title: t('Email рассылка'),
            dataIndex: 'is_email_dispatch',
            align: 'center',
            sorter: true,
            key: 'email',
            render: (_, record) => record.is_email_delivery ? <span ><CheckOutlined style={{color: 'green'}}/></span> : <span><CloseOutlined style={{color: 'red'}}/></span>
        }
    ]

    const onCloseTag = (e) => {
        const data = selected.filter(item => item !== e)
        onChangeSelect(data)
    }

    const onCreateTask = () => {
        createTask()
        resetFilters()
        onChangeSelect([])
        // setParametrsModal(createdTask)
    }

    const onCreateTaskAll = () => {
        createTask()
        resetFilters()
        onChangeSelect([])
        // setParametrsModal(createdTask)
    }



    return <>
        <TaskEmailAccGate search={search}/>
        <Card>

        <Filters />
        </Card>

        <Card>
            <Divider orientationMargin={0} orientation={'left'}>{t('Выбранные ЛС')}</Divider>
            <Row>
                {/*FIXME*/}
                {selected.length > 0
                    // ? selected.map((i, idx) => <Tag key={idx}>{contMap[i]}</Tag>)
                    ? selected.map((i, idx) => <Tag
                            closable
                            onClose={(e) => {
                                e.preventDefault()
                                onCloseTag(i);
                            }}
                            key={idx}
                        >
                            {i}
                        </Tag>
                    )
                    : filters.select_all
                        ? t('Все ЛС') + ` (${count})`
                        : t('Нет')
                }
            </Row>
        </Card>

        <Card>

            <div style={{position: 'relative' }}>
                <Row
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        top: '6px',
                        left: '25px',
                        width: '900px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    justify={'space-between'}
                >
                    {
                        selected.length > 0 ?
                            <Popconfirm
                                title={'Вы уверены, что хотите выбрать всех? Ранее выбранные ЛС сбросятся'}
                                okText={t('Да')}
                                placement={'left'}
                                cancelText={t('Нет')}
                                onConfirm={onCreateTaskAll}
                            >
                                <Button type={'primary'}
                                        style={{marginRight: '10px'}}
                                >
                                    {t(`Создать рассылку по всем отфильтрованным ЛС (${count})`)}
                                </Button>
                            </Popconfirm>
                            :
                            <Button type={'primary'}
                                    style={{marginRight: '10px'}}
                                    onClick={onCreateTaskAll}
                            >
                                {t(`Создать рассылку по всем отфильтрованным ЛС (${count})`)}
                            </Button>
                    }

                    <Tooltip
                        title={!(filters?.select_all === true || selected.length > 0) ? t('Необходимо выбрать лицевые счета') : null}>
                        <Button
                            type={'primary'}
                            onClick={onCreateTask}
                            disabled={!(filters?.select_all === true || selected.length > 0)}
                        >
                            {t('Создать рассылку по выбранным ЛС')}
                        </Button>
                    </Tooltip>

                    <div style={{display: 'flex', gap: 4, marginLeft: 16}}>
                        <span>Пересоздать PDF</span>
                        <Switch value={filters.recreate_pdf} onChange={(checked) => changeFilters({
                            key: 'recreate_pdf',
                            value: checked
                        })}/>
                    </div>
                </Row>


                <DictionaryProTable
                    data={data}
                    count={count}
                    initColumns={columns}
                    loading={loading}
                    showActions={false}
                    rowKey={'id'}
                    rowSelection={{
                        onChange: onChangeSelect,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selected
                    }}
                />
            </div>
        </Card>

    </>
}
