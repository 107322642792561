import {
    $afterCreateAction,
    $createdRolePermissionId,
    $editRolePermissionStatus,
    $selectedRolePermission,
    $rolePermissionList
} from './stores.js';
import {getRolePermissionListFx, getRolePermissionByIdFx, getPermissionFx} from './effects.js';
import {
    deleteRolePermissionEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    RolePermissionEditGate,
    RolePermissionListGate,
    RolePermissionViewGate,
    submitCreateRolePermissionEv,
    submitEditRolePermissionEv, RolePermissionCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {resetCreateInspectorEmbeddedEv, resetCreateTownEmbeddedEv} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateRolePermissionEv, response: triggerSuccessOpsEv}, reset: RolePermissionCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditRolePermissionEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteRolePermissionEv, response: triggerSuccessOpsEv})

$rolePermissionList.on(getRolePermissionListFx.doneData, (_, data) => data)
    .reset(RolePermissionListGate.close)

// $rolePermissionList.on(getPermissionFx.doneData, (_, data) => data)
//     .reset(RolePermissionListGate.close)

$selectedRolePermission.on(getRolePermissionByIdFx.doneData, (_, data) => data)
    .reset([RolePermissionEditGate.close, RolePermissionViewGate.close])
// FIXME
$createdRolePermissionId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "role_permission")
    const result = data ? {newId: data?.value?.id, permission_id: data?.value?.permission_id } : undefined
    console.log('created rolePermission id', result)
    return result
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editRolePermissionStatus.on(editCompleteEv, () => true)
    .reset(RolePermissionEditGate.close)

sample({
    clock: [RolePermissionEditGate.state, RolePermissionViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getRolePermissionByIdFx
})

sample({
    clock: RolePermissionListGate.state,
    // filter: (gate) => Object.hasOwn(gate, 'search'),
    filter: (gate) => {
        // debugger
        return gate.search.includes('role_id')
    },
    fn: (gate) => gate.search,
    target: getRolePermissionListFx
})

// sample({
//     source: RolePermissionListGate.state,
//     clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
//     filter: (gate) => Object.hasOwn(gate, 'search'),
//     fn: (gate) => gate.search,
//     target: getRolePermissionListFx
// })


// sample({
//     clock: RolePermissionListGate.state,
//     filter: (gate) => Object.hasOwn(gate, 'search'),
//     fn: (gate) => gate.search,
//     target: getPermissionFx
// })

sample({
    clock: submitCreateRolePermissionEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'role_permission', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedRolePermission,
    clock: submitEditRolePermissionEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'role_permission', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteRolePermissionEv,
    fn: (id) => {
        const actions = []

        actions.push({operation: 'delete', type: 'role_permission', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [RolePermissionCreateGate.close, RolePermissionEditGate.close],
    target: [resetCreateTownEmbeddedEv, resetCreateInspectorEmbeddedEv]
})
