import React from 'react';
import TranslateForModels from "../../components/TranslateForModels/TranslateForModels.jsx";

export const ButtonDublicateDeleted = (type, id) => {
    return <div>
        Объект {TranslateForModels(type)} с  Кодом {id} удален.
        <br/>
        Для восстановления этого объекта обратитесь к Администратору.
        <br/>

    </div>;
};
