import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const PaymentDeductionListGate = createGate()
export const PaymentDeductionViewGate = createGate()
export const PaymentDeductionEditGate = createGate()
export const PaymentDeductionCreateGate = createGate()

export const submitCreatePaymentDeductionEv = createEvent()
export const submitEditPaymentDeductionEv = createEvent()
export const deletePaymentDeductionEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
