import {createStore} from 'effector';
import {getNationalityListFx} from "./effects.js";

export const $nationalityList = createStore({data: [], count: 0})

export const $selectedNationality = createStore(null)

export const $editNationalityStatus = createStore(false)

export const $createdNationalityId = createStore(null)
export const $afterCreateAction = createStore(null)
