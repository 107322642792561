import {createStore} from 'effector';


export const $serviceUnitsList = createStore({data: [], count: 0})

export const $selectedServiceUnits = createStore(null)

export const $editServiceUnitsStatus = createStore(false)

export const $createdServiceUnitsId = createStore(null)
export const $afterCreateAction = createStore(null)