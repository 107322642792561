import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const CurrencyListGate = createGate()
export const CurrencyViewGate = createGate()
export const CurrencyEditGate = createGate()
export const CurrencyCreateGate = createGate()

export const submitCreateCurrencyEv = createEvent()
export const submitEditCurrencyEv = createEvent()
export const deleteCurrencyEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
