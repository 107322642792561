import {
    $fileList,
    $selectedFile, $selectedFileApproveCells,
    $selectedFileCells,
    $uploadModal,
    $uploadStatus
} from './stores.js';
import {
    acceptFileFx, deleteFileFx, downloadErrorFileFx, exportXlsListFx, getFileApprovedInfoFx,
    getFileCellsFx, getFileInfoFx,
    getFilesListFx, rejectFileFx,
    sendMessageUploadFx, stopUploadFileFx,
    uploadFileFx
} from './effects.js';
import {
    acceptFileEv,
    acceptMessageUploadEv, deleteFileEv, deselectFileEv, exportXlsListEv,
    FilesGate, rejectFileEv,
    resetUploadModalEv,
    sendMessageUploadEv,
    setUploadModalEv, stopUploadFileEv,
    uploadFileEv, ViewFileCellsApproveGate, ViewFileCellsGate
} from './events.js';
import {sample} from 'effector';
import {downloadErrorFileEv} from "./events.js";

$fileList
    .on(acceptMessageUploadEv, (state, msg) => {
        const newList = [...state.data]

        newList.map((item) => {
            if (item.id === msg.id) {
                for (const [key, value] of Object.entries(msg)) {
                    item[key] = value
                }
            }
            return item
        })
        return {data: newList, count: state.count}
    })
    .on(getFilesListFx.doneData, (_, payload) => payload)
    // .on(stopUploadFileFx.doneData, (_, payload) => payload)
    .reset(FilesGate.close)


$uploadStatus.on(uploadFileFx.doneData, () => true)
    .reset(resetUploadModalEv)

$uploadModal.on(setUploadModalEv, () => true)
    .reset(resetUploadModalEv)

$selectedFile.on(getFileInfoFx.doneData, (_, file) => file)

    .reset(ViewFileCellsGate.close)

$selectedFileApproveCells.on(getFileApprovedInfoFx.doneData, (_, file) => file)

$selectedFileCells.on(getFileCellsFx.doneData, (_, cells) => cells)
    .reset(ViewFileCellsGate.close)

sample({
    source: FilesGate.state,
    clock: [FilesGate.state.updates, uploadFileFx.doneData, acceptFileFx.doneData, rejectFileFx.doneData, deleteFileFx.doneData],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getFilesListFx
})

sample({
    clock: uploadFileEv,
    target: uploadFileFx
})

sample({
    clock: ViewFileCellsGate.state.updates,
    // filter: (gate) => Object.hasOwn(gate, 'id'),
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => {
     return +gate.id
    },
    target: getFileInfoFx
})

sample({
    clock: ViewFileCellsApproveGate.state.updates,
    // filter: (gate) => Object.hasOwn(gate, 'id'),
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => {
        return {id: +gate.id, filters: gate.search.toString()}
    },
    target: getFileApprovedInfoFx
})




sample({
    clock: ViewFileCellsGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => ({id: +gate.id, filters: gate.search}),
    target: getFileCellsFx
})

sample({
    clock: downloadErrorFileEv,
    target: downloadErrorFileFx
})

sample({
    clock: acceptFileEv,
    target: acceptFileFx
})

sample({
    clock: rejectFileEv,
    target: rejectFileFx
})

sample({
    clock: stopUploadFileEv,
    target: stopUploadFileFx
})

sample({
    clock: stopUploadFileFx.doneData,
    target: getFilesListFx
})

sample({
    clock: deleteFileEv,
    target: deleteFileFx
})

sample({
    clock: exportXlsListEv,
    target: exportXlsListFx
})

sendMessageUploadEv.watch((data) => {
    sendMessageUploadFx(data)
})
