import {
    $afterCreateAction,
    $createdBankApiInfoId,
    $editBankApiInfoStatus,
    $selectedBankApiInfo,
    $bankApiInfoList
} from './stores.js';
import {getBankApiInfoListFx, getBankApiInfoByIdFx} from './effects.js';
import {
    deleteBankApiInfoEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    BankApiInfoEditGate,
    BankApiInfoListGate,
    BankApiInfoViewGate,
    submitCreateBankApiInfoEv,
    submitEditBankApiInfoEv, BankApiInfoCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {$createdIpAddresses, $deletedIpAddresses, $ipAddressesChildren} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateBankApiInfoEv, response: triggerSuccessOpsEv}, reset: BankApiInfoCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditBankApiInfoEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteBankApiInfoEv, response: triggerSuccessOpsEv})

$bankApiInfoList.on(getBankApiInfoListFx.doneData, (_, data) => data)
    .reset(BankApiInfoListGate.close)

$selectedBankApiInfo.on(getBankApiInfoByIdFx.doneData, (_, data) => data)
    .reset([BankApiInfoEditGate.close, BankApiInfoViewGate.close])
$createdBankApiInfoId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "bank_api_info")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editBankApiInfoStatus.on(editCompleteEv, () => true)
    .reset(BankApiInfoEditGate.close)

sample({
    clock: [BankApiInfoEditGate.state, BankApiInfoViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getBankApiInfoByIdFx
})

sample({
    clock: BankApiInfoListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankApiInfoListFx
})

sample({
    source: BankApiInfoListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankApiInfoListFx
})

sample({
    source: {ip: $createdIpAddresses, created: $createdIpAddresses, updated: $ipAddressesChildren},
    clock: submitCreateBankApiInfoEv,
    fn: (src, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        actions.push({operation: 'create', type: 'bank_api_info', value: {...payload, id: idCounter}})
        const bank_api_info = idCounter


        // debugger

        if (src?.ip?.length > 0) {
            const created = src?.ip.map(item => {
                return {
                    ...item,
                    bank_id: payload.bank_id
                }
            })
            for (const newDist of Object.values(created)) {
                idCounter--

                actions.push({operation: 'create', type: 'bank_api_address', value: {...newDist, id: idCounter}})
            }
        }


        if (src.updated.data.length > 0) {
            const updated = src?.updated?.data.filter(item => item.changedFields)
            for (const newDist of Object.values(updated)) {
                idCounter--
                actions.push({operation: 'update', type: 'bank_api_address', value: {...newDist, object_id: idCounter}})
            }
        }



        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {selected: $selectedBankApiInfo, created: $createdIpAddresses, updated: $ipAddressesChildren, deleted: $deletedIpAddresses},
    clock: submitEditBankApiInfoEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'bank_api_info', value: {object_id: current.selected.id, ...changedFields}})

        if (current?.created?.length > 0) {
            for (const newDist of Object.values(current?.created)) {
                idCounter--
                actions.push({operation: 'create', type: 'bank_api_address', value: {...newDist, id: idCounter}})
            }
        }

        if (current.updated.data.length > 0) {
            const updated = current?.updated?.data.filter(item => item.changedFields).map(item => {
                return {
                    object_id: item.id,
                    is_active: item.is_active,
                    ip_address: item.ip_address
                }
            })

            for (const newDist of Object.values(updated)) {
                idCounter--
                actions.push({operation: 'update', type: 'bank_api_address', value: {...newDist}})
            }
        }

        if (current.deleted.length > 0) {
            const deleted = current?.deleted

            for (const newDist of Object.values(deleted)) {
                idCounter--
                // debugger
                // actions.push({operation: 'update', type: 'bank_api_address', value: {...newDist, object_id: idCounter, bank_id: null}})
                actions.push({operation: 'delete', type: 'bank_api_address', value: {id: newDist}})
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteBankApiInfoEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'bank_api_info', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
