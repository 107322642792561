import AddressesTable from "../../../components/Embedded/AddressesTable/AddressesTable.jsx";
import dayjs from "dayjs";
import Tooltip from "antd/es/tooltip/index.js";
import Button from "antd/es/button/index.js";
import {
	CloseOutlined,
	DeleteOutlined,
	EditOutlined,
	EyeOutlined,
	HistoryOutlined,
	PlusOutlined
} from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group.js";
import Popconfirm from "antd/es/popconfirm/index.js";
import {useTranslation} from "react-i18next";
import Table from "antd/es/table";
import style from '../DictionaryAddresses.module.css'
import {useState} from "react";
import {Link, useParams} from "react-router-dom";
import CreateSecondaryAddress from "./CreateSecondaryAddress.jsx";
import SelectSecondaryAddress from "./SelectSecondaryAddress.jsx";
import {
	$chosenSecAddress,
	$secAddressList, removeSelectedSecAddressEv,
	SecondaryAddressListGate
} from "../../../models/dictionaryAddressesModel/index.js";
import {useUnit} from "effector-react";

export default function SecondaryAddressTable ({chosenTown ,chosenDistr}) {
	const {t} = useTranslation();
	const genitive_form = t(`breadcrumbs.genitive.address`)
	const secAddress = useUnit($chosenSecAddress)
	const [mode, setMode] = useState(null)
	const [fakeID, setFakeID] = useState(-1000)
	const {id} = useParams()
	const removeSelectedSecAddress = useUnit(removeSelectedSecAddressEv)

	const columns = [
		// {
		// 	title: t(`Код ${genitive_form}`),
		// 	dataIndex: 'id',
		// 	width: '5%',
		// 	align: 'center',
		// 	sorter: true,
		// 	key: 'id',
		// 	render: (id) => id > 0 ? id : 'Новый'
		// },
		{
			title: t('Код улицы'),
			dataIndex: 'street_id',
			sorter: true,
			onCell: (record) => ({className: record?.changedFields?.includes('street_id') ? 'table-cell-edited' : ''}),
		},
		{
			title: t('Название улицы (каз)'),
			dataIndex: 'street_kz',
			sorter: true,
			onCell: (record) => ({className: record?.changedFields?.includes('street_kz') ? 'table-cell-edited' : ''}),
		},
		{
			title: t('Название улицы (рус)'),
			dataIndex: 'street_ru',
			sorter: true,
			onCell: (record) => ({className: record?.changedFields?.includes('street_ru') ? 'table-cell-edited' : ''}),
		},
		{
			title: t('Номер дома'),
			dataIndex: 'house',
			sorter: true,
			onCell: (record) => ({className: record?.changedFields?.includes('house') ? 'table-cell-edited' : ''}),
		},
		{
			title: t('Номер корпуса'),
			dataIndex: 'corpus',
			sorter: true,
			onCell: (record) => ({className: record?.changedFields?.includes('corpus') ? 'table-cell-edited' : ''}),
		},
		{
			title: t('Актуален'),
			dataIndex: 'is_actual',
			sorter: true,
			onCell: (record) => ({className: record?.changedFields?.includes('is_actual') ? 'table-cell-edited' : ''}),
			render: (is_actual) => is_actual ? t('Да') : t('Нет')
		},
		{
			title: t('Актуален с'),
			dataIndex: 'actual_from',
			sorter: true,
			onCell: (record) => ({className: record?.changedFields?.includes('is_actual_from') ? 'table-cell-edited' : ''}),
			render: (actual_from) => actual_from ? dayjs(actual_from).format('DD.MM.YYYY') : '-'
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id) => <ButtonGroup>
				<Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
										okText={t('Да')}
										onConfirm={() => removeSelectedSecAddress(id)}
										placement={'left'}
				>
					<Tooltip title={t('Удалить')} placement={'top'}>
						<Button icon={<DeleteOutlined />} danger/>
					</Tooltip>
				</Popconfirm>
			</ButtonGroup>
		}
	]

	const resetMode = () => {
		setMode(null)
	}
	const afterSASubmit = () => {
		setMode(null)
		setFakeID(fakeID - 1)
	}

	return <>
		<SecondaryAddressListGate id={id}/>

		<div className={style.sec_addresses_buttons}>
			<Button type={mode === 'create' ? "primary" : 'default'} onClick={() => setMode('create')}>{t('Создать вспомогательный адрес')}</Button>
			<Button type={mode === 'select' ? "primary" : 'default'} onClick={() => setMode('select')}>{t('Выбрать вспомогательный адрес')}</Button>
		</div>
		{
			mode === 'create' ?
				<CreateSecondaryAddress
					fakeID={fakeID}
					afterSubmit={afterSASubmit}
					chosenTown={chosenTown}
					chosenDistr={chosenDistr}
					resetMode={resetMode}
				/>
				:
				mode === 'select' ?
					<SelectSecondaryAddress
						afterSubmit={afterSASubmit}
						chosenTown={chosenTown}
						chosenDistr={chosenDistr}
						resetMode={resetMode}
					/>
					:
					''
		}
		<Table columns={columns} dataSource={secAddress} size={'small'} rowKey={'id'}/>
	</>
}
