import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Card from 'antd/es/card';
import {PlusOutlined} from '@ant-design/icons';
import CreateTownEmbeddedForm from '../CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import CreateStTypeEmbeddedForm from '../CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $createEditStreetEmbedded,
    setCreateTownEmbeddedEv, setCreateStTypeEmbeddedEv, $createTownEmbeddedOpen, $createStreetTypeEmbedded
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import {useTranslation} from 'react-i18next';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import Space from 'antd/es/space/index.js';
import {useEffect} from 'react';
import StarredTitle from '../../StarredTitle/StarredTitle.jsx';

export default function CreateStreetEmbeddedForm({form, withTown, searchedTown, inRow, unique = null}) {
    const open = typeof unique === 'boolean' ? unique : useUnit($createEditStreetEmbedded)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const setStTypeEmbedded = useUnit(setCreateStTypeEmbeddedEv)

    // const openStTypeEmbedded = useUnit($createStreetTypeEmbedded)
    const openStTypeEmbedded = typeof unique === 'boolean' ? unique : useUnit($createStreetTypeEmbedded)

    console.log('openStTypeEmbedded', openStTypeEmbedded)

    const townFormOpen = useUnit($createTownEmbeddedOpen)

    // debugger
    const {t} = useTranslation()

    const {townsOpts, stTypesOpts} = useStoreMap($dictsSelects, sel => ({
        townsOpts: sel.towns,
        stTypesOpts: sel.streetTypes
    }))

    useEffect(() => {
        if (searchedTown) {
            form.setFieldsValue({street: {town_id: searchedTown}})
        }
    }, [form, searchedTown]);

    useEffect(() => {
        if (withTown && searchedTown && townFormOpen) {
            setTownEmbedded(false)
        }
    }, [withTown, searchedTown, townFormOpen]);


    return open && inRow === true ?
        <Col span={24}>
            <Card title={t('Создать улицу')} style={{marginBottom: 16, width: '100%'}} size={'small'}>
                <Row justify={'space-between'} gutter={[24, 24]} >
                    <Col span={6}>
                        <Card title={<StarredTitle title={t('Тип улицы')}/>} style={{height: '100%'}}
                              size={'small'}>
                            <Form.Item name={['street', 'street_type_id']}
                                       rules={[{required: true, message: t('Обязательное поле')}]}>
                                <Select
                                    options={[...stTypesOpts, {label: 'Создать', value: -1, emoji: true}]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setStTypeEmbedded(true)
                                        } else {
                                            setStTypeEmbedded(false)
                                        }
                                    }}
                                    allowClear
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                                <span role="img" aria-label={option.data.label}>
                                                  {option?.data?.emoji ?
                                                      <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                                </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                            {option?.data.label}
                                                        </span> :
                                                    <span>
                                                            {option?.data.label}
                                                        </span>
                                            }
                                        </Space>
                                    )}
                                />
                            </Form.Item>

                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card title={<StarredTitle title={t('Название (рус)')}/>} style={{height: '100%'}}
                              size={'small'}>
                            <Form.Item name={['street', 'name_ru']}
                                // label={t('Название (рус)')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card title={<StarredTitle title={t('Название (каз)')}/>} style={{height: '100%'}}
                              size={'small'}>
                            <Form.Item name={['street', 'name_kz']}
                                // label={t('Название (каз)')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Card>
            {
                openStTypeEmbedded &&  <Row>
                    <CreateStTypeEmbeddedForm inRow={true}/>
                </Row>
            }


        </Col> : open ?
        <Row justify={'space-between'} gutter={[16, 16]}>
            {withTown && <>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Населенный пункт')}/>} size={'small'}
                          style={{height: '100%'}}>
                        <Form.Item name={['street', 'town_id']}
                                   rules={[{required: true, message: t('Обязательное поле')}]}>
                            <Select
                                options={[...townsOpts, {label: 'Создать', value: -1, emoji: true}]}
                                allowClear
                                disabled={!!searchedTown}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setTownEmbedded(true)
                                    } else {
                                        setTownEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm/>
                    </Card>
                </Col>
            </>}
            <Col span={12}>
                <Card title={<StarredTitle title={t('Тип улицы')}/>} style={{height: '100%'}}
                      size={'small'}>
                    <Form.Item name={['street', 'street_type_id']}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <Select
                            options={[...stTypesOpts, {label: 'Создать', value: -1, emoji: true}]}
                            onChange={(v) => {
                                if (v === -1) {
                                    setStTypeEmbedded(true)
                                } else {
                                    setStTypeEmbedded(false)
                                }
                            }}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                            }
                            optionRender={(option) => (
                                <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                    {
                                        option?.data?.emoji ?
                                            <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                            <span>
                                                    {option?.data.label}
                                                </span>
                                    }
                                </Space>
                            )}
                        />
                    </Form.Item>
                    {
                        openStTypeEmbedded &&  <Row>
                            <CreateStTypeEmbeddedForm />
                        </Row>
                    }

                </Card>
            </Col>
            <Col span={withTown ? 24 : 12}>
                <Card title={'Свойства улицы'} size={'small'} style={{height: '100%'}}>
                    <Form.Item name={['street', 'name_kz']}
                               label={t('Название (каз)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['street', 'name_ru']}
                               label={t('Название (рус)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                </Card>
            </Col>
        </Row> : ''
}
