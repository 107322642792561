import {
    $afterCreateAction,
    $createdRealEstateObjectTypeId,
    $editRealEstateObjectTypeStatus,
    $selectedRealEstateObjectType,
    $realEstateObjectTypeList,
} from './stores.js';
import {getRealEstateObjectTypeListFx, getRealEstateObjectTypeByIdFx} from './effects.js';
import {
    deleteRealEstateObjectTypeEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    RealEstateObjectTypeEditGate,
    RealEstateObjectTypeListGate,
    RealEstateObjectTypeViewGate,
    submitCreateRealEstateObjectTypeEv,
    submitEditRealEstateObjectTypeEv, RealEstateObjectTypeCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createRealEstateObjectTypeCompleteEv = combineEvents({events: {ev: submitCreateRealEstateObjectTypeEv, response: triggerSuccessOpsEv}, reset: RealEstateObjectTypeCreateGate.state})
const editRealEstateObjectTypeCompleteEv = combineEvents({ev: submitEditRealEstateObjectTypeEv, response: triggerSuccessOpsEv})
const deleteRealEstateObjectTypeCompleteEv = combineEvents({ev: deleteRealEstateObjectTypeEv, response: triggerSuccessOpsEv})

$realEstateObjectTypeList.on(getRealEstateObjectTypeListFx.doneData, (_, data) => data)
    .reset(RealEstateObjectTypeListGate.close)

$selectedRealEstateObjectType.on(getRealEstateObjectTypeByIdFx.doneData, (_, data) => data)
    .reset([RealEstateObjectTypeEditGate.close, RealEstateObjectTypeViewGate.close])
// FIXME
$createdRealEstateObjectTypeId.on(createRealEstateObjectTypeCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "real_estate_object_type")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editRealEstateObjectTypeStatus.on(editRealEstateObjectTypeCompleteEv, () => true)
    .reset(RealEstateObjectTypeEditGate.close)

sample({
    clock: [RealEstateObjectTypeEditGate.state, RealEstateObjectTypeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getRealEstateObjectTypeByIdFx
})

sample({
    clock: RealEstateObjectTypeListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getRealEstateObjectTypeListFx
})

sample({
    source: RealEstateObjectTypeListGate.state,
    clock: [createRealEstateObjectTypeCompleteEv, editRealEstateObjectTypeCompleteEv, deleteRealEstateObjectTypeCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getRealEstateObjectTypeListFx
})

sample({
    clock: submitCreateRealEstateObjectTypeEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'real_estate_object_type', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedRealEstateObjectType,
    clock: submitEditRealEstateObjectTypeEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'real_estate_object_type', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteRealEstateObjectTypeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'real_estate_object_type', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
