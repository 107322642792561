import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import {
    $selectedEconomySector,
    EconomySectorViewGate,
    getEconomySectorByIdFx
} from "../../../models/dictionaryEconomySectorModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";

export default function ViewEconomySector() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getEconomySectorByIdFx.pending)
    const data = useUnit($selectedEconomySector)
    const genitive_form = t(`breadcrumbs.genitive.economy-sector`);

    const items = [
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: data?.name_kz
        },
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: data?.name_ru
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <EconomySectorViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр сектора экономики')}</span> <MappedDictItem id={id} type={'economy-sector'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'economy-sector'}/>
        </Card>
    </>
}
