import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';

import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';

import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';
import useFormChanged from '../../../hooks/useFormChanged.js';


import {
    $editBankApiAddressStatus,
    $selectedBankApiAddress,
    BankApiAddressEditGate, getBankApiAddressByIdFx,
    submitEditBankApiAddressEv
} from "../../../models/dictionaryBankApiAddressModel/index.js";

export default function EditBankApiAddress() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedBankApiAddress)

    const [bankApiAddressForm] = Form.useForm()

    const submit = useUnit(submitEditBankApiAddressEv)

    const loading = useUnit(getBankApiAddressByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editBankApiAddressStatus)
    const genitive_form = t(`breadcrumbs.genitive.bank-api-address`);


    useEffect(() => {
        if (status) {
            navigate('/dictionaries/bank-api-address')
        }
    }, [status]);


    useEffect(() => {
        if (selected) {
            bankApiAddressForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'town_id', 'town_distr_id', 'street_type_id'])

    return <>
        <BankApiAddressEditGate id={id} />
        <Card loading={loading}>
            <Form form={bankApiAddressForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => submit(v)}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <CreateStTypeEmbeddedForm />
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'index'}
                                   label={t('Индекс')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['index']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        bankApiAddressForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {bankApiAddressForm.submit()}}
                            type={'primary'}
                    >
                        {t('Сохранить')}
                    </Button>
                </Space>
            </Row>
        </Card>
    </>
}
