import {
    $afterCreateAction,
    $createdPositionId,
    $editPositionStatus,
    $selectedPosition,
    $positionList
} from './stores.js';
import {getPositionListFx, getPositionByIdFx, createPositionFx} from './effects.js';
import {
    deletePositionEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    PositionEditGate,
    PositionListGate,
    PositionViewGate,
    submitCreatePositionEv,
    submitEditPositionEv, PositionCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createPositionCompleteEv = combineEvents({events: {ev: submitCreatePositionEv, response: triggerSuccessOpsEv}, reset: PositionCreateGate.state})
const editPositionCompleteEv = combineEvents({ev: submitEditPositionEv, response: triggerSuccessOpsEv})
const deletePositionCompleteEv = combineEvents({ev: deletePositionEv, response: triggerSuccessOpsEv})

$positionList.on(getPositionListFx.doneData, (_, data) => data)
    .reset(PositionListGate.close)

$selectedPosition.on(getPositionByIdFx.doneData, (_, data) => data)
    .reset([PositionEditGate.close, PositionViewGate.close])
// FIXME
$createdPositionId.on(createPositionCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "position")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editPositionStatus.on(editPositionCompleteEv, () => true)
    .reset(PositionEditGate.close)

sample({
    clock: [PositionEditGate.state, PositionViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getPositionByIdFx
})

sample({
    clock: PositionListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPositionListFx
})

sample({
    source: PositionListGate.state,
    clock: [createPositionCompleteEv, editPositionCompleteEv, deletePositionCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPositionListFx
})

sample({
    clock: submitCreatePositionEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'position', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedPosition,
    clock: submitEditPositionEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'position', value: {object_id: current.id, ...changedFields}})


        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deletePositionEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'position', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
