import {createStore} from 'effector';


export const $paymentDeductionList = createStore({data: [], count: 0})

export const $selectedPaymentDeduction = createStore(null)

export const $editPaymentDeductionStatus = createStore(false)

export const $createdPaymentDeductionId = createStore(null)
export const $afterCreateAction = createStore(null)
