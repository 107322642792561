import {createStore} from 'effector';

export const $addressesList = createStore({data: [], count: 0})

export const $selectedAddress = createStore(null)

export const $editAddressStatus = createStore(false)

export const $createdAddressId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $secAddressList = createStore({data: [], count: 0})
export const $chosenSecAddress = createStore([])

export const $mainAddressList = createStore({data: [], count: 0})
export const $chosenMainAddress = createStore(null)

$chosenSecAddress.watch(state => console.log('$chosenSecAddress', state))
