import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const setCreateTownEmbeddedEv = createEvent()
export const resetCreateTownEmbeddedEv = createEvent()
export const setCreateDistrictEmbeddedEv = createEvent()
export const setCreateStTypeEmbeddedEv = createEvent()
export const setCreateStreetEmbeddedEv = createEvent()
export const setCreateReoEmbeddedEv = createEvent()


export const setCreateEpdTemplateFieldEmbeddedEv = createEvent()
export const resetCreateEpdTemplateFieldEmbeddedEv = createEvent()


// Ивенты для CRUD таблицы вложенных IP адресов
export const IpAddressesChildrenGate = createGate()
export const changeIpAddressesChildrenFiltersEv = createEvent()

export const setCreateEditChildIpAddressEmbeddedEv = createEvent()
export const resetCreateEditChildIpAddressEmbeddedEv = createEvent()
export const createChildIpAddressEv = createEvent()
export const deleteNewIpAddressEv = createEvent()
export const editChildIpAddressEv = createEvent()
export const deleteChildIpAddressEv = createEvent()

// Ивенты для CRUD таблицы вложенных участков
export const SectorsChildrenGate = createGate()
export const changeSectorsChildrenFiltersEv = createEvent()

export const setCreateEditChildSectorsEmbeddedEv = createEvent()
export const resetCreateEditChildSectorsEmbeddedEv = createEvent()
export const createChildSectorsEv = createEvent()
export const selectChildSectorsEv = createEvent()
export const deleteNewSectorsEv = createEvent()
export const editChildSectorsEv = createEvent()
export const deleteChildSectorsEv = createEvent()


// Ивенты для CRUD таблицы вложенных Полей начислений для ЕПД
export const EPDTemplateFieldChildrenGate = createGate()
export const changeEPDTemplateFieldChildrenFiltersEv = createEvent()

export const setCreateEditChildEPDTemplateFieldEmbeddedEv = createEvent()
export const resetCreateEditChildEPDTemplateFieldEmbeddedEv = createEvent()
export const createChildEPDTemplateFieldEv = createEvent()
export const deleteNewEPDTemplateFieldEv = createEvent()
export const editChildEPDTemplateFieldEv = createEvent()
export const deleteChildEPDTemplateFieldEv = createEvent()


// Ивенты для CRUD таблицы вложенных Услуг
export const ServicesChildrenGate = createGate()
export const changeServicesChildrenFiltersEv = createEvent()

export const setCreateEditChildServicesEmbeddedEv = createEvent()
export const resetCreateEditChildServicesEmbeddedEv = createEvent()
export const createChildServicesEv = createEvent()
export const deleteNewServicesEv = createEvent()
export const editChildServicesEv = createEvent()
export const deleteChildServicesEv = createEvent()



// Ивенты для CRUD таблицы вложенных Контролеров


export const InspectorsChildrenGate = createGate()
export const changeInspectorsChildrenFiltersEv = createEvent()

export const setCreateEditChildInspectorsEmbeddedEv = createEvent()
export const resetCreateEditChildInspectorsEmbeddedEv = createEvent()
export const createChildInspectorsEv = createEvent()
export const deleteNewInspectorsEv = createEvent()
export const editChildInspectorsEv = createEvent()
export const deleteChildInspectorsEv = createEvent()

export const selectChildInspectorEv = createEvent()


// Ивенты для CRUD таблицы вложенных Услуг поставщиков


export const APSChildrenGate = createGate()
export const changeAPSChildrenFiltersEv = createEvent()

export const setCreateEditChildAPSEmbeddedEv = createEvent()
export const resetCreateEditChildAPSEmbeddedEv = createEvent()
export const createChildAPSEv = createEvent()
export const deleteNewAPSEv = createEvent()
export const editChildAPSEv = createEvent()
export const deleteChildAPSEv = createEvent()

export const selectChildAPSEv = createEvent()



export const setCreateInspectorEmbeddedEv = createEvent()
export const resetCreateInspectorEmbeddedEv = createEvent()

export const setCreateSectorEmbeddedEv = createEvent()
export const resetCreateSectorEmbeddedEv = createEvent()


export const setCreateRealEstateObjTypeEmbeddedEv = createEvent()
export const resetCreateRealEstateObjTypeEmbeddedEv = createEvent()


export const setCreateChiefInspectorEmbeddedEv = createEvent()
export const resetCreateChiefInspectorEmbeddedEv = createEvent()
