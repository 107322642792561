import {createGate} from 'effector-react';
import {createEvent} from 'effector';


export const BankAccountTypeListGate = createGate()
export const BankAccountTypeViewGate = createGate()
export const BankAccountTypeEditGate = createGate()
export const BankAccountTypeCreateGate = createGate()

export const submitCreateBankAccountTypeEv = createEvent()
export const submitEditBankAccountTypeEv = createEvent()
export const deleteBankAccountTypeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()