import {createStore} from 'effector';


export const $economySectorList = createStore({data: [], count: 0})

export const $selectedEconomySector = createStore(null)

export const $editEconomySectorStatus = createStore(false)

export const $createdEconomySectorId = createStore(null)
export const $afterCreateAction = createStore(null)
