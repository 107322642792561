import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useEffect} from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import {
    $createEditChildDistrictEmbedded, resetCreateEditChildDistrictsEmbeddedEv
} from '../../../models/embeddedFormsModels/embeddedDistrictsModel';
import useFormChanged from '../../../hooks/useFormChanged.js';

export default function CreateEditDistrictEmbeddedForm({onCreate, onEdit}) {
    const embedded = useUnit($createEditChildDistrictEmbedded)
    const reset = useUnit(resetCreateEditChildDistrictsEmbeddedEv)

    const {t} = useTranslation()
    const [districtForm] = Form.useForm()

    const [check, changedFields] = useFormChanged(embedded.item, ['name_ru', 'name_kz'])


    const onClose = () => {
        districtForm.resetFields()
        reset()
    }

    useEffect(() => {
        if (embedded.open && embedded.type === 'edit' && embedded.idx !== null && embedded.item) {
            districtForm.setFieldsValue({...embedded.item})
        }
    }, [embedded]);

    const onSubmit = (values) => {
        if (embedded.type === 'edit') {
            onEdit({payload: values, id: embedded.item.id})
        } else if (embedded.type === 'create') {
            onCreate(values)
        }
        onClose()
    }

    return embedded.open &&
        <Form form={districtForm}
              size={'small'}
              layout={'vertical'}
              onFinish={onSubmit}
              style={{width: '100%', marginBottom: 16}}
              onValuesChange={(_, all) => {
                  if (embedded.type === 'edit') {
                      check(all)
                  }
              }}
        >
            <Card title={t('Свойства района')} size={'small'} style={{height: '100%'}}>
                        <Form.Item name={'name_ru'} label={t('Название (рус)')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['name_ru'] : undefined}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_kz'}
                                   label={t('Название (каз)')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['name_kz'] : undefined}
                        >
                            <Input/>
                        </Form.Item>
            </Card>
            <Row style={{gap: 8, marginTop: 16}}>
                <Button onClick={onClose} danger>{t('Отмена')}</Button>
                <Button type={'primary'} onClick={districtForm.submit}>{t('Сохранить')}</Button>
            </Row>
        </Form>
}
