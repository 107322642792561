import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';

import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';

import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';
import useFormChanged from '../../../hooks/useFormChanged.js';


import {
    $cellType, $columnNumber,
    $editEPDTemplateFieldStatus, $fieldName,
    $selectedEPDTemplateField,
    EPDTemplateFieldEditGate, getEPDTemplateFieldByIdFx,
    submitEditEPDTemplateFieldEv
} from "../../../models/dictionaryEpdTemplateFieldModel/index.js";
import {Select} from "antd";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryEpdTemplateFieldModel/index.js";

export default function EditEpdTemplateField() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedEPDTemplateField)

    const [epdTemplateFieldForm] = Form.useForm()
    const templateOpts = useStoreMap($dictsSelects, sel => sel.template)

    const submit = useUnit(submitEditEPDTemplateFieldEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const loading = useUnit(getEPDTemplateFieldByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editEPDTemplateFieldStatus)
    const fieldNameOpt = useUnit($fieldName)
    const cellTypeOpt = useUnit($cellType)
    const columnNumberOpt = useUnit($columnNumber)
    const genitive_form = t(`breadcrumbs.genitive.epd-template-fields`);



    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/epd-template-fields?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            epdTemplateFieldForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'town_id', 'town_distr_id', 'street_type_id'])

    return <>
        <EPDTemplateFieldEditGate id={id}/>
        <Card loading={loading}>
            <Form form={epdTemplateFieldForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => submit(v)}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <CreateStTypeEmbeddedForm />
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'template_id'}
                                   label={t('Шаблон')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['template_id']}

                        >
                            <Select disabled={true} options={templateOpts}/>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'field_name'}
                                   label={t(`Название ${genitive_form}`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['field_name']}
                        >
                            <Select
                                options={fieldNameOpt}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'cell_type'}
                                   label={t('Тип ячейки')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['cell_type']}
                        >
                            <Select
                                options={cellTypeOpt}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'column_number'}
                                   label={t('Номер столбца')}
                                   rules={[
                                       {required: true, message: t('Обязательное поле')},
                                       {
                                           pattern: /^[0-9]+$/,
                                           message: 'Только цифры',
                                       },
                                   ]}
                                   validateStatus={changedFields['column_number']}
                        >
                            <Select
                                options={columnNumberOpt}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'font_size'}
                                   label={t('Размер шрифта')}
                                   rules={[
                                       {required: false, message: t('Обязательное поле')},
                                       {
                                           pattern: /^[0-9]+$/,
                                           message: 'Только цифры',
                                       },
                                   ]}
                                   validateStatus={changedFields['font_size']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                </Row>


            </Form>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        epdTemplateFieldForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                epdTemplateFieldForm.submit()
                                setAction('edit')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                epdTemplateFieldForm.submit()
                                setAction('list')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и вернуться к списку')}
                    </Button>
                </Space>
            </Row>
        </Card>
    </>
}
