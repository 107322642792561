import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import {useUnit} from 'effector-react';
import {
    $createChiefInspectorEmbeddedOpen,
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';

export default function CreateChiefInspectorEmbeddedForm() {
    const {t} = useTranslation()
    const open = useUnit($createChiefInspectorEmbeddedOpen)


    return open && <Row justify={'space-between '} >
                       <Form.Item name={'chief_name_ru'}
                                  label={t('ФИО старшего контролера (рус)')}
                                  rules={[{required: true, message: t('Обязательное поле')}]}
                       >
                           <Input/>
                       </Form.Item>
                       <Form.Item name={'chief_name_kz'}
                                  label={t('ФИО старшего контролера (каз)')}
                                  style={{marginRight: '10px'}}
                                  rules={[{required: true, message: t('Обязательное поле')}]}
                       >
                           <Input/>
                       </Form.Item>
                   </Row>
    // </Card>
}
