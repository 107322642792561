import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import FormItem from 'antd/es/form/FormItem';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {useEffect, useState} from 'react';
import Form from 'antd/es/form/index.js';
import {useStoreMap} from 'effector-react';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import Select from 'antd/es/select/index.js';
import {filterOption, optionRender} from '../../../utils/selectUtils.jsx';
import CreatePayTypeEmbedded from './CreatePayTypeEmbedded.jsx';

export default function CreatePenSrvEmbedded({open, form, namespace, prefixRu, prefixKz, shortPrefixRu, shortPrefixKz}) {
    const {t} = useTranslation()
    const [paymentEmbedded, setPaymentEmbedded] = useState(false)
    const selectedPayType = Form.useWatch([namespace, 'service_payment_type_id'], form)
    const nRu = Form.useWatch([namespace, 'name_ru'], form)
    const nKz = Form.useWatch([namespace, 'name_kz'], form)
    const snRu = Form.useWatch([namespace, 'short_name_ru'], form)
    const snKz = Form.useWatch([namespace, 'short_name_kz'], form)

    useEffect(() => {
        if (!paymentEmbedded && selectedPayType === -1) {
            setPaymentEmbedded(true)
        } else if (paymentEmbedded && selectedPayType !== -1) {
            setPaymentEmbedded(false)
            form.resetFields([[namespace, 'new_payment_type']])
        }
    }, [selectedPayType, paymentEmbedded]);

    const srvPayTypeOpts = useStoreMap($dictsSelects, sel => [...sel.servicePaymentType, {label: t('Создать'), emoji: true, value: -1}])

    useEffect(() => {
        if (open) {
            if (prefixRu) {
                form.setFieldValue([namespace, 'name_ru'], `Пеня за ${prefixRu}`)
            }
            if (prefixKz) {
                form.setFieldValue([namespace, 'name_kz'], `${prefixKz} үшін айыппұл`)
            }
            if (shortPrefixRu) {
                form.setFieldValue([namespace, 'short_name_ru'], `Пеня за ${shortPrefixRu}`)
            }
            if (shortPrefixKz) {
                form.setFieldValue([namespace, 'short_name_kz'], `${shortPrefixKz} үшін айыппұл`)
            }
        }
    }, [open, prefixRu, prefixKz, shortPrefixRu, shortPrefixKz]);

    return <Card title={t('Создание пеневой услуги')}>
        <FormItem name={[namespace, 'service_class_id']} initialValue={22} hidden />
        <Row gutter={[8, 0]}>
            <Col span={12}>
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <FormItem name={[namespace, 'name_ru']} label={t('Название (рус)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                            <Input />
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem name={[namespace, 'short_name_ru']} label={t('Крат. Название (рус)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                            <Input />
                        </FormItem>
                    </Col>
                </Row>
            </Col>
            <Col span={6}>
                <FormItem name={[namespace, 'name_kz']} label={t('Название (каз)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Input />
                </FormItem>
            </Col>
            <Col span={4}>
                <FormItem name={[namespace, 'short_name_kz']} label={t('Крат. Название (каз)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Input />
                </FormItem>
            </Col>
        </Row>
        <Row gutter={[8, 0]}>
            <Col span={6}>
                <FormItem
                    name={[namespace, 'service_payment_type_id']}
                    label={t('Вид платежа')}
                    rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Select
                        options={srvPayTypeOpts}
                        filterOption={filterOption}
                        optionRender={optionRender}
                        showSearch allowClear
                    />
                </FormItem>
            </Col>
            {paymentEmbedded && <Col span={16}>
                <CreatePayTypeEmbedded
                    form={form}
                    open={paymentEmbedded}
                    namespace={[namespace, 'new_payment_type']}
                    prefixRu={nRu}
                    prefixKz={nKz}
                    shortPrefixRu={snRu}
                    shortPrefixKz={snKz}
                />
            </Col>}
        </Row>
    </Card>
}