import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const EconomySectorListGate = createGate()
export const EconomySectorViewGate = createGate()
export const EconomySectorEditGate = createGate()
export const EconomySectorCreateGate = createGate()

export const submitCreateEconomySectorEv = createEvent()
export const submitEditEconomySectorEv = createEvent()
export const deleteEconomySectorEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
