import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './assets/locales/ru/translation.json';
import kz from './assets/locales/kz/translation.json';

const resources = {
    ru: {
        translation: ru,
    },
    kz: {
        translation: kz,
    }
}

await i18next.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('lang') ?? 'ru',
    debug: false,
    fallbackLng: 'ru',
    interpolation: { escapeValue: false },
    ns: 'translation',
    defaultNS: 'translation',
})

export default i18next;
