import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedFile,
    $selectedFileCells, acceptFileEv,
    getFileCellsFx, rejectFileEv, ViewFileCellsGate, downloadErrorFileEv
} from '../../../models/uploadAccountsModel';
import {usePaginationControl} from '../../../hooks/usePaginationControl.js';
import {useServerSort} from '../../../hooks/useServerSort.js';
import dayjs from 'dayjs';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import Card from 'antd/es/card';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import styles from '../Upload.module.css';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useState} from 'react';
import Switch from 'antd/es/switch';
import Button from 'antd/es/button';
import {Popconfirm} from "antd";
import Collapse from "antd/es/collapse";
import TextArea from "antd/es/input/TextArea.js";
import {useServerSortPage} from '../../../hooks/useServerSortPage.js';

export default function ViewAccountsFileCells() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const selectedFile = useUnit($selectedFile)
    const {data, count} = useUnit($selectedFileCells)
    const loading = useUnit(getFileCellsFx.pending)
	const accept = useUnit(acceptFileEv)
	const reject = useUnit(rejectFileEv)
    const downloadErrorFile = useUnit(downloadErrorFileEv)

    const {id} = useParams()
    const [search, setSearch] = useSearchParams()

    const [curPage, limit, _field, _order, onChangeTable] = useServerSortPage()

    const [valid, setValid] = useState(!!(search.get('validation') && search.get('validation')==='true'))
    const onValidChange = (checked) => {
        setValid(!!checked)
        search.set('validation', checked)
        setSearch(search)
    }

    const confirmDisabled = !(selectedFile?.status === 'file processed')
    const downloadErrors = selectedFile?.error_row_count > 0

    const textError = (data, value) => {
        const e = data || ''
        const se = e.split('|')
        const te = se.shift()
        const ie = se.join(', ')
        const error = t(`import.errors.${te}`)
        if (value === undefined)
            return <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
        return <div>
            <div>{value}</div>
            <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
        </div>
    }

    const columns = [
        {
            title: t('№ строки'),
            dataIndex: 'row_index',
            align: 'center',
            key: 'row_index',
            sorter: true,
        },
        {
            title: t('Статус'),
            dataIndex: 'row_is_valid',
            align: 'center',
            key: 'row_is_valid',
            sorter: true,
            onCell: (record) => ({className: !record?.row_is_valid && styles.invalid}),
            render: (value, record) => !record?.row_is_valid ?
                [...new Set(((record?.row_error || '').split('\n') || []))].map(e => textError(e)) :
                <CheckOutlined/>
        },
        // {
        //     title: t('Код файла'),
        //     dataIndex: 'import_file_id',
        //     align: 'center',
        //     key: 'import_file_id',
        //     sorter: true,
        // },
        {
            title: t('ЛС'),
            dataIndex: 'account_id_value',
            align: 'center',
            key: 'account_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.account_id_error && styles.invalid}),
            render: (value, record) => record?.account_id_error ?
                textError(record?.account_id_error, value) : value
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_value',
            align: 'center',
            key: 'name_value',
            sorter: true,
            onCell: (record) => ({className: record?.name_error && styles.invalid}),
            render: (value, record) => record?.name_error ?
                textError(record?.name_error, value) : value
        },
        {
            title: t('Код улицы'),
            dataIndex: 'street_id_value',
            align: 'center',
            key: 'street_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.street_id_error && styles.invalid}),
            render: (value, record) => record?.street_id_error ?
                textError(record?.street_id_error, value) : value
        },
        // {
        //     title: t('Код адреса'),
        //     dataIndex: 'address_id_value',
        //     align: 'center',
        //     key: 'address_id_value',
        //     sorter: true,
        //     onCell: (record) => ({className: record?.address_id_error && styles.invalid}),
        //     render: (value, record) => record?.address_id_error ?
        //         record?.address_id_error :
        //         value
        // },
        {
            title: t('Дом'),
            dataIndex: 'house_value',
            align: 'center',
            key: 'house_value',
            sorter: true,
            onCell: (record) => ({className: record?.house_error && styles.invalid}),
            render: (value, record) => record?.house_error ?
                textError(record?.house_error, value) : value
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat_value',
            align: 'center',
            key: 'flat_value',
            sorter: true,
            onCell: (record) => ({className: record?.flat_error && styles.invalid}),
            render: (value, record) => record?.flat_error ?
                textError(record?.flat_error, value) : value
        },
        {
            title: t('Кол-во проживающих'),
            dataIndex: 'people_quantity_value',
            align: 'center',
            key: 'people_quantity_value',
            sorter: true,
            onCell: (record) => ({className: record?.people_quantity_error && styles.invalid}),
            render: (value, record) => record?.people_quantity_error ?
                textError(record?.people_quantity_error, value) : value
        },
        {
            title: t('Общая площадь'),
            dataIndex: 'full_square_value',
            align: 'center',
            key: 'full_square_value',
            sorter: true,
            onCell: (record) => ({className: record?.full_square_error && styles.invalid}),
            render: (value, record) => record?.full_square_error ?
                textError(record?.full_square_error, value) : value
        },
        {
            title: t('ИИН'),
            dataIndex: 'iin_value',
            align: 'center',
            key: 'iin_value',
            sorter: true,
            onCell: (record) => ({className: record?.iin_error && styles.invalid}),
            render: (value, record) => record?.iin_error ?
                textError(record?.iin_error, value) : value
        },
        {
            title: t('Моб. телефон'),
            dataIndex: 'mobile_phone_value',
            align: 'center',
            key: 'mobile_phone_value',
            sorter: true,
            onCell: (record) => ({className: record?.mobile_phone_error && styles.invalid}),
            render: (value, record) => record?.mobile_phone_error ?
                textError(record?.mobile_phone_error, value) : value
        },
        {
            title: t('Флаг рассылки по Электрон. почте'),
            dataIndex: 'is_email_delivery_value',
            align: 'center',
            key: 'is_email_delivery_value',
            sorter: true,
            onCell: (record) => ({className: record?.is_email_delivery_error && styles.invalid}),
            render: (value, record) => record?.is_email_delivery_error ?
                textError(record?.is_email_delivery_error, value) :
                value === true ? <CheckOutlined /> : <CloseOutlined />
        },
        {
            title: t('Электрон. почта'),
            dataIndex: 'email_value',
            align: 'center',
            key: 'email_value',
            sorter: true,
            onCell: (record) => ({className: record?.email_error && styles.invalid}),
            render: (value, record) => record?.email_error ?
                textError(record?.email_error, value) : value
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus_value',
            align: 'center',
            key: 'corpus_value',
            sorter: true,
            onCell: (record) => ({className: record?.corpus_error && styles.invalid}),
            render: (value, record) => record?.corpus_error ?
                textError(record?.corpus_error, value) : value
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id_value',
            align: 'center',
            key: 'town_distr_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.town_distr_id_error && styles.invalid}),
            render: (value, record) => record?.town_distr_id_error ?
                textError(record?.town_distr_id_error, value) : value
        },
        {
            title: t('Индекс'),
            dataIndex: 'index_value',
            align: 'center',
            key: 'index_value',
            sorter: true,
            onCell: (record) => ({className: record?.index_error && styles.invalid}),
            render: (value, record) => record?.index_error ?
                textError(record?.index_error, value) : value
        },
        {
            title: t('Этаж'),
            dataIndex: 'floor_value',
            align: 'center',
            key: 'floor_value',
            sorter: true,
            onCell: (record) => ({className: record?.floor_error && styles.invalid}),
            render: (value, record) => record?.floor_error ?
                textError(record?.floor_error, value) : value
        },
        {
            title: t('Количество комнат'),
            dataIndex: 'room_count_value',
            align: 'center',
            key: 'room_count_value',
            sorter: true,
            onCell: (record) => ({className: record?.room_count_error && styles.invalid}),
            render: (value, record) => record?.room_count_error ?
                textError(record?.room_count_error, value) : value
        },
        {
            title: t('Жилая площадь'),
            dataIndex: 'living_square_value',
            align: 'center',
            key: 'living_square_value',
            sorter: true,
            onCell: (record) => ({className: record?.living_square_error && styles.invalid}),
            render: (value, record) => record?.living_square_error ?
                textError(record?.living_square_error, value) : value
        },
        {
            title: t('Отапливаемая площадь'),
            dataIndex: 'heated_square_value',
            align: 'center',
            key: 'heated_square_value',
            sorter: true,
            onCell: (record) => ({className: record?.heated_square_error && styles.invalid}),
            render: (value, record) => record?.heated_square_error ?
                textError(record?.heated_square_error, value) : value
        },
        {
            title: t('Телефон'),
            dataIndex: 'phone_value',
            align: 'center',
            key: 'phone_value',
            sorter: true,
            onCell: (record) => ({className: record?.phone_error && styles.invalid}),
            render: (value, record) => record?.phone_error ?
                textError(record?.phone_error, value) : value
        },
        {
            title: t('Код АЭСбыт'),
            dataIndex: 'aesbyt_account_value',
            align: 'center',
            key: 'aesbyt_account_value',
            sorter: true,
            onCell: (record) => ({className: record?.aesbyt_account_error && styles.invalid}),
            render: (value, record) => record?.aesbyt_account_error ?
                textError(record?.aesbyt_account_error, value) : value
        },
        {
            title: t('Код энергетической кампании'),
            dataIndex: 'energy_account_value',
            align: 'center',
            key: 'energy_account_value',
            sorter: true,
            onCell: (record) => ({className: record?.energy_account_error && styles.invalid}),
            render: (value, record) => record?.energy_account_error ?
                textError(record?.energy_account_error, value) : value
        },
        // {
        //     title: t('КОД ОН'),
        //     dataIndex: 'real_estate_object_id_value',
        //     align: 'center',
        //     key: 'real_estate_object_id_value',
        //     sorter: true,
        //     onCell: (record) => ({className: record?.real_estate_object_id_error && styles.invalid}),
        //     render: (value, record) => record?.real_estate_object_id_error ?
        //         record?.real_estate_object_id_error :
        //         value
        // },
    ]

    const items = [
        {
            key: 'total_rows',
            label: t('Всего строк'),
            children: selectedFile?.total_row_count
        },
        {
            key: 'error_row_count',
            label: t('Ошибочных строк'),
            children: selectedFile?.error_row_count
        },
        {
            key: 'imported_row_count',
            label: t('Импортировано строк'),
            children: selectedFile?.imported_row_count
        },
    ]

    const dateItems = [
        {
            key: 'load_date',
            label: t('Дата загрузки'),
            children: selectedFile?.created_at && dayjs(selectedFile?.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'to_list',
            children: <Button
                onClick={() => navigate("../..", {relative: "path", replace: true})}
            >{t('К списку')}</Button>
        },
        {
            key: 'confirm',
            children: <Popconfirm
                title={t('Вы уверены, что хотите подтвердить этот файл?')}
                description={'Внесены в Систему будут только валидные строки'}
                onConfirm={() => {
                    accept(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
            >
                <Button type={'primary'} disabled={confirmDisabled}>{t('Подтвердить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'cancel',
            children: <Popconfirm
                title={t('Вы уверены, что хотите отклонить этот файл?')}
                onConfirm={() => {
                    reject(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
                okButtonProps={{danger: true}}
            >
                <Button danger>{t('Отклонить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'downloadErrors',
            children: <Button
                disabled={!downloadErrors}
                onClick={() => downloadErrorFile(id)}
            >{t('Скачать файл с ошибками')}</Button>
        },
    ]

    return <Card title={`${t('Просмотр строк файла')} ${selectedFile?.file_name}`}>
        <ViewFileCellsGate id={id} search={search} />
        <ViewDictionaryItemCommon showNav={false} items={items} itemsDates={dateItems}/>
        <Divider/>
        {selectedFile?.error ? <Collapse
            size="small"
            items={[
                {
                    key: '1',
                    label: t('Лог ошибок обработки файла'),
                    children: <TextArea rows={40} value={selectedFile?.error} />,
                },
            ]}
        /> : null}
        <Divider />
        <div style={{display: 'flex', gap: 4, marginBottom: 24}}>
            <Switch checked={valid} onChange={onValidChange} checkedChildren={'Валидные'} unCheckedChildren={'Ошибочные'} />
        </div>
        <Table dataSource={data}
               loading={loading}
               columns={columns}
               onChange={(pagination, _filters, sorter) => {
                   onChangeTable({page: pagination.current, limit: pagination.pageSize, sort_field: sorter?.field ?? null, sort_order: sorter?.order ?? null})
               }}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(curPage),
                   total: count
               }}
               size={'small'}
               className={'table-container'}
        />
    </Card>
}
