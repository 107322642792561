export default function FormatNumber({value, type}) {
    let maximumFractionDigits = 2
    if (type === 0) maximumFractionDigits = 0
    if (type === 2) maximumFractionDigits = 2
    if (type === 3) maximumFractionDigits = 3

    if (Number.isNaN(Number.parseFloat(value)) || value === '-' || value === undefined || value === null) {
        return value?.toLocaleString('ru-RU')
    } else {
        return new Intl.NumberFormat('ru-RU', {
            maximumFractionDigits,
            minimumFractionDigits: maximumFractionDigits,
        }).format(value)
    }
}
