import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const TaskPdfToContsGate = createGate()
export const InspectorFilesGate = createGate()
export const UploadedFilesGate = createGate()
export const ViewFilesGate = createGate()

export const changeFiltersEv = createEvent()

export const applyFiltersEv = createEvent()

export const resetFiltersEv = createEvent()

export const changeSelectedControllersEv = createEvent()

export const createTaskEv = createEvent()
export const acceptMessageUploadEmailEv = createEvent()

export const downloadFilesPerControllersEv = createEvent()
export const downloadSingleFileEv = createEvent()

export const resetTaskCreatedEv = createEvent()

export const resetUploadFileModalEv = createEvent()
export const uploadFileEv = createEvent()
export const setUploadFileModalEv = createEvent()
export const approveFileEv = createEvent()
export const disaproveFileEv = createEvent()

export const stopImportTaskEv = createEvent()
export const stopConfirmTaskEv = createEvent()

