import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {
    $afterCreateAction,
    $createdTownId,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    submitCreateTownEv
} from '../../../models/dictionaryTownsModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import CreateEditDistrictEmbedded
    from '../../../components/Embedded/CreateEditDistrictEmbedded/CreateEditDistrictEmbedded.jsx';
import DistrictsTable from '../../../components/Embedded/DistrictsTable/DistrictsTable.jsx';
import StreetsTable from '../../../components/Embedded/StreetsTable/StreetsTable.jsx';

export default function CreateTown() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [cityForm] = Form.useForm()
    const submit = useUnit(submitCreateTownEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const createdId = useUnit($createdTownId)
    const action = useUnit($afterCreateAction)
    const resetAttrs = useUnit(resetAfterCreationAttrs)


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/town/edit/${createdId}`)
            } else if (action === 'new') {
                cityForm.resetFields()
            } else if (action === 'list') {
                cityForm.resetFields()
                navigate(`/dictionaries/town?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);

    const setAction = useUnit(setAfterCreateActionEv)

    return <>
        <Form form={cityForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Card title={t('Свойства города')} size={'small'}>
                <Row justify={'space-between'}>
                    <Col span={7}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название города (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название города (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name={'index'}
                                   label={t('Индекс')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Card title={t('Районы')} size={'small'} style={{marginTop: 16}}>
            <DistrictsTable />
        </Card>
        <Card title={t('Улицы')} size={'small'} style={{marginTop: 16}}>
            <StreetsTable />
        </Card>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    cityForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            cityForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующий')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            cityForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            cityForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и перейти к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
