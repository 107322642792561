import {createStore} from 'effector';

// Сторы для форм-врезок parent-сущностей (когда создается только одна)
export const $createTownEmbeddedOpen = createStore(false)
export const $createInspectorEmbeddedOpen = createStore(false)
export const $createSectorEmbeddedOpen = createStore(false)
export const $createRealEstateObjTypeEmbeddedOpen = createStore(false)
export const $createChiefInspectorEmbeddedOpen = createStore(false)
export const $createDistrictEmbeddedOpen = createStore(false)
export const $createStreetTypeEmbedded = createStore(false)
export const $createEditStreetEmbedded = createStore(false)
export const $createEpdTemplateFieldEmbeddedOpen = createStore(false)
export const $createReoEmbeddedOpen = createStore(false)

// Сторы для CRUD таблицы вложенных IP адресов
export const $createEditChildIpAddressEmbedded = createStore({open: false, type: null, idx: null, item: null})
export const $ipAddressEmbeddedFilters = createStore({page: 1, limit: 10})
export const $ipAddressesChildren = createStore({data: [], count: 0})
export const $selectedChildIpAddressesImmutable = createStore([])
export const $createdIpAddresses = createStore([])
export const $deletedIpAddresses = createStore([])

// Сторы для CRUD таблицы вложенных участков
export const $createEditChildSectorsEmbedded = createStore({open: false, type: null, idx: null, item: null})
export const $sectorsEmbeddedFilters = createStore({page: 1, limit: 10})
export const $sectorsChildren = createStore({data: [], count: 0})
export const $selectedChildSectorsImmutable = createStore([])
export const $createdSectors = createStore([])
export const $deletedSectors = createStore([])


// Сторы для CRUD таблицы вложенных Полей начислений для ЕПД
export const $createEditChildEPDTemplateFieldEmbedded = createStore({open: false, type: null, idx: null, item: null})
export const $EPDTemplateFieldEmbeddedFilters = createStore({page: 1, limit: 10})
export const $EPDTemplateFieldChildren = createStore({data: [], count: 0})
export const $selectedChildEPDTemplateFieldImmutable = createStore([])
export const $createdEPDTemplateField = createStore([])
export const $deletedEPDTemplateField = createStore([])


// Сторы для CRUD таблицы вложенных Услуг
export const $createEditChildServicesEmbedded = createStore({open: false, type: null, idx: null, item: null})
export const $ServicesEmbeddedFilters = createStore({page: 1, limit: 10})
export const $ServicesChildren = createStore({data: [], count: 0})
export const $selectedChildServicesImmutable = createStore([])
export const $createdServices = createStore([])
export const $deletedServices = createStore([])


// Сторы для CRUD таблицы вложенных Контролеров
export const $createEditChildInspectorsEmbedded = createStore({open: false, type: null, idx: null, item: null})
export const $InspectorsEmbeddedFilters = createStore({page: 1, limit: 10})
export const $InspectorsChildren = createStore({data: [], count: 0})
export const $selectedChildInspectorsImmutable = createStore([])
export const $createdInspectors = createStore([])
export const $deletedInspectors = createStore([])


// Сторы для CRUD таблицы вложенных Услуг поставщиков
export const $createEditChildAPSEmbedded = createStore({open: false, type: null, idx: null, item: null})
export const $APSEmbeddedFilters = createStore({page: 1, limit: 10})
export const $APSChildren = createStore({data: [], count: 0})
export const $selectedChildAPSImmutable = createStore([])
export const $createdAPS = createStore([])
export const $deletedAPS = createStore([])
