import Table from "antd/es/table";
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
    $accountsProviderServiceList, $providerAccountsList, AccrualsGate, ProviderAccountsGate,
} from "../../../models/dictionaryAccountsModel/index.js";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import FormatedNumber from "../../../components/FormatedNumber.jsx";
import {CloseCircleOutlined} from "@ant-design/icons";
import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import Space from "antd/es/space";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import FilterBlock from "../../../components/FiltersBlock/FiltersBlock.jsx";


export default function ProviderAccounts({isView, id}) {

    const {t} = useTranslation()
    const {search} = searchAndActions()

    const {data} = useUnit($providerAccountsList)

    const filters = [
        {name: 'prov_acc_current_month', label: t('Месяц'),  object: 'deduction'},
    ]


    const columns = [
        {
            title: t('Месяц'),
            dataIndex: 'current_month',
            width: '20%',
            render: (date) => date ? dayjs(date).format('MM.YY') : '-'
        },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'providers'}/>,
        },
        {
            title: t('№ договора поставщика с потребителем'),
            dataIndex: 'external_number',
            width: '20%',
        },
        {
            title: t('ФИО'),
            dataIndex: 'name',
            width: '20%',
        },
        {
            title: t('Код улицы'),
            dataIndex: 'street_id',
            width: '20%',
        },
        {
            title: t('Название улицы'),
            dataIndex: 'street_name',
            width: '20%',
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            width: '20%',
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
            width: '20%',
        },

    ]


    return <>
        <ProviderAccountsGate id={id} search={search.toString()} />
        <FilterBlock items={filters}/>

        <Table
            dataSource={data}
            columns={columns}
            bordered
            rowKey={'service_id'}
            className={'table-container'}
            pagination={false}
            size={'small'}
            // rowClassName={(record) => record.id === selectedService?.id ? 'selectedRowObject' : null}
        />
    </>
}
