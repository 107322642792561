import {
    $afterCreateAction,
    $createdServicePaymentTypeId,
    $editServicePaymentTypeStatus,
    $selectedServicePaymentType,
    $servicePaymentTypeList,
} from './stores.js';
import {
    deleteServicePaymentTypeEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ServicePaymentTypeEditGate,
    ServicePaymentTypeListGate,
    ServicePaymentTypeViewGate,
    submitCreateServicePaymentTypeEv,
    submitEditServicePaymentTypeEv, ServicePaymentTypeCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {getServicePaymentTypeListFx, getServicePaymentTypeByIdFx} from "./effects.js";

const createServicePaymentTypeCompleteEv = combineEvents({events: {ev: submitCreateServicePaymentTypeEv, response: triggerSuccessOpsEv}, reset: ServicePaymentTypeCreateGate.state})
const editServicePaymentTypeCompleteEv = combineEvents({ev: submitEditServicePaymentTypeEv, response: triggerSuccessOpsEv})
const deleteServicePaymentTypeCompleteEv = combineEvents({ev: deleteServicePaymentTypeEv, response: triggerSuccessOpsEv})

$servicePaymentTypeList.on(getServicePaymentTypeListFx.doneData, (_, servicePayTypes) => servicePayTypes)
    .reset(ServicePaymentTypeListGate.close)



$selectedServicePaymentType.on(getServicePaymentTypeByIdFx.doneData, (_, servicePayTypes) => servicePayTypes)
    .reset([ServicePaymentTypeEditGate.close, ServicePaymentTypeViewGate.close])

$createdServicePaymentTypeId.on(createServicePaymentTypeCompleteEv, (_, {response}) => {
    const servicePayType = response.find ( i => i.type === 'service_payment_type')
    return servicePayType ? servicePayType.value.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editServicePaymentTypeStatus.on(editServicePaymentTypeCompleteEv, () => true)
    .reset(ServicePaymentTypeListGate.close)

sample({
    clock: [ServicePaymentTypeEditGate.state, ServicePaymentTypeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getServicePaymentTypeByIdFx
})

sample({
    clock: ServicePaymentTypeListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServicePaymentTypeListFx
})

sample({
    source: ServicePaymentTypeListGate.state,
    clock: [createServicePaymentTypeCompleteEv, editServicePaymentTypeCompleteEv, deleteServicePaymentTypeCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServicePaymentTypeListFx
})

sample({
    clock: submitCreateServicePaymentTypeEv,
    fn: (payload) => {
        const actions = []
        actions.push({operation: 'create', type: 'service_payment_type', value: {...payload, id: -1}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedServicePaymentType,
    clock: submitEditServicePaymentTypeEv,
    fn: (current, payload) => {
        const actions = []
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'service_payment_type', value: {object_id: current.id, ...changedFields}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteServicePaymentTypeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'service_payment_type', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
