import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import {useUnit} from 'effector-react';
import {
    $createRealEstateObjTypeEmbeddedOpen,
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';

export default function CreateRealEstateObjTypeEmbeddedForm({inCol, namespace}) {
    const {t} = useTranslation()
    const open = useUnit($createRealEstateObjTypeEmbeddedOpen)

    return open && <>
        {
            inCol ?
                <Col span={24} >
                    <Col span={24}>
                        <Form.Item name={namespace ? [namespace, 'name_ru'] : 'real_estate_object_type_name_ru'}
                                   label={t('Название (рус)')}
                                   style={{marginRight: '10px'}}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={namespace ? [namespace, 'name_kz'] : 'real_estate_object_type_name_kz'}
                                   label={t('Название (каз)')}
                                   style={{marginRight: '10px'}}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Col> :
                <Row justify={'space-between '} >
                    <Col span={6}>
                        <Form.Item name={namespace ? [namespace, 'name_ru'] : 'real_estate_object_type_name_ru'}
                                   label={t('Название (рус)')}
                                   style={{marginRight: '10px'}}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={namespace ? [namespace, 'name_kz'] : 'real_estate_object_type_name_kz'}
                                   label={t('Название (каз)')}
                                   style={{marginRight: '10px'}}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
        }
        </>
}
