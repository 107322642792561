import {sample} from "effector";
import {
	CalcTypeAlgorithmCreateGate,
	CalcTypeAlgorithmEditGate,
	CalcTypeAlgorithmsGate, CalcTypeAlgorithmViewGate, deleteAlgorithmEv,
	resetAfterCreationAttrs,
	submitCreateAlgorithmEv,
	submitEditAlgorithmEv,
	setAfterCreateActionEv
} from "./events.js";
import {
	deleteAlgorithmFx,
	getAlgorithmByIdFx,
	getCalcTypeAlgorithmsFx,
} from "./effects.js";
import {
	$calcTypeAlgorithmsData,
	$createdCalcTypeAlgorithmId,
	$editAlgorithmStatus,
	$selectedAlgorithm,
	$afterCreateAction
} from "./stores.js";
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from "../dictionaryUniversalModel/index.js";

import {combineEvents} from "patronum";


const createSexCompleteEv = combineEvents({events: {ev: submitCreateAlgorithmEv, response: triggerSuccessOpsEv}, reset: CalcTypeAlgorithmEditGate.state})
const editSexCompleteEv = combineEvents({ev: submitEditAlgorithmEv, response: triggerSuccessOpsEv})
const deleteSexCompleteEv = combineEvents({ev: deleteAlgorithmEv, response: triggerSuccessOpsEv})

$calcTypeAlgorithmsData.on(getCalcTypeAlgorithmsFx.doneData, (_, data) => data)
	.reset(CalcTypeAlgorithmsGate.close)

$selectedAlgorithm.on(getAlgorithmByIdFx.doneData, (_, data) => data)
	.reset(CalcTypeAlgorithmEditGate.close)

$createdCalcTypeAlgorithmId.on(createSexCompleteEv, (_, data) => data)
	.reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
	.reset(resetAfterCreationAttrs)

$editAlgorithmStatus.on(editSexCompleteEv, () => true)
	.reset(CalcTypeAlgorithmEditGate.close)

sample({
	clock: CalcTypeAlgorithmsGate.state,
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => gate.search,
	target: getCalcTypeAlgorithmsFx
})

sample({
	source: $selectedAlgorithm,
	clock: submitCreateAlgorithmEv,
	fn: (source, clock) => {

		const actions = []
		let idCounter = -1

		actions.push({operation: 'create', type: 'algorithm', value: {...clock, id: idCounter}})
		return actions
	},
	target: submitGlobalUpdateEv
})

sample({
	source: $selectedAlgorithm,
	clock: submitEditAlgorithmEv,
	fn: (source, clock) => {

		const actions = []

		actions.push({operation: 'update', type: 'algorithm', value: {...clock, object_id: source.id}})
		return actions
	},
	target: submitGlobalUpdateEv
})

sample({
	source: CalcTypeAlgorithmsGate.state,
	clock: [createSexCompleteEv, editSexCompleteEv, deleteSexCompleteEv],
	target: getCalcTypeAlgorithmsFx
})

sample({
	clock: [CalcTypeAlgorithmEditGate.state, CalcTypeAlgorithmViewGate.state, CalcTypeAlgorithmCreateGate.state],
	filter: (gate) => Object.hasOwn(gate, 'id') && gate.id,
	fn: (gate) => gate.id,
	target: getAlgorithmByIdFx
})

sample({
	clock: deleteAlgorithmEv,
	target: deleteAlgorithmFx
})
