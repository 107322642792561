import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {
    $selectedTaxBranch, TaxBranchViewGate,
    getTaxBranchByIdFx
} from '../../../models/dictionaryTaxBranchModel';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';

export default function ViewTaxBranch() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getTaxBranchByIdFx.pending)
    const taxBranch = useUnit($selectedTaxBranch)
    const genitive_form = t(`breadcrumbs.genitive.tax_branch`)

    const items = [
        {
            key: 'town_id',
            label: t('Населенный пункт'),
            children: <MappedDictItem id={taxBranch?.town_id} type={'town'}/>
        },
        {
            key: 'town_distr_id',
            label: t('Район города'),
            children: <MappedDictItem id={taxBranch?.town_distr_id} type={'town_distr'}/>
        },
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: taxBranch?.name_kz
        },
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: taxBranch?.name_ru
        },
        {
            key: 'short_name_kz',
            label: t(`Кр. Название ${genitive_form} (каз)`),
            children: taxBranch?.short_name_kz
        },
        {
            key: 'short_name_ru',
            label: t(`Кр. Название ${genitive_form} (рус)`),
            children: taxBranch?.short_name_ru
        },
        {
            key: 'address_kz',
            label: t(`Адрес ${genitive_form} (каз)`),
            children: taxBranch?.address_kz
        },
        {
            key: 'address_ru',
            label: t(`Адрес ${genitive_form} (рус)`),
            children: taxBranch?.address_ru
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={taxBranch?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: dayjs(taxBranch?.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: taxBranch?.updated_by ? <CreatedBy id={taxBranch?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: taxBranch?.updated_at ? dayjs(taxBranch?.updated_at).format('DD.MM.YYYY HH:mm:ss') : '-'
        }
    ]

    return <>
        <TaxBranchViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр налогового отделения')}</span> <MappedDictItem id={id} type={'tax_branch'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'tax_branch'}/>
        </Card>
    </>
}
