import Modal from "antd/es/modal";
import {useUnit} from "effector-react";
import Form from "antd/es/form/index.js";
import {useEffect} from "react";
import UploadComponent from "../../../components/UploadComponent/UploadComponent.jsx";
import {useTranslation} from "react-i18next";
import {
	$uploadFileModal,
	$uploadStatus,
	resetUploadFileModalEv, uploadFileEv, uploadFileFx
} from "../../../models/taskPdfToControllersModel/index.js";

export default function FileManagerUploadModal({table_name}) {
	const {t} = useTranslation()
	const open = useUnit($uploadFileModal)
	const onClose = useUnit(resetUploadFileModalEv)
	const onUpload = useUnit(uploadFileEv)
	const loading = useUnit(uploadFileFx.pending)
	const status = useUnit($uploadStatus)
	const [fileForm] = Form.useForm()

	const onReset = () => {
		fileForm.resetFields()
		onClose()
	}

	const onSubmit = (values) => {
		onUpload({
			table_name,
			data: values
		})
	}

	useEffect(() => {
		if(status){
			onReset()
		}
	}, [status]);

	return <Modal open={open}
								width={'80vw'}
								title={t('Загрузка файла')}
								onCancel={onReset}
								okText={t('Загрузить')}
								cancelText={t('Отмена')}
								onOk={() => fileForm.submit()}
								okButtonProps={{loading: loading}}
								destroyOnClose
	>
		<Form form={fileForm} onFinish={onSubmit} layout={'vertical'} size={'small'}>
			<UploadComponent title={t('Файлы')} isForm formName={'files'} dragger multiple />
		</Form>
	</Modal>
}
