import {createStore} from 'effector';

export const $tasksList = createStore({data: [], count: 0})
export const $tasksFilters = createStore({
    status: null,
    page: 1,
    limit: 10,
    sort_field: 'created_at',
    sort_order: 'desc'
})

export const $taskLogsModal = createStore({open: false, logs: []})
export const $taskLogsIdModal = createStore({open: false, logs: [], id: null})
export const $taskLogsDataModal = createStore([])
export const $taskParametrsModal = createStore({open: false, args: []})
