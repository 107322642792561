import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import {
    $inspectorsList,
    getInspectorsListFx,
    InspectorsListGate,
    deleteInspectorsEv
} from "../../models/dictionaryInspectorsModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import CustomAccordion from "../../components/CustomAccordion/CustomAccordion.jsx";
import {$userPerms} from '../../models/infoModel/index.js';

export default function DictionaryInspectorsList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($inspectorsList)
    const loading = useUnit(getInspectorsListFx.pending)
    const onDelete = useUnit(deleteInspectorsEv)
    const perms = useUnit($userPerms)

    // const [search] = useSearchParams()
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.inspectors`)

    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        // {
        //     title: t(`ФИО ${genitive_form} (каз)`),
        //     dataIndex: 'name_kz',
        //     sorter: true,
        //     key: 'name_kz',
        //     render: (name, record) => <Link to={`/dictionaries/inspectors/view/${record.id}`}>{name}</Link>
        // },
        {
            // title: t(`ФИО ${genitive_form} (рус)`),
            title: t(`ФИО ${genitive_form}`),
            dataIndex: 'name_ru',
            sorter: true,
            key: 'surname_ru',
            render: (name, record) => <Link to={`/dictionaries/inspectors/view/${record.id}`}>{name}</Link>
        },
        {
            title: t(`Старший контролер`),
            dataIndex: 'chief_inspector_id',
            sorter: true,
            key: 'chief_inspector_id',
            render: (chief_inspector_id, record) => <MappedDictItem id={chief_inspector_id} type={'chief-inspector'}/>
        },
        {
            title: t(`Участок`),
            dataIndex: 'sector_ids',
            sorter: true,
            key: 'sector_ids',
            render: (sector_ids, record) => record.sector_count > 0 ? <Link to={`/dictionaries/sector/view/${sector_ids[0]}`}>{sector_ids[0]}</Link> : '-'
        },

        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                {perms?.inspectors?.update && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/inspectors/edit/${id}`}><Button icon={<EditOutlined/>}
                                                                             type={'primary'}/></Link>
                </Tooltip>}
                {perms?.inspectors?.view && <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/inspectors/view/${id}`}><Button icon={<EyeOutlined/>}/></Link>
                </Tooltip>}
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                {perms?.inspectors?.delete && <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                             okText={t('Да')}
                             onConfirm={() => onDelete(id)}
                             placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>}
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/ФИО контролера'), type: 'input'},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns'},
        {name: 'sector_id', label: t('Участок'), type: 'select', dict: 'sector'},
        {name: 'chief_inspector_id', label: t('Старший контролер'), type: 'select', dict: 'chief_inspector'},
        // {name: 'street_type_id', label: t('Тип'), type: 'select', dict: 'streetTypes', multiple: true},
        // {name: 'code_name', label: t('Код/ФИО'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]


    return <>
        <InspectorsListGate search={search.toString()}/>
        <FilterBlock items={filters} resetKeysOnTownChange={['sector_id', 'chief_inspector_id']}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />
    </>
}
