import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import PlusOutlined from '@ant-design/icons/es/icons/PlusOutlined';
import {
    submitEditTaxBranchEv,
    TaxBranchEditGate, $selectedTaxBranch, getTaxBranchByIdFx, $editTaxBranchStatus
} from '../../../models/dictionaryTaxBranchModel';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel';
import {$dictsSelects} from '../../../models/infoModel';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    setCreateDistrictEmbeddedEv,
    setCreateTownEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel';
import CreateTownEmbeddedForm
    from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import CreateDistrictEmbeddedForm
    from '../../../components/Embedded/CreateDistrictEmbeddedForm/CreateDistrictEmbeddedForm.jsx';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';
import {
    $distrsByCitySelect,
    getDistrsByCityEv
} from "../../../models/selectsByParentModel";

export default function EditTaxBranch() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selectedTaxBranch = useUnit($selectedTaxBranch)

    const [taxBranchForm] = Form.useForm()

    const submit = useUnit(submitEditTaxBranchEv)

    const loading = useUnit(getTaxBranchByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editTaxBranchStatus)

    const {townOpts, distOpts} = useStoreMap($dictsSelects, sel => ({
        townOpts: sel.towns,
        distOpts: sel.districts
    }))
    const getDistrsTown = useUnit(getDistrsByCityEv)
    const distrsByTown = useUnit($distrsByCitySelect)

    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const setDistEmbedded = useUnit(setCreateDistrictEmbeddedEv)
    const genitive_form = t(`breadcrumbs.genitive.tax_branch`)

    useEffect(() => {
        if (status) {
            navigate('/dictionaries/tax_branch')
        }
    }, [status]);

    useEffect(() => {
        if (selectedTaxBranch) {
            taxBranchForm.setFieldsValue(selectedTaxBranch)
            getDistrsTown(selectedTaxBranch.town_distr_id)
        }
    }, [selectedTaxBranch]);

    const [check, changedFields] = useFormChanged(selectedTaxBranch, ['town_id', 'town_distr_id', 'name_ru', 'name_kz', 'short_name_ru', 'short_name_kz', 'address_ru', 'address_kz'])

    const townSearched = Form.useWatch('town_id', taxBranchForm)

    return <>
        <TaxBranchEditGate id={id}/>
        <Form form={taxBranchForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[16,16]}>
                <Col span={5}>
                    <Card title={
                        <StarredTitle title={t('Населенный пункт')} required={true}/>
                    } style={{height: '100%'}} size={'small'}>
                        <Form.Item name={'town_id'}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['town_id']}
                        >
                            <Select allowClear
                                    options={[...townOpts, {
                                        label: 'Создать',
                                        value: -1,
                                        emoji: true
                                    }]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setTownEmbedded(true)
                                            getDistrsTown(undefined)
                                            setDistEmbedded(true)
                                        } else {
                                            taxBranchForm.resetFields(['town'])
                                            getDistrsTown(v)
                                            setTownEmbedded(false)
                                            taxBranchForm.resetFields(['town_distr_id', 'district'])
                                            setDistEmbedded(false)
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                            }
                                        </Space>
                                    )}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm/>
                    </Card>
                </Col>
                <Col span={5}>
                    <Card title={<StarredTitle title={t('Район города')} required={false}/>}
                          style={{height: '100%'}}
                          size={'small'}>
                        <Form.Item name={'town_distr_id'}
                            // rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['town_distr_id']}
                        >
                            <Select allowClear
                                    options={townSearched !== null && townSearched !== undefined ? [...distrsByTown, {
                                        label: 'Создать',
                                        value: -1,
                                        emoji: true
                                    }] : [...distOpts, {
                                        label: 'Создать',
                                        value: -1,
                                        emoji: true
                                    }]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setDistEmbedded(true)
                                        } else {
                                            taxBranchForm.resetFields(['district'])
                                            setDistEmbedded(false)
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                            }
                                        </Space>
                                    )}
                            />
                        </Form.Item>
                        <CreateDistrictEmbeddedForm/>
                    </Card>
                </Col>
                <Col span={14}>
                    <Card title={t('Свойства налогового отделения')} style={{height: '100%'}}
                          size={'small'}>
                        <Row gutter={[8, 0]}>
                            <Col span={16}>
                                <Form.Item name={'name_kz'}
                                           label={t(`Название ${genitive_form} (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['name_kz']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'short_name_kz'}
                                           label={t(`Кр. название (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['short_name_kz']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item name={'name_ru'}
                                           label={t(`Название ${genitive_form} (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['name_ru']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'short_name_ru'}
                                           label={t(`Кр. название (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['short_name_ru']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'address_kz'}
                                           label={t(`Адрес ${genitive_form} (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['address_kz']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'address_ru'}
                                           label={t(`Адрес ${genitive_form} (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                           validateStatus={changedFields['address_ru']}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    taxBranchForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            taxBranchForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
