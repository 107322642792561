import {useLocation, useSearchParams} from "react-router-dom";
import {useUnit} from "effector-react";
import {downloadExcelEv} from "../models/dictionaryExportModel/index.js";

export function searchAndActions() {
	const {pathname} = useLocation()
	const dictType = pathname.split('/').pop()
	const exportXls = useUnit(downloadExcelEv)
	const [search] = useSearchParams()

	const doubleDictType = dictType.split('-').length > 0 ?
		dictType.split('-').join('_') : dictType

	const exportXlsAction = () => exportXls({type: doubleDictType, filters: search})
	return {search, exportXlsAction}
}
