import {useStoreMap, useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import {$info} from '../../../models/infoModel/index.js';
import {
    selectCorrectionPeriodEv,
    $correctionPeriod,
    $correctionUpdating,
    $selectedCorrection, setJustificationEv, setUploadCorrModalEv, $uploadCorrectionMetadata
} from '../../../models/dictionaryCorrectionModel/index.js';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Radio from 'antd/es/radio';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Tooltip from 'antd/es/tooltip';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';

export default function CorrectionTypeSettings() {
    const {t} = useTranslation()

    const setUploadModal = useUnit(setUploadCorrModalEv)
    const uploadedMeta = useUnit($uploadCorrectionMetadata)

    const loading = useUnit($correctionUpdating)
    const correction = useUnit($selectedCorrection)
    const justify = useUnit(setJustificationEv)

    const applyJustification = useDebouncedCallback((val) => {
        justify(val)
    }, [justify], 1000)

    const currentDate = useStoreMap($info, i => i.current_date)
    const currMonth = dayjs(currentDate).format('MM.YYYY')
    const nextMonth = dayjs(currentDate).endOf('month').add(1).format('MM.YYYY')

    const selectPeriod = useUnit(selectCorrectionPeriodEv)

    const selPeriod = useUnit($correctionPeriod)

    return <Card size={'small'} extra={correction?.data?.has_corrections ? t('В корректировку внесены изменения') : null}>
        <Row justify={'space-between'}>
            <Col span={20}>
                <p style={{margin: 0}}>{t('Период оказания услуги')}</p>
                <Radio.Group value={selPeriod}
                             onChange={({target: {value}}) => selectPeriod(value)}
                             size={'small'}
                             disabled={loading || correction?.data?.has_corrections || correction?.data?.has_corrections}
                >
                    <Radio.Button value={'current'} disabled={!!uploadedMeta || correction?.data?.has_corrections}>
                        {t('Текущий расчетный месяц')}{`${currentDate ? ` (${currMonth})` : ''}`}
                    </Radio.Button>
                    <Radio.Button value={'next'} disabled={!!uploadedMeta || correction?.data?.has_corrections}>
                        {t('Следующий за расчетным месяцем')}{`${currentDate ? ` (${nextMonth})` : ''}`}
                    </Radio.Button>
                </Radio.Group>
            </Col>
            <Tooltip title={correction?.data?.has_corrections ? t('В корректировку внесены изменения') : null}>
                <Button type={'primary'} disabled={!!uploadedMeta || correction?.data?.has_corrections} onClick={() => setUploadModal(true)}>{t('Загрузка реестром')}</Button>
            </Tooltip>
            <Col span={24}>
                <StarredTitle title={t('Обоснование корректировки')} required={true} style={{marginTop: 8}}/>
                {correction && <Input defaultValue={correction?.data?.justification} onChange={(e) => applyJustification(e.target.value)}/>}
            </Col>
        </Row>
    </Card>
}
