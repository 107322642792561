import {$controllersFilter, $controllersList,} from './stores.js';
import {
    generateControllersPdfFx,
    getControllersFilterFx,
    getControllersListFx, printControllersPdfFx,
    sendControllersEmailFx,
    sendMessageControllersFx,
} from './effects.js';
import {
    ControllersFilterEv,
    ControllersGeneratePdfEv,
    ControllersListGate,
    ControllersSendEmailEv, getUrlPdfEv, printControllersPdfEv,
    sendMessageControllersEv
} from './events.js';
import {forward, sample} from 'effector';

$controllersList.on(getControllersListFx.doneData, (_, accounts) => accounts)
    .on(getControllersListFx.doneData, (state, payload) => {
        return payload
    })

    .on(getControllersFilterFx.doneData, (state, payload) => {
        return payload
    })

    .on(getUrlPdfEv, (state, payload) => {
        const newData = state.map(item => {
            if (item.id === payload.inspector_id) {
                return {
                    ...item,
                    file_id: payload.id,
                    file_status: payload.status
                }
            } else {
                return item
            }
        })
        return newData
    })

    .on(printControllersPdfEv, (state, payload) => {

        const updatedData = state.map(item => {
            if (payload.includes(item.id)) {
                return {
                    ...item,
                    file_status: 'processing'
                };
            }
            return item;
        });
        return updatedData
    })








    .reset(ControllersListGate.close)


$controllersFilter.on(getControllersListFx.doneData, (_, payload) => payload.data)
    // .on(getControllersListFx.doneData, (state, payload) => {
    //     // const controllers = payload.accounts.map(item => item.aesbyt_account)
    //     return payload
    // })


sample({
    clock: ControllersListGate.state,
    // filter: (gate) => Object.hasOwn(gate, 'search'),
    filter: (gate) => {
        return Object.hasOwn(gate, 'search')
    },
    fn: (gate) => {
        return gate.search
    },
    target: getControllersListFx
})


sample({
    clock: ControllersGeneratePdfEv,
    target: generateControllersPdfFx
})

sample({
    clock: ControllersSendEmailEv,
    target: sendControllersEmailFx
})

sample({
    clock: printControllersPdfEv,
    target: printControllersPdfFx
})


sample({
    clock: ControllersFilterEv,
    target: getControllersFilterFx
})


sendMessageControllersEv.watch((data) => {
    sendMessageControllersFx(data)
})




