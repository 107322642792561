import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {$info, $infoMaps, $userPerms} from '../../../../models/infoModel/index.js';
import {
    $bankAccsOpts, $embeddedBaForm,
    $providerServBaREOs,
    $providerSrvMap,
    $provSrvsSelOpts,
    $selectedServBaREO,
    addServBaREOEv,
    editServBaREOEv,
    deleteServBaREOEv,
    resetServBaREOEv,
    selectServBaREOEv, setBaEmbeddedFormEv, $selectedProvider,
} from '../../../../models/dictionaryProvidersModel/index.js';
import {
    $foundReos,
    $multiSelectedReos, resetFiltersEv,
    resetMultiSelectedREOsEv
} from '../../../../models/accountsREOSearchModel/index.js';
import {filterOption} from '../../../../utils/selectUtils.jsx';
import Table from 'antd/es/table';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import {CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import Popconfirm from 'antd/es/popconfirm';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import ProviderSrvREOSearchForm
    from '../ProviderREOSearchForm.jsx';
import Divider from 'antd/es/divider';
import {paginationConfig} from '../../../../utils/paginationConfig.js';
import ShowAddressCard from '../../../../components/AddressReoSearchForms/ShowAddressCard.jsx';
import AddressREOSearchForm
    from '../../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import {parseHouses} from '../../../../models/accountsREOSearchModel/utils.js';
import DatePicker from 'antd/es/date-picker';
import ProviderREOBankAccounts from '../ProviderREOBankAccounts.jsx';
import styles from '../ProviderDefaultBankAccounts.module.css'
import permitted from "../../../../utils/permitted.js";

const searchButtonsStyle = {
    display: 'flex', alignItems: 'end', justifyContent: 'center'
}

export default function ProvServREOForm({isView}) {
    const {t} = useTranslation()

    const [baREOCreateForm] = Form.useForm()
    const [baREOUpdForm] = Form.useForm()

    const [filtersForm] = Form.useForm()

    const [createMode, setCreateMode] = useState(false)

    const selectedProvider = useUnit($selectedProvider)
    const {provMap, bankMap} = useStoreMap($infoMaps, info => (
        {
            provMap: info.providersMap,
            bankMap: info.bankMap
        }
    ))

    const lastAllowedDate = useStoreMap($info, i => i.last_exported_day ? dayjs(i.last_exported_day) : dayjs().subtract(1))

    const foundReos = useUnit($foundReos)
    const selectedREOs = useUnit($multiSelectedReos)
    const resetAllSelectedREOs = useUnit(resetMultiSelectedREOsEv)
    const resetReoFilters = useUnit(resetFiltersEv)

    const serviceOpts = useUnit($provSrvsSelOpts)
    const srvMap = useUnit($providerSrvMap)

    const baOpts = useUnit($bankAccsOpts)
    const embeddedOpen = useUnit($embeddedBaForm)
    const openBaEmbedded = useUnit(setBaEmbeddedFormEv)

    const baREOs = useUnit($providerServBaREOs)
    const selectedBaREO = useUnit($selectedServBaREO)
    const selectBaREO = useUnit(selectServBaREOEv)
    const resetSelBaREO = useUnit(resetServBaREOEv)

    const perms = useUnit($userPerms)
    const changePermitted = permitted(['real_estate_object_provider_service::change'], perms)
    console.log('CCC', perms, changePermitted)


    useEffect(() => {
        if (selectedBaREO) {
            baREOUpdForm.setFieldsValue({
                ...selectedBaREO,
                date_from: selectedBaREO?.date_from ? dayjs(selectedBaREO?.date_from) : null,
                date_to: selectedBaREO?.date_to ? dayjs(selectedBaREO?.date_to) : null
            })
        }
    }, [selectedBaREO]);

    const addBaREO = useUnit(addServBaREOEv)
    const changeBaREO = useUnit(editServBaREOEv)
    const deleteBaREO = useUnit(deleteServBaREOEv)

    const onCreate = (values) => {
        const data = baREOCreateForm.getFieldsValue(true)
        const {type, ...payload} = {...values, ...data}
        addBaREO({type, payload})
        baREOCreateForm.resetFields()
    }

    const onUpdate = values => {
        changeBaREO(values)
        resetSelBaREO()
        baREOUpdForm.resetFields()
    }

    const onCancelCreate = () => {
        setCreateMode(false)
        baREOCreateForm.resetFields()
        if (embeddedOpen) {
            openBaEmbedded(false)
        }
    }

    const onCancel = () => {
        resetSelBaREO()
        baREOUpdForm.resetFields()
        if (embeddedOpen) {
            openBaEmbedded(false)
        }
    }

    const [dataSource, setDataSource] = useState([])
    const [filters, setFilters] = useState(null)

    const onFilterValsChange = (cur) => {
        if (Object.hasOwn(cur, 'town_id')) {
            filtersForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus'])
        }
    }

    const onFilter = (values) => {
        for (const [key, value] of Object.entries(values)) {
            if (value === undefined || value === '' || value === null) {
                delete values[key]
            }
        }
        setFilters(values)
    }

    useEffect(() => {
        if (filters && Object.values(filters)?.some(v => v !== null && v !== undefined)) {
            let result = [...baREOs]
            for (const [key, value] of Object.entries(filters)) {
                if (key === 'house_in') {
                    const houses = parseHouses(value)
                    result = result.filter(i => houses.includes(i.house))
                } else {
                    result = result.filter(i => i[key] === value)
                }
            }
            setDataSource(result)
        } else {
            setDataSource(baREOs)
        }
    }, [filters, baREOs])

    useEffect(() => {
        if (baREOs.length > 0) {
            resetAllSelectedREOs()
            resetReoFilters()
            setCreateMode(false)
        }
    }, [baREOs]);


    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'provider_service_id',
            width: '20%',
            sorter: (a, b) => a.provider_service_id - b.provider_service_id && a.bank_account_id - b.bank_account_id,
            render: (id) => <MappedDictItem id={srvMap[id]} type={'services'}/>
        },
        // {
        //     title: t('ОН'),
        //     width: '10%',
        //     dataIndex: 'real_estate_object_id',
        //     render: (id) => <MappedDictItem id={id} type={'real-estate-object'}/>
        // },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: (a, b) => a.town_id - b.town_id,
            width: '10%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            sorter: (a, b) => a.town_distr_id - b.town_distr_id,
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            sorter: (a, b) => a.street_id - b.street_id,
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            sorter: (a, b) => parseInt(a?.house) - parseInt(b?.house),
        },
        {
            title: t('Корпус'),
            sorter: (a, b) => a.corpus?.localeCompare(b?.corpus),
            dataIndex: 'corpus',
        },
        {
            title: t('Р/С'),
            dataIndex: 'account_number',
            sorter: (a, b) => a?.account_number?.localeCompare(b?.account_number),
            render: (id, record) => {
                const isBeneficiary = record?.bank_account_provider_id !== selectedProvider?.id
                if (isBeneficiary) {
                    return `${t('Бенефициар')} ${provMap[record?.bank_account_provider_id]} - ${bankMap[record?.bank_id]} - ${record?.account_number}`
                } else {
                    return `${bankMap[record?.bank_id]} - ${record?.account_number}`
                }
            },
        },
        {
            title: t('Действует с'),
            dataIndex: 'date_from',
            sorter: (a, b) => dayjs(a?.date_from) - dayjs(b?.date_from),
            render: (date) => date ? dayjs(date).format('DD.MM.YYYY') : null,
        },
        {
            title: t('Действует по'),
            dataIndex: 'date_to',
            sorter: (a, b) => dayjs(a?.date_to) - dayjs(b?.date_to),
            render: (date) => date ? dayjs(date).format('DD.MM.YYYY') : null,
        },
        {
            title: changePermitted ? (createMode ? null : <Tooltip title={t('Добавить')}>
                <Button type={'primary'} icon={<PlusOutlined/>}
                        onClick={() => setCreateMode(true)}/>
            </Tooltip>) : null,
            hidden: isView,
            width: '96px',
            align: 'center',
            dataIndex: 'id',
            render: (id, record) => {
                if (!changePermitted) return
                const nonDeletable = dayjs(record?.date_from) < dayjs(lastAllowedDate).startOf('day')
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined/>}
                                onClick={() => selectBaREO(id)}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={() => deleteBaREO(id)}
                                placement={'left'}
                                disabled={nonDeletable}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined/>} type={'primary'} danger disabled={nonDeletable}/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            },
        }
    ]

    const {actualDateObjects, actualIds} = useMemo(() => {
        const actualDateObjects = (baREOs || []).filter(
            (ba) => dayjs(ba.date_from).startOf('day') <= dayjs() && (dayjs(ba.date_to) >= dayjs() || !ba.date_to)
        )
        return {actualDateObjects, actualIds: actualDateObjects.map(x => x.id)}
    }, [baREOs, lastAllowedDate])

    return <>
        {!isView && createMode && <>
            <Card title={'Добавление расчетного счета для ОН'} size={'small'}
                  style={{marginBottom: '8px'}}>
                <ProviderSrvREOSearchForm fromProvider/>
                <Divider/>
                <Form form={baREOCreateForm} layout={'vertical'}
                      onFinish={onCreate}
                      size={'small'}
                >
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <Form.Item name={'provider_service_id'} label={t('Услуга')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Select options={serviceOpts}
                                        filterOption={filterOption}
                                        style={{width: '100%'}}
                                        showSearch
                                        allowClear
                                />
                            </Form.Item>
                        </Col>
                        <Col span={18} style={{marginBottom: 16}}>
                            <ProviderREOBankAccounts />
                        </Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <ButtonGroup>
                            <Tooltip
                                title={!selectedREOs?.length ? t('Укажите Объекты недвижимости') : null}>
                                <Button type={'primary'}
                                        onClick={() => {
                                            baREOCreateForm.setFieldsValue({type: 'selected'})
                                            baREOCreateForm.submit()
                                        }}
                                        disabled={!selectedREOs?.length}
                                >
                                    {t('Добавить выделенные')}
                                </Button>
                            </Tooltip>
                            <Tooltip
                                title={foundReos?.length === 0 ? t('Объекты недвижимости отсутствуют') : null}>
                                <Button
                                    onClick={() => {
                                        baREOCreateForm.setFieldsValue({type: 'all'})
                                        baREOCreateForm.submit()
                                    }}
                                    disabled={foundReos?.length === 0}
                                >
                                    {t('Добавить все подходящие под фильтр')}
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                        <Button danger type={'primary'}
                                onClick={() => onCancelCreate()}>{t('Отмена')}</Button>
                    </Row>
                </Form>
            </Card>
            <Divider/>
        </>}

        {/* filter */}
        <Card size={'small'}>
            <Form form={filtersForm} layout={'vertical'} size={'small'} onFinish={onFilter} onValuesChange={onFilterValsChange}>
                <Row gutter={[8, 8]}>
                    <Col span={6}>
                        <Form.Item name={'provider_service_id'} label={t('Услуга')}>
                            <Select
                                options={serviceOpts}
                                filterOption={filterOption}
                                size={'small'}
                                style={{width: '100%'}}
                                showSearch
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={15}>
                        <AddressREOSearchForm
                            extForm={filtersForm} itemsAsRow onlyItems extButtons
                            sizes={{
                                town: 4,
                                corpus: 2,
                                street: 7,
                                house: 6,
                                town_distr: 5,
                            }}
                        />
                    </Col>
                    <Col span={3} style={searchButtonsStyle}>
                        <Form.Item>
                            <ButtonGroup size={'small'}>
                                <Button onClick={filtersForm.submit} type={'primary'}>
                                    {t('Найти')}
                                </Button>
                                <Button
                                    onClick={() => {
                                        filtersForm.resetFields()
                                        setFilters(null)
                                    }}
                                    disabled={!filters}
                                >
                                    {t('Очистить')}
                                </Button>
                            </ButtonGroup>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>

        {/* table & edit form */}
        <Row gutter={[16, 16]} style={{marginTop: 8}}>
            <Col span={selectedBaREO ? 14 : 24}>
                {/*<Card title={'Расчетные счета для ОН'} size={'small'} style={{minHeight: '100%'}}>*/}
                    <Table dataSource={dataSource}
                           columns={columns}
                           bordered
                           className={'table-container'}
                           pagination={paginationConfig}
                           size={'small'}
                           rowClassName={(record) => record.id === selectedBaREO?.id
                               ? 'selectedRowObject'
                               : actualIds.includes(record?.id)
                                   ? 'actualRow'
                                   : null
                           }
                    />
                {/*</Card>*/}
            </Col>
            {selectedBaREO && <Col span={10}>
                <Card title={'Редактирование Р/С по услуге для ОН'} size={'small'}>
                    <Row gutter={[8, 12]}>
                        <Col span={24}>
                            {t('Услуга')}: <MappedDictItem
                            id={srvMap[selectedBaREO.provider_service_id]}
                            type={'services'} link={false}
                        />
                        </Col>
                        <Col span={24}>
                            {t('ОН')}: <ShowAddressCard raw={selectedBaREO} textOnly/>
                        </Col>
                        <Col span={24}>
                            {t('Расчетный счет')}: {
                            selectedBaREO?.bank_account_provider_id !== selectedProvider?.id
                                ? `${t('Бенефициар')} ${provMap[selectedBaREO?.bank_account_provider_id]} - ${bankMap[selectedBaREO?.bank_id]} - ${selectedBaREO?.account_number}`
                                : `${bankMap[selectedBaREO?.bank_id]} - ${selectedBaREO?.account_number}`
                        }
                        </Col>
                        <Col span={24}>
                            <Form form={baREOUpdForm} layout={'vertical'} onFinish={onUpdate} size={'small'}>
                                <Form.Item name={'date_from'} label={t('Действует с')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        disabled={dayjs(selectedBaREO?.date_from ?? dayjs()) < lastAllowedDate.startOf('day')}
                                        minDate={lastAllowedDate}
                                        format={'DD.MM.YYYY'}
                                    />
                                </Form.Item>
                                <Form.Item name={'date_to'} label={t('Действует по')}>
                                    <DatePicker
                                        style={{width: '100%'}}
                                        minDate={dayjs()}
                                        format={'DD.MM.YYYY'}
                                    />
                                </Form.Item>
                            </Form>
                        </Col>
                        <Col span={24}>
                            <ButtonGroup size={'small'}>
                                <Button type={'primary'} onClick={() => baREOUpdForm.submit()}>
                                    {t('Применить')}
                                </Button>
                                <Button onClick={onCancel} disabled={!selectedBaREO}>
                                    {t('Отменить')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card>
            </Col>}
        </Row>
    </>
}
