import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    $createdServicePaymentTypeId,
    submitCreateServicePaymentTypeEv,
    ServicePaymentTypeCreateGate,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
} from '../../../models/dictionaryServicePaymentTypeModel/index.js';

export default function CreateServicePaymentType() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [servicePaymentTypeForm] = Form.useForm()

    const submit = useUnit(submitCreateServicePaymentTypeEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdServicePaymentTypeId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.service-payment-type`);

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/service-payment-type/edit/${createdId}`)
            } else if (action === 'new') {
                servicePaymentTypeForm.resetFields()
            } else if (action === 'list') {
                navigate(`/dictionaries/service-payment-type?code_name=${createdId}`)
                servicePaymentTypeForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <ServicePaymentTypeCreateGate/>
        <Form form={servicePaymentTypeForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Card title={t('Свойства вида платежа')} size={'small'}>
                <Row justify={'space-between'}>
                    <Col span={5}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'short_name_kz'}
                                   label={t(`Краткое название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Краткое название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    servicePaymentTypeForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            servicePaymentTypeForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующий')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            servicePaymentTypeForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            servicePaymentTypeForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и перейти к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
