import {
    $afterCreateAction,
    $createdPaymentDeductionId,
    $editPaymentDeductionStatus,
    $selectedPaymentDeduction,
    $paymentDeductionList
} from './stores.js';
import {getPaymentDeductionListFx, getPaymentDeductionByIdFx} from './effects.js';
import {
    deletePaymentDeductionEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    PaymentDeductionEditGate,
    PaymentDeductionListGate,
    PaymentDeductionViewGate,
    submitCreatePaymentDeductionEv,
    submitEditPaymentDeductionEv, PaymentDeductionCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {resetCreateInspectorEmbeddedEv, resetCreateTownEmbeddedEv} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreatePaymentDeductionEv, response: triggerSuccessOpsEv}, reset: PaymentDeductionCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditPaymentDeductionEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deletePaymentDeductionEv, response: triggerSuccessOpsEv})

$paymentDeductionList.on(getPaymentDeductionListFx.doneData, (_, data) => data)
    .reset(PaymentDeductionListGate.close)

$selectedPaymentDeduction.on(getPaymentDeductionByIdFx.doneData, (_, data) => data)
    .reset([PaymentDeductionEditGate.close, PaymentDeductionViewGate.close])
// FIXME
$createdPaymentDeductionId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "paymentDeduction")
    const result = data ? data?.value?.id : undefined
    console.log('created paymentDeduction id', result)
    return result
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editPaymentDeductionStatus.on(editCompleteEv, () => true)
    .reset(PaymentDeductionEditGate.close)

sample({
    clock: [PaymentDeductionEditGate.state, PaymentDeductionViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getPaymentDeductionByIdFx
})

sample({
    clock: PaymentDeductionListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPaymentDeductionListFx
})

sample({
    source: PaymentDeductionListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPaymentDeductionListFx
})

sample({
    clock: submitCreatePaymentDeductionEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.inspector_id === '-' || payload.inspector_id === ' ') {
            payload.inspector_id = null
        }

        if (payload.inspector_id < 0) {
            const newInspector = {
                ...payload.inspector,
                town_id: payload.town_id
            }

            actions.push({operation: 'create', type: 'inspectors', value: {...newInspector, id: idCounter}})
            delete payload.inspector
            idCounter--

        }

        actions.push({operation: 'create', type: 'paymentDeduction', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedPaymentDeduction,
    clock: submitEditPaymentDeductionEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        if (payload.inspector_id < 0) {
            const newInspector = {
                ...payload.inspector,
                town_id: payload.town_id
            }
            actions.push({operation: 'create', type: 'inspectors', value: {...newInspector, id: idCounter}})
            delete payload.inspector

        }

        if (changedFields.inspector_id === '-' || changedFields.inspector_id === ' ') {
            changedFields.inspector_id = null
        }

        if (changedFields.inspector) {
            changedFields.inspector_id = idCounter
            delete changedFields.inspector
        }


        actions.push({operation: 'update', type: 'paymentDeduction', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deletePaymentDeductionEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'paymentDeduction', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [PaymentDeductionCreateGate.close, PaymentDeductionEditGate.close],
    target: [resetCreateTownEmbeddedEv, resetCreateInspectorEmbeddedEv]
})
