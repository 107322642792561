import {$accountsFilter, $accountsList, $accountsTotal,} from './stores.js';
import {
    changePageAccountsFx,
    generateAccountsPdfFx,
    getAccountsFilterFx,
    getAccountsListFx, printAccountPdfFx,
    sendAcccountsEmailFx,
    sendMessageAccountsFx
} from './effects.js';
import {
    AccountsFilterEv,
    AccountsGeneratePdfEv,
    AccountsListGate,
    AccountsSendEmailEv, changeAccountsPageEv, getAccountUrlPdfEv, printAccountPdfEv, sendEmailAccountsEv,
    sendMessageAccountsEv
} from './events.js';
import {forward, sample} from 'effector';

$accountsList.on(getAccountsListFx.doneData, (_, data) => data)
    .on(getAccountsListFx.doneData, (state, payload) => {
        return payload.accounts
    })

    .on(getAccountsFilterFx.doneData, (state, payload) => {
        return payload.accounts
    })

    .on(getAccountUrlPdfEv, (state, payload) => {
        // debugger
        const newData = state.map(item => {
            if (parseInt(item.id) === payload.account_id) {
                return {
                    ...item,
                    file_id: payload.id,
                    file_generated: payload.file_generated,
                    file_status: payload.status
                }
            } else {
                return item
            }
        })
        return newData
    })

    .on(sendEmailAccountsEv, (state, payload) => {
        const newData = state.map(item => {
            if (item.email === payload.email) {
                return {
                    ...item,
                    email_status: payload.status
                }
            } else {
                return item
            }
        })
        return newData
    })


    .on(AccountsSendEmailEv, (state, payload) => {

        const updatedData = state.map(item => {
            if (payload.includes(item.id)) {
                return {
                    ...item,
                    email_status: 'processing'
                };
            }
            return item;
        });

        return updatedData
    })


    .on(AccountsGeneratePdfEv, (state, payload) => {

        const updatedData = state.map(item => {
            if (payload.includes(item.id)) {
                return {
                    ...item,
                    file_status: 'processing'
                };
            }
            return item;
        });

        return updatedData
    })











$accountsTotal.on(getAccountsListFx.doneData, (_, data) => data)
    .on(getAccountsListFx.doneData, (state, payload) => {
        return payload.count
    })
    .on(getAccountsFilterFx.doneData, (state, payload) => {
        return payload.count
    })

    .on(changePageAccountsFx.doneData, (state, payload) => {
        return payload.count
    })

    .reset(AccountsListGate.close)


$accountsFilter.on(getAccountsListFx.doneData, (_, data) => data)
    .on(getAccountsListFx.doneData, (state, payload) => {
        const accounts = payload.accounts.map(item => item.aesbyt_account)
        return accounts
    })


sample({
    clock: AccountsListGate.state,
    // filter: (gate) => Object.hasOwn(gate, 'search'),
    filter: (gate) => {
        return Object.hasOwn(gate, 'search')
    },
    fn: (gate) => {
        console.log('gate gate', gate)
        return gate.search
    },
    target: getAccountsListFx
})


sample({
    clock: AccountsGeneratePdfEv,
    target: generateAccountsPdfFx
})

sample({
    clock: changeAccountsPageEv,
    target: changePageAccountsFx
})

sample({
    clock: AccountsSendEmailEv,
    target: sendAcccountsEmailFx
})

sample({
    clock: printAccountPdfEv,
    target: printAccountPdfFx
})


sample({
    clock: AccountsFilterEv,
    target: getAccountsFilterFx
})


sendMessageAccountsEv.watch((data) => {
    sendMessageAccountsFx(data)
})




