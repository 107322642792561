import {createStore} from 'effector';


export const $chiefInspectorList = createStore({data: [], count: 0})

export const $selectedChiefInspector = createStore(null)

export const $editChiefInspectorStatus = createStore(false)

export const $createdChiefInspectorId = createStore(null)
export const $afterCreateAction = createStore(null)
