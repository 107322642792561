import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getNationalityListFx} from "./effects.js";

export const NationalityListGate = createGate()
export const NationalityViewGate = createGate()
export const NationalityEditGate = createGate()
export const NationalityCreateGate = createGate()

export const submitCreateNationalityEv = createEvent()
export const submitEditNationalityEv = createEvent()
export const deleteNationalityEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
