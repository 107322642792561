import {combine, createStore} from 'effector';
import {$infoMaps} from '../infoModel/index.js';


export const $correctionList = createStore({data: [], count: 0})

export const $selectedCorrection = createStore(null)

export const $createdCorrectionId = createStore(null)

export const $finalStatus = createStore(null)

export const $redirectPath = createStore(null)
export const $finalAction = createStore(null)

export const afterActionsEnum = {
    single_save: 'single_save',
    single_next: 'single_next',
    single_skip: 'single_skip',
    mass_selected: 'mass_selected',
    mass_all: 'mass_all',
}

// upload correction
export const $uploadCorrectionModal = createStore(false)
export const $uploadCorrectionStatus = createStore(false)
export const $uploadCorrectionMetadata = createStore(null)
export const $uploadCorrProcessing = createStore(false)
export const $uploadConfirmedStatus = createStore(false)
export const $uploadConfirmedMeta = createStore(null)
export const $uploadConfirmDone = combine($uploadConfirmedMeta, (meta) => meta?.status === 'processed')
// ---

export const $correctionHistory = createStore({count: 0, data: []})
export const $correctionHistoryTariffs = createStore({})
export const $correctionHistoryTable = createStore({page: 1, limit: 10, sort_order: null, sort_field: null})

export const $correctionUpdating = createStore(false)

export const $prefsSelectOpts = createStore({providers: [], services: []})

export const $correctionPeriod = createStore('current')

export const $accsTable = createStore({page: 1, limit: 10, sort_order: null, sort_field: null})
export const $accsFilters = createStore({})
export const $foundAccs = createStore({data: [], count: 0})

export const $excludedAccs = createStore([])

export const $selectedAcc = createStore(null)
export const $selectedAccMeta = createStore(null)
export const $multiSelectedAccs = createStore([])

export const $srvsByAcc = createStore({services: [], corrections: []})
export const $filterOptionsByAccSrvs = combine($srvsByAcc, $infoMaps, (a, maps) => {
    return {
        provOpts: Array.from(new Set(a?.services?.map(s => s?.provider_id)))?.map(pId => ({label: maps?.providersMap[pId] ?? '-', value: pId})) ?? [],
        srvOpts: a?.services?.map(s => ({label: maps?.servicesMap[s.service_id] ?? '-', value: s?.service_id, provider_id: s?.provider_id})) ?? [],
    }
})

export const $selectedAccSrv = createStore(null)
export const $createSrvModal = createStore(false)
export const $massEditSrvModal = createStore({open: false, type: null})

export const $accSrvsFilter = createStore({provider_id: null, service_id: null})

export const $psTarifs = createStore([])
export const $psData = createStore([])
export const $psTarifsSL = combine($psTarifs, $infoMaps, (tarifs, infoMap) => {
    return tarifs?.map(t => ({
        label: `${t.value} ${(infoMap.serviceUnitsShortMap || {})[t.service_unit_id]}`,
        value: t.id,
    }))
})

export const $tarifAlgoMap = combine($psTarifs, $infoMaps, (tarifs, maps) => {
    return Object.fromEntries(tarifs?.map(t => [t?.id, maps?.algoSrvUnitMap[t?.service_unit_id]]) ?? [])
})

export const $accSrvsMap = combine($foundAccs, accs => Object.fromEntries(accs?.data?.map(a => [a.id, {services: a.services, corrections: a.corrections}])))

export const $afterApplyAction = createStore(null)

export const $tarifCreated = createStore(null)

export const $srvCreateEditDone = createStore(false)

