import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Table from 'antd/es/table'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import {
	$afterCreateAction, $chosenMainAddress,
	$createdAddressId,
	AddressCreateGate, createGroupAddressesEv,
	resetAfterCreationAttrs,
	setAfterCreateActionEv,
} from '../../../models/dictionaryAddressesModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import dayjs from 'dayjs';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {
	setCreateDistrictEmbeddedEv,
	setCreateStreetEmbeddedEv, setCreateTownEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import CreateStreetEmbeddedForm
	from '../../../components/Embedded/CreateStreetEmbeddedForm/CreateStreetEmbeddedForm.jsx';
import {
	$distrsByCitySelect,
	$streetsByCitySelect, getDistrsByCityEv,
	getStreetsByCityEv
} from '../../../models/selectsByParentModel/index.js';
import CreateTownEmbeddedForm
	from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import CreateDistrictEmbeddedForm
	from "../../../components/Embedded/CreateDistrictEmbeddedForm/CreateDistrictEmbeddedForm.jsx";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import ButtonGroup from "antd/es/button/button-group.js";
import Tooltip from "antd/es/tooltip/index.js";

export default function CreateGroupAddress() {
	const navigate = useNavigate()
	const {t} = useTranslation()

	const lang = localStorage.getItem('lang') ?? 'ru'

	const [addressForm] = Form.useForm()

	const [tableData, setTableData] = useState([])

	const loading = useUnit(universalDictEndpointFx.pending)

	const {townOpts, distOpts, streetOpts} = useStoreMap($dictsSelects, sel => ({
		townOpts: sel.towns,
		distOpts: sel.districts,
		streetOpts: sel.streets
	}))


	const getStByTown = useUnit(getStreetsByCityEv)
	const getDistrsTown = useUnit(getDistrsByCityEv)

	const streetsByTown = useUnit($streetsByCitySelect)
	const distrsByTown = useUnit($distrsByCitySelect)
	const setStreetEmbedded = useUnit(setCreateStreetEmbeddedEv)
	const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
	const setDistEmbedded = useUnit(setCreateDistrictEmbeddedEv)

	const createdId = useUnit($createdAddressId)
	const action = useUnit($afterCreateAction)
	const setAction = useUnit(setAfterCreateActionEv)
	const resetAttrs = useUnit(resetAfterCreationAttrs)

	const onCreate = useUnit(createGroupAddressesEv)

	const chosenMainAddress = useUnit($chosenMainAddress)

	useEffect(() => {
		if (createdId !== null) {
			if (action === 'new') {
				// addressForm.resetFields()
				setTableData([])
			}
			resetAttrs()
		}
		if (action === 'list') {
			addressForm.resetFields()
			resetAttrs()
			navigate(`/dictionaries/address`)

		}
	}, [createdId, action]);

	const townSearched = typeof Form.useWatch('town_id', addressForm) === 'number'
	const distrId = Form.useWatch('town_distr_id', addressForm)
	const streetId = Form.useWatch('street_id', addressForm)

	console.log('streetId', streetId)

	const columns = [
		{
			width: 200,
			title: t('Улицы'),
			dataIndex: 'street_id',
			sorter: true,
			key: 'street_id',
			render: (st_id, record) => st_id > 0 ? <MappedDictItem id={st_id} type={'street'}/> : record.street_name
		},
		{
			width: 200,
			title: t('Дом'),
			dataIndex: 'house',
			sorter: true,
			key: 'house',
			// render: (st_id) => <MappedDictItem id={st_id} type={'street'}/>
		},
		{
			width: 200,
			title: t('Корпус'),
			dataIndex: 'corpus',
			sorter: true,
			key: 'corpus',
			// render: (st_id) => <MappedDictItem id={st_id} type={'street'}/>
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id) => <ButtonGroup>
				<Tooltip title={t('Удалить')} placement={'top'}>
					<Button
						icon={<DeleteOutlined />}
						onClick={() => onDeleteRow(id)}
						danger
					/>
				</Tooltip>
			</ButtonGroup>
		}
	]

	const generateData = () => {
		const formData = addressForm.getFieldsValue()
		let result = []
		for (let i = parseInt(formData.house_from); i <= parseInt(formData.house_to); i++) {
			let newItem = {
				town_id: formData.town_id,
				town: formData.town || null,
				town_distr_id: formData.town_distr_id || null,
				district: formData.district,
				street_id: formData.street_id,
				street: formData.street,
				street_name: formData?.street ? formData?.street[`name_${lang}`] : '-',
				street_type: formData.streetType || null,
				street_type_id: formData.street_type_id || null,
				house: i,
				corpus: formData.corpus,
				is_actual: formData.is_actual,
				actual_from: formData.actual_from,
				is_on_crossroad: false,
				id: i
			}
			result.push(newItem)
		}
		setTableData(result)
	}

	const onDeleteRow = (id) => {
		setTableData(tableData.filter(item => item.id !== id))
	}

	const onFinish = () => {
		onCreate(tableData)
	}


	console.log('typeof addressForm.getFieldValue', typeof addressForm.getFieldValue('street_id') !== 'number')

	return <>
		<AddressCreateGate/>
		<Form form={addressForm} layout={'vertical'} size={'small'}
					onFinish={(v) => {
						const payload = {
							...v,
							actual_from: dayjs(v.actual_from).format('YYYY-MM-DD'),
							main_address_id: chosenMainAddress,
						}
						generateData(payload)
						// submit(payload)
					}}
		>
			<Row justify={'space-between'} gutter={[24, 24]}>
				<Col span={12}>
					<Card title={t('Улица')} style={{height: '100%'}} size={'small'}>
						<Form.Item name={'town_id'} label={t('Населенный пункт')}
								   rules={[{required: true, message: t('Обязательное поле')}]}
						>
							<Select allowClear
									options={[...townOpts, {label: 'Создать', value: -1, emoji: true}]}
									onChange={(v) => {
										if (v === -1) {
											setTownEmbedded(true)
											getStByTown(undefined)
											getDistrsTown(undefined)
											addressForm.setFieldValue('street_id', v)
											setStreetEmbedded(true)
										} else {
											setTownEmbedded(false)
											getStByTown(v)
											getDistrsTown(v)
											setStreetEmbedded(false)
											addressForm.resetFields(['town_distr_id', 'street', 'street_id', 'house', 'corpus', 'house_to', 'house_from'])
										}
									}}
									showSearch
									filterOption={(input, option) =>
										(option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
									}
									optionRender={(option) => (
										<Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
											  <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
											{
												option?.data?.emoji ?
													<span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
													<span>
                                                    {option?.data.label}
                                                </span>
											}
										</Space>
									)}
							/>
						</Form.Item>
						<CreateTownEmbeddedForm/>
						<Form.Item name={'town_distr_id'} label={t('Район')}
								   rules={distrId < 0 ? [{required: true, message: t('Обязательное поле')}] : null}
						>
							<Select allowClear
									options={townSearched ? [...distrsByTown, {
										label: 'Создать',
										value: -1,
										emoji: true
									}] : [...distOpts, {
										label: 'Создать',
										value: -1,
										emoji: true
									}]}
									onChange={(v) => {
										if (v === -1) {
											setDistEmbedded(true)
										} else {
											addressForm.resetFields(['district'])
											setDistEmbedded(false)
										}
									}}
									showSearch
									filterOption={(input, option) =>
										(option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
									}
									optionRender={(option) => (
										<Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
											  <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
											{
												option?.data?.emoji ?
													<span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
													<span>
                                                    {option?.data.label}
                                                </span>
											}
										</Space>
									)}
									disabled={typeof addressForm.getFieldValue('town_id') !== 'number'}
							/>
						</Form.Item>
						<CreateDistrictEmbeddedForm/>
						<Form.Item name={'street_id'} label={t('Улица')}
								   rules={[{required: true, message: t('Обязательное поле')}]}>
							<Select allowClear
									options={townSearched ? [...streetsByTown, {
										label: 'Создать',
										value: -1,
										emoji: true
									}] : [...streetOpts, {
										label: 'Создать',
										value: -1,
										emoji: true
									}]}
									onChange={(v) => {
										if (v === -1) {
											setStreetEmbedded(true)
										} else {
											addressForm.resetFields(['street', 'house', 'corpus'])
											setStreetEmbedded(false)
											if (v !== undefined) {
												addressForm.resetFields(['house_from', 'house_to', 'corpus'])
											} else {
											}
										}
									}}
									showSearch
									filterOption={(input, option) =>
										(option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
									}
									optionRender={(option) => (
										<Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
											  <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
											{
												option?.data?.emoji ?
													<span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
													<span>
                                                    {option?.data.label}
                                                </span>
											}
										</Space>
									)}
									disabled={typeof addressForm.getFieldValue('town_id') !== 'number'}
							/>
						</Form.Item>
						<CreateStreetEmbeddedForm form={addressForm} searchedTown={townSearched}/>

						<div style={{marginBottom: '24px'}}>
							<Button
								type={'primary'}
								// onClick={generateData}
								onClick={addressForm.submit}
								// disabled={
								// 	formData.town_id === undefined ||
								// 	formData.town_distr_id === undefined ||
								// 	formData.street_id === undefined ||
								// 	formData.house_from === undefined ||
								// 	formData.house_to === undefined ||
								// 	formData.actual_from === undefined
								// }
							> Сгенерировать </Button>
						</div>
					</Card>
				</Col>
				<Col span={12}>
					<Card size={'small'} style={{height: '100%'}}>
						<Form.Item name={'house_from'}
								   label={t('Номер дома от')}
											 rules={[{required: true, message: t('Обязательное поле')}]}
						>
							<Input
								disabled={streetId === undefined ? true : false}
							/>
						</Form.Item>
						<Form.Item name={'house_to'}
											 label={t('Номер дома до')}
											 rules={[{required: true, message: t('Обязательное поле')}]}
						>
							<Input
								disabled={streetId === undefined ? true : false}
							/>
						</Form.Item>
						<Form.Item name={'corpus'} label={t('Номер корпуса')}>
							<Input
								disabled={streetId === undefined ? true : false}
							/>
						</Form.Item>
						<Form.Item name={'is_actual'}
											 label={t('Актуален')}
							// rules={[{required: true, message: t('Обязательное поле')}]}
											 valuePropName={'checked'}

						>
							<Switch value={'checked'}/>
						</Form.Item>
						<Form.Item name={'actual_from'}
											 label={t('Дата начала актуальности')}
											 rules={[{required: true, message: t('Обязательное поле')}]}
						>
							<DatePicker style={{width: '100%'}}/>
						</Form.Item>
					</Card>
				</Col>
			</Row>
		</Form>
		<Divider orientation={'left'} orientationMargin={0}/>
		<Card title={'Сгенерированные данные'}>
			<Table
				columns={columns}
				dataSource={tableData}
			/>

		</Card>
		<br/>

		<Row>
			<Space>
				<Button danger onClick={() => {
					addressForm.resetFields()
					navigate(-1)
				}}
				>
					{t('Отмена')}
				</Button>
				<Button loading={loading}
						onClick={() => {
							setAction('new')
							onFinish()
						}}
						type={'primary'}
						ghost
				>
					{t('Сохранить и создать еще')}
				</Button>
				<Button loading={loading}
								onClick={() => {
									setAction('list')
									onFinish()
								}}
								type={'primary'}
				>
					{t('Сохранить и вернуться к списку')}
				</Button>
			</Space>
		</Row>
	</>
}
