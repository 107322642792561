import {
    $afterCreateAction,
    $createdRoleId,
    $editRoleStatus,
    $selectedRole,
    $roleList
} from './stores.js';
import {getRoleListFx, getRoleByIdFx, createRoleFx} from './effects.js';
import {
    deleteRoleEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    RoleEditGate,
    RoleListGate,
    RoleViewGate,
    submitCreateRoleEv,
    submitEditRoleEv, RoleCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createRoleCompleteEv = combineEvents({events: {ev: submitCreateRoleEv, response: triggerSuccessOpsEv}, reset: RoleCreateGate.state})
const editRoleCompleteEv = combineEvents({ev: submitEditRoleEv, response: triggerSuccessOpsEv})
const deleteRoleCompleteEv = combineEvents({ev: deleteRoleEv, response: triggerSuccessOpsEv})

$roleList.on(getRoleListFx.doneData, (_, data) => data)
    .reset(RoleListGate.close)

$selectedRole.on(getRoleByIdFx.doneData, (_, data) => data)
    .reset([RoleEditGate.close, RoleViewGate.close])
// FIXME
$createdRoleId.on(createRoleCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "role")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editRoleStatus.on(editRoleCompleteEv, () => true)
    .reset(RoleEditGate.close)

sample({
    clock: [RoleEditGate.state, RoleViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getRoleByIdFx
})

sample({
    clock: RoleListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getRoleListFx
})

sample({
    source: RoleListGate.state,
    clock: [createRoleCompleteEv, editRoleCompleteEv, deleteRoleCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getRoleListFx
})

sample({
    clock: submitCreateRoleEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'role', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedRole,
    clock: submitEditRoleEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'role', value: {object_id: current.id, ...changedFields}})


        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteRoleEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'role', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
