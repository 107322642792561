import {createStore} from 'effector';

// Фильтры из форм для поиска и для создания отсутствующих
export const $addressSearch = createStore({})
export const $reoSearch = createStore({})
export const $accountSearch = createStore({})

// Найденная сущность для режима single
export const $selectedAddress = createStore(null)
export const $selectedReo = createStore(null)
export const $selectedAccount = createStore(null)

// Найденные сущности для режима multi
export const $multiSelectedAddresses = createStore([])
export const $foundReos = createStore([])
export const $multiSelectedAccounts = createStore([])

// Выбранные ОН
export const $multiSelectedReos = createStore([])

// Отсутствующие сущности при multi-поиске
export const $missAddresses = createStore([])
export const $missReos = createStore([])
export const $missAccounts = createStore([])

// Созданные отсутсвующие сущности
export const $createdMissAddresses = createStore([])
export const $createdMissReos = createStore([])
export const $createdMissAccounts = createStore([])

// Существующие ОН
export const $existingReos = createStore([])

// Созданный объект недвижимости
export const $createdREO = createStore(null)
export const $selectedREOFromAddress = createStore(null)

export const $missAddrsCreatedStatus = createStore(null)

// Если в будущем понадобится серверная пагинация
// export const $tableParams = createStore({
//     page: 1,
//     limit: 10,
//     sort_order: null,
//     sort_field: null,
//     ignore_limit: true
// })