import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import PlusOutlined from '@ant-design/icons/es/icons/PlusOutlined';
import {
    submitEditStreetEv,
    StreetEditGate, $selectedStreet, getStreetByIdFx, $editStreetStatus
} from '../../../models/dictionaryStreetsModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {
    setCreateTownEmbeddedEv,
    setCreateStTypeEmbeddedEv,
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import CreateTownEmbeddedForm
    from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';
import useFormChanged from '../../../hooks/useFormChanged.js';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';
import AddressesTable from '../../../components/Embedded/AddressesTable/AddressesTable.jsx';
import Switch from "antd/es/switch";
import DatePicker from "antd/es/date-picker";
import dayjs from "dayjs";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryStreetsModel/index.js";


export default function EditStreet() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selectedStreet = useUnit($selectedStreet)

    const [streetForm] = Form.useForm()

    const submit = useUnit(submitEditStreetEv)

    const loading = useUnit(getStreetByIdFx.pending)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editStreetStatus)

    const {townsOpts, stTypesOpts} = useStoreMap($dictsSelects, sel => ({
        townsOpts: sel.towns,
        stTypesOpts: sel.streetTypes
    }))

    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const setStreetTypeEmbedded = useUnit(setCreateStTypeEmbeddedEv)
    const genitive_form = t(`breadcrumbs.genitive.street`)

    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/street?code_name=${selectedStreet.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selectedStreet) {
            const data = {
                ...selectedStreet,
                actual_from: dayjs(selectedStreet.actual_from),
            }
            streetForm.setFieldsValue(data)
        }
    }, [selectedStreet]);

    const [check, changedFields] = useFormChanged(selectedStreet, ['name_kz', 'name_ru', 'town_id', 'town_distr_id', 'street_type_id'])

    return <>
        <StreetEditGate id={id}/>
            <Form form={streetForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => submit(v)}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <Row justify={'space-between'} gutter={[24, 24]}>
                    <Col span={6}>
                        <Card title={<StarredTitle title={t('Населенный пункт')}/>} style={{height: '100%'}} size={'small'} loading={loading}>
                            <Form.Item name={'town_id'}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['town_id']}
                            >
                                <Select options={[...townsOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                        allowClear
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                        onChange={(v) => {
                                            if (v === -1) {
                                                setTownEmbedded(true)
                                            } else {
                                                streetForm.resetFields(['town'])
                                                setTownEmbedded(false)
                                            }
                                        }}
                                        optionRender={(option) => (
                                            <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                                {
                                                    option?.data?.emoji ?
                                                        <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                        <span>
                                                    {option?.data.label}
                                                </span>
                                                }
                                            </Space>
                                        )}
                                />
                            </Form.Item>
                            <CreateTownEmbeddedForm/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title={<StarredTitle title={t('Тип улицы')}/>} style={{height: '100%'}} size={'small'} loading={loading}>
                            <Form.Item name={'street_type_id'}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['street_type_id']}
                            >
                                <Select options={[...stTypesOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                        onChange={(v) => {
                                            if (v === -1) {
                                                setStreetTypeEmbedded(true)
                                            } else {
                                                streetForm.resetFields(['streetType'])
                                                setStreetTypeEmbedded(false)
                                            }
                                        }}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                        optionRender={(option) => (
                                            <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                                {
                                                    option?.data?.emoji ?
                                                        <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                        <span>
                                                    {option?.data.label}
                                                </span>
                                                }
                                            </Space>
                                        )}
                                />
                            </Form.Item>
                            <CreateStTypeEmbeddedForm/>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title={t('Свойства улицы')} style={{height: '100%'}} size={'small'} loading={loading}>
                            <Form.Item name={'name_ru'}
                                       label={t(`Название ${genitive_form} (рус)`)}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['name_ru']}
                                       tooltip={t('Изменение названия улицы следует использовать в случае ошибки или описки в названии. Для ситуации переименования следует воспользоваться модулем переименования.')}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name={'name_kz'}
                                       label={t(`Название ${genitive_form} (каз)`)}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['name_kz']}
                                       tooltip={t('Изменение названия улицы следует использовать в случае ошибки или описки в названии. Для ситуации переименования следует воспользоваться модулем переименования.')}
                            >
                                <Input/>
                            </Form.Item>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title={t('Актуальность')} style={{height: '100%'}} size={'small'}>
                            <Form.Item
                                name={'is_actual'}
                                label={t('Актуален')}
                                valuePropName={'checked'}

                            >
                                <Switch value={'checked'}/>
                            </Form.Item>
                            <Form.Item
                                name={'actual_from'}
                                label={t('Дата начала актуальности')}
                                rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <DatePicker style={{width: '100%'}}/>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Form>
            <Card title={t('Адреса')} size={'small'} style={{marginTop: 16}}>
                <AddressesTable parent={'street_id'} parentId={+id}/>
            </Card>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        streetForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                streetForm.submit()
                                setAction('edit')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                streetForm.submit()
                                setAction('list')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и вернуться к списку')}
                    </Button>
                </Space>
            </Row>
    </>
}
