import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    ServicesChildrenGate,
    $createdServices,
    $ServicesChildren,
    deleteChildServicesEv,
    deleteNewServicesEv,
    createChildServicesEv,
    editChildServicesEv,
    setCreateEditChildServicesEmbeddedEv,
    $ServicesEmbeddedFilters, changeServicesChildrenFiltersEv
} from '../../../models/dictionaryEmbeddedFormsModel';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    EditOutlined,
    PlusOutlined
} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group.js';
import Popconfirm from 'antd/es/popconfirm';
import Table from 'antd/es/table';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import {
    getServicesChildrenFx,
} from '../../../models/dictionaryEmbeddedFormsModel/effects.js';
import CreateEditServicesEmbedded from "./CreateEditServicesEmbedded.jsx";
import MappedDictItem from "../../MappedDictItem/MappedDictItem.jsx";

export default function ServicesTable({view, parent, parentId}) {
    const {t} = useTranslation()
    const {data, count} = useUnit($ServicesChildren)
    const loading = useUnit(getServicesChildrenFx.pending)
    const newData = useUnit($createdServices)

    const deleteExisting = useUnit(deleteChildServicesEv)
    const deleteNew = useUnit(deleteNewServicesEv)

    const onCreate = useUnit(createChildServicesEv)
    const onEdit = useUnit(editChildServicesEv)

    const setModal = useUnit(setCreateEditChildServicesEmbeddedEv)

    const {page, limit, sortField, sortOrder} = useUnit($ServicesEmbeddedFilters)
    const changeFilters = useUnit(changeServicesChildrenFiltersEv)
    const genitive_form = t(`breadcrumbs.genitive.services`);


    const columns = [
        {
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'id',
        },
        {
            title: t(`Название ${genitive_form} (рус)`),
            dataIndex: 'name_ru',
            sorter: true,
            render: (name_ru) => name_ru
        },
        {
            title: t(`Название ${genitive_form} (каз)`),
            dataIndex: 'name_kz',
            sorter: true,
            render: (name_kz) => name_kz
        },
        {
            title: t(`Короткое Название ${genitive_form} (рус)`),
            dataIndex: 'short_name_ru',
            sorter: true,
            render: (short_name_ru) => short_name_ru
        },
        {
            title: t(`Короткое Название ${genitive_form} (каз)`),
            dataIndex: 'short_name_kz',
            sorter: true,
            render: (short_name_kz) => short_name_kz
        },

        // {
        //     title: t('Единица измерения'),
        //     dataIndex: 'service_unit_id',
        //     sorter: true,
        //     key: 'service_unit_id',
        //     render: (service_unit_id) => <MappedDictItem id={service_unit_id} type={'service_unit'}/>
        // },
        {
            title: t('Порядок ЕПД'),
            dataIndex: 'epd_order',
            sorter: true,
            key: 'epd_order',
        },
        {
            title: t('Вид платежа'),
            dataIndex: 'service_payment_type_id',
            sorter: true,
            key: 'service_payment_type_id',
        },
        {
            title: t('Услуга по счетчику'),
            dataIndex: 'is_counter_service',
            sorter: true,
            key: 'is_counter_service',
            render: (is_counter_service) => is_counter_service === true ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/> : is_counter_service === false ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> : '-'

        },


        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined/>} onClick={() => setModal({type: 'create'})} type={'primary'}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            hidden: view,
            key: 'actions',
            render: (id,row) => {

                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={
                                    () => {
                                        if (row.new_item === true) {
                                            return  deleteNew(id)
                                        } else {
                                            deleteExisting(id)
                                        }
                                    }
                                }
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined />} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]

    if (view) {
        const idx = columns.findIndex(i => i.key === 'actions')
        if (idx !== -1) {
            columns.splice(idx, 1)
        }
    }

    const rowClassName = (record, index) => {
        const checkNew = Math.sign(record.id) === 1 ? false : true
        if (checkNew === true) {
            return 'custom-new-row'
        } else if (record?.changedFields) {
            return 'custom-edit-old-row'
        } else if (record.new_item) {
            return 'custom-new-select-row'
        } else {
            return 'custom-normal-row'
        }
    };



    return <>
        <ServicesChildrenGate parent={parent} parentId={parentId} />
        <CreateEditServicesEmbedded onCreate={onCreate} onEdit={onEdit} />

        <Table dataSource={[...data, ...newData]}
               columns={columns}
               loading={loading}
               bordered
               size={'small'}
               rowClassName={rowClassName}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(page),
                   total: count
               }}
               onChange={({current, pageSize}, filters, {field, order}) => {
                   const payload = []
                   if (current !== page || pageSize !== limit) {
                       payload.push({key: 'page', value: current})
                       payload.push({key: 'limit', value: pageSize})
                   }

                   if (field !== sortField || order !== sortOrder) {
                       if (!order) {
                           payload.push({key: 'sort_field', value: null})
                           payload.push({key: 'sort_order', value: null})
                       } else {
                           payload.push({key: 'sort_field', value: field})
                           payload.push({
                               key: 'sort_order',
                               value: order === 'ascend' ? 'asc' : 'desc'
                           })
                       }
                   }
                   changeFilters(payload)
               }}
               style={{width: '100%'}}
        />
    </>
}
