import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import './components/style.css'
import {
	$bankApiAuditFiltersSt,
	$bankApiAuditSt,
	bankApiAuditChangeFilterEv,
	bankApiAuditDownloadFullEv,
	bankApiAuditDownloadShortEv,
	dataPaymentBankApiAuditModalEv,
	getBankApiAuditFx,
	openPaymentBankApiAuditModalEv,
	PaymentBankApiGate,
} from "../../models/paymentModel/index.js";
import {searchAndActions} from "../../utils/searchAndActions.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import Table from "antd/es/table";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import ButtonGroup from "antd/es/button/button-group.js";
import Tooltip from "antd/es/tooltip/index.js";

import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import Card from "antd/es/card";
import {paginationConfig} from "../../utils/paginationConfig.js";
import PaymentBankApiAuditModal from "./components/PaymentBankApiAuditModal.jsx";
import Col from "antd/es/col/index.js";
import Row from "antd/es/row/index.js";
import {$dictsSelects} from "../../models/infoModel/index.js";


const options = [
		{
			label: "GetPrintedInvoicePDF",
			value: "GetPrintedInvoicePDF"
		},
		{
			label: "Pay",
			value: "Pay"
		},
		{
			label: "PayD",
			value: "PayD"
		},
		{
			label: "GetInvoicesByAccountsNew",
			value: "GetInvoicesByAccountsNew"
		},
		{
			label: "GetInvoicesByAccountsWithIIN",
			value: "GetInvoicesByAccountsWithIIN"
		},
		{
			label: "RollbackPaymentsBySysRef",
			value: "RollbackPaymentsBySysRef"
		},
		{
			label: "RollbackPayments",
			value: "RollbackPayments"
		},
		{
			label: "GetPaysInfo",
			value: "GetPaysInfo"
		},
		{
			label: "GetPaymentsInfo",
			value: "GetPaymentsInfo"
		},
		{
			label: "GetNotMovedPays",
			value: "GetNotMovedPays"
		},
		{
			label: "GetBankDayChecks",
			value: "GetBankDayChecks"
		},
		{
			label: "GetReport108e",
			value: "GetReport108e"
		}
	]


export default function PaymentBankApi() {
	const {t} = useTranslation()
	const {data, count} = useUnit($bankApiAuditSt)
	const dataPaymentFalse = data.filter(item => item.confirmation_status !== true)
	const {search, exportXlsAction} = searchAndActions()

	const {page, limit, sortField, sortOrder} = useUnit($bankApiAuditFiltersSt)

	const openModal = useUnit(openPaymentBankApiAuditModalEv)
	const setModalData  = useUnit(dataPaymentBankApiAuditModalEv)

	const downloadFull  = useUnit(bankApiAuditDownloadFullEv)
	const downloadShort  = useUnit(bankApiAuditDownloadShortEv)
	const changeFilters  = useUnit(bankApiAuditChangeFilterEv)

	const bankOpts = useStoreMap($dictsSelects, sel => sel.bank)?.filter(item => item.is_contract_signed === true)

	const onChangePage = (payload) => {
		changeFilters(payload)
	}


	const keysData = data.map(item => {
		return {
			...item,
			key: item.id
		}
	})

	const onOpenModal = (id, type, request, responce) => {
		openModal()
		setModalData({type: type, data: {request: request, responce: responce}})
	}


	const loading = useUnit(getBankApiAuditFx.pending)
	const columns = [
		{
			title: t('Дата запроса'),
			dataIndex: 'event_datetime',
			key: 'event_datetime',
			render: (date) => <CustomDates data={date} />
		},


		{
			title: t('Банк'),
			dataIndex: 'bank_code',
			key: 'bank_code',
		},


		{
			title: t('Метод API'),
			dataIndex: 'endpoint',
			key: 'endpoint',
		},


		{
			title: t('Статус ответа'),
			dataIndex: 'responce_status',
			key: 'responce_status',
		},


		{
			title: t('Время ответа'),
			dataIndex: 'responce_time',
			key: 'responce_time',
			render: (date, row) => {
				const diff = dayjs(date).diff(dayjs(row?.event_datetime), 'millisecond')
				const seconds = Math.floor(diff / 1000);
				const milliseconds = diff % 1000;

				return `${seconds > 0 ? seconds + 'сек' : ''} ${milliseconds}мс`
			}
		},

		{
			title: t('Параметры запроса'),
			dataIndex: 'request',
			key: 'request',
			render: (value) => {
				return value.substring(0, 50)
			},
		},

		{
			title: t('Ответ'),
			dataIndex: 'responce',
			key: 'responce',
			render: (value) => {
				return value.substring(0, 50)

			},
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id, row) => <ButtonGroup>
					<Tooltip title={t('Просмотреть')} placement={'top'}>
						<Button
							type={'primary'}
							size={'small'}
							onClick={() => onOpenModal(id, 'request', row.request, row.responce)}
						>
							Просмотр
						</Button>
					</Tooltip>
			</ButtonGroup>
		}


	]
	const filters = [
		// {name: 'bank_id', label: t('Банк'), type: 'select', options: bankOpts},
		{name: 'branch_name', label: t('Отделение'), type: 'input'},
		{name: 'payment_date_from', label: t('Дата от'), type: 'date', },
		{name: 'payment_date_to', label: t('Дата до'), type: 'date', },
		{name: 'endpoint', label: t('Метод API'), type: 'select', options: options},
	]


	return <>
		<PaymentBankApiGate search={search.toString()}/>

		<PaymentBankApiAuditModal />

		<FilterBlock items={filters} />
		<Divider/>
		<Row gutter={[16,16]}>
			<Col>

					<Button type={'primary'}  size={'small'} onClick={() => downloadFull(search)}>
						Выгрузить в Excel с запросом и ответом
					</Button>
			</Col>
			<Col>

					<Button type={'primary'}  size={'small'} onClick={() => downloadShort(search)}>
						Выгрузить в Excel без запроса и ответа
					</Button>
			</Col>
		</Row>
		<br/>

		<Card size={'small'}>
			<Table
				columns={columns}
				dataSource={keysData}
				size={'small'}
				onChange={({current, pageSize}, filters, {field, order}) => {
					const payload = []
					if (current !== page || pageSize !== limit) {
						payload.push({key: 'page', value: current})
						payload.push({key: 'limit', value: pageSize})
					}
					if (field !== sortField || order !== sortOrder) {
						if (!order) {
							payload.push({key: 'sort_field', value: null})
							payload.push({key: 'sort_order', value: null})
						} else {
							payload.push({key: 'sort_field', value: field})
							payload.push({
								key: 'sort_order',
								value: order === 'ascend' ? 'asc' : 'desc'
							})
						}
					}
					onChangePage(payload)
				}}
				pagination={{
					...paginationConfig,
					pageSize: 10,
					current: parseInt(page),
					total: count
				}}

			/>
		</Card>
	</>
}
