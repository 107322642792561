import {createEffect} from 'effector';
import api from '../../api/api.js';


export const sendMessageAccountsFx = createEffect()
    .use(async (data) => {
        data.sendMessage(JSON.stringify(data.payload))
    });



export const getAccountsListFx = createEffect()
    .use(async (page) => {
        try {
            console.log('page', page)
            // debugger
            const response = await api().get(`/accounts/?limit=10&offset=${page.includes('page') ? parseInt(page.split('page=')[1]) - 1: 0}`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });


export const changePageAccountsFx = createEffect()
    .use(async (page) => {
        try {
            // debugger
            const response = await api().get(`/accounts/?limit=10&offset=${page * 10}`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });


export const generateAccountsPdfFx = createEffect()
    .use(async (payload) => {
        try {
            const data = {
                account_ids: payload,
            }
            const response = await api().post('/epd/generate', data);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });


export const sendAcccountsEmailFx = createEffect()
    .use(async (payload) => {
        try {
            const data = {
                account_ids: payload,
                report_date: dayjs(new Date()).format(),
            }
            const response = await api().post('/dispatch/email', data);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });

export const printAccountPdfFx = createEffect()
    .use(async (payload) => {
        try {
            const data = {
                account_ids: payload,
                report_date: dayjs(new Date()).format(),
            }
            const response = await api().post('/dispatch/print', data);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });

export const getAccountsFilterFx = createEffect()
    .use(async (value) => {
        console.log('value', value)
        // debugger
        // const aesbyt_account = value?.accounts?.map(item => 'aesbyt_account=' + item)
        const aesbyt_account = value.accounts !== '' ? `aesbyt_account=${value.accounts}` : '&'
        try {
            const response = await api().get(`/accounts/?${aesbyt_account}&limit=10&offset=0`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });
