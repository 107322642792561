import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import {
    AccrualsGate,
    getAccAccrEv
} from "../../../models/dictionaryAccountsModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {$accrualsData, getAccrualsListDataFx} from "../../../models/dictionaryAccountsModel";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import FilterBlock from "../../../components/FiltersBlock/FiltersBlock.jsx";
import DictionaryProTable from "../../../components/DictionaryProTable/DictionaryProTable.jsx";
import {useParams} from "react-router-dom";
import FormatedNumber from "../../../components/FormatedNumber.jsx";
import DatePicker from "antd/es/date-picker";
import {$info} from "../../../models/infoModel/index.js";
import React, {useEffect, useState} from "react";
import Button from "antd/es/button";
import Space from "antd/es/space";


export default function Accruals({isView}) {

    const {t} = useTranslation()
    const {search} = searchAndActions()
    const currentDate = useStoreMap($info, i => i.current_date)

    const {id} = useParams()


    const {data, count} = useUnit($accrualsData)
    const loading = useUnit(getAccrualsListDataFx.pending)
    const getAccAccr = useUnit(getAccAccrEv)


    const columns = [
        {
            title: t('Дата ЕПД'),
            dataIndex: 'epd_report_date',
            key: 'epd_report_date',
            fixed: 'left',
            render: (date) => <CustomDates type='month' data={date} />,
        },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            key: 'provider_id',
            fixed: 'left',
            render: (provider_id) => <MappedDictItem id={provider_id} type={'providers'}/>
        },
        {
            title: t('Услуга'),
            dataIndex: 'service_id',
            key: 'service_id',
            fixed: 'left',
            render: (service_id) => <MappedDictItem id={service_id} type={'services'}/>
        },
        {
            title: t('Тариф'),
            dataIndex: 'tarif_value',
            key: 'tarif_value',
            render: (_, row) => <Space>
                <FormatedNumber value={row.tarif_value}/>
                <span>
                    {row.measure_name}
                </span>
            </Space>
        },
        {
            title: t('Нач. сальдо'),
            dataIndex: 'sum_saldo_begin',
            key: 'sum_saldo_begin',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Сумма начисления'),
            dataIndex: 'sum_calc',
            key: 'sum_calc',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Перерасчет'),
            dataIndex: 'sum_recalc',
            key: 'sum_recalc',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Кон. сальдо'),
            dataIndex: 'sum_saldo_end',
            key: 'sum_saldo_end',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Оплач. показания'),
            dataIndex: 'pay_count',
            hide: true,
            key: 'pay_count',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Флаг ведения сальдо'),
            dataIndex: 'sign_saldo_calc',
            hide: true,
            key: 'sign_saldo_calc',
            render: (value) => value
        },
        {
            title: t('Сторонние платежи'),
            dataIndex: 'external_payment',
            key: 'external_payment',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Банковские платежи'),
            dataIndex: 'payment',
            key: 'payment',
            render: (_, row) => row?.payment === null ? 0 : <FormatedNumber value={row?.payment}/>
        },
        {
            title: t('Кассовые платежи'),
            dataIndex: 'cash_payment',
            key: 'cash_payment',
            render: (_, row) => row?.cash_payment === null ? 0 : <FormatedNumber value={row?.cash_payment}/>
        },
        {
            title: t('Кол-во прожив.'),
            dataIndex: 'people_quantity',
            key: 'people_quantity',
        },
        {
            title: t('Площадь'),
            dataIndex: 'full_square',
            key: 'full_square',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Последние проверенные показания'),
            dataIndex: 'check_count',
            key: 'check_count',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Дата последней проверки'),
            dataIndex: 'check_date',
            key: 'check_date',
            hide: true,
        },
        {
            title: t('Значение минимального тарифа'),
            dataIndex: 'tarif_value_min',
            key: 'tarif_value_min',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Значение среднего тарифа'),
            dataIndex: 'tarif_value_avg',
            key: 'tarif_value_avg',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Значение максимального тарифа'),
            dataIndex: 'tarif_value_max',
            key: 'tarif_value_max',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Минимальная сумма начисления'),
            dataIndex: 'sum_calc_min',
            key: 'sum_calc_min',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Средняя сумма начисления'),
            dataIndex: 'sum_calc_avg',
            key: 'sum_calc_avg',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Максимальная сумма начисления'),
            dataIndex: 'sum_calc_max',
            key: 'sum_calc_max',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Сумма платежей прошлого месяца'),
            dataIndex: 'sum_pay',
            hide: true,
            key: 'sum_pay',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Сумма неоплаченной пени'),
            dataIndex: 'sum_fine',
            hide: true,
            key: 'sum_fine',
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Потребление'),
            dataIndex: 'consumption',
            key: 'consumption',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Минимальное потребление'),
            dataIndex: 'consumption_min',
            key: 'consumption_min',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Среднее потребление'),
            dataIndex: 'consumption_avg',
            key: 'consumption_avg',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Максимальное потребление'),
            dataIndex: 'consumption_max',
            key: 'consumption_max',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Нормативное потребление по минимальному тарифу'),
            dataIndex: 'norm1',
            key: 'norm1',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        },
        {
            title: t('Нормативное потребление по среднему тарифу'),
            dataIndex: 'norm2',
            key: 'norm2',
            hide: true,
            render: (value) => <FormatedNumber value={value}/>
        }
    ]

    const filters = [
        {name: 'epd_report_date_from', label: t('Месяц'), type: 'select', dict: 'closed_months'},
    ]

    const [month, setMonth] = useState(dayjs(currentDate))

    const onChangeDate = (value) => {
        console.log('XXXXX', value)
        setMonth(value)
    }
    const onPress = () => {
        console.log('XXXXX', month)
        getAccAccr({id, month})
    }

    useEffect(() => {
        setMonth(dayjs(currentDate))
    }, [currentDate]);

    //FIXME button!!!
    return <>
        <AccrualsGate id={id} search={search.toString()} />
        {/*<FilterBlock items={filters}/>*/}
        <DatePicker.MonthPicker
            placeholder={t('Месяц')}
            defaultValue={dayjs(month)}
            onChange={onChangeDate}
            />
        <Button onClick={onPress} type="primary">{t('Показать')}</Button>

        <DictionaryProTable
            data={data} count={count} initColumns={columns} showActions={false} loading={loading}
            left={[
                'service_id', 'provider_id', 'epd_report_date'
            ]}
        />

    </>
}
