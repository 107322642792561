import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';

import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import useFormChanged from "../../../hooks/useFormChanged.js";
import {
    $createdPositionId,
    $selectedPosition, PositionCreateGate,
    submitCreatePositionEv, $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
} from "../../../models/dictionaryPositionModel/index.js";

export default function CreatePosition() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [positionForm] = Form.useForm()

    const submit = useUnit(submitCreatePositionEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const selected = useUnit($selectedPosition)


    const createdId = useUnit($createdPositionId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const formData = positionForm.getFieldsValue()

    const [check, changedFields] = useFormChanged(selected, ['town_kz','name_kz', 'name_ru'])
    const genitive_form = t(`breadcrumbs.genitive.position`);

    console.log('formData', formData)


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/position/edit/${createdId}`)
            } else if (action === 'new') {
                positionForm.resetFields()
            } else if (action === 'list') {
                positionForm.resetFields()
                navigate(`/dictionaries/position?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);



    return <>
        <PositionCreateGate/>
        <Form form={positionForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>

            <Row justify={'space-between'} gutter={[24, 24]}>


                <Col span={12}>
                    <Card title={<StarredTitle title={t('Свойства Должности')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>

                    </Card>
                </Col>

            </Row>

        </Form>

        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    positionForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            positionForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            positionForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            positionForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
