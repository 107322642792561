import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getBankApiInfoListFx} from "./effects.js";

export const BankApiInfoListGate = createGate()
export const BankApiInfoViewGate = createGate()
export const BankApiInfoEditGate = createGate()
export const BankApiInfoCreateGate = createGate()

export const submitCreateBankApiInfoEv = createEvent()
export const submitEditBankApiInfoEv = createEvent()
export const deleteBankApiInfoEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
