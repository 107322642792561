import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getAccountsListFx = createEffect()
    .use(async (filters) => (await api().get(`/accounts/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getAccountByIdFx = createEffect()
    .use(async (id) => (await api().get(`/accounts/${id}`)).data)

export const getAccountReEsObjFx = createEffect()
    .use(async (id) => (await api().get(`/real_estate_objects/${id}`)).data)

export const getProviderServicesFx = createEffect()
    .use(async (id) => (await api().get(`/provider_service/?account_id=${id}&ignore_limit=true`)).data)


export const getProviderSrvAccsFx = createEffect()
    .use(async (id) => (await api().get(`/account_provider_service/?account_id=${id}&ignore_limit=true`)).data)

export const getProviderAccountFx = createEffect()
    .use(async ({id, search, filters}) => (await api().get(`/account_provider/?account_id=${id}&ignore_limit=true&current_month=${filters}`)).data)
    // .use(async ({id, search}) => (await api().get(`/account_provider/?account_id=${id}&ignore_limit=true&current_month=2024-11-01`)).data)

export const getAccrualsFx = createEffect()
    .use(async (id) => (await api().get(`/accruals/?account_id=${id}&ignore_limit=true`)).data)

export const getAccrualsListDataFx = createEffect()
    .use(async ({id, filters = '', month}) => {
        let mf = ''
        if (month) {
            month = dayjs(month).startOf('month')
            const st = month.format('YYYY-MM-DD')
            const fn = month.endOf('month').format('YYYY-MM-DD')
            console.log('ACCCCC', month, st, fn)
            mf = `&epd_report_date_from=${st}&epd_report_date_to=${fn}`
        }
        // debugger
        return (await api().get(`/accruals/?account_id=${id}${mf}${filters?.length > 0 ? `&${filters}` : ''}`)).data
    })

export const getPaymentsDataFx = createEffect()
    .use(async ({id, filters}) => (await api().get(`/payments/?account_id=${id}${filters.length > 0 ? `&${filters}` : ''}`)).data)

export const getPaymentsElementsFx = createEffect()
    .use(async ({id, filters}) => {
        return (await api().get(`/payment_elements/?account_id=${id}`)).data
    })

export const getProviderServiceFx = createEffect()
    .use(async (payload) => {
        return (await api().get(`/account_provider_service/?${payload}`)).data
    })


export const getFileApprovedInfoFx = createEffect()
    .use(async ({id, filters}) => (await api().get(`/provider_payments/?account_id=${id}&ignore_limit=true`)).data)

export const lockUnlockAccountFx = createEffect()
    .use(async (payload) => (await api().post(`/accounts/block_unblock`, payload)).data)
