import {
    $addressesChildren,
    $createdAddresses,
    $selectedChildAddressesImmutable,
    $createEditChildAddressEmbedded,
    $deletedAddresses,
    $addressEmbeddedFilters,
} from './stores.js';
import {
    AddressesChildrenGate,
    changeAddressesChildrenFiltersEv,
    setCreateEditChildAddressEmbeddedEv,
    deleteNewAddressEv,
    deleteChildAddressEv,
    editChildAddressEv,
    createChildAddressEv,
    resetCreateEditChildAddressEmbeddedEv
} from './events.js';
import {getAddressesChildrenFx} from './effects.js';
import {sample} from 'effector';
import queryString from 'query-string';

// Для CRUD таблицы вложенных адресов
$addressesChildren
    .on(getAddressesChildrenFx.doneData, (_, addresses) => addresses)
    .reset(AddressesChildrenGate.close)

$selectedChildAddressesImmutable
    .on(getAddressesChildrenFx.doneData, (state, addresses) => ([...state, ...addresses.data]))
    .reset(AddressesChildrenGate.close)

$createdAddresses.reset(AddressesChildrenGate.close)

$createEditChildAddressEmbedded.reset(resetCreateEditChildAddressEmbeddedEv)

$addressEmbeddedFilters.on(changeAddressesChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(AddressesChildrenGate.close)

sample({
    source: {gate: AddressesChildrenGate.state, filters: $addressEmbeddedFilters},
    clock: [AddressesChildrenGate.state.updates, $addressEmbeddedFilters.updates],
    filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters, [src.gate.parent]: src.gate.parentId}
        return queryString.stringify(all)
    },
    target: getAddressesChildrenFx
})

sample({
    source: $createdAddresses,
    clock: createChildAddressEv,
    fn: (src, newAddress) => {
        if (src.some(s => s.id < 0)) {
            const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
            return [...src, {...newAddress, id: newId}]
        } else {
            return [...src, {...newAddress, id: -1}]
        }
    },
    target: $createdAddresses
})

sample({
    source: {mutable: $addressesChildren, original: $selectedChildAddressesImmutable},
    clock: editChildAddressEv,
    filter: (src, {id}) => id > 0,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id)
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                changedFields.push(key)
                result[existingIdx][key] = payload[key]
            } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                changedFields.splice(changedFields.indexOf(key), 1)
                result[existingIdx][key] = payload[key]
            }
        }

        result[existingIdx]['changedFields'] = changedFields
        if (payload.street_type_id < 0) {
            result[existingIdx]['streetType'] = payload?.street?.streetType
        }

        return {data: result, count: src.mutable.count}
    },
    target: $addressesChildren
})

sample({
    source: $createdAddresses,
    clock: editChildAddressEv,
    filter: (src, {id}) => id < 0,
    fn: (src, {payload, id}) => {
        const result = [...src]
        const existing = result.find(i => i.id === id)
        const idx = result.findIndex(i => i.id === id)
        // Если редактируемая улица является новой, то не отражаем в ней присутствие измененных полей
        result.splice(idx, 1, {...payload, id: existing.id})
        return result
    },
    target: $createdAddresses
})

sample({
    source: {existing: $addressesChildren, deleted: $deletedAddresses},
    clock: deleteChildAddressEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)
        if (item.id > 0) {
            return [...src.deleted, item.id]
        } else {
            return src.deleted
        }
    },
    target: $deletedAddresses
})

sample({
    source: $addressesChildren,
    clock: deleteChildAddressEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $addressesChildren
})

sample({
    source: $createdAddresses,
    clock: deleteNewAddressEv,
    fn: (src, id) => {
        const result = [...src]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return result
    },
    target: $createdAddresses
})

sample({
    source: {existing: $addressesChildren, newAddresses: $createdAddresses},
    clock: setCreateEditChildAddressEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            let item
            let itemIdx
            if (clock.id > 0) {
                item = src.existing.data.find(i => i.id === clock.id)
                itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            } else {
                item = src.newAddresses.find(i => i.id === clock.id)
                itemIdx = src.newAddresses.findIndex(i => i.id === clock.id)
            }
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildAddressEmbedded
})
