import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import {useUnit} from 'effector-react';
import {Select} from "antd";
import {
    $ipAddressesChildren,
    changeIpAddressesChildrenFiltersEv
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
// import {changeIpAddressFiltersEv} from "../../../models/dictionaryBankApiAddressModel/index.js";



export default function IpAddressEmbeddedFilters() {
    const {t} = useTranslation()
    const changeFilter = useUnit(changeIpAddressesChildrenFiltersEv);
    const opt = [
        {
            label: 'Все',
            value: 'all',
        },
        {
            label: 'Да',
            value: true,
        },
        {
            label: 'Нет',
            value: false,
        }
    ]

    const onChange = (value) => {
        changeFilter({key: 'is_active', value: value})
    }

    return <Card title={t('Фильтрация')} size={'small'} style={{marginBottom: 16}}>
        <Row justify={'space-between'} gutter={[16, 16]}>
            <Col span={8}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <span>{t('Актуальный')}</span>
                    <Select
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        onChange={onChange}
                        options={opt}
                        defaultValue={true}
                    />
                </div>
            </Col>
        </Row>
    </Card>
}
