import {createStore} from 'effector';


export const $knpList = createStore({data: [], count: 0})

export const $selectedKnp = createStore(null)

export const $editKnpStatus = createStore(false)

export const $createdKnpId = createStore(null)
export const $afterCreateAction = createStore(null)
