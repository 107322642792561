import {
    $afterCreateAction,
    $createdNationalityId,
    $editNationalityStatus,
    $selectedNationality,
    $nationalityList
} from './stores.js';
import {getNationalityListFx, getNationalityByIdFx, createNationalityFx} from './effects.js';
import {
    deleteNationalityEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    NationalityEditGate,
    NationalityListGate,
    NationalityViewGate, submitCreateNationalityEv, submitEditNationalityEv, NationalityCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createNationalityCompleteEv = combineEvents({events: {ev: submitCreateNationalityEv, response: triggerSuccessOpsEv}, reset: NationalityCreateGate.state})
const editNationalityCompleteEv = combineEvents({ev: submitEditNationalityEv, response: triggerSuccessOpsEv})
const deleteNationalityCompleteEv = combineEvents({ev: deleteNationalityEv, response: triggerSuccessOpsEv})

$nationalityList.on(getNationalityListFx.doneData, (_, nationality) => nationality)
    .reset(NationalityListGate.close)

$selectedNationality.on(getNationalityByIdFx.doneData, (_, nationality) => nationality)
    .reset([NationalityEditGate.close, NationalityViewGate.close])
// FIXME
$createdNationalityId.on(createNationalityCompleteEv, (_, {response}) => response.streets[0])
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editNationalityStatus.on(editNationalityCompleteEv, () => true)
    .reset(NationalityEditGate.close)

sample({
    clock: [NationalityEditGate.state, NationalityViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getNationalityByIdFx
})

sample({
    clock: NationalityListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getNationalityListFx
})

sample({
    source: NationalityListGate.state,
    clock: [createNationalityCompleteEv, editNationalityCompleteEv, deleteNationalityCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getNationalityListFx
})

sample({
    clock: submitCreateNationalityEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'nationality', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedNationality,
    clock: submitEditNationalityEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'nationality', value: {object_id: current.id, ...changedFields}})


        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteNationalityEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'nationality', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
