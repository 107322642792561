import MappedDictItem from "../MappedDictItem/MappedDictItem.jsx";
import Button from "antd/es/button";
import {DownOutlined} from "@ant-design/icons";
import {useState} from "react";

const style = {
    width: '100%',
    marginTop: '5px',
    padding: 0,
    height: '22px'
}

const styleColor = {
    // color: '#1677ff'
    color: '#fff'
}

export default function CustomAccordion({ids, count, type}) {

    const [viewMore, seViewMore] = useState(false)

     const more = viewMore === false ? ids.slice(0, 3).map(item => {
         return <div>
             <MappedDictItem id={item} type={type}/>
         </div>
     }) : ids.map(item => {
         return <div>
             <MappedDictItem id={item} type={type}/>
         </div>
     })


    return <>

        {more}

        {viewMore === false && count > 3 ? <Button style={style} type="primary" onClick={() => seViewMore(true)}>
            <DownOutlined style={styleColor}/>
        </Button> : ''}

    </>


}

