import {createStore} from 'effector';
import {getReportsListFx} from "./effects.js";


export const $reportsList = createStore({data: [], count: 0})

export const $selectedReports = createStore(null)

export const $editReportsStatus = createStore(false)

export const $createdReportsId = createStore(null)
export const $afterCreateAction = createStore(null)
