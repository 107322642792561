import {sample} from 'effector';
import {
    $contollersAll,
    $controllersList, $createdTask,
    $filters, $generateTypeSt, $radioFilesSt, $radioSt, $reportDate,
    $selectedControllers, $sendToBank, $taskCreated, $uploadedFilesData, $uploadFileModal, $uploadStatus,
} from './stores.js';
import {
    applyFiltersEv,
    changeFiltersEv,
    changeSelectedControllersEv,
    createTaskAllEv,
    createTaskEv, downloadFilesEv,

    downloadSingleFileEv,
    InspectorFilesGate,
    resetFiltersEv,
    resetTaskCreatedEv,
    resetUploadFileModalEv, setGenerateTypeEv,
    setRadioEv,
    setRadioFilesEv,
    setReportDateEv, setSendToBankEv,
    setUploadFileModalEv,
    TaskAllPdfGate,
    TaskPdfToContsGate,
    UploadedFilesGate,
    uploadFileEv
} from './events.js';
import {
    createTaskFx, downloadFilesFx,
    downloadSingleFileFx, getAllDataFx,
    getControllersFx, getFileListFx, getForAllPdfFx, uploadFileFx
} from './effects.js';
import queryString from 'query-string';
import {notifyCreationEv} from '../tasksGlobalModel/index.js';
import dayjs from 'dayjs';
import {notification} from 'antd';
import i18n from '../../i18n.js';
import {useSearchParams} from "react-router-dom";
import {$generateType} from "../addressSearchModel/index.js";

// $controllersList.on(getForAllPdfFx.doneData, (_, controllers) => controllers)
$controllersList.on(getForAllPdfFx.doneData, (_, controllers) => {
    const newData = {
     count: controllers?.count,
        data: controllers?.data ? controllers?.data : controllers?.accounts
    }
    return newData
})
    .on(getAllDataFx.doneData, (_, controllers) => {
        const newData = {
            count: controllers.count,
            data: controllers?.data ? controllers.data : controllers.accounts
        }
        return newData
    })
    .reset([TaskPdfToContsGate.close, InspectorFilesGate.close])

// $contollersAll.on(getForAllPdfFx.doneData, (existing, newData) => [...existing, ...newData?.data?.filter(n => !existing?.map(e => e.id)?.includes(n.id))])

// $contollersAll.on(getForAllPdfFx.doneData, (existing, newData) => {
//     debugger
//     return  [
//         ...existing,
//         // ...newData?.data?.filter(n => !existing?.map(e => e.id)?.includes(n.id))
//         ...newData?.data?.length > 0 ?
//             newData?.data?.filter(n => !existing?.map(e => e.id)?.includes(n.id)) :
//             newData?.accounts?.filter(n => !existing?.map(e => e.id)?.includes(n.id))
//     ]
// })
    .reset([TaskPdfToContsGate.close, InspectorFilesGate.close])

$filters.on(changeFiltersEv, (state, {key, value}) => ({...state, [key]: value}))
    .reset(resetFiltersEv)

$reportDate.on(setReportDateEv, (state, value) => value)
$generateTypeSt.on(setGenerateTypeEv, (state, value) => value)
$sendToBank.on(setSendToBankEv, (state, value) => value)

$selectedControllers.on(changeSelectedControllersEv, (state, v) => v)
$radioSt.on(setRadioEv, (state, v) => v)
$radioFilesSt.on(setRadioFilesEv, (state, v) => v)

$taskCreated.on(createTaskFx.doneData, () => true)
    .reset(resetTaskCreatedEv)

$uploadFileModal.on(setUploadFileModalEv, () => true)
    .reset(resetUploadFileModalEv)
$uploadStatus.on(uploadFileFx.doneData, () => true)
    .reset(resetUploadFileModalEv)
$uploadedFilesData.on(getFileListFx.doneData, (_, data) => data)
    .reset(UploadedFilesGate.close)

sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeFiltersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return []
    },
    target: $selectedControllers
})


sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeSelectedControllersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true && selected.length > 0) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return {
            ...filters
            // select_all : false
        }
    },
    target: $filters
})

sample({
    source: {gate: TaskPdfToContsGate.state, filters: $filters},
    clock: [TaskPdfToContsGate.state.updates, applyFiltersEv, resetFiltersEv],
    filter: ({gate}) => Object.hasOwn(gate, 'search'),
    fn: ({gate, filters}) => {
        const checked = {...filters}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf' || key === 'has_pdf' || key === 'pdf_month') {
                delete checked[key]
            }
        }

        return {
            ...queryString.parse(gate.search.toString(), {parseNumbers: true, parseBooleans: true}),
            // ...queryString.parse(filters, {parseNumbers: true, parseBooleans: true}),
            ...checked
        }
    },
    target: getControllersFx
})

sample({
    source: {gate: TaskAllPdfGate.state, filters: $filters, radio: $radioSt},
    clock: [TaskAllPdfGate.state.updates, applyFiltersEv, resetFiltersEv, $radioSt.updates],
    filter: ({gate}) => Object.hasOwn(gate, 'search'),
    fn: ({gate, filters, radio}) => {
        const checked = {...filters}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf' || key === 'has_pdf' || key === 'pdf_month') {
                delete checked[key]
            }
        }
        return {
            filters: {
                ...queryString.parse(gate.search.toString(), {parseNumbers: true, parseBooleans: true}),
                ...checked
            },
            type: radio
        }
    },
    target: getForAllPdfFx
})

sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeFiltersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return []
    },
    target: $selectedControllers
})


sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeSelectedControllersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true && selected.length > 0) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return {
            ...filters,
            select_all : false
        }
    },
    target: $filters
})

sample({
    source: {selected: $selectedControllers, filters: $filters, radio: $radioSt, gate: TaskAllPdfGate.state},
    clock: createTaskEv,
    fn: (data, flags) => {
        const filter = queryString.parse(data.gate.search.toString(), {parseNumbers: true})
        if (filter.town_id) {
            filter.town_id = [filter.town_id]
        }
        const checked = {...data.filters, ...filter}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf' || key === 'has_pdf' || key === 'pdf_month') {
                delete checked[key]
            }
        }


        const payload = {
            include_payments: flags.include_payments ? flags.include_payments : false,
            include_personal_data: flags.include_personal_data ? flags.include_personal_data : false,
            include_taxes: flags.include_taxes ? flags.include_taxes : false,
            past_period: flags.past_period ? flags.past_period : false,
            send_to_bank: flags.send_to_bank ? flags.send_to_bank : false,

            filters: checked,
            report_date: flags.past_period === true ? dayjs(flags.prev_month).format() : null,
            recreate_pdf: data.filters.recreate_pdf ?? false,
            generate_type: data.radio
        }

        console.log('send task with', payload)

        if (data.radio === 'sector') {
            payload.sector_ids = data.selected
        } else if (data.radio === 'inspector') {
            payload.inspector_ids = data.selected
        } else if (data.radio === 'reo') {
            payload.reo_ids =  data.selected
        } else if (data.radio === 'account') {
            payload.account_ids = data.selected
        }

        delete payload.month
        // if (flags.past_period === false) {
        //     delete payload.report_date
        // }
        return payload
    },
    target: [createTaskFx, $createdTask]
})


sample({
    source: {selected: $selectedControllers, filters: $filters, radio: $radioSt, gate: TaskAllPdfGate.state},
    clock: createTaskAllEv,
    fn: (data, flags) => {
        const filter = queryString.parse(data.gate.search.toString(), {parseNumbers: true})
        if (filter.town_id) {
            filter.town_id = [filter.town_id]
        }
        const checked = {...data.filters, ...filter}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf' || key === 'has_pdf' || key === 'pdf_month') {
                delete checked[key]
            }
        }

        const payload = {
            include_payments: flags.include_payments ? flags.include_payments : false,
            include_personal_data: flags.include_personal_data ? flags.include_personal_data : false,
            include_taxes: flags.include_taxes ? flags.include_taxes : false,
            past_period: flags.past_period ? flags.past_period : false,
            send_to_bank: flags.send_to_bank ? flags.send_to_bank : false,

            filters: {...checked, show_list_option: 'only_active'},
            report_date: flags.past_period === true ? dayjs(flags.prev_month).format() : null,
            recreate_pdf: data.filters.recreate_pdf ?? false,
            generate_type: data.radio
        }
        delete payload.month
        // if (flags.past_period === false) {
        //     delete payload.report_date
        // }
        if (data.selected.length === 0) {
            delete payload.selected
        }
        return payload
    },
    target: [createTaskFx, $createdTask]
})

sample({
    source: {gate: InspectorFilesGate.state, filters: $filters, send_to_bank: $sendToBank, report_date: $reportDate},
    clock: [InspectorFilesGate.state.updates, applyFiltersEv, resetFiltersEv, $sendToBank.updates, $reportDate.updates],
    filter: ({gate}) => Object.hasOwn(gate, 'search'),
    fn: ({gate, filters, send_to_bank, report_date}) => {
        const checked = {...filters}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf') {
                delete checked[key]
            } else if (key === 'pdf_month') {
                checked[key] = dayjs(checked[key]).format()
            }
        }


        const result = {
            data: {
                ...queryString.parse(gate.search.toString(), {parseNumbers: true}),
            ...checked
            },
            generate_type: gate.generate_type,
            send_to_bank: send_to_bank,
            report_date: report_date
        }

        console.log('files GET', result)
        return result
    },
    target: getAllDataFx
})

sample({
    clock: downloadFilesEv,
    target: downloadFilesFx
})

sample({
    clock: downloadSingleFileEv,
    target: downloadSingleFileFx
})

sample({
    clock: createTaskFx.doneData,
    target: notifyCreationEv
})

downloadFilesFx.failData.watch((err) => {
    if (err.response.status === 404) {
        notification.error({
            message: i18n.t('Ошибка'),
            description: i18n.t('Файлы по данным параметрам не найдены')
        })
    }
})

downloadSingleFileFx.failData.watch((err) => {
    if (err.response.status === 404) {
        notification.error({
            message: i18n.t('Ошибка'),
            description: i18n.t('Файл не найден')
        })
    }
})

sample({
    clock: uploadFileEv,
    target: uploadFileFx
})

// sample({
//     source: UploadedFilesGate.state,
//     clock: uploadFileFx.doneData,
//     fn: (gate) => gate.table_name,
//     target: getFileListFx
// })

// sample({
//     clock: UploadedFilesGate.state,
//     fn: (gate) => gate.table_name,
//     target: getFileListFx
// })
