import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getInspectorsListFx} from "./effects.js";

export const InspectorsListGate = createGate()
export const InspectorsViewGate = createGate()
export const InspectorsEditGate = createGate()
export const InspectorsCreateGate = createGate()

export const submitCreateInspectorsEv = createEvent()
export const submitEditInspectorsEv = createEvent()
export const deleteInspectorsEv = createEvent()
export const getInspectorsEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
