import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useEffect} from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import {
    $createEditChildAddressEmbedded, resetCreateEditChildAddressEmbeddedEv
} from '../../../models/embeddedFormsModels/embeddedAddressesModel';
import useFormChanged from '../../../hooks/useFormChanged.js';
import dayjs from 'dayjs';
import Col from 'antd/es/col';

export default function CreateEditAddressEmbeddedForm({onCreate, onEdit}) {
    const embedded = useUnit($createEditChildAddressEmbedded)
    const reset = useUnit(resetCreateEditChildAddressEmbeddedEv)

    const {t} = useTranslation()
    const [addressForm] = Form.useForm()

    const [check, changedFields] = useFormChanged(embedded.item, ['house', 'corpus', 'is_actual', 'actual_from'])


    const onClose = () => {
        addressForm.resetFields()
        reset()
    }

    useEffect(() => {
        if (embedded.open && embedded.type === 'edit' && embedded.idx !== null && embedded.item) {
            addressForm.setFieldsValue({
                ...embedded.item,
                actual_from: embedded?.item?.actual_from ? dayjs(embedded.item.actual_from) : null
            })
        }
    }, [embedded]);

    const onSubmit = (values) => {
        if (embedded.type === 'edit') {
            onEdit({
                payload: {
                    ...values,
                    actual_from: dayjs(values.actual_from).format('YYYY-MM-DD')
                }, id: embedded.item.id
            })
        } else if (embedded.type === 'create') {
            onCreate({...values, actual_from: dayjs(values.actual_from).format('YYYY-MM-DD')})
        }
        onClose()
    }

    return embedded.open &&
        <Form form={addressForm}
              size={'small'}
              layout={'vertical'}
              onFinish={onSubmit}
              style={{width: '100%', marginBottom: 16}}
              onValuesChange={(_, all) => {
                  if (embedded.type === 'edit') {
                      check(all)
                  }
              }}
        >
            <Card title={t('Свойства адреса')} size={'small'} style={{height: '100%'}}>
                <Row justify={'space-between'} gutter={[16, 16]}>
                    <Col span={5}>
                        <Form.Item name={'house'}
                                   label={t('Номер дома')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['house'] : undefined}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'corpus'} label={t('Номер корпуса')}
                                   validateStatus={embedded.type === 'edit' ? changedFields['corpus'] : undefined}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'is_actual'}
                                   label={t('Актуален')}
                                   // rules={[{required: true, message: t('Обязательное поле')}]}
                                   valuePropName={'checked'}
                                   defaultValue={true}
                                   validateStatus={embedded.type === 'edit' ? changedFields['is_actual'] : undefined}
                        >
                            <Switch/>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item name={'actual_from'}
                                   label={t('Дата начала актуальности')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['actual_from'] : undefined}
                        >
                            <DatePicker style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
            <Row style={{gap: 8, marginTop: 16}}>
                <Button onClick={onClose} danger>{t('Отмена')}</Button>
                <Button type={'primary'} onClick={addressForm.submit}>{t('Сохранить')}</Button>
            </Row>
        </Form>
}
