import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const ChiefInspectorListGate = createGate()
export const ChiefInspectorViewGate = createGate()
export const ChiefInspectorEditGate = createGate()
export const ChiefInspectorCreateGate = createGate()

export const submitCreateChiefInspectorEv = createEvent()
export const submitEditChiefInspectorEv = createEvent()
export const deleteChiefInspectorEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
