import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getEmailSubApproveListFx = createEffect()
    .use(async (filters) => {
        return (await api().get(`/email_dispatch/${filters.length > 0 ? `?${filters}` : ''}`)).data
    })

export const approveEmailSubFx = createEffect()
    .use(async (ids) => (await api().post('/email_dispatch/approve', {ids})).data)

export const rejectEmailSubFx = createEffect()
    .use(async (ids) => (await api().post('/email_dispatch/disapprove', {ids})).data)

export const getEmailApproveFileFx = createEffect()
    .use(async (id) => (await api().get(`/import_file/email_dispatch/${id}`)).data)
