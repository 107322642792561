import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const setAddressSelectedEv = createEvent()

export const resetAddressSelectedEv = createEvent()

export const selectAddressEv = createEvent()

export const searchAddressEv = createEvent()
export const selectRowEv = createEvent()

export const AddressSearchGate = createGate()

export const createAddressEv = createEvent()
export const openCreateAddressEv = createEvent()

export const cancelSelectedEv = createEvent()
export const setAddressPaginationEv = createEvent()
export const getAddressNoLimitEv = createEvent()
export const setRESFormDataEv = createEvent()
export const onDeleteRowNoLimitEv = createEvent()
export const setGenetrateTypeEv = createEvent()
export const resetSelectedEntitiesEv = createEvent()
