import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const CorrectionListGate = createGate()

export const createCorrectionEv = createEvent()
export const cancelCorrectionEv = createEvent()
export const resetCreatedCorrectionEv = createEvent()

export const deleteCorrectionEv = createEvent()
export const rollbackCorrectionEv = createEvent()
export const setCorrHistTableEv = createEvent()

export const finishCorrectionEv = createEvent()
export const resetFinalStatusEv = createEvent()

export const setFinalActionEv = createEvent()
export const resetFinalActionEv = createEvent()

// correction itself

export const ProvSrvAccCorrectionGate = createGate()
export const ViewCorrectionGate = createGate()

// upload corrections
export const setUploadCorrModalEv = createEvent()
export const resetUploadCorrModalEv = createEvent()
export const uploadCorrEv = createEvent()
export const getCorrUploadErrorsEv = createEvent()
export const uploadConfirmEv = createEvent()
export const uploadCancelEv = createEvent()
export const startUploadTimeout = createEvent()
export const stopUploadTimeout = createEvent()
export const setUploadConfirmedMetaEv = createEvent()
// ---

export const selectCorrectionPeriodEv = createEvent()
export const setJustificationEv = createEvent()

export const setTableParamsEv = createEvent()
export const searchAccsEv = createEvent()
export const resetAccsEv = createEvent()

export const addExcludedAccEv = createEvent()
export const removeExcludedAccEv = createEvent()

export const setSelectedAccEv = createEvent()
export const setMultiSelectedAccsEv = createEvent()
export const resetSelectedAccEv = createEvent()

export const selectAccSrvEv = createEvent()
export const resetSelectedAccSrvEv = createEvent()

export const setSrvCreateModalEv = createEvent()
export const resetSrvCreateModalEv = createEvent()

export const setMassEditModalEv = createEvent()
export const resetMassEditModalEv = createEvent()

export const deleteAllTempCorrectionsEv = createEvent()

export const setAccSrvsFilterEv = createEvent()
export const resetAccSrvsFilterEv = createEvent()

export const setAfterActionEv = createEvent()

export const deleteSingleCorrectionEv = createEvent()

export const submitAddSingleCorrEv = createEvent()
export const submitEditSingleCorrEv = createEvent()
export const submitTempCorrsMassEv = createEvent()

export const submitAccountTempCorrEv = createEvent()
export const signalCorrSuccessEv = createEvent()

export const downloadHistoryEv = createEvent()
// тариф
export const getPSTarifsEv = createEvent()
export const createTariffEv = createEvent()

