import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const AddressesListGate = createGate()
export const AddressViewGate = createGate()
export const AddressEditGate = createGate()
export const AddressCreateGate = createGate()
export const SecondaryAddressListGate = createGate()
export const MainAddressListGate = createGate()

export const submitCreateAddressEv = createEvent()
export const submitEditAddressEv = createEvent()
export const deleteAddressEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()

export const getSecAddressesListEv = createEvent()
export const getMainAddressesListEv = createEvent()
export const setMainAddressEv = createEvent()
export const setSecAddressEv = createEvent()
export const resetMainAddressEv = createEvent()
export const removeSelectedSecAddressEv = createEvent()
export const addSecAddressEv = createEvent()

export const createGroupAddressesEv = createEvent()
