import {useSearchParams} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    ChonkyActions, FileHelper,
    FileList,
    FileNavbar,
    FileToolbar,
    FullFileBrowser,
    setChonkyDefaults
} from 'chonky';
import {ChonkyIconFA} from 'chonky-icon-fontawesome';
import React from "react";

import {
    $filesList, downloadFileEv,
    FilesListGate,
} from "../../models/filesModel";

setChonkyDefaults({
    iconComponent: ChonkyIconFA,
    disableSelection: true,
    disableDragAndDropProvider: true,
    disableDragAndDrop: true,
    defaultFileViewActionId: ChonkyActions.EnableListView.id,
});


const russianI18n = {
    locale: 'ru',
    formatters: {
        formatFileModDate: (intl, file) => {
            const safeModDate = FileHelper.getModDate(file);
            if (safeModDate) {
                return `${intl.formatDate(safeModDate)}, ${intl.formatTime(
                    safeModDate
                )}`;
            } else {
                return null;
            }
        },
        formatFileSize: (intl, file) => {
            if (!file || typeof file.size !== 'number') return null;
            return `Размер: ${file.size}`;
        },
    },
    messages: {
        // Chonky UI translation strings. String IDs hardcoded into Chonky's source code.
        'chonky.toolbar.searchPlaceholder': 'Поиск',
        'chonky.toolbar.visibleFileCount': `{fileCount, plural,
            one {# файл}
            few {# файла}
            many {# файлов}
            other {# файлов}
        }`,
        'chonky.toolbar.selectedFileCount': `{fileCount, plural,
            =0 {}
            one {# выделен}
            other {# выделено}
        }`,
        'chonky.toolbar.hiddenFileCount': `{fileCount, plural,
            =0 {}
            one {# скрыт}
            other {# скрыто}
        }`,
        'chonky.fileList.nothingToShow': 'Здесь пусто!',
        'chonky.contextMenu.browserMenuShortcut': 'Меню браузера: {shortcut}',

        // File action translation strings. These depend on which actions you have
        // enabled in Chonky.
        [`chonky.actionGroups.Actions`]: 'Действия',
        [`chonky.actionGroups.Options`]: 'Опции',
        [`chonky.actions.${ChonkyActions.OpenParentFolder.id}.button.name`]: 'Открыть родительскую папку',
        [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.name`]: 'Новая папка',
        [`chonky.actions.${ChonkyActions.CreateFolder.id}.button.tooltip`]: 'Создать новую папку',
        [`chonky.actions.${ChonkyActions.DeleteFiles.id}.button.name`]: 'Удалить файлы',
        [`chonky.actions.${ChonkyActions.OpenSelection.id}.button.name`]: 'Открыть выделение',
        [`chonky.actions.${ChonkyActions.SelectAllFiles.id}.button.name`]: 'Выделить все',
        [`chonky.actions.${ChonkyActions.ClearSelection.id}.button.name`]: 'Сбросить выделение',
        [`chonky.actions.${ChonkyActions.EnableListView.id}.button.name`]: 'Показать список',
        [`chonky.actions.${ChonkyActions.EnableGridView.id}.button.name`]: 'Показать иконки',
        [`chonky.actions.${ChonkyActions.SortFilesByName.id}.button.name`]: 'Сорт. по имени',
        [`chonky.actions.${ChonkyActions.SortFilesBySize.id}.button.name`]: 'Сорт. по размеру',
        [`chonky.actions.${ChonkyActions.SortFilesByDate.id}.button.name`]: 'Сорт. по дате',
        [`chonky.actions.${ChonkyActions.ToggleHiddenFiles.id}.button.name`]: 'Скрытые файлы',
        [`chonky.actions.${ChonkyActions.ToggleShowFoldersFirst.id}.button.name`]: 'Папки в начале',
    },
};


export default function FilesList() {
    const {t} = useTranslation()
    const files = useUnit($filesList)
    const download = useUnit(downloadFileEv)
    const [search, setSearch] = useSearchParams()
    const path = search?.get('path') || '/'

    const chainArr = (path === '/' || path === '' ? [''] : path.split('/'))
    const chain = chainArr.reduce((res, v) => {
        const p = res[res.length - 1]
        if (p === undefined) return ['']
        return [...res, `${p}/${v}`]
    }, []).map(
        (id) => {
            const p = id.split('/').pop()
            return {id: id === '' ? '/' : id, name: id === '' ? '/' : p, isDir: true}
        }
    )
    // console.log('path', path, files, chain)

    const handleAction = (data) => {
        // console.log('________________:', data);
        if (data.id === 'open_files') {
            const f = data?.payload?.targetFile || {}
            if (f.isDir) {
                console.log('move to dir:', f, path)
                setSearch({path: f.id})
                return
            }
            if (f.id) {
                console.log('download file:', f)
                download(f.id)
            }
        }
    }

    return <>
        <FilesListGate search={search.toString()}/>
        <FullFileBrowser
            files={files} folderChain={chain} onFileAction={handleAction}
            disableDefaultFileActions={false}
            i18n={russianI18n}
        >
            <FileNavbar/>
            <FileToolbar/>
            <FileList/>
        </FullFileBrowser>
    </>
}
