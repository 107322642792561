import {createStore} from 'effector';


export const $bankApiInfoList = createStore({data: [], count: 0})

export const $selectedBankApiInfo = createStore(null)

export const $editBankApiInfoStatus = createStore(false)

export const $createdBankApiInfoId = createStore(null)
export const $afterCreateAction = createStore(null)
