import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Table from 'antd/es/table';
import MappedDictItem from "../../../../components/MappedDictItem/MappedDictItem.jsx";
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import * as styles from './ServiceView.module.css'
import {useUnit} from "effector-react";
import {$userPerms} from "../../../../models/infoModel/index.js";
import permitted from "../../../../utils/permitted.js";

export default function ServiceView(props) {
    const {service} = props
    const {t} = useTranslation()
    const perms = useUnit($userPerms)
    const withBCom = permitted(['provider_service_bank_commission::view'], perms)
    const withPref = permitted(['provider_service_preference::view'], perms)

    console.log('ServiceView tf', service)
    return <Row gutter={[8, 8]} style={{padding: '8px 0'}}>
        <Col span={4}>
            <Tarifs data={service.tarifs}/>
        </Col>
        {withBCom && <Col span={10}>
            <Commissions data={service.commissions || []}/>
        </Col>}
        {withPref && <Col span={10}>
            <Prefs data={service.preferences || []}/>
        </Col>}
    </Row>
}

function Tarifs(props) {
    const {t} = useTranslation();
    const {data} = props
    const [inactive, setInactive] = useState(false)

    const changeInactive = () => {
        setInactive(!inactive)
    }

    // noinspection JSUnusedGlobalSymbols
    const columns = [
        {
            title: t('Тариф'),
            dataIndex: 'value',
            render: (_, r, _idx) => r.value,
            sortable: true,
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.value - b.value,
        },
        {
            title: <Button icon={inactive ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                           onClick={changeInactive}/>,
            dataIndex: 'id',
            width: '32px',
            render: () => null,
        },
    ];

    const fData = (data || []).filter(d => !!d.inactive === !!inactive)

    return <Table
        size={'small'}
        name={['tarifs']}
        columns={columns}
        bordered
        dataSource={fData}
        rowKey={'id'}
        pagination={false}
    />
}

function Prefs(props) {
    const {t} = useTranslation();
    const {data} = props

    const columns = [
        {
            title: t('Льгота'),
            dataIndex: 'preference_category_id',
            key: 'preference_category_id',
            render: (id, _) => {
                return <MappedDictItem id={id} type={'preference_category'}/>
            },
        },
    ]

    console.log('Prefs', data)
    return <Table dataSource={data}
                  columns={columns}
                  bordered
                  pagination={false}
                  size={'small'}
                  rowKey={'id'}
                  className={styles.tableInExpanded}
    />
}

function Commissions(props) {
    const {t} = useTranslation();
    const {data} = props

    const columns = [
        {
            title: t('Банк'),
            dataIndex: 'bank_id',
            render: (id, _) => {
                return <MappedDictItem id={id} type={'banks'}/>
            },
        },
        {
            title: t('Комиссия'),
            width: '100px',
            dataIndex: 'bank_commission_id',
            render: (v, _) => {
                return <MappedDictItem id={v} type={'bank_commission'} withID={false}/>
            },
        },
    ]

    return <Table dataSource={data}
                  columns={columns}
                  bordered
                  pagination={false}
                  size={'small'}
                  rowKey={'id'}
                  className={styles.tableInExpanded}
    />
}
