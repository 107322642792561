import {createEffect} from "effector";
import api from "../../api/api.js";
import queryString from "query-string";

export const getAgreementsFileListFx = createEffect()
	.use(async (filters) => {
		const parsed = filters.size > 0 ? queryString.parse(filters.toString(), {parseNumbers: true, parseBooleans: true}) : {}
		for (const [key, value] of Object.entries(parsed)) {
			if (!value || (Array.isArray(value) && value?.length === 0)) {
				delete parsed[key]
			}
		}
		return (await api().get(`/import_file/?table_name=external_number${filters.size > 0 ? `&${queryString.stringify(parsed)}` : ''}`)).data
	})

export const uploadAgreementsFileFx = createEffect()
	.use(async (data) => {
		const payload = new FormData()
		data.files.forEach(file => {
			payload.append('files', file?.originFileObj)
		})
		const config = {headers: {'Content-Type': 'multipart/form-data'}}

		return await (api().post(`import_file/import/external_number?${data.provider_id ? `provider_id=${data.provider_id}` : ''}`, payload, config))
	})

export const sendMessageUploadFx = createEffect()
	.use(async (data) => {
		data.sendMessage(JSON.stringify(data.payload))
	});


export const acceptFileFx = createEffect()
	.use(async (id) => (await api().get(`/import_file/confirm/${id}`)).data)

export const rejectFileFx = createEffect()
	.use(async (id) => (await api().get(`/import_file/cancel/${id}`)).data)

export const stopUploadFileFx = createEffect()
	.use(async (id, type) => (await api().post(`/import_file/${type}/${id}`)).data)

export const deleteFileFx = createEffect()
	.use(async (id) => (await api().delete(`/import_file/${id}`)).data)

export const getAgreementsFileCellsFx = createEffect()
	.use(async ({id, filters}) => {
        if (!filters?.get('sort_field')) filters.set('sort_field', 'row_index')
		return (await api().get(`/import_file/external_number/${id}?${filters}`)).data
	})

export const getAgreementsFileInfoFx = createEffect()
	.use(async (id) => (await api().get(`/import_file/${id}`)).data)


export const downloadFileFx = createEffect()
	.use(async (id) => {
		const file = await api().get(`/import_file/download/${id}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})

export const downloadErrorFileFx = createEffect()
	.use(async (id) => {
		const file = await api().get(`/import_file/download_errors/${id}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})
