import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {
    $selectedStreet, StreetViewGate,
    getStreetByIdFx
} from '../../../models/dictionaryStreetsModel';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import Divider from 'antd/es/divider';
import AddressesTable from '../../../components/Embedded/AddressesTable/AddressesTable.jsx';

export default function ViewStreet() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getStreetByIdFx.pending)
    const street = useUnit($selectedStreet)
    const genitive_form = t(`breadcrumbs.genitive.street`)
    const items = [
        {
            key: 'town_id',
            label: t('Населенный пункт'),
            children: <MappedDictItem id={street?.town_id} type={'town'}/>,
        },
        // {
        //     key: 'town_distr_id',
        //     label: t('Район'),
        //     children: <MappedDictItem id={street?.town_distr_id} type={'districts'}/>
        // },
        {
            key: 'street_type_id',
            label: t('Тип улицы'),
            children:<MappedDictItem id={street?.street_type_id} type={'street_types'}/>
        },
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: street?.name_ru
        },
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: street?.name_kz
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={street?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: dayjs(street?.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: street?.updated_by ? <CreatedBy id={street?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: street?.updated_at ? dayjs(street?.updated_at).format('DD.MM.YYYY HH:mm:ss') : '-'
        }
    ]

    return <>
        <StreetViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр улицы')}</span> <MappedDictItem id={id} type={'street'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'street'}/>
            <Divider>{t('Адреса')}</Divider>
            <AddressesTable view parent={'street_id'} parentId={+id}/>
        </Card>
    </>
}
