import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
	$paymentExportModal,
	$paymentExportModalData,
	closePaymentExportModalEv,
	exportToBankFx,
	onExportToBankSingleEv
} from '../../../models/paymentModel';
import Modal from "antd/es/modal";
import Button from "antd/es/button";


export default function PaymentExportModal() {
	const {t} = useTranslation()

	const open = useUnit($paymentExportModal)
	const onClose = useUnit(closePaymentExportModalEv)
	const payload = useUnit($paymentExportModalData)
	const onConfirmSingleEvent = useUnit(onExportToBankSingleEv)
	const loading = useUnit(exportToBankFx.pending)
	let bank_days = payload?.data?.bank_day || []
	if (typeof bank_days === 'string') bank_days = [bank_days]
	console.log('payload', payload)

	return (
		<Modal
			open={open}
			onCancel={onClose}
			footer={
			<>{
				payload?.type === 'single' ?
					<Button type={'primary'} onClick={() => onConfirmSingleEvent({bank_days})} loading={loading}>{t('Сделать доступным для банка')}</Button> :
					payload?.type === 'selected' ?
						<Button type={'primary'} onClick={() => onConfirmSingleEvent({bank_days})} loading={loading}>{t('Сделать доступными для банка')}</Button> :
						<Button type={'primary'} onClick={() => onConfirmSingleEvent({bank_days})} loading={loading}>{t('Сделать доступными для банка')}</Button>
			}
				<Button onClick={onClose}>
					Отмена
				</Button>
			</>
			}
			title={'Внимание!'}
		>
			Отменить данное действие не возможно.<br/>
            Вы принимаете на себя ответственность за то, что данные станут доступными для банка?
		</Modal>
	);
}
