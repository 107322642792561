import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getAddressesListFx = createEffect()
    .use(async (filters) => (await api().get(`/address/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getAddressByIdFx = createEffect()
    .use(async (id) => (await api().get(`/address/${id}`)).data)

export const getSecAddressesListFx  = createEffect()
		.use(async (filters) => (await api().get(`/address/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getMainAddressesListFx = createEffect()
		.use(async (filters) => (await api().get(`/address/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getSecondaryAddressesFx = createEffect()
		.use(async (id) => (await api().get(`/address/?main_address_id=${id}`)).data)
