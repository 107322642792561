import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const EPDTemplateFieldListGate = createGate()
export const EPDTemplateFieldViewGate = createGate()
export const EPDTemplateFieldEditGate = createGate()
export const EPDTemplateFieldCreateGate = createGate()

export const submitCreateEPDTemplateFieldEv = createEvent()
export const submitEditEPDTemplateFieldEv = createEvent()
export const deleteEPDTemplateFieldEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
