import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getPasswordSettingsListFx} from "./effects.js";

export const PasswordSettingsListGate = createGate()
export const PasswordSettingsViewGate = createGate()
export const PasswordSettingsEditGate = createGate()
export const PasswordSettingsCreateGate = createGate()

export const submitCreatePasswordSettingsEv = createEvent()
export const submitEditPasswordSettingsEv = createEvent()
export const deletePasswordSettingsEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()


export const openModalEv = createEvent()
export const dataModalEv = createEvent()
export const closeModalEv = createEvent()
