import {createStore} from 'effector';
import {getCalcTypeListFx} from "./effects.js";


export const $calcTypeList = createStore({data: [], count: 0})

export const $selectedCalcType = createStore(null)

export const $editCalcTypeStatus = createStore(false)

export const $createdCalcTypeId = createStore(null)
export const $afterCreateAction = createStore(null)
