import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';

import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import { setCreateTownEmbeddedEv} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import {
    $editCurrencyStatus,
    $selectedCurrency,
    CurrencyEditGate, getCurrencyByIdFx,
    submitEditCurrencyEv
} from "../../../models/dictionaryCurrencyModel/index.js";

export default function EditCurrency() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedCurrency)

    const [currencyForm] = Form.useForm()

    const submit = useUnit(submitEditCurrencyEv)

    const loading = useUnit(getCurrencyByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editCurrencyStatus)
    const townsOpts = useStoreMap($dictsSelects, sel => sel.towns)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const newTown = useUnit($createdTownIdModal)
    const genitive_form = t(`breadcrumbs.genitive.currency`);




    useEffect(() => {
        if (status) {
            navigate('/dictionaries/currency')
        }
    }, [status]);


    useEffect(() => {
        if (selected) {
            currencyForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['town_kz','name_kz', 'name_ru'])

    return <>
        <CurrencyEditGate id={id}/>
        <Form form={currencyForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Свойства валюты')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item name={'short_name_kz'}
                                   label={t(`Короткое Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Короткое Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>


            </Row>

        </Form>

        <Row>
            <Space>
                <Button danger onClick={() => {
                    currencyForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {currencyForm.submit()}}
                        type={'primary'}
                >
                    {t('Сохранить')}
                </Button>
            </Space>
        </Row>
    </>
}
