import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getUsersListFx = createEffect()
    .use(async (filters) => (await api().get(`/users/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getUsersByIdFx = createEffect()
    .use(async (id) => (await api().get(`/users/${id}`)).data)


export const createUserFx = createEffect()
    .use(async (payload) => (await api().post(`/users/`, payload)))

export const updateUserFx = createEffect()
    .use(async ({id, payload}) => (await api().put(`/users/${id}`, payload)).data)


export const getUserProviderFx = createEffect()
    .use(async (id) => (await api().get(`/user_provider/?user_id=${id}&ignore_limit=true`)).data)
