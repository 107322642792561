import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {
    $accLockedModal,
    resetAccLockedModalEv
} from '../../models/dictionaryAccountsModel/index.js';
import Modal from 'antd/es/modal';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Typography from 'antd/es/typography';
import {$userPerms} from '../../models/infoModel/index.js';

export default function AccountLockedModal() {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [_search, setSearch] = useSearchParams()

    const {open, status_reason, page, id} = useUnit($accLockedModal)
    const resetModal = useUnit(resetAccLockedModalEv)

    const perms = useUnit($userPerms)

    const onCancel = () => {
        if (page === 'list') {
            setSearch()
            resetModal()
        } else {
            resetModal()
            navigate('/dictionaries/accounts')
        }
    }

    return <Modal open={open}
                  title={`${t('ЛС')} ${id} ${t('заблокирован')}`}
                  width={'60vw'}
                  onCancel={() => onCancel()}
                  footer={<Space>
                      {page === 'list'
                          ? <Button type={'primary'} onClick={() => onCancel()}>
                              {t('Вернуться к поиску')}
                          </Button>
                          : <Button onClick={() => onCancel()}>
                              {t('Вернуться к списку')}
                          </Button>
                      }
                      {perms?.['accounts']?.['block_unblock']
                          && <Button type={'primary'} onClick={() => {
                              resetModal()
                              navigate(`/dictionaries/accounts/edit/${id}?tab=block`)
                          }}>
                              {t('Изменить статус')}
                          </Button>}
                  </Space>}
    >
        <Space direction={'vertical'}>
            <span>{t('Причина блокировки:')}</span>
            <Typography.Text>
                {status_reason ?? t('Нет')}
            </Typography.Text>
        </Space>
    </Modal>
}