import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const FilesGate = createGate()
export const ViewFileCellsGate = createGate()

export const uploadFileEv = createEvent()

export const setUploadModalEv = createEvent()
export const resetUploadModalEv = createEvent()

export const sendMessageUploadEv = createEvent()
export const acceptMessageUploadEv = createEvent()

export const selectFileEv = createEvent()
export const deselectFileEv = createEvent()

export const acceptFileEv = createEvent()
export const stopUploadFileEv = createEvent()
export const rejectFileEv = createEvent()
export const deleteFileEv = createEvent()

export const downloadErrorFileEv = createEvent()
