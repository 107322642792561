import {
    $servicesList,
} from './stores.js';
import {getServicesOptionsListFx} from './effects.js';
import {
    AdminServicesListGate, editServicesOptionEv,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from "../dictionaryUniversalModel/index.js";
import {combineEvents} from "patronum";


const editCompleteEv = combineEvents({ev: editServicesOptionEv, response: triggerSuccessOpsEv})

$servicesList.on(getServicesOptionsListFx.doneData, (_, data) => data)
    .reset(AdminServicesListGate.close)


sample({
    source: AdminServicesListGate.state,
    clock: [AdminServicesListGate.state, editCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServicesOptionsListFx
})


sample({
    clock: editServicesOptionEv,
    fn: (payload) => {
        const actions = []
        const data = {
            [payload.type]: payload.value,
        }
        actions.push({operation: 'upsert', type: 'service_options', value: {id: payload.id, ...data}})
        return actions
    },
    target: submitGlobalUpdateEv
})

