import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {useUnit} from 'effector-react';
import TasksStatuses from '../../components/TasksStatuses/TasksStatuses.jsx';
import FileManager from './components/FileManager.jsx';
import Tabs from 'antd/es/tabs';
import CreateTaskPerREO from './components/CreateTaskPerREO.jsx';
import {$taskCreatedStatus, resetTaskCreatedEv} from '../../models/taskEPDPerRESModel/index.js';

export default function TaskPrintEPDPerREO() {
    const {t} = useTranslation()
    const [activeKey, setActiveKey] = useState('create');

    const taskCreated = useUnit($taskCreatedStatus)
    const resetTaskCreated = useUnit(resetTaskCreatedEv)

    const onChangeTab = (payload) => {
        setActiveKey(payload)
    }

    useEffect(() => {
        if (taskCreated) {
            onChangeTab('status')
            resetTaskCreated()
        }
    }, [taskCreated])

    const tabs = [
        {
            key: 'create',
            label: t('Создание задачи'),
            children: <CreateTaskPerREO  />
        },
        {
            key: 'status',
            label: t('Статусы задач'),
            children: <TasksStatuses name={'real_estate_object_epd'}/>
        },
        {
            key: 'files',
            label: t('Файлы'),
            children: <FileManager />
        }
    ]

    return <Tabs
        defaultActiveKey={'create'}
        items={tabs}
        destroyInactiveTabPane
        activeKey={activeKey}
        onChange={onChangeTab}
        type={'card'}
    />
}