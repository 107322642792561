import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getAccountsFx = createEffect()
    .use(async (filters) => (await api().get(`/accounts/?${filters}&ignore_limit=true`)).data)

export const getReosFx = createEffect()
    .use(async (filters) => (await api().get(`/real_estate_objects/?${filters}&ignore_limit=true`)).data)

export const getAddressesFx = createEffect()
    .use(async (filters) => (await api().get(`/address/?${filters}&ignore_limit=true`)).data)