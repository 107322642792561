import Space from 'antd/es/space';
import {PlusOutlined} from '@ant-design/icons';

export function optionRender(option) {
    return <Space>
        <span role="img" aria-label={option.data.label}>
            {option?.data?.emoji ?
                <PlusOutlined style={{color: '#1677ff'}}/> : ''}
        </span>
        {
            option?.data?.emoji ?
                <span style={{color: '#1677ff'}}>
                    {option?.data.label}
                </span> :
                <span>
                    {option?.data.label}
                </span>
        }
    </Space>
}

export function filterOption (input, option) {
    const label = (((option?.label ? option?.label : option?.children) || '')+'').toLowerCase()
    input = input?.toLowerCase() || ''

    return (
        (
            /^\d+[ ]$/.test(input) && label.startsWith(input)
        ) || (
            !(/^\d+[ ]$/.test(input)) && label.includes(input)
        )
    )
}
