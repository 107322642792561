import {
    $afterCreateAction,
    $createdBankCommissionId,
    $editBankCommissionStatus,
    $selectedBankCommission,
    $bankCommissionList
} from './stores.js';
import {getBankCommissionListFx, getBankCommissionByIdFx} from './effects.js';
import {
    deleteBankCommissionEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    BankCommissionEditGate,
    BankCommissionListGate,
    BankCommissionViewGate,
    submitCreateBankCommissionEv,
    submitEditBankCommissionEv, BankCommissionCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateBankCommissionEv, response: triggerSuccessOpsEv}, reset: BankCommissionCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditBankCommissionEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteBankCommissionEv, response: triggerSuccessOpsEv})

$bankCommissionList.on(getBankCommissionListFx.doneData, (_, data) => data)
    .reset(BankCommissionListGate.close)

$selectedBankCommission.on(getBankCommissionByIdFx.doneData, (_, data) => data)
    .reset([BankCommissionEditGate.close, BankCommissionViewGate.close])
// FIXME
$createdBankCommissionId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "bank_commission")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editBankCommissionStatus.on(editCompleteEv, () => true)
    .reset(BankCommissionEditGate.close)

sample({
    clock: [BankCommissionEditGate.state, BankCommissionViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getBankCommissionByIdFx
})

sample({
    clock: BankCommissionListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankCommissionListFx
})

sample({
    source: BankCommissionListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankCommissionListFx
})

sample({
    clock: submitCreateBankCommissionEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'bank_commission', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedBankCommission,
    clock: submitEditBankCommissionEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        const value = {
            ...changedFields,
            id: parseInt(changedFields.id) ? parseInt(changedFields.id) : null,
            object_id:  current.id,
        }

        if (value.id === null) {
            delete value.id
        }
        actions.push({operation: 'update', type: 'bank_commission', value: value})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteBankCommissionEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'bank_commission', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
