import {createStore} from 'effector';
import {getPasswordSettingsListFx} from "./effects.js";


export const $passwordSettingsList = createStore({data: [], count: 0})

export const $selectedPasswordSettings = createStore(null)

export const $editPasswordSettingsStatus = createStore(false)

export const $createdPasswordSettingsId = createStore(null)
export const $afterCreateAction = createStore(null)


export const $passwordSettingsModal = createStore(false)
export const $passwordSettingsModalData = createStore(false)
