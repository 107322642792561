import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import {
	$deductionModal, $paymentDeductionModalData,
	$paymentExportModal,
	$paymentExportModalData,
	closeDeductionModalEv,
	closePaymentExportModalEv, onCreateSingleEv, onCreateSingleFx,
	onExportToBankSingleEv, onUpdateSingleEv, onUpdateSingleFx
} from '../../../models/paymentModel';
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Form from "antd/es/form/index.js";
import Input from "antd/es/input/index.js";
import {InputNumber, Select} from "antd";
import Space from "antd/es/space/index.js";
import {PlusOutlined} from "@ant-design/icons";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel/index.js";


const countTypes = [
	{
		label: 'По договору',
		value: 'По договору'
	},
	{
		label: 'По количеству строк',
		value: 'По количеству строк'
	},
]


export default function DeductionCreateEditModal() {
	const {t} = useTranslation()

	const open = useUnit($deductionModal)
	const onClose = useUnit(closeDeductionModalEv)
	const payload = useUnit($paymentDeductionModalData)
	const onCreateSingleEvent = useUnit(onCreateSingleEv)
	const onEditSingleEvent = useUnit(onUpdateSingleEv)
	const createLoading = useUnit(onCreateSingleFx.pending)
	const updLoading = useUnit(onUpdateSingleFx.pending)
	const loading = createLoading || updLoading

	const providerOpts = useStoreMap($dictsSelects, sel => sel.providers)
	const providerDict = useStoreMap($infoMaps, maps => maps.providersMap)

	const [deductionForm] = Form.useForm()

	const deductSelected = Form.useWatch('deduct_amount', deductionForm)
	const amountLeftSelected = Form.useWatch('amount_left', deductionForm)
	const amountSelected = Form.useWatch('amount', deductionForm)


	console.log('payload', payload)

	useEffect(() => {
		if (payload?.type === 'edit') {
			const data = {
				amount: payload?.row?.amount,
				amount_left: payload?.row?.amount_left,
				deduct_amount: payload?.row?.amount - payload?.row?.amount_left,

			}
			deductionForm.setFieldsValue(data)
		}
	}, [payload]);


	const onUpdateAmountLeft = (amount) => {
		if (amount.target.value > deductSelected) {
			deductionForm.setFieldValue('amount_left', amount.target.value - deductSelected)
		}
	}

	const onCreateEdit = (v) => {
		if (payload?.type === 'edit') {
			const data = {
				id: payload.row.id,
				amount: parseInt(amountSelected),
			}
			onEditSingleEvent(data)
			deductionForm.resetFields()
		} else {
			const formData = deductionForm.getFieldsValue()
			const data = {
				provider_id: formData.provider_id,
				provider_name: providerDict[formData.provider_id],
				amount: formData.amount,
				// FIXME count_type - non required
				// count_type: 'По договору',
				year_month: parseFloat(dayjs(Date.now()).format('YYYYMM')),
			}

			onCreateSingleEvent(data)

			deductionForm.resetFields()

		}
	}


	return (
		<Modal
			open={open}
			onCancel={onClose}
			width={700}
			footer={
			<>
				<Button onClick={onClose}>
					Отмена
				</Button>
				<Button
					type={'primary'}
					onClick={(v) => onCreateEdit(v)}
					disabled={amountSelected < 0 ? true : false}
					loading={loading}
				>
					{payload?.type === 'create' ? t('Создать') : t('Сохранить')}
				</Button>

			</>
			}
			title={payload?.type === 'create' ? t('Создание') : t(`Редактирование ${providerDict[payload?.row?.provider_id]}`)}
		>
			<Form form={deductionForm}
				  size={'small'}
				  layout={'vertical'}
				  onFinish={(v) => submit(v)}
				  validateTrigger={'onBlur'}
			>
				{
					payload?.type === 'create' ?
						<Row gutter={[16,16]}>
							<Col span={6}>
								<Form.Item name={'provider_id'}
										   label={t('Поставщик')}
										   rules={[
											   { required: true, message: 'Обязательное поле' },
										   ]}
								>
									<Select
										options={providerOpts}
										showSearch
										filterOption={(input, option) =>
											(option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
										}
									/>
								</Form.Item>
							</Col>
							<Col span={6}>
								<Form.Item name={'amount'}
										   label={t('К удержанию')}
										   rules={[
											   { required: true, message: 'Обязательное поле' },
										   ]}
								>
									<InputNumber style={{width: '100%'}}/>
								</Form.Item>
							</Col>
							{/*<Col span={6}>*/}
							{/*	<Form.Item name={'count_type'}*/}
							{/*			   label={t('Тип удержания')}*/}
							{/*			   rules={[*/}
							{/*				   { required: true, message: 'Обязательное поле' },*/}
							{/*			   ]}*/}
							{/*	>*/}
							{/*		<Select*/}
							{/*			options={countTypes}*/}
							{/*			showSearch*/}
							{/*			filterOption={(input, option) =>*/}
							{/*				(option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())*/}
							{/*			}*/}
							{/*		/>*/}
							{/*	</Form.Item>*/}
							{/*</Col>*/}
						</Row> :

						<Row gutter={[16,16]}>
							<Col span={8}>
								<Form.Item name={'amount'}
										   label={t('К удержанию')}
										   rules={[
											   { required: true, message: 'Обязательное поле' },
											   ({ getFieldValue }) => ({
												   validator(_, value) {
													   const deductAmount = getFieldValue('deduct_amount');
													   if (value <= deductAmount) {
														   return Promise.reject(new Error('Сумма к удержанию должна быть больше того, что уже удержали'));
													   }
													   return Promise.resolve();
												   },
											   }),
										   ]}
								>
									<InputNumber  style={{width: '100%'}} onBlur={(v) => onUpdateAmountLeft(v)}/>
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name={'deduct_amount'}
										   label={t('Удержано')}
										   rules={[{required: false, message: t('Обязательное поле')}]}
								>
									<InputNumber style={{width: '100%', color: '#000'}} disabled={true} />
								</Form.Item>
							</Col>
							<Col span={8}>
								<Form.Item name={'amount_left'}
										   label={t('Осталось удержать')}
										   rules={[{required: false, message: t('Обязательное поле')}]}
								>
									<InputNumber style={{width: '100%', color: '#000'}} disabled={true}/>
								</Form.Item>
							</Col>

						</Row>
				}
			</Form>
		</Modal>
	);
}
