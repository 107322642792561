import {createEffect} from 'effector';
import api from '../../api/api.js';
import {setAddressSelectedEv} from "./events.js";

export const getSelectsForAddressFx = createEffect()
    .use(async (filters) => (await api().get(`/address/select_address_list?${filters}`)).data)

export const getTownsSelectFx = createEffect()
    .use(async () => (await api().get(`/town/`)).data)

export const getDistrictsSelectFx = createEffect()
    .use(async (townId) => (await api().get(`/town_distr/?town_id=${townId}`)).data)

export const getAddressFx = createEffect()
    .use(async (filters) => (await api().get(`/address/?${filters}&sort_field=real_estate_object_id&sort_order=desc`)).data)


export const getAddressNoLimitFx = createEffect()
    .use(async (filters) => (await api().get(`/address/?${filters}&sort_field=real_estate_object_id&sort_order=desc&ignore_limit=true`)).data)
