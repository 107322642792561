import {$editServiceColumnStatus, $selectedColumn, $servicesColumnsData} from "./stores.js";
import {getServicesColumnByIdFx, getServicesColumnsListFx, sendEditServicesColumnFx} from "./effects.js";
import {sample} from "effector";
import {ServiceColumnsEditGate, ServiceColumnsGate, submitEditServiceColumnsEv} from "./events.js";
import {combineEvents} from "patronum";
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from "../dictionaryUniversalModel/index.js";

const editCompleteEv = combineEvents({events: {ev: submitEditServiceColumnsEv, response: triggerSuccessOpsEv}, reset: ServiceColumnsEditGate.close})

$servicesColumnsData.on(getServicesColumnsListFx.doneData, (_, data) => data)
	.reset(ServiceColumnsGate.close)

$selectedColumn.on(getServicesColumnByIdFx.doneData, (_, data) => data)
	.reset([ServiceColumnsGate.close, ServiceColumnsEditGate.close])

$editServiceColumnStatus.on(editCompleteEv, () => true)
	.reset(ServiceColumnsEditGate.close)

sample({
	clock: ServiceColumnsGate.state,
	fn: (gate) => {
		return gate.search
	},
	target: getServicesColumnsListFx
})

sample({
	clock: ServiceColumnsEditGate.state,
	filter: (gate) => Object.hasOwn(gate, 'id'),
	fn: (gate) => +gate.id,
	target: getServicesColumnByIdFx
})

// sample({
// 	source: ServiceColumnsEditGate.state,
// 	clock: [editServicesColumnsCompleteEv],
// 	filter: (gate) => Object.hasOwn(gate, 'id'),
// 	fn: (gate) => +gate.id,
// 	target: getServicesColumnByIdFx
// })

sample({
	source: $selectedColumn,
	clock: submitEditServiceColumnsEv,
	fn: (selected, payload) => {
		const actions = []
		const changedFields = {}
		for (const [key, value] of Object.entries(payload)) {
			if (key === 'image') {
				continue
			}
			if (selected[key] !== value) {
				changedFields[key] = value
			}
		}
		actions.push({operation: 'update', type: 'epd_pdf_settings', value: {object_id: selected.id, ...changedFields}})
		return actions
	},
	target: submitGlobalUpdateEv
})


