import {createStore} from 'effector';

export const $serviceClassesList = createStore({data: [], count: 0})

export const $selectedServiceClass = createStore(null)

export const $editServiceClassStatus = createStore(false)

export const $createdServiceClassId = createStore(null)
export const $afterCreateAction = createStore(null)
