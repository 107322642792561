import {createGate} from 'effector-react';
import {createEvent} from "effector";

export const AccountsListGate = createGate()

export const AccountsFilterEv = createEvent()
export const sendMessageAccountsEv = createEvent()
export const AccountsSendEmailEv = createEvent()
export const AccountsGeneratePdfEv = createEvent()
export const printAccountPdfEv = createEvent()
export const changeAccountsPageEv = createEvent()
export const getAccountUrlPdfEv = createEvent()
export const sendEmailAccountsEv = createEvent()
