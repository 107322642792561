import {createEffect} from 'effector';
import api from '../../api/api.js';


export const sendMessageControllersFx = createEffect()
    .use(async (data) => {
        data.sendMessage(JSON.stringify(data.payload))
    });



export const getControllersListFx = createEffect()
    .use(async () => {
        try {
            const response = await api().get('/inspectors/');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });


export const generateControllersPdfFx = createEffect()
    .use(async (payload) => {
        try {
            const response = await api().get('/accounts/?limit=10&offset=0');
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });


export const sendControllersEmailFx = createEffect()
    .use(async (payload) => {
        try {
            const data = {
                account_ids: payload,
                report_date: dayjs(new Date()).format(),
            }
            const response = await api().post('/dispatch/email', data);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });

export const printControllersPdfFx = createEffect()
    .use(async (payload) => {
        try {
            const data = {
                inspector_ids: payload,
                report_date: dayjs(new Date()).format(),
            }
            const response = await api().post('/dispatch/print', data);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });

export const getControllersFilterFx = createEffect()
    .use(async (value) => {
        console.log('value', value)
        const aesbyt_account = value?.accounts?.map(item => 'aesbyt_account=' + item)
        try {
            const response = await api().get(`/accounts/?${aesbyt_account?.join('&')}&limit=10&offset=0`);
            return response.data;
        } catch (error) {
            console.error('Failed to fetch accounts list', error);
            throw error;
        }
    });
