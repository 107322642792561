import {useUnit} from "effector-react";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {useMemo} from 'react';
import {
    $closeMonthsData,
    $monthsDict,
    CloseMonthGate, getClosedMonthFx,
    sendCloseMonthEv, sendCloseMonthFx
} from '../../models/closeMonthModel/index.js';
import {setCloseMonthBlockingModal} from '../../models/closeMonthBlockingModalModel/index.js';
import {$info} from "../../models/infoModel/index.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import Divider from "antd/es/divider";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import Button from "antd/es/button";
import {useTranslation} from "react-i18next";
import Tooltip from "antd/es/tooltip";
import {Progress} from "antd";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames, monthNamesG} from "../../utils/monthNamesDict.js";
import Descriptions from "antd/es/descriptions";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import Popconfirm from 'antd/es/popconfirm';

export default function CloseMonth() {
    const {t} = useTranslation();
    const {data, count} = useUnit($closeMonthsData)
    const loading = useUnit(getClosedMonthFx.pending)
    const {search} = searchAndActions()
    const monthDict = useUnit($monthsDict)
    const sendCloseMonth = useUnit(sendCloseMonthEv)
    const closeLoading = useUnit(sendCloseMonthFx.pending)
    const setModal = useUnit(setCloseMonthBlockingModal)
    const {current_date} = useUnit($info)

    const onConfirmCloseMonth = () => {
        setModal(true)
        sendCloseMonth()
    }

    const monthOpts = useMemo(() => {
        return monthDict.map(item => {
            return {
                ...item,
                label: t(monthNames[item.label]),
            }
        })
    }, [monthDict])

    const filterItems = [
        {
            name: 'months',
            label: t('Месяц'),
            type: 'select',
            options: monthOpts,
        },
        {
            name: 'created_by',
            label: t('Кто осуществил переход'),
            type: 'select',
            dict: 'users'
        },
        {
            name: 'created_at',
            label: t('Когда был переход'),
            type: 'range',
        },
    ]

    const columns = [
        {
            title: t('Статус перехода'),
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (status, record) => <Tooltip
                title={
                    status === 'processing'
                        ? t('В обработке')
                        : status === 'error'
                            ? record.load_error
                            : t('Успешно')
                }
                placement={'right'}
            >
                {status === 'processing'
                    ? <div>
                        <Progress type="circle" size={40} percent={parseInt(record.progress)}/>
                        {/*<LoadingOutlined style={{fontSize: 24}}/>*/}
                    </div>
                    : status === 'error'
                        ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                        : status === 'new'
                            ? '-'
                            : <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>}
            </Tooltip>
        },


        {
            width: '5%',
            title: t('Месяц, с которого осуществляется переход'),
            dataIndex: 'month',
            sorter: true,
            key: 'month',
            render: (text) => t(monthNames[text])
        },
        {
            width: '5%',
            title: t('Месяц, на который осуществляется переход'),
            dataIndex: 'month',
            sorter: true,
            key: 'month',
            render: (text) => {
                return t(monthNames[text !== 13 ? text + 1 : 2])
            },
        },
        {
            width: '5%',
            title: t('Дата и время запуска перехода'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date) => <CustomDates data={date}/>,
        },
        {
            width: '5%',
            title: t('Дата и время завершения перехода'),
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (date) => <CustomDates data={date}/>,
        },


        {
            width: '5%',
            title: t('Пользователь, запустивший переход.'),
            dataIndex: 'created_by',
            key: 'created_by',
            render: (id) => <MappedDictItem id={id} type={'users'} link={false}/>
        },

        {
            width: '5%',
            title: t('Действия'),
            key: 'actions',
            align: 'center',
            dataIndex: 'id',
            render: (id, record) =>
                <ButtonGroup>
                    <Tooltip title={t('Посмотреть логи')}>
                        <Button icon={<EyeOutlined/>} href={'/close-month/view/' + id}/>
                    </Tooltip>
                </ButtonGroup>
        }
    ]

    const prev_month = t(monthNames[dayjs(current_date).get('month')]) // from 0
    const current_month = t(monthNames[dayjs(current_date).get('month') + 1]) // from 0
    const next_month = t(monthNames[dayjs(current_date).get('month') + 2]) // from 0

    const current_month_g = t(monthNamesG[dayjs(current_date).get('month') + 1]) // from 0
    const next_date = dayjs(current_date).add(1, 'month')

    const no_close = next_date.format('YYYY-MM') >= dayjs().format('YYYY-MM')
    const tooltip = no_close ? t('Переход будет доступен с 1-го числа') : null
    console.log('CloseMonth', prev_month, current_month, next_month, current_date, dayjs(current_date))
    return <>
        <CloseMonthGate search={search.toString()}/>
        <Descriptions size={'small'}>
            <Descriptions.Item
                label={t('Последний закрытый месяц')}>{prev_month}</Descriptions.Item>
            <Descriptions.Item
                label={t('Текущий расчетный месяц')}>{current_month}</Descriptions.Item>
            <Descriptions.Item label={t('Будущий месяц')}>{next_month}</Descriptions.Item>
        </Descriptions>
        <Popconfirm title={t('Вы уверены, что хотите начать закрытие месяца?')}
                    okText={t('Да')}
                    onConfirm={onConfirmCloseMonth}
                    disabled={no_close}
        >
            <Tooltip title={tooltip}>
                <Button
                    type={'primary'} style={{marginBottom: '20px'}}
                    loading={closeLoading}
                    disabled={no_close || closeLoading}
                >
                    {t(`Перейти с ${current_month_g} на ${next_month}`)}
                </Button>
            </Tooltip>
        </Popconfirm>
        <Divider/>
        <FilterBlock items={filterItems}/>
        <DictionaryProTable
            style={{marginTop: '8px'}}
            initColumns={columns}
            showActions={false}
            data={data}
            loading={loading}
            count={count}
        />
    </>
}
