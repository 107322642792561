import {sample} from 'effector';
import {
    $afterCreateAction,
    $townsList,
    $createdTownId,
    $editTownStatus, $selectedTown,
} from './stores.js';
import {
    getTownsListFx,
    getTownByIdFx,
} from './effects.js';
import {
    TownsListGate, TownEditGate, TownViewGate,
    deleteTownEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    submitCreateTownEv, submitEditTownEv, TownCreateGate
} from './events.js';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $createdStreets,
    $deletedStreets,
    $streetsChildren
} from '../embeddedFormsModels/embeddedStreetsModel';
import {
    $deletedDistricts,
    $districtsChildren
} from '../embeddedFormsModels/embeddedDistrictsModel';

const createTownCompleteEv = combineEvents({events: {ev: submitCreateTownEv, response: triggerSuccessOpsEv}, reset: TownCreateGate.state})
const editTownCompleteEv = combineEvents({ev: submitEditTownEv, response: triggerSuccessOpsEv})
const deleteTownCompleteEv = combineEvents({ev: deleteTownEv, response: triggerSuccessOpsEv})

$townsList.on(getTownsListFx.doneData, (_, towns) => towns)
    .reset(TownsListGate.close)

$selectedTown.on(getTownByIdFx.doneData, (_, city) => city)
    .reset([TownEditGate.close, TownViewGate.close])

$createdTownId.on(createTownCompleteEv, (_, {response}) => {
    const town = response.find(i => i.type === 'town')
    return town ? town?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editTownStatus.on(editTownCompleteEv, () => true)
    .reset(TownEditGate.close)

sample({
    clock: TownsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getTownsListFx
})

sample({
    source: TownsListGate.state,
    clock: [createTownCompleteEv, editTownCompleteEv, deleteTownCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getTownsListFx
})

sample({
    clock: TownEditGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [getTownByIdFx]
})

sample({
    clock: TownViewGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [getTownByIdFx]
})

sample({
    source: {districts: $districtsChildren.map(state => state.data), streets: $createdStreets},
    clock: submitCreateTownEv,
    fn: (src, payload) => {
        // Инициализация набора действий создания
        const actions = []
        let idCounter = -1
        // Комманда на создание населенного пункта
        actions.push({operation: 'create', type: 'town', value: {...payload, id: idCounter}})
        // Фиксация новой ID для подстановки в дочерние сущности
        const town_id = idCounter

        // Список комманд на создание районов
        if (src?.districts?.length > 0) {
            const newDists = src.districts.filter(i => i.id < 0)
            for (const newDist of Object.values(newDists)) {
                idCounter--
                actions.push({operation: 'create', type: 'town_distr', value: {...newDist, town_id, id: idCounter}})
            }
        }
        // Список комманд на создание улиц
        if (src?.streets?.length > 0) {
            for (const newStreet of Object.values(src?.streets)) {
                let street_type_id = newStreet.street_type_id
                if (newStreet.street_type_id < 0) {
                    idCounter--
                    actions.push({operation: 'create', type: 'street_type', value: {...newStreet.streetType, id: idCounter}})
                    street_type_id = idCounter
                }
                idCounter--
                actions.push({operation: 'create', type: 'street', value: {...newStreet, town_id, street_type_id, id: idCounter}})
            }
        }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {
        current: $selectedTown,
        districts: $districtsChildren.map(state => state.data),
        deletedDists: $deletedDistricts,
        streets: $streetsChildren.map(state => state.data),
        newStreets: $createdStreets,
        deletedStreets: $deletedStreets
    },
    clock: submitEditTownEv,
    fn: (src, payload) => {
        // Инициализация набора действий создания/редактирования/удаления
        const actions = []
        let idCounter = -1

        // Проверка на измененные поля базовой сущности
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (src.current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'town', value: {object_id: src.current.id, ...changedFields}})

        /*
        Проверка на измененные поля в дочерних сущностях
        либо создание новых дочерних сущностей
        и добавление соответствующих комманд.

        У ново-созданных дочерних сущностей отрицательный id выставляемый на фронте, в порядке убывания.
        Так мы отслеживаем тип действия - создание. Бэк также завязан на эту логику.
        */
        if (src.districts?.length > 0) {
            for (const dist of Object.values(src.districts)) {
                if (dist.id < 0) {
                    actions.push({operation: 'create', type: 'town_distr', value: {...dist, town_id: src.current.id, id: idCounter}})
                    idCounter--
                } else if (dist.id > 0 && dist.changedFields?.length > 0) {
                    const payload = {}
                    for (const key of Object.values(dist.changedFields)) {
                        payload[key] = dist[key]
                    }
                    actions.push({operation: 'update', type: 'town_distr', value: {object_id: dist.id, ...payload}})
                }
            }
        }

        if (src?.streets?.length > 0) {
            for (const street of Object.values(src?.streets)) {
                if (street.id > 0 && street.changedFields?.length > 0) {
                    const payload = {}
                    for (const key of Object.values(street.changedFields)) {
                        payload[key] = street[key]
                    }
                    if (street.street_type_id < 0) {
                        idCounter--
                        actions.push({operation: 'create', type: 'street_type', value: {...street.streetType, id: idCounter}})
                        payload['street_type_id'] = idCounter
                        delete street.streetType
                    }
                    actions.push({operation: 'update', type: 'street', value: {object_id: street.id, ...payload}})
                }
            }
        }

        if (src?.newStreets?.length > 0) {
            for (const newStreet of Object.values(src?.newStreets)) {
                let street_type_id = newStreet.street_type_id
                if (newStreet.street_type_id < 0) {
                    idCounter--
                    actions.push({operation: 'create', type: 'street_type', value: {...newStreet.streetType, id: idCounter}})
                    street_type_id = idCounter
                    delete newStreet.streetType
                }
                idCounter--
                actions.push({operation: 'create', type: 'street', value: {...newStreet, town_id: src.current.id, street_type_id, id: idCounter}})
            }
        }

        // Удаление дочерних сущностей
        if (src.deletedDists?.length > 0) {
            for (const id of src.deletedDists) {
                actions.push({operation: 'delete', type: 'town_distr', value: {id}})
            }
        }
        if (src?.deletedStreets?.length > 0 ) {
            for (const id of src.deletedStreets) {
                actions.push({operation: 'delete', type: 'street', value: {id}})
            }
        }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteTownEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'town', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})