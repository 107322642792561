import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const KbkListGate = createGate()
export const KbkViewGate = createGate()
export const KbkEditGate = createGate()
export const KbkCreateGate = createGate()

export const submitCreateKbkEv = createEvent()
export const submitEditKbkEv = createEvent()
export const deleteKbkEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
