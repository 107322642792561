import Card from 'antd/es/card';
import {useStoreMap, useUnit} from 'effector-react';
import {$infoMaps} from '../../models/infoModel/index.js';
import {useTranslation} from 'react-i18next';
import Space from 'antd/es/space';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {
    $createdMissAddresses,
    $createdREO,
    $selectedREOFromAddress,
    createMissingAddressesEv, resetCreatedREOEv,
    resetSelectionEv
} from '../../models/accountsREOSearchModel/index.js';
import {
    $createReoEmbeddedOpen,
    setCreateReoEmbeddedEv
} from '../../models/dictionaryEmbeddedFormsModel/index.js';
import {useNavigate} from 'react-router-dom';
import {$createdAddress} from '../../models/addressSearchModel/index.js';

function getTitle(name) {
    switch (name) {
        case 'address':
            return 'адрес'
        case 'reo':
            return 'объект недвижимости'
        case 'account':
            return 'лицевой счет'
        default:
            return 'объект'
    }
}

function AddressOnly ({obj, missing, maps, onCancel}) {
    const {t} = useTranslation()
    const navigate = useNavigate()

    return obj ? <Space direction={'horizontal'}>
            {
                (obj.real_estate_object_id !== null)
                    ? <span style={{color: '#a80a0a'}}>
                        {`${t('Объект недвижимости по этому адресу уже сущестует')}: г. ${maps.towns[obj?.town_id]}, ${obj?.towd_distr_id ? `(${maps.distrs[obj?.towd_distr_id]}),` : ''} ${maps.streets[obj?.street_id]}, ${t('дом')} ${obj?.house} ${obj?.corpus ? `, корпус ${obj?.corpus}` : ''}`}
                </span>
                    : <span>{`г. ${maps.towns[obj?.town_id]}, ${obj?.towd_distr_id ? `(${maps.distrs[obj?.towd_distr_id]}),` : ''} ${maps.streets[obj?.street_id]}, ${t('дом')} ${obj?.house} ${obj?.corpus ? `, корпус ${obj?.corpus}` : ''}`}</span>
            }
            <Space direction={'vertical'}>
                <ButtonGroup size={'small'}>
                    <Button onClick={() => onCancel()}>{t('Изменить')}</Button>
                    <Button type={'primary'} onClick={() => {
                        onCancel()
                        navigate(`/dictionaries/real-estate-objects/edit/${obj.real_estate_object_id}`)
                    }}>
                        {t('Перейти к редактированию')}
                    </Button>
                </ButtonGroup>
            </Space>
        </Space>
        : <Space direction={'horizontal'}>
            <span>{`г. ${maps.towns[missing[0]?.town_id]}, ${missing[0]?.towd_distr_id ? `(${maps.distrs[missing[0]?.towd_distr_id]}),` : ''} ${maps.streets[missing[0]?.street_id]}, ${t('дом')} ${missing[0]?.house} ${missing[0]?.corpus ? `, корпус ${missing[0]?.corpus}` : ''}`}</span>
            <Space direction={'vertical'}>
                <Button onClick={() => onCancel()}>{t('Изменить')}</Button>
            </Space>
        </Space>
}

function AddressREO ({selReo, createdReo, address, missing, maps, onCancel}) {
    const {t} = useTranslation()
    const createReo = useUnit(setCreateReoEmbeddedEv)
    const reoEmbeddedOpen = useUnit($createReoEmbeddedOpen)
    const resetCreatedREO = useUnit(resetCreatedREOEv)

    const onChangeCreatedREO = () => {
        resetCreatedREO()
        createReo(true)
    }

    return selReo ? <Space direction={'horizontal'}>
            <span>{`г. ${maps.towns[selReo.town_id]}, ${selReo.towd_distr_id ? `(${maps.distrs[selReo.towd_distr_id]}),` : ''} ${maps.streets[selReo.street_id]}, ${t('дом')} ${selReo.house}, ${selReo.corpus ? `корпус ${selReo.corpus}` : ''}`}</span>
            <Space direction={'vertical'}>
                <Button onClick={() => onCancel()}>{t('Изменить')}</Button>
            </Space>
        </Space>
        : createdReo ? <Space direction={'horizontal'}>
            <span>{`г. ${maps.towns[createdReo.town_id]}, ${createdReo.towd_distr_id ? `(${maps.distrs[createdReo.towd_distr_id]}),` : ''} ${maps.streets[createdReo.street_id]}, ${t('дом')} ${createdReo.house}, ${createdReo.corpus ? `корпус ${createdReo.corpus}` : ''}`}</span>
            <ButtonGroup size={'small'}>
                <Button onClick={onChangeCreatedREO}>{t('Изменить созданный ОН')}</Button>
                <Button onClick={() => onCancel()} danger>{t('Изменить адрес')}</Button>
            </ButtonGroup>
        </Space>
            : selReo === false && createdReo === null
                ? <Space direction={'horizontal'}>
                    <span>{t('Объект недвижимости по этому адресу не найден:')}</span>
                    <span>
                        {address
                            ? `г. ${maps.towns[address?.town_id]}, ${address?.towd_distr_id ? `(${maps.distrs[address?.towd_distr_id]}),` : ''} ${maps.streets[address?.street_id]}, ${t('дом')} ${address?.house} ${address?.corpus ? `, корпус ${address?.corpus}` : ''}`
                            : `г. ${maps.towns[missing[0]?.town_id]}, ${missing[0]?.towd_distr_id ? `(${maps.distrs[missing[0]?.towd_distr_id]}),` : ''} ${maps.streets[missing[0]?.street_id]}, ${t('дом')} ${missing[0]?.house} ${missing[0]?.corpus ? `, корпус ${missing[0]?.corpus}` : ''}`
                        }
                    </span>
                    <ButtonGroup size={'small'}>
                        <Button disabled={reoEmbeddedOpen} onClick={() => createReo(true)} type={'primary'}>{t('Создать')}</Button>
                        <Button disabled={reoEmbeddedOpen} onClick={() => onCancel()}>{t('Вернуться к поиску')}</Button>
                    </ButtonGroup>
                </Space>
                : null
}

export default function FoundOrNot({obj, missing, withReo, name, mode = 'single'}) {
    const {t} = useTranslation()
    const reset = useUnit(resetSelectionEv)

    const createMissing = useUnit(createMissingAddressesEv)

    const selectedReo = useUnit($selectedREOFromAddress)
    const createdReo = useUnit($createdREO)
    const createdAddresses = useUnit($createdMissAddresses)

    const {towns, distrs, streets} = useStoreMap($infoMaps, info => ({
        towns: info.townsMap,
        distrs: info.districtsMap,
        streets: info.streetsMap
    }))

    if (mode === 'single') {
        return <Card size={'small'}
                     // title={obj ? t(`Выбранный ${getTitle(name)}`) : t(`Выбранный ${getTitle(name)} не найден`)}
                     title={t(`Выбранный ${getTitle(name)}`)}
        >
            {withReo
                ? <AddressREO selReo={selectedReo} createdReo={createdReo} maps={{towns, distrs, streets}} onCancel={reset} address={createdAddresses[0] ?? obj} missing={missing}/>
                : <AddressOnly obj={createdAddresses[0] ?? obj} missing={missing} maps={{towns, distrs, streets}} onCancel={reset}/>
            }
        </Card>
    } else if (mode === 'multi') {
        return <Card size={'small'}
              title={t(`Отсутствующие записи`)}
        >
            <Space direction={'vertical'}>
                {obj.map(o => <span>{`${towns[o.town_id] ?? '-'}, ${distrs[o.town_distr_id] ?? '-'}, ${streets[o.street_id] ?? '-'}, ${o.house ?? '-'}, ${o.corpus ?? '-'}`}</span>)}
                <ButtonGroup size={'small'}>
                    <Button type={'primary'} onClick={() => createMissing()}>{t('Создать')}</Button>
                    <Button onClick={() => reset()}>{t('Вернуться к поиску')}</Button>
                </ButtonGroup>
            </Space>
        </Card>
    }
}