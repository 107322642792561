import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import {
    $selectedSector,
    getSectorByIdFx,
    SectorViewGate
} from "../../../models/dictionarySectorModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {
    $selectedPasswordSettings,
    getPasswordSettingsByIdFx,
    PasswordSettingsViewGate
} from "../../../models/dictionaryPasswordSettingsModel/index.js";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import React from "react";

export default function ViewPasswordSettings() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getPasswordSettingsByIdFx.pending)
    const data = useUnit($selectedPasswordSettings)
    const genitive_form = t(`breadcrumbs.genitive.password-settings`);

    console.log('data', data)


    const items = [
        {
            key: 'name_ru',
            label: t('Название'),
            children: data?.name_ru ? data?.name_ru : '-',
        },
        {
            key: 'setting',
            label: t('Параметр'),
            children: data?.setting ? data?.setting : '-',
        },
        {
            key: 'value',
            label: t('Значение'),
            children: data?.params?.value
        },
        {
            key: 'active',
            label: t('Статус'),
            children: data?.active ?  <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
        },
    ]



    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <PasswordSettingsViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр параметра пароля')}</span> <MappedDictItem id={id} type={'password-settings'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'password-settings'}/>
        </Card>
    </>
}
