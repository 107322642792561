import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';

import {
    $selectedInspectors,
    getInspectorsByIdFx,
    InspectorsViewGate
} from "../../../models/dictionaryInspectorsModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import SectorsTable from "../../../components/Embedded/SectorsTable/SectorsTable.jsx";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion.jsx";

export default function ViewInspectors() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getInspectorsByIdFx.pending)
    const data = useUnit($selectedInspectors)
    const genitive_form = t(`breadcrumbs.genitive.inspectors`);
    console.log('data', data)
    const items = [
        {
            key: 'town_id',
            label: t('Населенный пункт'),
            children: <MappedDictItem id={data?.town_id} type={'town'}/>,
        },
        // {
        //     key: 'name_kz',
        //     label: t(`ФИО ${genitive_form} (каз)`),
        //     children: data?.name_kz
        // },
        {
            key: 'name_ru',
            label: t(`ФИО ${genitive_form}`),
            children: data?.name_ru
        },
        {
            key: 'chief_inspector_id',
            label: t(`Старший контролер`),
            children: <MappedDictItem id={data?.chief_inspector_id} type={'chief-inspector'}/>,
        },
        {
            key: 'sector_ids',
            label: t(`Участок`),
            children: <CustomAccordion ids={(data?.sector_ids || []).length > 0 ? [data?.sector_ids[0]] : []} count={data?.sector_count} type={'sector'}/>
        },

    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <InspectorsViewGate id={id} />
        <Card
            title={<div><span>{t('Просмотр Контролера')}</span> <MappedDictItem id={id} type={'inspectors'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'inspectors'}/>
        </Card>
        {/*<Card title={<div><span>{t('Участки')}</span> </div>}>*/}
        {/*    <SectorsTable*/}
        {/*        view*/}
        {/*        parent={'inspector_id'}*/}
        {/*        parentId={+id}*/}
        {/*    />*/}

        {/*</Card>*/}
    </>
}
