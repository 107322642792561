import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {
	$deductionData,
	$paymentElementsSt,
	exportAppliesTableEv, exportDeductionDayTableEv,
} from "../../../models/paymentModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import Button from "antd/es/button/index.js";
import ExcelOutlined from "../../../components/ExcelIcon/EcxelIcon.jsx";
import Col from "antd/es/col/index.js";
import Table from "antd/es/table";
import Card from "antd/es/card";

export default function PaymentTableDeductDay({payload}) {
	const {t} = useTranslation()
	const {data, count} = useUnit($deductionData)


	const {search} = searchAndActions()
	const exportXls = useUnit(exportDeductionDayTableEv)



	const columns = [
		{
			title: t('Дата удержания'),
			dataIndex: 'bank_day',
			key: 'bank_day',
			render: (date) => date ? dayjs(date).format('DD.MM.YY') : '-',
		},
		{
			title: t('Отделение банка'),
			dataIndex: 'bank_id',
			key: 'bank_id',
			render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>
		},

		{
			title: t('Поставщик'),
			dataIndex: 'provider_id',
			key: 'provider_id',
			render: (provider_id) => <MappedDictItem id={provider_id} type={'providers'}/>
		},
		{
			title: t('Услуга'),
			dataIndex: 'service_id',
			key: 'service_id',
			render: (service_id) => <MappedDictItem id={service_id} type={'services'}/>
		},

		{
			title: 'Сумма',
			children: [
				{
					title: 'Всего',
					dataIndex: 'service_amount',
					key: 'service_amount',
					render: (v) => <FormatNumber value={v} type={2}/>
				},
				{
					title: 'ЕРЦ',
					dataIndex: 'deduct_amount',
					key: 'deduct_amount',
					render: (v) => <FormatNumber value={v} type={2}/>
				},
				{
					title: 'Поставщик',
					dataIndex: 'number',
					key: 'number',
					render: (v, row) => <FormatNumber value={row.service_amount - row.deduct_amount} type={2}/>
				},
			],
		},

	]

	return (
		<>
			<Card>

			<Col>
				<Button
					onClick={() => exportXls(payload)}
					disabled={!data.length > 0}
					size={'small'}
				>
					<ExcelOutlined size={18}/>
					Экспорт
				</Button>
			</Col>
			<Table
				dataSource={data}
				columns={columns}
				size={'small'}
				showActions={false}
			/>
			</Card>

		</>

	);
}
