import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useNavigate, useParams} from 'react-router-dom';
import StickyBox from 'react-sticky-box'
import {
    $afterEditAction,
    $embeddedBaForm,
    $provAgreementsFiles, $providerDefaultBankAccounts,
    $providerEditStatus, $selectedProvider,
    getProviderByIdFx,
    ProviderEditGate, resetAfterEditAttrs, setAfterEditActionEv, setBaEmbeddedFormEv
} from '../../../models/dictionaryProvidersModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import Tabs from 'antd/es/tabs';
import ProviderMainForm from './tabComponents/ProviderMainForm.jsx';
import ProvServForm from './tabComponents/ProvServForm2.jsx';
import ProvServAccForm from './tabComponents/ProvServAccForm.jsx';
import ProvServAccTariffForm from './tabComponents/ProvServAccTariffForm.jsx';
import ProvServREOForm from './tabComponents/ProvServREOForm.jsx';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import Skeleton from 'antd/es/skeleton';
import Tooltip from 'antd/es/tooltip';
import {HomeOutlined} from "@ant-design/icons";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryChiefInspectorModel/index.js";
import {$createdCorrectionId, createCorrectionEv} from "../../../models/dictionaryCorrectionModel/index.js";
import ProviderKassaForm from "./tabComponents/ProviderKassaForm.jsx";
import {$userPerms} from "../../../models/infoModel/index.js";
import permitted from "../../../utils/permitted.js";

export default function EditProvider() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id} = useParams()

    const selectedProvider = useUnit($selectedProvider)
    const perms = useUnit($userPerms)

    const [providerForm] = Form.useForm()
    const formBeneficiary = Form.useWatch('is_beneficiary', providerForm)
    const loading = useUnit(getProviderByIdFx.pending)
    const editLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($providerEditStatus)
    const filesToUpload = useUnit($provAgreementsFiles)

    const defaultBa = useUnit($providerDefaultBankAccounts)
    const embeddedOpen = useUnit($embeddedBaForm)
    const setEmbeddedBA = useUnit(setBaEmbeddedFormEv)

    const createCorrection = useUnit(createCorrectionEv)
    const createdCorrectionId = useUnit($createdCorrectionId)



    const action = useUnit($afterEditAction)
    const setAction = useUnit(setAfterEditActionEv)
    const resetAttrs = useUnit(resetAfterEditAttrs)

    const [activeTab, setActiveTab] = useState('main')

    useEffect(() => {
        if (status && filesToUpload.length === 0) {
            if (action === 'list') {
                providerForm.resetFields()
                navigate(`/dictionaries/providers?code_name=${selectedProvider.id}`)
            } else if (action === 'correction') {
                if (createdCorrectionId) {
                    navigate(`/corrections/edit/${createdCorrectionId}`)
                }
            }
            resetAttrs()
        }
    }, [status, action, filesToUpload]);

    const tabs = [
        {
            key: 'main',
            label: t('Основная информация'),
            disabled: !permitted(['provider::change'], perms), // FIXME provider_main?
            children: <ProviderMainForm form={providerForm} edit={true}/>
        },
        {
            key: 'provServ',
            label: t('Услуги и Тарифы'),
            disabled: selectedProvider?.is_beneficiary || formBeneficiary || !permitted(
                ['provider_service::change', 'provider_service::view']
                , perms),
            children: <ProvServForm />
        },
        {
            key: 'provKassa',
            label: t('Кассы'),
            disabled: selectedProvider?.is_beneficiary || !permitted(['provider_kassa::change'], perms),
            children: <ProviderKassaForm id={id}/>
        },
        {
            key: 'provServReo',
            disabled: selectedProvider?.is_beneficiary || formBeneficiary || !permitted(['real_estate_object_provider_service::view'], perms),
            label: <HomeOutlined />,
            children: <ProvServREOForm />
        },
    ]

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={24} style={{zIndex: 11}}>
            <DefaultTabBar
                {...props}
                style={{
                    background: '#f5f5f5',
                    borderBottom: '1px solid #ffffff',
                    padding: '0 32px',
                    margin: '0 -32px 8px',
                }}
            />
        </StickyBox>
    )

    return <>
        {status === false && <ProviderEditGate id={+id}/>}
        {loading
            ? <Skeleton />
            : <Tabs defaultActiveKey="main" activeKey={activeTab} items={tabs} type={'card'}
                    onChange={(tabKey) => {
                        if (embeddedOpen) {
                            setEmbeddedBA(false)
                        }
                        setActiveTab(tabKey)
                    }}
                    renderTabBar={renderTabBar}
        />}
        <Divider />
        <Row>
            <Space>
                <Button danger onClick={() => {
                    providerForm.resetFields()
                    navigate('/dictionaries/providers')
                }}
                >
                    {t('Отмена')}
                </Button>
                <Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={editLoading}
                            onClick={() => {
                                setAction('edit')
                                providerForm.submit()
                            }}
                            type={'primary'}
                            disabled={!defaultBa}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                </Tooltip>
                <Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={editLoading}
                            onClick={() => {
                                setAction('list')
                                providerForm.submit()
                            }}
                            disabled={!defaultBa}
                    >
                        {t('Сохранить и перейти к списку')}
                    </Button>
                </Tooltip>
                {/*<Tooltip title={!defaultBa ? t('Отсутствует расчетный счет по умолчанию') : null}>*/}
                {/*    <Button loading={editLoading}*/}
                {/*            type={'primary'}*/}
                {/*            onClick={() => {*/}
                {/*                setAction('correction')*/}
                {/*                providerForm.submit()*/}
                {/*                createCorrection({*/}
                {/*                    mass: true,*/}
                {/*                    provider_id: id*/}
                {/*                })*/}
                {/*            }}*/}
                {/*            disabled={!defaultBa}*/}
                {/*    >*/}
                {/*        {t('Сохранить и создать корректировку')}*/}
                {/*    </Button>*/}
                {/*</Tooltip>*/}
            </Space>
        </Row>
    </>
}
