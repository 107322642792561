import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Col from 'antd/es/col'
import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {useStoreMap} from "effector-react";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import DatePicker from "antd/es/date-picker";
import Switch from "antd/es/switch/index.js";

export default function ReportComponentBankAndProviderAndDate({reportForm}) {
    const {t} = useTranslation()
    const banksOpt = useStoreMap($dictsSelects, sel => sel.bank).filter(item => item.is_contract_signed === true)
    const providerOpt = useStoreMap($dictsSelects, sel => sel.providers)
    const allProvider = Form.useWatch('all_provider_id', reportForm)
    const allBank = Form.useWatch('all_bank_id', reportForm)
    return <>
        <Col span={3}>
            <Form.Item name={'all_bank_id'}
                       label={t('Все банки')}
                       rules={[{required: false, message: t('Обязательное поле')}]}
            >
                <Switch />
            </Form.Item>
        </Col>
        <Col span={4}>
                <Form.Item name={'bank_id'}
                           label={t('Банк')}
                           rules={[{required: allBank === true ? false : true, message: t('Обязательное поле')}]}
                >
                    <Select
                        options={banksOpt}
                        showSearch
                        mode="multiple"
                        disabled={allBank}
                        filterOption={(input, option) =>
                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                        }
                    />
                </Form.Item>
        </Col>
        <Col span={3}>
            <Form.Item name={'all_provider_id'}
                       label={t('Все поставщики')}
                       rules={[{required: false, message: t('Обязательное поле')}]}
            >
                <Switch />
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name={'provider_id'}
                       label={t('Поставщик')}
                       rules={[{required: allProvider === true ? false : true, message: t('Обязательное поле')}]}
            >
                <Select
                    options={providerOpt}
                    showSearch
                    mode="multiple"
                    disabled={allProvider}
                    filterOption={(input, option) =>
                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                    }
                />
            </Form.Item>
        </Col>
        <Col span={4}>
            <Form.Item name={'report_date'}
                       label={t('Дата')}
                       rules={[{required: false, message: t('Обязательное поле')}]}
            >
                <DatePicker
                    style={{width: '100%'}}
                    defaultValue={dayjs().subtract(1, 'day')}
                />
            </Form.Item>
        </Col>
    </>
}
