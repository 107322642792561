import {
    $afterCreateAction,
    $createdTaxBranchId,
    $editTaxBranchStatus,
    $selectedTaxBranch,
    $taxBranchesList
} from './stores.js';
import {getTaxBranchByIdFx, getTaxBranchesListFx} from './effects.js';
import {
    deleteTaxBranchEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    TaxBranchEditGate,
    TaxBranchesListGate,
    TaxBranchViewGate, submitCreateTaxBranchEv, submitEditTaxBranchEv, TaxBranchCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createTaxBranchCompleteEv = combineEvents({events: {ev: submitCreateTaxBranchEv, response: triggerSuccessOpsEv}, reset: TaxBranchCreateGate.state})
const editTaxBranchCompleteEv = combineEvents({ev: submitEditTaxBranchEv, response: triggerSuccessOpsEv})
const deleteTaxBranchCompleteEv = combineEvents({ev: deleteTaxBranchEv, response: triggerSuccessOpsEv})

$taxBranchesList.on(getTaxBranchesListFx.doneData, (_, taxBranches) => taxBranches)
    .reset(TaxBranchesListGate.close)

$selectedTaxBranch.on(getTaxBranchByIdFx.doneData, (_, taxBranch) => taxBranch)
    .reset([TaxBranchEditGate.close, TaxBranchViewGate.close])

$createdTaxBranchId.on(createTaxBranchCompleteEv, (_, {response}) => {
    const tax_branch = response.find(i => i.type === 'tax_branch')
    return tax_branch ? tax_branch?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editTaxBranchStatus.on(editTaxBranchCompleteEv, () => true)
    .reset(TaxBranchEditGate.close)

sample({
    clock: [TaxBranchEditGate.state, TaxBranchViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getTaxBranchByIdFx
})

sample({
    clock: TaxBranchesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getTaxBranchesListFx
})

sample({
    source: TaxBranchesListGate.state,
    clock: [createTaxBranchCompleteEv, editTaxBranchCompleteEv, deleteTaxBranchCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getTaxBranchesListFx
})

sample({
    clock: submitCreateTaxBranchEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.town_id < 0) {
            actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            payload.town_id = idCounter
            idCounter--
            delete payload.town
        }

        if (payload.town_distr_id < 0) {
            actions.push({operation: 'create', type: 'town_distr', value: {...payload.district, town_id: payload.town_id, id: idCounter}})
            payload.town_distr_id = idCounter
            idCounter--
            delete payload.district
        }

        actions.push({operation: 'create', type: 'tax_branch', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedTaxBranch,
    clock: submitEditTaxBranchEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.town_id < 0) {
            actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            payload.town_id = idCounter
            idCounter--
            delete payload.street
        }

        if (payload.town_distr_id < 0) {
            actions.push({operation: 'create', type: 'town_distr', value: {...payload.district, town_id: payload.town_id, id: idCounter}})
            payload.town_distr_id = idCounter
            idCounter--
            delete payload.district
        }

        if (payload.town_distr_id === undefined) payload.town_distr_id = null

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'tax_branch', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteTaxBranchEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'tax_branch', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
