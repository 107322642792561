import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $editRealEstateObjectTypeStatus,
    $selectedRealEstateObjectType, getRealEstateObjectTypeByIdFx,
    RealEstateObjectTypeEditGate, submitEditRealEstateObjectTypeEv,

} from '../../../models/dictionaryRealEstateObjectTypeModel/index.js';
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryRealEstateObjectTypeModel/index.js";

export default function EditRealEstateObjectType() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selectedData = useUnit($selectedRealEstateObjectType)

    const [realEstateObjectTypeForm] = Form.useForm()

    const submit = useUnit(submitEditRealEstateObjectTypeEv)

    const loading = useUnit(getRealEstateObjectTypeByIdFx.pending)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editRealEstateObjectTypeStatus)
    const genitive_form = t(`breadcrumbs.genitive.real-estate-object-type`);

    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/real-estate-object-type?code_name=${selectedData.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selectedData) {
            realEstateObjectTypeForm.setFieldsValue(selectedData)
        }
    }, [selectedData]);

    const [check, changedFields] = useFormChanged(selectedData, ['name_kz', 'name_ru'])

    return <>
        <RealEstateObjectTypeEditGate id={id}/>
        <Form form={realEstateObjectTypeForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Card loading={loading} title={t('Свойства типа объекта недвижимости')} size={'small'}>
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    realEstateObjectTypeForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            realEstateObjectTypeForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            realEstateObjectTypeForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
