import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import {$bankApiAddressList, BankApiAddressViewGate} from "../../../models/dictionaryBankApiAddressModel/index.js";
import {
    $selectedBankApiInfo,
    BankApiInfoViewGate,
    getBankApiInfoByIdFx
} from "../../../models/dictionaryBankApiInfoModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {$infoMaps} from "../../../models/infoModel/index.js";
import IpAddressesTable from "../../../components/Embedded/IpAddressTable/IpAddressesTable.jsx";

export default function ViewBankApiInfo() {
    const {t} = useTranslation();
    const {id} = useParams()
    const {bank_ip, count} = useUnit($bankApiAddressList)

    const loading = useUnit(getBankApiInfoByIdFx.pending)
    const data = useUnit($selectedBankApiInfo)
    const {bank} = useStoreMap($infoMaps, sel => ({
        bank: sel.bankMap,
    }))

    console.log('data selected',data)


    const items = [
        {
            key: 'bank_id',
            label: t('Банк'),
            children: bank[data?.bank_id] ? bank[data?.bank_id] : data?.bank_id
        },
        {
            key: 'code',
            label: t('Аббревиатура'),
            children: data?.code
        },
    ]


    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <BankApiInfoViewGate id={id}/>

        <Card
            title={<div><span>{t('Просмотр Настройки для API с банком')}</span> <MappedDictItem id={id} type={'bank-api-info'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'bank-api-info'}/>
        </Card>
        <Card title={<div><span>{t('IP Адреса')}</span> </div>}>
            <IpAddressesTable view parent={'bank_id'} parentId={data?.bank_id}/>
        </Card>
    </>
}
