import {$info, $onSelectFilter, $passwordModal, $passwordModalData, $passwordModalErrorData} from './stores.js';
import {
    closePasswordModalEv,
    dataPasswordModalEv,
    getInfoEv,
    onSelectFilterEv,
    openPasswordModalEv, signalAccrualCalcDoneEv,
    submitChangePasswordEv
} from './events.js';
import {changePasswordFx, getInfoFx} from './effects.js';
import {AppGate} from './events.js';
import {sample} from 'effector';
import {universalDictEndpointFx} from '../dictionaryUniversalModel/index.js';
import {WsOpen} from '../webSocketModel/index.js';
import notification from 'antd/es/notification/index.js';
import i18n from '../../i18n.js';

// $info.on(getInfoFx.doneData, (_, data) => data)
$info.on(getInfoFx.doneData, (state, data) => {
    // debugger
    return data
})
    .reset(AppGate.close)

$onSelectFilter
    .on(onSelectFilterEv, (_, payload) => (payload))

$passwordModal
    .on(openPasswordModalEv, () => true)
    .on(closePasswordModalEv, () => false)
    .reset(closePasswordModalEv)

$passwordModal
    .on($passwordModalData, (_, payload) => payload)
    .reset(closePasswordModalEv)

$passwordModalErrorData
    .on(changePasswordFx.failData, (_, payload) => {
        if (payload?.response?.status === 403) {
            return ['Старый пароль не верен']
        } else if (payload?.response?.status === 422) {
            return payload?.response?.data?.detail?.map(i => Object.values(i)[0]) ?? []
        }
    })
    .reset(closePasswordModalEv)


sample({
    clock: [universalDictEndpointFx.doneData, WsOpen, getInfoEv, changePasswordFx.doneData],
    filter: (data) => {
        return !(Array.isArray(data) && data?.[0]?.type === 'correction');
    },
    target: getInfoFx
})





sample({
    clock: submitChangePasswordEv,
    target: changePasswordFx
})

sample({
    clock: changePasswordFx.doneData,
    target: closePasswordModalEv
})

signalAccrualCalcDoneEv.watch(() => notification.warning({message: i18n.t('Перерасчет завершен'), description: i18n.t('Перерасчет начислений успешно произведен.')}))
