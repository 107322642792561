import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $bankAccountsByProvider,
    $providerServices,
} from '../../../../models/dictionaryProvidersModel/index.js';
import Table from 'antd/es/table';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {
    CheckOutlined,
    EyeOutlined,
    MinusOutlined
} from '@ant-design/icons';
import {$info} from "../../../../models/infoModel/index.js";
import Space from "antd/es/space";
import {Popover} from "antd";
import ServiceView from "./ServiceCreateView.jsx";

function RenderIsNot(p, f, t, current_date, title) {
    if (!p)
        return <div style={{textAlign: 'center'}}><MinusOutlined style={{color: 'gray'}}/></div>

    const data = []
    const fd = dayjs(f)
    const td = dayjs(t)
    const bf = f && fd.isSameOrBefore(current_date)
    const at = t && td.isSameOrAfter(current_date)
    const color = ((bf || !f) && (at || !t)) ? 'red' : 'gold'
    if (f) data.push(`с ${fd.format('DD.MM.YYYY')}`)
    if (t) data.push(`по ${td.format('DD.MM.YYYY')}`)
    return <div style={{textAlign: 'center'}}>
        <Popover content={data.join(' ')} title={title}>
            <CheckOutlined style={{color}}/>
        </Popover>
    </div>
}


export default function ProvServView({isView}) {
    const {t} = useTranslation()
    const providerAccs = useUnit($bankAccountsByProvider)
    const current_date = dayjs(useStoreMap($info, info => info?.current_date))
    const services = useUnit($providerServices)

    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'service_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'services'}/>,
        },
        // {
        //     title: t('Вид платежа'),
        //     dataIndex: 'service_payment_type_id',
        //     render: (id) => isId(id) ?
        //         <MappedDictItem id={id} type={'service-payment-type'}/> :
        //         <div style={{textAlign: 'center'}}><MinusOutlined/></div>,
        // },
        {
            title: t('Расчетный счет'),
            dataIndex: 'bank_account_id',
            render: id => {
                const acc = providerAccs.find(x => x.id === id)
                let data
                if (!acc)
                    data = <div style={{textAlign: 'center'}}><MinusOutlined/></div>
                else {
                    data = <div>
                        <Popover
                            title={t('Расчетный счет') + ' ' + acc.account_number}
                            content={[
                                <MappedDictItem id={acc.bank_id} type={'banks'} link={false}
                                                withID={false}
                                                wrap={false}/>,
                                <br/>,
                                <MappedDictItem id={acc.bank_account_type_id}
                                                type={'bank-account-type'}
                                                link={false} withID={false} wrap={false}/>
                            ]}
                        >
                            {acc.account_number}
                        </Popover>
                    </div>
                }
                return <div>{data}</div>
            }
        },
        {
            title: t('Оплач. услуга'),
            dataIndex: 'is_paid_service',
            render: (val) => <div style={{textAlign: 'center'}}>
                {val ? <CheckOutlined/> : <MinusOutlined/>}
            </div>,
        },
        // {
        //     title: t('Алгоритм'),
        //     dataIndex: 'algorithm_id',
        //     render: (val) => isId(val) ? <MappedDictItem
        //         id={val} type={'algorithm'} link={false} withID={true} wrap={false}
        //     /> : <div style={{textAlign: 'center'}}><MinusOutlined/></div>,
        // },
        {
            title: t('Опции пени'),
            // dataIndex: 'is_penalty_priority',
            render: (val, r) => <Popover
                title={t('Опции пени')}
                content={<>
                    {r.is_penalty_charge ?
                        t('Пеня начисляется') : t('Пеня не начисляется')
                    }
                    <br/>
                    {r.is_penalty_priority ?
                        t('Пеня приоритетна') : t('Пеня не приоритетна')
                    }
                    <br/>
                    {r.penalty_percent || 0} %
                    <br/>
                    <MappedDictItem id={r.penalty_service_id} type={'services'}/>
                    {/*{t('День начисления пени')}: {r.penalty_calculation_date || 1}*/}
                </>}
            >
                <Space style={{textAlign: 'center'}}>
                    {r.is_penalty_charge ? <CheckOutlined/> : <MinusOutlined/>}
                    {r.is_penalty_charge ? <>
                        {r.is_penalty_priority ? <CheckOutlined/> : <MinusOutlined/>}
                        <span>{r.penalty_percent || 0} %</span>
                        <MappedDictItem id={r.penalty_service_id} type={'services'}/>
                    </> : ''}
                    {/*<span>{r.penalty_calculation_date || 1}-й</span>*/}
                </Space>
            </Popover>,
        },
        {
            title: t('Тарифы'),
            render: (_, r) => {
                const data = (r.tarifs || []).filter(t => !t.is_archived)
                const max = data.reduce((a, b) => a > b.value ? a : b.value, 0)
                return <div style={{textAlign: 'center'}}>
                    <Popover
                        title={t('Тарифы')}
                        content={<Row>
                            {data.map(t => <Col span={24}>
                                {t.value}
                            </Col>)}
                        </Row>}
                    >
                        {max}
                    </Popover>
                </div>
            }
        },
        {
            title: t('Оказание услуги'),
            dataIndex: 'date_from',
            render: (date, r) => {
                const data = []
                if (r.date_from) data.push(<Col span={24}>
                    с {dayjs(r.date_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.date_to) data.push(<Col span={24}>
                    по {dayjs(r.date_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><MinusOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: <Tooltip title={t('Не начислять')}>{t('Не начисл.')}</Tooltip>,
            dataIndex: 'is_not_accrued',
            render: (v, r) => {
                return RenderIsNot(
                    r.is_not_accrued,
                    r.not_accrued_from,
                    r.not_accrued_to,
                    current_date,
                    t('Не начислять'),
                )
            }
        },
        {
            title: <Tooltip title={t('Не переводить сальдо')}>{t('Не перев.')}</Tooltip>,
            dataIndex: 'is_not_balance_transfer',
            render: (v, r) => {
                return RenderIsNot(
                    r.is_not_balance_transfer,
                    r.not_balance_transfer_from,
                    r.not_balance_transfer_to,
                    current_date,
                    t('Не переводить сальдо'),
                )
            }
        },
        {
            title: <Tooltip title={t('Не печатать')}>{t('Не печ.')}</Tooltip>,
            dataIndex: 'is_not_printed',
            render: (v, r) => {
                return RenderIsNot(
                    r.is_not_printed,
                    r.not_printed_from,
                    r.not_printed_to,
                    current_date,
                    t('Не печатать'),
                )
            }
        },
        {
            title: null,
            dataIndex: 'id',
            render: (id) => {
                return <Button icon={<EyeOutlined/>} onClick={() => view(id)}/>
            }
        },
    ]

    const view = (id) => {
        console.log('view', id)
    }

    console.log('View PS:', services)
    return <Table dataSource={services}
               columns={columns}
               bordered
               rowKey={'service_id'}
               className={'table-container'}
               pagination={false}
               size={'small'}
               expandable={{
                   expandedRowRender: (r) => <ServiceView key={r.id} service={r}/>,
                   expandRowByClick: true,
               }}
        />
}
