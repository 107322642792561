import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import sortByUser from '../../utils/sortByUser.js';
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {
    $sectorList, deleteSectorEv,
    getSectorListFx,
    SectorListGate
} from "../../models/dictionarySectorModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {$paymentDeductionList, PaymentDeductionListGate} from "../../models/paymentDeductionModel/index.js";
import FormatNumber from "../../components/FormatNumber/FormatNumber.jsx";
import React from "react";

export default function PaymentDeductionList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($paymentDeductionList)
    const loading = useUnit(getSectorListFx.pending)
    const onDelete = useUnit(deleteSectorEv)
    // const [search] = useSearchParams()
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.sector`)


    const columns = [
        {
            width: '5%',
            title: t(`Код`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            sorter: true,
            key: 'provider_id',
            render: (provider_id, record) => <MappedDictItem id={provider_id} type={'providers'} />
        },
        {
            title: t('Сумма'),
            dataIndex: 'amount',
            sorter: true,
            key: 'amount',
            render: (value) => <FormatNumber value={value} type={2}/>
        },
        {
            title: t('Сумма к удержанию'),
            dataIndex: 'amount_left',
            sorter: true,
            key: 'amount_left',
            render: (value) => <FormatNumber value={value} type={2}/>
        },
        {
            title: t('Остаток'),
            dataIndex: 'amount',
            sorter: true,
            key: 'amount',
            render: (value, row) => <FormatNumber value={value - row.amount_left} type={2}/>
        },
        // {
        //     title: t(`Название ${genitive_form} (каз)`),
        //     dataIndex: 'name_kz',
        //     sorter: true,
        //     key: 'name_kz',
        //     render: (name, record) => <Link to={`/dictionaries/sector/view/${record.id}`}>{`${name}`}</Link>
        // },
        // {
        //     title: t(`Название ${genitive_form} (рус)`),
        //     dataIndex: 'name_ru',
        //     sorter: true,
        //     key: 'name_ru',
        //     render: (name, record) => <Link to={`/dictionaries/sector/view/${record.id}`}>{`${name  }`}</Link>
        // },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/sector/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
                </Tooltip>
                <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/sector/view/${id}`}><Button icon={<EyeOutlined />} /></Link>
                </Tooltip>
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => onDelete(id)}
                            placement={'left'}
                >
                <Tooltip title={t('Удалить')} placement={'top'}>
                    <Button icon={<DeleteOutlined />} danger/>
                </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код'), type: 'input'},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns'},
        // {name: 'sector', label: t('Участок'), type: 'select', dict: 'sector'},
        {name: 'inspector_id', label: t('Контролер'), type: 'select', dict: 'inspector', multiple: true},
        // {name: 'street_type_id', label: t('Тип'), type: 'select', dict: 'streetTypes', multiple: true},
        // {name: 'name', label: t('Название'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <PaymentDeductionListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />
    </>
}
