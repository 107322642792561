import {$chosenClosedMonth, ViewCloseMonthGate} from "../../../models/closeMonthModel/index.js";
import {useParams} from "react-router-dom";
import {Card} from "antd";
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";

export default function ViewCloseMonth(){
	const {id} = useParams()
	const {t} = useTranslation()
	const chosenClosedMonth = useUnit($chosenClosedMonth)

	return <>
		<ViewCloseMonthGate id={id}/>
		<Card
			title={<div><span>{t('Логи перехода')}</span> </div>}
			loading={false}
		>
			<div>{chosenClosedMonth?.logs}</div>
		</Card>
	</>
}
