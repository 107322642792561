import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const AdminServicesListGate = createGate()

export const editServicesOptionEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()

export const setServiceTypeEmbedded = createEvent()
