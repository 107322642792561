import {memo} from 'react';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';

const TableExt = memo(function ({data, count, columns, loading, onChangeSelect, selected}) {
    return <DictionaryProTable
        data={data}
        count={count}
        initColumns={columns}
        loading={loading}
        showActions={false}
        rowKey={'id'}
        rowSelection={{
            onChange: onChangeSelect,
            preserveSelectedRowKeys: true,
            selectedRowKeys: selected
        }}
    />
})

export default TableExt