import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ServicesCreateGate,
    submitCreateServicesEv,
    $createdServicesId
} from '../../../models/dictionaryServicesModel';
import {$dictsSelects, $penSrvsInUse} from '../../../models/infoModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';
import {filterOption, optionRender} from '../../../utils/selectUtils.jsx';
import CreatePenSrvEmbedded from './CreatePenSrvEmbedded.jsx';
import CreatePayTypeEmbedded from './CreatePayTypeEmbedded.jsx';


export default function CreateServices() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [servicesForm] = Form.useForm()
    const nRu = Form.useWatch('name_ru', servicesForm)
    const nKz = Form.useWatch('name_kz', servicesForm)
    const snRu = Form.useWatch('short_name_ru', servicesForm)
    const snKz = Form.useWatch('short_name_kz', servicesForm)
    const isPenalty = Form.useWatch('is_penalty', servicesForm)
    const selectedPayType = Form.useWatch('service_payment_type_id', servicesForm)
    const selectedPenSrv = Form.useWatch('penalty_service_id', servicesForm)

    const [paymentEmbedded, setPaymentEmbedded] = useState(false)
    const [penSrvEmbedded, setPenSrvEmbedded] = useState(false)

    useEffect(() => {
        if (!paymentEmbedded && selectedPayType === -1) {
            setPaymentEmbedded(true)
        } else if (paymentEmbedded && selectedPayType !== -1) {
            setPaymentEmbedded(false)
            servicesForm.resetFields(['new_payment_type'])
        }
    }, [selectedPayType, paymentEmbedded]);

    useEffect(() => {
        if (!penSrvEmbedded && selectedPenSrv === -1) {
            setPenSrvEmbedded(true)
        } else if (penSrvEmbedded && selectedPenSrv !== -1) {
            setPenSrvEmbedded(false)
            servicesForm.resetFields(['new_pen_srv'])
        }
    }, [selectedPenSrv, penSrvEmbedded]);

    useEffect(() => {
        if (isPenalty && penSrvEmbedded) {
            setPenSrvEmbedded(false)
            servicesForm.resetFields(['new_pen_srv'])
            servicesForm.resetFields(['penalty_service_id'])
        }
    }, [isPenalty, penSrvEmbedded])

    const submit = useUnit(submitCreateServicesEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const penSrvUsed = useUnit($penSrvsInUse)
    const {
        templateOpts,
        srvPayTypeOpts,
        srvServiceClassOpts,
        penSrvOpts
    } = useStoreMap($dictsSelects, sel => ({
        templateOpts: sel.template,
        srvPayTypeOpts: [...sel.servicePaymentType, {label: t('Создать'), emoji: true, value: -1}],
        srvServiceClassOpts: sel.serviceClasses,
        penSrvOpts: [...sel.services.filter(s => s.is_penalty === true && !penSrvUsed.includes(s.value)), {label: t('Создать'), emoji: true, value: -1}]
    }))


    const createdId = useUnit($createdServicesId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.services`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/services/edit/${createdId}`)
            } else if (action === 'new') {
                servicesForm.resetFields()
            } else if (action === 'list') {
                navigate(`/dictionaries/services?code_name=${createdId}`)
                servicesForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);

    return <>
        <ServicesCreateGate/>
        <Form form={servicesForm} layout={'vertical'} onFinish={submit} size={'small'}>
            <Row justify={'space-between'} gutter={[16, 16]}>
                <Col span={24}>
                    <Card title={t('Основные свойства услуги')} size={'small'}
                          style={{height: '100%'}}>
                        <Row gutter={[8, 0]}>
                            <Col span={12}>
                                <Row gutter={[8, 0]}>
                                    <Col span={12}>
                                        <Form.Item name={'name_ru'}
                                                   label={t(`Название ${genitive_form} (рус)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={'short_name_ru'}
                                                   label={t(`Крат. Название (рус)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row gutter={[8, 0]}>
                                    <Col span={12}>
                                        <Form.Item name={'name_kz'}
                                                   label={t(`Название ${genitive_form} (каз)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={'short_name_kz'}
                                                   label={t(`Крат. Название (каз)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title={<StarredTitle title={t('Дополнительные свойства')}/>}
                          size={'small'} style={{height: '100%'}}>
                        <Row gutter={[8, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    name={'service_payment_type_id'}
                                    label={t('Вид платежа')}
                                    rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={srvPayTypeOpts}
                                        filterOption={filterOption}
                                        optionRender={optionRender}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={'service_class_id'}
                                    label={t('Класс услуг')}
                                    rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={srvServiceClassOpts}
                                        filterOption={filterOption}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'template_id'}
                                           label={t('Шаблон')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Select options={templateOpts} filterOption={filterOption} showSearch allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'epd_order'} label={t('Порядок ЕПД')}>
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'is_meter'} label={t('Услуга по счетчику')} valuePropName={'checked'}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'is_penalty'} label={t('Является пеневой')} valuePropName={'checked'}>
                                    <Switch />
                                </Form.Item>
                            </Col>
                            {!isPenalty && <Col span={6}>
                                <Form.Item name={'penalty_service_id'} label={t('Пеневая услуга')}>
                                    <Select options={penSrvOpts} filterOption={filterOption} optionRender={optionRender} showSearch allowClear/>
                                </Form.Item>
                            </Col>}
                        </Row>
                        {paymentEmbedded && <CreatePayTypeEmbedded
                                form={servicesForm}
                                open={paymentEmbedded}
                                namespace={['new_payment_type']}
                                prefixRu={nRu}
                                prefixKz={nKz}
                                shortPrefixRu={snRu}
                                shortPrefixKz={snKz}
                            />}
                        {penSrvEmbedded && <>
                        {paymentEmbedded && <Divider orientation={'left'} orientationMargin={0}/>}
                            <CreatePenSrvEmbedded
                            form={servicesForm}
                            open={penSrvEmbedded}
                            namespace={'new_pen_srv'}
                            prefixRu={nRu}
                            prefixKz={nKz}
                            shortPrefixRu={snRu}
                            shortPrefixKz={snKz}
                        /></>}
                    </Card>
                </Col>

            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    servicesForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            servicesForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            servicesForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            servicesForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и перейти к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
