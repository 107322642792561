import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {
    $selectedStreetType,
    StreetTypeViewGate,
    getStreetTypeByIdFx
} from '../../../models/dictionaryStreetTypesModel/index.js';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import dayjs from 'dayjs';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";

export default function ViewStreetType() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getStreetTypeByIdFx.pending)
    const stType = useUnit($selectedStreetType)
    const genitive_form = t(`breadcrumbs.genitive.street_type`)

    const items = [
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: stType?.name_kz
        },
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: stType?.name_ru
        },
        {
            key: 'short_name_kz',
            label: t(`Краткое название ${genitive_form} (каз)`),
            children: stType?.short_name_kz
        },
        {
            key: 'short_name_ru',
            label: t(`Краткое название ${genitive_form} (рус)`),
            children: stType?.short_name_ru
        }
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={stType?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={stType?.created_at} />
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: stType?.updated_by ? <CreatedBy id={stType?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: stType?.updated_at ? dayjs(stType?.updated_at).format('DD.MM.YYYY HH:mm:ss') : '-'
        }
    ]

    return <>
        <StreetTypeViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр типа улицы')}</span> <MappedDictItem id={id} type={'street_type'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'street_type'}/>
        </Card>
    </>
}
