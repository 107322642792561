import {useTranslation} from "react-i18next";
import {useState, useEffect} from "react";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import GroupOutlined from "@ant-design/icons/GroupOutlined";
import ReadOutlined from "@ant-design/icons/ReadOutlined";
import FieldBinaryOutlined from "@ant-design/icons/FieldBinaryOutlined";
import Card from 'antd/es/card'
import Row from "antd/es/row";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Col from "antd/es/col";
import Select from "antd/es/select";
import Popconfirm from "antd/es/popconfirm";
import InputNumber from "antd/es/input-number";
import Radio from "antd/es/radio";
import * as math from "mathjs";
import Tooltip from "antd/es/tooltip";
import Input from "antd/es/input";
import List from "antd/es/list";

window.math = math

function Operator(props) {
    const {t} = useTranslation()
    const {id, value, onChange, onRemove} = props
    const [curVal, setCurVal] = useState(value)

    useEffect(() => {
        // console.log('Operator.effect value', id, value, curVal)
        setCurVal(value)
    }, [value])

    const removeLeft = () => onRemove(id, 'left')
    const removeRight = () => onRemove(id, 'right')

    const changeCurVal = (newVal) => {
        // console.log('Operator.changeCurVal', id, value, curVal, newVal)
        setCurVal(newVal)
        onChange(id, newVal)
    }

    // console.log('Operator', id, value)
    return <Row style={{alignItems: 'center'}}>
        <Col>
            <Select
                allowClear={false}
                options={[
                    {value: '+', label: '+'},
                    {value: '-', label: '-'},
                    {value: '*', label: '*'},
                    {value: '/', label: '/'},
                ]}
                value={curVal}
                onChange={changeCurVal}
            />
        </Col>
        <Col>
            <Popconfirm
                title={t('Удаление оператора')}
                description={t("С какой стороны удалить операнд?")}
                onConfirm={removeRight}
                onCancel={removeLeft}
                okText={t('Справа')}
                cancelText={t("Слева")}
            >
                <Button icon={<CloseOutlined/>} danger size={'small'}/>
            </Popconfirm>
        </Col>
    </Row>
}

function Constant(props) {
    const {id, value, onChange} = props
    const [curVal, setCurVal] = useState(value)

    useEffect(() => {
        // console.log('Constant.effect value', id, value, curVal)
        setCurVal(value)
    }, [value])

    const changeCurVal = (newVal) => {
        // console.log('Constant.changeCurVal', id, value, curVal, newVal)
        setCurVal(newVal)
        onChange(newVal)
    }
    // console.log('Constant', id, value, curVal)
    return <Row>
        <Col>
            <Select
                style={{minWidth: '240px'}}
                allowClear={false}
                showSearch={true}
                options={[
                    {value: 'tarif.value', label: "Тариф: Значение",},
                    {value: 'account.full_square', label: 'ЛС: Общая площадь',},
                    {value: 'account.living_square', label: 'ЛС: Жилая площадь',},
                    {value: 'account.heated_square', label: 'ЛС: Отапливаемая площадь',},
                    {value: 'account.people_quantity', label: 'ЛС: Количество проживающих',},
                    {value: 'account.room_count', label: "ЛС: Количество комнат",},
                    {value: 'account.floor', label: "ЛС: Этаж",},
                    {value: 'real_estate_object.floor_count', label: "ОН: Количество этажей",},
                    {
                        value: 'real_estate_object.apartment_count',
                        label: "ОН: Количество квартир",
                    },
                    {
                        value: 'real_estate_object.entrance_count',
                        label: "ОН: Количество подъездов"
                    },
                    {
                        value: 'account_provider_service.service_square',
                        label: "Услуга ЛС: Площадь по услуге"
                    },
                    {
                        value: 'account_provider_service.people_quantity',
                        label: "Услуга ЛС: Кол-во человек"
                    },

                ]}
                value={curVal}
                onChange={changeCurVal}
            />
        </Col>
    </Row>
}

function Number(props) {
    const {id, value, onChange} = props
    const [curVal, setCurVal] = useState(value)

    useEffect(() => {
        // console.log('Number.effect value', id, value, curVal)
        setCurVal(value)
    }, [value])

    const changeCurVal = (newVal) => {
        // console.log('Number.changeCurVal', id, value, curVal, newVal)
        setCurVal(newVal)
        onChange(newVal)
    }

    // console.log('Number', id, value, curVal)
    return <Row>
        <Col>
            <InputNumber
                decimalSeparator=","
                value={curVal}
                onChange={changeCurVal}
            />
        </Col>
    </Row>
}

function Operand(props) {
    const {id, value, onChange, type} = props
    const [curType, setCurType] = useState(type)
    const [curVal, setCurVal] = useState(value)

    const changeCurType = (newType) => {
        setCurType(newType)
        let newVal = []
        if (newType === 'number') newVal = 1.0
        if (newType === 'constant') newVal = 'tarif.value'
        setCurVal(newVal)
        onChange(id, newVal, newType)
    }
    const changeCurVal = (newVal) => {
        // console.log('Operand.changeCurVal', id, newVal)
        setCurVal(newVal)
        onChange(id, newVal, curType)
    }

    useEffect(() => {
        // console.log('Operand.effect value', id, value, curVal, type, curType)
        setCurVal(value)
    }, [value])

    useEffect(() => {
        // console.log('Operand.effect type', id, value, curVal, type, curType)
        setCurType(type)
    }, [type])

    // console.log('Operand', id, value, curVal, type, curType)
    return <Row style={{alignItems: 'center'}}>
        <Col>
            <Radio.Group
                size={'small'}
                value={curType}
                onChange={(e) => changeCurType(e.target.value)}
            >
                <Tooltip title={'Число'}>
                    <Radio.Button value="number"><FieldBinaryOutlined/></Radio.Button>
                </Tooltip>
                <Tooltip title={'Переменная'}>
                    <Radio.Button value="constant"><ReadOutlined color={'#1677ff'}/></Radio.Button>
                </Tooltip>
                <Tooltip title={'Выражение'}>
                    <Radio.Button value="expression"><GroupOutlined
                        color={'#1677ff'}/></Radio.Button>
                </Tooltip>
            </Radio.Group>
        </Col>
        <Col>
            {curType === 'number' ?
                <Number id={id} value={curVal} onChange={changeCurVal}/> :
                (
                    curType === 'constant' ?
                        <Constant id={id} value={curVal} onChange={changeCurVal}/> :
                        (
                            curType === 'expression' ? <Expression
                                id={id} value={curVal} onChange={changeCurVal}
                            /> : null
                        )
                )
            }
        </Col>
    </Row>
}

function Expression(props) {
    let {id, value, onChange} = props
    const isEmpty = (
        value === undefined ||
        value === null ||
        value === '' ||
        (typeof value === 'object' && value.length === 0)
    )
    if (isEmpty) {
        value = [
            {type: 'number', value: 1.0},
        ]
    }
    const [curVal, setCurVal] = useState(value)

    // useEffect(() => {
    //     console.log('Expression.effect value', id, value, curVal, type, curType)
    //     setCurVal(value)
    // }, [value])

    const onAdd = () => {
        console.log('Expression.onAdd', id, value, curVal)
        const newValue = [
            ...curVal,
            {type: 'operator', value: '+'},
            {type: 'number', value: 1.0},
        ]
        setCurVal(newValue)
        onChange && onChange(newValue)
    }
    const onRemove = (idx, side) => {
        console.log('Expression.onRemove', idx, side, 'opts', id, value, curVal)
        const newValue = curVal.toSpliced(
            side === 'left' ? idx - 1 : idx, 2
        )
        console.log('Expression.onRemove newVal', newValue)
        setCurVal(newValue)
        onChange && onChange(newValue)
    }
    const changeOperand = (idx, oValue, oType) => {
        console.log('Expression.changeOperand', idx, oValue, oType, 'opts', id, value, curVal)
        const newValue = curVal.toSpliced(
            idx, 1, {id: idx, value: oValue, type: oType}
        )
        console.log('Expression.changeOperand newVal', newValue)
        setCurVal(newValue)
        onChange && onChange(newValue)
    }
    const changeOperator = (idx, oValue) => {
        console.log('Expression.changeOperator', idx, oValue, 'opts', id, value, curVal)
        const newValue = curVal.toSpliced(
            idx, 1, {id: idx, value: oValue, type: 'operator'}
        )
        console.log('Expression.changeOperand newVal', newValue)
        setCurVal(newValue)
        onChange && onChange(newValue)
    }

    console.log('Expression', id, value, curVal)
    return <Card size={'small'}>
        <Row gutter={[8, 8]} style={{alignItems: 'center'}}>
            <Col><Button disabled type={'text'} style={{padding: '4px 0'}}>(</Button></Col>
            {curVal.map((v, idx) => <Col key={idx}>
                {v.type === 'operator' ?
                    <Operator
                        id={idx} value={v.value} type={v.type}
                        onChange={changeOperator}
                        onRemove={onRemove}
                    /> : <Operand
                        id={idx} value={v.value} type={v.type}
                        onChange={changeOperand}
                    />
                }
            </Col>)}
            <Col>
                <Button type="primary" onClick={onAdd} icon={<PlusOutlined/>} size={'small'}/>
            </Col>
            <Col><Button disabled type={'text'} style={{padding: '4px 0'}}>)</Button></Col>
        </Row>
    </Card>
}

function formulaStringToArray(f) {
    const compact = (v) => {
        // console.log(v)
        const op = v.op
        if (op === undefined) {
            // console.log('no op',v)
            if (v.content) {
                return [{
                    value: compact(v.content),
                    type: 'expression',
                }]
            }
            if (v.value !== undefined) return [{
                value: v.value,
                type: 'number',
            }]
            if (v.object !== undefined) {
                const name = v.object.name + '.' + v.index.dimensions.map(
                    x => x.value
                ).join('.')
                return [{
                    value: name,
                    type: 'constant',
                }]
            }
            if (v.name) return [{
                value: v.name,
                type: 'constant',
            }]
            console.log('undefined operand type', v)
            return []
        }
        return [
            ...compact(v.args[0]),
            {
                value: op,
                type: 'operator',
            },
            ...compact(v.args[1]),
        ]
    }
    return compact(math.parse(f))
}

function formulaArrayToString(a) {
    const vals = (a || []).map(
        e => e.type === 'expression' ? formulaArrayToString(e.value) : e.value
    )
    return vals.join(' ')
}

export const AlgorithmCalculator = (props) => {
    const {t} = useTranslation()
    const {
        initValue = '1',
        onConfirm,
        onCancel,
    } = props
    const [inputValue, setInputValue] = useState(() => {
        const arrValue = formulaStringToArray(initValue)
        console.log('AlgorithmCalculator.state.initValue', initValue, arrValue)
        return arrValue
    })
    const doConfirm = () => {
        const strValue = formulaArrayToString(inputValue)
        console.log('AlgorithmCalculator.doConfirm', initValue, inputValue, strValue)
        onConfirm && onConfirm(strValue)
    }
    const doCancel = () => {
        setInputValue(initValue)
        onCancel && onCancel()
    }
    const onChange = (newVal) => {
        console.log('AlgorithmCalculator.onChange', newVal)
        setInputValue(newVal)
    }

    // useEffect(() => {
    //     console.log('AlgorithmCalculator.effect ', initValue, inputValue)
    //     const arrValue = formulaStringToArray(initValue)
    //     console.log('AlgorithmCalculator.effect arr', arrValue)
    //     setInputValue(arrValue)
    // }, [initValue])

    console.log('AlgorithmCalculator', 'cur', inputValue, 'init', initValue)
    return <Card size={'small'} style={{marginTop: '8px'}}>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Expression id={'root'} value={inputValue} onChange={onChange}/>
            </Col>
            <Col span={24}>
                <Space direction={'horizontal'}>
                    <Button onClick={doConfirm} type={'primary'}>
                        {t('Применить новую формулу')}
                    </Button>
                    <Button onClick={doCancel}>
                        {t('Отменить изменения формулы')}
                    </Button>
                </Space>
            </Col>
        </Row>
    </Card>
}

const engToRus = {
    'tarif.value': "[Тариф: Значение]",
    'account.full_square': '[ЛС: Общая площадь]',
    'account.living_square': '[ЛС: Жилая площадь]',
    'account.heated_square': '[ЛС: Отапливаемая площадь]',
    'account.people_quantity': '[ЛС: Количество проживающих]',
    'account.room_count': "[ЛС: Количество комнат]",
    'account.floor': "[ЛС: Этаж]",
    'real_estate_object.floor_count': "[ОН: Количество этажей]",
    'real_estate_object.apartment_count': "[ОН: Количество квартир]",
    'real_estate_object.entrance_count': "[ОН: Количество подъездов]",
    'account_provider_service.service_square': "[Услуга ЛС: Площадь по услуге]",
    'account_provider_service.people_quantity': "[Услуга ЛС: Кол-во человек]",
}
const rusToEng = Object.fromEntries(Object.entries(engToRus).map(([k, v]) => [v, k]))

const engToKaz = {
    'tarif.value': "[Тариф: Значение]",
    'account.full_square': '[ЛС: Общая площадь]',
    'account.living_square': '[ЛС: Жилая площадь]',
    'account.heated_square': '[ЛС: Отапливаемая площадь]',
    'account.people_quantity': '[ЛС: Количество проживающих]',
    'account.room_count': "[ЛС: Количество комнат]",
    'account.floor': "[ЛС: Этаж]",
    'real_estate_object.floor_count': "[ОН: Количество этажей]",
    'real_estate_object.apartment_count': "[ОН: Количество квартир]",
    'real_estate_object.entrance_count': "[ОН: Количество подъездов]",
    'account_provider_service.service_square': "[Услуга ЛС: Площадь по услуге]",
    'account_provider_service.people_quantity': "[Услуга ЛС: Кол-во человек]",
}
const kazToEng = Object.fromEntries(Object.entries(engToKaz).map(([k, v]) => [v, k]))

function formulaStringToLang(f) {
    const lang = localStorage.getItem('lang') ?? 'ru'
    const dict = lang === 'ru' ? engToRus : engToKaz
    Object.entries(dict).map(([k, v]) => {
        f = f.replaceAll(k, v)
    })
    return f
}

function formulaLangToString(f) {
    const lang = localStorage.getItem('lang') ?? 'ru'
    const dict = lang === 'ru' ? rusToEng : kazToEng
    Object.entries(dict).map(([k, v]) => {
        f = f.replaceAll(k, v)
    })
    return f
}

function checkFormula(fi) {
    let f = fi
    if (f === '' || f === undefined || f === null) return false

    f = f.replaceAll(/ +/g, ' ').replaceAll(/^ +/g, '')
        .replaceAll(/ +$/g, '')
        .replaceAll(/[\]] /g, ']')
        .replaceAll(/ [\[]/g, '[')
        .replaceAll(/]([0-9\[])/g, ']*$1')
        .replaceAll(/([0-9\]])\[/g, '$1*[')

    f = formulaLangToString(f)
    const scope = {}
    Object.keys(engToRus).map(k => {
        const [l1, l2] = k.split('.')
        if (scope[l1] === undefined) scope[l1] = {}
        scope[l1][l2] = Math.random() * 100
    })
    try {
        const p = math.parse(f)
        p.evaluate(scope)
    } catch (e) {
        console.log('AlgorithmEditor.checkFormula', fi, f, e)
        return false
    }

    return true
}

export const AlgorithmEditor = (props) => {
    const lang = localStorage.getItem('lang') ?? 'ru'
    const dict = lang === 'ru' ? engToRus : engToKaz
    const {t} = useTranslation()
    const {
        initValue = '1',
        onConfirm,
        onCancel,
    } = props
    const [status, setStatus] = useState("")
    const [inputValue, setInputValue] = useState(() => {
        const arrValue = formulaStringToLang(initValue)
        console.log('AlgorithmEditor.state.initValue', initValue, arrValue)
        return arrValue
    })
    const doConfirm = () => {
        const strValue = formulaLangToString(inputValue)
        if (!checkFormula(inputValue)) {
            setStatus('error')
            return
        }
        console.log('AlgorithmEditor.doConfirm', initValue, inputValue, strValue)
        onConfirm && onConfirm(strValue)
    }
    const doCancel = () => {
        setInputValue(initValue)
        onCancel && onCancel()
    }
    const onChange = (newVal) => setInputValue(newVal)

    const data = Object.values(dict)
    // console.log('AlgorithmEditor', 'cur', inputValue, 'init', initValue)
    return <Card size={'small'} style={{marginTop: '8px'}}>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Input
                    value={inputValue} onChange={e => onChange(e.target.value)}
                    status={status}
                />
            </Col>
            <Col span={24}>
                <List
                    header={t('Доступные переменные. Вводить с квадратными скобками.')}
                    bordered
                    dataSource={data}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                />
            </Col>
            <Col span={24}>
                <Space direction={'horizontal'}>
                    <Button onClick={doConfirm} type={'primary'}>
                        {t('Применить новую формулу')}
                    </Button>
                    <Button onClick={doCancel}>
                        {t('Отменить изменения формулы')}
                    </Button>
                </Space>
            </Col>
        </Row>
    </Card>
}

export default {}
