import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import {
    getDistrictsListFx,
    $districtsList,
    DistrictsListGate,
    deleteDistrictEv
} from '../../models/dictionaryDistrictsModel';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import MappedDictItem from '../../components/MappedDictItem/MappedDictItem.jsx';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {$userPerms} from '../../models/infoModel/index.js';

export default function DictionaryDistrictsList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($districtsList)
    const loading = useUnit(getDistrictsListFx.pending)
    const deleteDistrict = useUnit(deleteDistrictEv)
    const perms = useUnit($userPerms)

    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.town_distr`)


    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (id) => <MappedDictItem id={id} type={'town'}/>
        },
        {
            title: t(`Название ${genitive_form} (рус)`),
            dataIndex: 'name_ru',
            sorter: true,
            key: 'name_ru',
            render: (name, record) => <Link to={`/dictionaries/town_distr/view/${record.id}`}>{name}</Link>
        },
        {
            title: t(`Название ${genitive_form} (каз)`),
            dataIndex: 'name_kz',
            sorter: true,
            key: 'name_kz',
            render: (name, record) => <Link to={`/dictionaries/town_distr/view/${record.id}`}>{name}</Link>
        },

        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    sorter: true,
                    width: '10%',
                    key: 'created_by',
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    width: '10%',
                    key: 'updated_at',
                    sorter: true,
                    render: (date, record) => record.updated_at ? <CustomDates data={date} /> : '-'
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    sorter: true,
                    width: '10%',
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                {perms?.town_distr?.update && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/town_distr/edit/${id}`}><Button icon={<EditOutlined/>}
                                                                             type={'primary'}/></Link>
                </Tooltip>}
                {perms?.town_distr?.view && <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/town_distr/view/${id}`}><Button icon={<EyeOutlined/>}/></Link>
                </Tooltip>}
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                {perms?.town_distr?.delete && <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                             okText={t('Да')}
                             onConfirm={() => deleteDistrict(id)}
                             placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>}
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns',},
        // {name: 'name', label: t('Название'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <DistrictsListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable data={data} count={count} initColumns={columns} loading={loading} exportXlsAction={exportXlsAction}/>
    </>
}
