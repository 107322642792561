import {
    $corpusSelect,
    $districtsSelect, $houseSelect,
    $addressList,
    $selectedEntities,
    $selectedAddress,
    $streetsSelect,
    $townsSelect,
    $createdAddress,
    // $needCreateAddress,
    $openCreateAddress, $viewTable, $addressPagination, $selectedRows, $addressNoLimit, $RESFormData, $generateType,
} from './stores.js';
import {
    getDistrictsSelectFx,
    getAddressFx,
    getSelectsForAddressFx,
    getTownsSelectFx, getAddressNoLimitFx
} from './effects.js';
import {
    AddressSearchGate,
    selectAddressEv,
    setAddressSelectedEv,
    searchAddressEv,
    createAddressEv,
    openCreateAddressEv,
    cancelSelectedEv,
    setAddressPaginationEv,
    selectRowEv,
    getAddressNoLimitEv, setRESFormDataEv, onDeleteRowNoLimitEv, setGenetrateTypeEv, resetSelectedEntitiesEv

} from './events.js';
import {sample} from 'effector';
import queryString from 'query-string';
import {$info, $infoMaps} from "../infoModel/index.js";
import dayjs from "dayjs";
import {reset} from "patronum";

const lang = localStorage.getItem('lang') ?? 'ru'



$addressList.on(getAddressFx.doneData, (_, payload) => payload)
    // .on(selectAddressEv, (_, payload) => ({data: [], count: 0}))
    .reset([AddressSearchGate.close, resetSelectedEntitiesEv])


$viewTable
    .on(getAddressFx.doneData, (_, payload) => true)
    .on(selectAddressEv, (_, payload) => false)
    .on(openCreateAddressEv, (_, payload) => false)

$selectedAddress.on(selectAddressEv, (_, id) => id)
    .on(cancelSelectedEv, (_, id) => null)
    .reset([AddressSearchGate.close, $selectedEntities.updates, resetSelectedEntitiesEv])



$selectedEntities.on(setAddressSelectedEv, (state, {key, value}) => ({...state, [key]: value}))
    .reset([AddressSearchGate.close, resetSelectedEntitiesEv])

$createdAddress
    .on(createAddressEv, (_, data) => data)
    .on(cancelSelectedEv, (_, id) => null)

$openCreateAddress
    .on(openCreateAddressEv, (_, data) => data)
    .on(createAddressEv, (_, data) => false)

$selectedRows
    .on(selectRowEv, (_, data) => data)
    .reset(resetSelectedEntitiesEv)
$generateType
    .on(setGenetrateTypeEv, (_, data) => data)

// $addressNoLimit
//     .on(getAddressNoLimitFx.doneData, (_, data) => data)

$RESFormData
    .on(setRESFormDataEv, (_, data) => data)



$townsSelect.reset(AddressSearchGate.close)
$districtsSelect.reset(AddressSearchGate.close)
$streetsSelect.reset(AddressSearchGate.close)
$houseSelect.reset(AddressSearchGate.close)
$corpusSelect.reset(AddressSearchGate.close)


sample({
    clock: AddressSearchGate.open,
    target: getTownsSelectFx
})

sample({
    clock: $selectedEntities.updates,
    filter: (selected) => {
        if (selected.house_from !== null || selected.house_to !== null) {
            return false
        }
        if (selected.town_id !== null && selected.town_id !== undefined) {
            // return selected.town_id === -1 ? 0 : selected.town_id
            return selected
        }
    },
    fn: (selected) => {
        return selected.town_id === -1 ? 0 : selected.town_id
    },
    target: getDistrictsSelectFx
})


// sample({
//     clock: setAddressPaginationEv,
//     fn: (pagination, ) => {
//         debugger
//         const data = {
//             ...pagination,
//             page: pagination.current
//         }
//       return data
//     },
//     target: getAddressFx
// })


sample({
    clock: setAddressPaginationEv,

    // filter: (selected) => selected.town_id !== null && selected.town_id !== undefined,
    fn: (pagination, ) => {
        const data = {
            ...pagination,
            page: pagination.current
        }
        return data
    },
    target: $addressPagination
})


sample({
    clock: $selectedEntities.updates,
    // filter: (selected) => selected.town_id !== null && selected.town_id !== undefined,
    filter: (selected) => {
        if (selected.house_from !== null || selected.house_to !== null) {
            return false
        }
        if (selected.town_id !== null && selected.town_id !== undefined) {
            // return selected.town_id === -1 ? 0 : selected.town_id
            return selected
        }
    },
    fn: (clock) => {
        // debugger
        const data = {
            ...clock,
            town_id: clock.town_id === -1 ? 0 : clock.town_id,
            town_distr_id: null
        }
        return queryString.stringify(data, {skipEmptyString: true, skipNull: true})
    },
    target: getSelectsForAddressFx
})

sample({
    source: $selectedEntities,
    clock: getTownsSelectFx.doneData,
    fn: (_, clock) => clock.data.map(t => ({label: t[`name_${lang}`], value: t.id})),
    target: $townsSelect
})

sample({
    source: $selectedEntities,
    clock: getDistrictsSelectFx.doneData,
    fn: (_, clock) => {
        return clock.data.map(t => ({label: `${t.id} ${t[`name_${lang}`]}`, value: t.id}))
    },
    target: $districtsSelect
})



sample({
    source: {street:$selectedEntities, info: $infoMaps},
    clock: getSelectsForAddressFx.doneData,
    filter: (data, clock) => {

        if (clock.length > 0) {
            if (clock[0].street_type_id === undefined) {
                return false
            } else {
                return clock
            }
        } else {
            return clock
        }
    },
    fn: (data, clock) =>{
        return clock.map(t => ({label: t.id + ' ' + data.info.shortStTypeMap[t.street_type_id][`short_name_${lang}`] + ' ' + t[`street_${lang}`], value: t.id}))
    },
    target: $streetsSelect
})



sample({
    source: $selectedEntities,
    clock: getSelectsForAddressFx.doneData,
    filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && (!!selected.town_distr_id || !!selected.street_id) && !selected.house && !selected.corpus,
    fn: (_, clock) => clock.filter(i => Object.keys(i).includes('house')).map(i => ({label: i.house, value: i.house})),
    target: $houseSelect
})





sample({
    source: $selectedEntities,
    clock: getSelectsForAddressFx.doneData,
    filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && (!!selected.town_distr_id || !!selected.street_id) && !!selected.house && !selected.corpus,
    fn: (_, clock) => clock.filter(i => Object.keys(i).includes('corpus')).map(i => ({label: i.corpus, value: i.corpus})),
    target: $corpusSelect
})



sample({
    // clock: $selectedEntities.updates,
    clock: [searchAddressEv, $addressPagination.updates],
    source: {selected: $selectedEntities, pagination: $addressPagination},
    // filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && (!!selected.town_distr_id || !!selected.street_id),
    // filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && !!selected.street_id,
    fn: (data) => {

        const filters = data.selected
        for (const key in filters) {
            const v = filters[key]
            if (v === undefined || v === null || v === '' || v === ' ') {
                delete filters[key]
            }
            // if (key.includes('id')) {
            //     const newKey = key.split('_')[0]
            //     filters[newKey] = filters[key]
            //     delete filters[key]
            // }
            if (!window.location.href.includes('create-group')) {
                if (key === 'house') {
                    filters['house_from'] = filters[key]
                    filters['house_to'] = filters[key]
                    delete filters[key]
                }
                if (key === 'corpus') {
                    filters['corpus_from'] = filters[key]
                    filters['corpus_to'] = filters[key]
                    delete filters[key]
                }
            }

        }
        const newFilters = {
            ...filters,
            page: data.pagination.page,
            limit: data.pagination.limit,
        }

        if (newFilters.town_id === -1) {
            newFilters.town_id = 0
        }

        return queryString.stringify(newFilters, {skipEmptyString: true, skipNull: true})
    },
    target: getAddressFx
})



sample({
    // clock: $selectedEntities.updates,
    clock: getAddressNoLimitEv,
    source: {selected: $selectedEntities, pagination: $addressPagination},
    // filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && (!!selected.town_distr_id || !!selected.street_id),
    // filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && !!selected.street_id,
    fn: (data) => {

        const filters = data.selected
        for (const key in filters) {
            const v = filters[key]
            if (v === undefined || v === null || v === '' || v === ' ') {
                delete filters[key]
            }
            // if (key.includes('id')) {
            //     const newKey = key.split('_')[0]
            //     filters[newKey] = filters[key]
            //     delete filters[key]
            // }

            if (!window.location.href.includes('create-group')) {
                if (key === 'house') {
                    filters['house_from'] = filters[key]
                    filters['house_to'] = filters[key]
                    delete filters[key]
                }
                if (key === 'corpus') {
                    filters['corpus_from'] = filters[key]
                    filters['corpus_to'] = filters[key]
                    delete filters[key]
                }
            }

        }
        const newFilters = {
            ...filters,
        }

        if (newFilters.town_id === -1) {
            newFilters.town_id = 0
        }

        return queryString.stringify(newFilters, {skipEmptyString: true, skipNull: true})
    },
    target: getAddressNoLimitFx
})


sample({
    clock: getAddressNoLimitFx.doneData,
    source: {selected: $selectedEntities, pagination: $addressPagination, formData: $RESFormData, generateType: $generateType},
    fn: (data, payload) => {
        let result = []
        if (data.generateType === 'new_address') {
            let newAddress = []
            const houses_ids = [...new Set(payload.data.map(item => item.house))].map(item => parseInt(item))
                for (let i = parseInt(data.selected.house_from); i <= parseInt(data.selected.house_to); i++) {
                    if (!houses_ids.includes(i)) {
                        let newItem = {
                            town_id: data.selected.town_id,
                            town_distr_id: data.selected.town_distr_id || null,
                            street_id: data.selected.street_id,
                            house: i,
                            corpus: data.selected.corpus ? data.selected.corpus : null,
                            is_actual: true,
                            actual_from: `${dayjs(Date.now()).format('YYYY-MM-DD')}T15:35:44.014Z`,
                            is_on_crossroad: false,
                            id: -i
                        }
                        newAddress.push(newItem)
                    }
                }

            const allAddress  = [...payload.data, ...newAddress]
            result = allAddress.map((item, count) => {
                return {
                    name_kz: data.formData.name_kz,
                    name_ru: data.formData.name_ru,
                    sector_id: data.formData.sector_id,
                    real_estate_object_type_id: data.formData.real_estate_object_type_id,
                    reo_type_name_kz: data.formData.real_estate_object_type_name_kz,
                    reo_type_name_ru: data.formData.real_estate_object_type_name_ru,
                    floor_count: data.formData.floor_count,
                    entrance_count: data.formData.entrance_count,
                    apartment_count: data.formData.apartment_count,
                    is_electricity_network_present: data.formData.is_electricity_network_present ? data.formData.is_electricity_network_present : false,
                    is_heat_network_present: data.formData.is_heat_network_present ? data.formData.is_heat_network_present : false,
                    is_elevator_present: data.formData.is_elevator_present ? data.formData.is_elevator_present : false,
                    is_mailboxes_present: data.formData.is_mailboxes_present ? data.formData.is_mailboxes_present : false,
                    address_id: item.id,
                    town_id: item.town_id,
                    town_distr_id: item.town_distr_id ? item.town_distr_id : null,
                    street_id: item.street_id ? item.street_id : null,
                    house: item.house ? item.house : null,
                    corpus: item.corpus ? item.corpus : null,
                    id: count
                }
            })

            return result.filter(item => item.address_id < 0)


        } else if (payload.data.length > 0) {
            result = payload.data.map((item, count) => {
                return {
                    name_kz: data.formData.name_kz,
                    name_ru: data.formData.name_ru,
                    sector_id: data.formData.sector_id,
                    real_estate_object_type_id: data.formData.real_estate_object_type_id,
                    reo_type_name_kz: data.formData.real_estate_object_type_name_kz,
                    reo_type_name_ru: data.formData.real_estate_object_type_name_ru,
                    floor_count: data.formData.floor_count,
                    entrance_count: data.formData.entrance_count,
                    apartment_count: data.formData.apartment_count,
                    is_electricity_network_present: data.formData.is_electricity_network_present ? data.formData.is_electricity_network_present : false,
                    is_heat_network_present: data.formData.is_heat_network_present ? data.formData.is_heat_network_present : false,
                    is_elevator_present: data.formData.is_elevator_present ? data.formData.is_elevator_present : false,
                    is_mailboxes_present: data.formData.is_mailboxes_present ? data.formData.is_mailboxes_present : false,
                    address_id: item.id,
                    town_id: data.selected.town_id,
                    town_distr_id: data.selected.town_distr_id ? data.selected.town_distr_id : null,
                    street_id: data.selected.street_id ? data.selected.street_id : null,
                    house: item.house ? item.house : null,
                    corpus: item.corpus ? item.corpus : null,
                    id: count,
                    real_estate_object_id: item.real_estate_object_id
                }
            })
            return result.filter(item => item.real_estate_object_id === null)

        } else {
            return []
        }
    },
    target: $addressNoLimit
})


sample({
    clock: onDeleteRowNoLimitEv,
    source: $addressNoLimit,
    fn: (data, payload) => {
        if (payload === 'all') {
            return []
        } else {
            return data.filter(item => item.id !== payload)

        }
    },
    target: $addressNoLimit
})
