import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const TaskPdfToContsGate = createGate()
export const TaskAllPdfGate = createGate()
export const InspectorFilesGate = createGate()
export const UploadedFilesGate = createGate()

export const changeFiltersEv = createEvent()

export const applyFiltersEv = createEvent()

export const resetFiltersEv = createEvent()

export const changeSelectedControllersEv = createEvent()

export const createTaskEv = createEvent()
export const createTaskAllEv = createEvent()
export const setRadioEv = createEvent()
export const setRadioFilesEv = createEvent()
export const setReportDateEv = createEvent()
export const setSendToBankEv = createEvent()

export const downloadFilesEv = createEvent()
export const downloadSingleFileEv = createEvent()

export const resetTaskCreatedEv = createEvent()

export const resetUploadFileModalEv = createEvent()
export const uploadFileEv = createEvent()
export const setUploadFileModalEv = createEvent()
export const setGenerateTypeEv = createEvent()

