import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import {useStoreMap, useUnit} from 'effector-react';
import {changeStreetsChildrenFiltersEv} from '../../../models/embeddedFormsModels/embeddedStreetsModel';
import {$dictsSelects} from '../../../models/infoModel/index.js';

export default function StreetEmbeddedFilters() {
    const {t} = useTranslation()
    const changeFilter = useUnit(changeStreetsChildrenFiltersEv);
    const stOptions = useStoreMap($dictsSelects, sel => sel.streetTypes)

    return <Card title={t('Фильтрация')} size={'small'} style={{marginBottom: 16}}>
        <Row justify={'space-between'} gutter={[16, 16]}>
            <Col span={8}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <span>{t('Код / Название')}</span>
                    <Input onBlur={(e) => changeFilter({key: 'code_name', value: e.target.value})} allowClear/>
                </div>
            </Col>
            <Col span={8}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <span>{t('Тип улицы')}</span>
                    <Select options={stOptions}
                            showSearch
                            allowClear
                            onChange={(v) => changeFilter({key: 'street_type_id', value: v})}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    />
                </div>
            </Col>
        </Row>
    </Card>
}