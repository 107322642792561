import {sample} from "effector";
import {AccrualsGate} from "./events.js";
import {getAccrualsListDataFx} from "./effects.js";
import {$accrualsData} from "./stores.js";

$accrualsData.on(getAccrualsListDataFx.doneData, (_, data) => data)
	.reset(AccrualsGate.close)

sample({
	clock: AccrualsGate.state,
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => gate.search,
	target: getAccrualsListDataFx
})
