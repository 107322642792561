import {createStore} from 'effector';

export const $districtsList = createStore({data: [], count: 0})

export const $selectedDistrict = createStore(null)

export const $editDistrictStatus = createStore(false)

export const $createdDistrictId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $selectedDistStreetsImmutable = createStore([])
export const $selectedDistStreets = createStore([])
export const $deletedStreets = createStore([])

export const $createdTownIdModal = createStore(null)