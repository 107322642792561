import {createStore} from 'effector';


export const $positionList = createStore({data: [], count: 0})

export const $selectedPosition = createStore(null)

export const $editPositionStatus = createStore(false)

export const $createdPositionId = createStore(null)
export const $afterCreateAction = createStore(null)
