import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedCorrection,
    downloadHistoryEv,
    downloadHistoryFx,
    getCorrectionHistoryFx,
    rollbackCorrectionEv,
    ViewCorrectionGate
} from '../../models/dictionaryCorrectionModel/index.js';
import CorrectionCompareTable from './ProvAccSrv/CorrectionCompareTable.jsx';
import {useNavigate, useParams} from 'react-router-dom';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Divider from 'antd/es/divider';
import Space from 'antd/es/space';
import ExcelOutlined from '../../components/ExcelIcon/EcxelIcon.jsx';

export default function CorrectionView() {
    const {t} = useTranslation()
    const {id} = useParams()
    const navigate = useNavigate()

    const loading = useUnit(getCorrectionHistoryFx.pending)
    const downloading = useUnit(downloadHistoryFx.pending)
    const correction = useUnit($selectedCorrection)
    const download = useUnit(downloadHistoryEv)
    const rollback = useUnit(rollbackCorrectionEv)

    return <>
        {id && <ViewCorrectionGate id={+id}/>}
        <Card title={`${t('Просмотр корректировки')} ${correction?.data?.id}`} loading={loading}>
            {correction?.data?.status === 'rollback' && <p style={{marginTop: 0, color: 'orangered'}}><strong>{t('Произведен откат корректировки')}</strong></p>}
            <CorrectionCompareTable />
            <Divider />
            <Space style={{alignItems: 'start'}}>
                {
                    correction?.data?.status === 'success'
                    && <Popconfirm title={t('Вы уверены?')}
                                   description={t('Данное действие отменит все изменения, внесенные в этой корректировке')}
                                   okText={t('Да')}
                                   cancelText={t('Нет')}
                                   onConfirm={() => rollback(correction?.data?.id)}
                                   okButtonProps={{danger: true, type: 'primary'}}
                    >
                        <Button type={'primary'} danger>
                            {t('Откатить корректировку')}
                        </Button>
                    </Popconfirm>
                }
                <Button onClick={() => navigate('/corrections')}>
                    {t('Вернуться к списку')}
                </Button>
                <Button icon={<ExcelOutlined size={18}/>} onClick={() => download(id)} loading={downloading}>
                    {t('Выгрузка Excel')}
                </Button>
            </Space>
        </Card>
    </>
}