import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import Tabs from 'antd/es/tabs';
import CreateTaskAllPdf from './components/CreateTaskAllPdf.jsx';
import TasksStatuses from '../../components/TasksStatuses/TasksStatuses.jsx';
import {useUnit} from 'effector-react';
import {$taskCreated, resetTaskCreatedEv} from '../../models/taskAllPdfModel/index.js';
import FileManager from './components/FileManager.jsx';

export default function TaskAllPdf() {
    const {t} = useTranslation()
    const [activeKey, setActiveKey] = useState('create');

    const taskCreated = useUnit($taskCreated)
    const resetTaskCreated = useUnit(resetTaskCreatedEv)

    const onChangeTab = (payload) => {
        setActiveKey(payload)
    }

    useEffect(() => {
        if (taskCreated) {
            onChangeTab('status')
            resetTaskCreated()
        }
    }, [taskCreated])

    const tabs = [
        {
            key: 'create',
            label: t('Создание задачи'),
            children: <CreateTaskAllPdf  />
        },
        {
            key: 'status',
            label: t('Статусы задач'),
            children: <TasksStatuses name={'generate_epd'}/>
        },
        {
            key: 'files',
            label: t('Файлы'),
            children: <FileManager />
        }
    ]

    return <Tabs
        defaultActiveKey={'create'}
        items={tabs}
        destroyInactiveTabPane
        activeKey={activeKey}
        onChange={onChangeTab}
        type={'card'}
    />
}
