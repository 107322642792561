import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useParams} from 'react-router-dom';
import {
    $accrualsData, AccrualsGate, getAccAccrEv, getAccrualsListDataFx
} from '../../../models/dictionaryAccountsModel/index.js';
import {$info, $infoMaps} from '../../../models/infoModel/index.js';
import {searchAndActions} from '../../../utils/searchAndActions.js';
import Space from 'antd/es/space';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {useState} from 'react';
import FormatedNumber from "../../../components/FormatedNumber.jsx";
import Row from "antd/es/row";
import Col from "antd/es/col";

export default function PreferencesTab() {
    const {t} = useTranslation()
    const {id} = useParams()
    const currentDate = useStoreMap($info, i => i.current_date ? dayjs(i.current_date) : null)
    const [selectedMonth, setSelectedMonth] = useState(currentDate)

    const prefsMap = useStoreMap($infoMaps, maps => maps.preferenceCategoryMap)
    const prefsData = useStoreMap(
        $info,
        i => Object.fromEntries(
            (i?.preference_category || []).map(x => [x.id, x.discount ?? 0])
        )
    ) || {}

    const {search} = searchAndActions()
    let {data, count} = useUnit($accrualsData)
    const loading = useUnit(getAccrualsListDataFx.pending)

    const getAccAccr = useUnit(getAccAccrEv)

    data = data.filter(x => (x.preferences || []).length > 0)

    const onPress = () => {
        getAccAccr({id, month: selectedMonth})
    }

    const columns = [
        {
            title: t('Код и название поставщика'),
            dataIndex: 'provider_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'providers'}/>
        },
        {
            title: t('Код и название услуги'),
            dataIndex: 'service_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'services'}/>
        },
        {
            title: t('Кол-во проживающих'),
            sorter: true,
            dataIndex: 'people_quantity',
            render: (v) => <FormatedNumber value={v}/>,
        },
        {
            title: t('Начисления полн.'),
            sorter: true,
            dataIndex: 'full_calc',
            render: (v) => <FormatedNumber value={v}/>,
        },
        {
            title: t('Начисления со льготами'),
            sorter: true,
            dataIndex: 'sum_calc',
            render: (v) => <FormatedNumber value={v}/>,
        },
        {
            title: t('Льготы'),
            dataIndex: 'preferences',
            width: '10%',
            render: (prefs, r) => {
                if (!!prefs && prefs !== '-' && prefs?.length > 0 && prefs !== '[]') {
                    if (typeof prefs === 'string') prefs = JSON.parse(prefs)
                    const items = prefs?.filter(pref => !!pref?.people_quantity).filter(
                        pref => prefsMap[pref.preference_id]
                    ).map(pref => ({
                        key: pref.preference_id,
                        label: prefsMap[pref.preference_id] ?? '-',
                        children: <Row gutter={[8, 0]}>
                            <Col>
                                {pref.people_quantity + ' ' + t('чел., ')}
                            </Col>
                            <Col>
                                <FormatedNumber value={
                                    (prefsData[pref.preference_id] || 0) *
                                    (pref.people_quantity || 0) / 100 *
                                    (r.tarif_value || 0)
                                }/>
                                {t(' тг.')}
                            </Col>
                        </Row>
                    }))
                    return <Descriptions column={1} items={items}/>
                } else return '-'
            }
        }
    ]

    return <>
        <AccrualsGate id={id} search={search.toString()} />
        <Space style={{marginBottom: 8}}>
            <DatePicker.MonthPicker
                placeholder={t('Месяц')}
                defaultValue={currentDate}
                minDate={currentDate}
                maxDate={currentDate}
                onChange={(date) => setSelectedMonth(date)}
            />
            <Button onClick={onPress} type="primary">{t('Показать')}</Button>
        </Space>
        <DictionaryProTable
            data={data} count={count} initColumns={columns} showActions={false} loading={loading}
            pagination={false}
        />
        {/*<Table columns={columns} dataSource={data}/>*/}
    </>
}
