import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {
    $editChiefInspectorStatus,
    $selectedChiefInspector,
    ChiefInspectorEditGate, getChiefInspectorByIdFx,
    submitEditChiefInspectorEv
} from "../../../models/dictionaryChiefInspectorModel/index.js";
import InspectorsTable from "../../../components/Embedded/InspectorsTable/InspectorsTable.jsx";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryChiefInspectorModel/index.js";

export default function EditChiefInspector() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedChiefInspector)

    const [inspectorsForm] = Form.useForm()

    const submit = useUnit(submitEditChiefInspectorEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const loading = useUnit(getChiefInspectorByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editChiefInspectorStatus)

    const genitive_form = t(`breadcrumbs.genitive.chief-inspector`);



    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/chief-inspector?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            inspectorsForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['town_kz','name_kz', 'name_ru'])

    const onSubmit = (values) => {
        values['name_kz'] = values['name_ru']
        return submit(values)
    }

    return <>
        <ChiefInspectorEditGate id={id}/>
            <Form form={inspectorsForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={onSubmit}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <Row justify={'space-between'} gutter={[24, 24]}>
                    <Col span={24}>
                        <Card title={<StarredTitle title={t('Свойства Контролера')}/>} size={'small'}
                              style={{height: '100%'}} loading={loading}>
                            <Row>
                                {/*<Col span={8}>*/}
                                {/*    <Form.Item name={'name_kz'}*/}
                                {/*               label={t(`ФИО ${genitive_form} (каз)`)}*/}
                                {/*               rules={[{required: true, message: t('Обязательное поле')}]}*/}
                                {/*               validateStatus={changedFields['name_kz']}*/}
                                {/*    >*/}
                                {/*        <Input/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                <Col span={8}>
                                    <Form.Item name={'name_ru'}
                                               label={t(`ФИО ${genitive_form}`)}
                                               rules={[{required: true, message: t('Обязательное поле')}]}
                                               validateStatus={changedFields['name_ru']}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>
            </Form>
            <InspectorsTable
                parent={'chief_inspector_id'}
                parentId={+id}
            />
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        inspectorsForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                inspectorsForm.submit()
                                setAction('edit')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                inspectorsForm.submit()
                                setAction('list')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и вернуться к списку')}
                    </Button>
                </Space>
            </Row>
    </>
}
