import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {
    $accrualsData, $cashPaymentsData, AccrualsGate, CashPaymentsGate, getAccAccrEv, getAccrualsListDataFx
} from '../../../models/dictionaryAccountsModel/index.js';
import {$info, $infoMaps} from '../../../models/infoModel/index.js';
import {searchAndActions} from '../../../utils/searchAndActions.js';
import Space from 'antd/es/space';
import DatePicker from 'antd/es/date-picker';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import React, {useState} from 'react';
import FormatedNumber from "../../../components/FormatedNumber.jsx";
import Row from "antd/es/row";
import Col from "antd/es/col";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";
import {downloadExcelEv} from "../../../models/dictionaryExportModel/index.js";


const exportXlsAction = (type) =>  {
    const exportXls = useUnit(downloadExcelEv)

    const [search] = useSearchParams()
    return exportXls({type: type, filters: search})
}

export default function CashPaymentTab() {
    const {t} = useTranslation()
    const {id} = useParams()
    console.log('id', id)
    const currentDate = useStoreMap($info, i => i.current_date ? dayjs(i.current_date) : null)
    const [selectedMonth, setSelectedMonth] = useState(currentDate)

    const prefsMap = useStoreMap($infoMaps, maps => maps.preferenceCategoryMap)
    const prefsData = useStoreMap(
        $info,
        i => Object.fromEntries(
            (i?.preference_category || []).map(x => [x.id, x.discount ?? 0])
        )
    ) || {}
    const {search} = searchAndActions()

    let {data, count} = useUnit($cashPaymentsData)
    const loading = useUnit(getAccrualsListDataFx.pending)






    const getAccAccr = useUnit(getAccAccrEv)

    // data = data.filter(x => (x.preferences || []).length > 0)

    const onPress = () => {
        getAccAccr({id, month: selectedMonth})
    }

    const columns = [
        {
            title: t('Дата реестра'),
            dataIndex: 'reestr_date',
            key: 'reestr_date',
            sorter: true,
            render: (date) => <CustomDates data={date} />
        },

        {
            title: t('Касса'),
            dataIndex: 'bank_id',
            key: 'bank_id',
            sorter: true,
            render: (bank_id, record) => <MappedDictItem id={bank_id} type={'banks'} />

        },
        {
            title: t('Дата платежа'),
            dataIndex: 'payment_date',
            key: 'payment_date',
            sorter: true,
            render: (date) => <CustomDates data={date} />
        },

        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            key: 'provider_id',
            sorter: true,
            render: (bank_id, record) => <MappedDictItem id={bank_id} type={'providers'} />
        },
        {
            title: t('Услуга'),
            dataIndex: 'service_id',
            key: 'service_id',
            sorter: true,
            render: (id, record) => <MappedDictItem id={id} type={'services'} />
        },
        {
            title: t('№ ЛС'),
            dataIndex: 'account_id',
            key: 'account_id',
            sorter: true,
            render: (id, record) => <MappedDictItem id={id} type={'accounts'} />
        },
        {
            title: t('Сумма'),
            dataIndex: 'summa',
            key: 'summa',
            sorter: true,
            render: (v) => <FormatNumber value={v} type={2}/>
        },
    ]

    return <>
        <CashPaymentsGate id={id} search={search.toString()} />
        {/*<Space style={{marginBottom: 8}}>*/}
        {/*    <DatePicker.MonthPicker*/}
        {/*        placeholder={t('Месяц')}*/}
        {/*        defaultValue={currentDate}*/}
        {/*        minDate={currentDate}*/}
        {/*        maxDate={currentDate}*/}
        {/*        onChange={(date) => setSelectedMonth(date)}*/}
        {/*    />*/}
        {/*    <Button onClick={onPress} type="primary">{t('Показать')}</Button>*/}
        {/*</Space>*/}
        <DictionaryProTable
            data={data} count={count} initColumns={columns}  loading={loading}
            pagination={false} exportXlsAction={() => exportXlsAction('provider-payments')} showActions={false} hiddenActions={'create'}
        />
        {/*<Table columns={columns} dataSource={data}/>*/}
    </>
}
