import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $bankAccsModal,
    $bankAccsOpts,
    $newBankAccsOpts,
    $selectedBankAccount,
    $selectedProvider,
    addDefaultProvBAEv,
    addNewDefaultProvBAEv,
    addNewProvSrvBAEv,
    addNewTempSrvBaREOEv,
    addProvSrvBAEv,
    addTempSrvBaREOEv,
    createEditBankAccountFx,
    createEditDefaultBankAccountFx, createEditREOBankAccountFx,
    createEditSrvBankAccountFx,
    editDefaultProvBAEv,
    editProvSrvBAEv,
    editTempSrvBaREOEv,
    selectProvForBAsEv,
} from '../../../models/dictionaryProvidersModel/index.js';
import {$dictsSelects, $info, $userPerms} from '../../../models/infoModel/index.js';
import {filterOption, optionRender} from '../../../utils/selectUtils.jsx';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Modal from "antd/es/modal";
import DatePicker from "antd/es/date-picker";
import InputNumber from 'antd/es/input-number';


export default function CreateEditBAModal({beneficiaryId, afterClose, activeAccs, allAccs}) {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang') ?? 'ru'
    const minDateFrom = useStoreMap($info, i => i.last_exported_day ? dayjs(i.last_exported_day).startOf('day') : dayjs().subtract(1))

    const submitBALoading = useUnit(createEditBankAccountFx.pending)
    const submitDBALoading = useUnit(createEditDefaultBankAccountFx.pending)
    const submitSrvBALoading = useUnit(createEditSrvBankAccountFx.pending)
    const submitReoBALoading = useUnit(createEditREOBankAccountFx.pending)
    const loading = submitBALoading || submitDBALoading || submitSrvBALoading || submitReoBALoading
    const perms = useUnit($userPerms)

    const {mode, open, id, type} = useUnit($bankAccsModal)

    const [baForm] = Form.useForm()
    const formDateFrom = Form.useWatch('date_from', baForm)
    const selectProvider = useUnit(selectProvForBAsEv)
    const addDefaultAcc = useUnit(addDefaultProvBAEv)
    const addNewDefaultAcc = useUnit(addNewDefaultProvBAEv)
    const editDefaultAcc = useUnit(editDefaultProvBAEv)

    const addSrvAcc = useUnit(addProvSrvBAEv)
    const addNewSrvAcc = useUnit(addNewProvSrvBAEv)
    const editSrvAcc = useUnit(editProvSrvBAEv)

    const addBaReoTemp = useUnit(addTempSrvBaREOEv)
    const addNewBaReoTemp = useUnit(addNewTempSrvBaREOEv)
    const editBaReoTemp = useUnit(editTempSrvBaREOEv)

    const selectedProvider = useUnit($selectedProvider)
    const baOptsStore = useUnit($bankAccsOpts)
    const newBAOpts = useUnit($newBankAccsOpts)
    const selectedBa = useUnit($selectedBankAccount)

    const nonEditable = mode === 'edit' && selectedBa && dayjs(selectedBa?.date_from).startOf('day') <= dayjs(minDateFrom)

    const calcMax = () => {
        if (allAccs && type === 'srv') {
            let accs = allAccs?.filter(acc => dayjs(acc?.date_from) <= dayjs(formDateFrom) && (dayjs(formDateFrom) <= dayjs(acc?.date_to) || acc?.date_to === null))
            if (mode === 'edit' && selectedBa) {
                accs = accs.filter(acc => acc.id !== selectedBa.id)
            }
            const percentSum = accs?.reduce((acc, cur) => acc + (cur?.transfer_percent ?? 0), 0)
            return 100 - percentSum
        }
    }

    const maxPercent = calcMax()

    const beneficiaries = useStoreMap(
        $info,
        (i) => (i.provider || []).filter(p => p.is_beneficiary)
    )
    const {bankOpts, bankAccTypeOpts} = useStoreMap($dictsSelects, sel => ({
        bankOpts: sel.bank,
        bankAccTypeOpts: sel.bank_account_types
    }))
    const providerId = Form.useWatch('provider_id', baForm)
    const baId = Form.useWatch('bank_account_id', baForm)

    useEffect(() => {
        if (mode === 'add' && open) {
            const newBId = type !== 'reo' && beneficiaryId ? beneficiaryId : selectedProvider?.id ?? -1
            baForm.setFieldValue('provider_id', newBId)
        }
    }, [beneficiaryId, open, mode, type, selectedProvider]);

    const onChangeProvider = () => {
        baForm.resetFields(['bank_account_id'])
    }

    useEffect(() => {
        if (providerId) {
            selectProvider(providerId)
        }
    }, [providerId]);

    useEffect(() => {
        if (selectedBa && open) {
            if (type === 'default') {
                baForm.setFieldsValue({
                    provider_id: selectedBa.bank_account_provider_id,
                    bank_account_id: selectedBa?.bank_account_id ?? null,
                    date_from: dayjs(selectedBa?.date_from) ?? dayjs()
                })
            } else if (type === 'srv') {
                baForm.setFieldsValue({
                    provider_id: selectedBa.bank_account_provider_id,
                    bank_account_id: selectedBa?.bank_account_id ?? null,
                    date_from: dayjs(selectedBa?.date_from) ?? dayjs(),
                    date_to: selectedBa?.date_to ? dayjs(selectedBa?.date_to) : null,
                    transfer_percent: selectedBa?.transfer_percent ?? 0
                })
            }
        }
    }, [selectedBa, open])

    const onSubmit = (values) => {
        if (mode === 'add' && values.bank_account_id < 0 && !newBAOpts.some(opt => opt.value === values.bank_account_id)) {
            switch (type) {
                case 'default': addNewDefaultAcc(values)
                    break
                case 'srv': addNewSrvAcc(values)
                    break
                case 'reo': addNewBaReoTemp(values)
                    break
            }
        } else if (mode === 'add' && (values.bank_account_id > 0 || newBAOpts.some(opt => opt.value === values.bank_account_id))) {
            switch (type) {
                case 'default': addDefaultAcc(values)
                    break
                case 'srv': addSrvAcc(values)
                    break
                case 'reo': addBaReoTemp(values)
                    break
            }
        } else if (mode === 'edit') {
            switch (type) {
                case 'default': editDefaultAcc(values)
                    break
                case 'srv': editSrvAcc(values)
                    break
                case 'reo': editBaReoTemp(values)
                    break
            }
        }
        onCancelAdd()
    }

    const onCancelAdd = () => {
        baForm.resetFields()
        afterClose()
    }

    const beneficiaryProvidersOpts = [
        {value: selectedProvider?.id ?? -1, label: t('0 Собственные р/с')},
        ...beneficiaries.map(b => ({
            value: b.id,
            label: b.id + ' ' + b?.[`name_${lang}`]
        }))
    ]

    const initialValues = mode === 'add' ? {
        provider_id: beneficiaryId ? beneficiaryId : selectedProvider?.id ?? -1,
        bank_account_id: null,
        date_from: dayjs()
    } : {}

    const baOpts = [
        ...baOptsStore,
        ...newBAOpts,
        ...(((selectedProvider !== null && providerId !== selectedProvider?.id && perms?.bank_account?.change)) ? [] : [{
            value: -1,
            emoji: true,
            label: t('Создать'),
        }])
    ]

    return <Modal
        afterClose={afterClose}
        cancelText={t('Отменить')}
        centered={true}
        destroyOnClose={true}
        okText={mode === 'add' ? t('Добавить') : t('Применить')}
        title={mode === 'add' ? t('Добавление р/с') : t('Редактирование р/с')}
        open={open}
        okButtonProps={{loading}}
        onOk={baForm.submit}
        onCancel={onCancelAdd}
        width={800}
    >
        <Form
            layout={'vertical'} form={baForm} onFinish={onSubmit}
            size={'small'}
            initialValues={initialValues}
        >
            <Row gutter={[8, 0]} style={{alignItems: 'flex-end'}}>
                <Col span={8}>
                    <Form.Item
                        name={'provider_id'}
                        label={t('Поставщик Бенефициар')}
                        rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select
                            disabled={mode === 'edit' || nonEditable || type === 'reo'}
                            options={beneficiaryProvidersOpts}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            onChange={onChangeProvider}
                            dropdownStyle={{minWidth: 'fit-content'}}
                        />
                    </Form.Item>
                </Col>
                <Col span={type !== 'srv' ? 12 : 16}>
                    <Form.Item name={'bank_account_id'} label={t('Расчетный счет')}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <Select
                            disabled={mode === 'edit' || nonEditable}
                            options={baOpts} filterOption={filterOption} optionRender={optionRender} showSearch allowClear dropdownStyle={{minWidth: 'fit-content'}}
                        />
                    </Form.Item>
                </Col>
                <Col span={type !== 'srv' ? 4 : 8}>
                    <Form.Item name={'date_from'} label={t('Действует с')} rules={[{required: true, message: t('Обязательное поле')}]}>
                        <DatePicker
                            disabled={nonEditable}
                            style={{width: '100%'}}
                            minDate={minDateFrom}
                            format={'DD.MM.YYYY'}
                        />
                    </Form.Item>
                </Col>
                {type === 'srv' && <>
                    <Col span={8}>
                        <Form.Item name={'date_to'} label={t('Действует по')}>
                            <DatePicker
                                style={{width: '100%'}}
                                minDate={dayjs()}
                                format={'DD.MM.YYYY'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'transfer_percent'} label={`${t('Процент перечисления')} (${t('макс.')} ${maxPercent ?? '-'}%)`}
                                   rules={[
                                       {required: true, message: t('Обязательное поле')},
                                       {validator: (rule, value) => value <= maxPercent ? Promise.resolve() : Promise.reject(t('Процент по активным Р/С не должен превышать 100%'))},
                                   ]}
                        >
                            <InputNumber precision={1} min={0.1} style={{width: '100%'}}
                                         step={0.1}
                                         disabled={nonEditable}
                                         decimalSeparator={','}
                                         parser={v => Number(v.replace(',', '.'))}
                                         placeholder={'-'}
                            />
                        </Form.Item>
                    </Col>
                </>}
                {
                    type === 'reo' && <Col span={8}>
                        <Form.Item name={'date_to'} label={t('Действует по')}>
                            <DatePicker
                                style={{width: '100%'}}
                                minDate={dayjs()}
                                format={'DD.MM.YYYY'}
                            />
                        </Form.Item>
                    </Col>
                }
                { baId === -1 ? <>
                <Col span={8}>
                    <Form.Item name={'bank_id'} label={t('Банк')}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <Select
                            options={bankOpts} filterOption={filterOption} showSearch allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'bank_account_type_id'} label={t('Тип расчетного счета')}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <Select
                            options={bankAccTypeOpts} filterOption={filterOption} showSearch
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'account_number'} label={t('Номер счета')}
                               validateTrigger={'onBlur'}
                               normalize={value => value.toUpperCase()}
                               rules={[
                                   {required: true, message: t('Обязательное поле')},
                                   {
                                       pattern: /^[A-Z0-9]{20}$/,
                                       message: t('20 символов, только латинские буквы и цифры')
                                   },
                               ]}>
                        <Input showCount maxLength={20}/>
                    </Form.Item>
                </Col>
                </> : null }
            </Row>
        </Form>
    </Modal>
}
