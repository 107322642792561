import {useTranslation} from 'react-i18next';
import Input from 'antd/es/input';
import FormItem from 'antd/es/form/FormItem';
import Card from 'antd/es/card';
import {useEffect} from 'react';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

export default function CreatePayTypeEmbedded({open, form, namespace = [], prefixRu, prefixKz, shortPrefixRu, shortPrefixKz}) {
    const {t} = useTranslation()

    useEffect(() => {
        if (open) {
            if (prefixRu) {
                form.setFieldValue([...namespace, 'name_ru'], `ЕРЦ за ${prefixRu}`)
            }
            if (prefixKz) {
                form.setFieldValue([...namespace, 'name_kz'], `ЕРЦ ${prefixKz} үшін`)
            }
            if (shortPrefixRu) {
                form.setFieldValue([...namespace, 'short_name_ru'], `ЕРЦ за ${shortPrefixRu}`)
            }
            if (shortPrefixKz) {
                form.setFieldValue([...namespace, 'short_name_kz'], `ЕРЦ ${shortPrefixKz} үшін`)
            }
        }
    }, [open, prefixRu, prefixKz, shortPrefixRu, shortPrefixKz]);

    return <Card title={t('Создание вида платежа')}>
        <Row gutter={[8, 0]}>
            <Col span={6}>
                <FormItem name={[...namespace, 'name_ru']} label={t('Название (рус)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Input />
                </FormItem>
            </Col>
            <Col span={6}>
                <FormItem name={[...namespace, 'short_name_ru']} label={t('Крат. Название (рус)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Input />
                </FormItem>
            </Col>
            <Col span={6}>
                <FormItem name={[...namespace, 'name_kz']} label={t('Название (каз)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Input />
                </FormItem>
            </Col>
            <Col span={6}>
                <FormItem name={[...namespace, 'short_name_kz']} label={t('Крат. Название (каз)')} rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Input />
                </FormItem>
            </Col>
        </Row>
    </Card>
}