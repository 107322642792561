import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const SectorListGate = createGate()
export const SectorViewGate = createGate()
export const SectorEditGate = createGate()
export const SectorCreateGate = createGate()

export const submitCreateSectorEv = createEvent()
export const submitEditSectorEv = createEvent()
export const deleteSectorEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
