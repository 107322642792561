import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $correctionHistory, $correctionHistoryTable,
    $correctionHistoryTariffs, setCorrHistTableEv
} from '../../../models/dictionaryCorrectionModel/index.js';
import Table from 'antd/es/table';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import {isId} from '../../../utils/helpers.js';
import {$infoMaps} from '../../../models/infoModel/index.js';
import Descriptions from 'antd/es/descriptions';
import {Link} from "react-router-dom";

export default function CorrectionCompareTable() {
    const {t} = useTranslation()
    const {srvUnitMap, prefsMap} = useStoreMap($infoMaps, m => (
        {srvUnitMap: m.serviceUnitsMap, prefsMap: m.preferenceCategoryMap}
    ))
    const setTable = useUnit(setCorrHistTableEv)

    const {count, data} = useUnit($correctionHistory)
    const {limit, page, sort_field, sort_order} = useUnit($correctionHistoryTable)
    const tariffsMap = useUnit($correctionHistoryTariffs)

    const onChangeTable = ({current, pageSize}, filters, {field, order}) => {
        const payload = []
        if (current !== page || pageSize !== limit) {
            payload.push({key: 'page', value: current})
            payload.push({key: 'limit', value: pageSize})
        }

        if (field !== sort_field || order !== sort_order) {
            if (!order) {
                payload.push({key: 'sort_field', value: null})
                payload.push({key: 'sort_order', value: null})
            } else {
                payload.push({key: 'sort_field', value: field})
                payload.push({
                    key: 'sort_order',
                    value: order === 'ascend' ? 'asc' : 'desc'
                })
            }
        }
        setTable(payload)
    }

    const columns = [
        {
            title: t('ЛС'),
            dataIndex: 'account_id',
            render: (id) => <Link to={`/dictionaries/accounts/view/${id}`} target={'_blank'}>
                        {id}
                    </Link>
        },
        {
            title: t('Код и название поставщика'),
            dataIndex: 'provider_id',
            render: (id) => <MappedDictItem id={id} type={'providers'}/>
        },
        {
            title: t('Код и название услуги'),
            dataIndex: 'service_id',
            render: (id) => <MappedDictItem id={id} type={'services'}/>
        },
        {
            title: t('Измененный атрибут'),
            dataIndex: 'column',
            render: (attr) => t(`corr_attrs.${attr}`)
        },
        {
            title: t('Предыдущее значение'),
            dataIndex: 'old',
            width: '25%',
            render: (old, record) => {
                if (record?.column === 'tarif_id' && isId(old)) {
                    return `${tariffsMap?.[old]?.value ?? '-'} ${tariffsMap?.[old]?.service_unit_id ? srvUnitMap[tariffsMap?.[old]?.service_unit_id] ?? '' : ''}`
                } else if (record?.column === 'provider_id' && isId(old)) {
                    return <MappedDictItem id={old} type={'providers'}/>
                } else if (record?.column === 'service_id' && isId(old)) {
                    return <MappedDictItem id={old} type={'services'}/>
                } else if (record?.column.includes('date_')) {
                    return old ? dayjs(old).format('DD.MM.YYYY') : ''
                } else if (record?.column === 'preferences') {
                    if (old?.length > 0 && old !== '[]') {
                        const items = JSON.parse(old)?.map(pref => ({key: pref.preference_id, title: prefsMap[pref?.preference_id] ?? '-', children: pref?.people_quantity ?? '-'}))
                        return <Descriptions column={1} items={items}/>
                    } else return '-'
                } else return old
            }
        },
        {
            title: t('Новое значение'),
            dataIndex: 'new',
            width: '25%',
            render: (newVal, record) => {
                if (record?.column === 'tarif_id' && isId(newVal)) {
                    return `${tariffsMap?.[newVal]?.value ?? '-'} ${tariffsMap?.[newVal]?.service_unit_id ? srvUnitMap[tariffsMap?.[newVal]?.service_unit_id] ?? '' : ''}`
                } else if (record?.column === 'provider_id' && isId(newVal)) {
                    return <MappedDictItem id={newVal} type={'providers'}/>
                } else if (record?.column === 'service_id' && isId(newVal)) {
                    return <MappedDictItem id={newVal} type={'services'}/>
                } else if (record?.column.includes('date_')) {
                    return newVal ? dayjs(newVal).format('DD.MM.YYYY') : ''
                } else if (record?.column === 'preferences') {
                    if (newVal?.length > 0 && newVal !== '[]') {
                        const items = JSON.parse(newVal)?.map(pref => ({key: pref.preference_id, label: prefsMap[pref?.preference_id] ?? '-', children: pref?.people_quantity ?? '-'}))
                        return <Descriptions column={1} items={items} bordered/>
                    } else return '-'
                } else return newVal
            }
        },
        {
            title: t('Период'),
            dataIndex: 'period',
            render: (period) => dayjs(period).format('DD.MM.YYYY')
        }
    ]

    return <Table
        dataSource={data}
        style={{width: '100%', marginTop: 16}}
        columns={columns}
        onChange={onChangeTable}
        pagination={{
            ...paginationConfig,
            pageSize: parseInt(limit),
            current: parseInt(page),
            total: count
        }}
    />
}
