import {sample} from "effector";
import {SaldoGate} from "./events.js";
import {getSaldoListFx} from "./effects.js";
import {$saldoData} from "./stores.js";

$saldoData.on(getSaldoListFx.doneData, (_, data) => data)
	.reset(SaldoGate.close)

sample({
	clock: SaldoGate.state,
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => gate.search,
	target: getSaldoListFx
})
