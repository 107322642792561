import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import {
	$exportedPaymentsSt,
	exportExportDbfEv,
	exportExportTableEv,
	exportExportTxtEv
} from "../../../models/paymentModel/index.js";
import Button from "antd/es/button/index.js";
import ExcelOutlined from "../../../components/ExcelIcon/EcxelIcon.jsx";
import Col from "antd/es/col/index.js";
import Card from "antd/es/card";
import Row from "antd/es/row";
import Table from "antd/es/table";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {Select} from "antd";
import {$dictsSelects} from "../../../models/infoModel/index.js";

export default function PaymentTableProv({currentPayment}) {
	const {t} = useTranslation()
	const {data, count} = useUnit($exportedPaymentsSt)
	console.log('currentPayment',currentPayment)
	const exportXls = useUnit(exportExportTableEv)
	const exportDbf = useUnit(exportExportDbfEv)
	const exportTxt = useUnit(exportExportTxtEv)
	const {search} = searchAndActions()

	const [bank, setBank] = useState(['all']);



	const banksOpt = useStoreMap($dictsSelects, sel => sel.bank).filter(item => item.is_contract_signed === true)

	const bankOptions = [
		...banksOpt,
		{
			label: 'Все',
			value: 'all'
		}
	]


	const columns = [
		// {
		// 	title: t('Поставщик'),
		// 	dataIndex: 'provider_id',
		// 	key: 'provider_id',
		// },
		{
			title: t('Дата'),
			dataIndex: 'dat',
			key: 'dat',
			render: (date) => {
				return date ? dayjs(date, 'DD.MM.YYYY').format('DD.MM.YY') : '-'
			}
		},
		{
			title: t('Отделение'),
			dataIndex: 'bank_id',
			key: 'bank_id',
			render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>
		},
		{
			title: t('БИК'),
			dataIndex: 'kodbn',
			key: 'kodbn',
		},
		{
			title: t('Р/С поставщика'),
			dataIndex: 'numrs',
			key: 'numrs',
		},
		{
			title: t('Код р-ного поставщика'),
			dataIndex: 'tsh',
			key: 'tsh',
		},
		{
			title: t('БИН'),
			dataIndex: 'bin',
			key: 'bin',
		},
		{
			title: t('Сумма платежа'),
			dataIndex: 'sump',
			key: 'sump',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
		{
			title: t('Название поставщика'),
			dataIndex: 'name_pol',
			key: 'name_pol',
		},
		{
			title: t('Тип платежа'),
			dataIndex: 'nazn_pl',
			key: 'nazn_pl',
		},
		{
			title: t('Код услуги'),
			dataIndex: 'serv_id',
			key: 'serv_id',
		},
		{
			title: t('КБК'),
			dataIndex: 'kodd',
			key: 'kodd',
		},
		{
			title: t('-'),
			dataIndex: 'fl',
			key: 'fl',
		},
		{
			title: t('КБЕ'),
			dataIndex: 'kbe',
			key: 'kbe',
		},

		{
			title: t('КНП'),
			dataIndex: 'knp',
			key: 'knp',
		},
		{
			title: t('ИИН'),
			dataIndex: 'iin_abon',
			key: 'iin_abon',
		},
		{
			title: t('ФИО'),
			dataIndex: 'fio',
			key: 'fio',
		},


	]


	const filter = currentPayment?.export_ids?.map(id => `payment_export_id=${id}`)?.join('&')

	const onExport = (filter, type) => {
		console.log('bank', bank)

		const bankFilter = filter + bank?.map(item => {
			if (item !== 'all') {
				return `&bank_id=${item}`
			}
		})

			if (type === 'xls') {
				exportXls(bankFilter)
			} else if (type === 'dbf') {
				exportDbf(bankFilter)
			} else if (type === 'txt') {
				exportTxt(bankFilter)
			}
	}

	const onChangeBank = (e) =>  {
		if ((e.includes('all') && !bank.includes('all'))  || e.length === 0) {
			setBank(['all'])
		} else {
			setBank(e.filter(item => item !== 'all'))
		}
	}




	return (
		<>
			<Card>
				<Col>
					<Row gutter={[8,8]}>

						<Col span={7} >
							<Row
								style={{alignItems: 'center',}}
							>
								<p style={{margin: '0', marginRight: '5px'}}>
									Выберите банк:
								</p>
								<Select
									style={{width: '50%'}}
									placeholder={'Выберите банк'}
									options={bankOptions}
									defaultValue={'all'}
									value={bank}
									mode={'multiple'}
									// onChange={(e) => setBank(e)}
									onChange={(e) => onChangeBank(e)}
								/>
							</Row>

						</Col>

						<Col>
							<Button
								// onClick={() => exportXls(filter)}
								onClick={() => onExport(filter, 'xls')}
								size={'small'}
							>
								<ExcelOutlined size={18}/>
								Экспорт
							</Button>
						</Col>
						<Col>
							<Button
								// onClick={() => exportDbf(filter)}
								onClick={() => onExport(filter, 'dbf')}
								size={'small'}
							>
								<ExcelOutlined size={18}/>
								Экспорт DBF
							</Button>
						</Col>
						<Col>
							<Button
								// onClick={() => exportTxt(filter)}
								onClick={() => onExport(filter, 'txt')}
								size={'small'}
							>
								<ExcelOutlined size={18}/>
								Экспорт TXT
							</Button>
						</Col>
					</Row>
				</Col>
				<br/>
				<Table
					dataSource={data}
					// dataSource={[]}
					columns={columns}
				/>
			</Card>
		</>

	);
}

