import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedAccount,
    lockUnlockAccountEv
} from '../../../models/dictionaryAccountsModel/index.js';
import {useEffect} from 'react';
import Switch from 'antd/es/switch';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import TextArea from 'antd/es/input/TextArea';

export default function BlockAcc() {
    const {t} = useTranslation()
    const account = useUnit($selectedAccount)
    const submit = useUnit(lockUnlockAccountEv)

    const [statusForm] = Form.useForm()
    const switched = Form.useWatch('status', statusForm)

    useEffect(() => {
        if (account) {
            statusForm.setFieldsValue({
                status: account.status === 'active',
                status_reason: account?.status_reason ?? ''
            })
        }
    }, [account]);

    useEffect(() => {
        if ((account?.status !== 'closed') !== switched) {
            statusForm.resetFields(['status_reason'])
        } else {
            statusForm.setFieldsValue({
                status_reason: account?.status_reason ?? ''
            })
        }
    }, [account, switched])

    return <Card title={t('Статус активности ЛС')}>
        <Form form={statusForm} layout={'vertical'} onFinish={(values) => submit(values)}>
            <Form.Item name={'status'} label={t('Активен')} valuePropName={'checked'}>
                <Switch />
            </Form.Item>
            <Form.Item name={'status_reason'} label={t('Причина')}
                       rules={[{required: true, message: t('Обязательное поле')}]}
            >
                <TextArea rows={3} />
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} onClick={() => statusForm.submit()}>
                    {t('Сохранить')}
                </Button>
            </Form.Item>
        </Form>
    </Card>
}