import {
    $logsList,
    $selectedFile,
    $selectedFileCells,
    $uploadModal,
    $uploadStatus
} from './stores.js';
import {
    acceptFileFx, deleteFileFx, downloadErrorFileFx, downloadFileFx,
    getFileCellsFx, getFileInfoFx,
    getFilesListFx, rejectFileFx,
    sendMessageUploadFx, stopUploadFileFx,
    uploadFileFx
} from './effects.js';
import {
    acceptFileEv,
    acceptMessageUploadEv, deleteFileEv, deselectFileEv, downloadErrorFileEv, downloadFileEv,
    FilesGate, rejectFileEv,
    resetUploadModalEv,
    sendMessageUploadEv,
    setUploadModalEv, stopUploadFileEv,
    uploadFileEv, ViewFileCellsGate
} from './events.js';
import {sample} from 'effector';

$logsList
    .on(acceptMessageUploadEv, (state, msg) => {
        const newList = [...state.data]
        newList.map((item) => {
            if (item.id === msg.id) {
                for (const [key, value] of Object.entries(msg)) {
                    item[key] = value
                }
            }
            return item
        })
        return {data: newList, count: state.count}
    })
    .on(getFilesListFx.doneData, (_, payload) => payload)
    // .on(stopUploadFileFx.doneData, (_, payload) => payload)
    .reset(FilesGate.close)


$uploadStatus.on(uploadFileFx.doneData, () => true)
    .reset(resetUploadModalEv)

$uploadModal.on(setUploadModalEv, () => true)
    .reset(resetUploadModalEv)

$selectedFile.on(getFileInfoFx.doneData, (_, file) => file)
    .reset(ViewFileCellsGate.close)
$selectedFileCells.on(getFileCellsFx.doneData, (_, cells) => cells)
    .reset(ViewFileCellsGate.close)

sample({
    source: FilesGate.state,
    clock: [FilesGate.state.updates, uploadFileFx.doneData, acceptFileFx.doneData, rejectFileFx.doneData, deleteFileFx.doneData],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getFilesListFx
})

sample({
    clock: uploadFileEv,
    target: uploadFileFx
})

sample({
    clock: downloadFileEv,
    target: downloadFileFx
})

sample({
    clock: downloadErrorFileEv,
    target: downloadErrorFileFx
})

sample({
    clock: ViewFileCellsGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getFileInfoFx
})

sample({
    clock: ViewFileCellsGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => ({id: +gate.id, filters: gate.search}),
    target: getFileCellsFx
})

sample({
    clock: acceptFileEv,
    target: acceptFileFx
})

sample({
    clock: rejectFileEv,
    target: rejectFileFx
})

sample({
    clock: stopUploadFileEv,
    target: stopUploadFileFx
})

sample({
    clock: stopUploadFileFx.doneData,
    target: getFilesListFx
})

sample({
    clock: deleteFileEv,
    target: deleteFileFx
})

sendMessageUploadEv.watch((data) => {
    sendMessageUploadFx(data)
})
