import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {
    $selectedAddress, AddressViewGate,
    getAddressByIdFx
} from '../../../models/dictionaryAddressesModel';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";

export default function ViewAddress() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getAddressByIdFx.pending)
    const address = useUnit($selectedAddress)
    console.log(' address', address)

    const items = [
        {
            key: 'index',
            label: t('Индекс'),
            children: address?.index,
        },
        {
            key: 'town_id',
            label: t('Город'),
            // FIXME HON wait back
            children: address?.town_id ? <MappedDictItem id={address?.town_id} type={'town'} /> : '-'
        },
        {
            key: 'town_distr_id',
            label: t('Район'),
            children: address?.town_distr_id ? <MappedDictItem id={address?.town_distr_id} type={'town_distr'} /> : '-'
        },
        {
            key: 'street_id',
            label: t('Улица'),
            children:<MappedDictItem id={address?.street_id} type={'street'}/>
        },
        {
            key: 'house',
            label: t('Номер дома'),
            children: address?.house
        },
        {
            key: 'corpus',
            label: t('Номер корпуса'),
            children: address?.corpus
        },
        {
            key: 'is_actual',
            label: t('Актуален'),
            children: address?.is_actual ? t('Да') : t('Нет')
        },
        {
            key: 'actual_from',
            label: t('Актуален с'),
            children: address?.actual_from ? <CustomDates data={address?.actual_from} /> : '-'
        },
        {
            key: 'is_on_crossroad',
            label: t('Находится на перекрестке'),
            children: address?.is_on_crossroad ? t('Да') : t('Нет')
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={address?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={address?.created_at} />
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: address?.updated_by ? <CreatedBy id={address?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: address?.updated_at ? <CustomDates data={address?.updated_at} /> : '-'
        }
    ]

    return <>
        <AddressViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр дома')}</span> <MappedDictItem id={id} type={'address'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'address'}/>
        </Card>
    </>
}
