import {useUnit} from 'effector-react';
import {
    $uploadConfirmedMeta,
    $uploadConfirmedStatus,
    $uploadCorrectionMetadata, $uploadCorrProcessing, getCorrUploadErrorsEv, uploadCancelEv,
    uploadConfirmEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Descriptions from 'antd/es/descriptions';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {useTranslation} from 'react-i18next';
import Popconfirm from 'antd/es/popconfirm';
import {useWebSocket} from '../../../../api/WebSocketProvider.jsx';
import {$WsSt, sendMsgEv} from '../../../../models/webSocketModel/index.js';
import {useEffect} from 'react';

export default function UploadCorrectionMeta() {
    const {t} = useTranslation();
    const fileProcessing = useUnit($uploadCorrProcessing)
    const fileData = useUnit($uploadCorrectionMetadata)
    const confirm = useUnit(uploadConfirmEv)
    const cancel = useUnit(uploadCancelEv)
    const downloadErrors = useUnit(getCorrUploadErrorsEv)

    const { sendMessage } = useWebSocket()
    const wsStatus = useUnit($WsSt)
    const sub = useUnit(sendMsgEv)
    const uploadConfirmed = useUnit($uploadConfirmedStatus)
    const uploadConfirmedMeta = useUnit($uploadConfirmedMeta)

    useEffect(() => {
        if (uploadConfirmed) {
            const payload = {
                sendMessage: sendMessage,
                payload: {
                    object_type: "correction_load_file",
                    subscribe: true
                }
            }
            sub(payload)
        }
        return () => sub({
            sendMessage: sendMessage,
            payload: {
                object_type: "correction_load_file",
                subscribe: false
            }
        })
    }, [wsStatus, uploadConfirmed]);

    const fileItems = [
        {
            key: '1', label: t('Строк всего'), children: fileData?.total_rows ?? 0
        },
        {
            key: '2', label: t('Строк валидных'), children: (fileData?.total_rows ?? 0) - (fileData?.total_errors ?? 0)
        },
        {
            key: '3', label: t('Строк ошибочных'), children: fileData?.total_errors ?? 0
        },
    ]

    return <Col span={24}>
        <Row gutter={[8,8]}>
            <Col span={24}>
                <Card size={'small'} title={fileProcessing ? `${t('Файл обрабатывается')} (${fileData?.progress ?? 0}%)` : t("Файл обработан")} loading={fileProcessing}>
                    <Descriptions items={fileItems} />
                    <ButtonGroup>
                        <Button type={"default"} onClick={() => downloadErrors()} disabled={!fileData?.total_errors}>
                            {t('Скачать ошибочные строки')}
                        </Button>
                        <Popconfirm title={t('Вы уверены, что хотите подтвердить загрузку файла?')}
                                    onConfirm={() => confirm()}
                                    okText={t('Да')}
                                    cancelButtonProps={t('Нет')}
                                    loading={!!uploadConfirmedMeta}
                        >
                            <Button type={"primary"}>
                                {t('Подтвердить загрузку')}
                            </Button>
                        </Popconfirm>
                        <Button danger onClick={() => cancel()} loading={!!uploadConfirmedMeta}>
                            {t('Отменить загрузку')}
                        </Button>
                    </ButtonGroup>
                </Card>
            </Col>
        </Row>
    </Col>
}