import {createStore} from 'effector';


export const $bankAccountTypeList = createStore({data: [], count: 0})

export const $selectedBankAccountType = createStore(null)

export const $editBankAccountTypeStatus = createStore(false)

export const $createdBankAccountTypeId = createStore(null)
export const $afterCreateAction = createStore(null)