import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $editKnpStatus,
    $selectedKnp,
    getKnpByIdFx,
    KnpEditGate,
    submitEditKnpEv
} from "../../../models/dictionaryKnpModel/index.js";
import {InputNumber} from "antd";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryKnpModel/index.js";

export default function EditKnp() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedKnp)

    const [knpForm] = Form.useForm()

    const submit = useUnit(submitEditKnpEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const loading = useUnit(getKnpByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editKnpStatus)
    const genitive_form = t(`breadcrumbs.genitive.knp`);


    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/knp?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            knpForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'short_name_kz', 'short_name_ru'])

    return <>
        <KnpEditGate id={id}/>
        <Form form={knpForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Card loading={loading} title={t('Свойства КНП')} size={'small'}>
                <Row justify={'space-between'} gutter={[16,0]}>
                    <Col span={4}>
                        <Form.Item name={'id'}
                                   label={t(`Код ${genitive_form}`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}

                        >
                            <InputNumber style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Короткое Название ${genitive_form} (рус)`)}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={'short_name_kz'}
                                   label={t(`Короткое Название ${genitive_form} (каз)`)}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    knpForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            knpForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            knpForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
