import {createGate} from 'effector-react';
import {createEvent} from 'effector';


export const ServiceUnitsListGate = createGate()
export const ServiceUnitsViewGate = createGate()
export const ServiceUnitsEditGate = createGate()
export const ServiceUnitsCreateGate = createGate()

export const submitCreateServiceUnitsEv = createEvent()
export const submitEditServiceUnitsEv = createEvent()
export const deleteServiceUnitsEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()