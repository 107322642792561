import {
    $createdOrgTypeId,
    $selectedOrgType,
    $orgTypeEditStatus,
    $orgTypesList,
    $afterCreateOrgTypeAction
} from './stores.js';
import {
    getOrgTypeByIdFx,
    getOrgTypesListFx
} from './effects.js';
import {
    deleteOrgTypeEv,
    resetAfterCreationAttrs, setAfterCreateActionEv,
    OrgTypeEditGate,
    OrgTypesListGate,
    OrgTypeViewGate, submitCreateOrgTypeEv,
    submitEditOrgTypeEv, OrgTypeCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createOrgTypeCompleteEv = combineEvents({events: {ev: submitCreateOrgTypeEv, response: triggerSuccessOpsEv}, reset: OrgTypeCreateGate.state})
const editOrgTypeCompleteEv = combineEvents({ev: submitEditOrgTypeEv, response: triggerSuccessOpsEv})
const deleteOrgTypeCompleteEv = combineEvents({ev: deleteOrgTypeEv, response: triggerSuccessOpsEv})

$orgTypesList.on(getOrgTypesListFx.doneData, (_, stTypes) => stTypes)
    .reset(OrgTypesListGate.close)

$selectedOrgType.on(getOrgTypeByIdFx.doneData, (_, stTypes) => stTypes)
    .reset([OrgTypeEditGate.close, OrgTypeViewGate.close])

$createdOrgTypeId.on(createOrgTypeCompleteEv, (_, {response}) => {
    const stType = response.find(i => i.type === 'organization_type')
    return stType ? stType?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateOrgTypeAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$orgTypeEditStatus.on(editOrgTypeCompleteEv, () => true)
    .reset(OrgTypeEditGate.close)

sample({
    clock: OrgTypesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getOrgTypesListFx
})

sample({
    source: OrgTypesListGate.state,
    clock: [createOrgTypeCompleteEv, editOrgTypeCompleteEv, deleteOrgTypeCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getOrgTypesListFx
})

sample({
    clock: [OrgTypeEditGate.state, OrgTypeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getOrgTypeByIdFx
})

sample({
    clock: submitCreateOrgTypeEv,
    fn: payload => {
        const actions = []
        actions.push({operation: 'create', type: 'organization_type', value: {...payload, id: -1}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedOrgType,
    clock: submitEditOrgTypeEv,
    fn: (src, payload) => {
        const actions = []
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (src[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'organization_type', value: {object_id: src.id, ...changedFields}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteOrgTypeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'organization_type', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
