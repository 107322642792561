import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import {
    $passwordSettingsModal,
    $passwordSettingsModalData, $selectedPasswordSettings,
    closeModalEv, getPasswordSettingsByIdFx, PasswordSettingsEditGate, submitEditPasswordSettingsEv,
} from "../../../models/dictionaryPasswordSettingsModel/index.js";
import Row from "antd/es/row/index.js";
import Col from "antd/es/col/index.js";
import Card from "antd/es/card/index.js";
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import Form from "antd/es/form/index.js";
import Input from "antd/es/input/index.js";
import CreateTownEmbeddedForm from "../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx";
import CreateInspectorEmbeddedForm
    from "../../../components/Embedded/CreateInspectorEmbeddedForm/CreateInspectorEmbeddedForm.jsx";
import Switch from "antd/es/switch/index.js";


export default function EditPasswordSettingsModal() {
    const {t} = useTranslation()
    const [pwdStgForm] = Form.useForm()
    const open = useUnit($passwordSettingsModal)
    const onClose = useUnit(closeModalEv)
    const payload = useUnit($passwordSettingsModalData)
    const selected = useUnit($selectedPasswordSettings)
    const submit = useUnit(submitEditPasswordSettingsEv)
    const loading = useUnit(getPasswordSettingsByIdFx.pending)

    console.log('payload', payload)

    useEffect(() => {
        if (selected) {
            const data = {
                ...selected,
                value: selected.params.value
            }
            pwdStgForm.setFieldsValue(data)
        }
    }, [selected]);



    return <>
        <PasswordSettingsEditGate id={payload?.id}/>
        <Modal
            open={open}
            onCancel={onClose}
            footer={
                <>
                    <Button
                        type={'primary'}
                        onClick={() => pwdStgForm.submit()}
                    >
                        {t('Сохранить')}
                    </Button>
                    <Button onClick={onClose}>
                        Отмена
                    </Button>
                </>
            }
            title={`Редактирование параметра пароля: ${payload?.name_ru}`}
        >

            <Form form={pwdStgForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => submit(v)}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <Card size={'small'} loading={loading}>

                    <Row justify={'space-between'} gutter={[24, 24]}>
                        <Col span={12}>
                            <Form.Item name={'value'}
                                       label={t('Значение')}
                                       rules={[{required: false, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item name={'active'}
                                       label={t('Статус')}
                                       rules={[{required: false, message: t('Обязательное поле')}]}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>

                    </Row>
                </Card>

            </Form>
        </Modal>
    </>;
}
