import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const DistrictsListGate = createGate()
export const DistrictViewGate = createGate()
export const DistrictEditGate = createGate()
export const DistrictCreateGate = createGate()

export const submitCreateDistrictEv = createEvent()
export const submitEditDistrictEv = createEvent()
export const deleteDistrictEv = createEvent()

export const createDistStreetEv = createEvent()
export const editDistStreetEv = createEvent()
export const deleteDistStreetEv = createEvent()
export const resetDistStreetsEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()