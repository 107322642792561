import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {useEffect, useState} from "react";
import {
    CheckCircleOutlined, CloseCircleOutlined,
    DeleteOutlined, DownloadOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined, StopOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Popconfirm, Progress, Select} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import sortByUser from '../../utils/sortByUser.js';
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {
    $reportsList, deleteReportsEv, downloadReportEv,
    getReportsListFx,
    ReportsListGate, submitCreateReportsEv, sendMessageUploadEv, createReportFx
} from '../../models/reportsModel/index.js';
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import Form from "antd/es/form/index.js";
import Row from "antd/es/row/index.js";
import Col from "antd/es/col/index.js";
import Card from "antd/es/card/index.js";
import ReportComponentBankAndDate from "./components/ReportComponentBankAndDate.jsx";
import reportComponentDate from "./components/ReportComponentDate.jsx";
import ReportComponentDate from "./components/ReportComponentDate.jsx";
import ReportComponentBankAndRangeDate from "./components/ReportComponentBankAndRangeDate.jsx";
import ReportComponentBankAndMonthDate from "./components/ReportComponentBankAndMonthDate.jsx";
import ReportComponentBankAndDateAndFormat from "./components/ReportComponentBankAndDateAndFormat.jsx";
import ReportComponentAddress from "./components/ReportComponentAddress.jsx";
import ReportComponentProviderAndDate from "./components/ReportComponentProviderAndDate.jsx";
import ReportComponentMassProviderAndDate from "./components/ReportComponentMassProviderAndDate.jsx";
import ReportComponentBankAndProviderAndDate from "./components/ReportComponentBankAndProviderAndDate.jsx";
import {$WsSt} from "../../models/webSocketModel/index.js";
import {useWebSocket} from "../../api/WebSocketProvider.jsx";


const groupType = [
    {
        label: 'Отчеты по поставщикам',
        value: 'providers',
    },
    {
        label: 'Отчеты по банкам',
        value: 'banks',
    },
    {
        label: 'Общие отчеты',
        value: 'other',
    },
]

const subTypeDict = {
    '7011': '106Э Информация по платежам в электронном виде (7011 «Казахтелеком Акколь»)',
    '8006': '106Э Информация по платежам в электронном виде (8006 «Казахтелеком  Астана»)',
    '7002': '106Э Информация по платежам в электронном виде (7002 АРЭК -Энергосбыт)',
    '7010': '106Э Информация по платежам в электронном виде (7010 АРЭК-Акколь)',
    '8009': '106Э Информация по платежам в электронном виде (8009 АлмаТВ)',
    '106': '106 Ведомость распределения платежей по банкам (excel)',
    'days': '505А Реестр платежей по банкам за период',
    'month': '505А Реестр платежей по банкам за месяц',
    'total': '505А Реестр платежей по банкам итоговый',
}

const reportsSubType = {
    '106e': [
        {
            label: '106Э Информация по платежам в электронном виде (7011 «Казахтелеком Акколь»)',
            value: '7011'
        },
        {
            label: '106Э Информация по платежам в электронном виде (8006 «Казахтелеком  Астана»)',
            value: '8006'
        },
        {
            label: '106Э Информация по платежам в электронном виде (7002 АРЭК -Энергосбыт)',
            value: '7002'
        },
        {
            label: '106Э Информация по платежам в электронном виде (7010 АРЭК-Акколь)',
            value: '7010'
        },
        {
            label: '106Э Информация по платежам в электронном виде (8009 АлмаТВ)',
            value: '8009'
        },
        {
            label: '106 Ведомость распределения платежей по банкам (excel)',
            value: '106'
        },
    ],
    '505a': [
        {
            label: '505А Реестр платежей по банкам за период',
            value: 'days'
        },
        {
            label: '505А Реестр платежей по банкам за месяц',
            value: 'month'
        },
        {
            label: '505А Реестр платежей по банкам итоговый',
            value: 'total'
        },

    ],
}


const reportsType = {
    'providers': [
        {
            label: '106Э',
            value: '106e',
            subtype: true
        },
        {
            label: '500 Отчет по кондоминиумам',
            value: '500',
            subtype: false
        },
    ],
    'banks': [
        {
            label: '108Э  Электронные Платежные поручения для банков',
            value: '108e',
            subtype: false
        },
        {
            label: '505А Реестр платежей по банкам',
            value: '505a',
            subtype: true
        },

    ],
    'other': [
        {
            label: '668 Диапазон лицевых счетов по адресу',
            value: '668',
            subtype: false
        },
        {
            label: '701 Общая информация по лицевым счетам "Астана-ЕРЦ"',
            value: '701',
            subtype: true
        },
        {
            label: '702 Статистика по поставщикам услуг "Астана-ЕРЦ" ',
            value: '702',
            subtype: true
        },
        // {
        //     label: '106 Excel',
        //     value: '106',
        //     subtype: false
        // },

    ],
}


export default function ReportsList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($reportsList)
    const loading = useUnit(getReportsListFx.pending)
    const createReport = useUnit(submitCreateReportsEv)
    const createLoading = useUnit(createReportFx.pending)
    const [reportForm] = Form.useForm()
    const onDelete = useUnit(deleteReportsEv)
    const downloadReport = useUnit(downloadReportEv)
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.reports`)
    const downloadReports = null
    const typeSelected = Form.useWatch('type', reportForm)
    const subtypeSelected = Form.useWatch('subtype', reportForm)
    const groupSelected = Form.useWatch('group', reportForm)
    const streetSelected = Form.useWatch('street_id', reportForm)

    const { sendMessage } = useWebSocket();
    const wsStatus = useUnit($WsSt)

    useEffect(() => {
        const payload = {
            sendMessage: sendMessage,
            payload: {
                object_type: "report_701",
                action: 'get_list',
                subscribe: true
            }
        }
        sendMessageUploadEv(payload)

    }, [wsStatus]);

    const columns = [
        {
            title: t('Статус'),
            dataIndex: 'status',
            sorter: true,
            key: 'status',
            width: '10%',
            align: 'center',
            render: (status) => {
                return  status === 'processing' || status === 'new'
                    ? <div>
                        <Progress type="circle" size={40} />
                    </div>
                    : status === "processed" ||  status === 'completed' || status === 'success'
                        ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
                        : status === "stopped" ? <StopOutlined style={{color: 'red', fontSize: 24}}/>
                            : status === "error" ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                                : '-'

            }

        },
        {
            title: t('Тип отчета'),
            dataIndex: 'type',
            sorter: true,
            key: 'type',
            width: '10%'
        },
        {
            title: t('Подтип отчета'),
            dataIndex: 'subtype',
            sorter: true,
            key: 'subtype',
            width: '20%',
            render: (subtype) => {
                return subTypeDict[subtype]
            }
        },
        {
            title: t('Параметры'),
            dataIndex: 'filters',
            sorter: true,
            key: 'filters',
            width: '50%',
            render: (filters) => {
                return <div>
                    {
                        filters?.bank_id === null ? '' :
                            (
                                <>
                                    Банк: {' '}
                                    {filters.bank_id.map(item => (
                                        <MappedDictItem key={item} id={item} type="bank" />
                                    ))},
                                </>
                            )
                    }
                    {
                        filters?.provider_id === null ? '' :
                            (
                                <>
                                    Поставщик: {' '}
                                    {filters.provider_id.map(item => (
                                        <MappedDictItem key={item} id={item} type="providers" />
                                    ))},
                                </>
                            )
                    }
                    {
                        filters?.report_date !== null ?
                            ` Дата: ${filters?.report_date}, ` : ''
                    }
                    {
                        filters?.report_date_from !== null ?
                            ` Дата с: ${filters?.report_date_from}, ` : ''
                    }
                    {
                        filters?.report_date_to !== null ?
                            ` Дата по: ${filters?.report_date_to}, ` : ''
                    }

                    {
                        filters?.street_id === null ? '' :
                            (
                                <>
                                    Улица: {' '}
                                    <MappedDictItem key={filters.street_id} id={filters.street_id} type="street" />,
                                </>
                            )
                    }
                    {
                        filters?.house === null ? '' :
                            (
                                <>
                                    Дом: {filters.house}
                                </>
                            )
                    }

                </div>
            }
        },
        // {
        //     title: t('Статус формирования'),
        //     dataIndex: 'status',
        //     sorter: true,
        //     key: 'status',
        // },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',

            render: (id, row) => row.status === 'success' || row.status === 'error' ?
                <ButtonGroup>
                <Tooltip title={t('Скачать')} placement={'top'}>
                        <Button icon={<DownloadOutlined />}
                                type={'primary'}
                                onClick={() => downloadReport(row.path)}
                        />
                </Tooltip>
            </ButtonGroup> : ''
        }
    ]

    const filters = [
        {name: 'group', label: t('Группа отчетов'), type: 'select', options: groupType, object: 'reports_group'},
        {name: 'type', label: t('Тип отчета'), type: 'select', options: reportsType, object: 'reports_type'},
        {name: 'subtype', label: t('Подтип отчета'), type: 'select', options: reportsSubType, object: 'reports_subtype'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
    ]




    const onSubmit = (value) => {
        const payload = {...value}
        if (payload.report_date === undefined) {
            payload.report_date = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
        } else {
            if (subtypeSelected === 'days') {
                payload.report_date_from = dayjs(payload.report_date[0]).format('YYYY-MM-DD')
                payload.report_date_to = dayjs(payload.report_date[1]).format('YYYY-MM-DD')
                delete payload.report_date
            } else {
                payload.report_date = dayjs(value.report_date).format('YYYY-MM-DD')
            }
        }
        if (payload.bank_id) {
            if (Array.isArray(payload.bank_id) === false) {
                payload.bank_id = [value.bank_id]
            }
        }
        if (
            value.subtype === '7002' ||
            value.subtype === '7010' ||
            value.subtype === '8009' ||
            value.subtype === '7011' ||
            value.subtype === '8006'
        ) {
            payload.format = 'dbf'
        } else {
            payload.format = value.format ? value.format : 'xlsx'
        }
        if (value.type === '702' || value.type === '701') {
            payload.report_date = value.report_date === undefined ? dayjs().subtract(1, 'month').format('YYYY-MM-DD') :
                dayjs(value.report_date).format('YYYY-MM-DD')
        } else if (value.type === '668') {
            payload.report_date = undefined
        }

        // if (value)

        createReport(payload)
        reportForm.resetFields()
    }



    return <>
        <ReportsListGate search={search}/>

        <Form form={reportForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => onSubmit(v)}
              validateTrigger={'onBlur'}
            // onValuesChange={(_, all) => check(all)}
        >
            <Card
                title={t('Создать отчет')}
                size={'small'}
            >
                <Row gutter={[24, 24]} style={{width: '100%'}}>
                    <Col span={4}>
                        <Form.Item name={'group'}
                                   label={t('Группа отчетов')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={groupType}
                                showSearch
                                onChange={() => {
                                    reportForm.setFieldValue('type', [])
                                    reportForm.setFieldValue('subtype', [])
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'type'}
                                   label={t('Тип отчета')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={reportsType[groupSelected]}
                                showSearch
                                onChange={() => {
                                    reportForm.setFieldValue('subtype', [])
                                }}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    {
                        (typeSelected === '106e' || typeSelected === '505a') && <Col span={8}>
                            <Form.Item name={'subtype'}
                                       label={t('Подтип отчета')}
                                       rules={[{required: false, message: t('Обязательное поле')}]}
                            >
                                <Select
                                    options={reportsSubType[typeSelected]}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Row gutter={[24, 24]} style={{width: '100%'}}>
                    {
                        (
                            subtypeSelected === '7002' ||
                            subtypeSelected === '7010'
                        ) && <ReportComponentBankAndDate/>
                    }
                    {
                        (
                            subtypeSelected === '106'
                        ) && <ReportComponentBankAndProviderAndDate form={reportForm} />
                    }
                    {
                        (
                            subtypeSelected === '7011' ||
                            subtypeSelected === '8006' ||
                            subtypeSelected === '8009'
                        ) && <ReportComponentDate/>
                    }
                    {
                        (
                            subtypeSelected === 'days'
                        ) && <ReportComponentBankAndRangeDate />
                    }
                    {
                        (
                            subtypeSelected === 'month' ||
                            subtypeSelected === 'total'
                        ) && <ReportComponentBankAndMonthDate />
                    }
                    {
                        (
                            typeSelected === '108e'
                        ) && <ReportComponentBankAndDateAndFormat />
                    }
                    {
                        (
                            typeSelected === '668'
                        ) && <ReportComponentAddress form={reportForm} />
                    }
                    {
                        (
                            typeSelected === '701' ||
                            typeSelected === '702'
                        ) && <ReportComponentProviderAndDate form={reportForm} />
                    }
                    {
                        (
                            typeSelected === '500'
                        ) && <ReportComponentMassProviderAndDate form={reportForm} />
                    }
                </Row>
                <Row>
                    <Button
                        type={'primary'}
                        size={'small'}
                        loading={createLoading}
                        onClick={() => reportForm.submit()}
                        disabled={typeSelected === '668' ? streetSelected === undefined ? true : false : false}
                    >
                        Создать
                    </Button>
                </Row>
            </Card>
        </Form>

        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            showActions={false}
            exportXlsAction={exportXlsAction}
        />
    </>
}
