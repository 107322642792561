import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import './style.css'
import {
    $InspectorsChildren,
    $createdInspectors,
    deleteNewInspectorsEv,
    createChildInspectorsEv,
    editChildInspectorsEv,
    setCreateEditChildInspectorsEmbeddedEv,
    $InspectorsEmbeddedFilters,
    changeInspectorsChildrenFiltersEv,
    deleteChildInspectorsEv,
    InspectorsChildrenGate,
    $deletedInspectors,
} from '../../../models/dictionaryEmbeddedFormsModel';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import {CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group.js';
import Popconfirm from 'antd/es/popconfirm';
import MappedDictItem from "../../MappedDictItem/MappedDictItem.jsx";
import Table from 'antd/es/table';
import Divider from 'antd/es/divider';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import { getOneInspectorChildrenFx} from '../../../models/dictionaryEmbeddedFormsModel/effects.js';
import {Link} from "react-router-dom";
import Form from "antd/es/form/index.js";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel/index.js";
import CreateEditInspectorsEmbeddedForm from "./CreateEditInspectorsEmbedded.jsx";

export default function InspectorsTable({view, parent, parentId}) {
    const {t} = useTranslation()
    const {data, count} = useUnit($InspectorsChildren)
    const loading = useUnit(getOneInspectorChildrenFx.pending)
    const newInspectors = useUnit($createdInspectors)


    const [inspectorsForm] = Form.useForm()
    const formData = inspectorsForm.getFieldsValue()
    const deleteExisting = useUnit(deleteChildInspectorsEv)
    const deleteNew = useUnit(deleteNewInspectorsEv)

    const onCreate = useUnit(createChildInspectorsEv)
    const onEdit = useUnit(editChildInspectorsEv)


    const setModal = useUnit(setCreateEditChildInspectorsEmbeddedEv)

    const {page, limit, sortField, sortOrder} = useUnit($InspectorsEmbeddedFilters)
    const changeFilters = useUnit(changeInspectorsChildrenFiltersEv)
    const genitive_form = t(`breadcrumbs.genitive.inspector`);

    const dict =  useStoreMap($dictsSelects, maps => maps.inspector)



    const lang = localStorage.getItem('lang') ?? 'ru'

    const rowClassName = (record, index) => {
        const checkNew = Math.sign(record.id) === 1 ? false : true
        if (checkNew === true) {
            return 'custom-new-row'
        } else if (record?.changedFields) {
            return 'custom-edit-old-row'
        } else if (record.new_item) {
            return 'custom-new-select-row'
        } else {
            return 'custom-normal-row'
        }
    };


    const columns = [
        // {
        //     title: t('ФИО контролера'),
        //     dataIndex: 'name_kz',
        //     width: '5%',
        //     align: 'center',
        //     sorter: true,
        //     key: 'name_kz',
        //     render: (name_kz, row) => {
        //         return row.id === undefined  ?  '-' : <MappedDictItem id={row.id} type={'inspector'}/>
        //     }
        // },
        {
            title: t('ФИО контролера'),
            dataIndex: 'name_ru',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'name_ru',
            render: (name_ru, row) => {
                return row.id === undefined  ?  '-' : <Link to={`/dictionaries/inspectors/view/${row.id}`}>{`${name_ru}`}</Link>
            }
        },
        {
            title: t('Участок'),
            dataIndex: 'sector_ids',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'sector_ids',
            render: (sector_ids, row) => {
                return sector_ids?.length > 0 ? <Link to={`/dictionaries/sector/view/${sector_ids[0]}`}>{`${sector_ids[0]}`}</Link> : '-'
            }
        },


        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined/>} onClick={() => setModal({type: 'create'})} type={'primary'}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            hidden: view,
            key: 'actions',
            render: (id, row) => {
                if (row.new_item === true) {
                    return <ButtonGroup>
                        <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                    okText={t('Да')}
                                    onConfirm={
                                        () => {
                                            if (Math.sign(row.id) === 1) {
                                                return deleteExisting(id)
                                            } else {
                                                return deleteNew(id)
                                            }
                                        }
                                    }
                                    placement={'left'}
                        >
                            <Tooltip title={t('Удалить')}>
                                <Button
                                    icon={<CloseOutlined />}
                                    type={'primary'}
                                    danger
                                />
                            </Tooltip>
                        </Popconfirm>
                    </ButtonGroup>
                }
            }
        }
    ]


    if (view) {
        const idx = columns.findIndex(i => i.key === 'actions')
        if (idx !== -1) {
            columns.splice(idx, 1)
        }
    }



    return <>
    <InspectorsChildrenGate parent={parent} parentId={+parentId} />
        <CreateEditInspectorsEmbeddedForm onCreate={onCreate} onEdit={onEdit}/>
        <Divider> Назначенные контролеры </Divider>

        <Table dataSource={[...data, ...newInspectors]}
               columns={columns}
               loading={loading}
               bordered
               size={'small'}
               rowClassName={rowClassName}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(page),
                   total: count
               }}
               onChange={({current, pageSize}, filters, {field, order}) => {
                   const payload = []
                   if (current !== page || pageSize !== limit) {
                       payload.push({key: 'page', value: current})
                       payload.push({key: 'limit', value: pageSize})
                   }

                   if (field !== sortField || order !== sortOrder) {
                       if (!order) {
                           payload.push({key: 'sort_field', value: null})
                           payload.push({key: 'sort_order', value: null})
                       } else {
                           payload.push({key: 'sort_field', value: field})
                           payload.push({
                               key: 'sort_order',
                               value: order === 'ascend' ? 'asc' : 'desc'
                           })
                       }
                   }
                   changeFilters(payload)
               }}
               style={{width: '100%'}}
        />
    </>
}
