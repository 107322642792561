import {createStore} from 'effector';


export const $activityTypeList = createStore({data: [], count: 0})

export const $selectedActivityType = createStore(null)

export const $editActivityTypeStatus = createStore(false)

export const $createdActivityTypeId = createStore(null)
export const $afterCreateAction = createStore(null)
