import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedFile,
    $selectedFileCells, acceptFileEv, downloadErrorFileEv,
    getFileCellsFx, rejectFileEv, ViewFileCellsGate
} from '../../../models/uploadProviderAccountsModel';
// import {usePaginationControl} from '../../../hooks/usePaginationControl.js';
// import {useServerSort} from '../../../hooks/useServerSort.js';
import dayjs from 'dayjs';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import styles from '../Upload.module.css';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import React, {useState} from 'react';
import {Popconfirm, Switch} from 'antd';
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames} from "../../../utils/monthNamesDict.js";
import Collapse from "antd/es/collapse";
import TextArea from "antd/es/input/TextArea";
import {useServerSortPage} from '../../../hooks/useServerSortPage.js';
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";

export default function UploadProviderAccountsViewFileCells() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const selectedFile = useUnit($selectedFile)
    const {data, count} = useUnit($selectedFileCells)
    const loading = useUnit(getFileCellsFx.pending)
    const accept = useUnit(acceptFileEv)
    const reject = useUnit(rejectFileEv)

    const downloadErrorFile = useUnit(downloadErrorFileEv)

    const {id} = useParams()
    const [search, setSearch] = useSearchParams()

    const [curPage, limit, _field, _order, onChangeTable] = useServerSortPage()

    const [valid, setValid] = useState(!!(search.get('validation') && search.get('validation')==='true'))
    const onValidChange = (checked) => {
        setValid(!!checked)
        search.set('validation', checked)
        setSearch(search)
    }

    const confirmDisabled = !(
        selectedFile?.status === 'file processed' && selectedFile?.is_approved === null
    )

    const downloadErrors = selectedFile?.error_row_count > 0

    const textError = (data) => {
        const e = data || ''
        const se = e.split('|')
        const te = se.shift()
        const ie = se.join(', ')

        const error = t(`import.errors.${te}`)
        return <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
    }

    const columns = [
        {
            title: t('№ строки'),
            dataIndex: 'row_index',
            align: 'center',
            key: 'row_index',
            sorter: true,
        },
        {
            title: t('Статус'),
            dataIndex: 'row_is_valid',
            align: 'center',
            key: 'valid',
            sorter: true,
            render: (valid) => valid ? <CheckOutlined/> : <CloseOutlined style={{color: 'red'}}/>
        },
        // {
        //     title: t('Код ЕПД'),
        //     dataIndex: 'epd_id_value',
        //     key: 'epd_id_value',
        //     sorter: true,
        //     onCell: (record) => ({className: record?.epd_id_error && styles.invalid}),
        //     render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
        //         <span>{v}</span>
        //         {record?.epd_id_error && textError(record?.epd_id_error)}
        //     </div>
        // },
        {
            title: t('№ ЛС'),
            dataIndex: 'account_id_value',
            key: 'account_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.account_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.account_id_error && textError(record?.account_id_error)}
            </div>
        },
        {
            title: t('Номер договора поставщика с потребителем'),
            dataIndex: 'external_number_value',
            key: 'external_number_value',
            sorter: true,
            onCell: (record) => ({className: record?.external_number_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.external_number_error &&
                    textError(record?.external_number_error)}
            </div>
        },
        {
            title: t('ФИО потребятеля по данным поставщика'),
            dataIndex: 'name_value',
            key: 'name_value',
            sorter: true,
            onCell: (record) => ({className: record?.name_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.name_error &&
                    textError(record?.name_error)}
            </div>
        },
        {
            title: t('Код улицы по классификатору поставщика'),
            dataIndex: 'street_id_value',
            key: 'street_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.street_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.street_id_error && textError(record?.street_id_error)}
            </div>
        },
        {
            title: t('Название улицы по данным поставщика'),
            dataIndex: 'street_name_value',
            key: 'street_name_value',
            sorter: true,
            onCell: (record) => ({className: record?.street_name_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>

                {record?.street_name_error && textError(record?.street_name_error)}
            </div>
        },
        {
            title: t('Номер дома по данным поставщика'),
            dataIndex: 'house_value',
            key: 'house_value',
            sorter: true,
            onCell: (record) => ({className: record?.house_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.house_error && textError(record?.house_error)}
            </div>
        },
        {
            title: t('Номер квартиры по данным поставщика'),
            dataIndex: 'flat_value',
            key: 'flat_value',
            sorter: true,
            onCell: (record) => ({className: record?.flat_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.flat_error && textError(record?.flat_error)}
            </div>
        },
    ]

    const items = [
        {
            key: 'provider_id',
            label: t('Поставщик'),
            // children: <CreatedBy id={selectedFile?.provider_id}/>
            children: <MappedDictItem id={selectedFile?.params?.provider_id} type={'providers'}/>
        },
        {
            key: 'total_row_count',
            label: t('Всего строк'),
            children: selectedFile?.total_row_count
        },
        {
            key: 'error_row_count',
            label: t('Ошибочных строк'),
            children: selectedFile?.error_row_count
        },
        // {
        //     key: 'file_extention',
        //     label: t('Расширение'),
        //     children: selectedFile?.file_extention
        // },
        // {
        //     key: 'file_type',
        //     label: t('Тип'),
        //     children: `${selectedFile?.file_type ?? '-'} ${t('столбцов')}`,
        // },
        {
            key: 'report_date',
            label: t('Месяц'),
            children: selectedFile?.params.current_month ? t(monthNames[new Date(selectedFile?.params.current_month).getMonth() + 1]) : '-'
        }
    ]

    const dateItems = [
        {
            key: 'load_date',
            label: t('Дата загрузки'),
            children: selectedFile?.load_date && dayjs(selectedFile?.load_date).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'to_list',
            children: <Button
                onClick={() => navigate("../..", {relative: "path", replace: true})}
            >{t('К списку')}</Button>
        },
        {
            key: 'confirm',
            children: <Popconfirm
                title={t('Вы уверены, что хотите подтвердить этот файл?')}
                onConfirm={() => {
                    accept(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
            >
                <Button type={'primary'}
                        disabled={confirmDisabled}>{t('Подтвердить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'cancel',
            children: <Popconfirm
                title={t('Вы уверены, что хотите отклонить этот файл?')}
                onConfirm={() => {
                    reject(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
                okButtonProps={{danger: true}}
            >
                <Button danger disabled={confirmDisabled}>{t('Отклонить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'downloadErrors',
            children: <Button
                disabled={!downloadErrors}
                onClick={() => downloadErrorFile(id)}
            >{t('Скачать файл с ошибками')}</Button>
        },
    ]

    return <Card title={`${t('Просмотр строк файла')} ${selectedFile?.file_name}`}>
        <ViewFileCellsGate id={id} search={search}/>
        <ViewDictionaryItemCommon showNav={false} items={items} itemsDates={dateItems}/>
        <Divider/>
        {selectedFile?.load_error ? <Collapse
            size="small"
            items={[
                {
                    key: '1',
                    label: t('Лог ошибок обработки файла'),
                    children: <TextArea rows={40} value={selectedFile?.load_error} />,
                },
            ]}
        /> : null}
        <Divider/>
        <div style={{display: 'flex', gap: 4, marginBottom: 24}}>
            <Switch checked={valid} onChange={onValidChange} checkedChildren={'Валидные'} unCheckedChildren={'Ошибочные'} />
        </div>
        <Table dataSource={data}
               loading={loading}
               columns={columns}
               onChange={(pagination, _filters, sorter) => {
                   onChangeTable({page: pagination?.current, limit: pagination?.pageSize, sort_field: sorter?.field ?? null, sort_order: sorter?.order ?? null})
               }}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(curPage),
                   total: count
               }}
               size={'small'}
               className={'table-container'}
        />
    </Card>
}
