import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Select from 'antd/es/select';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';

import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    BanksCreateGate,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, submitCreateBanksEv,
    $createdBanksId
} from '../../../models/dictionaryBanksModel/index.js';
import {validateBIN} from '../../../utils/validateBIN.js';
import {$dictsSelects, $info} from "../../../models/infoModel/index.js";
import {InputNumber, Switch} from "antd";

export default function CreateBanks() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [banksForm] = Form.useForm()

    const submit = useUnit(submitCreateBanksEv)

    const loading = useUnit(universalDictEndpointFx.pending)

    const branch_all = useStoreMap($info, maps => maps.bank).map(item => item.branch)


    const createdId = useUnit($createdBanksId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.banks`);

    const bankCommissionOpts = useStoreMap($dictsSelects, sel => sel.bank_commission)


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/banks/edit/${createdId}`)
            } else if (action === 'new') {
                banksForm.resetFields()
            } else if (action === 'list') {
                banksForm.resetFields()
                navigate(`/dictionaries/banks?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <BanksCreateGate/>
        <Form form={banksForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Row gutter={[16, 0]} justify={'space-between'} >
                <Col span={12}>
                    <Card title={t('Свойства банка')} size={'small'} style={{height: '100%'}}>
                        <Row gutter={[16, 0]}>
                            <Col span={12}>
                                <Form.Item name={'name_ru'}
                                           label={t(`Название ${genitive_form} (рус)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'name_kz'}
                                           label={t(`Название ${genitive_form} (каз)`)}
                                           rules={[{
                                               required: true,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'bik'}
                                           label={t('БИК')}
                                           validateTrigger={['onBlur']}
                                           rules={[
                                               {required: true, message: t('Обязательное поле')},
                                               {
                                                   pattern: /^[A-Z]{8}$/,
                                                   message: 'Только заглавные латинские буквы, 8 букв',
                                               },
                                           ]}
                                >
                                    <Input maxLength={8} showCount/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'bank_bin_iin'}
                                           label={t('БИН')}
                                           validateTrigger={['onBlur']}
                                           rules={[
                                               {required: true, message: t('Обязательное поле')},
                                               {
                                                   validator: async (rule, value) => {
                                                       if (value && (value.length !== 12 || /^\d+$/.test(value) === false)) {
                                                           return Promise.reject(new Error(t('БИН должен состоять из 12 цифр')));
                                                       } else if (value
                                                           && value.length === 12
                                                           && value.split('').every(i => Number.isInteger(+i))
                                                           && !validateBIN(value)
                                                       ) {
                                                           return Promise.reject(new Error(t('БИН недействителен')));
                                                       } else if (value
                                                           && value.length === 12
                                                           && value.split('').every(i => Number.isInteger(+i))
                                                           && validateBIN(value)
                                                       ) {
                                                           return Promise.resolve();
                                                       }
                                                   }
                                               }
                                           ]}
                                >
                                    <Input showCount maxLength={12}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'branch'}
                                           label={t('Отделение')}
                                           validateTrigger={['onBlur']}
                                           rules={[
                                               {required: true, message: t('Обязательное поле')},
                                               {
                                                   validator: async (rule, value) => {
                                                       const check = branch_all?.find(item => item === parseInt(value)) === undefined  ? false : true
                                                       if (check === true) {
                                                           return Promise.reject(new Error(t('Отделение уже занято')));
                                                       } {
                                                           return Promise.resolve();
                                                       }
                                                   }
                                               }
                                           ]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={t('Договор с ЕРЦ')} size={'small'} style={{height: '100%'}}>
                        <Row gutter={[16, 0]}>
                            <Col span={5}>
                                <Form.Item name={'is_contract_signed'}
                                           label={t('Договор с ЕРЦ')}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Switch/>
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <Form.Item name={'agreement'}
                                           label={t('Номер и дата договора')}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name={'bank_commission_id'}
                                           label={t('Комиссия банка')}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Select
                                        options={bankCommissionOpts}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }

                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name={'address'}
                                           label={t('Адрес')}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'chief_contacts'}
                                           label={t('Контакты руководителя')}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'accountant_contacts'}
                                           label={t('Контакты бухгалтера')}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    banksForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            banksForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующий')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            banksForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            banksForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
