import {
    $afterCreateAction,
    $createdUsersId,
    $editUsersStatus,
    $selectedUsers, $userProviderList,
    $usersList, $usrPrvModal, $usrPrvModalData,
     $userCreateErrors,
} from './stores.js';
import {getUsersListFx, getUsersByIdFx, createUserFx, updateUserFx, getUserProviderFx} from './effects.js';
import {
    deleteUsersEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    UsersEditGate,
    UsersListGate,
    UsersViewGate,
    submitCreateUsersEv,
    submitEditUsersEv,
    UsersCreateGate,
    openUsrPrvModalEv,
    closeUsrPrvModalEv,
    dataUsrPrvModalEv,
    UsersProviderGate,
    deleteUsrPrvEv, addUsrPrvEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {resetCreateInspectorEmbeddedEv, resetCreateTownEmbeddedEv} from "../dictionaryEmbeddedFormsModel/index.js";
import {$createdRolePermissionId} from "../dictionaryRolePermissionModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateUsersEv, response: triggerSuccessOpsEv}, reset: UsersCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditUsersEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteUsersEv, response: triggerSuccessOpsEv})

$usersList.on(getUsersListFx.doneData, (_, data) => data)
    .reset(UsersListGate.close)

$userProviderList.on(getUserProviderFx.doneData, (_, data) => data)
    .reset(UsersProviderGate.close)
    .reset(closeUsrPrvModalEv)

$selectedUsers.on(getUsersByIdFx.doneData, (_, data) => data)
    .reset([UsersEditGate.close, UsersViewGate.close])

$createdUsersId.on(createUserFx.doneData, (_, response) => {
    return response.data
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editUsersStatus.on(updateUserFx.doneData, () => true)
    .reset(UsersEditGate.close)

$usrPrvModal.on(openUsrPrvModalEv, () => true)
    .on(closeUsrPrvModalEv, () => false)
    .reset(UsersEditGate.close)

$usrPrvModalData.on(dataUsrPrvModalEv, (_, payload) => payload)
    .reset(UsersEditGate.close)




$userCreateErrors
    .on(createUserFx.failData, (_, res) => {
        if (res?.response?.status === 422 && Array.isArray(res?.response?.data?.detail)) {
            return res?.response?.data?.detail?.map(i => Object.values(i)[0]) ?? []
        }
    })
    .on(updateUserFx.failData, (_, res) => {
        if (res?.response?.status === 422 && Array.isArray(res?.response?.data?.detail)) {
            return res?.response?.data?.detail?.map(i => Object.values(i)[0]) ?? []
        }
    })
    .reset([createUserFx.doneData, updateUserFx.doneData, UsersCreateGate.close, UsersEditGate.close])

sample({
    clock: [UsersEditGate.state, UsersViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getUsersByIdFx
})




sample({
    source: UsersProviderGate.state,
    clock: [UsersProviderGate.state, triggerSuccessOpsEv],
    filter: (gate) => {
        if (gate?.id !== undefined) return true
    },
    fn: (gate) => gate.id,
    target: getUserProviderFx
})

// sample({
//     source: UsersProviderGate.state,
//     clock: [submitGlobalUpdateEv],
//     filter: (gate) => {
//         if (gate?.id !== undefined) return true
//     },
//     fn: (gate) => gate.id,
//     target: getUserProviderFx
// })

sample({
    clock: UsersListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getUsersListFx
})

sample({
    source: UsersListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getUsersListFx
})

sample({
    clock: submitCreateUsersEv,
    fn: (payload) => {
        // debugger
        const data = {
            ...payload,
            name_kz: payload.name_ru,
            is_active: payload.is_active ? payload.is_active : true
        }
        delete data.confirm_password

        return data
    },
    target: createUserFx
})

sample({
    source: $selectedUsers,
    clock: submitEditUsersEv,
    fn: (current, payload) => {
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        // debugger
        const data = {
            id: current.id,
            payload: changedFields
        }
        if (data.payload.password_new) {
            data.payload.password = data.payload.password_new
            delete data.payload.password_new
            delete data.payload.confirm_password
        }
        return data
    },
    target: updateUserFx
})

sample({
    clock: deleteUsersEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'users', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteUsrPrvEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'user_provider', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})


sample({
    clock: addUsrPrvEv,
    fn: (payload) => {
        const actions = []
        actions.push({operation: 'create', type: 'user_provider', value: {...payload, id: -1}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [UsersCreateGate.close, UsersEditGate.close],
    target: [resetCreateTownEmbeddedEv, resetCreateInspectorEmbeddedEv]
})
