import {createStore} from 'effector';
import {getRoleListFx} from "./effects.js";


export const $roleList = createStore({data: [], count: 0})

export const $selectedRole = createStore(null)

export const $editRoleStatus = createStore(false)

export const $createdRoleId = createStore(null)
export const $afterCreateAction = createStore(null)
