import {createStore} from 'effector';

export const $selectedEntities = createStore({
    town_id: null,
    town_distr_id: null,
    street_id: null,
    house: null,
    corpus: null,
    house_from: null,
    house_to: null
})

export const $townsSelect = createStore([])
export const $districtsSelect = createStore([])
export const $streetsSelect = createStore([])
export const $houseSelect = createStore([])
export const $corpusSelect = createStore([])

export const $addressList = createStore({data: [], count: 0})
export const $selectedAddress = createStore(null)
export const $selectedRows = createStore([])
export const $addressNoLimit = createStore([])
export const $RESFormData = createStore([])
export const $generateType = createStore(null)

export const $openCreateAddress = createStore(false)
export const $createdAddress = createStore(null)
export const $viewTable = createStore(false)
export const $addressPagination = createStore({
    defaultPageSize: 10,
    defaultCurrent: 1,
    pageSizeOptions: [10, 20, 100],
    showSizeChanger: true,
    position: ['bottomCenter'],
    limit: 10,
    page: 1
})

