import {createGate} from 'effector-react';
import {createEvent} from "effector";

export const AccountsListGate = createGate()


export const AccountsWsOpen = createEvent()
export const WsOpen = createEvent()
export const sendMsgEv = createEvent()

