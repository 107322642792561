import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $providerAgreements,
    $selectedAgreement,
    addProvAgreementEv, applyProvAgreementEv,
    deleteProvAgreementEv, downloadProvAgreementFileEv,
    selectProvAgreementEv,
} from '../../../models/dictionaryProvidersModel/index.js';
import Card from 'antd/es/card';
import Table from 'antd/es/table';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import {
    CheckCircleOutlined,
    CloseCircleOutlined, DeleteOutlined, DownloadOutlined, EditOutlined,
    PlusOutlined
} from '@ant-design/icons';
import Switch from 'antd/es/switch';
import {useEffect, useState} from 'react';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import CustomDates from '../../../components/CustomDates/CustomDates.jsx';
import DatePicker from 'antd/es/date-picker';
import UploadComponent from '../../../components/UploadComponent/UploadComponent.jsx';

export default function ProviderAgreements({isView}) {
    const {t} = useTranslation()

    const [agreementsForm] = Form.useForm()

    const data = useUnit($providerAgreements)

    const selectedAgreement = useUnit($selectedAgreement)

    const addAgreement = useUnit(addProvAgreementEv)
    const selectAgreement = useUnit(selectProvAgreementEv)
    const changeAgreement = useUnit(applyProvAgreementEv)
    const deleteAgreement = useUnit(deleteProvAgreementEv)
    const downloadFile = useUnit(downloadProvAgreementFileEv)

    const [add, setAdd] = useState(false)

    useEffect(() => {
        if (selectedAgreement) {
            setAdd(true)
            const prep = {...selectedAgreement}
            for (const [key, value] of Object.entries(prep)) {
                if (key === 'date_begin' || key === 'date_end') {
                    prep[key] = value ? dayjs(value) : null
                }
            }
            agreementsForm.setFieldsValue(prep)
        }
    }, [selectedAgreement]);

    const onSubmit = (values) => {
        if (selectedAgreement) {
            changeAgreement({id: selectedAgreement.id, ...values})
        } else {
            addAgreement(values)
        }
        agreementsForm.resetFields()
        setAdd(false)
    }

    const onCancelAdd = () => {
        agreementsForm.resetFields()
        setAdd(false)
        selectAgreement(null)
    }

    const columns = [
        {
            title: t('Номер'),
            dataIndex: 'number',
            key: 'number'
        },
        {
            title: t('Действующий'),
            dataIndex: 'is_active',
            key: 'is_active',
            align: 'center',
            width: '5%',
            render: (is_active) => is_active ? <CheckCircleOutlined /> : <CloseCircleOutlined />
        },
        {
            title: t('Начало действия'),
            dataIndex: 'date_begin',
            key: 'date_begin',
            render: (date) => <CustomDates data={date}/>
        },
        {
            title: t('Окончание действия'),
            dataIndex: 'date_end',
            key: 'date_end',
            render: (date) => <CustomDates data={date}/>
        },
        {
            title: isView ? null : <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined />} type={'primary'} onClick={() => setAdd(true)} />
            </Tooltip>,
            width: '5%',
            key: 'actions',
            dataIndex: 'id',
            align: 'center',
            render: (id) => <ButtonGroup>
                {id >= 0 && <Tooltip title={t('Скачать')}>
                    <Button onClick={() => downloadFile(id)} icon={<DownloadOutlined/>}/>
                </Tooltip>}
                {!isView && <Tooltip title={t('Редактировать')}>
                    <Button onClick={() => selectAgreement(id)} icon={<EditOutlined/>}/>
                </Tooltip>}
                {!isView && <Popconfirm title={t('Вы уверены, что хотите удалить эту запись')}
                             okText={t('Да')}
                             onConfirm={() => deleteAgreement(id)}
                             placement={'left'}
                >
                    <Tooltip title={t('Удалить')}>
                        <Button danger icon={<DeleteOutlined/>} />
                    </Tooltip>
                </Popconfirm>}
            </ButtonGroup>
        }
    ]

    return <Card title={t('Договора')} size={'small'} style={{minHeight: '100%'}}>
        {add && <Form layout={'vertical'} form={agreementsForm} onFinish={onSubmit} size={'small'}>
            <Row gutter={[16, 16]} style={{alignItems: 'flex-end'}}>
                <Col span={8}>
                    <Form.Item name={'number'} label={t('Номер договора')}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'date_begin'} label={t('Дата начала действия')}
                               rules={[{required: true, message: t('Обязательное поле')}]}>
                        <DatePicker style={{width: '100%'}} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={'date_end'} label={t('Дата окончания действия')}
                               rules={[{required: false, message: t('Обязательное поле')}]}>
                        <DatePicker style={{width: '100%'}} />
                    </Form.Item>
                </Col>
                </Row>
                <Row gutter={[16, 16]} style={{alignItems: 'flex-end'}}>
                <Col span={8}>
                    <Form.Item name={'is_active'} label={t('Действующий')}
                               valuePropName={'checked'}>
                        <Switch/>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <UploadComponent title={t('Файл')} size={'small'} isForm={true} formName={'file'}/>
                </Col>
                <Col span={2}>
                    <Form.Item>
                        <ButtonGroup>
                            <Button type={'primary'}
                                    onClick={() => agreementsForm.submit()}>{selectedAgreement ? t('Сохранить') : t('Добавить')}</Button>
                            <Button danger onClick={onCancelAdd}>{t('Отмена')}</Button>
                        </ButtonGroup>
                    </Form.Item>
                </Col>
            </Row>
        </Form>}
        <Table
            dataSource={data} columns={columns} bordered pagination={false} size={'small'}
            rowKey={(r) => `${r.id}:${r.number}:${r.date_begin}:${r.date_end}:${r.is_active}`}
        />
    </Card>
}
