import Space from 'antd/es/space';
import {LoadingOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {$WsSt} from '../../models/webSocketModel/index.js';

export default function InfoLoading() {
    const {t} = useTranslation()
    const socketReady = useUnit($WsSt)

    return <div style={{
        height: '100vh',
        width: '100vw',
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#001529',
        color: 'white'
    }}>
        <Space direction={'vertical'} align={'center'}>
            <LoadingOutlined spin={true} style={{fontSize: 48}}/>
            {socketReady ? <p>{t('Загрузка данных')}</p> : <p>{t('Ожидание соединения')}</p>}
        </Space>
    </div>
}