import {sample} from 'effector';
import {
    $contollersAll,
    $controllersList, $createdTask,
    $filters, $selectedByIdFile,
    $selectedControllers, $taskCreated, $uploadedFilesData, $uploadFileModal, $uploadStatus,
} from './stores.js';
import {
    acceptMessageUploadEmailEv,
    applyFiltersEv, approveFileEv,
    changeFiltersEv,
    changeSelectedControllersEv,
    createTaskEv, disaproveFileEv,
    downloadFilesPerControllersEv,
    downloadSingleFileEv,
    InspectorFilesGate,
    resetFiltersEv,
    resetTaskCreatedEv, resetUploadFileModalEv, setUploadFileModalEv, stopConfirmTaskEv, stopImportTaskEv,
    TaskPdfToContsGate, UploadedFilesGate, uploadFileEv, ViewFilesGate
} from './events.js';
import {
    approveFileFx,
    createTaskFx, disapproveFileFx,
    downloadFilesPerControllersFx, downloadSingleFileFx,
    getControllersFx, getFileListFx, uploadFileFx, getFileInfoFx, stopImportTaskFx, stopConfirmTaskFx
} from './effects.js';
import queryString from 'query-string';
import {notifyCreationEv} from '../tasksGlobalModel/index.js';
import dayjs from 'dayjs';
import {notification} from 'antd';
import i18n from '../../i18n.js';
import {useSearchParams} from "react-router-dom";
import {ViewFileCellsGate} from "../uploadAccountsModel/index.js";

$controllersList.on(getControllersFx.doneData, (_, controllers) => controllers)
    .reset([TaskPdfToContsGate.close, InspectorFilesGate.close])

$contollersAll.on(getControllersFx.doneData, (existing, newData) => [...existing, ...newData?.data?.filter(n => !existing?.map(e => e.id)?.includes(n.id))])
    .reset([TaskPdfToContsGate.close, InspectorFilesGate.close])

$filters.on(changeFiltersEv, (state, {key, value}) => ({...state, [key]: value}))
    .reset(resetFiltersEv)

$selectedControllers.on(changeSelectedControllersEv, (state, v) => v)

$taskCreated.on(createTaskFx.doneData, () => true)
    .reset(resetTaskCreatedEv)

$uploadFileModal.on(setUploadFileModalEv, () => true)
    .reset(resetUploadFileModalEv)
$uploadStatus.on(uploadFileFx.doneData, () => true)
    .reset(resetUploadFileModalEv)
$uploadedFilesData.on(getFileListFx.doneData, (_, data) => data)
.on(acceptMessageUploadEmailEv, (state, data) => {
    const newList = [...state.data]
    newList.map((item) => {
        if (item.id === data.id) {
            for (const [key, value] of Object.entries(data)) {
                item[key] = value
            }
        }
        return item
    })
    return {data: newList, count: state.count}
})
    .reset(UploadedFilesGate.close)

$selectedByIdFile.on(getFileInfoFx.doneData, (_, file) => file)
    .reset(ViewFileCellsGate.close)


sample({
    clock: ViewFilesGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    // fn: (gate) => {
    //     return +gate.id
    // },
    target: getFileInfoFx
})

sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeFiltersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return []
    },
    target: $selectedControllers
})


sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeSelectedControllersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true && selected.length > 0) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return {
            ...filters
            // select_all : false
        }
    },
    target: $filters
})

sample({
    source: {gate: TaskPdfToContsGate.state, filters: $filters},
    clock: [TaskPdfToContsGate.state.updates, applyFiltersEv, resetFiltersEv],
    filter: ({gate}) => Object.hasOwn(gate, 'search'),
    fn: ({gate, filters}) => {
        const checked = {...filters}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf' || key === 'has_pdf' || key === 'pdf_month') {
                delete checked[key]
            }
        }

        return {
            ...queryString.parse(gate.search.toString(), {parseNumbers: true, parseBooleans: true}),
            // ...queryString.parse(filters, {parseNumbers: true, parseBooleans: true}),
            ...checked
        }
    },
    target: getControllersFx
})

sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeFiltersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return []
    },
    target: $selectedControllers
})


sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: changeSelectedControllersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true && selected.length > 0) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return {
            ...filters,
            select_all : false
        }
    },
    target: $filters
})

sample({
    source: {selected: $selectedControllers, filters: $filters},
    clock: createTaskEv,
    filter: ({selected, filters}) => {
        return filters
    },
    fn: ({selected, filters}) => {

        const checked = {...filters}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf' || key === 'has_pdf' || key === 'pdf_month') {
                delete checked[key]
            }
        }
        const payload = {
            inspector_ids: selected,
            filters: checked,
            report_date: dayjs(new Date()).format(),
            recreate_pdf: filters.recreate_pdf ?? false
        }
        if (payload.inspector_ids.length > 0) {
            delete payload.filters
        } else {
            delete payload.inspector_ids
        }
        return payload
    },
    target: [createTaskFx, $createdTask]
})

sample({
    source: {gate: InspectorFilesGate.state, filters: $filters},
    clock: [InspectorFilesGate.state.updates, applyFiltersEv, resetFiltersEv],
    filter: ({gate}) => Object.hasOwn(gate, 'search'),
    fn: ({gate, filters}) => {
        const checked = {...filters}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf') {
                delete checked[key]
            } else if (key === 'pdf_month') {
                checked[key] = dayjs(checked[key]).format()
            }
        }

        return {
            ...queryString.parse(gate.search.toString(), {parseNumbers: true}),
            ...checked
        }
    },
    target: getControllersFx
})

sample({
    clock: downloadFilesPerControllersEv,
    target: downloadFilesPerControllersFx
})

sample({
    clock: downloadSingleFileEv,
    target: downloadSingleFileFx
})

sample({
    clock: createTaskFx.doneData,
    target: notifyCreationEv
})


sample({
    clock: stopImportTaskEv,
    target: stopImportTaskFx
})

sample({
    clock: stopConfirmTaskEv,
    target: stopConfirmTaskFx
})


sample({
    clock: approveFileEv,
    target: approveFileFx
})
sample({
    clock: disaproveFileEv,
    target: disapproveFileFx
})

downloadFilesPerControllersFx.failData.watch((err) => {
    if (err.response.status === 404) {
        notification.error({
            message: i18n.t('Ошибка'),
            description: i18n.t('Файлы по данным параметрам не найдены')
        })
    }
})

downloadSingleFileFx.failData.watch((err) => {
    if (err.response.status === 404) {
        notification.error({
            message: i18n.t('Ошибка'),
            description: i18n.t('Файл не найден')
        })
    }
})


sample({
    clock: uploadFileEv,
    target: uploadFileFx
})

sample({
    source: UploadedFilesGate.state,
    clock: [uploadFileFx.doneData, disapproveFileFx.doneData],
    fn: (gate) => gate,
    target: getFileListFx
})

sample({
    clock: UploadedFilesGate.state,
    fn: (gate) => gate,
    target: getFileListFx
})
