import permitted from './permitted.js';

export default function permByUrl(perms, url = window.location.pathname, extAction) {
    if (url === '/' || url === '/login') return true
    if (url.includes('dictionaries')) {
        const s = url.split('/')
        let type = s[2]?.replaceAll('-', '_')
        let action = s[3] && s[3] === 'edit' ? 'change' : s[3]

        if (type === 'swap_sector') {
            return permitted(['sector_inspector::change'], perms)
        } else if (type === 'swap_real_estate_object') {
            return perms?.sector_reo?.change
        } else if (type === 'template_accruals') {
            type = 'accrual_file_template'
        } else if (type === 'epd_template_fields') {
            type = 'epd_template'
        } else if (type === 'service_classes') {
            type = 'service_class'
        } else if (type === 'chief_inspector') {
            type = 'inspector'
        } else if (type === 'role_permission') {
            type = 'role'
        } else if (type === 'swap_address') {
            return perms?.address?.change
        }

        if (!perms?.[type] && Object.keys(perms).some(p => type.includes(p)) && type.endsWith('s')) {
            type = type.slice(0, -1)
        }

        console.log("=>(permByUrl.js:2) check", type, action, extAction, perms?.[type])
        if (type && !action && !extAction) {
            return perms?.[type]?.view
        } else if (type && !action && extAction) {
            return perms?.[type]?.[extAction]
        } else if (type && action) {
            return perms?.[type]?.[action]
        }
    } else if (url.includes('corrections')) {
        const s = url.split('/')
        const action = s[2] && s[2] === 'edit' ? 'change' : s[2]
        if (!action) {
            return perms?.corrections?.view
        } else if (action) {
            return perms?.corrections?.[action]
        }
    } else if (url.includes('upload-accounts')) {
        return permitted(['import_file::import_accounts'], perms)
    } else if (url.includes('upload-update-accounts')) {
        return permitted(['import_file::import_personal_data'], perms)
    } else if (url.includes('upload-real-estate-objects')) {
        return permitted(['import_file::import_reo'], perms)
    } else if (url.includes('upload-agreements')) {
        return permitted(['import_file::import_external_number'], perms)
    } else if (url.includes('upload-provider-accounts')) {
        return permitted(['import_file::import_account_provider'], perms)
    } else if (url.startsWith('/upload')) {
        return permitted(['import_file::import_accruals'], perms)
    } else if (url.includes('close-month')) {
        return perms?.closed_month?.view
    } else if (url === '/accruals') {
        return perms?.accrual?.list
    } else if (url === '/payment') {
        return perms?.payment_distribution?.list
    } else if (url === '/upload-cash-payments') {
        return permitted(['import_file::import_provider_payments'], perms)
    } else if (url === '/tasks/all') {
        return permitted(['epd_generator::change'], perms)
    } else if (url.startsWith('/approve-emails')) {
        return perms?.import_file?.import_email
    } else if (url === '/tasks/email-per-accounts') {
        return perms?.dispatch?.change
    } else if (url.split('/').at(-1) === 'reports') {
        return perms?.report?.list
    } else if (url === '/reports/files') {
        return perms?.report?.list
    } else if (url === '/admin-services') {
        return perms?.service?.update
    } else if (url.includes('/services-columns')) {
        return perms?.services_columns?.update
    } else if (url.includes('/not-found')) {
        return true
    } else {
        return false
    }
}
