import {createEffect} from "effector";
import api from "../../api/api.js";

export const getCalcTypeAlgorithmsFx = createEffect()
	.use(async (filters) => (await api().get(`/algorithm/${filters.length > 0 ? `?${filters}` : ''}`)).data)


export const getAlgorithmByIdFx = createEffect()
	.use(async (id) => (await api().get(`/algorithm/${id}`)).data)

export const submitCreateAlgorithmFx = createEffect()
	.use(async (payload) => (await api().post(`/algorithm/`, payload)).data)

export const submitEditAlgorithmFx = createEffect()
	.use(async ({id, values}) => (await api().put(`/algorithm/${id}`, values)).data)

export const deleteAlgorithmFx = createEffect()
	.use(async (id) => (await api().delete(`/algorithm/${id}`)).data)
