import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getCurrencyListFx = createEffect()
    .use(async (filters) => (await api().get(`/currency/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getCurrencyByIdFx = createEffect()
    .use(async (id) => (await api().get(`/currency/${id}`)).data)

export const createCurrencyFx = createEffect()
    .use(async (data) => (await api().post(`/currency/`, data)))
