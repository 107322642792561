import {useTranslation} from 'react-i18next';
import {useUnit} from "effector-react";
import {Link} from 'react-router-dom';
import {$userPerms} from '../../models/infoModel/index.js';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import Descriptions from 'antd/es/descriptions';
import Space from 'antd/es/space';
import permByUrl from '../../utils/permByUrl.js';
import styles from './ViewDictionaryItemCommon.module.css';
// import {createCorrectionEv} from "../../models/dictionaryCorrectionModel/index.js";

export default function ViewDictionaryItemCommon(
    {
        items,
        itemsDates,
        customItems,
        id,
        href,
        showNav = true
    }) {
    const {t} = useTranslation();
    const perms = useUnit($userPerms)
    // const createCorrection = useUnit(createCorrectionEv)

    return <div className={styles.viewContainer}>
        {
            items && items.length > 0 && <div
                className={items.length % 2 === 0 ? styles.viewCommonInfoGrid2 : styles.viewCommonInfoGrid3}>
                {items.map(i => <Card key={i.key} title={i.label} size={'small'}>
                    {i.children}
                </Card>)}
            </div>
        }
        {customItems && <div className={styles.viewCommonInfoCustom}>{customItems}</div>}
        <div className={styles.viewSider}>
            {showNav && <Space>
                <Link to={permByUrl(perms, `/dictionaries/${href}/edit`) ? `/dictionaries/${href}/edit/${id}` : `#`}>
                <Button disabled={!permByUrl(perms, `/dictionaries/${href}/edit`)} type={'primary'}>
                    {t('Редактировать')}
                </Button>
                </Link>
                <Link to={`/dictionaries/${href}`}>
                    <Button>{t('К списку')}</Button>
                </Link>
                {/*{*/}
                {/*    window.location.href.includes('accounts') && <Button*/}
                {/*        type={'primary'}*/}
                {/*        onClick={() => {*/}
                {/*            createCorrection({*/}
                {/*                mass: false,*/}
                {/*                account_id: id*/}
                {/*            })*/}
                {/*        }}*/}

                {/*    >*/}
                {/*        {t('Создать корректировку')}*/}
                {/*    </Button>*/}
                {/*}*/}
            </Space>}
            <Descriptions column={1} items={itemsDates}/>
        </div>
    </div>
}
