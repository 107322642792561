import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const TownsListGate = createGate()
export const TownViewGate = createGate()
export const TownEditGate = createGate()
export const TownCreateGate = createGate()

export const submitCreateTownEv = createEvent()
export const submitEditTownEv = createEvent()
export const deleteTownEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()