import {useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {
    $providersList, deleteProviderEv,
    getProvidersListFx,
    ProviderListGate
} from '../../models/dictionaryProvidersModel/index.js';
import {searchAndActions} from '../../utils/searchAndActions.js';
import {Link} from 'react-router-dom';
import {
    CheckCircleOutlined,
    CloseCircleOutlined, DeleteOutlined,
    EditOutlined,
    EyeOutlined, HistoryOutlined
} from '@ant-design/icons';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import MappedDictItem from '../../components/MappedDictItem/MappedDictItem.jsx';
import CustomDates from '../../components/CustomDates/CustomDates.jsx';
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Divider from 'antd/es/divider';
import {$userPerms} from '../../models/infoModel/index.js';

export default function DictionaryProvidersList() {
    const {data, count} = useUnit($providersList)
    const loading = useUnit(getProvidersListFx.pending)
    const {search, exportXlsAction} = searchAndActions()
    const perms = useUnit($userPerms)

    const {t} = useTranslation()

    const deleteProvider = useUnit(deleteProviderEv)

    const columns = [
        {
            title: t('Код'),
            dataIndex: 'id',
            key: 'id',
            width: '3%',
            sorter: true,
        },
        {
            title: t('Наименование'),
            dataIndex: 'name_ru',
            key: 'name_ru',
            sorter: true,
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            key: 'town_id',
            sorter: true,
            render: (town) => <MappedDictItem id={town} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            key: 'town_distr_id',
            sorter: true,
            render: (town_distr_id) => <MappedDictItem id={town_distr_id} type={'town_distr'}/>,
        },
        {
            title: t('КБК'),
            dataIndex: 'kbk_id',
            key: 'kbk_id',
            sorter: true,
            render: (kbk) => <MappedDictItem id={kbk} type={'kbk'}/>
        },
        {
            title: t('Тип расчета с ЕРЦ'),
            dataIndex: 'calc_type_id',
            key: 'calc_type_id',
            sorter: true,
            render: calcType => <MappedDictItem id={calcType} type={'calc-type'}/>
        },
        {
            title: t('КНП'),
            dataIndex: 'knp_id',
            key: 'knp_id',
            sorter: true,
            render: (knp) => <MappedDictItem id={knp} type={'knp'}/>,
        },
        {
            title: t('Категория организации'),
            dataIndex: 'organization_type_id',
            key: 'organization_type_id',
            sorter: true,
            render: (organizationType) => <MappedDictItem id={organizationType} type={'organization_type'}/>,
        },
        {
            title: t('КБЕ'),
            dataIndex: 'kbe_id',
            key: 'kbe_id',
            sorter: true,
            render: (kbe) => <MappedDictItem id={kbe} type={'kbe'}/>,
        },
        {
            title: t('Бенефициар'),
            dataIndex: 'is_beneficiary',
            key: 'is_beneficiary',
            sorter: true,
            align: 'center',
            width: '3%',
            render: is => is ? <CheckCircleOutlined /> : <CloseCircleOutlined />
        },
        {
            title: t('БИН/ИИН'),
            dataIndex: 'bin_iin',
            key: 'bin_iin',
            sorter: true
        },
        {
            title: t('Email'),
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: t('Юридический адрес'),
            dataIndex: 'judical_address',
            key: 'judical_address',
            sorter: true,
        },
        {
            title: t('Фактический адрес'),
            dataIndex: 'actual_address',
            key: 'actual_address',
            sorter: true,
        },
        {
            title: t('Адреса совпадают'),
            dataIndex: 'is_addresses_same',
            key: 'is_addresses_same',
            sorter: true,
            align: 'center',
            width: '3%',
            render: is => is ? <CheckCircleOutlined /> : <CloseCircleOutlined />
        },
        {
            title: t('Контакты'),
            dataIndex: 'accountant_contact',
            key: 'accountant_contact',
        },
        {
            title: t('ФИО Руководителя'),
            dataIndex: 'chief_contact',
            key: 'chief_contact',
        },
        {
            title: t('Заметки'),
            dataIndex: 'notes',
            key: 'notes',
        },
        {
            title: t('Дата начала поставки услуг'),
            dataIndex: 'provide_start_date',
            key: 'provide_start_date',
            sorter: true,
            render: (date) => date ? <CustomDates data={date} /> : '-',
        },
        {
            title: t('Дата окончания поставки услуг'),
            dataIndex: 'end_date',
            key: 'end_date',
            sorter: true,
            render: (date) => date ? <CustomDates data={date} /> : '-',
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                {(
                    perms?.provider?.change
                    || perms?.provider_service?.change
                    || perms?.provider_kassa?.change
                    || perms?.real_estate_object_provider_service?.change
                ) && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/providers/edit/${id}`}><Button icon={<EditOutlined/>}
                                                                            type={'primary'}/></Link>
                </Tooltip>}
                {(
                    perms?.provider?.view
                    || perms?.provider_service?.view
                    || perms?.provider_kassa?.view
                    || perms?.real_estate_object_provider_service?.view
                ) && <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/providers/view/${id}`}><Button icon={<EyeOutlined/>}/></Link>
                </Tooltip>}
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                {perms?.provider?.delete && <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                             okText={t('Да')}
                             onConfirm={() => deleteProvider(id)}
                             placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>}
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/Название Поставщика'), type: 'input'},
        {name: 'bin_iin', label: t('БИН/ИИН поставщика'), type: 'input'},

        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns', multiple: true},
        // {name: 'town_distr_id', label: t('Район'), type: 'select', dict: 'districts'},
        {name: 'town_distr_id', label: t('Район города'), type: 'select', dict: 'districts', multiple: true},
        {name: 'is_beneficiary', label: t('Является бенефициаром'), type: 'switch'},
        {name: 'beneficiary_id', label: t('Поставщик-Бенефициар'), type: 'select', dict: 'providers', multiple: true},

        {name: 'is_not_accrued', label: t('Не начислять'), type: 'switch'},
        {name: 'is_not_balance_transfer', label: t('Не переводить сальдо'), type: 'switch'},
        {name: 'is_not_printed', label: t('Не печатать'), type: 'switch'},

        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]

    return <>
        <ProviderListGate search={search.toString()}/>
        <FilterBlock items={filters}/>
        <Divider />
        <DictionaryProTable data={data} count={count} loading={loading} initColumns={columns} exportXlsAction={exportXlsAction}/>
    </>
}
