import Card from 'antd/es/card';
import {useTranslation} from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import {useUnit} from 'effector-react';
import {changeAddressesChildrenFiltersEv} from '../../../models/embeddedFormsModels/embeddedAddressesModel';

export default function AddressEmbeddedFilters() {
    const {t} = useTranslation()
    const changeFilter = useUnit(changeAddressesChildrenFiltersEv);
    const genitive_form = t(`breadcrumbs.genitive.address`)

    return <Card title={t('Фильтрация')} size={'small'} style={{marginBottom: 16}}>
        <Row justify={'space-between'} gutter={[16, 16]}>
            <Col span={8}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <span>{t(`Код ${genitive_form}`)}</span>
                    <Input onBlur={(e) => changeFilter({key: 'code_name', value: e.target.value})} allowClear/>
                </div>
            </Col>
            <Col span={8}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <span>{t('Номер дома')}</span>
                    <Input onBlur={(e) => changeFilter({key: 'house', value: e.target.value})} allowClear/>
                </div>
            </Col>
            <Col span={8}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                    <span>{t('Корпус')}</span>
                    <Input onBlur={(e) => changeFilter({key: 'corpus', value: e.target.value})} allowClear/>
                </div>
            </Col>
        </Row>
    </Card>
}
