import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Switch from 'antd/es/switch';
import Divider from 'antd/es/divider';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import InputNumber from "antd/es/input-number";
import AddressREOSearchForm
    from '../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import {$createReoEmbeddedOpen} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import CreateREOEmbeddedFrom
    from '../../../components/Embedded/CreateREOEmbeddedForm/CreateREOEmbeddedFrom.jsx';
import {binValidator} from "../../../utils/validateBIN.js";
import InputMask from 'react-input-mask';

export default function CreateAccountCommon() {
    const {t} = useTranslation()

    const createReoOpen = useUnit($createReoEmbeddedOpen)

    return <>
        <AddressREOSearchForm single isCard itemsAsRow withReo/>
        <Divider/>
        <CreateREOEmbeddedFrom/>
        {createReoOpen && <Divider/>}
        <Row justify={'space-between'} gutter={[16, 0]}>
            <Col span={7}>
                <Card size={'small'} style={{minHeight: '100%'}}>
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item name={'name_ru'} label={t('ФИО')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                name={'iin'} label={t('ИИН')}
                                rules={[
                                    {required: false, message: t('Обязательное поле')},
                                    {validator: (rule, value) => binValidator(rule, value, false)}
                                ]}
                            >
                                <Input showCount maxLength={12}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'flat'} label={t('Квартира')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={8}>
                <Card size={'small'} style={{minHeight: '100%'}}>
                    <Row gutter={[8, 0]}>
                        <Col span={9}>
                            <Form.Item name={'full_square'} label={t('Общая пл.')}
                                       rules={[{
                                           required: false,
                                           message: t('Обязательное поле')
                                       }]}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                            >
                                <InputNumber style={{width: '100%'}}
                                             decimalSeparator={','}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'living_square'} label={t('Жилая пл.')}
                                       rules={[
                                           {
                                               required: false,
                                               message: t('Обязательное поле'),
                                           },
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   if (value && value > getFieldValue('full_square')) {
                                                       return Promise.reject(new Error(t('Значение не должно превышать общую площадь')))
                                                   }
                                                   return Promise.resolve()
                                               }
                                           })
                                       ]}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                            >
                                <InputNumber style={{width: '100%'}}
                                             decimalSeparator={','}/>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name={'heated_square'} label={t('Отапл. пл.')}
                                       rules={[
                                           {
                                               required: false,
                                               message: t('Обязательное поле'),
                                           },
                                           ({getFieldValue}) => ({
                                               validator(_, value) {
                                                   if (value && value > getFieldValue('full_square')) {
                                                       return Promise.reject(new Error(t('Значение не должно превышать общую площадь')))
                                                   }
                                                   return Promise.resolve()
                                               }
                                           })
                                       ]}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                            >
                                <InputNumber style={{width: '100%'}}
                                             decimalSeparator={','}/>
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item name={'people_quantity'}
                                       label={t('Кол-во проживающих')}
                                       rules={[{
                                           required: false,
                                           message: t('Обязательное поле')
                                       }]}
                                           tooltip={t('Используется как справочное значение для вывода в шапке ЕПД. Не используется для расчетов начислений.')}
                            >
                                <InputNumber style={{width: '100%'}} precision={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'room_count'} label={t('Кол-во комнат')}>
                                <InputNumber style={{width: '100%'}} precision={0}/>
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item name={'floor'} label={t('Этаж')}>
                                <InputNumber style={{width: '100%'}} precision={0}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
            <Col span={6}>
                <Card size={'small'}>
                    <Row justify={'space-between'} gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item name={'phone'} label={t('Тел.')}>
                                {/*<Input/>*/}
                                <InputMask mask="99-99-99">
                                    {() => <Input />}
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'mobile_phone'} label={t('Моб. тел.')}>
                                <InputMask mask="(999) 999-99-99">
                                    {() => <Input />}
                                </InputMask>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'email'} label={t('Email')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name={'is_email_delivery'} label={t('Email рассылка')}
                                       valuePropName={'checked'}>
                                <Switch/>
                            </Form.Item>
                        </Col>

                    </Row>
                </Card>
            </Col>
            <Col span={3}>
                <Card size={'small'}>
                    <Row justify={'space-between'} gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item name={'aesbyt_account'} label={t('Код АЭСбыт')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name={'energy_account'} label={t('Код энергокомпании')}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
        <Divider/>
    </>
}
