import {useTranslation} from 'react-i18next';
import Tabs from 'antd/es/tabs';
import CreateTaskPerAccounts from './components/CreateTaskPerAccounts.jsx';
import TasksStatuses from '../../components/TasksStatuses/TasksStatuses.jsx';
import {useEffect, useState} from 'react';
import {useUnit} from 'effector-react';
import {
    $taskCreationStatus,
    resetTaskCreationStatus
} from '../../models/taskEmailPerAccountsModel/index.js';

export default function TaskEmailPerAccounts() {
    const {t} = useTranslation()
    const [activeKey, setActiveKey] = useState('create');

    const taskCreated = useUnit($taskCreationStatus)
    const resetTaskCreated = useUnit(resetTaskCreationStatus)

    const onChangeTab = (payload) => {
        setActiveKey(payload)
    }

    useEffect(() => {
        if (taskCreated) {
            onChangeTab('status')
            resetTaskCreated()
        }
    }, [taskCreated])

    const tabs = [
        {
            key: 'create',
            label: t('Создание задачи'),
            children: <CreateTaskPerAccounts />
        },
        {
            key: 'status',
            label: t('Статусы задач'),
            children: <TasksStatuses name={'send_email'}/>
        }
    ]

    return <Tabs
        defaultActiveKey={'create'}
        items={tabs}
        destroyInactiveTabPane
        activeKey={activeKey}
        onChange={onChangeTab}
        type={'card'}
    />
}
