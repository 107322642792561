import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const TasksGate = createGate()

export const notifyCreationEv = createEvent()

export const changeTasksFilterEv = createEvent()
export const resetTasksFilterEv = createEvent()

export const stopTaskEv = createEvent()

export const acceptTaskFromSocketEv = createEvent()

export const setLogsModalEv = createEvent()
export const setLogsModalIdEv = createEvent()
export const LogsIdModalGate = createGate()
export const setParametrsModalEv = createEvent()
export const resetLogsModalEv = createEvent()
export const resetParametrsModalEv = createEvent()
