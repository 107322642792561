import {createStore} from 'effector';
import {getUsersListFx} from "./effects.js";
import {openUsrPrvModalEv} from "./events.js";


export const $usersList = createStore({data: [], count: 0})

export const $selectedUsers = createStore(null)

export const $editUsersStatus = createStore(false)

export const $createdUsersId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $userCreateErrors = createStore([])
export const $usrPrvModal = createStore(false)
export const $usrPrvModalData = createStore(false)
export const $usrPrvModalErrorData = createStore(null)

export const $userProviderList = createStore({data: [], count: 0})
