import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $createdAddresses, $addressEmbeddedFilters,
    $addressesChildren, changeAddressesChildrenFiltersEv,
    createChildAddressEv,
    deleteChildAddressEv,
    deleteNewAddressEv,
    editChildAddressEv,
    setCreateEditChildAddressEmbeddedEv, AddressesChildrenGate,
    getAddressesChildrenFx
} from '../../../models/embeddedFormsModels/embeddedAddressesModel';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import {CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
import Popconfirm from 'antd/es/popconfirm';
import CreateEditAddressEmbedded
    from '../CreateEditAddressEmbedded/CreateEditAddressEmbedded.jsx';
import Table from 'antd/es/table';
import Divider from 'antd/es/divider';
import AddressEmbeddedFilters from './AddressEmbeddedFilters.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import dayjs from 'dayjs';

export default function AddressesTable({view, parent, parentId}) {
    const {t} = useTranslation()
    const {data, count} = useUnit($addressesChildren)
    const loading = useUnit(getAddressesChildrenFx.pending)
    const newAddresses = useUnit($createdAddresses)

    const deleteExisting = useUnit(deleteChildAddressEv)
    const deleteNew = useUnit(deleteNewAddressEv)

    const onCreate = useUnit(createChildAddressEv)
    const onEdit = useUnit(editChildAddressEv)

    const setModal = useUnit(setCreateEditChildAddressEmbeddedEv)

    const {page, limit, sortField, sortOrder} = useUnit($addressEmbeddedFilters)
    const changeFilters = useUnit(changeAddressesChildrenFiltersEv)
    const genitive_form = t(`breadcrumbs.genitive.address`)

    const columns = [
        {
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'id',
            render: (id) => id > 0 ? id : 'Новый'
        },
        {
            title: t('Номер дома'),
            dataIndex: 'house',
            sorter: true,
            onCell: (record) => ({className: record?.changedFields?.includes('house') ? 'table-cell-edited' : ''}),
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            sorter: true,
            onCell: (record) => ({className: record?.changedFields?.includes('corpus') ? 'table-cell-edited' : ''}),
        },
        {
            title: t('Актуален'),
            dataIndex: 'is_actual',
            sorter: true,
            onCell: (record) => ({className: record?.changedFields?.includes('is_actual') ? 'table-cell-edited' : ''}),
            render: (is_actual) => is_actual ? t('Да') : t('Нет')
        },
        {
            title: t('Актуален с'),
            dataIndex: 'actual_from',
            sorter: true,
            onCell: (record) => ({className: record?.changedFields?.includes('is_actual_from') ? 'table-cell-edited' : ''}),
            render: (actual_from) => actual_from ? dayjs(actual_from).format('DD.MM.YYYY') : '-'
        },
        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined />} onClick={() => setModal({type: 'create'})} type={'primary'}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={() => deleteExisting(id)}
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined />} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]

    const actionsNew = {
        title: <Tooltip title={t('Добавить')}>
            <Button icon={<PlusOutlined />} onClick={() => setModal({type: 'create'})} type={'primary'}/>
        </Tooltip>,
        width: '5%',
        align: 'center',
        dataIndex: 'id',
        key: 'actions',
        render: (id) => {
            return <ButtonGroup>
                <Tooltip title={t('Редактировать')}>
                    <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => deleteNew(id)}
                            placement={'left'}
                >
                    <Tooltip title={t('Удалить')}>
                        <Button icon={<CloseOutlined />} type={'primary'} danger/>
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    }

    if (view) {
        const idx = columns.findIndex(i => i.key === 'actions')
        if (idx !== -1) {
            columns.splice(idx, 1)
        }
    }

    const columnsNew = columns.toSpliced(columns.findIndex(i => i.key === 'actions'), 1, actionsNew)

    return <>
        <AddressesChildrenGate parent={parent} parentId={+parentId} />
        <CreateEditAddressEmbedded onCreate={onCreate} onEdit={onEdit} />
        {
            (newAddresses?.length > 0 || !parent) && <>
                <Table dataSource={[...newAddresses]} columns={columnsNew} pagination={false}/>
                <Divider />
            </>
        }
        {(!!parentId || parentId === 0) && <AddressEmbeddedFilters/>}
        {parent && <Table dataSource={[...data]}
                columns={columns}
                loading={loading}
                bordered
                size={'small'}
                pagination={{
                    ...paginationConfig,
                    pageSize: parseInt(limit),
                    current: parseInt(page),
                    total: count
                }}
                onChange={({current, pageSize}, filters, {field, order}) => {
                    const payload = []
                    if (current !== page || pageSize !== limit) {
                        payload.push({key: 'page', value: current})
                        payload.push({key: 'limit', value: pageSize})
                    }

                    if (field !== sortField || order !== sortOrder) {
                        if (!order) {
                            payload.push({key: 'sort_field', value: null})
                            payload.push({key: 'sort_order', value: null})
                        } else {
                            payload.push({key: 'sort_field', value: field})
                            payload.push({
                                key: 'sort_order',
                                value: order === 'ascend' ? 'asc' : 'desc'
                            })
                        }
                    }
                    changeFilters(payload)
                }}
                style={{width: '100%'}}
        />}
    </>
}
