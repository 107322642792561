import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $accsFilters,
    $correctionPeriod,
    $massEditSrvModal,
    $psTarifsSL,
    $srvCreateEditDone, $tarifCreated, createTariffEv, getPSTarifsEv, resetMassEditModalEv,
    submitTempCorrsMassEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {$dictsSelects, $info} from '../../../../models/infoModel/index.js';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import DatePicker from 'antd/es/date-picker';
import Switch from 'antd/es/switch';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import message from 'antd/es/message';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import {isId} from '../../../../utils/helpers.js';

export default function MassCorrModal() {
    const {t} = useTranslation()
    const {open} = useUnit($massEditSrvModal)

    const {provider_id: provFromFilter, service_id: srvFromFilter} = useStoreMap($accsFilters, f => (
        {
            provider_id: f.provider_id ?? undefined,
            service_id: f.service_id ?? undefined
        }
    ))

    const [srvForm] = Form.useForm();

    const provider_id = Form.useWatch('provider_id', srvForm)
    const service_id = Form.useWatch('service_id', srvForm)
    const selectedTarif = Form.useWatch('tarif_id', srvForm)
    const notTransferSaldo = Form.useWatch('not_transfer_saldo', srvForm)

    const selPeriod = useUnit($correctionPeriod)
    const currentDate = useStoreMap($info, i => i.current_date)
    const currMonth = dayjs(currentDate)
    const nextMonth = dayjs(currentDate).endOf('month').add(1)

    const defaultDate = useMemo(() => {
        if (selPeriod === 'current') {
            return currMonth
        } else if (selPeriod === 'next') {
            return nextMonth
        }
    }, [selPeriod, currentDate])

    const allowedDates = useMemo(() => {
        if (isId(service_id)) {
            const min = selPeriod === 'current' ? currMonth.startOf('month') : nextMonth.startOf('month')
            const max = null
            return {max, min}
        } else {
            const min = selPeriod === 'current' ? currMonth.startOf('month') : nextMonth.startOf('month')
            const max = null
            return {max, min}
        }
    }, [selPeriod, currentDate, service_id])

    const submit = useUnit(submitTempCorrsMassEv)
    const reset = useUnit(resetMassEditModalEv)
    const status = useUnit($srvCreateEditDone)

    const getPSTarifs = useUnit(getPSTarifsEv)
    const psTarifs = useUnit($psTarifsSL)
    const createTariff = useUnit(createTariffEv)
    const createdTariffId = useUnit($tarifCreated)

    const {provOpts, srvOpts, srvUnitOpts} = useStoreMap($dictsSelects, sel => ({
        provOpts: sel.providers,
        srvOpts: sel.services,
        srvUnitOpts: sel.service_units
    }))

    const switchOpts = [{label: '', value: null}, {label: 'Да', value: true}, {label: 'Нет', value: false}]

    const onCancel = () => {
        srvForm.resetFields()
        reset()
    }

    const onFinish = (values) => {
        submit(values)
        onCancel()
    }

    const onSubmitTariff = async () => {
        try {
            let values

            values = await srvForm.validateFields(['new_tarif'])
            const {tarif_value, tarif_service_unit_id} = values['new_tarif']

            if (tarif_value && tarif_service_unit_id) {
                createTariff({tarif_value, tarif_service_unit_id})
            }
        } catch (e) {
            message.error(t('Заполните необходимые поля'))
        }
    }

    useEffect(() => {
        if (status) {
            onCancel()
        }
    }, [status])

    useEffect(() => {
        if (open && isId(provider_id) && isId(service_id)) {
            getPSTarifs({provider_id, service_id})
        } else if (open && !(isId(provider_id) && isId(service_id))) {
            getPSTarifs({provider_id: provFromFilter, service_id: srvFromFilter})
        }
    }, [open, provider_id, service_id, provFromFilter, srvFromFilter]);

    useEffect(() => {
        if (open && createdTariffId && isId(provider_id) && isId(service_id)) {
            srvForm.setFieldValue('tarif_id', createdTariffId)
            getPSTarifs({provider_id, service_id})
        } else if (open && createdTariffId && !(isId(provider_id) && isId(service_id))) {
            getPSTarifs({provider_id: provFromFilter, service_id: srvFromFilter})
        }
    }, [createdTariffId, open]);

    const switchIsNotAccrued = (is_not_accrued) => {
        if (is_not_accrued === false) {
            srvForm.setFieldValue('is_not_balance_transfer', false)
        }
    }

    const switchIsNotBalanceTransfer = (is_not_balance_transfer) => {
        if (is_not_balance_transfer === true) {
            srvForm.setFieldValue('is_not_accrued', true)
        }
    }

    const onValsChange = (cur) => {
        if (Object.hasOwn(cur, 'service_id') && !isId(cur?.service_id)) {
            srvForm.setFieldValue('date_from', allowedDates.min)
            srvForm.resetFields(['tarif_id'])
        } else if (Object.hasOwn(cur, 'provider_id') && !isId(cur?.provider_id)) {
            srvForm.setFieldValue('date_from', allowedDates.min)
            srvForm.resetFields(['service_id', 'tarif_id'])
        } else if (Object.hasOwn(cur, 'provider_id') && isId(cur?.provider_id)) {
            srvForm.resetFields(['service_id', 'tarif_id'])
            if (srvOpts.filter(s => s.providers.includes(cur?.provider_id)).some(s => s.value === srvFromFilter)) {
                srvForm.setFieldValue('service_id', srvFromFilter)
            }
        } else if (Object.hasOwn(cur, 'not_transfer_saldo') && cur?.not_transfer_saldo) {
            srvForm.resetFields(['tarif_id'])
        }
    }

    // const srvRequired = useMemo(() => {
    //     if (isId(provider_id) && srvOpts?.filter(s => s?.providers?.includes(provider_id))?.some(s => s.value === srvFromFilter)) {
    //         return false
    //     } else if (isId(provider_id) && !srvOpts?.filter(s => s?.providers?.includes(provider_id))?.some(s => s.value === srvFromFilter)) {
    //         return true
    //     } else return false
    // }, [provider_id, service_id, srvOpts])

    return <Modal open={!!open}
                  title={t('Групповая корректировка')}
                  onCancel={onCancel}
                  onOk={() => srvForm.submit()}
                  okText={t('Сохранить')}
                  okButtonProps={{disabled: selectedTarif === -1}}
                  width={'60vw'}
    >
        <Form layout={'vertical'} form={srvForm} onFinish={onFinish} onValuesChange={onValsChange}>
            <Row gutter={[16, 0]}>
                <Col span={10}>
                    <Form.Item name={'tarif_id'} label={t('Тариф')}
                               rules={(provider_id && !notTransferSaldo) ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <Select
                            options={
                            (!provider_id && !service_id)
                                ? [...psTarifs, {label: t('Создать'), value: -1, emoji: true}]
                                : (provider_id && service_id)
                                    ? [...psTarifs, {label: t('Создать'), value: -1, emoji: true}]
                                    : []
                            }
                            style={{width: '100%'}}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Row gutter={[16, 0]}>
                        <Col span={8}>
                            <Form.Item name={'is_not_accrued'} label={t('Не начислять')}>
                                <Select options={switchOpts} onChange={switchIsNotAccrued} allowClear/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'is_not_balance_transfer'} label={t('Не переводить')}>
                                <Select options={switchOpts} onChange={switchIsNotBalanceTransfer} allowClear/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'is_not_printed'} label={t('Не печатать')}>
                                <Select options={switchOpts}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {selectedTarif === -1 && <>
                <Form.Item name={['new_tarif', 'tarif_value']} label={t('Значение тарифа')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}} />
                </Form.Item>
                <Form.Item name={['new_tarif', 'tarif_service_unit_id']} label={t('Единица измерения')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={srvUnitOpts}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}/>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type={'primary'} onClick={onSubmitTariff}>{t('Сохранить')}</Button>
                        <Button danger
                                onClick={() => {
                                    srvForm.resetFields(['tarif_id', ['new_tarif', 'tarif_value'], ['new_tarif', 'tarif_service_unit_id']])
                                }}
                        >
                            {t('Отмена')}
                        </Button>
                    </Space>
                </Form.Item>
            </>}
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item name={'provider_id'} label={t('Поставщик')}>
                        <Select
                            options={provOpts.filter(p => p.value !== provFromFilter)}
                            style={{width: '100%'}}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={'service_id'} label={t('Услуга')}
                               rules={provider_id ? [{required: true, message: t('Обязательное поле')}] : []}
                    >
                        <Select
                            options={provider_id ? srvOpts.filter(s => s?.providers?.includes(provider_id)) : []}
                            disabled={!isId(provider_id)}
                            style={{width: '100%'}}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                {provider_id && <Col span={12}>
                    <Form.Item name={'not_transfer_saldo'} label={t('Не переносить сальдо предыдущего поставщика')} valuePropName={'checked'}>
                        <Switch disabled={!provider_id}/>
                    </Form.Item>
                </Col>}
                {provider_id && <Col span={6}>
                    <Form.Item name={'date_from'} label={t('Действует с')}
                               rules={isId(provider_id) ? [{
                                   required: true,
                                   message: t('Обязательное поле')
                               }] : []}
                               initialValue={defaultDate}
                    >
                        <DatePicker minDate={allowedDates.min}
                                    maxDate={allowedDates.max}
                                    disabledDate={service_id && service_id !== srvFromFilter
                                        ? null
                                        : (date) => date > dayjs(date).startOf('month')}
                                    style={{width: '100%'}}
                        />
                    </Form.Item>
                </Col>}
                <Col span={6}>
                    <Form.Item name={'date_to'} label={t('Действует по')}>
                        <DatePicker minDate={allowedDates.min} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
}