import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const ActivityTypeListGate = createGate()
export const ActivityTypeViewGate = createGate()
export const ActivityTypeEditGate = createGate()
export const ActivityTypeCreateGate = createGate()

export const submitCreateActivityTypeEv = createEvent()
export const submitEditActivityTypeEv = createEvent()
export const deleteActivityTypeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
