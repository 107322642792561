import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import './components/style.css'
import {useStoreMap, useUnit} from "effector-react";
import {
    $paymentAppliesData, cancelAppliesByAllFx, exportPaymentAppliesEv, getPaymentAppliesDataFx,
    getPaymentElementsEv,
    getPaymentErrorByIdEv,
    onCancelAppliesSingleEv,
    onConfirmAppliesSingleEv,
    PaymentAppiesGate,
} from '../../models/paymentModel/index.js';
import {searchAndActions} from "../../utils/searchAndActions.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import Table from "antd/es/table";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import ButtonGroup from "antd/es/button/button-group";
import Tooltip from "antd/es/tooltip";
import {
    QuestionCircleOutlined,
} from "@ant-design/icons";
import {Popconfirm} from "antd";
import ExcelOutlined from "../../components/ExcelIcon/EcxelIcon.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import CreatedBy from "../../components/CreatedBy/CreatedBy.jsx";
import Card from "antd/es/card";
import {paginationConfig} from "../../utils/paginationConfig.js";
import PaymentTableError from "./components/PaymentTableError.jsx";
import PaymentTableApplied from "./components/PaymentTableApplied.jsx";
import FormatNumber from "../../components/FormatNumber/FormatNumber.jsx";
import {$dictsSelects, $infoMaps} from "../../models/infoModel/index.js";
import {Link} from "react-router-dom";

const options = [{
    label: 'Распределено', value: true,
}, {
    label: 'Не распределено', value: false,
},]

export default function PaymentApplies() {
    const {t} = useTranslation()
    const {data} = useUnit($paymentAppliesData)
    const loading = useUnit(getPaymentAppliesDataFx.pending)
    const actionLoading = useUnit(cancelAppliesByAllFx.pending)
    const {search} = searchAndActions()
    const exportXlsAction = useUnit(exportPaymentAppliesEv)
    const keysData = data.map(item => {
        return {
            ...item, key: item.apply_ids[0]
        }
    }).filter(item => !dayjs(item?.bank_day).isSame(dayjs(), 'day'))

    const dataPaymentNew = keysData.filter(item => item.apply_status === 'new')


    // const loading = useUnit(getPaymentDataFx.pending)
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState([]);
    // const [bankDay, setBankDay] = useState(false);
    // const [date, setDate] = useState(null);
    const [activeKey, setActiveKey] = useState('view');

    const [checkStatus, setCheckStatus] = useState(false);
    const [applyId, setApplyId] = useState(false);


    const onConfirmSingleEvent = useUnit(onConfirmAppliesSingleEv)
    const onCancelAppliesSingleEvent = useUnit(onCancelAppliesSingleEv)
    // const getPayment = useUnit(getPaymentByIdEv)
    const getPayment = useUnit(getPaymentElementsEv)
    const getPaymentError = useUnit(getPaymentErrorByIdEv)

    const bankOpts = useStoreMap($dictsSelects, sel => sel.bankBranch)?.filter(item => item.is_contract_signed === true)
    const bankBranch = useStoreMap($infoMaps, sel => sel.bankBranchMap)


	const columns = [
		{
			title: t('Дата'),
			dataIndex: 'bank_day',
			key: 'bank_day',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.bank_day) - new Date(b.bank_day),
			render: (date) => <CustomDates data={date} type={'day'}/>

		},
		// {
		// 	title: t('Банк'),
		// 	dataIndex: 'bank_id',
		// 	key: 'bank_id',
        //     render: (bank_id) => {
        //         // debugger
        //         return <Link to={`/banks/${bankBranch[bank_id]?.id}`}>
        //             {
        //                 `${bankBranch[bank_id]?.branch} ${bankBranch[bank_id]?.name}`
        //             }
        //         </Link>
        //     }
		// },
		{
			title: t('Кол-во платежей'),
			dataIndex: 'payment_count',
			key: 'payment_count',
            sorter: (a, b) => a.payment_count - b.payment_count,
			// render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>

		},
		{
			title: t('Сумма'),
			dataIndex: 'payment_sum',
			key: 'payment_sum',
            sorter: (a, b) => a.payment_sum - b.payment_sum,
			render: (value) => <FormatNumber value={value} type={2}/>
			// render: (bank_id) => <MappedDictItem id={bank_id} type={'banks'}/>

		},
		{
			title: t('Статус'),
			dataIndex: 'apply_status',
			key: 'apply_status',
            sorter: (a, b) => a.apply_status.localeCompare(b.apply_status),
			render: (status) => {
				if (status === 'success') {
					return 'Распределено'
				} else if (status === 'new') {
					return 'Не распределено'
				} else if (status === 'not_ready') {
                    return 'Не подтверждено'
                } else if (status === 'error') {
                    return <span style={{color: 'red'}}>Ошибка </span>
				} else if (status === 'processing') {
					return 'Обработка'
				}
			}

		},
		{
			title: t('Ответственный'),
			dataIndex: 'apply_user_id',
			key: 'apply_user_id',
            sorter: (a, b) => a.apply_user_id - b.apply_user_id,
			render: (id, row) => row.apply_status === 'new' ? '-' : <CreatedBy id={id}/>
		},
		{
			title: t('Дата действия'),
			dataIndex: 'apply_date',
			key: 'apply_date',
            sorter: (a, b) => new Date(a.apply_date) - new Date(b.apply_date),
			render: (date, row) => row.apply_status === 'new' ? '-' : <CustomDates data={date}/>
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id, row) => <ButtonGroup>
                {
                    row.apply_status === 'new' || row.apply_status === 'error'
                        ? <Popconfirm
                            title={t('Вы уверены?')}
                            okText={t('Да')}
                            onConfirm={() => onConfirmSingle(row)}
                            placement={'left'}
                            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                        >
                            {
                                <Tooltip title={t('Распределить эту запись')} placement={'top'}>
                                    <Button
                                        type={'primary'}
                                        size={'small'}
                                        disabled={
                                            data.some(item =>
                                                item?.bank_day < row?.bank_day &&
                                                item?.bank_id === row?.bank_id &&
                                                (item?.apply_status === 'new' || item?.apply_status === 'error')
                                            ) || row.apply_status === 'not_ready'
                                        }
                                    >
                                        Распределить
                                    </Button>
                                </Tooltip>
                            }
                        </Popconfirm> :
                        row.apply_status === 'success' && row.export_status !== 'success'   ?
                            <Popconfirm
                                title={t('Вы уверены?')}
                                okText={t('Да')}
                                onConfirm={() => onCancelSingle(row)}
                                placement={'left'}
                                icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                            >
                                {
                                    <Tooltip title={t('Отменить распределение этой записи')}
                                             placement={'top'}>
                                        <Button type={'primary'}>
                                            Отменить
                                        </Button>
                                    </Tooltip>
                                }
                            </Popconfirm> : ''
                }
			</ButtonGroup>
		}
    ]

    const filters = [
        // {name: 'bank_id', label: t('Банк'), type: 'select', options: bankOpts},
        {name: 'branch_name', label: t('Отделение'), type: 'input'},
        {name: 'bank_day_from', label: t('Дата от'), type: 'date',},
        {name: 'bank_day_to', label: t('Дата до'), type: 'date',},
        {name: 'confirmation_status', label: t('Статус'), type: 'select', options: options},
    ]

    const onSelectChange = (newSelectedRowKeys, newSelectedRowData) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRowData(newSelectedRowData)
        checkInBankDates(newSelectedRowData)
    };

    const checkInBankDates = (newSelected) => {
        const sorted = newSelected.sort((a, b) => new Date(a.bank_day) - new Date(b.bank_day));
        const selectedIds = newSelected.map(item => item.id)
        const last = sorted[sorted.length - 1]
        const result = data.some(item => item?.bank_day < last?.bank_day && item?.bank_id === last?.bank_id && (item?.apply_status === 'new' || item?.apply_status === 'error') && !selectedIds.includes(item.id))
        setCheckStatus(result)
    }

    const rowSelection = {
        selectedRowKeys, onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.confirmation_status,
        }),
    };

    const onChangeTab = (payload) => {
        setActiveKey(payload)
    }

    const handleRowClick = (record) => {
        setSelectedRowKey(record.key);
        // getPayment(record.apply_ids)
        getPayment(record.apply_ids.map(id => `payment_apply_id=${id}`).join('&'))
        // setApplyId(record.id)
        setApplyId(record.apply_ids)
        if (record.apply_status === 'error') {
            getPaymentError(record.apply_ids)
            setActiveKey('error')
        }

    };

    const rowClassName = (record) => {
        return record.key === selectedRowKey ? 'payment-selected-row' : '';
    };

    const tabs = [
        {
            key: 'view', label: t('Платежи'), children: <PaymentTableApplied applyId={applyId}/>
        },
        {
            key: 'error', label: t('Ошибки'), children: <PaymentTableError/>
        },
    ]

    const onConfirmSingle = (row) => {
        const data = {
            // payment_applies_ids: [dayjs(row.apply_date).format('YYYY-mm-DD')],
            bank_days: [dayjs(row.bank_day).format('YYYY-MM-DD')],
        }
        // debugger
        onConfirmSingleEvent(data)
    }
    const onCancelSingle = (row) => {
        const data = {
            // payment_applies_ids: [dayjs(row.apply_date).format('YYYY-mm-DD')],
            bank_days: [dayjs(row.bank_day).format('YYYY-MM-DD')],
        }
        onCancelAppliesSingleEvent(data)
    }

    const onConfirmAll = () => {
        // const data = {
            // payment_applies_ids: dataPaymentNew.map(item => dayjs(item.apply_date).format('YYYY-mm-DD')),
            // bank_day: dataPaymentNew.map(item => dayjs(item.bank_day).format('YYYY-MM-DD')),
        // }

// FIXME
        const data = {
            bank_days: dataPaymentNew.map(item => {
                return dayjs(item.bank_day).format('YYYY-MM-DD')
            })
        }

        dataPaymentNew.map(item => {
            return dayjs(item.bank_day).format('YYYY-MM-DD')
        })
        // debugger
        // const data_1 = {bank_day: dataPaymentNew.map(item => dayjs(item.bank_day).format('YYYY-MM-DD'))}

        onConfirmSingleEvent(data)
    }

    const onConfirmSelected = () => {
        // const data = {
            // payment_applies_ids: selectedRowData.map(item => dayjs(item.apply_date).format('YYYY-mm-DD')),
            // bank_day: selectedRowData.map(item => dayjs(item.bank_day).format('YYYY-MM-DD')),
        // }

// FIXME
        const data = {
            bank_days: dataPaymentNew.map(item => {
                return dayjs(item.bank_day).format('YYYY-MM-DD')
            })
        }
        onConfirmSingleEvent(data)
    }

    return <>
        <PaymentAppiesGate search={search.toString()}/>

        <FilterBlock items={filters}/>
        <Divider/>
        <Row gutter={[8, 0]}>
            <Col>
                <Popconfirm
                    title={t('Провести распределение по ЛС?')}
                            okText={t('Да')}
                            onConfirm={() => onConfirmAll()}
                            placement={'left'}
                            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                    disabled={actionLoading}
                >
                    <Button type={'primary'} disabled={dataPaymentNew.length <= 0}
                            size={'small'} loading={actionLoading}>
                        Распределить все не распределенные
                        ({dataPaymentNew.length > 0 ? dataPaymentNew.length : 0})
                    </Button>
                </Popconfirm>
            </Col>
            <Col>
                <Popconfirm
                    title={t('Провести распределение по ЛС?')}
                    okText={t('Да')}
                    onConfirm={() => onConfirmSelected()}
                    placement={'left'}
                    disabled={actionLoading}
                    icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                >
                    {/*<Button type={'primary'} disabled={selectedRowData.length > 0 ? false : true} size={'small'}>*/}
                    <Button type={'primary'}
                            disabled={(!(checkStatus === false && selectedRowData.length > 0))}
                            size={'small'} loading={actionLoading}>
                        Распределить отмеченные
                        ({selectedRowData.length > 0 ? selectedRowData.length : 0})
                    </Button>
                </Popconfirm>
            </Col>
            <Col>
                <Button onClick={() => exportXlsAction(search)}>
                    <ExcelOutlined size={18}/>
                    Экспорт
                </Button>
            </Col>


        </Row>
        <br/>
        <Card size={'small'} loading={loading}>
            <div style={{color: 'red', marginLeft: '10px'}}>
                {
                    checkStatus === true ? t('Нельзя пропускать дни в распределении') : ''
                }
            </div>
            <Table
                columns={columns}
                dataSource={keysData}
                size={'small'}
                rowSelection={rowSelection}
                rowClassName={rowClassName}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={{
                    ...paginationConfig, pageSize: 10, // current: parseInt(page),
                    // total: count
                }}
            />
        </Card>
        <Divider/>
        <Tabs
            defaultActiveKey={'view'}
            items={tabs}
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={onChangeTab}
            type={'card'}
        />
    </>
}
