import {useStoreMap} from 'effector-react';
import {$infoMaps} from '../../models/infoModel/index.js';

export default function CreatedBy({id}) {
    const users = useStoreMap($infoMaps, maps => maps.usersMap)

    return <span>
        {id && users[id] ? `${id} ${users[id]}` : '-'}
    </span>
}
