import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import './components/style.css'
import {useStoreMap, useUnit} from "effector-react";
import {
	$paymentRetentionMonthData,
	exportDeductionMonthTableEv,
	exportPaymentAppliesEv,
	getPaymentRetentionMonthDataFx,
	PaymentRetentionMonthGate,
	setDeductionModalDataEv,
} from '../../models/paymentModel/index.js';
import {searchAndActions} from "../../utils/searchAndActions.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import ButtonGroup from "antd/es/button/button-group";
import Tooltip from "antd/es/tooltip";
import {
	EditOutlined, PlusOutlined,
} from "@ant-design/icons";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import Card from "antd/es/card";
import FormatNumber from "../../components/FormatNumber/FormatNumber.jsx";
import {$dictsSelects, $infoMaps} from "../../models/infoModel/index.js";
import DeductionCreateEditModal from "./components/DeductionCreateEditModal.jsx";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import ExcelOutlined from "../../components/ExcelIcon/EcxelIcon.jsx";








function FormattedDates({date}) {
	const dateStr = date.toString();
	const year = dateStr.slice(0, 4);
	const month = dateStr.slice(4, 6);
	return dayjs(`${year}-${month}-01`).format('MM.YYYY');
}

export default function PaymentRetentionMonth() {
	const {t} = useTranslation()
	const {data, count} = useUnit($paymentRetentionMonthData)
	const loading = useUnit(getPaymentRetentionMonthDataFx.pending)
	console.log('data retention', data)
	const {search} = searchAndActions()
	const exportXlsAction = useUnit(exportDeductionMonthTableEv)
	const keysData = data.map(item => {
		return {
			...item, key: item.id
		}
	})


	const YearMonthSelected =  search.toString()

	const DateSelected = YearMonthSelected?.split('&')?.length > 1 ?
		YearMonthSelected?.split('&')[0]?.split('year_month=')[1] :
		YearMonthSelected?.split('&')[0]?.split('year_month=')[1]

	const DateNow = dayjs(Date.now()).format('YYYY-MM-DD')


	const disabledEdit = dayjs(DateNow).isAfter(dayjs(DateSelected), 'month')





	const [checkStatus, setCheckStatus] = useState(false);


	const providerOpts = useStoreMap($dictsSelects, sel => sel.providers)


	const setModalData = useUnit(setDeductionModalDataEv)

	const openModal = (row, type) => {
		const payload = {
			type: type,
			row: row,
		}
		setModalData(payload)
	}



	const columns = [

		{
			title: t('Месяц'),
			dataIndex: 'year_month',
			sorter: true,
			key: 'year_month',
			render: (date) => date ? <FormattedDates date={date} /> : '-'
		},
		{
			title: t('Поставщик'),
			dataIndex: 'provider_id',
			sorter: true,
			key: 'provider_id',
			render: (provider_id, record) => <MappedDictItem id={provider_id} type={'providers'} />
		},
		{
			title: t('Тип удержания'),
			dataIndex: 'count_type',
			sorter: true,
			key: 'count_type',
		},
		{
			title: 'Сумма',
			key: 'summ',
			children: [
				{
					title: 'К удержанию',
					dataIndex: 'amount',
					key: 'amount',
					sorter: true,
					render: (v) => <FormatNumber value={v} type={2}/>
				},
				{
					title: 'Удержано',
					dataIndex: 'deduct_amount',
					key: 'deduct_amount',
					sorter: true,
					render: (v,row) => <FormatNumber value={row.amount - row.amount_left} type={2}/>
				},
				{
					title: 'Осталось удержать',
					dataIndex: 'amount_left',
					key: 'amount_left',
					sorter: true,
					render: (v) => <FormatNumber value={v} type={2}/>
					// render: (v, row) => <FormatNumber value={row.service_amount - row.deduct_amount} type={2}/>
				},
			],
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id, row) => <ButtonGroup>
				<Tooltip title={'Редактировать'}>
					<Button
						type={'primary'}
						size={'small'}
						icon={<EditOutlined />}
						disabled={disabledEdit}
						onClick={() => openModal(row, 'edit')}
					/>
				</Tooltip>
			</ButtonGroup>
		}
	]

	const filters = [
		{name: 'year_month', label: t('Месяц'), type: 'date', object: 'deduction'},
		{name: 'provider_id', label: t('Поставщик'), type: 'select', options: providerOpts},
		{name: 'amount_from', label: t('Сумма остатка от'), type: 'input',},
		{name: 'amount_to', label: t('Сумма остатка до'), type: 'input',},
	]










	return <>
		<PaymentRetentionMonthGate search={search.toString()}/>
		<DeductionCreateEditModal />
		<FilterBlock items={filters}/>
		<Divider/>

		<Card size={'small'}>
			<div style={{color: 'red', marginLeft: '10px'}}>
				{
					checkStatus === true ? t('Нельзя пропускать дни в распределении') : ''
				}
			</div>
			<Row gutter={[16,16]}>
				<Col>
					<Button
						type={'primary'}
						style={{marginBottom: '5px'}}
						icon={<PlusOutlined size={18}/>}
						onClick={() => openModal({}, 'create')}
					>
						Создать удержание
					</Button>
				</Col>

				<Col>
					<Button
						onClick={() => exportXlsAction()}
						disabled={!data.length > 0}
						size={'small'}
					>
						<ExcelOutlined size={18}/>
						Экспорт
					</Button>
				</Col>
			</Row>


			<DictionaryProTable
				data={keysData}
				initColumns={columns}
				size={'small'}
				showActions={false}
				loading={loading}
			/>
		</Card>
		<Divider/>
	</>
}
