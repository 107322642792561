import {sample} from "effector";
import {PaymentsGate} from "./events.js";
import {getPaymentsDataFx, getPaymentsElementsFx} from "./effects.js";
import {$paymentsData, $paymentsElementsData} from "./stores.js";

$paymentsData.on(getPaymentsDataFx.doneData, (_, data) => data)
	.reset(PaymentsGate.close)
$paymentsElementsData.on(getPaymentsElementsFx.doneData, (_, data) => data)
	.reset(PaymentsGate.close)

sample({
	clock: PaymentsGate.state,
	filter: (gate) => Object.hasOwn(gate, 'search'),
	fn: (gate) => gate.search,
	target: [getPaymentsDataFx, getPaymentsElementsFx]
})
