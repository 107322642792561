import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import {useUnit} from 'effector-react';
import {$createDistrictEmbeddedOpen} from '../../../models/dictionaryEmbeddedFormsModel/index.js';

export default function CreateDistrictEmbeddedForm() {
    const {t} = useTranslation()
    const open = useUnit($createDistrictEmbeddedOpen)

    return open && <Card title={t('Свойства района города')} style={{marginBottom: 16}} size={'small'}>
        <Form.Item name={['district', 'name_ru']}
                   label={t('Название (рус)')}
                   rules={[{required: true, message: t('Обязательное поле')}]}
        >
            <Input/>
        </Form.Item>
        <Form.Item name={['district', 'name_kz']}
                   label={t('Название (каз)')}
                   rules={[{required: true, message: t('Обязательное поле')}]}
        >
            <Input/>
        </Form.Item>
    </Card>
}

import Card from 'antd/es/card';
