import {useState} from 'react';

export default function useFormChanged(original, required) {
    const [changedFields, setChangedFields] = useState({})
    const check = (values) => {
        const checked = {}
        for (const [key, value] of Object.entries(values)) {
            if ((!value || value === '') && required.includes(key)) {
                checked[key] = 'error'
            } else if (original[key] !== value) {
                checked[key] = 'warning'
            }
        }
        setChangedFields(checked)
    }

    return [check, changedFields]
}