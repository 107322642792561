import {
    $correctionList,
    $selectedCorrection,
    $createdCorrectionId,
    $finalAction,
    $redirectPath,
    $finalStatus,
    $uploadCorrectionModal,
    $uploadCorrectionStatus,
    $uploadCorrectionMetadata,
    $correctionPeriod,
    $uploadCorrProcessing,
    $correctionHistory,
    $correctionHistoryTariffs,
    $correctionUpdating,
    $prefsSelectOpts,
    $accsFilters,
    $accsTable,
    $accSrvsFilter,
    $foundAccs,
    $excludedAccs,
    $selectedAcc,
    $selectedAccMeta,
    $multiSelectedAccs,
    $srvsByAcc,
    $selectedAccSrv,
    $createSrvModal,
    $massEditSrvModal,
    $afterApplyAction,
    $psData,
    $psTarifs,
    $tarifCreated,
    $srvCreateEditDone,
    $accSrvsMap,
    afterActionsEnum,
    $correctionHistoryTable,
    $uploadConfirmedStatus,
    $uploadConfirmedMeta
} from './stores.js';
import {
    getCorrectionListFx,
    getCorrectionByIdFx,
    finishCorrectionFx,
    cancelCorrectionFx,
    createCorrectionFx,
    deleteCorrectionFx,
    uploadCorrFx,
    getUploadCorrMetaFx,
    uploadCorrConfirmFx,
    uploadCorrCancelFx,
    getCorrectionHistoryFx,
    getPrefsSelectsFx,
    getAccsFx,
    getPSTarifsFx,
    submitTempCorrectionFx,
    downloadCorrUploadErrorsFx,
    rollbackCorrectionFx, submitAccountTempCorrFx, downloadHistoryFx,
} from './effects.js';
import {
    CorrectionListGate,
    cancelCorrectionEv,
    createCorrectionEv,
    finishCorrectionEv,
    resetCreatedCorrectionEv,
    setFinalActionEv,
    resetFinalActionEv,
    deleteCorrectionEv,
    ProvSrvAccCorrectionGate,
    setUploadCorrModalEv,
    resetUploadCorrModalEv,
    uploadCancelEv,
    uploadCorrEv,
    startUploadTimeout,
    stopUploadTimeout,
    uploadConfirmEv,
    getCorrUploadErrorsEv,
    searchAccsEv,
    resetAccsEv,
    setTableParamsEv,
    selectCorrectionPeriodEv,
    setAccSrvsFilterEv,
    resetAccSrvsFilterEv,
    addExcludedAccEv,
    removeExcludedAccEv,
    setSelectedAccEv,
    resetSelectedAccEv,
    setMultiSelectedAccsEv,
    resetSelectedAccSrvEv,
    setSrvCreateModalEv,
    resetSrvCreateModalEv,
    setMassEditModalEv,
    resetMassEditModalEv,
    setAfterActionEv,
    getPSTarifsEv,
    selectAccSrvEv,
    createTariffEv,
    submitAddSingleCorrEv,
    submitEditSingleCorrEv,
    submitTempCorrsMassEv,
    deleteSingleCorrectionEv,
    deleteAllTempCorrectionsEv,
    setJustificationEv,
    ViewCorrectionGate,
    rollbackCorrectionEv,
    submitAccountTempCorrEv,
    signalCorrSuccessEv,
    setCorrHistTableEv,
    setUploadConfirmedMetaEv,
    downloadHistoryEv,
} from './events.js';
import {sample} from 'effector';
import {$info, $userPerms} from '../infoModel/index.js';
import {combineEvents, interval} from 'patronum';
import {isId} from '../../utils/helpers.js';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {prepareMassPayload, preparePrefsSelects} from './utils.js';
import queryString from 'query-string';
import notification from 'antd/es/notification';
import i18n from '../../i18n.js';
import permitted from "../../utils/permitted.js";

$correctionList.on(getCorrectionListFx.doneData, (_, data) => data)
    .reset(CorrectionListGate.close)

$selectedCorrection.on(getCorrectionByIdFx.doneData, (_, data) => data)
    .reset([ProvSrvAccCorrectionGate.close, ViewCorrectionGate.close])

$createdCorrectionId.on(createCorrectionFx.doneData, (_, res) => res)
    .reset([resetCreatedCorrectionEv, $selectedCorrection.updates])

$finalAction.on(setFinalActionEv, (_, data) => data)
    .reset(resetFinalActionEv)
$finalStatus.on(finishCorrectionFx.doneData, () => true)
    .reset([resetFinalActionEv, resetCreatedCorrectionEv])

sample({
    clock: CorrectionListGate.close,
    target: resetCreatedCorrectionEv
})

sample({
    selected: $selectedCorrection,
    clock: ProvSrvAccCorrectionGate.state,
    filter: (selected, gate) => Object.hasOwn(gate, 'id'),
    fn: (selected, gate) => {
        return +gate.id
    },
    target: getCorrectionByIdFx
})

sample({
    source: CorrectionListGate.state,
    clock: [CorrectionListGate.state, deleteCorrectionFx.doneData],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => {
        const defaultParams = {
            limit: '10',
            page: '1',
            sort_field: 'created_at',
            sort_order: 'desc',
        }
        const paramsToSet = {}
        Object.entries(defaultParams).forEach(([key, value]) => {
            if (!gate.search.has(key)) {
                paramsToSet[key] = value
            }
        });
        if (Object.keys(paramsToSet).length) {
            return queryString.stringify({...queryString.parse(gate.search.toString(), {parseNumbers: true, parseBooleans: true}), ...paramsToSet})
        } else {
            return gate.search.toString()
        }
    },
    target: getCorrectionListFx
})

sample({
    source: $info.map(i => i.current_date),
    clock: createCorrectionEv,
    filter: (date) => !!date && dayjs(date).isValid(),
    fn: (date, payload) => {
        const checked = {...payload}
        if (!checked.object_type) {
            checked.object_type = 'account_provider_service'
        }
        if (!checked.variant) {
            checked.variant = 'attrs'
        }
        checked['period'] = dayjs(date).format()
        // const curUrl = window.location.pathname
        // localStorage.setItem('corFrom', curUrl)
        return checked
    },
    target: createCorrectionFx
})


sample({
    source: $selectedCorrection,
    clock: finishCorrectionEv,
    fn: (selected) =>  selected.data.id,
    target: finishCorrectionFx
})

sample({
    clock: deleteCorrectionEv,
    target: deleteCorrectionFx
})


sample({
    source: $selectedCorrection,
    clock: cancelCorrectionEv,
    fn: (selected) => selected && selected?.data?.id,
    target: cancelCorrectionFx
})

sample({
    source: $finalAction,
    clock: [finishCorrectionFx.doneData, cancelCorrectionFx.doneData],
    filter: (afterAction) => afterAction && afterAction === 'close',
    fn: () => {
        // return localStorage.getItem('corFrom') ?? '/corrections'
        return '/corrections'
    },
    target: $redirectPath
})

sample({
    source: {current: $selectedCorrection, finalAction: $finalAction},
    clock: finishCorrectionFx.doneData,
    filter: ({finalAction}) => finalAction && finalAction === 'resume',
    fn: ({current}) => {
        const payload = {
            mass: current?.data?.mass,
            object_type: current?.data?.object_type,
            variant: current?.data?.variant,
            service_accounts_set: current?.data?.service_accounts_set
        }
        if (current?.data?.mass === true) {
            payload['service_id'] = current?.data?.service_id
            payload['provider_id'] = current?.data?.provider_id
        }
        return payload
    },
    target: createCorrectionFx
})

sample({
    clock: rollbackCorrectionEv,
    target: rollbackCorrectionFx
})

rollbackCorrectionFx.doneData.watch(() => {
    notification.success({message: i18n.t('Успешно'), description: i18n.t('Откат корректировки завершен')})
})

// correction itself

export const tarifCreatedEv = combineEvents({events: {trigger: createTariffEv, response: triggerSuccessOpsEv}, reset: $psData.updates})

// создание/редактирование PS из модалки завершено
export const corrAddCompleteEv = combineEvents({events: {trigger: submitAddSingleCorrEv, response: submitTempCorrectionFx.doneData}, reset: $foundAccs.updates})
export const corrEditCompleteEv = combineEvents({events: {trigger: submitEditSingleCorrEv, response: submitTempCorrectionFx.doneData}, reset: $foundAccs.updates})
export const corrMassCompleteEv = combineEvents({events: {trigger: submitTempCorrsMassEv, response: submitTempCorrectionFx.doneData}, reset: $foundAccs.updates})

// upload corrs
const {tick: uploadInterval} = interval({timeout: 20000, start: startUploadTimeout, stop: stopUploadTimeout})

$uploadCorrectionModal.on(setUploadCorrModalEv, (_, open) => open)
    .reset([ProvSrvAccCorrectionGate.close, resetUploadCorrModalEv])

$uploadCorrectionStatus.on(uploadCorrFx.doneData, () => true)
    .reset(resetUploadCorrModalEv)

$uploadCorrectionMetadata.on(getUploadCorrMetaFx.doneData, (_, data) => data)
    .reset([ProvSrvAccCorrectionGate.close, uploadCancelEv, uploadCorrEv])

$uploadConfirmedStatus.on(uploadCorrConfirmFx.doneData, () => true)
    .reset([ProvSrvAccCorrectionGate.close, uploadCancelEv, uploadCorrEv])

$uploadConfirmedMeta.reset([ProvSrvAccCorrectionGate.close, uploadCorrEv])


sample({
    source: $correctionPeriod,
    clock: uploadCorrEv,
    fn: (period, payload) => ({selPeriod: period, formValues: payload}),
    target: uploadCorrFx
})

sample({
    source: $uploadCorrProcessing,
    clock: $uploadCorrectionMetadata.updates,
    filter: (processing, meta) => !processing && (meta?.status === 'processing' || meta?.status === 'waiting'),
    target: startUploadTimeout
})

sample({
    source: $uploadCorrProcessing,
    clock: $uploadCorrectionMetadata.updates,
    filter: (processing, meta) => processing && !(meta?.status === 'processing' || meta?.status === 'waiting'),
    target: stopUploadTimeout
})

sample({
    clock: $uploadCorrectionMetadata.updates,
    filter: meta => !!meta,
    fn: meta => (meta?.status === 'processing' || meta?.status === 'waiting'),
    target: $uploadCorrProcessing
})

sample({
    clock: uploadCorrFx.doneData,
    fn: ({data}) => data[0]?.id,
    target: getUploadCorrMetaFx
})

sample({
    source: $uploadCorrectionMetadata,
    clock: uploadInterval,
    filter: (meta) => (meta?.status === 'processing' || meta?.status === 'waiting') && isId(meta?.id),
    fn: (meta) => meta.id,
    target: getUploadCorrMetaFx
})

sample({
    source: $uploadCorrectionMetadata,
    clock: uploadConfirmEv,
    fn: (meta) => meta.id,
    target: uploadCorrConfirmFx
})

sample({
    source: $uploadCorrectionMetadata,
    clock: uploadCancelEv,
    fn: (meta) => meta.id,
    target: uploadCorrCancelFx
})

sample({
    source: $uploadCorrectionMetadata,
    clock: getCorrUploadErrorsEv,
    fn: (meta) => meta.id,
    target: downloadCorrUploadErrorsFx
})

sample({
    source: $selectedCorrection,
    clock: setUploadConfirmedMetaEv,
    filter: (corr, meta) => meta?.correction_id === corr?.data?.id,
    fn: (corr, meta) => meta,
    target: $uploadConfirmedMeta
})
// ---

$correctionHistory.on(getCorrectionHistoryFx.doneData, (state, payload) => payload)
    .reset([ProvSrvAccCorrectionGate.close, ViewCorrectionGate.close])

$correctionHistoryTariffs.on(getCorrectionHistoryFx.doneData, (state, {tariffs}) => Object.fromEntries((tariffs ?? []).map(t => [t.id, {value: t.value, service_unit_id: t.service_unit_id}])))
    .reset([ProvSrvAccCorrectionGate.close, ViewCorrectionGate.close])

$correctionHistoryTable
    .on(setCorrHistTableEv, (state, payload) => {
        const upd = {...state}
        for (const {key, value} of Object.values(payload)) {
            upd[key] = value
        }
        return upd
    })
    .reset([ProvSrvAccCorrectionGate.close, ViewCorrectionGate.close])
$correctionHistoryTable.watch(state => console.log('CORR HIST TABLE', state))
ViewCorrectionGate.close.watch(state => console.log('VIEW CORR GATE CLOSE', state))

$correctionUpdating.on(submitGlobalUpdateEv, () => true)
    .reset(triggerSuccessOpsEv)

$prefsSelectOpts.on(getPrefsSelectsFx.doneData, (_, {data}) => preparePrefsSelects(data))
    .reset(ProvSrvAccCorrectionGate.close)

$correctionPeriod.on($selectedCorrection.updates, (_, cor) => cor?.data?.service_accounts_set ?? undefined)
    .reset([ProvSrvAccCorrectionGate.close])

$accsFilters.on(searchAccsEv, (_, filters) => filters)
    .reset([resetAccsEv, ProvSrvAccCorrectionGate.close])

$accsTable
    .on(setTableParamsEv, (state, payload) => {
        const upd = {...state}
        for (const {key, value} of Object.values(payload)) {
            upd[key] = value
        }
        return upd
    })
    .reset([resetAccsEv, ProvSrvAccCorrectionGate.close, selectCorrectionPeriodEv])

$accSrvsFilter.on(setAccSrvsFilterEv, (state, payload) => ({...state, ...payload}))
    .reset(resetAccSrvsFilterEv)

$foundAccs.on(getAccsFx.doneData, (_, payload) => payload)
    .reset([resetAccsEv, ProvSrvAccCorrectionGate.close, finishCorrectionFx.doneData])

$excludedAccs.on(addExcludedAccEv, (state, id) => [...state, id])
    .on(removeExcludedAccEv, (state, id) => state.filter(i => i !== id))
    .reset([$accsFilters.updates, selectCorrectionPeriodEv, ProvSrvAccCorrectionGate.close])

$selectedAcc.on(setSelectedAccEv, (_, id) => id)
    .reset([$accsFilters.updates, resetSelectedAccEv, resetAccsEv, setTableParamsEv, ProvSrvAccCorrectionGate.close])

$selectedAccMeta.reset([$accsFilters.updates, resetSelectedAccEv, resetAccsEv, setTableParamsEv])

$multiSelectedAccs.on(setMultiSelectedAccsEv, (_, ids) => ids)
    .on($excludedAccs.updates, (state, excl) => state.filter(i => !excl.includes(i)))
    .reset([$accsFilters.updates, resetAccsEv, selectCorrectionPeriodEv, ProvSrvAccCorrectionGate.close])

$srvsByAcc.reset([$accsFilters.updates, setSelectedAccEv, resetSelectedAccEv, ProvSrvAccCorrectionGate.close]) // FIXME  resetFinalActionEv?

$selectedAccSrv.reset([$accsFilters.updates, resetSelectedAccSrvEv, ProvSrvAccCorrectionGate.close])

$createSrvModal.on(setSrvCreateModalEv, (_, modal) => modal)
    .reset(resetSrvCreateModalEv)

$massEditSrvModal.on(setMassEditModalEv, (_, modal) => modal)
    .reset(resetMassEditModalEv)

$afterApplyAction.on(setAfterActionEv, (_, action) => action)
    .reset(setSelectedAccEv, setMultiSelectedAccsEv, setTableParamsEv)

$psData.on(getPSTarifsFx.doneData, (_, payload) => {
    const data = ((payload?.data || [])[0] || {})
    return {
        provider_id: data.provider_id,
        service_id: data.service_id,
        provider_service_id: data.id,
        date_from: data.date_from,
        date_to: data.date_to,
    }
})

$psTarifs.on(getPSTarifsFx.doneData, (_, payload) => {
    const data = ((payload?.data ?? [])[0] ?? {})?.tarifs ?? []
    return data
})
    .reset([resetSelectedAccSrvEv, resetSrvCreateModalEv, resetMassEditModalEv])

$tarifCreated.on(tarifCreatedEv, (state, {response}) => {
    return response[0]?.value?.id
})
    .reset([getPSTarifsEv, $foundAccs.updates])

$srvCreateEditDone.on([corrAddCompleteEv, corrEditCompleteEv, corrMassCompleteEv], () => true)
    .reset([resetSelectedAccSrvEv, selectAccSrvEv])
//
// sample({
//     clock: ProvSrvAccCorrectionGate.open,
//     target: getPrefsSelectsFx
// })

sample({
    source: {
        perms: $userPerms,
        gate: ProvSrvAccCorrectionGate.state,
    },
    clock: [$userPerms, ProvSrvAccCorrectionGate.open],
    filter: (data) => {
        const withId = Object.hasOwn(data?.gate, 'id')
        const perms = data?.perms || {}
        const pOk = permitted([`provider_service_preference::view`], perms)
        return withId && pOk
    },
    fn: (data) => +(data?.gate?.id),
    target: getPrefsSelectsFx,
})


sample({
    source: $selectedCorrection,
    clock: $uploadConfirmedMeta.updates,
    filter: (corr, meta) => meta?.status === 'processed' && meta?.correction_id === corr?.data?.id,
    fn: corr => corr?.data?.id,
    target: getCorrectionByIdFx
})

sample({
    source: $selectedCorrection,
    clock: $uploadConfirmedMeta.updates,
    filter: (corr, meta) => meta?.status === 'processed' && meta?.correction_id === corr?.data?.id,
    fn: corr => ({id: corr?.data?.id}),
    target: getCorrectionHistoryFx
})

sample({
    source: ProvSrvAccCorrectionGate.open,
    clock: $selectedCorrection.updates,
    filter: (gate, corr) => gate && corr && corr?.data?.has_corrections === true,
    fn: (_gate, corr) => ({id: corr?.data?.id}),
    target: getCorrectionHistoryFx
})

sample({
    source: {gate: ProvSrvAccCorrectionGate.status, corr: $selectedCorrection},
    clock: signalCorrSuccessEv,
    filter: ({gate}) => !!gate,
    fn: ({corr}) => ({id: corr?.data?.id}),
    target: getCorrectionHistoryFx
})

sample({
    source: $selectedCorrection,
    clock: ViewCorrectionGate.state,
    filter: (corr, gate) => !isId(corr?.data?.id) && Object.hasOwn(gate, 'id') && isId(gate?.id),
    fn: (_corr, gate) => gate?.id,
    target: getCorrectionByIdFx
})

sample({
    source: {corr: $selectedCorrection, table: $correctionHistoryTable},
    clock: $correctionHistoryTable.updates,
    filter: ({corr}) => isId(corr?.data?.id),
    fn: ({corr, table}) => ({id: corr?.data?.id, table: queryString.stringify(table, {skipEmptyString: true, skipNull: true})}),
    target: getCorrectionHistoryFx
})

sample({
    source: $selectedCorrection,
    clock: ViewCorrectionGate.state,
    filter: (corr, gate) => !isId(corr?.data?.id) && Object.hasOwn(gate, 'id') && isId(gate?.id),
    fn: (_, gate) => ({id: gate?.id}),
    target: getCorrectionHistoryFx,
})

sample({
    clock: downloadHistoryEv,
    target: downloadHistoryFx
})

sample({
    source: $selectedCorrection,
    clock: rollbackCorrectionFx.doneData,
    filter: (corr) => isId(corr?.data?.id),
    fn: (corr) => corr?.data?.id,
    target: getCorrectionByIdFx
})

sample({
    clock: [getPSTarifsEv],
    filter: ({provider_id, service_id}) => !!isId(provider_id) && isId(service_id),
    fn: (payload) => payload,
    target: getPSTarifsFx
})

sample({
    source: {ps: $psData},
    clock: createTariffEv,
    filter: ({ps}) => !!ps?.provider_service_id,
    fn: ({ps}, payload) => {
        const {tarif_service_unit_id: service_unit_id, tarif_value: value} = payload
        return [
            {
                operation: 'create',
                type: 'tarif',
                value: {
                    service_unit_id,
                    value: +value,
                    provider_service_id: ps?.provider_service_id,
                    id: -1
                }
            }
        ]
    },
    target: submitGlobalUpdateEv
})

//////////////////////////////
sample({
    clock: submitTempCorrectionFx.doneData,
    target: signalCorrSuccessEv
})

sample({
    clock: submitAccountTempCorrFx.doneData,
    filter: (ev) => ev.every((item) => item?.success === true),
    target: signalCorrSuccessEv
})

sample({
    source: {table: $accsTable, filters: $accsFilters, correction: $selectedCorrection, period: $correctionPeriod},
    clock: [$accsTable.updates, $accsFilters.updates, signalCorrSuccessEv, $correctionPeriod.updates],
    filter: ({filters}) => Object.keys(filters).length > 0,
    fn: ({table, filters, correction, period}) => {
        const checked = {...filters}
        checked['service_accounts_set'] = period
        return queryString.stringify({...checked, ...table, correction_id: correction?.data?.id}, {skipEmptyString: true, skipNull: true})
    },
    target: getAccsFx
})

sample({
    source: {table: $accsTable, filters: $accsFilters, correction: $selectedCorrection, period: $correctionPeriod,},
    clock: tarifCreatedEv,
    filter: ({correction}) => isId(correction?.data?.id),
    fn: ({table, filters, correction, period}) => {
        const checked = {...filters}
        checked['service_accounts_set'] = period
        return queryString.stringify({...checked, ...table, correction_id: correction?.data?.id}, {skipEmptyString: true, skipNull: true})
    },
    target: getAccsFx
})

sample({
    source: $foundAccs,
    clock: setSelectedAccEv,
    filter: ({data}, id) => data?.length > 0 && isId(id),
    fn: ({data}, id) => data.find(a => a.id === id),
    target: $selectedAccMeta
})

sample({
    source: {map: $accSrvsMap, selected: $selectedAcc},
    clock: [$selectedAcc.updates, $accSrvsMap.updates],
    fn: ({map, selected}) => {
        const existing = map[selected]
        if (existing) {
            const psIds = existing?.services?.map(s => s.provider_service_id)
            const added = existing?.corrections?.filter(i => !psIds.includes(i.provider_service_id))
            if (added.length > 0) {
                for (const [idx, newSrv] of Object.entries(added)) {
                    const formatted = {newSrv: true}
                    for (const [key, value] of Object.entries(newSrv)) {
                        if (['tarif_id', 'preferences_new', 'external_number_new', 'iin_new', 'ex_payment_value', 'saldo_end_value', 'people_quantity_new', 'service_square_new', 'is_not_accrued_new', 'is_not_balance_transfer_new', 'is_not_printed_new', 'provider_id_new', 'service_id_new'].includes(key) && value) {
                            if (key === 'ex_payment_value') {
                                formatted['ex_payments'] = value
                                continue
                            } else if (key === 'saldo_end_value') {
                                formatted['sum_saldo_end'] = value
                                continue
                            }
                            formatted[key.replace('_new', '')] = value
                        } else if (['date_from_new', 'date_to_new'].includes(key) && value) {
                            formatted[key.replace('_new', '')] = dayjs(value)
                        } else if ((key === 'provider_id' || key === 'service_id' || key === 'provider_service_id') && value) {
                            formatted[key] = value
                        }
                    }

                    added[idx] = {...formatted}
                }
            }

            for (const [idx, srv] of Object.entries(existing?.services)) {
                const isCorrected = existing?.corrections?.find(c => c.provider_service_id === srv.provider_service_id)
                if (isCorrected) {
                    existing['services'][idx]['tarif_id'] = isCorrected?.tarif_id
                }
            }
            return {services: [...existing?.services, ...added], corrections: existing?.corrections}
        } else return {services: [], corrections: []}
    },
    target: $srvsByAcc
})

sample({
    source: $srvsByAcc,
    clock: selectAccSrvEv,
    filter: (acc, id) => acc?.services?.length > 0 && isId(id),
    fn: (acc, id) => {
        let srv = acc.services.find(s => s.provider_service_id === id)

        const srvCorr = acc.corrections.find(corr => corr.provider_service_id === id)
        if (srvCorr) {
            for (const [key, value] of Object.entries(srvCorr)) {
                if (['provider_id_new', 'service_id_new', 'tarif_id', 'preferences_new', 'external_number_new', 'iin_new', 'ex_payment_value', 'saldo_end_value', 'people_quantity_new', 'service_square_new', 'is_not_accrued_new', 'is_not_balance_transfer_new', 'is_not_printed_new'].includes(key) && value) {
                    if (key === 'ex_payment_value') {
                        srv['ex_payments'] = value
                        continue
                    } else if (key === 'saldo_end_value') {
                        srv['sum_saldo_end'] = value
                        continue
                    }
                    srv[key.replace('_new', '')] = value
                } else if (['date_from_new', 'date_to_new'].includes(key) && value) {
                    srv[key.replace('_new', '')] = dayjs(value)
                }
            }
        }

        return srv
    },
    target: $selectedAccSrv
})

sample({
    source: {selected: $selectedAcc, correction: $selectedCorrection},
    clock: submitAddSingleCorrEv,
    filter: ({selected}) => !!selected,
    fn: ({selected, correction}, payload) => {
        const formValues = {...payload}
        for (const [key, value] of Object.entries(formValues)) {
            if (!['tarif_id', 'tarif_value', 'tarif_date_from', 'tarif_service_unit_id', 'provider_id', 'service_id', 'sum_saldo_begin'].includes(key)) {
                formValues[`${key}_new`] = value
                delete formValues[key]
            }
        }
        if (formValues?.preferences_new && formValues?.preferences_new?.length > 0) {
            formValues.preferences_new = formValues.preferences_new.filter(i => i?.id !== -1)
        }
        const prov = formValues.provider_id
        const serv = formValues.service_id
        delete formValues.provider_id
        delete formValues.service_id

        for (const [key, value] of Object.entries(formValues)) {
            if (value === null || value === undefined) {
                delete formValues[key]
            } else if (key === 'tarif_id' && value < 0) {
                delete formValues[key]
            } else if ((key.includes('_from') || key.includes('_to')) && value && dayjs(value).isValid()) {
                if (key.startsWith('not_')) {
                    if (key.includes('_from')) {
                        formValues[key] = dayjs(value).date(dayjs(value).startOf('month').date()).format()
                    } else if (key.includes('_to')) {
                        formValues[key] = dayjs(value).date(dayjs(value).endOf('month').date()).format()
                    }
                } else {
                    formValues[key] = dayjs(value).format()
                }
            }
        }

        const result = {
            correction_id: correction?.data?.id,
            values: [{
                filters: {
                    account_in: selected.toString(),
                },
                values: [{
                    provider_id: prov,
                    service_id: serv,
                    values: {...formValues, provider_id_new: prov, service_id_new: serv}
                }]
            }]
        }

        return result
    },
    target: submitTempCorrectionFx
})

sample({
    source: {selectedAcc: $selectedAcc, selectedSrv: $selectedAccSrv, correction: $selectedCorrection},
    clock: submitEditSingleCorrEv,
    filter: ({selectedSrv}) => !!selectedSrv,
    fn: ({selectedAcc, selectedSrv, correction}, payload) => {
        const filteredValues = {...payload}
        for (const [key, value] of Object.entries(filteredValues)) {
            if (!['tarif_id', 'tarif_value', 'tarif_date_from', 'tarif_service_unit_id', 'sum_saldo_begin', 'saldo_end_value', 'ex_payment_value', 'correction_value'].includes(key)) {
                filteredValues[`${key}_new`] = value
                delete filteredValues[key]
            }
        }

        if (filteredValues?.preferences_new && filteredValues?.preferences_new?.length > 0) {
            filteredValues.preferences_new = filteredValues.preferences_new.filter(i => i?.id !== -1)
        }

        const prepped = {
            filters: {account_in: selectedAcc.toString()},
            values: [{
                provider_id: selectedSrv?.provider_id,
                service_id: selectedSrv?.service_id,
                values: Object.fromEntries(
                    Object.entries(filteredValues)
                        .filter(([key, value]) => (value !== undefined && (key === 'date_to_new' ? true : value !== null)))
                        .map(([key, value]) => {
                            if (key.includes('_from') || key.includes('_to') && dayjs(value).isValid()) {
                                if (key.startsWith('not_')) {
                                    if (key.includes('_from')) {
                                        return [key, dayjs(value).date(dayjs(value).startOf('month').date()).format()]
                                    } else if (key.includes('_to')) {
                                        return [key, dayjs(value).date(dayjs(value).endOf('month').date()).format()]
                                    }
                                } else {
                                    return [key, dayjs(value).format()]
                                }
                            } else return [key, value]
                        }))
            }]
        }

        const result = {
            correction_id: correction?.data?.id,
            values: [prepped]
        }

        return result
    },
    target: submitTempCorrectionFx
})

sample({
    source: {
        selectedAccs: $multiSelectedAccs,
        action: $afterApplyAction,
        filters: $accsFilters,
        correction: $selectedCorrection,
        excluded: $excludedAccs,
        period: $correctionPeriod,
        modal: $massEditSrvModal
    },
    clock: submitTempCorrsMassEv,
    fn: ({period, selectedAccs, excluded, modal, correction, filters}, payload) => {
        let massFilters = {}
        if (modal.type === afterActionsEnum.mass_all) {
            massFilters = {...filters}
            if (excluded?.length > 0) {
                massFilters['account_not_in'] = excluded
            }
        }
        const filtersChecked = modal.type === afterActionsEnum.mass_all
            ? massFilters
            : {account_in: selectedAccs.join(', ')}


        for (const [key, value] of Object.entries(filtersChecked)) {
            if (value === null || value === undefined || value === '') {
                delete filtersChecked[key]
            } else if (key === 'street_id' && value) {
                filtersChecked[key] = [value]
            }
        }

        const formValues = {...payload}

        for (const [key, value] of Object.entries(formValues)) {
            if (!['tarif_id', 'tarif_value', 'not_transfer_saldo', 'tarif_date_from', 'tarif_service_unit_id', 'sum_saldo_begin', 'saldo_end_value', 'ex_payment_value', 'correction_value'].includes(key)) {
                formValues[`${key}_new`] = value
                delete formValues[key]
            }
        }

        const srvsPrepped = []

        if (modal.type === afterActionsEnum.mass_selected) {
            for (const acc of selectedAccs) {
                const prepped = {
                    provider_id: filters?.provider_id,
                    service_id: filters?.service_id,
                    values: prepareMassPayload(formValues),
                    delete: false,
                }
                if (isId(formValues?.provider_id_new) && formValues?.provider_id_new !== filters?.provider_id) {
                    prepped['change_provider'] = true
                }

                srvsPrepped.push(prepped)
            }
        } else if (modal.type === afterActionsEnum.mass_all) {
            const checkedPayload = {
                provider_id: filters?.provider_id,
                service_id: filters?.service_id,
                values: prepareMassPayload(formValues),
                delete: false,
            }
            if (isId(formValues?.provider_id_new) && formValues?.provider_id_new !== filters?.provider_id) {
                checkedPayload['change_provider'] = true
            }
            srvsPrepped.push(checkedPayload)
        }

        const result = {
            correction_id: correction?.data?.id,
            ignore_existed: false,
            values: [{
                filters: filtersChecked,
                values: srvsPrepped
            }]
        }

        return result
    },
    target: submitTempCorrectionFx
})

sample({
    source: {acc: $selectedAccMeta, corr: $selectedCorrection},
    clock: submitAccountTempCorrEv,
    filter: ({acc, corr}) => isId(acc?.id) && !!corr,
    fn: ({acc, corr}, payload) => {
        const checked = {...payload}
        for (const [key, value] of Object.entries(checked)) {
            if (value === acc[key]) {
                delete checked[key]
            }
        }

        for (const [key, value] of Object.entries(checked)) {
            checked[`${key}_new`] = value
            delete checked[key]
        }

        return [
            {
                operation: 'create',
                type: 'correction_account',
                value: {
                    ...checked,
                    account_id: acc?.id,
                    correction_id: corr?.data?.id,
                    id: -1
                }
            }
        ]
    },
    target: submitAccountTempCorrFx
})

sample({
    source: {correction: $selectedCorrection},
    clock: deleteSingleCorrectionEv,
    fn: ({correction}, payload) => {
        return {
            correction_id: correction?.data?.id,
            values: [
                {
                    filters: {account_in: payload.account_id.toString()},
                    values: [{
                        provider_id: payload.provider_id,
                        service_id: payload.service_id,
                        delete: true
                    }]
                }
            ]
        }
    },
    target: submitTempCorrectionFx
})

sample({
    source: $selectedCorrection,
    clock: deleteAllTempCorrectionsEv,
    fn: (correction) => {
        return {correction_id: correction?.data?.id, clear_all: true, values: []}
    },
    target: submitTempCorrectionFx
})

sample({
    source: {correction: $selectedCorrection, date: $info.map(s => s.current_date)},
    clock: selectCorrectionPeriodEv,
    filter: (_, per) => typeof per === 'string' && per?.length > 0,
    fn: ({correction, date}, per) => {
        const calcDate = per === 'current'
            ? dayjs(dayjs(date).startOf('month')).format()
            : dayjs(dayjs(date).endOf('month').add(1)).format()

        return [{
            operation: 'update',
            type: 'correction',
            value: {
                object_id: correction?.data?.id,
                service_accounts_set: per,
                period: calcDate
            }}]
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedCorrection,
    clock: setJustificationEv,
    filter: (correction) => !!correction?.data?.id,
    fn: (correction, text) => {
        return [{operation: 'update', type: 'correction', value: {object_id: correction?.data?.id, justification: text}}]
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {correction: $selectedCorrection},
    clock: setSelectedAccEv,
    filter: ({correction}, accId) => !!correction?.data?.id && correction?.data?.account_id !== accId,
    fn: ({correction}, accId) => {
        return [{operation: 'update', type: 'correction', value: {object_id: correction?.data?.id, account_id: accId}}]
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedCorrection,
    clock: $accsFilters,
    filter: (correction, filters) => correction?.data?.filters && JSON.stringify(correction?.data?.filters) !== JSON.stringify(filters),
    fn: (correction, filters) => {
        return [{operation: 'update', type: 'correction', value: {object_id: correction?.data?.id, filters}}]
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: triggerSuccessOpsEv,
    filter: (data) => data[0]?.type === 'correction' && data[0]?.success === true,
    fn: (data) => data[0]?.value?.id,
    target: getCorrectionByIdFx
})

sample({
    source: $selectedCorrection,
    clock: submitTempCorrectionFx.doneData,
    filter: (correction) => isId(correction?.data?.id),
    fn: (correction) => correction?.data?.id,
    target: getCorrectionByIdFx
})

signalCorrSuccessEv.watch(() => notification.success(
        {
            message: i18n.t('Успешно!'),
            description: i18n.t('Изменения внесены в корректировку')
        }
    ))

submitTempCorrectionFx.failData.watch((err) => {
    if (err?.response?.status === 400) {
        notification.error({message: i18n.t('Ошибка'), description: i18n.t('У данного ЛС уже есть действующий поставщик с выбранной услугой')})
    }
})

finishCorrectionFx.doneData.watch(() => notification.warning({message: i18n.t('Корректировка завершена'), description: i18n.t('Перерасчет начислений произведен.')}))
