import {
    $afterCreateAction, $createdTownIdModal,
    $createdDistrictId, $deletedStreets,
    $districtsList, $editDistrictStatus, $selectedDistrict,
    $selectedDistStreets
} from './stores.js';
import {
    getDistrictByIdFx,
    getDistrictsListFx,
} from './effects.js';
import {
    deleteDistrictEv,
    DistrictCreateGate,
    DistrictEditGate,
    DistrictsListGate,
    DistrictViewGate,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    submitCreateDistrictEv,
    submitEditDistrictEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createDistrictCompleteEv = combineEvents({events: {ev: submitCreateDistrictEv, response: triggerSuccessOpsEv}, reset: DistrictCreateGate.state})
const editDistrictCompleteEv = combineEvents({ev: submitEditDistrictEv, response: triggerSuccessOpsEv})
const deleteDistrictCompleteEv = combineEvents({ev: deleteDistrictEv, response: triggerSuccessOpsEv})

$districtsList.on(getDistrictsListFx.doneData, (_, districts) => districts)
    .reset(DistrictsListGate.close)

$selectedDistrict.on(getDistrictByIdFx.doneData, (_, dist) => dist)
    .reset([DistrictViewGate.close, DistrictEditGate.close])

// $selectedDistStreets
//     .on(getDistrictStreetsFx.doneData, (_, dists) => dists)
//     .reset([resetDistStreetsEv, DistrictEditGate.close, DistrictViewGate.close])

// $selectedDistStreetsImmutable
//     .on(getDistrictStreetsFx.doneData, (_, dists) => dists)
//     .reset([resetDistStreetsEv, DistrictEditGate.close, DistrictViewGate.close])

$createdDistrictId.on(createDistrictCompleteEv, (_, {response}) => {
    
    const town_distr = response.find(i => i.type === 'town_distr')
    return town_distr ? town_distr?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editDistrictStatus.on(editDistrictCompleteEv, () => true)
    .reset(DistrictEditGate.close)

$createdTownIdModal.reset([DistrictCreateGate.close, DistrictEditGate.close])

sample({
    clock: DistrictsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getDistrictsListFx
})

sample({
    source: DistrictsListGate.state,
    clock: [createDistrictCompleteEv, editDistrictCompleteEv, deleteDistrictCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getDistrictsListFx
})

sample({
    clock: DistrictEditGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getDistrictByIdFx
})

sample({
    clock: DistrictViewGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getDistrictByIdFx
})

sample({
    source: $selectedDistStreets,
    clock: submitCreateDistrictEv,
    fn: (streets, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.town_id < 0) {
            actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            payload.town_id = idCounter
            idCounter--
            delete payload.town
        }
        actions.push({operation: 'create', type: 'town_distr', value: {...payload, id: idCounter}})
        idCounter--

        // if (streets?.length > 0) {
        //     for (const newStreet of Object.values(streets)) {
        //         idCounter--
        //         actions.push({operation: 'create', type: 'street', value: {...newStreet, id: idCounter}})
        //     }
        // }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {current: $selectedDistrict, streets: $selectedDistStreets, deletedStreets: $deletedStreets},
    clock: submitEditDistrictEv,
    fn: (src, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.town_id < 0) {
            actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            payload.town_id = idCounter
            idCounter--
            delete payload.town
        }

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (src.current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'town_distr', value: {object_id: src.current.id, ...changedFields}})

        // if (src.streets?.length > 0) {
        //     for (const street of Object.values(src.streets)) {
        //         if (street.id < 0) {
        //             actions.push({operation: 'create', type: 'street', value: {...street, id: idCounter}})
        //             idCounter--
        //         } else if (street.id > 0 && street.changed === true && street.changedFields?.length > 0) {
        //             const payload = {}
        //             for (const key of Object.values(street.changedFields)) {
        //                 payload[key] = street[key]
        //             }
        //             actions.push({operation: 'update', type: 'street', value: {id: street.id, ...payload}})
        //         }
        //     }
        // }

        // if (src.deletedStreets?.length > 0) {
        //     for (const id of src.deletedStreets) {
        //         actions.push({operation: 'delete', type: 'street', value: id})
        //     }
        // }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteDistrictEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'town_distr', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

// sample({
//     source: $selectedDistStreets,
//     clock: createDistStreetEv,
//     fn: (src, newStreet) => {
//         if (src.some(s => s.id < 0)) {
//             const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
//             return [...src, {...newStreet, id: newId}]
//         } else {
//             return [...src, {...newStreet, id: -1}]
//         }
//     },
//     target: $selectedDistStreets
// })

// sample({
//     source: {mutable: $selectedDistStreets, original: $selectedDistStreetsImmutable},
//     clock: editDistStreetEv,
//     fn: (src, {payload, idx}) => {
//         if (idx !== -1) {
//             const result = [...src.mutable]
//             const existingImmutable = src.original[idx]
//             const existing = result[idx]
//             const changedFields = existing.changedFields ?? []
//             /*
//             Проход по уже отредактированным улицам и по изначально существующим для этого района.
//             Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
//             Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
//             */
//             for (const key in payload) {
//                 if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
//                     changedFields.push(key)
//                 } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
//                     changedFields.splice(changedFields.indexOf(key), 1)
//                 }
//             }
//             // Если редактируемая̆ улица является новой, то не отражаем в ней присутствие измененных полей
//             if (existing.id < 0) {
//                 result.splice(idx, 1, {...payload, id: existing.id})
//             } else {
//                 result.splice(idx, 1, {...payload, id: existing.id, edited: true, changedFields})
//             }
//             return result
//         } else return src
//     },
//     target: $selectedDistStreets
// })

// sample({
//     source: {existing: $selectedDistStreets, deleted: $deletedStreets},
//     clock: deleteDistStreetEv,
//     fn: (src, idx) => {
//         if (idx !== -1) {
//             const srcCopy = [...src.existing]
//             const item = srcCopy[idx]
//             if (item.id > 0) {
//                 return [...src.deleted, item.id]
//             } else {
//                 return src.deleted
//             }
//         } else {
//             return src.deleted
//         }
//     },
//     target: $deletedStreets
// })

// sample({
//     source: $selectedDistStreets,
//     clock: deleteDistStreetEv,
//     fn: (src, idx) => {
//         if (idx !== -1) {
//             const result = [...src]
//             result.splice(idx, 1)
//             return result
//         } else {
//             return src
//         }
//     },
//     target: $selectedDistStreets
// })

// sample({
//     source: $selectedDistStreets,
//     clock: setCreateEditStreetEmbeddedEv,
//     fn: (src, clock) => {
//         if (clock.type === 'edit') {
//             return {open: true, type: 'edit', idx: clock.idx, item: src[clock.idx]}
//         } else if (clock.type === 'create') {
//             return {open: true, type: 'create'}
//         }
//     },
//     target: $createEditStreetEmbedded
// })
