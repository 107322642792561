import {createStore} from 'effector';


export const $epdTemplateFieldList = createStore({data: [], count: 0})

export const $selectedEPDTemplateField = createStore(null)

export const $editEPDTemplateFieldStatus = createStore(false)

export const $createdEPDTemplateFieldId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $fieldName = createStore([
    {
        label: 'Наименование услуг',
        value: 'service_name',
    },
    {
        label: 'Сальдо на (начало месяца)',
        value: 'sum_saldo_begin',
    },
    {
        label: 'Оплата',
        value: 'sum_pay',
    },
    {
        label: 'Наименование провайдера',
        value: 'provider_name',
    },
    {
        label: 'Пред. показание',
        value: 'pay_count',
    },
    {
        label: 'Текущее показание',
        value: 'check_count',
    },
    {
        label: 'Количество людей',
        value: 'people_quantity',
    },
    {
        label: 'Количество',
        value: 'consumption',
    },
    {
        label: 'Стоимость',
        value: 'tarif_value',
    },
    {
        label: 'Начислено за (месяц)',
        value: 'sum_calc',
    },
    {
        label: 'Пеня',
        value: 'sum_fine',
    },
    {
        label: 'Перерасчет',
        value: 'sum_recalc',
    },
    {
        label: 'К оплате',
        value: 'sum_saldo_end',
    }
])

export const $cellType = createStore([
    {
        label: 'Обычный',
        value: 'normal',
    },
    {
        label: 'Соединенный',
        value: 'merge',
    },
    {
        label: '2+3 ячейка',
        value: 'sum_column',
    },
    {
        label: 'Жирный',
        value: 'bold',
    },
    {
        label: 'Двойной',
        value: 'double',
    },
    {
        label: 'Мин Макс',
        value: 'column',
    }
])

export const $columnNumber = createStore([
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    }

])
