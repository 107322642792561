import {useTranslation} from 'react-i18next';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import UploadComponent from '../../../components/UploadComponent/UploadComponent.jsx';
import {useUnit} from 'effector-react/effector-react.umd';
import {
    $uploadModal, $uploadStatus,
    resetUploadModalEv,
    uploadFileEv, uploadFileFx
} from '../../../models/uploadREOModel/index.js';
import {Row, Select} from 'antd';
import {useEffect, useMemo} from 'react';
import {useStoreMap} from "effector-react";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import Col from "antd/es/col";
import dayjs from "dayjs";

const monthDict = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
}

export default function UploadREOModal() {
    const {t} = useTranslation()
    const open = useUnit($uploadModal)
    const onClose = useUnit(resetUploadModalEv)
    const onUpload = useUnit(uploadFileEv)
    const loading = useUnit(uploadFileFx.pending)
    const status = useUnit($uploadStatus)
    const [fileForm] = Form.useForm()

    const providerOpts = useStoreMap($dictsSelects, sel => sel.providers)
    const monthOptions = useMemo(() => {
        const current = (new Date).getMonth()
        const prev = current - 1
        return [
            {
                label: t(monthDict[prev]),
                value: prev
            },
            {
                label: t(monthDict[current]),
                value: current
            }
        ]
    }, [])



    const onReset = () => {
        fileForm.resetFields()
        onClose()
    }

    const onSubmit = (values) => {
        // debugger
        // const payload = {
        //     ...values,
        //     report_date: dayjs().set('date', 1).set('month', values.month).toISOString()
        // }
        // delete payload.month
        // debugger
        onUpload(values)
    }

    useEffect(() => {
        if (status) {
            onReset()
        }
    }, [status]);

    return <Modal open={open}
                  width={'80vw'}
                  title={t('Загрузка файла')}
                  onCancel={onReset}
                  okText={t('Загрузить')}
                  cancelText={t('Отмена')}
                  onOk={() => fileForm.submit()}
                  okButtonProps={{loading: loading}}
                  destroyOnClose
    >
        <Form form={fileForm} onFinish={onSubmit} layout={'vertical'} size={'small'}>
            {/*<Form.Item name={'send_each_row'}*/}
            {/*           label={t('Исключить ошибочный файл целиком')}*/}
            {/*           valuePropName={'checked'}*/}
            {/*>*/}
            {/*    <Switch />*/}
            {/*</Form.Item>*/}
            <UploadComponent title={t('Файлы')} isForm formName={'files'} dragger multiple />
        </Form>
    </Modal>
}
