import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const BanksListGate = createGate()
export const BanksViewGate = createGate()
export const BanksEditGate = createGate()
export const BanksCreateGate = createGate()

export const submitCreateBanksEv = createEvent()
export const submitEditBanksEv = createEvent()
export const deleteBanksEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
