import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {
	$paymentAppliesMoreDataSt,
	$paymentElementsSt,
	exportAppliesTableEv,
} from "../../../models/paymentModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import Button from "antd/es/button/index.js";
import ExcelOutlined from "../../../components/ExcelIcon/EcxelIcon.jsx";
import Col from "antd/es/col/index.js";
import Table from "antd/es/table";
import Card from "antd/es/card";

export default function PaymentTableApplied({applyId}) {
	const {t} = useTranslation()
	const {data, count} = useUnit($paymentElementsSt)
	const tempdata = useUnit($paymentAppliesMoreDataSt)


	const {search} = searchAndActions()
	const exportXls = useUnit(exportAppliesTableEv)

	const filter = {
		// payment_apply_id: applyId,
		payment_apply_id: applyId.length > 0 ? applyId?.map(id => `payment_apply_id=${id}`)?.join('&') : applyId,
	}



	const columns = [
		{
			title: t('Дата системы'),
			dataIndex: 'bank_day',
			key: 'bank_day',
			render: (date) => date ? dayjs(date).format('DD.MM.YYYY') : '-'
		},
		{
			title: t('Дата оплаты'),
			dataIndex: 'system_payment_date',
			key: 'system_payment_date',
			render: (date) => date ? dayjs(date).format('DD.MM.YYYY') : '-'
		},
		{
			title: t('Лицевой счет'),
			dataIndex: 'account_id',
			key: 'account_id',
			render: (account_id) => <MappedDictItem id={account_id} type={'account'}/>
		},

		{
			title: t('Поставщик'),
			dataIndex: 'provider_id',
			key: 'provider_id',
			render: (provider_id) => <MappedDictItem id={provider_id} type={'providers'}/>
		},
		{
			title: t('Присоединено к Услуге'),
			dataIndex: 'service_id',
			key: 'service_id',
			render: (service_id) => <MappedDictItem id={service_id} type={'services'}/>
		},
		{
			title: t('Услуге в платеже'),
			dataIndex: 'old_service_id',
			key: 'old_service_id',
			render: (service_id) => <MappedDictItem id={service_id} type={'services'}/>
		},
		{
			title: t('Сумма'),
			dataIndex: 'pay_sum',
			key: 'pay_sum',
			render: (value) => <FormatNumber value={value} type={2}/>
		},
	]

	return (
		<>
			<Card>

			<Col>
				<Button
					onClick={() => exportXls(filter)}
					disabled={!data.length > 0}
					size={'small'}
				>
					<ExcelOutlined size={18}/>
					Экспорт
				</Button>
			</Col>
			<Table
				dataSource={data}
				columns={columns}
				size={'small'}
				showActions={false}
			/>
			</Card>

		</>

	);
}
