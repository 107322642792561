import {
    $afterCreateAction,
    $createdSectorId,
    $editSectorStatus,
    $selectedSector,
    $sectorList
} from './stores.js';
import {getSectorListFx, getSectorByIdFx} from './effects.js';
import {
    deleteSectorEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    SectorEditGate,
    SectorListGate,
    SectorViewGate,
    submitCreateSectorEv,
    submitEditSectorEv, SectorCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {resetCreateInspectorEmbeddedEv, resetCreateTownEmbeddedEv} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateSectorEv, response: triggerSuccessOpsEv}, reset: SectorCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditSectorEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteSectorEv, response: triggerSuccessOpsEv})

$sectorList.on(getSectorListFx.doneData, (_, data) => data)
    .reset(SectorListGate.close)

$selectedSector.on(getSectorByIdFx.doneData, (_, data) => data)
    .reset([SectorEditGate.close, SectorViewGate.close])
// FIXME
$createdSectorId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "sector")
    const result = data ? data?.value?.id : undefined
    console.log('created sector id', result)
    return result
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editSectorStatus.on(editCompleteEv, () => true)
    .reset(SectorEditGate.close)

sample({
    clock: [SectorEditGate.state, SectorViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getSectorByIdFx
})

sample({
    clock: SectorListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getSectorListFx
})

sample({
    source: SectorListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getSectorListFx
})

sample({
    clock: submitCreateSectorEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        if (payload.inspector_id === '-' || payload.inspector_id === ' ') {
            payload.inspector_id = null
        }

        if (payload.inspector_id < 0) {
            const newInspector = {
                ...payload.inspector,
                town_id: payload.town_id
            }

            actions.push({operation: 'create', type: 'inspectors', value: {...newInspector, id: idCounter}})
            delete payload.inspector
            idCounter--

        }

        actions.push({operation: 'create', type: 'sector', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedSector,
    clock: submitEditSectorEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        if (payload.inspector_id < 0) {
            const newInspector = {
                ...payload.inspector,
                town_id: payload.town_id
            }
            actions.push({operation: 'create', type: 'inspectors', value: {...newInspector, id: idCounter}})
            delete payload.inspector

        }

        if (changedFields.inspector_id === '-' || changedFields.inspector_id === ' ') {
            changedFields.inspector_id = null
        }

        if (changedFields.inspector) {
            changedFields.inspector_id = idCounter
            delete changedFields.inspector
        }


        actions.push({operation: 'update', type: 'sector', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteSectorEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'sector', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [SectorCreateGate.close, SectorEditGate.close],
    target: [resetCreateTownEmbeddedEv, resetCreateInspectorEmbeddedEv]
})
