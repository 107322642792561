import {
    $afterCreateAction,
    $createdServicesId,
    $editServicesStatus,
    $selectedServices,
    $servicesList, $serviceTypeEmbeddedOpen
} from './stores.js';
import {getServicesListFx, getServicesByIdFx} from './effects.js';
import {
    deleteServicesEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ServicesEditGate,
    ServicesListGate,
    ServicesViewGate,
    submitCreateServicesEv,
    submitEditServicesEv, getServiceEv, setServiceTypeEmbedded, ServicesCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateServicesEv, response: triggerSuccessOpsEv}, reset: ServicesCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditServicesEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteServicesEv, response: triggerSuccessOpsEv})

$servicesList.on(getServicesListFx.doneData, (_, data) => data)
    .reset(ServicesListGate.close)

$selectedServices.on(getServicesByIdFx.doneData, (_, data) => data)
    .reset([ServicesEditGate.close, ServicesViewGate.close])

// FIXME
$createdServicesId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "services")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)
    .reset(ServicesEditGate.close)

$editServicesStatus.on(editCompleteEv, () => true)
    .reset(ServicesEditGate.close)

$serviceTypeEmbeddedOpen.on(setServiceTypeEmbedded, (_, open) => open)
    .reset([ServicesCreateGate.close, ServicesEditGate.close])

sample({
    clock: getServiceEv,
    target: getServicesByIdFx
})

sample({
    clock: [ServicesEditGate.state, ServicesViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getServicesByIdFx
})

sample({
    clock: ServicesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServicesListFx
})

sample({
    source: ServicesListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServicesListFx
})

sample({
    clock: submitCreateServicesEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        let penSrvId = payload?.penalty_service_id
        const templateId = payload?.template_id ?? 1

        if (payload?.service_payment_type_id < 0) {
            actions.push({operation: 'create', type: 'service_payment_type', value: {...payload?.new_payment_type, id: idCounter}})
            idCounter--
        }
        if (payload?.penalty_service_id && penSrvId < 0) {
            let penSrvPayTypeId = payload?.new_pen_srv?.service_payment_type_id
            if (penSrvPayTypeId < 0) {
                actions.push({operation: 'create', type: 'service_payment_type', value: {...payload?.new_pen_srv?.payment_type, id: idCounter}})
                penSrvPayTypeId = idCounter
                idCounter--
            }
            delete payload?.new_pen_srv?.new_payment_type

            actions.push({operation: 'create', type: 'services', value: {...payload?.new_pen_srv, is_penalty: true, template_id: templateId, service_payment_type_id: penSrvPayTypeId, id: idCounter}})
            penSrvId = idCounter
            idCounter--
        }
        // if (payload.service_unit_id < 0) {
        //     actions.push({operation: 'create', type: 'service_units', value: {...payload.service_unit, id: idCounter}})
        //     payload.service_unit_id = idCounter
        //     idCounter--
        //     delete payload.service_unit
        // }
        delete payload.new_payment_type
        delete payload.new_pen_srv

        actions.push({operation: 'create', type: 'services', value: {...payload, penalty_service_id: penSrvId ?? null, id: idCounter}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedServices,
    clock: submitEditServicesEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        let payTypeId = payload?.service_payment_type_id
        let penSrvId = payload?.penalty_service_id
        const templateId = payload?.template_id ?? 1

        if (payload?.service_payment_type_id < 0) {
            actions.push({operation: 'create', type: 'service_payment_type', value: {...payload?.new_payment_type, id: idCounter}})
            payTypeId = idCounter
            idCounter--
        }
        if (payload?.penalty_service_id && penSrvId < 0) {
            let penSrvPayTypeId = payload?.new_pen_srv?.service_payment_type_id
            if (penSrvPayTypeId < 0) {
                actions.push({operation: 'create', type: 'service_payment_type', value: {...payload?.new_pen_srv?.new_payment_type, id: idCounter}})
                penSrvPayTypeId = idCounter
                idCounter--
            }
            delete payload?.new_pen_srv?.new_payment_type

            actions.push({operation: 'create', type: 'services', value: {...payload?.new_pen_srv, is_penalty: true, template_id: templateId, service_payment_type_id: penSrvPayTypeId, id: idCounter}})
            penSrvId = idCounter
            idCounter--
        }

        // if (payload.service_unit_id < 0) {
        //     actions.push({operation: 'create', type: 'service_units', value: {...payload.service_unit, id: idCounter}})
        //     payload.service_unit_id = idCounter
        //     idCounter--
        //     delete payload.service_unit
        // }


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        if (Object.hasOwn(changedFields, 'service_payment_type_id') && changedFields.service_payment_type_id < 0) {
            changedFields['service_payment_type_id'] = payTypeId
        }

        if (Object.hasOwn(changedFields, 'penalty_service_id') && changedFields.penalty_service_id < 0) {
            changedFields['penalty_service_id'] = penSrvId
        }
        delete payload.new_payment_type
        delete payload.new_pen_srv

        actions.push({operation: 'update', type: 'services', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteServicesEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'services', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
