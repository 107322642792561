import React from 'react';
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
	$paymentBankApiAuditModal,
	$paymentBankApiAuditModalData,
	 closePaymentBankApiAuditModalEv,
} from "../../../models/paymentModel/index.js";
import Modal from "antd/es/modal";
import TextArea from "antd/es/input/TextArea";
import Card from "antd/es/card";
import Button from "antd/es/button";

export default function PaymentBankApiAuditModal() {
	const {t} = useTranslation()

	const open = useUnit($paymentBankApiAuditModal)
	const onClose = useUnit(closePaymentBankApiAuditModalEv)
	const {type, data} = useUnit($paymentBankApiAuditModalData)




	return (
		<Modal
			open={open}
			onCancel={onClose}
			title={'Просмотр запроса и ответа'}
			width={1000}
			footer={
				<>
					<Button type={'primary'} onClick={onClose}>{t('Закрыть')}</Button>
				</>
			}

		>
			<Card title={'Запрос'}>
				<TextArea value={data?.request} style={{height: '600px'}}/>
			</Card>
			<Card title={'Ответ'}>
				<TextArea value={data?.responce} style={{height: '600px'}}/>
			</Card>




		</Modal>

	);
}

