import Table from "antd/es/table";
import {useTranslation} from "react-i18next";
import {useUnit} from "effector-react";
import {
    $accountsProviderServiceList, AccrualsGate,
} from "../../../models/dictionaryAccountsModel/index.js";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import FormatedNumber from "../../../components/FormatedNumber.jsx";
import {CloseCircleOutlined} from "@ant-design/icons";
import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import Space from "antd/es/space";
import {searchAndActions} from "../../../utils/searchAndActions.js";


export default function ProviderServices({isView, id}) {

    const {t} = useTranslation()
    const {search} = searchAndActions()

    const {data} = useUnit($accountsProviderServiceList)

    console.log('provider service data', data)

    const columns = [
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'providers'}/>,
        },
        {
            title: t('Услуга'),
            dataIndex: 'service_id',
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'services'}/>,
        },
        {
            title: t('Площадь'),
            dataIndex: 'full_square',
            render: (v) => <FormatedNumber value={v}/>,
        },
        {
            title: t('Кол-во проживающих'),
            dataIndex: 'people_quantity',
            render: (id) => id,
        },
        {
            title: t('№ договора поставщика с потребителем'),
            dataIndex: 'external_number'
        },
        {
            title: t('Тариф'),
            dataIndex: 'tarifs',
            render: (v, row) => <Space>
                <FormatedNumber value={row.tarif_value}/>
                <span>
                    {row.measure_name}
                </span>
                {row.date_from ? <span>
                    {t('с')} <CustomDates data={row.date_from} type={'day'}/>
                </span> : ''}
                {row.date_to ? <span>
                    {t('по')} <CustomDates data={row.date_to} type={'day'}/>
                </span> : ''}
            </Space>,
        },
        // date_from
        // date_to
        // is_not_accrued
        // not_accrued_from
        // not_accrued_to
        // is_not_balance_transfer
        // not_balance_transfer_from
        // not_balance_transfer_to
        // is_not_printed
        // not_printed_from
        // not_printed_to
    ]


    return <>
        <AccrualsGate id={id} search={search.toString()} />

        <Table
            dataSource={data}
            columns={columns}
            bordered
            rowKey={'service_id'}
            className={'table-container'}
            pagination={false}
            size={'small'}
            // rowClassName={(record) => record.id === selectedService?.id ? 'selectedRowObject' : null}
        />
    </>
}
