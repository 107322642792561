import dayjs from 'dayjs';

const format = {
    min: 'DD.MM.YY HH:mm',
    day: 'DD.MM.YY',
    month: 'MM.YY',
    year: 'YY',
}
export default function CustomDates({data, type = 'min'}) {
    return data && data !== '-' ? dayjs(data).format(format[type]) : '-'
}
