import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getRoleListFx} from "./effects.js";

export const RoleListGate = createGate()
export const RoleViewGate = createGate()
export const RoleEditGate = createGate()
export const RoleCreateGate = createGate()

export const submitCreateRoleEv = createEvent()
export const submitEditRoleEv = createEvent()
export const deleteRoleEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
