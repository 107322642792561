import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {useUnit} from 'effector-react';
import {
    $providerSrvMap,
    $provSrvsSelOpts, $viewAPS, getViewAPSEv,
} from '../../../../models/dictionaryProvidersModel';
import Button from 'antd/es/button';
import {CloseCircleOutlined} from '@ant-design/icons';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Table from 'antd/es/table';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {filterOption} from '../../../../utils/selectUtils.jsx';
import {paginationConfig} from '../../../../utils/paginationConfig.js';


export default function ProvServAccForm({isView, provider_id}) {
    const {t} = useTranslation()
    const serviceOpts = useUnit($provSrvsSelOpts)
    const srvMap = useUnit($providerSrvMap)
    const data = useUnit($viewAPS)
    const getData = useUnit(getViewAPSEv)

    const [filterBySrv, setFilterBySrv] = useState(null)
    const columns = [
        {
            title: t('Услуга'),
            dataIndex: 'provider_service_id',
            width: '20%',
            sorter: (a, b) => a.provider_service_id - b.provider_service_id,
            render: (id) => <MappedDictItem id={srvMap[id]} type={'services'}/>
        },
        {
            title: t('Номер ЛС'),
            // width: '10%',
            dataIndex: 'account_id',
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        // { // FIXME temp disable
        //     title: t('Номер Абонента'),
        //     dataIndex: 'external_number',
        // },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            width: '10%',
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        // {
        //     title: t('Район'),
        //     dataIndex: 'town_distr_id',
        //     render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        // },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
        },
        {
            title: t('Площадь'),
            dataIndex: 'full_square'
        },
        {
            title: t('Кол-во проживающих'),
            dataIndex: 'people_quantity'
        },
        {
            title: t('№ договора'),
            dataIndex: 'external_number'
        },
        {
            title: t('Оказание услуги'),
            dataIndex: 'date_from',
            render: (date, r) => {
                const data = []
                if (r.date_from) data.push(<Col span={24}>
                    с {dayjs(r.date_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.date_to) data.push(<Col span={24}>
                    по {dayjs(r.date_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}>-</div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: t('Не начислять'),
            dataIndex: 'is_not_accrued',
            render: (v, r) => {
                const data = []
                if (r.not_accrued_from) data.push(<Col span={24}>
                    с {dayjs(r.not_accrued_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.not_accrued_to) data.push(<Col span={24}>
                    по {dayjs(r.not_accrued_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><CloseCircleOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: t('Не переводить сальдо'),
            dataIndex: 'is_not_balance_transfer',
            render: (v, r) => {
                const data = []
                if (r.not_balance_transfer_from) data.push(<Col span={24}>
                    с {dayjs(r.not_balance_transfer_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.not_balance_transfer_to) data.push(<Col span={24}>
                    по {dayjs(r.not_balance_transfer_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><CloseCircleOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
        {
            title: t('Не печатать'),
            dataIndex: 'is_not_printed',
            render: (v, r) => {
                const data = []
                if (r.not_printed_from) data.push(<Col span={24}>
                    с {dayjs(r.not_printed_from).format('DD.MM.YYYY')}
                </Col>)
                if (r.not_printed_to) data.push(<Col span={24}>
                    по {dayjs(r.not_printed_to).format('DD.MM.YYYY')}
                </Col>)
                if (data.length === 0)
                    return <div style={{textAlign: 'center'}}><CloseCircleOutlined/></div>
                else
                    return <Row>{data}</Row>
            }
        },
    ]

    return <>
        <Card title={'Фильтры'} size={'small'}>
            <Row gutter={[16, 0]} style={{alignItems: 'flex-end'}}>
                <Col span={4}>
                    <p style={{marginTop: 0, marginBottom: 4}}>{t('Фильтр по Услуге')}</p>
                    <Select
                        options={serviceOpts}
                        size={'small'}
                        filterOption={filterOption}
                        style={{width: '100%'}}
                        showSearch
                        allowClear
                        value={filterBySrv}
                        onSelect={(id) => setFilterBySrv(id)}
                        onClear={() => setFilterBySrv(null)}
                    />
                </Col>
                <Col span={4}>
                    <Button onClick={() => getData({provider_id, provider_service_id: filterBySrv})} size={'small'} >
                        {t('Применить фильтр')}
                    </Button>
                </Col>
                <Col span={4}>
                    <Button
                        onClick={() => setFilterBySrv(null)}
                        disabled={!filterBySrv}
                        size={'small'}
                    >
                        {t('Очистить фильтр')}
                    </Button>
                </Col>
            </Row>
        </Card>
        <Row gutter={[16, 16]} style={{marginTop: 8}}>
            <Col span={24}>
                <Card title={'Услуги с ЛС'} size={'small'}>
                    <Table dataSource={data}
                           columns={columns}
                           bordered
                           className={'table-container'}
                           pagination={paginationConfig}
                           size={'small'}
                           rowKey={'id'}
                    />
                </Card>
            </Col>
        </Row>
    </>
}
