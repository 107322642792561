import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';

import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';

import {
    $servicesList, editServicesOptionEv,
    getServicesOptionsListFx

} from "../../models/adminServicesModel/index.js";
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {AdminServicesListGate} from "../../models/adminServicesModel/index.js";
import {
    CheckOutlined,
    CloseOutlined,
    EyeOutlined,
    FileProtectOutlined, HolderOutlined
} from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";
import Tooltip from "antd/es/tooltip";
import Button from "antd/es/button";
import {Popconfirm} from "antd";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import React, {useContext, useMemo, useState} from "react";
import Table from "antd/es/table";
import Select from "antd/es/select";
import Switch from "antd/es/switch";

import {DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import Form from "antd/es/form";
import Input from "antd/es/input";


const opt = [
    {
        label: 'Все',
        value: 'all',
    },
    {
        label: 'Да',
        value: true,
    },
    {
        label: 'Нет',
        value: false,
    }
]

export default function AdminServices() {
    const {t} = useTranslation()
    const {data, count} = useUnit($servicesList)
    const loading = useUnit(getServicesOptionsListFx.pending)
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.admin-services`)
    const editServicesOption = useUnit(editServicesOptionEv)

    const updateServiceOption = (row, type) => {
        const payload = {
            id: row.id,
            type: type,
            value: !row[type]
        }
        editServicesOption(payload)
    }

    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t(`Название ${genitive_form}`),
            dataIndex: 'name',
            sorter: true,
            key: 'name',
            render: (name, record) => <MappedDictItem id={record.id} type={'services'}/>
        },

        {
            title: t(`Содержит перс. данные`),
            dataIndex: 'is_ext_number_is_personal',
            sorter: true,
            key: 'is_ext_number_is_personal',
            render: (is_ext_number_is_personal) => is_ext_number_is_personal === true ?
                <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
        },
        {
            title: t(`Редактирование запрещено`),
            dataIndex: 'is_ext_number_edit_prohibited',
            sorter: true,
            key: 'is_ext_number_edit_prohibited',
            render: (is_ext_number_edit_prohibited) => is_ext_number_edit_prohibited === true ?
                <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (_, row) => <ButtonGroup>
                <Popconfirm
                    title={t('Вы уверены, что хотите разрешить показывать ИИН в этой записи?')}
                    okText={t('Да')}
                    onConfirm={() => updateServiceOption(row, 'is_ext_number_is_personal')}
                    placement={'left'}
                >
                    <Tooltip title={
                        row.is_ext_number_is_personal ?
                            t('Скрыть ИИН') :
                            t('Показывать ИИН')
                    } placement={'top'}>
                        {row.is_ext_number_is_personal ?
                            <Button icon={<EyeOutlined/>} danger/> :
                            <Button icon={<EyeOutlined/>} type='primary'/>}
                    </Tooltip>
                </Popconfirm>
                <Popconfirm
                    title={t('Вы уверены, что хотите запретить редактирование этой записи?')}
                    okText={t('Да')}
                    onConfirm={() => updateServiceOption(row, 'is_ext_number_edit_prohibited')}
                    placement={'left'}
                >
                    <Tooltip title={
                        row.is_ext_number_edit_prohibited ?
                            t('Запретить редактирование') :
                            t('Разрешить редактирование')
                    } placement={'top'}>
                        {
                            row.is_ext_number_edit_prohibited ?
                                <Button icon={<FileProtectOutlined/>} danger/> :
                                <Button icon={<FileProtectOutlined/>} type={'primary'}/>
                        }
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]

    const filters = [
        {
            name: 'code_name',
            label: t('Код/Название'),
            type: 'input'
        },
        {
            name: 'is_ext_number_edit_prohibited',
            label: t('Редактирование запрещено'),
            type: 'select',
            options: opt
        },
        {
            name: 'is_ext_number_is_personal',
            label: t('Содержит перс. данные'),
            type: 'select',
            options: opt
        },

    ]

    console.log('data', data)

    return <>
        <AdminServicesListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
            showActions={false}
        />

        {/*<TestService />*/}

    </>
}

const RowContext = React.createContext({});

const DragHandle = () => {
    const {setActivatorNodeRef, listeners} = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined/>}
            style={{
                cursor: 'move',
            }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};


const Row = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };
    const contextValue = useMemo(
        () => ({
            setActivatorNodeRef,
            listeners,
        }),
        [setActivatorNodeRef, listeners],
    );
    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

function TestService() {
    const possibleFields = {
        account_id: "Лицевой счет",
        service_id: "Код услуги",
        service_name: "Наименование услуги",
        people_quantity: "Количество проживающих",
        square: "Площадь",
        tarif: "Значение тарифа",
        service_unit_name: "Единица измерения",
        with_saldo: "Признак ведения сальдо",
        beg: "Долг/переплата без учета начислений и перерасчетов текущего месяца",
        nach: "Сумма начисления в тенге",
        corr: "Сумма перерасчета в тенге",
        endd: "Долг/переплата с учетом начислений и перерасчетов текущего месяца – сумма к оплате",
        cons: "потребление в натуральных показателях",
        pay_count: "Оплаченные показания",
        last_count: "Последние проверенные показания",
        check_date: "Дата последней проверки",
        is_main: "признак основной услуги",
        pay: "оплата",
        bill_id: "№ счетфактуры",
        ext_num: "№ договора/абонент ГЦТ/ИИН",
    }
    const possibleFieldsOpts = Object.entries(possibleFields).map(
        ([k, v]) => ({value: k, label: v})
    )
    const types = {
        int: 'Целое число',
        float: 'Дробное число',
        date: 'Дата',
        string: 'Строка',
        bool: 'Логический тип',
        iin: 'ИИН',
    }
    const typesOpts = Object.entries(types).map(([k, v]) => ({value: k, label: v}))
    const cells = [
        '',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ]
    const [data, setData] = useState([
        {idx: 1, cell: 1, field: 'account_id', type: 'int', required: true},
        {idx: 2, cell: 2, field: 'service_id', type: 'int', required: true},
        {idx: 3, cell: 3, field: 'service_name', type: 'string', required: false},
    ])

    const columns = [
        {
            key: 'sort',
            align: 'center',
            width: 80,
            render: () => <DragHandle/>,
        },
        {
            title: 'Столбец',
            dataIndex: 'cell',
            key: 'cell',
            render: (v) => cells[v]
        },
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            render: (v, record) => <Select
                defaultValue={v}
                options={possibleFieldsOpts}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, field: value} : d)
                )}
            />
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            render: (v, record) => <Select
                defaultValue={v}
                options={typesOpts}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, field: value} : d)
                )}
            />
        },
        {
            title: 'Обязательно',
            dataIndex: 'required',
            key: 'required',
            render: (v, record) => <Switch
                checkedChildren="Обязательное"
                unCheckedChildren="Необязательное"
                defaultValue={v}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, required: value} : d)
                )}
            />
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            render: (v, record) => <Button onClick={() => {
                setData(data.filter(d => d.cell !== record.cell))
            }}>Удалить</Button>
        },
    ]

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );
    const onDragEnd = ({active, over}) => {
        console.log('active', active, 'over', over)
        if (active.id !== over?.id) {
            console.log('setDat')
            setData((prev) => {
                const activeIndex = prev.findIndex((i) => i.idx === active.id);
                const overIndex = prev.findIndex((i) => i.idx === over?.id);
                const newData = arrayMove(prev, activeIndex, overIndex).map(
                    (item, index) => ({...item, cell: index + 1})
                )
                console.log('activeIndex', activeIndex, 'overIndex', overIndex, 'newData', newData)

                return newData
            });
        }
    };
    const [form] = Form.useForm();

    console.log('DDDDD', data)
    return <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
    >
        <SortableContext
            // rowKey array
            items={data.map((i) => i.idx)}
            strategy={verticalListSortingStrategy}
        >
            <Form.Item label={'Название шаблона ф/н'}>
                <Input/>
            </Form.Item>
            <Table
                components={{
                    body: {
                        row: Row,
                    },
                }}
                dataSource={data}
                columns={columns}
                rowKey={'idx'}
                pagination={false}
                footer={() => {
                    return <Form
                        layout="inline"
                        form={form}
                        onFinish={(formData) => {
                            console.log(formData)
                            setData([...data, {
                                ...formData, idx: data.length + 1,
                            }].map(
                                (item, index) => ({...item, cell: index + 1})
                            ))
                        }}
                    >
                        <Form.Item label="Поле" name={'field'} style={{width: '300px'}}>
                            <Select
                                options={possibleFieldsOpts}
                            />
                        </Form.Item>
                        <Form.Item label="Тип" name={'type'} style={{width: '300px'}}>
                            <Select
                                options={typesOpts}
                            />
                        </Form.Item>
                        <Form.Item label="Обязательно" name={'required'}>
                            <Switch/>
                        </Form.Item>
                        <Button onClick={form.submit}>Добавить</Button>
                    </Form>
                }}
            />
            <Button >Сохранить</Button>
        </SortableContext>
    </DndContext>
}
