import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useUnit} from "effector-react";
import {
	$selectedAlgorithm,
	CalcTypeAlgorithmViewGate,
	getAlgorithmByIdFx
} from "../../../models/calcTypeAlgorithmsModel/index.js";
import CreatedBy from "../../../components/CreatedBy/CreatedBy.jsx";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {Card} from "antd";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import ViewDictionaryItemCommon from "../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx";

export default function ViewCalcTypeAlgorithms() {
	const {t} = useTranslation()
	const {id} = useParams()
	const loading = useUnit(getAlgorithmByIdFx.pending)
	const data = useUnit($selectedAlgorithm)
	const genitive_form = t(`breadcrumbs.genitive.algorithm`)

	const items = [
		{
			key: 'name_kz',
			label: t(`Название ${genitive_form} (каз)`),
			children: data?.name_kz
		},
		{
			key: 'name_ru',
			label: t(`Название ${genitive_form} (рус)`),
			children: data?.name_ru
		},
		{
			key: 'formula',
			label: t(`Формула ${genitive_form}`),
			children: data?.formula
		},
	]

	const itemsDates = [
		{
			key: 'created_by',
			label: t('Кем создан'),
			children: <CreatedBy id={data?.created_by}/>
		},
		{
			key: 'created_at',
			label: t('Дата создания'),
			children: <CustomDates data={data?.created_at}/>
		},
		{
			key: 'updated_by',
			label: t('Кем изменен'),
			children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
		},
		{
			key: 'updated_at',
			label: t('Дата изменения'),
			children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
		}
	]


	return <>
		<CalcTypeAlgorithmViewGate id={id}/>
		<Card
			title={<div><span>{t('Просмотр алгоритма типа расчетов')}</span> <MappedDictItem id={id} type={'calc-type-algorithms'} link={false}/></div>}
			loading={loading}
		>
			<ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'algorithm'}/>
		</Card>
	</>
}
