import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Col from 'antd/es/col'
import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {useStoreMap} from "effector-react";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import DatePicker from "antd/es/date-picker";

export default function ReportComponentDate() {
    const {t} = useTranslation()
    const banksOpt = useStoreMap($dictsSelects, sel => sel.bank)
    return <>
        <Col span={4}>
            <Form.Item name={'report_date'}
                       label={t('Дата')}
                       rules={[{required: false, message: t('Обязательное поле')}]}
            >
                <DatePicker
                    style={{width: '100%'}}
                    defaultValue={dayjs().subtract(1, 'day')}
                />
            </Form.Item>
        </Col>
    </>
}
