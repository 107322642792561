import dayjs from "dayjs";
import {createEffect} from "effector";
import api from "../../api/api.js";
import queryString from "query-string";
// import {changeServiceInErrorEv} from "./events.js";

export const getPaymentDataFx = createEffect()
	.use(async (filters) => (await api().get(`/payment_confirms/?ignore_limit=true${filters.length > 0 ? `&${filters}` : ''}`)).data)

// export const getPaymentElementsFx = createEffect()
// 	.use(async () => {
// 		return (await api().get(`/payment_elements/`)).data
// 	})

export const confirmByIdFx = createEffect()
	.use(async (data) => (await api().post(`/payment_confirms/manual_confirm`, data)))


export const confirmByAllFx = createEffect()
	.use(async (data) => (await api().post(`/payment_confirms/mass_manual_confirm`, data)))

export const setDeductionFx = createEffect()
	.use(async (data) => (await api().post(`/payment_exports/set_deduction`, data)))






export const getPaymentByIdFx = createEffect()
	.use(async (id) => {
		// const date = dayjs(row.payment_date).format('YYYY-MM-DD')
		const result = await api().get(`/payments/?payment_confirm_id=${id}&groupped=true`)
		return result.data
	})

export const getPaymentErrorByIdFx = createEffect()
	.use(async (id) => (await api().get(`/applied_payments_errors/?payment_apply_id=${id}`)).data)
	// .use(async (id) => (await api().get(`/payment_applies/applied_payments_errors/?id=${id}`)).data)

export const confirmAppliesByAllFx = createEffect()
	.use(async (data) => (await api().post(`/payment_applies/apply`, data)))

export const cancelAppliesByAllFx = createEffect()
	.use(async (data) => (await api().post(`/payment_applies/cancel`, data)))

export const prepareExportByAllFx = createEffect()
	.use(async (data) => (await api().post(`/payment_exports/prepare_export`, data)))

// FIXME NEED ENDPOINT
export const exportToBankFx = createEffect()
	.use(async (data) => (await api().post(`/payment_exports/bank_ready`, data)))


export const getPaymentAppliesDataFx = createEffect()
.use(async (filters) => (await api().get(`/payment_applies/?ignore_limit=true${filters.length > 0 ? `&${filters}` : ''}`)).data)

export const getPaymentRetentionMonthDataFx = createEffect()
	.use(async (filters) => (await api().get(`/deduction_provider_amounts/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getPaymentRetentionDayDataFx = createEffect()
	.use(async (filters) => (await api().get(`/payment_exports/?ignore_limit=true${filters.length > 0 ? `&${filters}` : ''}`)).data)


export const getPaymentExportDataFx = createEffect()
.use(async (filters) => (await api().get(`/payment_exports/?ignore_limit=true${filters.length > 0 ? `&${filters}` : ''}`)).data)

export const getPaymentElementsFx = createEffect()
	.use(async (payment_id) => (await api().get(`/applied_payments/?${payment_id}`)).data)


export const getDeductionPaymentsFx = createEffect()
	.use(async (payment_id) => (await api().get(`/deducted_payments/?${payment_id}`)).data)
	// .use(async (payment_id) => (await api().get(`/deducted_payments/`)).data)


export const changeServiceInErrorFx = createEffect()
	.use(async (data) => (await api().post(`/payment_elements/edit_service_id?object_id=${data.object_id}&new_service_id=${data.new_service_id}`)))

export const getExportedPaymentsFx = createEffect()
	.use(async (export_id) => (await api().get(`/exported_payments/?${export_id}`)).data)


export const getBankApiAuditFx = createEffect()
	.use(async (filters) => (await api().get(`/bank_api_audit/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const onUpdateSingleFx = createEffect()
	.use(async (data) => (await api().post(`/deduction_provider_amounts/change_amount`, data)))

export const onCreateSingleFx = createEffect()
	.use(async (data) => (await api().post(`/deduction_provider_amounts/`, data)))


export const bankApiAuditDownloadFullFx = createEffect()
	.use(async (filters) => {
		const params = filters.size > 0 ? `?${filters.toString()}` : ''
		const file = await api().get(`/bank_api_audit/excel_full/${params}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})


export const bankApiAuditDownloadShortFx = createEffect()
	.use(async (filters) => {
		const params = filters.size > 0 ? `?${filters.toString()}` : ''
		const file = await api().get(`/bank_api_audit/excel_short/${params}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})



export const exportPaymentConfirmFx = createEffect()
	.use(async (filters) => {
		const params = filters.size > 0 ? `?${filters.toString()}` : ''
		const file = await api().get(`/payment_confirms/excel/${params}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})


export const exportPaymentAppliesFx = createEffect()
	.use(async (filters) => {
		const params = filters.size > 0 ? `?${filters.toString()}` : ''
		const file = await api().get(`/payment_applies/excel/${params}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})


export const exportPaymentExportFx = createEffect()
	.use(async (filters) => {
		const params = filters.size > 0 ? `?${filters.toString()}` : ''
		const file = await api().get(`/payment_exports/excel/${params}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})



export const exportConfirmTableFx = createEffect()
	.use(async (id) => {
		// const newFilters = queryString.stringify(filters, {skipEmptyString: true, skipNull: true})
		const file = await api().get(`/payment_confirms/confirmed_excel/?payment_confirm_id=${id}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})


export const exportAppliesTableFx = createEffect()
	.use(async (filters) => {
		const params = filters.size > 0 ? `?${filters.toString()}` : ''
		const file = await api().get(`/applied_payments/excel/?${filters?.payment_apply_id}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})


export const exportAppliesErrorFx = createEffect()
	.use(async (filters) => {
		const params = filters.size > 0 ? `?${filters.toString()}` : ''
		// FIXME need endpoint
		const file = await api().get(`/applied_payments_error/excel/${params}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})


export const exportExportTableFx = createEffect()
	.use(async (filter) => {
		// const params = filters.size > 0 ? `?${filters.toString()}` : ''
		// FIXME need endpoint
		const file = await api().get(`/exported_payments/excel/?${filter}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		// link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		link.download = file.headers["content-disposition"].split('filename=').length > 1 ?
			file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '') :
			decodeURIComponent(file.headers["content-disposition"].split("filename*=utf-8''")[1].replace(/"/gm, ''))
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})

export const exportExportDbfFx = createEffect()
	.use(async (filter) => {
		// const params = filters.size > 0 ? `?${filters.toString()}` : ''
		// FIXME need endpoint
		const file = await api().get(`/exported_payments/dbf/?${filter}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=').length > 1 ?
			file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '') :
			decodeURIComponent(file.headers["content-disposition"].split("filename*=utf-8''")[1].replace(/"/gm, ''))
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})

export const exportExportTxtFx = createEffect()
	.use(async (filter) => {
		// const params = filters.size > 0 ? `?${filters.toString()}` : ''
		// FIXME need endpoint
		const file = await api().get(`/exported_payments/txt/?${filter}`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=').length > 1 ?
			file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '') :
			decodeURIComponent(file.headers["content-disposition"].split("filename*=utf-8''")[1].replace(/"/gm, ''))
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})



export const exportDeductionDayFx = createEffect()
	.use(async (id) => {
		// const params = filters.size > 0 ? `?${filters.toString()}` : ''
		// FIXME need endpoint
		const file = await api().get(`/deducted_payments/excel/`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})

export const exportDeductionMonthFx = createEffect()
	.use(async (id) => {
		// const params = filters.size > 0 ? `?${filters.toString()}` : ''
		// FIXME need endpoint
		const file = await api().get(`/deduction_provider_amounts/excel/`, {responseType: 'blob'})
		const link = document.createElement('a')
		const url = URL.createObjectURL(file.data)
		link.href = url
		link.target = '_blank'
		link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
		document.body.appendChild(link)

		link.click()

		document.body.removeChild(link)
		URL.revokeObjectURL(url)
	})
