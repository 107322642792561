import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getSectorListFx = createEffect()
    .use(async (filters) => {
        const response = await api().get(`/sector/${filters.length > 0 ? `?${filters}` : ''}`)
        return response.data
    })

export const getSelectsForAddressFx = createEffect()
    .use(async (filters) => (await api().get(`/address/select_address_list?${filters}`)).data)

export const getDistrictsSelectFx = createEffect()
    .use(async (filters) => (await api().get(`/town_distr/?${filters}`)).data)

export const getAddressFx = createEffect()
    .use(async (filters) => (await api().get(`/address/?${filters}`)).data)
