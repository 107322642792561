import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const BankAccountsListGate = createGate()
export const BankAccountViewGate = createGate()
export const BankAccountEditGate = createGate()
export const BankAccountCreateGate = createGate()

export const submitCreateBankAccountEv = createEvent()
export const submitEditBankAccountEv = createEvent()
export const deleteBankAccountEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()