import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import {
    $selectedBanks,
    BanksViewGate, getBanksByIdFx,

} from "../../../models/dictionaryBanksModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {$infoMaps} from "../../../models/infoModel/index.js";
import Col from "antd/es/col";
import Row from "antd/es/row";
import {CheckOutlined, CloseCircleOutlined} from "@ant-design/icons";

export default function ViewBanks() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getBanksByIdFx.pending)
    const data = useUnit($selectedBanks)
    const genitive_form = t(`breadcrumbs.genitive.banks`);

    const commissionMap = useStoreMap($infoMaps, maps => maps.bankCommissionMap)
    console.log('commissionMap', commissionMap, data)

    const items = [
        // {
        //     key: 'bank_commission_id',
        //     label: t('Комиссия банка'),
        //     // children: data?.bank_commission_id
        //     children:
        // },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <BanksViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр Банка')}</span> <MappedDictItem id={id} type={'banks'}
                                                                           link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon
                items={[]} itemsDates={itemsDates} id={id} href={'banks'}
                customItems={[
                    <Row gutter={[8, 0]}>
                        <Col span={16}>
                            <Card title={t('Свойства банка')} size={'small'} loading={loading}
                                  style={{height: '100%'}}
                            >
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <Card key={'name_ru'}
                                              title={t(`Название ${genitive_form} (рус)`)}
                                              size={'small'}>
                                            {data?.name_ru || '-'}
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card key={'name_kz'}
                                              title={`Название ${genitive_form} (каз)`}
                                              size={'small'}>
                                            {data?.name_kz || '-'}
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card key={'bik'} title={t(`БИК`)} size={'small'}>
                                            {data?.bik || '-'}
                                        </Card>

                                    </Col>
                                    <Col span={12}>
                                        <Card key={'bank_bin_iin'} title={t(`БИН`)} size={'small'}>
                                            {data?.bank_bin_iin || '-'}
                                        </Card>
                                    </Col>
                                    <Col span={4}>
                                        <Card key={'branch'} title={t(`Отделение`)} size={'small'}>
                                            {data?.branch || '-'}
                                        </Card>
                                    </Col>
                                    <Col span={20}>
                                        <Card key={'address'} title={t(`Адрес`)} size={'small'}>
                                            {data?.address || '-'}
                                        </Card>
                                    </Col>

                                    <Col span={12}>
                                        <Card key={'chief_contacts'} title={t(`Контакты руководителя`)} size={'small'}>
                                            {data?.chief_contacts || '-'}
                                        </Card>
                                    </Col>
                                    <Col span={12}>
                                        <Card key={'accountant_contacts'} title={t(`Контакты бухгалтера`)} size={'small'}>
                                            {data?.accountant_contacts || '-'}
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={t('Договор с ЕРЦ')}
                                  size={'small'} loading={loading}
                                  style={{height: '100%'}}
                            >
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Card key={'is_contract_signed'}
                                              title={t(`Наличие договора с ЕРЦ`)} size={'small'}>
                                            {data?.is_contract_signed ? <CheckOutlined/> :
                                                <CloseCircleOutlined/>}
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card key={'agreement'} title={t(`Номер и дата договора с ЕРЦ`)}
                                              size={'small'}>
                                            {data?.agreement || '-'}
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card key={'bank_commission_id'}
                                              title={t(`Комиссия банка`)} size={'small'}>
                                            {
                                                data?.bank_commission_id >= 0 ? <Link to={`/dictionaries/bank-commission/view/${data?.bank_commission_id}`}>{commissionMap[data?.bank_commission_id]}</Link> : '-'
                                            }
                                        </Card>

                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                ]}
            />
        </Card>
    </>
}
