import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import {CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import Table from 'antd/es/table';
import Popconfirm from 'antd/es/popconfirm';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
    $districtEmbeddedFilters,
    $districtsChildren, changeDistrictsChildrenFiltersEv,
    createChildDistrictsEv,
    deleteChildDistrictsEv,
    DistrictsChildrenGate,
    editChildDistrictsEv,
    setCreateEditChildDistrictsEmbeddedEv,
    getDistrictsChildrenFx
} from '../../../models/embeddedFormsModels/embeddedDistrictsModel';
import CreateEditDistrictEmbeddedForm
    from '../CreateEditDistrictEmbedded/CreateEditDistrictEmbedded.jsx';

export default function DistrictsTable({view, parent, parentId}) {
    const {t} = useTranslation()
    const {data, count} = useUnit($districtsChildren)
    const loading = useUnit(getDistrictsChildrenFx.pending)

    const {sortField, sortOrder} = useUnit($districtEmbeddedFilters)
    const changeFilters = useUnit(changeDistrictsChildrenFiltersEv)


    const setForm = useUnit(setCreateEditChildDistrictsEmbeddedEv)

    const onCreate = useUnit(createChildDistrictsEv)
    const onEdit = useUnit(editChildDistrictsEv)
    const deleteDistrict = useUnit(deleteChildDistrictsEv)

    const columns = [
        {
            title: t('Код района'),
            dataIndex: 'id',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'id',
            render: (id) => id < 0 ? t('Новый') : id
        },
        {
            title: t('Название района (рус)'),
            dataIndex: 'name_ru',
            sorter: true,
            onCell: (record) => ({className: record?.changedFields?.includes('name_ru') ? 'table-cell-edited' : ''}),
            render: (name, record) => record.id < 0 ? name : <Link to={`/dictionaries/town_distr/view/${record.id}`}>{name}</Link>
        },
        {
            title: t('Название района (каз)'),
            dataIndex: 'name_kz',
            sorter: true,
            onCell: (record) => ({className: record?.changedFields?.includes('name_kz') ? 'table-cell-edited' : ''}),
            render: (name, record) => record.id < 0 ? name : <Link to={`/dictionaries/town_distr/view/${record.id}`}>{name}</Link>
        },
        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined />} onClick={() => setForm({type: 'create'})} type={'primary'}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            key: 'actions',
            dataIndex: 'id',
            render: (id) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined />} onClick={() => setForm({type: 'edit', id})}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={() => deleteDistrict(id)}
                                placement={'left'}
                    >
                    <Tooltip title={t('Удалить')}>
                        <Button icon={<CloseOutlined />} type={'primary'} danger/>
                    </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]
    if (view) {
        const idx = columns.findIndex(i => i.key === 'actions')
        if (idx !== -1) {
            columns.splice(idx, 1)
        }
    }

    return <>
        <DistrictsChildrenGate parent={parent} parentId={parentId}/>
        <CreateEditDistrictEmbeddedForm onCreate={onCreate} onEdit={onEdit} />
        <Table dataSource={data}
               columns={columns}
               loading={loading}
               bordered
               size={'small'}
               pagination={false}
               onChange={(_, filters, {field, order}) => {
                   const payload = []

                   if (field !== sortField || order !== sortOrder) {
                       if (!order) {
                           payload.push({key: 'sort_field', value: null})
                           payload.push({key: 'sort_order', value: null})
                       } else {
                           payload.push({key: 'sort_field', value: field})
                           payload.push({
                               key: 'sort_order',
                               value: order === 'ascend' ? 'asc' : 'desc'
                           })
                       }
                   }
                   changeFilters(payload)
               }}
               style={{width: '100%'}}
        />
    </>
}
