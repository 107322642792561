import {createStore} from 'effector';


export const $realEstateObjectTypeList = createStore({data: [], count: 0})

export const $selectedRealEstateObjectType = createStore(null)

export const $editRealEstateObjectTypeStatus = createStore(false)

export const $createdRealEstateObjectTypeId = createStore(null)
export const $afterCreateAction = createStore(null)
