import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import Modal from "antd/es/modal";
import Button from "antd/es/button";
import {
    $passwordSettingsModal,
    $passwordSettingsModalData, $selectedPasswordSettings,
    closeModalEv, getPasswordSettingsByIdFx, PasswordSettingsEditGate, submitEditPasswordSettingsEv,
} from "../../../models/dictionaryPasswordSettingsModel/index.js";
import Row from "antd/es/row/index.js";
import Col from "antd/es/col/index.js";
import Card from "antd/es/card/index.js";
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import Form from "antd/es/form/index.js";
import Input from "antd/es/input/index.js";
import CreateTownEmbeddedForm from "../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx";
import CreateInspectorEmbeddedForm
    from "../../../components/Embedded/CreateInspectorEmbeddedForm/CreateInspectorEmbeddedForm.jsx";
import Switch from "antd/es/switch/index.js";
import {
    $userProviderList,
    $usrPrvModal,
    $usrPrvModalData, addUsrPrvEv,
    closeUsrPrvModalEv, deleteUsrPrvEv, getUserProviderFx,
    UsersProviderGate
} from "../../../models/dictionaryUsersModel/index.js";
import Table from "antd/es/table";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {Select} from "antd";
import {$controllersList} from "../../../models/taskAllPdfModel/index.js";
import {DeleteOutlined} from "@ant-design/icons";
import Tooltip from "antd/es/tooltip/index.js";


export default function UserProviderModal() {
    const {t} = useTranslation()
    const [pwdStgForm] = Form.useForm()
    const open = useUnit($usrPrvModal)
    // const open = true
    const onClose = useUnit(closeUsrPrvModalEv)
    const payload = useUnit($usrPrvModalData)

    const {data, count} = useUnit($userProviderList)

    const selected = useUnit($selectedPasswordSettings)
    const submit = useUnit(submitEditPasswordSettingsEv)
    const loading = useUnit(getPasswordSettingsByIdFx.pending)

    const addUsrPrv = useUnit(addUsrPrvEv)
    const deleteUsrPrv = useUnit(deleteUsrPrvEv)

    const onAddUsrPrv = (value) => {
        const data = {
            update_existed: true,
            provider_id: value.provider_id,
            user_id: payload.id
        }
        addUsrPrv(data)

        pwdStgForm.resetFields()

    }

    const providerOpt = useStoreMap($dictsSelects, sel => sel.providers)

    console.log('payload', payload)


    const columns = [
        {
            title: t('Удалить'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',

            render: (id, row) => <>
                <Tooltip title={t('Удалить')} placement={'top'}>
                    <Button icon={<DeleteOutlined/>} danger onClick={() => deleteUsrPrv(id)}/>
                </Tooltip>
            </>
        },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            sorter: (a, b) => a.provider_id - b.provider_id,
            key: 'provider_id',
            defaultSortOrder: 'ascend',
            render: (provider_id) => <MappedDictItem id={provider_id} type={'providers'} />
        },

    ]

    console.log('data', data)



    return <>
        <UsersProviderGate id={payload?.id}/>
        <Modal
            open={open}
            onCancel={onClose}
            width={700}
            footer={
                <>

                    <Button onClick={onClose} type={'primary'}>
                        Закрыть
                    </Button>
                </>
            }
            title={`Редактирование параметра пароля: ${payload?.name_ru}`}
        >
            <Form form={pwdStgForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => onAddUsrPrv(v)}
                  validateTrigger={'onBlur'}
                  // onValuesChange={(_, all) => check(all)}
            >
                <Card size={'small'} loading={loading}>

                    <Row justify={'space-between'} gutter={[24, 24]}>
                        <Col span={12}>
                            <Form.Item name={'provider_id'}
                                       label={t('Поставщик')}
                                       rules={[{required: false, message: t('Обязательное поле')}]}
                            >
                                <Select
                                    options={providerOpt}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Button
                                type={'primary'}
                                onClick={() => {
                                    pwdStgForm.submit()
                                    // setIsFilter(true)
                                }}
                                size={'small'}
                                style={{marginTop: '22px'}}
                            >
                                {t('Добавить поставщика')}
                            </Button>
                        </Col>

                    </Row>
                </Card>

            </Form>

                <Table
                    columns={columns}
                    dataSource={data}

                />

        </Modal>
    </>;
}
