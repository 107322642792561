import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Filters from './Filters.jsx';
import {Popconfirm, Switch} from 'antd';
import {
    $controllersList,
    $controllersMap,
    $selectedControllers,
    $filters,
    changeSelectedControllersEv,
    createTaskEv, getControllersFx,
    TaskPdfToContsGate, resetFiltersEv, changeFiltersEv
} from '../../../models/taskPdfToControllersModel/index.js';
import {useUnit} from 'effector-react';
import {Tag} from 'antd';
import Tooltip from 'antd/es/tooltip';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {useSearchParams} from 'react-router-dom';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion.jsx";
import FilterBlock from "../../../components/FiltersBlock/FiltersBlock.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import queryString from "query-string";

export default function CreateTaskPerControllers() {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang') ?? 'ru'

    const {data, count} = useUnit($controllersList)
    const contMap = useUnit($controllersMap)
    const loading = useUnit(getControllersFx.pending)
    const onChangeSelect = useUnit(changeSelectedControllersEv)
    const selected = useUnit($selectedControllers)
    const createTask = useUnit(createTaskEv)

    const changeFilters = useUnit(changeFiltersEv)

    const [search] = useSearchParams()
    const resetFilters = useUnit(resetFiltersEv)

    const columns = [
        {
            title: t('Код'),
            dataIndex: 'id',
            width: '5%',
            sorter: true,
            key: 'id',
        },
        {
            title: t('ФИО контролера'),
            dataIndex: 'name_ru',
            sorter: true,
            width: '15%',
            key: 'fio',
            render: (_, i) => lang === 'kz' && i.name_kz ? i.name_kz : i.name_ru
        },
        {
            title: t('Участок'),
            dataIndex: 'sector_ids',
            sorter: true,
            key: 'sector_ids',
            width: '15%',
            // render: (sector_ids, row) => sector_ids.map(item => <div><MappedDictItem id={item} type={'sector'}/> </div>)
            render: (sector_ids, row) => <CustomAccordion ids={sector_ids} count={row?.sector_count} type={'sector'}/>
        },
        // {
        //     title: t('Район города'),
        //     dataIndex: 'town_distr_ids',
        //     sorter: true,
        //     width: '15%',
        //     key: 'town_distr_ids',
        //     // render: (town_distr_ids) => town_distr_ids.map(item => <div><MappedDictItem id={item} type={'town_distr'}/></div>)
        //     render: (town_distr_ids, row) => <CustomAccordion ids={town_distr_ids} count={row?.sector_count} type={'town_distr'}/>
        // },
        {
            title: t('Город'),
            dataIndex: 'town_id',
            sorter: true,
            width: '15%',
            key: 'town',
            render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>,
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        {name: 'sector_id', label: t('Участок'), type: 'select', dict: 'sector', multiple: true},
        {name: 'town_distr_id', label: t('Район города'), type: 'select', dict: 'districts', multiple: true},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns', multiple: true},
    ]

    const onCloseTag = (e) => {
        const data = selected.filter(item => item !== e)
        onChangeSelect(data)
    }

    const onCreateTask = () => {
        createTask()
        onChangeSelect([])
        resetFilters()

    }

    const onCreateTaskAll = () => {
        changeFilters({key: 'show_list_option', value: 'only_active'})

        createTask()
        onChangeSelect([])
        resetFilters()

    }







    return <>
        <TaskPdfToContsGate search={search}/>
        <Card>
            <Filters />
            {/*<FilterBlock items={filters}/>*/}
        </Card>
        <Card>
            <Divider orientationMargin={0} orientation={'left'}>{t('Выбранные контролеры')}</Divider>
            <Row>
                {selected.length > 0
                    ? selected.map((i, idx) => <Tag
                            closable
                            onClose={(e) => {
                                e.preventDefault()
                                onCloseTag(i);
                            }}
                            key={idx}
                        >
                            {contMap[i]}
                        </Tag>
                    )
                    : filters.select_all === true
                        ? t('Все контролеры') + ` (${count})`
                        : t('Нет')
                }
            </Row>

        </Card>
        <Card>


        <div style={{position: 'relative'}}>
            <Row
                style={{
                    position: 'absolute',
                    zIndex: 10,
                    top: '6px',
                    left: '25px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
                justify={'space-between'}
            >
                {
                    selected.length > 0 ?
                        <Popconfirm
                            title={'Вы уверены, что хотите выбрать всех? Ранее выбранные Контролеры сбросятся'}
                            okText={t('Да')}
                            placement={'left'}
                            cancelText={t('Нет')}
                            onConfirm={onCreateTaskAll}
                        >
                            <Button type={'primary'}
                                    style={{marginRight: '10px', marginBottom: '5px'}}
                            >
                                {t(`Сформировать PDF по всем отфильтрованным контролерам (${count})`)}
                            </Button>
                        </Popconfirm>
                        :
                        <Button type={'primary'}
                                style={{marginRight: '10px', marginBottom: '5px'}}
                                onClick={onCreateTaskAll}
                        >
                            {t(`Сформировать PDF по всем отфильтрованным контролерам (${count})`)}
                        </Button>
                }


                <Tooltip
                    title={!(filters?.select_all === true || selected.length > 0) ? t('Необходимо выбрать Контролеров') : null}>
                    <Button type={'primary'}
                            style={{marginRight: '10px', marginBottom: '5px'}}
                            onClick={onCreateTask}
                            disabled={!(filters?.select_all === true || selected.length > 0)}
                    >
                        {t('Сформировать PDF по выбранным контролерам')}
                    </Button>
                </Tooltip>

                <div style={{display: 'flex', gap: 4, marginLeft: 16}}>
                <span>Пересоздать PDF</span>
                <Switch value={filters.recreate_pdf} onChange={(checked) => changeFilters({
                    key: 'recreate_pdf',
                    value: checked
                })}/>
                </div>

            </Row>
            <DictionaryProTable
                style={{paddingTop: '30px'}}
                data={data}
                count={count}
                initColumns={columns}
                    loading={loading}
                    showActions={false}
                    rowKey={'id'}
                    rowSelection={{
                        onChange: onChangeSelect,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selected
                    }}
                />


            </div>
        </Card>

    </>
}

