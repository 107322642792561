import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getReportsListFx} from "./effects.js";

export const ReportsListGate = createGate()
export const ReportsViewGate = createGate()
export const ReportsEditGate = createGate()
export const ReportsCreateGate = createGate()

export const submitCreateReportsEv = createEvent()
export const submitEditReportsEv = createEvent()
export const deleteReportsEv = createEvent()
export const downloadReportEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()

export const sendMessageUploadEv = createEvent()
export const acceptMessageUploadEv = createEvent()
