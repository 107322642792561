import {createEvent} from 'effector';
import {createGate} from 'effector-react';

// Ивенты для CRUD таблицы вложенных адресов
export const AddressesChildrenGate = createGate()
export const changeAddressesChildrenFiltersEv = createEvent()

export const setCreateEditChildAddressEmbeddedEv = createEvent()
export const resetCreateEditChildAddressEmbeddedEv = createEvent()
export const createChildAddressEv = createEvent()
export const deleteNewAddressEv = createEvent()
export const editChildAddressEv = createEvent()
export const deleteChildAddressEv = createEvent()