import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $providerKassaList,
    $selectedProvService, addProvKassaEv, deleteProvKassaEv,
    ProviderKassaGate,
} from '../../../../models/dictionaryProvidersModel/index.js';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Tooltip from 'antd/es/tooltip';
import Col from "antd/es/col";
import Row from "antd/es/row";
import ButtonGroup from 'antd/es/button/button-group';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {
    DeleteOutlined, PlusOutlined,
} from '@ant-design/icons';
import {$dictsSelects} from "../../../../models/infoModel/index.js";
import Form from "antd/es/form";
import Space from "antd/es/space/index.js";
import {Select} from "antd";
import {useState} from "react";

export default function ProviderKassaForm({id, isView}) {
    const {t} = useTranslation()

    const [kassaForm] = Form.useForm()

    const {data, count} = useUnit($providerKassaList)
    const selectedService = useUnit($selectedProvService)
    const deleteProvKassa = useUnit(deleteProvKassaEv)
    const addProvKassa = useUnit(addProvKassaEv)




    const addKassa = (v) => {
        addProvKassa({prov_id: id, bank_id: v.bank_id})
        kassaForm.resetFields()
    }


    const bankOpts = useStoreMap($dictsSelects, sel => sel.bank)



    const sucBank = data.map(item => item.bank_id)

    const filterBanks = bankOpts.filter(item => !sucBank.includes(item.value))

    const columns = [
        {
            title: t('Касса'),
            dataIndex: 'bank_id',
            sorter: (a, b) => a.bank_id - b.bank_id,
            width: '20%',
            render: (id) => <MappedDictItem id={id} type={'banks'}/>,
        },

        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => deleteProvKassa({id})}
                            disabled={isView}
                            placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined />} danger disabled={isView}/>
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    ]
    return <>
        <ProviderKassaGate id={id}/>
        {
            !isView && <Form
                form={kassaForm}
                size={'small'}
                layout={'vertical'}
                onFinish={(v) => addKassa(v)}
                validateTrigger={'onBlur'}
            >
                <Row gutter={[16,16]}>
                    <Col span={6}>
                        <Form.Item name={'bank_id'}
                                   label={t('Касса')}
                            // rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Select
                                options={filterBanks}

                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }

                            />

                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'add'}
                                   label={t(' ')}
                            // rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Button
                                onClick={() => {
                                    // setAction('new')
                                    kassaForm.submit()
                                }}
                                type={'primary'}
                                // ghost
                            >
                                {t('Добавить')}
                            </Button>
                        </Form.Item>

                    </Col>
                </Row>

            </Form>
        }

        <Table dataSource={data}
               columns={columns}
               bordered
               rowKey={'service_id'}
               className={'table-container'}
               pagination={false}
               size={'small'}
               rowClassName={(record) => record.id === selectedService?.id ? 'selectedRowObject' : null}
        />
    </>
}
