import {
    $afterCreateAction,
    $createdBanksId,
    $editBanksStatus,
    $selectedBanks,
    $banksList
} from './stores.js';
import {getBanksListFx, getBanksByIdFx} from './effects.js';
import {
    deleteBanksEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    BanksEditGate,
    BanksListGate,
    BanksViewGate,
    submitCreateBanksEv,
    submitEditBanksEv, BanksCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateBanksEv, response: triggerSuccessOpsEv}, reset: BanksCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditBanksEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteBanksEv, response: triggerSuccessOpsEv})

$banksList.on(getBanksListFx.doneData, (_, data) => data)
    .reset(BanksListGate.close)

$selectedBanks.on(getBanksByIdFx.doneData, (_, data) => data)
    .reset([BanksEditGate.close, BanksViewGate.close])
// FIXME
$createdBanksId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "banks")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editBanksStatus.on(editCompleteEv, () => true)
    .reset(BanksEditGate.close)

sample({
    clock: [BanksEditGate.state, BanksViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getBanksByIdFx
})

sample({
    clock: BanksListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBanksListFx
})

sample({
    source: BanksListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBanksListFx
})

sample({
    clock: submitCreateBanksEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        // FIXME

        actions.push({operation: 'create', type: 'banks', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedBanks,
    clock: submitEditBanksEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1



        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'banks', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteBanksEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'banks', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
