import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Tabs from "antd/es/tabs";
import PaymentPage from "./PaymentPage.jsx";
import PaymentApplies from "./PaymentApplies.jsx";
import PaymentExport from "./PaymentExport.jsx";
import PaymentBankApi from "./PaymentBankApi.jsx";
import StickyBox from "react-sticky-box";
import PaymentRetentionMonth from "./PaymentRetentionMonth.jsx";
import PaymentRetentionDay from "./PaymentRetentionDay.jsx";
import {useUnit} from "effector-react";
import {resetBottomTableEv} from "../../models/paymentModel/index.js";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {useSearchParams} from "react-router-dom";

export default function PaymentContainer() {
    const {t} = useTranslation()
    const [activeKey, setActiveKey] = useState('confirm');
    const [search, setSearch] = useSearchParams()

    const onResetBottomTab = useUnit(resetBottomTableEv)

    const tabs = [
        {
            key: 'confirm',
            label: t('Подтверждение от банка'),
            children: <PaymentPage/>
        },
        {
            key: 'applies',
            label: t('Распределение по ЛС'),
            children: <PaymentApplies/>
        },
        {
            key: 'export',
            label: t('Распределение по р/с поставщиков'),
            children: <PaymentExport/>
        },
        {
            key: 'retention-day',
            label: t('Удержание по дням'),
            children: <PaymentRetentionDay/>
        },
        {
            key: 'retention-month',
            label: t('Удержание за месяц'),
            children: <PaymentRetentionMonth/>
        },
        // {
        //     key: 'payments',
        //     label: t('Платежи'),
        //     children: <PaymentsPage/>
        // },
        {
            key: 'bank-api',
            label: t('Обращения по API'),
            children: <PaymentBankApi/>
        },

    ]

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={24} style={{zIndex: 11}}>
            <DefaultTabBar
                {...props}
                style={{
                    background: '#f5f5f5',
                    borderBottom: '1px solid #ffffff',
                    padding: '0 32px',
                    margin: '0 -32px 8px',
                }}
            />
        </StickyBox>
    )

    const onChangeTab = (payload) => {
        setActiveKey(payload)
        onResetBottomTab()
        setSearch('')
    }

    return <>
        <Tabs
            defaultActiveKey={'confirm'}
            items={tabs}
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={onChangeTab}
            type={'card'}
            renderTabBar={renderTabBar}
        />

    </>
}
