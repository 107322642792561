import axios from "axios"
import cookies from "../utils/cookie.jsx";
import {notification} from "antd";
import {setAuthStatus} from '../models/authModel';
import {setObjectDictPathEv} from "../models/notFoundModel";
import i18n from '../i18n.js';
import {openPasswordModalEv} from '../models/infoModel/index.js';

const apiVersion = 'api/v1'
const apiUrl = window.location.origin.includes('localhost') ?
    `https://erc-dev.frontier.kz/${apiVersion}` :
    window.location.origin + '/' + apiVersion

export default function api (auth = true) {


    const instance = axios.create({baseURL: apiUrl, withCredentials: true});

    if (auth) {
        const access_token = cookies.get('access_token')
        instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    }


    instance.interceptors.response.use((response) => {
        return response;
    }, async (error) => {
        if (error.request.status === 0) {
            notification.error({
                message: i18n.t("Ошибка"),
                description: i18n.t('Нет сети или сервер не отвечает'),
                placement: 'topRight'
            });
        } else if (error.response.status === 401 && error.config.url.includes("token/login")) {
            cookies.remove('access_token', {path: '/'})
            setAuthStatus(false)
        } else if (error.response.status === 401) {
            cookies.remove('access_token', {path: '/'})
            setAuthStatus(false)
        } else if (error.response.status === 404){
            if (!error?.config?.url.includes('download')) {
                const pathName = window.location.pathname.replace(/\/[a-z]+\/\d+$/, '')
                setObjectDictPathEv(pathName)
                window.location.replace('/not-found')
            }
        } else if (error.response.status === 403 && !error?.response?.config?.url.includes('change_password')) {
            notification.error({
                message: i18n.t('Нет доступа'),
                description: i18n.t('У вас отсутствует право на данный ресурс или на данную операцию'),
                placement: 'topRight'
            });
        } else if (error.response.status === 428) {
            notification.error({
                message: i18n.t('Смена пароля'),
                description: i18n.t('Вам необходимо сменить пароль'),
                placement: 'topRight'
            });
            openPasswordModalEv()
        } else {
            if (error.config.url.includes('create_aps') && error.response.status === 400) {
                return Promise.reject(error);
            } else if ((error.config.url.includes('change_password') || error.config.url.includes('users')) && error.response.status === 422) {
                return Promise.reject(error);
            } else {
                notification.error({
                    message: error.response.status === 500 ? i18n.t('Серверная ошибка') : error.response.status,
                    description: `${error.message}`,
                    placement: 'topRight'
                });
            }
        }
        return Promise.reject(error);
    });

    return instance;
}
