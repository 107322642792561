import {
    $afterCreateAction,
    $createdCalcTypeId,
    $editCalcTypeStatus,
    $selectedCalcType,
    $calcTypeList
} from './stores.js';
import {getCalcTypeListFx, getCalcTypeByIdFx} from './effects.js';
import {
    deleteCalcTypeEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    CalcTypeEditGate,
    CalcTypeListGate,
    CalcTypeViewGate,
    submitCreateCalcTypeEv,
    submitEditCalcTypeEv, CalcTypeCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateCalcTypeEv, response: triggerSuccessOpsEv}, reset: CalcTypeCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditCalcTypeEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteCalcTypeEv, response: triggerSuccessOpsEv})

$calcTypeList.on(getCalcTypeListFx.doneData, (_, data) => data)
    .reset(CalcTypeListGate.close)

$selectedCalcType.on(getCalcTypeByIdFx.doneData, (_, data) => data)
    .reset([CalcTypeEditGate.close, CalcTypeViewGate.close])
// FIXME
$createdCalcTypeId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "calc_type")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editCalcTypeStatus.on(editCompleteEv, () => true)
    .reset(CalcTypeEditGate.close)

sample({
    clock: [CalcTypeEditGate.state, CalcTypeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getCalcTypeByIdFx
})

sample({
    clock: CalcTypeListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getCalcTypeListFx
})

sample({
    source: CalcTypeListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getCalcTypeListFx
})

sample({
    clock: submitCreateCalcTypeEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'calc_type', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedCalcType,
    clock: submitEditCalcTypeEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'calc_type', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteCalcTypeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'calc_type', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
