import {createStore} from 'effector';


export const $servicesList = createStore({data: [], count: 0})

export const $selectedServices = createStore(null)

export const $editServicesStatus = createStore(false)

export const $createdServicesId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $serviceTypeEmbeddedOpen = createStore(false)
