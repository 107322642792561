import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedFile,
    $selectedFileCells, acceptFileEv, downloadErrorFileEv,
    getFileCellsFx, rejectFileEv, ViewFileCellsGate
} from '../../../models/fileUploadModel';
// import {usePaginationControl} from '../../../hooks/usePaginationControl.js';
// import {useServerSort} from '../../../hooks/useServerSort.js';
import dayjs from 'dayjs';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import styles from '../Upload.module.css';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import React, {useState} from 'react';
import {Popconfirm, Switch} from 'antd';
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames} from "../../../utils/monthNamesDict.js";
import Collapse from "antd/es/collapse";
import TextArea from "antd/es/input/TextArea";
import {useServerSortPage} from '../../../hooks/useServerSortPage.js';
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";

export default function ViewFileCells() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const selectedFile = useUnit($selectedFile)
    const {data, count} = useUnit($selectedFileCells)
    const loading = useUnit(getFileCellsFx.pending)
    const accept = useUnit(acceptFileEv)
    const reject = useUnit(rejectFileEv)

    const downloadErrorFile = useUnit(downloadErrorFileEv)

    const {id} = useParams()
    const [search, setSearch] = useSearchParams()

    const [curPage, limit, _field, _order, onChangeTable] = useServerSortPage()

    const [valid, setValid] = useState(!!(search.get('validation') && search.get('validation')==='true'))
    const onValidChange = (checked) => {
        setValid(!!checked)
        search.set('validation', checked)
        setSearch(search)
    }

    const confirmDisabled = !(
        selectedFile?.status === 'processed' && selectedFile?.is_accepted === null
    )

    const downloadErrors = selectedFile?.total_errors > 0

    const textError = (data) => {
        const e = data || ''
        const se = e.split('|')
        const te = se.shift()
        const ie = se.join(', ')

        const error = t(`import.errors.${te}`)
        return <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
    }

    const columns = [
        {
            title: t('№ строки'),
            dataIndex: 'row_index',
            align: 'center',
            key: 'row_index',
            sorter: true,
        },
        {
            title: t('Статус'),
            dataIndex: 'row_is_valid',
            align: 'center',
            key: 'valid',
            sorter: true,
            render: (valid) => valid ? <CheckOutlined/> : <CloseOutlined style={{color: 'red'}}/>
        },
        // {
        //     title: t('Код ЕПД'),
        //     dataIndex: 'epd_id_value',
        //     key: 'epd_id_value',
        //     sorter: true,
        //     onCell: (record) => ({className: record?.epd_id_error && styles.invalid}),
        //     render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
        //         <span>{v}</span>
        //         {record?.epd_id_error && textError(record?.epd_id_error)}
        //     </div>
        // },
        {
            title: t('№ ЛС'),
            dataIndex: 'account_id_value',
            key: 'account_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.account_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.account_id_error && textError(record?.account_id_error)}
            </div>
        },
        {
            title: t('Код услуги поставщика'),
            dataIndex: 'provider_service_id_value',
            key: 'provider_service_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.provider_service_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.provider_service_id_error &&
                    textError(record?.provider_service_id_error)}
            </div>
        },
        {
            title: t('Услуга'),
            dataIndex: 'service_id_value',
            key: 'service_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.service_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.service_id_error &&
                    textError(record?.service_id_error)}
            </div>
        },
        {
            title: t('Количество проживающих'),
            dataIndex: 'people_quantity_value',
            key: 'people_quantity_value',
            sorter: true,
            onCell: (record) => ({className: record?.people_quantity_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.people_quantity_error && textError(record?.people_quantity_error)}
            </div>
        },
        {
            title: t('Площадь жилья'),
            dataIndex: 'full_square_value',
            key: 'full_square_value',
            sorter: true,
            onCell: (record) => ({className: record?.full_square_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>

                {record?.full_square_error && textError(record?.full_square_error)}
            </div>
        },
        {
            title: t('Тариф'),
            dataIndex: 'tarif_value_value',
            key: 'tarif_value_value',
            sorter: true,
            onCell: (record) => ({className: record?.tarif_value_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={3}/>
                {record?.tarif_value_error && textError(record?.tarif_value_error)}
            </div>
        },
        {
            title: t('Единица измерения'),
            dataIndex: 'measure_name_value',
            key: 'measure_name_value',
            sorter: true,
            onCell: (record) => ({className: record?.measure_name_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.measure_name_error && textError(record?.measure_name_error)}
            </div>
        },
        {
            title: t('Знак суммы расчета'),
            dataIndex: 'sign_saldo_calc_value',
            key: 'sign_saldo_calc_value',
            sorter: true,
            onCell: (record) => ({className: record?.sign_saldo_calc_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.sign_saldo_calc_error && textError(record?.sign_saldo_calc_error)}
            </div>
        },
        {
            title: t('Сальдо начальное'),
            dataIndex: 'sum_saldo_begin_value',
            key: 'sum_saldo_begin_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_saldo_begin_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.sum_saldo_begin_error && textError(record?.sum_saldo_begin_error)}
            </div>
        },
        {
            title: t('Сумма расчета'),
            dataIndex: 'sum_calc_value',
            key: 'sum_calc_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_calc_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_calc_error && textError(record?.sum_calc_error)}
            </div>
        },
        {
            title: t('Сумма перерасчета'),
            dataIndex: 'sum_recalc_value',
            key: 'sum_recalc_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_recalc_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_recalc_error && textError(record?.sum_recalc_error)}
            </div>
        },
        {
            title: t('Сальдо конечное'),
            dataIndex: 'sum_saldo_end_value',
            key: 'sum_saldo_end_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_saldo_end_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_saldo_end_error && textError(record?.sum_saldo_end_error)}
            </div>
        },
        {
            title: t('Количество платежей'),
            dataIndex: 'pay_count_value',
            key: 'pay_count_value',
            sorter: true,
            onCell: (record) => ({className: record?.pay_count_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.pay_count_error && textError(record?.pay_count_error)}
            </div>
        },
        {
            title: t('Количество чеков'),
            dataIndex: 'check_count_value',
            key: 'check_count_value',
            sorter: true,
            onCell: (record) => ({className: record?.check_count_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.check_count_error && textError(record?.check_count_error)}
            </div>
        },
        {
            title: t('Дата чека'),
            dataIndex: 'check_date_value',
            key: 'check_date_value',
            sorter: true,
            onCell: (record) => ({className: record?.check_date_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.check_date_error && textError(record?.check_date_error)}
            </div>
        },
        {
            title: t('Мин. тарифная ставка'),
            dataIndex: 'tarif_value_min_value',
            key: 'tarif_value_min_value',
            sorter: true,
            onCell: (record) => ({className: record?.tarif_value_min_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={3}/>
                {record?.tarif_value_min_error && textError(record?.tarif_value_min_error)}
            </div>
        },
        {
            title: t('Ср. тарифная ставка'),
            dataIndex: 'tarif_value_avg_value',
            key: 'tarif_value_avg_value',
            sorter: true,
            onCell: (record) => ({className: record?.tarif_value_avg_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={3}/>
                {record?.tarif_value_avg_error && textError(record?.tarif_value_avg_error)}
            </div>
        },
        {
            title: t('Макс. тарифная ставка'),
            dataIndex: 'tarif_value_max_value',
            key: 'tarif_value_max_value',
            sorter: true,
            onCell: (record) => ({className: record?.tarif_value_max_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={3}/>
                {record?.tarif_value_max_error && textError(record?.tarif_value_max_error)}
            </div>
        },
        {
            title: t('Мин. сумма расчета'),
            dataIndex: 'sum_calc_min_value',
            key: 'sum_calc_min_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_calc_min_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_calc_min_error && textError(record?.sum_calc_min_error)}
            </div>
        },
        {
            title: t('Ср. сумма расчета'),
            dataIndex: 'sum_calc_avg_value',
            key: 'sum_calc_avg_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_calc_avg_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_calc_avg_error && textError(record?.sum_calc_avg_error)}
            </div>
        },
        {
            title: t('Макс. сумма расчета'),
            dataIndex: 'sum_calc_max_value',
            key: 'sum_calc_max_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_calc_max_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_calc_max_error && textError(record?.sum_calc_max_error)}
            </div>
        },
        {
            title: t('Оплаченная сумма'),
            dataIndex: 'sum_pay_value',
            key: 'sum_pay_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_pay_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_pay_error && textError(record?.sum_pay_error)}
            </div>
        },
        {
            title: t('Сумма штрафа'),
            dataIndex: 'sum_fine_value',
            key: 'sum_fine_value',
            sorter: true,
            onCell: (record) => ({className: record?.sum_fine_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>
                {record?.sum_fine_error && textError(record?.sum_fine_error)}
            </div>
        },
        {
            title: t('Потребление'),
            dataIndex: 'consumption_value',
            key: 'consumption_value',
            sorter: true,
            onCell: (record) => ({className: record?.consumption_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.consumption_error && textError(record?.consumption_error)}
            </div>
        },
        {
            title: t('Потребление мин.'),
            dataIndex: 'consumption_min_value',
            key: 'consumption_min_value',
            sorter: true,
            onCell: (record) => ({className: record?.consumption_min_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.consumption_min_error && textError(record?.consumption_min_error)}
            </div>
        },
        {
            title: t('Потребление ср.'),
            dataIndex: 'consumption_avg_value',
            key: 'consumption_avg_value',
            sorter: true,
            onCell: (record) => ({className: record?.consumption_avg_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.consumption_avg_error && textError(record?.consumption_avg_error)}
            </div>
        },
        {
            title: t('Потребление макс.'),
            dataIndex: 'consumption_max_value',
            key: 'consumption_max_value',
            sorter: true,
            onCell: (record) => ({className: record?.consumption_max_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.consumption_max_error && textError(record?.consumption_max_error)}
            </div>
        },
        {
            title: t('Норма 1'),
            dataIndex: 'norm1_value',
            key: 'norm1_value',
            sorter: true,
            onCell: (record) => ({className: record?.norm1_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.norm1_error && textError(record?.norm1_error)}
            </div>
        },
        {
            title: t('Норма 2'),
            dataIndex: 'norm2_value',
            key: 'norm2_value',
            sorter: true,
            onCell: (record) => ({className: record?.norm2_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.norm2_error && textError(record?.norm2_error)}
            </div>
        },
    ]

    const items = [
        {
            key: 'provider_id',
            label: t('Поставщик'),
            // children: <CreatedBy id={selectedFile?.provider_id}/>
            children: <MappedDictItem id={selectedFile?.provider_id} type={'providers'}/>
        },
        {
            key: 'total_rows',
            label: t('Всего строк'),
            children: selectedFile?.total_rows
        },
        {
            key: 'total_errors',
            label: t('Всего ошибок'),
            children: selectedFile?.total_errors
        },
        // {
        //     key: 'file_extention',
        //     label: t('Расширение'),
        //     children: selectedFile?.file_extention
        // },
        {
            key: 'file_type',
            label: t('Тип'),
            children: `${selectedFile?.file_type ?? '-'} ${t('столбцов')}`,
        },
        {
            key: 'report_date',
            label: t('Месяц'),
            children: selectedFile?.report_date ? t(monthNames[new Date(selectedFile?.report_date).getMonth() + 1]) : '-'
        }
    ]

    const dateItems = [
        {
            key: 'load_date',
            label: t('Дата загрузки'),
            children: selectedFile?.load_date && dayjs(selectedFile?.load_date).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'to_list',
            children: <Button
                onClick={() => navigate("../..", {relative: "path", replace: true})}
            >{t('К списку')}</Button>
        },
        {
            key: 'confirm',
            children: <Popconfirm
                title={t('Вы уверены, что хотите подтвердить этот файл?')}
                onConfirm={() => {
                    accept(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
            >
                <Button type={'primary'}
                        disabled={confirmDisabled}>{t('Подтвердить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'cancel',
            children: <Popconfirm
                title={t('Вы уверены, что хотите отклонить этот файл?')}
                onConfirm={() => {
                    reject(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
                okButtonProps={{danger: true}}
            >
                <Button danger disabled={confirmDisabled}>{t('Отклонить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'downloadErrors',
            children: <Button
                disabled={!downloadErrors}
                onClick={() => downloadErrorFile(id)}
            >{t('Скачать файл с ошибками')}</Button>
        },
    ]

    return <Card title={`${t('Просмотр строк файла')} ${selectedFile?.file_name}`}>
        <ViewFileCellsGate id={id} search={search}/>
        <ViewDictionaryItemCommon showNav={false} items={items} itemsDates={dateItems}/>
        <Divider/>
        {selectedFile?.load_error ? <Collapse
            size="small"
            items={[
                {
                    key: '1',
                    label: t('Лог ошибок обработки файла'),
                    children: <TextArea rows={40} value={selectedFile?.load_error} />,
                },
            ]}
        /> : null}
        <Divider/>
        <div style={{display: 'flex', gap: 4, marginBottom: 24}}>
            <Switch checked={valid} onChange={onValidChange} checkedChildren={'Валидные'} unCheckedChildren={'Ошибочные'} />
        </div>
        <Table dataSource={data}
               loading={loading}
               columns={columns}
               onChange={(pagination, _filters, sorter) => {
                   onChangeTable({page: pagination?.current, limit: pagination?.pageSize, sort_field: sorter?.field ?? null, sort_order: sorter?.order ?? null})
               }}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(curPage),
                   total: count
               }}
               size={'small'}
               className={'table-container'}
        />
    </Card>
}
