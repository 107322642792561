import Card from "antd/es/card";
import {useTranslation} from "react-i18next";
import {
	LaunchRecalculationsGate,
	sendLaunchRecalculationEv,
} from "../../models/launchRecalculationsModel/index.js";
import {useStoreMap, useUnit} from "effector-react";
import {$dictsSelects} from "../../models/infoModel/index.js";
import {Radio, Row, Select, Space} from "antd";
import {useCallback, useEffect, useState} from "react";
import style from './LaunchRecalculations.module.css'
import Input from "antd/es/input";
import Button from "antd/es/button";
import Col from "antd/es/col";
import TESTPAGENotification from "../../components/TESTPAGENotification/TESTPAGENotification.jsx";

export default function LaunchRecalculations(){
	const {t} = useTranslation()
	const {providersOpts, servicesOpts, inspectorsOpt, sectorOpts} = useStoreMap($dictsSelects, sel => ({
		providersOpts: sel.providers,
		servicesOpts: sel.services,
		inspectorsOpt: sel.inspector,
		sectorOpts: sel.sector,
	}))
	const sendLaunchRecalculation = useUnit(sendLaunchRecalculationEv)

	const [activeRadio, setActiveRadio] = useState('all')
	const [values, setValues] = useState({
		provider: null,
		service: null,
		inspector: null,
		sector: null,
		real_estate_object: null,
		account: null,
	})

	const onChange = (e) => {
		console.log('radio checked', e.target.value)
		setValues({
			provider: null,
			service: null,
			inspector: null,
			sector: null,
			real_estate_object: null,
			account: null,
		})
		setActiveRadio(e.target.value)
	}

	const changeValue = (e) => {
		if(activeRadio === 'real_estate_object' || activeRadio === 'account'){
			setValues({
				...values,
				[activeRadio]: e.target.value
			})
		} else {
			setValues({
				...values,
				[activeRadio]: e
			})
		}
	}

	const onSubmit = () => {
		sendLaunchRecalculation({
			object_type: activeRadio,
			object_id: activeRadio === 'all' ? 0 : values[activeRadio]
		})
	}

	useEffect(() => {
		console.log('values', values)
	}, [values])

	const providerOptions = providersOpts.filter(item => item.value !== ' ')
	const servicesOptions = servicesOpts.filter(item => item.value !== ' ')
	const inspectorsOptions = inspectorsOpt.filter(item => item.value !== ' ').filter(item => item.value !== '-')
	const sectorOptions = sectorOpts.filter(item => item.value !== ' ').filter(item => item.value !== '-')





	return <>
		<LaunchRecalculationsGate />
        <TESTPAGENotification />
		<Card
			title={t('Запустить пересчет')}
			size={"small"}
		>
			<Radio.Group onChange={onChange} value={activeRadio} style={{display: 'block'}}>
				<Row justify={'start'} gutter={[16,16]}>
					<Col span={5}>
						<Radio value={'all'}>{t('Все')}</Radio>
					</Col>
					<Col span={5}>
						<Radio value={'provider'}>
						<span onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}}>
							<Select
								className={style.launch_recalc_ipnut}
								placeholder={t('Выберите поставщика')}
								options={providerOptions}
								allowClear
								showSearch
								filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
								disabled={activeRadio !== 'provider'}
								value={values['provider']}
								onChange={changeValue}
							/>
						</span>
					</Radio>
					</Col>
					<Col span={5}>
						<Radio value={'service'}>
						<span onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}}>
							<Select
								className={style.launch_recalc_ipnut}
								placeholder={t('Выберите услугу')}
								options={servicesOptions}
								allowClear
								showSearch
								filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
								disabled={activeRadio !== 'service'}
								value={values['service']}
								onChange={changeValue}
							/>
						</span>
					</Radio>
					</Col>
					<Col span={5}>
						<Radio value={'inspector'}>
						<span onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}}>
							<Select
								className={style.launch_recalc_ipnut}
								placeholder={t('Выберите контроллера')}
								options={inspectorsOptions}
								allowClear
								showSearch
								filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
								disabled={activeRadio !== 'inspector'}
								value={values['inspector']}
								onChange={changeValue}
							/>
						</span>
					</Radio>
					</Col>
					<Col span={5}>
						<Radio value={'sector'}>
						<span onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
						}}>
							<Select
								className={style.launch_recalc_ipnut}
								placeholder={t('Выберите участок')}
								options={sectorOptions}
								allowClear
								showSearch
								filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
								disabled={activeRadio !== 'sector'}
								value={values['sector']}
								onChange={changeValue}
							/>
						</span>
						</Radio>
					</Col>
					<Col span={5}>
						<Radio value={'real_estate_object'}>
							<Input
								className={style.launch_recalc_ipnut}
								disabled={activeRadio !== 'real_estate_object'}
								value={values['real_estate_object']}
								onChange={changeValue}
								placeholder={t('Введите объект недвижимости')}
							/>
						</Radio>
					</Col>
					<Col span={5}>
						<Radio value={'account'}>
							<Input
								className={style.launch_recalc_ipnut}
								disabled={activeRadio !== 'account'}
								value={values['account']}
								onChange={changeValue}
								placeholder={t('Введите лицеой счет')}
							/>
						</Radio>
					</Col>
				</Row>
			</Radio.Group>
			<Button
				type={'primary'}
				onClick={onSubmit}
				className={style.submit_button}
				disabled={activeRadio !== 'all' ? !values[activeRadio] : false}
			>
				{t('Запустить')}
			</Button>
		</Card>
	</>
}
