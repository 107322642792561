import {createEffect, createEvent, createStore, sample} from 'effector';
import {interval} from 'patronum';
import {$info, getInfoFx} from '../infoModel/index.js';
import api from '../../api/api.js';

export const $closeMonthBlockingModalOpen = createStore(false)
export const setCloseMonthBlockingModal = createEvent()
export const stopTaskEv = createEvent()
export const stopTaskFx = createEffect()
    .use(async (id) => (await api().post(`/tasks/revoke/${id}`)).data)

const startTimeout = createEvent()
const stopTimeout = createEvent()

$closeMonthBlockingModalOpen.on(setCloseMonthBlockingModal, (_, payload) => payload)

const {tick} = interval({timeout: 30000, start: startTimeout, stop: stopTimeout})

sample({
    clock: $closeMonthBlockingModalOpen.updates,
    filter: (open) => open === true,
    target: startTimeout
})

sample({
    clock: $closeMonthBlockingModalOpen.updates,
    filter: (open) => open === false,
    target: stopTimeout
})

sample({
    clock: tick,
    target: getInfoFx
})

sample({
    source: $closeMonthBlockingModalOpen,
    clock: $info.updates,
    filter: (modal, info) => modal === false && info?.closing_month_task !== null,
    fn: () => true,
    target: setCloseMonthBlockingModal
})

sample({
    source: $closeMonthBlockingModalOpen,
    clock: $info.updates,
    filter: (modal, info) => modal === true && info?.closing_month_task === null,
    fn: () => false,
    target: setCloseMonthBlockingModal
})

sample({
    source: $info,
    clock: stopTaskEv,
    fn: (info) => info?.closing_month_task,
    target: stopTaskFx
})