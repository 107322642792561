import {createStore} from 'effector';
import {submitCreateBanksEv} from "./events.js";


export const $banksList = createStore({data: [], count: 0})

export const $selectedBanks = createStore(null)

export const $editBanksStatus = createStore(false)

export const $createdBanksId = createStore(null)
export const $afterCreateAction = createStore(null)
