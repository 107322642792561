import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getRealEstateObjectsListFx = createEffect()
    .use(async (filters) => (await api().get(`/real_estate_objects/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getRealEstateObjectsByIdFx = createEffect()
    .use(async (id) => (await api().get(`/real_estate_objects/${id}`)).data)

export const createRealEstateObjectsFx = createEffect()
    .use(async (data) => (await api().post(`/real_estate_objects/`, data)))



export const getAccountByIdFx = createEffect()
    .use(async (id) => (await api().get(`/accounts/?real_estate_object=${id}`)).data)
