import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import PlusOutlined from '@ant-design/icons/es/icons/PlusOutlined';
import {
    submitEditAddressEv,
    AddressEditGate, $selectedAddress, getAddressByIdFx, $editAddressStatus, $chosenMainAddress
} from '../../../models/dictionaryAddressesModel';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {
    setCreateDistrictEmbeddedEv,
    setCreateStreetEmbeddedEv,
    setCreateTownEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import CreateStreetEmbeddedForm
    from '../../../components/Embedded/CreateStreetEmbeddedForm/CreateStreetEmbeddedForm.jsx';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $distrsByCitySelect,
    $streetsByCitySelect, getDistrsByCityEv,
    getStreetsByCityEv
} from '../../../models/selectsByParentModel/index.js';
import CreateTownEmbeddedForm
    from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import dayjs from "dayjs";
import CreateDistrictEmbeddedForm
    from "../../../components/Embedded/CreateDistrictEmbeddedForm/CreateDistrictEmbeddedForm.jsx";
import SecondaryAddressTable from "./SecondaryAddressTable.jsx";
import SelectMainAddress from "./SelectMainAddress.jsx";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryAddressesModel/index.js";

export default function EditAddress() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selectedAddress = useUnit($selectedAddress)


    const [addressForm] = Form.useForm()

    const submit = useUnit(submitEditAddressEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const loading = useUnit(getAddressByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editAddressStatus)

    const {townOpts, distOpts, streetOpts} = useStoreMap($dictsSelects, sel => ({
        townOpts: sel.towns,
        distOpts: sel.districts,
        streetOpts: sel.streets
    }))

    const getStByTown = useUnit(getStreetsByCityEv)
    const getDistrsTown = useUnit(getDistrsByCityEv)

    const streetsByTown = useUnit($streetsByCitySelect)
    const distrsByTown = useUnit($distrsByCitySelect)
    const setStreetEmbedded = useUnit(setCreateStreetEmbeddedEv)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const setDistEmbedded = useUnit(setCreateDistrictEmbeddedEv)

    const townSearched = Form.useWatch('search_town_id', addressForm)
    const distrId = Form.useWatch('search_town_distr_id', addressForm)
    const streetId = Form.useWatch('street_id', addressForm)

    const [isMain, setIsMain] = useState(true)
    const chosenMainAddress = useUnit($chosenMainAddress)
    const toggleIsMain = () => {
        setIsMain(!isMain)
    }

    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/address?code_name=${selectedAddress.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selectedAddress) {
            const data = {
                ...selectedAddress,
                actual_from: dayjs(selectedAddress.actual_from),
                wtf_actual_from: dayjs(selectedAddress.actual_from),
                search_town_id: selectedAddress.town_id,
                search_town_distr_id: selectedAddress.town_distr_id
            }
            addressForm.setFieldsValue(data)
            setIsMain(!selectedAddress.main_address_id)
            getDistrsTown(data.town_id)
            getStByTown(data.town_id)
        }
    }, [selectedAddress]);

    const [check, changedFields] = useFormChanged(selectedAddress, ['street_id', 'house', 'is_actual', 'actual_from'])

    console.log('AddressEdit', addressForm.getFieldsValue(), townSearched)
    return <>
        <AddressEditGate id={id}/>
            <Form form={addressForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => {
                      const payload = {
                          ...v,
                          main_address_id: chosenMainAddress
                      }
                      submit(payload)
                  }}
                  validateTrigger={'onBlur'}
                  initialValues={{
                      actual_from: dayjs(new Date()),
                  }}
                  onValuesChange={(_, all) => check(all)}
            >
                <Row justify={'space-between'} gutter={[24, 24]}>
                    <Col span={12}>
                        <Card title={t('Основные свойства')} style={{height: '100%'}} size={'small'} loading={loading}>
                            <Form.Item name={'search_town_id'} label={t('Населенный пункт')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Select allowClear
                                        options={[...townOpts, {label: 'Создать', value: -1, emoji: true}]}
                                        onChange={(v) => {
                                            if (v === -1) {
                                                setTownEmbedded(true)
                                                getStByTown(undefined)
                                                getDistrsTown(undefined)
                                                addressForm.setFieldValue('street_id', v)
                                                setStreetEmbedded(true)
                                                setDistEmbedded(true)
                                            } else {
                                                setTownEmbedded(false)
                                                getStByTown(v)
                                                getDistrsTown(v)
                                                setStreetEmbedded(false)
                                                setDistEmbedded(false)
                                                addressForm.resetFields(['search_town_distr_id', 'town_distr_id', 'street', 'street_id'])
                                            }
                                        }}
                                        showSearch
                                        filterOption={(input, option) =>
                                          (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                        optionRender={(option) => (
                                          <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                            <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                              {
                                                  option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                              }
                                          </Space>
                                        )}
                                />
                            </Form.Item>
                            <CreateTownEmbeddedForm/>
                            <Form.Item name={'search_town_distr_id'} label={t('Район')}
                                       rules={distrId < 0 ? [{required: false, message: t('Обязательное поле')}] : null}
                            >
                                <Select allowClear
                                        options={typeof townSearched === 'number' && townSearched >= 0 ? [...distrsByTown, {
                                            label: 'Создать',
                                            value: -1,
                                            emoji: true
                                        }] : [...distOpts, {
                                            label: 'Создать',
                                            value: -1,
                                            emoji: true
                                        }]}
                                        onChange={(v) => {
                                            if (v === -1) {
                                                setDistEmbedded(true)
                                            } else {
                                                addressForm.resetFields(['district'])
                                                setDistEmbedded(false)
                                            }
                                        }}
                                        filterOption={(input, option) =>
                                          (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                        showSearch
                                        optionRender={(option) => (
                                          <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                            <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                              {
                                                  option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                              }
                                          </Space>
                                        )}
                                        disabled={!(typeof addressForm.getFieldValue('search_town_id') === 'number') || !isMain}
                                />
                            </Form.Item>
                            <CreateDistrictEmbeddedForm/>
                            <Form.Item name={'street_id'} label={t('Улица')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['street_id']}
                                       tooltip={t('Изменение улицы следует использовать в случае ошибки или описки. Для ситуации переименования следует воспользоваться модулем переименования.')}
                            >
                                <Select allowClear
                                        options={typeof townSearched === 'number' && townSearched >= 0 ? [...streetsByTown, {
                                            label: 'Создать',
                                            value: -1,
                                            emoji: true
                                        }] : [...streetOpts, {
                                            label: 'Создать',
                                            value: -1,
                                            emoji: true
                                        }]}
                                        onChange={(v) => {
                                            if (v === -1) {
                                                setStreetEmbedded(true)
                                            } else {
                                                addressForm.resetFields(['street'])
                                                setStreetEmbedded(false)
                                            }
                                        }}
                                        filterOption={(input, option) =>
                                          (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                        showSearch
                                        optionRender={(option) => (
                                          <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                            <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                              {
                                                  option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                              }
                                          </Space>
                                        )}
                                        disabled={!(typeof addressForm.getFieldValue('search_town_id') === 'number')}
                                />
                            </Form.Item>
                            <CreateStreetEmbeddedForm form={addressForm} searchedTown={townSearched}/>
                            <Row justify={"space-between"}>
                                <Col span={11}>
                                    <Form.Item name={'house'}
                                           label={t('Номер дома')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['house']}
                                           tooltip={t('Изменение номера дома следует использовать в случае ошибки или описки. Для ситуации переименования следует воспользоваться модулем переименования.')}
                                >
                                    <Input/>
                                </Form.Item>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        name={'corpus'} label={t('Номер корпуса')} validateStatus={changedFields['corpus']}
                                        tooltip={t('Изменение корпуса следует использовать в случае ошибки или описки. Для ситуации переименования следует воспользоваться модулем переименования.')}
                                    >
                                    <Input/>
                                </Form.Item>
                                </Col>
                            </Row>
                            <div style={{marginBottom: '24px'}}>
                                <label>{t('Основной адрес: ')}</label>
                                <Switch value={isMain} onChange={toggleIsMain}/>
                            </div>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card size={'small'} style={{height: '100%'}} loading={loading}>
                            <Form.Item name={'index'}
                                       label={t('Индекс')}
                              // rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <Input disabled={!isMain}/>
                            </Form.Item>
                            <Form.Item name={'is_on_crossroad'}
                                       label={t('Находится на перекрестке')}
                                       valuePropName={'checked'}
                                       validateStatus={changedFields['is_on_crossroad']}
                            >
                                <Switch/>
                            </Form.Item>
                            <Form.Item name={'is_actual'}
                                       label={t('Актуален')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       valuePropName={'checked'}
                                       validateStatus={changedFields['is_actual']}
                            >
                                <Switch/>
                            </Form.Item>
                            <Form.Item
                                name={'wtf_actual_from'}
                                label={t('Дата начала актуальности')}
                                rules={[{required: true, message: t('Обязательное поле')}]}
                            >
                                <DatePicker/>
                            </Form.Item>

                        </Card>
                    </Col>
                </Row>
            </Form>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Card title={isMain ? t('Выбор вспомогательных адресов') : t('Выбор главного адреса')} size={'small'}>
                {
                    isMain ?
                      <SecondaryAddressTable
                        chosenTown={addressForm.getFieldValue('search_town_id')}
                        chosenDistr={addressForm.getFieldValue('search_town_distr_id')}
                      />
                      :
                      <SelectMainAddress
                        chosenTown={addressForm.getFieldValue('search_town_id')}
                        chosenDistr={addressForm.getFieldValue('search_town_distr_id')}
                      />
                }
            </Card>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        addressForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                addressForm.submit()
                                setAction('edit')
                            }}
                            type={'primary'}
                            disabled={!isMain && !chosenMainAddress}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                addressForm.submit()
                                setAction('list')
                            }}
                            type={'primary'}
                            disabled={!isMain && !chosenMainAddress}
                    >
                        {t('Сохранить и вернуться к списку')}
                    </Button>
                </Space>
            </Row>
    </>
}
