import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {
    $selectedBankCommission,
    BankCommissionViewGate,
    getBankCommissionByIdFx
} from "../../../models/dictionaryBankCommissionModel/index.js";

export default function ViewBankCommission() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getBankCommissionByIdFx.pending)
    const data = useUnit($selectedBankCommission)
    const genitive_form = t(`breadcrumbs.genitive.kbk`);
    console.log('data', data)
    const items = [
        {
            key: 'commission',
            label: t(`Комиссия банка`),
            children: `${data?.commission || 0} %`
        },
        {
            key: 'reimbursement',
            label: t(`Возмещение в ЕРЦ`),
            children: `${data?.reimbursement || 0} %`
        },

    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <BankCommissionViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр комиссии банка')}</span> <MappedDictItem id={id} type={'bank_commission'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'bank-commission'}/>
        </Card>
    </>
}
