import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {$dictsSelects, $penSrvsInUse} from '../../../models/infoModel/index.js';
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryServicesModel/index.js";
import {
    $editServicesStatus,
    $selectedServices, getServicesByIdFx,
    ServicesEditGate,
    submitEditServicesEv
} from '../../../models/dictionaryServicesModel/index.js';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import useFormChanged from '../../../hooks/useFormChanged.js';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';
import {filterOption, optionRender} from '../../../utils/selectUtils.jsx';
import CreatePayTypeEmbedded from './CreatePayTypeEmbedded.jsx';
import CreatePenSrvEmbedded from './CreatePenSrvEmbedded.jsx';

export default function EditServices() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedServices)
    const penSrvUsed = useUnit($penSrvsInUse)
    const {
        templateOpts,
        srvPayTypeOpts,
        srvServiceClassOpts,
        penSrvOpts
    } = useStoreMap($dictsSelects, sel => ({
        templateOpts: sel.template,
        srvPayTypeOpts: [...sel.servicePaymentType, {label: t('Создать'), emoji: true, value: -1}],
        srvServiceClassOpts: sel.serviceClasses,
        penSrvOpts: [...sel.services.filter(s => (s.is_penalty === true && !penSrvUsed.includes(s.value)) || (s.is_penalty === true && s?.id === selected?.penalty_service_id)), {label: t('Создать'), emoji: true, value: -1}]
    }))

    const [servicesForm] = Form.useForm()

    const nRu = Form.useWatch('name_ru', servicesForm)
    const nKz = Form.useWatch('name_kz', servicesForm)
    const snRu = Form.useWatch('short_name_ru', servicesForm)
    const snKz = Form.useWatch('short_name_kz', servicesForm)
    const isPenalty = Form.useWatch('is_penalty', servicesForm)
    const selectedPayType = Form.useWatch('service_payment_type_id', servicesForm)
    const selectedPenSrv = Form.useWatch('penalty_service_id', servicesForm)

    const [paymentEmbedded, setPaymentEmbedded] = useState(false)
    const [penSrvEmbedded, setPenSrvEmbedded] = useState(false)

    useEffect(() => {
        if (!paymentEmbedded && selectedPayType === -1) {
            setPaymentEmbedded(true)
        } else if (paymentEmbedded && selectedPayType !== -1) {
            setPaymentEmbedded(false)
            servicesForm.resetFields(['new_payment_type'])
        }
    }, [selectedPayType, paymentEmbedded]);

    useEffect(() => {
        if (!penSrvEmbedded && selectedPenSrv === -1) {
            setPenSrvEmbedded(true)
        } else if (penSrvEmbedded && selectedPenSrv !== -1) {
            setPenSrvEmbedded(false)
            servicesForm.resetFields(['new_pen_srv'])
        }
    }, [selectedPenSrv, penSrvEmbedded]);

    useEffect(() => {
        if (isPenalty && penSrvEmbedded) {
            setPenSrvEmbedded(false)
            servicesForm.resetFields(['new_pen_srv'])
            servicesForm.resetFields(['penalty_service_id'])
        }
    }, [isPenalty, penSrvEmbedded])

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const submit = useUnit(submitEditServicesEv)

    const loading = useUnit(getServicesByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editServicesStatus)
    const genitive_form = t(`breadcrumbs.genitive.services`);



    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/services?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            servicesForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, [
        'name_kz', 'name_ru', 'short_name_ru', 'short_name_kz', 'epd_order', 'service_unit_id',
        'template_id', 'is_penalty', 'penalty_service_id', 'service_payment_type_id',
    ])

    console.log('ServicesEdit', changedFields)
    return <>
        <ServicesEditGate id={id}/>
        <Form form={servicesForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[16, 16]}>
                <Col span={24}>
                    <Card title={t('Основные свойства услуги')} size={'small'} style={{height: '100%'}} loading={loading}>
                        <Row gutter={[8, 0]}>
                            <Col span={12}>
                                <Row gutter={[8, 0]}>
                                    <Col span={12}>
                                        <Form.Item name={'name_ru'}
                                                   label={t(`Название ${genitive_form} (рус)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                                   validateStatus={changedFields['name_ru']}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={'short_name_ru'}
                                                   label={t(`Крат. Название (рус)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                                   validateStatus={changedFields['short_name_ru']}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row gutter={[8, 0]}>
                                    <Col span={12}>
                                        <Form.Item name={'name_kz'}
                                                   label={t(`Название ${genitive_form} (каз)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                                   validateStatus={changedFields['name_kz']}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name={'short_name_kz'}
                                                   label={t(`Крат. Название (каз)`)}
                                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                                   validateStatus={changedFields['short_name_kz']}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title={<StarredTitle title={t('Дополнительные свойства')}/>}
                          size={'small'} style={{height: '100%'}}>
                        <Row gutter={[8, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    name={'service_payment_type_id'}
                                    label={t('Вид платежа')}
                                    rules={[{required: true, message: t('Обязательное поле')}]}
                                    validateStatus={changedFields['service_payment_type_id']}
                                >
                                    <Select
                                        options={srvPayTypeOpts}
                                        filterOption={filterOption}
                                        optionRender={optionRender}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={'service_class_id'}
                                    label={t('Класс услуг')}
                                    rules={[{required: true, message: t('Обязательное поле')}]}
                                    validateStatus={changedFields['service_class_id']}
                                >
                                    <Select
                                        options={srvServiceClassOpts}
                                        filterOption={filterOption}
                                        showSearch allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'template_id'}
                                           label={t('Шаблон')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={changedFields['template_id']}
                                >
                                    <Select options={templateOpts} filterOption={filterOption} showSearch allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'epd_order'} label={t('Порядок ЕПД')}
                                           validateStatus={changedFields['epd_order']}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'is_meter'} label={t('Услуга по счетчику')} valuePropName={'checked'}
                                           validateStatus={changedFields['is_meter']}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'is_penalty'} label={t('Является пеневой')} valuePropName={'checked'}
                                           validateStatus={changedFields['is_penalty']}
                                           tooltip={selected?.in_use ? t('Услуга уже используется') : null}
                                >
                                    <Switch disabled={!!selected?.in_use}/>
                                </Form.Item>
                            </Col>
                            {!isPenalty && <Col span={6}>
                                <Form.Item name={'penalty_service_id'} label={t('Пеневая услуга')}
                                           validateStatus={changedFields['penalty_service_id']}
                                           tooltip={selected?.in_use ? t('Услуга уже используется') : null}
                                >
                                    <Select disabled={!!selected?.in_use} options={penSrvOpts} filterOption={filterOption} optionRender={optionRender} showSearch allowClear/>
                                </Form.Item>
                            </Col>}
                        </Row>
                        {paymentEmbedded && <CreatePayTypeEmbedded
                            form={servicesForm}
                            open={paymentEmbedded}
                            namespace={['new_payment_type']}
                            prefixRu={nRu}
                            prefixKz={nKz}
                            shortPrefixRu={snRu}
                            shortPrefixKz={snKz}
                        />}
                        {penSrvEmbedded && <>
                            {paymentEmbedded && <Divider orientation={'left'} orientationMargin={0}/>}
                            <CreatePenSrvEmbedded
                                form={servicesForm}
                                open={penSrvEmbedded}
                                namespace={'new_pen_srv'}
                                prefixRu={nRu}
                                prefixKz={nKz}
                                shortPrefixRu={snRu}
                                shortPrefixKz={snKz}
                            /></>}
                    </Card>
                </Col>

            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    servicesForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            servicesForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            servicesForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
