import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import Col from 'antd/es/col';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';

import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import Table from "antd/es/table";
import React, {useContext, useEffect, useMemo, useState} from "react";
import Button from "antd/es/button/index.js";
import {HolderOutlined} from "@ant-design/icons";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {Select} from "antd";
import Switch from "antd/es/switch/index.js";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import Form from "antd/es/form/index.js";
import Input from "antd/es/input/index.js";
import {
    TemplatePaymentsViewGate,
    $selectedTemplatePayments,
    getTemplatePaymentsByIdFx
} from "../../../models/templatePaymentsModel/index.js";


const RowContext = React.createContext({});

const DragHandle = () => {
    const {setActivatorNodeRef, listeners} = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined/>}
            style={{
                cursor: 'move',
            }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

const Row = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                // zIndex: 9999,
            }
            : {}),
    };
    const contextValue = useMemo(
        () => ({
            setActivatorNodeRef,
            listeners,
        }),
        [setActivatorNodeRef, listeners],
    );
    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

export default function ViewTemplateAccruals() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getTemplatePaymentsByIdFx.pending)
    const selected = useUnit($selectedTemplatePayments)
    const genitive_form = t(`breadcrumbs.genitive.accruals`);

    const [templatePaymentsForm] = Form.useForm()
    const [form] = Form.useForm();


    const types = {
        int: 'Целое число',
        float: 'Дробное число',
        date: 'Дата',
        str: 'Строка',
        bool: 'Логический тип',
        iin: 'ИИН',
    }
    const typesOpts = Object.entries(types).map(([k, v]) => ({value: k, label: v}))
    const cells = [
        '',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ]

    const [data, setData] = useState([])

    const columns = [
        // {
        //     key: 'sort',
        //     align: 'center',
        //     width: 80,
        //     render: () => <DragHandle/>,
        // },
        {
            title: 'Столбец',
            dataIndex: 'cell',
            key: 'cell',
            render: (v) => cells[v]
        },
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            render: (v, record) => <Select
                defaultValue={v}
                options={possibleFieldsOpts}
                disabled={true}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, field: value} : d)
                )}
            />
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            render: (v, record) => <Select
                defaultValue={v}
                options={typesOpts}
                disabled={true}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, field: value} : d)
                )}
            />
        },
        {
            title: 'Обязательно',
            dataIndex: 'required',
            key: 'required',
            render: (v, record) => <Switch
                checkedChildren="Обязательное"
                unCheckedChildren="Необязательное"
                defaultValue={v}
                disabled={true}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, required: value} : d)
                )}
            />
        },
        // {
        //     title: '',
        //     dataIndex: '',
        //     key: 'actions',
        //     render: (v, record) => <Button onClick={() => {
        //         setData(data.filter(d => d.cell !== record.cell))
        //     }}>Удалить</Button>
        // },
    ]

    const possibleFields = {
        account_id: "Лицевой счет",
        service_id: "Код услуги",
        service_name: "Наименование услуги",
        people_quantity: "Количество проживающих",
        full_square: "Площадь",
        tarif_value: "Значение тарифа",
        tarif_value_min: "Значение минимального тарифа",
        tarif_value_avg: "Значение среднего тарифа",
        tarif_value_max: "Значение максимального тарифа",
        measure_name: "Единица измерения",
        sign_saldo_calc: "Флаг ведения сальдо",
        sum_saldo_begin: "Долг/переплата без учета начислений и перерасчетов текущего месяца",
        sum_calc: "Сумма начисления в тенге",
        sum_calc_min: "Сумма начисления в тенге (минимальное)",
        sum_calc_avg: "Сумма начисления в тенге (среднее)",
        sum_calc_max: "Сумма начисления в тенге (максимальное)",
        sum_recalc: "Сумма перерасчета в тенге",
        sum_pay: "Сумма платежей прошлого месяца",
        sum_fine: "Сумма неоплаченной пени",
        sum_saldo_end: "Долг/переплата с учетом начислений и перерасчетов текущего месяца",
        consumption: "Потребление в натуральных показателях",
        consumption_min: "Минимальное потребление в натуральных показателях",
        consumption_avg: "Среднее потребление в натуральных показателях",
        consumption_max: "Максимальное потребление в натуральных показателях",
        check_count: "Последние проверенные показания",
        norm1: "Нормативное потребление по минимальному тарифу",
        norm2: "Нормативное потребление по среднему тарифу",
        service_square: "Обслуживаемая площадь",
        pay_count: "Оплаченные показания",
        check_date: "Дата последней проверки",
        is_main_service: "признак основной услуги",
        bill_id: "№ счетфактуры",
        external_number: "№ договора/абонент ГЦТ",
        iin: "ИИН",
    }
    const possibleFieldsOpts = Object.entries(possibleFields).map(
        ([k, v]) => ({value: k, label: v})
    )

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );
    const onDragEnd = ({active, over}) => {
        console.log('active', active, 'over', over)
        if (active.id !== over?.id) {
            console.log('setDat')
            setData((prev) => {
                const activeIndex = prev.findIndex((i) => i.idx === active.id);
                const overIndex = prev.findIndex((i) => i.idx === over?.id);
                const newData = arrayMove(prev, activeIndex, overIndex).map(
                    (item, index) => ({...item, cell: index + 1})
                )
                console.log('activeIndex', activeIndex, 'overIndex', overIndex, 'newData', newData)

                return newData
            });
        }
    };

    useEffect(() => {
        if (selected) {
            templatePaymentsForm.setFieldValue('name', selected.name_ru)
            // debugger
            // getInspectorsByTown(selected.town_id)
            setData(selected.data)
        }
    }, [selected]);

    const items = [
        {
            key: 'town_id',
            label: t('Населенный пункт'),
            children: <MappedDictItem id={data?.town_id} type={'town'}/>,
        },
        {
            key: 'inspector_id',
            label: t('Контролеры'),
            children: <MappedDictItem id={data?.inspector_id} type={'inspector'}/>,
        },

        {
            key: 'adv_sector',
            label: t('Рекламный сектор'),
            children: data?.adv_sector
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <TemplatePaymentsViewGate id={id}/>
        <Form form={templatePaymentsForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => onSubmit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Card
                title={<div><span>{t('Просмотр шаблона импорта начислений')}</span> <MappedDictItem id={id} type={'sector'} link={false}/></div>}
                loading={loading}
            >

                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={onDragEnd}
                >
                    <SortableContext
                        // rowKey array
                        items={data.map((i) => i.idx)}
                        strategy={verticalListSortingStrategy}

                    >
                        <Card style={{width: '100%'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between' }}>
                                <Col span={4}>
                                    <Form.Item name={'name'} label={'Название шаблона ф/н'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={'created_by'} label={'Кем создан'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={'created_at'} label={'Дата создания'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={'updated_by'} label={'Кем изменен'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item name={'updated_at'} label={'Дата изменения'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </div>
                        </Card>
                        <Card>
                            <Table
                                components={{
                                    body: {
                                        row: Row,
                                    },
                                }}
                                dataSource={data}
                                columns={columns}
                                rowKey={'idx'}
                                pagination={false}

                            />
                        </Card>


                    </SortableContext>
                </DndContext>
            </Card>
        </Form>


            {/*<Col span={6}>*/}
            {/*    <ViewDictionaryItemCommon  itemsDates={itemsDates} id={id} href={'sector'} style={{width: '50%'}}/>*/}
            {/*</Col>*/}
            {/*<Col>*/}
            {/*    <p>bvla</p>*/}
            {/*</Col>*/}
        {/*</Card>*/}
    </>
}
