import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useStoreMap} from 'effector-react';
import {$info} from '../../models/infoModel';
import dayjs from 'dayjs';
import queryString from 'query-string';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Select from 'antd/es/select';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import {filterOption} from '../../utils/selectUtils.jsx';
import AccountSearchForm from '../../components/AddressReoSearchForms/AccountSearchForm.jsx';
import {isId} from "../../utils/helpers.js";

export default function AccrualsListFilters() {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang') ?? 'ru'

    const [search, setSearch] = useSearchParams()
    const [form] = Form.useForm()

    const serviceId = Form.useWatch('service_id', form)
    const providerId = Form.useWatch('provider_id', form)

    const {provider: providersMap, service: servicesMap} = useStoreMap(
        $info, ({provider, service}) => ({provider, service})
    )

    const providers = Object.values(providersMap || {}).filter(
        o => isId(serviceId) ? (o.services || []).includes(serviceId) : true
    ).map(
        i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id})
    )

    const services = Object.values(servicesMap || {}).filter(
        o => isId(providerId) ? (o.providers || []).includes(providerId) : true
    ).map(
        i => ({label: `${i.id} ${i?.[`name_${lang}`]}`, value: i.id})
    )

    useEffect(() => {
        if (search?.size > 0) {
            const parsed = queryString.parse(search.toString(), {
                parseBooleans: true,
                parseNumbers: true
            })
            for (const [key, value] of Object.entries(parsed)) {
                if (key.split('_').at(-1) === 'from' && typeof value === 'string' && dayjs(value).isValid()) {
                    const actualKey = key.split('_').slice(0, -1).join('_')
                    parsed[actualKey] = [dayjs(value), dayjs(parsed[`${actualKey}_to`])]
                    delete parsed[key]
                }
                if (key === 'inspector') {
                    parsed[key] = Array.isArray(value) ? value.map(item => item === null ? -1 : item) : [value === null ? -1 : value]
                }
            }

            form.setFieldsValue(parsed)
        }
    }, [search])

    const onSubmit = (values) => {
        setSearch(prepareValues(values))
    }

    const onReset = () => {
        form.resetFields()
        setSearch()
    }

    return <Card size={'small'}>
        <Form layout={'vertical'} form={form} onFinish={onSubmit} size={'small'}>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <AccountSearchForm
                        extForm={form} extButtons onlyItems itemsAsRow
                    />
                </Col>
                <Col span={6}>
                    <Form.Item name={'provider_id'} label={t('Поставщик')}>
                        <Select
                            options={providers}
                            dropdownStyle={{width: 300}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={'service_id'} label={t('Услуга')}>
                        <Select
                            options={services}
                            dropdownStyle={{width: 300}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'epd_report_date'} label={t('Расчетный месяц')}
                    >
                        <DatePicker.RangePicker
                            picker={'month'}
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={'is_for_bank'}
                        label={t('Для банка')}
                        valuePropName={'checked'}
                        initialValue={false}
                    >
                        <Switch/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col span={5}>
                    <ButtonGroup>
                        <Button type={'primary'} size={'small'}
                                onClick={() => form.submit()}>{t('Применить')}</Button>
                        <Button size={'small'} onClick={onReset}>{t('Сбросить')}</Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>
    </Card>
}

function prepareValues(values) {
    for (const [key, value] of Object.entries(values)) {
        if (value === undefined || value === ' ' || value === '') {
            delete values[key]
            continue
        }

        if (!value && value !== false) {
            if (
                key !== 'town_id' && key !== 'sector_id'
                && key !== 'street_id' && key !== 'street_type_id' && key !== 'town_distr_id'
                && key !== 'algorithm_id'
            ) {
                delete values[key]
                continue
            }
        }

        if (key === 'epd_report_date' && Array.isArray(value) && value.every(v => dayjs(v).isValid())) {
            values[`${key}_from`] = dayjs(value[0]).format('YYYY-MM-DD')
            values[`${key}_to`] = dayjs(value[1]).format('YYYY-MM-DD')
            delete values[key]
        }
        if (key.split('_').includes('date') && !Array.isArray(value)) {
            values[key] = dayjs(value).format('YYYY-MM-DD')
        }
        if (key === 'name_ru') {
            values[key] = value
            values['name_kz'] = value
        }
    }
    return values
}
