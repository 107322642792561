import {combine, createStore} from 'effector';

export const $filters = createStore({has_email: true, is_email_delivery: true, recreate_pdf: false})

export const $accountsList = createStore({data: [], count: 0})

export const $selectedAccounts = createStore([])
export const $createdTask = createStore([])

export const $taskCreationStatus = createStore(false)

export const $accountsAll = createStore([])
// FIXME HON
export const $accountsMap = combine($accountsAll, cntrs => Object.fromEntries(cntrs.map(i => [i.id, `${i.firstname} ${i.patronymic} ${i.surname}`])))
