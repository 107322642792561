import StickyBox from 'react-sticky-box'
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useNavigate} from 'react-router-dom';
import {
    $afterCreateAction,
    setAfterCreateActionEv,
    resetAfterCreationAttrs,
    $createdProviderId,
    ProviderCreateGate,
    uploadAgreementFileFx,
    $provAgreementsFiles,
    $providerDefaultBankAccounts,
    setBaEmbeddedFormEv, $embeddedBaForm
} from '../../../models/dictionaryProvidersModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {$createdCorrectionId, createCorrectionEv} from "../../../models/dictionaryCorrectionModel/index.js";
import {$info} from '../../../models/infoModel/index.js';
import Tabs from 'antd/es/tabs';
import ProviderMainForm from './tabComponents/ProviderMainForm.jsx';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';


export default function CreateProvider() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const loading = useUnit(universalDictEndpointFx.pending)
    const fileUploadLoading = useUnit(uploadAgreementFileFx.pending)

    const currentDate = useStoreMap($info, i => i.current_date)

    const [providerForm] = Form.useForm()

    const createdId = useUnit($createdProviderId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)

    const createCorrection = useUnit(createCorrectionEv)
    const createdCorrectionId = useUnit($createdCorrectionId)

    const filesToUpload = useUnit($provAgreementsFiles)

    const defaultBa = useUnit($providerDefaultBankAccounts)
    const embeddedOpen = useUnit($embeddedBaForm)
    const setEmbeddedBA = useUnit(setBaEmbeddedFormEv)

    const [activeTab, setActiveTab] = useState('main')

    useEffect(() => {
        if (createdId !== null && filesToUpload.length === 0) {
            if (action === 'edit') {
                navigate(`/dictionaries/providers/edit/${createdId}`)
            } else if (action === 'new') {
                providerForm.resetFields()
            } else if (action === 'list') {
                providerForm.resetFields()
                navigate(`/dictionaries/providers`)
            } else if (action === 'correction') {
                createCorrection({
                    mass: true,
                    account_id: createdId
                })
            }
            resetAttrs()
        }
    }, [createdId, action, filesToUpload]);

    useEffect(() => {
        if (createdCorrectionId) {
            navigate(`/corrections/edit/${createdCorrectionId}`)
        }
    }, [createdCorrectionId]);

    useEffect(() => {
        if (currentDate) {
            providerForm.setFieldValue('date_from', dayjs(currentDate))
        }
    }, [currentDate])

    const tabs = [
        {
            key: 'main',
            label: t('Основная информация'),
            children: <ProviderMainForm form={providerForm}/>
        },
        // {
        //     key: 'provServ',
        //     label: <Tooltip title={t('Доступно только в редактировании поставщика')}>
        //         {t('Услуги и Тарифы')}
        //     </Tooltip>,
        //     disabled: true,
        // },
        // {
        //     key: 'provServTariff',
        //     disabled: isBeneficiary,
        //     label: t('Тарифы'),
        //     children: <ProvServTariffForm />
        // },
        // {
        //     key: 'provServAcc',
        //     disabled: true,
        //     label: <Tooltip title={t('Доступно только в редактировании поставщика')}>
        //         {t('ЛС')}
        //     </Tooltip>,
        // },
        // {
        //     key: 'provServAccTariff',
        //     disabled: true,
        //     label: <Tooltip title={t('Доступно только в редактировании поставщика')}>
        //         {t('Тарифы ЛС')}
        //     </Tooltip>,
        // },
        // {
        //     key: 'provServReo',
        //     disabled: true,
        //     label: <Tooltip title={t('Доступно только в редактировании поставщика')}><HomeOutlined/></Tooltip>,
        // }
    ]

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={24} style={{zIndex: 11}}>
            <DefaultTabBar
                {...props}
                style={{
                    background: '#f5f5f5',
                    borderBottom: '1px solid #ffffff',
                    padding: '0 32px',
                    margin: '0 -32px 8px',
                }}
            />
        </StickyBox>
    )

    return <>
        <ProviderCreateGate/>
            <Tabs defaultActiveKey="main" activeKey={activeTab} items={tabs} type={'card'}
                  onChange={(tabKey) => {
                      if (embeddedOpen) {
                          setEmbeddedBA(false)
                      }
                      setActiveTab(tabKey)
                  }}
                  renderTabBar={renderTabBar}
            />
        <Divider/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    providerForm.resetFields()
                    resetAttrs()
                    navigate('/dictionaries/providers')
                }}
                >
                    {t('Отмена')}
                </Button>
                <Tooltip title={defaultBa?.length < 1 ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={loading || fileUploadLoading}
                            onClick={() => {
                                setAction('new')
                                providerForm.submit()
                            }}
                            disabled={defaultBa?.length < 1}
                            type={'primary'}
                            ghost
                    >
                        {t('Сохранить и создать следующего')}
                    </Button>
                </Tooltip>
                <Tooltip title={defaultBa?.length < 1 ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={loading || fileUploadLoading}
                            onClick={() => {
                                setAction('edit')
                                providerForm.submit()
                            }}
                            disabled={defaultBa?.length < 1}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                </Tooltip>
                <Tooltip title={defaultBa?.length < 1 ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('list')
                                providerForm.submit()
                            }}
                            disabled={defaultBa?.length < 1}
                    >
                        {t('Сохранить и перейти к списку')}
                    </Button>
                </Tooltip>

                <Tooltip title={defaultBa?.length < 1 ? t('Отсутствует расчетный счет по умолчанию') : null}>
                    <Button loading={loading}
                            type={'primary'}
                            onClick={() => {
                                setAction('correction')
                                providerForm.submit()
                            }}
                            disabled={defaultBa?.length < 1}
                    >
                        {t('Сохранить и создать корректировку')}
                    </Button>
                </Tooltip>
            </Space>
        </Row>
    </>
}
