import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import {
	$calcTypeAlgorithmsData,
	CalcTypeAlgorithmsGate, deleteAlgorithmEv,
	getCalcTypeAlgorithmsFx
} from "../../models/calcTypeAlgorithmsModel/index.js";
import {useUnit} from "effector-react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import CreatedBy from "../../components/CreatedBy/CreatedBy.jsx";
import ButtonGroup from "antd/es/button/button-group.js";
import Tooltip from "antd/es/tooltip/index.js";
import Button from "antd/es/button/index.js";
import {DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {Popconfirm} from "antd";
import {searchAndActions} from "../../utils/searchAndActions.js";

export default function CalcTypeAlgorithms(){
	const {t} = useTranslation()
	const {data, count} = useUnit($calcTypeAlgorithmsData)
	const loading = useUnit(getCalcTypeAlgorithmsFx.pending)
	const genitive_form = t(`breadcrumbs.genitive.algorithm`)
	const {search, exportXlsAction} = searchAndActions()
	const onDelete = useUnit(deleteAlgorithmEv)

	const columns = [
		{
			width: '5%',
			title: t(`Код ${genitive_form}`),
			dataIndex: 'id',
			sorter: true,
			key: 'id',
		},
		{
			title: t(`Название ${genitive_form} (каз)`),
			dataIndex: 'name_kz',
			sorter: true,
			key: 'name_kz',
			render: (name, record) => <Link to={`/dictionaries/algorithm/view/${record.id}`}>{`${name}`}</Link>
		},
		{
			title: t(`Название ${genitive_form} (рус)`),
			dataIndex: 'name_ru',
			sorter: true,
			key: 'name_ru',
			render: (name, record) => <Link to={`/dictionaries/algorithm/view/${record.id}`}>{`${name}`}</Link>
		},
		{
			title: t(`Формула ${genitive_form}`),
			dataIndex: 'formula',
			sorter: true,
			key: 'formula',
		},
		{
			title: t('Создан'),
			key: 'created',
			children: [
				{
					title: t('Дата'),
					dataIndex: 'created_at',
					width: '10%',
					key: 'created_at',
					sorter: true,
					render: (date) => <CustomDates data={date} />
				},
				{
					title: t('Кем'),
					dataIndex: 'created_by',
					width: '10%',
					key: 'created_by',
					sorter: true,
					render: (id) => <CreatedBy id={id}/>
				}
			]
		},
		{
			title: t('Обновлен'),
			key: 'updated',
			children: [
				{
					title: t('Дата'),
					dataIndex: 'updated_at',
					key: 'updated_at',
					width: '10%',
					sorter: true,
					render: (date) => date && <CustomDates data={date} />
				},
				{
					title: t('Кем'),
					dataIndex: 'updated_by',
					width: '10%',
					sorter: true,
					key: 'updated_by',
					render: (id) => id && <CreatedBy id={id}/>
				}
			]
		},
		{
			title: t('Действия'),
			width: '5%',
			align: 'center',
			dataIndex: 'id',
			key: 'actions',
			render: (id) => <ButtonGroup>
				<Tooltip title={t('Редактировать')} placement={'top'}>
					<Link to={`/dictionaries/algorithm/edit/${id}`}><Button icon={<EditOutlined />} type={'primary'} /></Link>
				</Tooltip>
				<Tooltip title={t('Создать новый на основе этого алгоритма')} placement={'top'}>
					<Link to={`/dictionaries/algorithm/create/${id}`}><Button icon={<PlusCircleOutlined />}/></Link>
				</Tooltip>
				<Tooltip title={t('Просмотр')} placement={'top'}>
					<Link to={`/dictionaries/algorithm/view/${id}`}><Button icon={<EyeOutlined />} /></Link>
				</Tooltip>
				<Tooltip title={t('История изменений')} placement={'top'}>
					<Button icon={<HistoryOutlined />}/>
				</Tooltip>
				<Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
										okText={t('Да')}
										onConfirm={() => onDelete(id)}
										placement={'left'}
				>
					<Tooltip title={t('Удалить')} placement={'top'}>
						<Button icon={<DeleteOutlined />} danger/>
					</Tooltip>
				</Popconfirm>
			</ButtonGroup>
		}
	]

	return <>
		<CalcTypeAlgorithmsGate search={search.toString()}/>
		<DictionaryProTable
			data={data}
			count={count}
			loading={loading}
			initColumns={columns}
			exportXlsAction={exportXlsAction}
		/>
	</>
}
