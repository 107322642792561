import {
    $afterCreateAction,
    $createdPasswordSettingsId,
    $editPasswordSettingsStatus,
    $selectedPasswordSettings,
    $passwordSettingsList, $passwordSettingsModal, $passwordSettingsModalData
} from './stores.js';
import {getPasswordSettingsListFx, getPasswordSettingsByIdFx} from './effects.js';
import {
    deletePasswordSettingsEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    PasswordSettingsEditGate,
    PasswordSettingsListGate,
    PasswordSettingsViewGate,
    submitCreatePasswordSettingsEv,
    submitEditPasswordSettingsEv, PasswordSettingsCreateGate, dataModalEv,openModalEv, closeModalEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {resetCreateInspectorEmbeddedEv, resetCreateTownEmbeddedEv} from "../dictionaryEmbeddedFormsModel/index.js";
import params from "react-input-mask/lib/react-input-mask.development.js";

const createCompleteEv = combineEvents({events: {ev: submitCreatePasswordSettingsEv, response: triggerSuccessOpsEv}, reset: PasswordSettingsCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditPasswordSettingsEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deletePasswordSettingsEv, response: triggerSuccessOpsEv})

$passwordSettingsList.on(getPasswordSettingsListFx.doneData, (_, data) => data)
    .reset(PasswordSettingsListGate.close)

$passwordSettingsModal.on(openModalEv, () => true)
    .on(closeModalEv, () => false)
    .reset(closeModalEv)

$passwordSettingsModalData.on(dataModalEv, (_, data) => data)
    .reset(closeModalEv)

$selectedPasswordSettings.on(getPasswordSettingsByIdFx.doneData, (_, data) => data)
    .reset([PasswordSettingsEditGate.close, PasswordSettingsViewGate.close])
// FIXME
$createdPasswordSettingsId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "password_settings")
    const result = data ? data?.value?.id : undefined
    return result
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editPasswordSettingsStatus.on(editCompleteEv, () => true)
    .reset(PasswordSettingsEditGate.close)

sample({
    clock: [PasswordSettingsEditGate.state, PasswordSettingsViewGate.state],
    filter: (gate) => {
        if (gate.id !== undefined) return true
    },
    fn: (gate) => +gate.id,
    target: getPasswordSettingsByIdFx
})

sample({
    clock: PasswordSettingsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPasswordSettingsListFx
})

sample({
    source: PasswordSettingsListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPasswordSettingsListFx
})

sample({
    clock: submitCreatePasswordSettingsEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const data = {
            setting: payload.setting,
            name_ru: payload.name_ru,
            params: payload.value ? {value: parseInt(payload.value)} : {},
            active: payload.active ? payload.active : true
        }
        actions.push({operation: 'create', type: 'password_settings', value: {...data, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedPasswordSettings,
    clock: submitEditPasswordSettingsEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        const data = {
            ...changedFields,
        }
        if (changedFields.value) {
            data.params = {value: parseInt(changedFields.value)}
            delete data.value
        }



        actions.push({operation: 'update', type: 'password_settings', value: {object_id: current.id, ...data}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deletePasswordSettingsEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'password_settings', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [PasswordSettingsCreateGate.close, PasswordSettingsEditGate.close],
    target: [resetCreateTownEmbeddedEv, resetCreateInspectorEmbeddedEv]
})
sample({
    clock: editCompleteEv,
    target: closeModalEv
})
