import {createEffect} from 'effector/compat';
import api from '../../api/api.js';
import dayjs from 'dayjs';

export const getSelectsForREOFx = createEffect()
    .use(async (filters) => (await api().get(`/real_estate_objects/select_address_list?${filters}`)).data)

export const getTownsSelectFx = createEffect()
    .use(async () => (await api().get(`/town/?ignore_limit=true`)).data)

export const getDistrictsSelectFx = createEffect()
    .use(async (townId) => (await api().get(`/town_distr/?town_id=${townId}&ignore_limit=true`)).data)

export const getREOListFx = createEffect()
    .use(async (filters) => (await api().get(`/real_estate_objects/?${filters}`)).data)

export const createTaskFx = createEffect()
.use(async (data) => {
    return (await api().post(`/real_estate_object_epd/generate`, data)).data
})

export const downloadFilesPerREOFx = createEffect()
    .use(async ({ids, filters, report_date}) => {
        const file = (await api().post(`/real_estate_object_epd/download_files_zip`, {reo_ids: ids, filters, report_date}, {responseType: 'blob'}))
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = `Файлы по объектам недвижимости (${ids.join(', ')}) от ${dayjs(report_date).format('DD.MM.YYYY')}.zip`
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const downloadSingleREOFileFx = createEffect()
.use(async id => {
    const file = (await api().get(`/dispatch/download_inspector_file/?file_id=${id}`, {responseType: 'blob'}))
    const link = document.createElement('a')
    const url = URL.createObjectURL(file.data)
    link.href = url
    link.target = '_blank'
    link.download = `Файл по объекту недвижимости ${id}.pdf`
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
})
