import {useTranslation} from 'react-i18next';
import {useState} from "react";
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Radio from 'antd/es/radio';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import Form from 'antd/es/form';
import {Popconfirm, Switch} from 'antd';
import {
    $controllersList,
    $selectedControllers,
    changeSelectedControllersEv,
    createTaskEv, getControllersFx,
    resetFiltersEv, changeFiltersEv, createTaskAllEv, createTaskFx
} from '../../../models/taskAllPdfModel/index.js';
import {useStoreMap, useUnit} from 'effector-react';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion.jsx";
import FilterBlock from "../../../components/FiltersBlock/FiltersBlock.jsx";
import {setRadioEv, TaskAllPdfGate} from "../../../models/taskAllPdfModel/index.js";
import {$dictsSelects, $userPerms} from '../../../models/infoModel';
import AddressREOSearchForm
    from "../../../components/AddressReoSearchForms/AddressREOSearchForm.jsx";
import AccountSearchForm from "../../../components/AddressReoSearchForms/AccountSearchForm.jsx";

export default function CreateTaskAllPdf() {
    const {t} = useTranslation()
    const lang = localStorage.getItem('lang') ?? 'ru'
    const perms = useUnit($userPerms)

    const [radioValue, setRadioValue] = useState('all');
    const [pastPeriodStatus, setPastPeriodStatus] = useState(false);
    const [disabledFlagStatus, setDisabledFlagStatus] = useState({
        send_to_bank: false,
        include_payments: false,
        past_period: false,
        include_personal_data: false,
        include_taxes: false,
        month: null
    });

    const closedMonthsOpts = useStoreMap($dictsSelects, sel => sel.closed_months)
    const setRadio = useUnit(setRadioEv)
    const [flagsForm] = Form.useForm()

    const {data, count} = useUnit($controllersList)
    const loading = useUnit(getControllersFx.pending)
    const onChangeSelect = useUnit(changeSelectedControllersEv)
    const selected = useUnit($selectedControllers)
    const createTask = useUnit(createTaskEv)
    const createTaskAll = useUnit(createTaskAllEv)
    const actionLoading = useUnit(createTaskFx.pending)

    const changeFilters = useUnit(changeFiltersEv)

    const [search] = useSearchParams()
    const resetFilters = useUnit(resetFiltersEv)

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value)
        setRadio(e.target.value)
    };

    const columns = [
        {
            title: t('Код'),
            dataIndex: 'id',
            width: '5%',
            sorter: true,
            key: 'id',
        },
        {
            title: t('ФИО контролера'),
            dataIndex: 'name_ru',
            sorter: true,
            width: '15%',
            key: 'fio',
            render: (_, i) => lang === 'kz' && i.name_kz ? i.name_kz : i.name_ru
        },
        {
            title: t('Участок'),
            dataIndex: 'sector_ids',
            sorter: true,
            key: 'sector_ids',
            width: '15%',
            render: (sector_ids, row) => <CustomAccordion ids={sector_ids} count={row?.sector_count} type={'sector'}/>
        },
        {
            title: t('Город'),
            dataIndex: 'town_id',
            sorter: true,
            width: '15%',
            key: 'town',
            render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>,
        }
    ]
    const columnsSector = [
        {
            width: '5%',
            title: t(`Код участка`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (town_id, _) => <MappedDictItem id={town_id} type={'town'} />
        },
        {
            title: t('Контролер'),
            dataIndex: 'inspector_id',
            sorter: true,
            key: 'inspector_id',
            render: (_, record) => record.inspector_id === null ? 'Без контролера' : <MappedDictItem id={record.inspector_id} type={'inspector'} />
        },
        {
            title: t('Рекламный сектор'),
            dataIndex: 'adv_sector',
            sorter: true,
            key: 'adv_sector',
            render: (adv_sector, _) => adv_sector
        },
    ]
    const columnsInspector = [
        {
            width: '5%',
            title: t(`Код контролера`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t(`Старший контролер`),
            dataIndex: 'chief_inspector_id',
            sorter: true,
            key: 'chief_inspector_id',
            render: (chief_inspector_id, _) => <MappedDictItem id={chief_inspector_id} type={'chief_inspector'}/>
        },
        {
            title: t(`ФИО контролера`),
            dataIndex: 'name_kz',
            sorter: true,
            key: 'name_kz',
            render: (_, record) => <MappedDictItem id={record.id} type={'inspector'} withID={false}/>
        },
        {
            title: t(`Участок`),
            dataIndex: 'sector_ids',
            sorter: true,
            key: 'sector_ids',
            render: (sector_ids, record) => record.sector_count > 0 ? <Link to={`/dictionaries/sector/view/${sector_ids[0]}`}>{sector_ids[0]}</Link> : '-'
        },

    ]
    const columnsReo = [
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (town_id) => <MappedDictItem id={town_id} type={'town'}/>
        },
        {
            title: t('Район города'),
            dataIndex: 'town_distr_id',
            sorter: true,
            key: 'town_distr_id',
            render: (town_distr_id, _) => <MappedDictItem id={town_distr_id} type={'town_distr'}/>
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            sorter: true,
            key: 'street_id',
            render: (street_id, _) => <MappedDictItem id={street_id} type={'street'}/>
        },

        {
            title: t('Дом'),
            dataIndex: 'house',
            sorter: true,
            key: 'house',
            render: (house, _) => house
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            sorter: true,
            key: 'corpus',
            render: (corpus, _) => corpus
        },

        {
            title: t('Название'),
            dataIndex: 'name_kz',
            sorter: true,
            key: 'name_kz',
            render: (_, r) => r[`name_${lang}`] || r['name_kz'] || r['name_ru'],
        },
        {
            title: t('Тип ОН'),
            dataIndex: 'real_estate_object_type_id',
            sorter: true,
            key: 'real_estate_object_type_id',
            render: (real_estate_object_type_id, _) => <MappedDictItem id={real_estate_object_type_id} type={'real-estate-object-type'}/>
        },
        {
            title: t('Участок'),
            dataIndex: 'sector_id',
            sorter: true,
            key: 'sector_id',
            render: (sector_id, _) => <MappedDictItem id={sector_id} type={'sector'}/>
        },
        {
            title: t('Контролер'),
            dataIndex: 'inspector_id',
            sorter: true,
            key: 'inspector_id',
            render: (inspector_id, _) => <MappedDictItem id={inspector_id} type={'inspector'}/>
        },
    ]
    const columnsAccount = [
        {
            title: t(`Номер ЛС`),
            dataIndex: 'id',
            sorter: true,
            width: '3%',
            key: 'id'
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            key: 'town_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town'}/>,
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            key: 'town_distr_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town_distr'}/>,
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            key: 'street_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'street'}/>,
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            key: 'house'
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            key: 'corpus'
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
            key: 'flat'
        },
        {
            title: t('ФИО потребителя'),
            dataIndex: 'name_kz',
            key: 'fio',
            sorter: true,
            render: (_, r) => r[`name_${lang}`] || r['name_kz'] || r['name_ru'],
        },
        {
            title: t('ИИН'),
            dataIndex: 'iin',
            key: 'iin'
        },
        {
            title: t('В E-mail рассылке'),
            dataIndex: 'is_email_delivery',
            key: 'is_email_delivery'
        },
    ]


    const filtersMass = [
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns'},
    ]
    const filtersSector = [
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns', },
        {name: 'sector_id', label: t('Участок'), type: 'numrange', dict: 'sector'},
        {name: 'inspector_id', label: t('Без контролера'), type: 'switch'},
    ]
    const filtersInspector = [
        {name: 'code_name', label: t('Код/ФИО контролера'), type: 'input'},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns', },
        // {name: 'inspector_id', label: t('Контролеры'), type: 'select', dict: 'inspector', multiple: true},
        {name: 'chief_inspector_id', label: t('Старший контролер'), type: 'select', dict: 'chief_inspector', multiple: true},
    ]

    const filtersREO = [
        {name: 'custom', type: 'custom', component: AddressREOSearchForm, span: 24,},
        {name: 'code_name', label: t('ЖК'), type: 'input'},
    ]
    const filtersAccounts = [
        {name: 'custom', type: 'custom', component: AccountSearchForm, span: 24,},
        {name: 'is_email_delivery', label: t('В E-mail рассылке'), type: 'switch'},
    ]

    const onCreateTask = () => {
        const flagData = flagsForm.getFieldsValue()
        console.log('onCreateTask', flagData)
        if (!flagData.prev_month || flagData.prev_month === ' ') {
            flagData.prev_month = closedMonthsOpts[closedMonthsOpts.length-1]?.value
        }
        createTask(flagData)
        onChangeSelect([])
        resetFilters()
    }

    const onCreateTaskAll = () => {
        const flagData = flagsForm.getFieldsValue()
        changeFilters({key: 'show_list_option', value: 'only_active'})
        createTaskAll(flagData)
        onChangeSelect([])
        resetFilters()
    }

    const onChangeFlag = (value, type) => {
        const formData = flagsForm.getFieldsValue()

        // FIXME OPTIMIZE 2 if   'send_to_bank' || 'include_payments' .... 'flag3' || 'include_personal_data || 'include_taxes'
        if (type === 'send_to_bank') {
            if (value === true) {
                setDisabledFlagStatus({
                    send_to_bank: false,
                    include_payments: false,
                    past_period: true,
                    include_personal_data: true,
                    include_taxes: true
                })
                flagsForm.setFieldValue('past_period', undefined)
                flagsForm.setFieldValue('include_personal_data', undefined)
                flagsForm.setFieldValue('include_taxes', undefined)

            } else {
                setDisabledFlagStatus({
                    send_to_bank: false,
                    include_payments: false,
                    past_period: formData.include_payments === true,
                    include_personal_data: formData.include_payments === true,
                    include_taxes: formData.include_payments === true
                })
                flagsForm.setFieldValue('past_period', undefined)
                flagsForm.setFieldValue('include_personal_data', undefined)
                flagsForm.setFieldValue('include_taxes', undefined)
            }

        } else if (type === 'include_payments') {
            if (value === true) {
                setDisabledFlagStatus({
                    send_to_bank: false,
                    include_payments: false,
                    past_period: true,
                    include_personal_data: true,
                    include_taxes: true
                })
                flagsForm.setFieldValue('past_period', undefined)
                flagsForm.setFieldValue('include_personal_data', undefined)
                flagsForm.setFieldValue('include_taxes', undefined)
            } else {
                setDisabledFlagStatus({
                    send_to_bank: false,
                    include_payments: false,
                    past_period: formData.send_to_bank === true,
                    include_personal_data: formData.send_to_bank === true,
                    include_taxes: formData.send_to_bank === true
                })
                flagsForm.setFieldValue('past_period', undefined)
                flagsForm.setFieldValue('include_personal_data', undefined)
                flagsForm.setFieldValue('include_taxes', undefined)
            }
        } else if (type === 'past_period') {
            setPastPeriodStatus(value)
            if (value === true) {
                setDisabledFlagStatus({
                    send_to_bank: true,
                    include_payments: true,
                    past_period: false,
                    include_personal_data: false,
                    include_taxes: false
                })
                flagsForm.setFieldValue('send_to_bank', undefined)
                flagsForm.setFieldValue('include_payments', undefined)
            } else {
                setDisabledFlagStatus({
                    send_to_bank: formData.include_personal_data === true || formData.include_taxes === true,
                    include_payments: formData.include_personal_data === true || formData.include_taxes === true,
                    past_period: false,
                    include_personal_data: false,
                    include_taxes: false
                })
                flagsForm.setFieldValue('send_to_bank', undefined)
                flagsForm.setFieldValue('include_payments', undefined)
            }
        } else if (type === 'include_personal_data') {
            if (value === true) {
                setDisabledFlagStatus({
                    send_to_bank: true,
                    include_payments: true,
                    past_period: false,
                    include_personal_data: false,
                    include_taxes: false
                })
                flagsForm.setFieldValue('send_to_bank', undefined)
                flagsForm.setFieldValue('include_payments', undefined)
            } else {
                setDisabledFlagStatus({
                    send_to_bank: formData.past_period === true || formData.include_taxes === true,
                    include_payments: formData.past_period === true || formData.include_taxes === true,
                    past_period: false,
                    include_personal_data: false,
                    include_taxes: false
                })
                flagsForm.setFieldValue('send_to_bank', undefined)
                flagsForm.setFieldValue('include_payments', undefined)
            }
        } else if (type === 'include_taxes') {
            if (value === true) {
                setDisabledFlagStatus({
                    send_to_bank: true,
                    include_payments: true,
                    past_period: false,
                    include_personal_data: false,
                    include_taxes: false
                })
                flagsForm.setFieldValue('send_to_bank', undefined)
                flagsForm.setFieldValue('include_payments', undefined)
            } else {
                setDisabledFlagStatus({
                    send_to_bank: formData.past_period === true || formData.include_personal_data === true,
                    include_payments: formData.past_period === true || formData.include_personal_data === true,
                    past_period: false,
                    include_personal_data: false,
                    include_taxes: false
                })
                flagsForm.setFieldValue('send_to_bank', undefined)
                flagsForm.setFieldValue('include_payments', undefined)
            }
        } else if (type === 'month') {

            setDisabledFlagStatus({
               ...disabledFlagStatus,
                month: value
            })
        }
    }

    return <>
        <TaskAllPdfGate search={search}/>

        <Row gutter={[8, 8]} justify={'space-between'}>
            <Col span={24}>
                <Card size={'small'} title={t('Тип объектов для формирования ЕПД')}>
                    <Radio.Group onChange={onChangeRadio} value={radioValue}>
                        <Radio value={'all'}>Массовый</Radio>
                        {perms?.sector?.view && <Radio value={'sector'}>По участкам</Radio>}
                        {perms?.inspector?.view && <Radio value={'inspector'}>По контролерам</Radio>}
                        {perms?.real_estate_objects?.view && <Radio value={'reo'}>По ОН</Radio>}
                        {perms?.accounts?.view && <Radio value={'account'}>По ЛС</Radio>}
                    </Radio.Group>
                </Card>
            </Col>
            <Col span={24}>
                <Card size={'small'} title={t('Параметры формирования ЕПД')}>
                    <Form form={flagsForm} layout={'vertical'} size={'small'}>
                        <Row gutter={[8, 0]} justify={'space-between'}>
                            <Col span={4}>
                                <Form.Item
                                    name={'send_to_bank'}
                                    label={'Передать в БВУ'}
                                >
                                    <Switch
                                        disabled={disabledFlagStatus.send_to_bank}
                                        onChange={(v) => onChangeFlag(v, 'send_to_bank')}

                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name={'include_payments'}
                                    label={'Учет текущих платежей'}
                                >
                                    <Switch
                                        onChange={(v) => onChangeFlag(v, 'include_payments')}
                                        disabled={disabledFlagStatus.include_payments}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name={'past_period'}
                                    label={'Закрытый период'}
                                >
                                    <Switch
                                        onChange={(v) => onChangeFlag(v, 'past_period')}
                                        disabled={disabledFlagStatus.past_period}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name={'prev_month'}
                                    label={'Месяц'}
                                >
                                    <Select
                                        disabled={!pastPeriodStatus}
                                        onChange={(v) => onChangeFlag(v, 'month')}
                                        options={closedMonthsOpts}
                                        style={{width: '50%'}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name={'include_personal_data'}
                                    label={'Перс. данные'}
                                >
                                    <Switch
                                        onChange={(v) => onChangeFlag(v, 'include_personal_data')}
                                        disabled={disabledFlagStatus.include_personal_data}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name={'include_taxes'}
                                    label={'Перс. данные налога'}
                                >
                                    <Switch
                                        disabled={disabledFlagStatus.include_personal_data}
                                        onChange={(v) => onChangeFlag(v, 'include_taxes')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
            <Col span={24}>
                <FilterBlock items={
                    radioValue === 'all' ?
                        filtersMass :
                        radioValue === 'sector' ?
                            filtersSector :
                            radioValue === 'inspector' ?
                                filtersInspector :
                                radioValue === 'reo' ?
                                    filtersREO : filtersAccounts
                }/>
            </Col>
            <Col span={24}>
                <Card style={{padding: '5px'}} size={'small'}>
                    <Row
                        gutter={[8, 0]}
                        style={{
                            marginBottom: '-48px',
                            marginRight: '48px',
                            zIndex: 10,
                            position: 'relative'
                        }}
                    >
                        <Col span={5}>
                            <Popconfirm
                                title={'По нажатию на кнопку начнется формирование PDF. Вы уверены?'}
                                okText={t('Да')}
                                placement={'left'}
                                cancelText={t('Нет')}
                                onConfirm={onCreateTask}
                                disabled={actionLoading}
                            >
                                <Button type={'primary'}
                                        style={{marginRight: '10px', marginBottom: '5px'}}
                                        disabled={selected.length === 0}
                                        loading={actionLoading}
                                >
                                    {t(`Сформировать PDF по выбранным`)}
                                </Button>
                            </Popconfirm>
                        </Col>
                        <Col span={7}>
                            <Popconfirm
                                title={'По нажатию на кнопку начнется формирование PDF. Вы уверены?'}
                                okText={t('Да')}
                                placement={'left'}
                                cancelText={t('Нет')}
                                onConfirm={onCreateTaskAll}
                                disabled={actionLoading}
                            >
                                <Button type={'primary'}
                                        style={{marginRight: '10px', marginBottom: '5px'}}
                                        loading={actionLoading}
                                >
                                    {t(`Сформировать PDF по всем попадающим под фильтр`)}
                                </Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]} justify={'space-between'}>
                        <Col span={24}>
                            <div
                                className={'epd-all-create-entities-table'}
                                style={{minHeight: '44px'}}
                            >
                                {radioValue !== 'all' && <DictionaryProTable
                                    data={data}
                                    count={count}
                                    initColumns={
                                        radioValue === 'sector' ?
                                            columnsSector :
                                            radioValue === 'inspector' ?
                                                columnsInspector :
                                                radioValue === 'reo' ?
                                                    columnsReo :
                                                    radioValue === 'account' ?
                                                        columnsAccount : columns
                                    }
                                    loading={loading}
                                    showActions={false}
                                    rowKey={'id'}
                                    rowSelection={{
                                        onChange: onChangeSelect,
                                        preserveSelectedRowKeys: true,
                                        selectedRowKeys: selected
                                    }}
                                />}
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    </>
}
