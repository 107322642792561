import {createEffect} from 'effector';
import api from '../../api/api.js';
import queryString from 'query-string';

export const uploadFileFx = createEffect()
.use(async (data) => {
    const payload = new FormData()
    data.files.forEach(file => {
        payload.append('files', file?.originFileObj)
    })
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    const report_date = data.report_date ? data.report_date.replace('+', '%2B') : null
    const values = `${data.provider_id ?
        `&provider_id=${data.provider_id}` : ''}${data.bank_id ?
        `&bank_id=${data.bank_id}` : ''}${data.current_day ?
        `&current_day=${data.current_day}` : ''}`
    return await (api().post(
        `/import_file/import/provider_payments?${values}`, payload, config
    ))
    // FIXME Backup erc-1031
    // return await (api().post(
    //     `/accruals/load_files?${data.provider_id ? `&provider_id=${data.provider_id}` : ''}
    //     ${report_date ? `&report_date=${report_date}` : ''}`, payload, config
    // ))
})

export const sendMessageUploadFx = createEffect()
    .use(async (data) => {
        data.sendMessage(JSON.stringify(data.payload))
    });

export const getFilesListFx = createEffect()
    .use(async (filters) => {
        const parsed = filters.size > 0 ? queryString.parse(filters.toString(), {parseNumbers: true, parseBooleans: true}) : {}
        for (const [key, value] of Object.entries(parsed)) {
            if (!value || (Array.isArray(value) && value?.length === 0)) {
                delete parsed[key]
            }
        }
        return (await api().get(`/import_file/?status=non_rejected&table_name=provider_payments${filters.length > 0 ? `&${filters}` : ''}`)).data
    })

export const getFileInfoFx = createEffect()
    .use(async (id) => (await api().get(`/import_file/${id}`)).data)

export const getFileApprovedInfoFx = createEffect()
    .use(async ({id, filters}) => (await api().get(`/provider_payments/?reestr_id=${id}${filters.length > 0 ? `&${filters}` : ''}`)).data)

export const getFileCellsFx = createEffect()
    .use(async ({id, filters}) => {
        // if (!filters?.get('sort_field')) filters.set('sort_field', 'row_index')
        return (await api().get(`/import_file/provider_payments/${id}${filters.length > 0 ? `?${filters}` : ''}`)).data
    })

export const acceptFileFx = createEffect()
    .use(async (id) => (await api().get(`/import_file/confirm/${id}`)).data)

export const rejectFileFx = createEffect()
    .use(async (id) => (await api().get(`/import_file/cancel/${id}`)).data)

export const deleteFileFx = createEffect()
    .use(async (id) => (await api().delete(`/import_file/${id}`)).data)

export const stopUploadFileFx = createEffect()
    // .use(async (id, type) => (
    //     await api().post(`/import_file/${type}/${id}`)
    // ).data)
    .use(async (id) => {
        return await ( api().post(`/import_file/stop_import/${id}`))
    })

export const downloadFileFx = createEffect()
    .use(async (id) => {
        const file = await api().get(`/import_file/download/${id}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const downloadErrorFileFx = createEffect()
    .use(async (id) => {
        const file = await api().get(`/import_file/download_errors/${id}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })


export const exportXlsListFx = createEffect()
    .use(async (id) => {
        // const newFilters = queryString.stringify(filters, {skipEmptyString: true, skipNull: true})
        const file = await api().get(`/provider_payments/excel/${id ? `?reestr_id=${id}` : '' }`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })
