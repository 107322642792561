import {createEffect, createEvent, createStore, sample} from 'effector';
import api from '../../api/api.js';
import cookie from '../../utils/cookie.jsx';

const initAuth = (window.location.href.includes('localhost') && cookie.get('access_token')?.length > 0 && cookie.get('access_token') !== 'undefined')
    ? true
    : !window.location.href.includes('localhost')

export const $authStatus = createStore(initAuth)

export const setAuthStatus = createEvent()

export const loginEv = createEvent()
export const logoutEv = createEvent()

export const loginFx = createEffect()
    .use(async ({username, password}) => {
        const formData = new FormData()
        formData.append('username', username)
        formData.append('password', password)
        return (await api().post('/token/login', formData)).data
    })

export const logoutFx = createEffect()
    .use(async () => (await api().post('/token/logout')).data)

$authStatus
    .on(setAuthStatus, (_,data) => data)


$authStatus.reset(logoutFx.doneData)
    .on(loginFx.doneData, () => true)
    .on(loginFx.fail, () => false)

loginFx.doneData.watch(response => {
    // if (window.location.href.includes('localhost')) {
        cookie.set('access_token', response.access_token, {path: '/'})
    // }
})

logoutFx.doneData.watch(response => {
    cookie.remove('access_token', {path: '/'})
    location.reload()
})


sample({
    clock: loginEv,
    target: loginFx
})

sample({
    clock: logoutEv,
    target: logoutFx
})
