import {createGate} from 'effector-react';
import {createEvent} from "effector";
import {sendMessageControllersFx} from "./effects.js";

export const ControllersListGate = createGate()

export const ControllersFilterEv = createEvent()
export const sendMessageControllersEv = createEvent()
export const ControllersSendEmailEv = createEvent()
export const ControllersGeneratePdfEv = createEvent()
export const printControllersPdfEv = createEvent()
export const getUrlPdfEv = createEvent()
