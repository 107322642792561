import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {Select} from "antd";
import {HolderOutlined} from "@ant-design/icons";
import Switch from "antd/es/switch/index.js";
import {DndContext, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import Table from "antd/es/table/index.js";
import {CSS} from "@dnd-kit/utilities";
import {
    TemplatePaymentsCreateGate,
    submitCreateTemplatePaymentsEv,
    $createdTemplatePaymentsId,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,

} from "../../../models/templatePaymentsModel/index.js";

const RowContext = React.createContext({});

const DragHandle = () => {
    const {setActivatorNodeRef, listeners} = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined/>}
            style={{
                cursor: 'move',
            }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

const Row = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                // zIndex: 9999,
            }
            : {}),
    };
    const contextValue = useMemo(
        () => ({
            setActivatorNodeRef,
            listeners,
        }),
        [setActivatorNodeRef, listeners],
    );
    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

export default function CreateTemplateAccruals() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [templatePaymentsForm] = Form.useForm()
    const [form] = Form.useForm();

    const submit = useUnit(submitCreateTemplatePaymentsEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdTemplatePaymentsId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)




    const possibleFields = {
        account_id: "Лицевой счет",
        service_id: "Код услуги",
        service_name: "Наименование услуги",
        people_quantity: "Количество проживающих",
        full_square: "Площадь",
        tarif_value: "Значение тарифа",
        tarif_value_min: "Значение минимального тарифа",
        tarif_value_avg: "Значение среднего тарифа",
        tarif_value_max: "Значение максимального тарифа",
        measure_name: "Единица измерения",
        sign_saldo_calc: "Флаг ведения сальдо",
        sum_saldo_begin: "Долг/переплата без учета начислений и перерасчетов текущего месяца",
        sum_calc: "Сумма начисления в тенге",
        sum_calc_min: "Сумма начисления в тенге (минимальное)",
        sum_calc_avg: "Сумма начисления в тенге (среднее)",
        sum_calc_max: "Сумма начисления в тенге (максимальное)",
        sum_recalc: "Сумма перерасчета в тенге",
        sum_pay: "Сумма платежей прошлого месяца",
        sum_fine: "Сумма неоплаченной пени",
        sum_saldo_end: "Долг/переплата с учетом начислений и перерасчетов текущего месяца",
        consumption: "Потребление в натуральных показателях",
        consumption_min: "Минимальное потребление в натуральных показателях",
        consumption_avg: "Среднее потребление в натуральных показателях",
        consumption_max: "Максимальное потребление в натуральных показателях",
        check_count: "Последние проверенные показания",
        norm1: "Нормативное потребление по минимальному тарифу",
        norm2: "Нормативное потребление по среднему тарифу",
        service_square: "Обслуживаемая площадь",
        pay_count: "Оплаченные показания",
        check_date: "Дата последней проверки",
        is_main_service: "признак основной услуги",
        bill_id: "№ счетфактуры",
        external_number: "№ договора/абонент ГЦТ",
        iin: "ИИН",
    }
    const possibleFieldsOpts = Object.entries(possibleFields).map(
        ([k, v]) => ({value: k, label: v})
    )
    const types = {
        int: 'Целое число',
        float: 'Дробное число',
        date: 'Дата',
        str: 'Строка',
        bool: 'Логический тип',
        iin: 'ИИН',
    }
    const typesOpts = Object.entries(types).map(([k, v]) => ({value: k, label: v}))
    const cells = [
        '',
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
    ]
    const [data, setData] = useState([])
    console.log('data', data)

    const columns = [
        {
            key: 'sort',
            align: 'center',
            width: 80,
            render: () => <DragHandle/>,
        },
        {
            title: 'Столбец',
            dataIndex: 'cell',
            key: 'cell',
            render: (v) => cells[v]
        },
        {
            title: 'Поле',
            dataIndex: 'field',
            key: 'field',
            render: (v, record) => <Select
                defaultValue={v}
                options={possibleFieldsOpts}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, field: value} : d)
                )}
            />
        },
        {
            title: 'Тип',
            dataIndex: 'type',
            key: 'type',
            render: (v, record) => <Select
                defaultValue={v}
                options={typesOpts}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, type: value} : d)
                )}
            />
        },
        {
            title: 'Обязательно',
            dataIndex: 'required',
            key: 'required',
            render: (v, record) => <Switch
                checkedChildren="Обязательное"
                unCheckedChildren="Необязательное"
                defaultValue={v}
                onChange={(value) => setData(
                    data.map(d => d.cell === record.cell ? {...d, required: value} : d)
                )}
            />
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            render: (v, record) => <Button onClick={() => {
                setData(data.filter(d => d.cell !== record.cell))
            }}>Удалить</Button>
        },
    ]

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
                distance: 1,
            },
        }),
    );
    const onDragEnd = ({active, over}) => {
        console.log('active', active, 'over', over)
        if (active.id !== over?.id) {
            console.log('setDat')
            setData((prev) => {
                const activeIndex = prev.findIndex((i) => i.idx === active.id);
                const overIndex = prev.findIndex((i) => i.idx === over?.id);
                const newData = arrayMove(prev, activeIndex, overIndex).map(
                    (item, index) => ({...item, cell: index + 1})
                )
                console.log('activeIndex', activeIndex, 'overIndex', overIndex, 'newData', newData)

                return newData
            });
        }
    };



    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/template-accruals/edit/${createdId}`)
            } else if (action === 'new') {
                templatePaymentsForm.resetFields()
                form.resetFields()
                setData([])
            }
            resetAttrs()
        }
    }, [createdId, action]);

    const onSubmit = (payload) => {
        const createdData = {
            name_ru: payload.name,
            name_kz: payload.name,
            data: data
        }
        submit(createdData)
    }

    return <>
        <TemplatePaymentsCreateGate/>
        <Form form={templatePaymentsForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => onSubmit(v)}
              validateTrigger={'onBlur'}
              // onValuesChange={(_, all) => check(all)}
        >
            <DndContext
                sensors={sensors}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
            >
                <SortableContext
                    // rowKey array
                    items={data.map((i) => i.idx)}
                    strategy={verticalListSortingStrategy}

                >
                    <Card >
                        <Col span={6}>
                            <Form.Item name={'name'} label={'Название шаблона ф/н'}>
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Card>
                    <Card>
                        <Table
                            components={{
                                body: {
                                    row: Row,
                                },
                            }}
                            dataSource={data}
                            columns={columns}
                            rowKey={'idx'}
                            pagination={false}
                            footer={() => {
                                return <Form
                                    layout="inline"
                                    form={form}
                                    onFinish={(formData) => {
                                        console.log(formData)
                                        setData([...data, {
                                            ...formData, idx: data.length + 1,
                                        }].map(
                                            (item, index) => ({...item, cell: index + 1})
                                        ))
                                    }}
                                >
                                    <Form.Item label="Поле" name={'field'} style={{width: '300px'}}>
                                        <Select
                                            options={possibleFieldsOpts}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Тип" name={'type'} style={{width: '300px'}}>
                                        <Select
                                            options={typesOpts}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Обязательно" name={'required'}>
                                        <Switch/>
                                    </Form.Item>
                                    <Button
                                        onClick={() => {
                                            form.submit()
                                        }}
                                        style={{alignSelf: 'center'}}
                                        type={'primary'}>
                                        Добавить
                                    </Button>
                                </Form>
                            }}
                        />
                        {/*<Button >Сохранить</Button>*/}

                    </Card>


                </SortableContext>
            </DndContext>

        </Form>


        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    templatePaymentsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            templatePaymentsForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            templatePaymentsForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
            </Space>
        </Row>
    </>
}
