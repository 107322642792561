import {
    $afterCreateAction,
    $createdKBeId,
    $editKBeStatus,
    $selectedKBe,
    $kbeList
} from './stores.js';
import {getKBeListFx, getKBeByIdFx} from './effects.js';
import {
    deleteKBeEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    KBeEditGate,
    KBeListGate,
    KBeViewGate,
    submitCreateKBeEv,
    submitEditKBeEv, KBeCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateKBeEv, response: triggerSuccessOpsEv}, reset: KBeCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditKBeEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteKBeEv, response: triggerSuccessOpsEv})

$kbeList.on(getKBeListFx.doneData, (_, kbes) => kbes)
    .reset(KBeListGate.close)

$selectedKBe.on(getKBeByIdFx.doneData, (_, kbes) => kbes)
    .reset([KBeEditGate.close, KBeViewGate.close])

$createdKBeId.on(createCompleteEv, (_, {response}) => {
    const kbe = response.find( i => i.type === 'kbe')
    return kbe ? kbe?.value.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editKBeStatus.on(editCompleteEv, () => true)
    .reset(KBeEditGate.close)

sample({
    clock: [KBeEditGate.state, KBeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getKBeByIdFx
})

sample({
    clock: KBeListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getKBeListFx
})

sample({
    source: KBeListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getKBeListFx
})

sample({
    clock: submitCreateKBeEv,
    fn: (payload) => {
        const actions = []
        let idCounter = -1

        // debugger

        actions.push({operation: 'create', type: 'kbe', value: {...payload, id: parseInt(payload.id)}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedKBe,
    clock: submitEditKBeEv,
    fn: (current, payload) => {
        const actions = []
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        const value = {
            ...changedFields,
            id: parseInt(changedFields.id) ? parseInt(changedFields.id) : null,
            object_id:  current.id,
        }

        if (value.id === null) {
            delete value.id
        }

        actions.push({operation: 'update', type: 'kbe', value: value})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteKBeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'kbe', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
