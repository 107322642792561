import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import Select from "antd/es/select";
import InputNumber from 'antd/es/input-number'
import Switch from 'antd/es/switch'
import {PlusOutlined} from "@ant-design/icons";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel/index.js";
import {
    setCreateRealEstateObjTypeEmbeddedEv, setCreateSectorEmbeddedEv,
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {
    $afterCreateAction,
    $editRealEstateObjectsStatus,
    $selectedRealEstateObjects, getRealEstateObjectsByIdFx,
    RealEstateObjectsEditGate, setAfterCreateActionEv, submitEditRealEstateObjectsEv
} from "../../../models/dictionaryRealEstateObjectsModel/index.js";
import CreateSectorEmbeddedForm
    from "../../../components/Embedded/CreateSectorEmbeddedForm/CreateSectorEmbeddedForm.jsx";
import CreateRealEstateObjTypeEmbeddedForm
    from "../../../components/Embedded/CreateRealEstateObjTypeEmbeddedForm/CreateRealEstateObjTypeEmbeddedForm.jsx";
import ShowAddressCard from '../../../components/AddressReoSearchForms/ShowAddressCard.jsx';


export default function EditRealEstateObjects() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedRealEstateObjects)
    const setRealEstateObjTypeEmbedded = useUnit(setCreateRealEstateObjTypeEmbeddedEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const [realEstateObjectsForm] = Form.useForm()

    const submit = useUnit(submitEditRealEstateObjectsEv)

    const loading = useUnit(getRealEstateObjectsByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editRealEstateObjectsStatus)
    const inspectorsMap = useStoreMap($infoMaps, maps => maps.inspectorsMap)
    const sectorOpts = useStoreMap($dictsSelects, sel => sel.sector)
    const sectorWithInspectorOpts = sectorOpts.map(item => {
        if (item.value === ' ' || item.value === '-') {
            return {
                label: item.label,
                value: item.value
            }
        } else {
            return {
                label: `${item.label} (${item.inspector_id ? `${item.inspector_id} ${inspectorsMap[item.inspector_id]?.name}` : 'Без контролера'})`,
                value: item.value
            }
        }
    })
    const realEstateObjectTypesOpts = useStoreMap($dictsSelects, sel => sel.real_estate_object_type)
    const setSectorEmbedded = useUnit(setCreateSectorEmbeddedEv)

    const infoMaps = useUnit($infoMaps)


    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/real-estate-objects?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            realEstateObjectsForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['town_kz', 'name_kz', 'name_ru'])

    return <>
        <RealEstateObjectsEditGate id={id}/>
        <Form form={realEstateObjectsForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <ShowAddressCard raw={selected} title={t('Адрес')}/>
            <Row justify={'space-between'} gutter={[8, 0]} style={{marginTop: '8px'}}>
                <Col span={4}>
                    <Card title={<StarredTitle title={t('Тип объекта недвижимости')}/>}
                          style={{height: '100%'}}
                          size={'small'}>
                        <Form.Item name={'real_estate_object_type_id'}
                            // label={'Тип объекта недвижимости'}
                                   rules={[{
                                       required: true,
                                       message: t('Обязательное поле')
                                   }]}
                                   validateStatus={changedFields['real_estate_object_type_id']}
                        >
                            <Select

                                options={[...realEstateObjectTypesOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setRealEstateObjTypeEmbedded(true)
                                    } else {
                                        // realEstateObjectsForm.resetFields(['sector_id'])
                                        setRealEstateObjTypeEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                                <span role="img" aria-label={option.data.label}>
                                                  {option?.data?.emoji ?
                                                      <PlusOutlined
                                                          style={{color: '#1677ff'}}/> : ''}
                                                </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />

                        </Form.Item>
                        <CreateRealEstateObjTypeEmbeddedForm
                            form={realEstateObjectsForm} inCol={true}/>
                    </Card>
                </Col>
                <Col span={3}>
                    <Card title={t('Участок')} style={{height: '100%'}} size={'small'}>
                        <Form.Item name={'sector_id'}
                            // label={'Участок'}
                                   rules={[{
                                       required: false,
                                       message: t('Обязательное поле')
                                   }]}
                                   validateStatus={changedFields['sector_id']}
                        >
                            <Select
                                options={[...sectorWithInspectorOpts, {
                                    label: 'Создать',
                                    value: -1,
                                    emoji: true
                                }]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setSectorEmbedded(true)
                                    } else {
                                        // realEstateObjectsForm.resetFields(['sector_id'])
                                        setSectorEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                                <span role="img" aria-label={option.data.label}>
                                                  {option?.data?.emoji ?
                                                      <PlusOutlined
                                                          style={{color: '#1677ff'}}/> : ''}
                                                </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateSectorEmbeddedForm form={realEstateObjectsForm}
                                                  inCol={true}/>
                    </Card>
                </Col>
                <Col span={9}>
                    <Card size={'small'} style={{height: '100%'}} loading={loading}>
                        <Row gutter={[8, 0]}>
                            <Col span={14}>
                                <Form.Item
                                    name={'name_ru'}
                                    label={'ЖК'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Input
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item name={'cadastral_number'}
                                           label={'РКА'}
                                           validateStatus={changedFields['cadastral_number']}
                                           rules={[
                                               {required: false, message: t('Обязательное поле')},
                                               {pattern: /^[0-9]{16}$/, message: t('16 цифр')},
                                           ]}
                                >
                                    <Input showCount maxLength={16}/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name={'floor_count'}
                                           label={'Этажей'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'entrance_count'}
                                           label={'Подъездов'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name={'apartment_count'}
                                           label={'Квартир'}
                                           rules={[{
                                               required: false,
                                               message: t('Обязательное поле')
                                           }]}
                                >
                                    <InputNumber style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card size={'small'} style={{height: '100%'}} loading={loading}>
                        <Row gutter={[8, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    name={'is_electricity_network_present'}
                                    label={'Электричество'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                    validateStatus={changedFields['is_electricity_network_present']}
                                >
                                    <Switch
                                        defaultChecked={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name={'is_heat_network_present'}
                                    label={'Тепловая сеть'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                    validateStatus={changedFields['is_electricity_network_present']}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item
                                    name={'is_elevator_present'}
                                    label={'Лифт'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={'is_mailboxes_present'}
                                    label={'Почтовые ящики'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch defaultChecked={false}/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name={'gis_url'}
                                    label={'2ГИС'}
                                    rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

        </Form>


        <Divider orientation={'left'} orientationMargin={0}/>

        <Row>
            <Space>
                <Button danger onClick={() => {
                    realEstateObjectsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            setAction('edit')
                            realEstateObjectsForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            setAction('list')
                            realEstateObjectsForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
