import {useStoreMap, useUnit} from 'effector-react';
import {$infoMaps, $localDict} from '../../models/infoModel/index.js';
import Table from "antd/lib/table/index.js";
import CustomDates from "../CustomDates/CustomDates.jsx";
import Descriptions from "antd/es/descriptions/index.js";


const FiltersTable = (props) => {
    const {data} = props
    const {town_id, town_distr_id, area_id} = useStoreMap($infoMaps, maps => ({
        town_id: maps.townsMap,
        town_distr_id: maps.districtsMap,
        area_id: maps.sectorsMap
    }))


    const localDict = useUnit($localDict)

    const dict = {
        town_id: town_id,
        town_distr_id: town_distr_id,
        area_id: area_id
    }

    const dataA = Object.entries(data)


    const items = dataA?.map((item, index) => {
        return {
            key: index,
            label: localDict[item[0]],
            children: Array.isArray(item[1]) ?
                item[1]?.map(el => dict[item[0]][el] + ', ')
                :
                item[1]?.toString(),
        }
    })

    return <>
        <Descriptions
            bordered
            size={'small'}
            column={1}
            items={items}
        />
    </>
}

export default function NotificationTable({response}) {
    const localDict = useUnit($localDict)
    const {inspectorsMap} = useStoreMap($infoMaps, maps => ({inspectorsMap: maps.inspectorsMap}))
    const args = Object.entries(response.args)
    const data = args.map((item, index, arr) => {
        if (item[0] === 'inspector_ids' || item[0] === 'account_ids') {
            if (item[1].length === 0) {
                return false
            } else {
                return {
                    name: item[0],
                    value: item[1]

                }
            }
        } else if (item[0] === "filters") {
            if (arr?.find(item => item[0] === 'inspector_ids' || item[0] === 'account_ids')[1]?.length > 0) {
                return false
            } else {
                return {
                    name: item[0],
                    value: item[1]

                }
            }
        } else if (item[0] === "generate_type") {
            if (arr?.find(item => item[0] === 'inspector_ids' || item[0] === 'account_ids')[1]?.length > 0) {
                return false
            } else {
                return {
                    name: localDict[item[0]],
                    value: localDict[item[1]]

                }
            }
        } else {
            return {
                name: item[0],
                value: item[1]
            }
        }

    }).filter(el => el !== false)



    const columns = [
        {
            title: 'Названине',
            dataIndex: 'name',
            key: 'name',
            render: (value, row) => {
                return localDict[value] ? localDict[value] : value
            }
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            render: (value, row) => {
                if (Array.isArray(value)) {
                    if (row.name === 'inspector_ids') {
                        return value.map(item => inspectorsMap[item] + ', ')
                    } else {
                        return value.join(', ')
                    }
                } else {
                    if (row.name === 'filters') {
                        return <FiltersTable data={row.value}/>
                    } else if (row.name === 'report_date') {
                        return <CustomDates data={value}/>
                    } else if (typeof value === 'boolean') {
                        return value ? 'Да' : 'Нет'
                    } else {
                        return value
                    }
                }
            }

        },
    ]



    return <Table
        dataSource={data}
        columns={columns}
        bordered
        rowClassName={'table-row'}
        className={'table-container'}
        pagination={false}

    />
}

