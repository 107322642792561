import {sample} from 'effector';
import {
    $accountsAll,
    $accountsList, $createdTask,
    $filters,
    $selectedAccounts, $taskCreationStatus,
} from './stores.js';
import {
    applyFiltersEv,
    changeFiltersEv,
    changeSelectedAccountsEv, createTaskEv,
    resetFiltersEv, resetTaskCreationStatus,
    TaskEmailAccGate
} from './events.js';
import {
    createTaskFx,
    getAccountsFx
} from './effects.js';
import queryString from 'query-string';
import {notifyCreationEv} from '../tasksGlobalModel/index.js';

$accountsList.on(getAccountsFx.doneData, (_, data) => data)
    .reset(TaskEmailAccGate.close)

$accountsAll.on(getAccountsFx.doneData, (existing, newData) => [...existing, ...newData?.data?.filter(n => !existing?.map(e => e.id)?.includes(n.id))])
// $accountsAll.on(getAccountsFx.doneData, (existing, newData) => {
    // return newData
// })
    .reset(TaskEmailAccGate.close)

$filters.on(changeFiltersEv, (state, {key, value}) => ({...state, [key]: value}))
    .reset(resetFiltersEv)

$selectedAccounts.on(changeSelectedAccountsEv, (state, v) => v)

$taskCreationStatus.on(createTaskFx.doneData, () => true)
    .reset(resetTaskCreationStatus)

sample({
    source: {selected: $selectedAccounts, filters: $filters},
    clock: changeFiltersEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return []
    },
    target: $selectedAccounts
})


sample({
    source: {selected: $selectedAccounts, filters: $filters},
    clock: changeSelectedAccountsEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true && selected.length > 0) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return {
            ...filters,
            select_all : false
        }
    },
    target: $filters
})

sample({
    source: {gate: TaskEmailAccGate.state, filters: $filters},
    clock: [TaskEmailAccGate.state.updates, applyFiltersEv, resetFiltersEv],
    filter: ({gate}) => Object.hasOwn(gate, 'search'),
    fn: ({gate, filters}) => {
        const checked = {...filters}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf') {
                delete checked[key]
            }
        }

        return {
            ...queryString.parse(gate.search.toString(), {parseNumbers: true, parseBooleans: true}),
            // ...queryString.parse(filters, {parseNumbers: true, parseBooleans: true}),
            ...checked
        }
    },
    target: getAccountsFx
})

sample({
    source: {selected: $selectedAccounts, filters: $filters},
    clock: createTaskEv,
    filter: ({selected, filters}) => {
        return filters
    },
    fn: ({selected, filters}) => {
        const payload = {
            account_ids: selected,
            filters: filters,
            report_date: dayjs(new Date()).format(),
            recreate_pdf: filters.recreate_pdf ?? false
        }
        if (payload.account_ids.length > 0) {
            delete payload.filters
        } else {
            delete payload.account_ids
        }
        return payload
    },
    target: [createTaskFx, $createdTask]
})

sample({
    clock: createTaskFx.doneData,
    target: notifyCreationEv
})
