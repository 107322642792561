import {
    $createTownEmbeddedOpen,
    $createDistrictEmbeddedOpen,
    $createEditStreetEmbedded,
    $createStreetTypeEmbedded,
    $createInspectorEmbeddedOpen,
    $ipAddressesChildren,
    $selectedChildIpAddressesImmutable,
    $createEditChildIpAddressEmbedded,
    $createdIpAddresses,
    $deletedIpAddresses,
    $sectorsChildren,
    $selectedChildSectorsImmutable,
    $createEditChildSectorsEmbedded,
    $sectorsEmbeddedFilters,
    $createdSectors,
    $deletedSectors,
    $createSectorEmbeddedOpen,
    $createEditChildEPDTemplateFieldEmbedded,
    $EPDTemplateFieldChildren,
    $selectedChildEPDTemplateFieldImmutable,
    $createdEPDTemplateField,
    $deletedEPDTemplateField,
    $createEditChildServicesEmbedded,
    $ServicesChildren,
    $selectedChildServicesImmutable,
    $createdServices,
    $deletedServices,
    $createEpdTemplateFieldEmbeddedOpen,
    $ipAddressEmbeddedFilters,
    $EPDTemplateFieldEmbeddedFilters,
    $ServicesEmbeddedFilters,
    $createEditChildInspectorsEmbedded,
    $InspectorsEmbeddedFilters,
    $InspectorsChildren,
    $selectedChildInspectorsImmutable,
    $createdInspectors,
    $deletedInspectors,
    $createChiefInspectorEmbeddedOpen,
    $createRealEstateObjTypeEmbeddedOpen,
    $createReoEmbeddedOpen,
    $createEditChildAPSEmbedded,
    $APSEmbeddedFilters,
    $APSChildren,
    $selectedChildAPSImmutable,
    $createdAPS,
    $deletedAPS

} from './stores.js';
import {
    resetCreateTownEmbeddedEv,
    setCreateTownEmbeddedEv,
    setCreateDistrictEmbeddedEv,
    setCreateStreetEmbeddedEv,
    setCreateStTypeEmbeddedEv,
    setCreateInspectorEmbeddedEv,
    resetCreateInspectorEmbeddedEv,
    IpAddressesChildrenGate,
    resetCreateEditChildIpAddressEmbeddedEv,
    changeIpAddressesChildrenFiltersEv,
    createChildIpAddressEv,
    editChildIpAddressEv,
    deleteChildIpAddressEv,
    deleteNewIpAddressEv,
    setCreateEditChildIpAddressEmbeddedEv,
    SectorsChildrenGate,
    resetCreateEditChildSectorsEmbeddedEv,
    changeSectorsChildrenFiltersEv,
    createChildSectorsEv,
    editChildSectorsEv,
    deleteChildSectorsEv,
    deleteNewSectorsEv,
    setCreateEditChildSectorsEmbeddedEv,
    setCreateSectorEmbeddedEv,
    resetCreateSectorEmbeddedEv,
    selectChildSectorsEv,
    EPDTemplateFieldChildrenGate,
    changeEPDTemplateFieldChildrenFiltersEv,
    setCreateEditChildEPDTemplateFieldEmbeddedEv,
    resetCreateEditChildEPDTemplateFieldEmbeddedEv,
    createChildEPDTemplateFieldEv,
    deleteNewEPDTemplateFieldEv,
    editChildEPDTemplateFieldEv,
    deleteChildEPDTemplateFieldEv,
    ServicesChildrenGate,
    changeServicesChildrenFiltersEv,
    setCreateEditChildServicesEmbeddedEv,
    resetCreateEditChildServicesEmbeddedEv,
    createChildServicesEv,
    deleteNewServicesEv,
    editChildServicesEv,
    deleteChildServicesEv,
    setCreateEpdTemplateFieldEmbeddedEv,
    resetCreateEpdTemplateFieldEmbeddedEv,
    InspectorsChildrenGate,
    changeInspectorsChildrenFiltersEv,
    setCreateEditChildInspectorsEmbeddedEv,
    resetCreateEditChildInspectorsEmbeddedEv,
    createChildInspectorsEv,
    deleteNewInspectorsEv,
    editChildInspectorsEv,
    deleteChildInspectorsEv,
    selectChildInspectorEv,
    setCreateChiefInspectorEmbeddedEv,
    resetCreateChiefInspectorEmbeddedEv,
    setCreateRealEstateObjTypeEmbeddedEv,
    resetCreateRealEstateObjTypeEmbeddedEv,
    setCreateReoEmbeddedEv,
    APSChildrenGate,
    changeAPSChildrenFiltersEv,
    setCreateEditChildAPSEmbeddedEv,
    resetCreateEditChildAPSEmbeddedEv,
    createChildAPSEv,
    deleteNewAPSEv,
    editChildAPSEv,
    deleteChildAPSEv
} from './events.js';
import {
    getIpAddressesChildrenFx, getOneSectorChildrenFx,
    getSectorsChildrenFx,
    getEPDTemplateFieldChildrenFx,
    getServicesChildrenFx,
    getInspectorsChildrenFx,
    getOneInspectorChildrenFx,
    getAPSChildrenFx

} from './effects.js';
import {sample} from 'effector';
import queryString from 'query-string';
import {getServiceEv, getServicesByIdFx} from "../dictionaryServicesModel/index.js";
import {RealEstateObjectsCreateGate, RealEstateObjectsEditGate} from "../dictionaryRealEstateObjectsModel/index.js";

$createTownEmbeddedOpen.on(setCreateTownEmbeddedEv, (_, open) => open)
    .reset(resetCreateTownEmbeddedEv)

$createEpdTemplateFieldEmbeddedOpen.on(setCreateEpdTemplateFieldEmbeddedEv, (_, open) => open)
    .reset(resetCreateEpdTemplateFieldEmbeddedEv)

$createInspectorEmbeddedOpen.on(setCreateInspectorEmbeddedEv, (_, open) => open )
    .reset(resetCreateInspectorEmbeddedEv)

$createSectorEmbeddedOpen.on(setCreateSectorEmbeddedEv, (_, open) => open )
    .reset([resetCreateSectorEmbeddedEv, RealEstateObjectsEditGate.close, RealEstateObjectsCreateGate.close])
    // .reset(SectorsChildrenGate.close)


$createRealEstateObjTypeEmbeddedOpen.on(setCreateRealEstateObjTypeEmbeddedEv, (_, open) => open )
    .reset(resetCreateRealEstateObjTypeEmbeddedEv)


$createChiefInspectorEmbeddedOpen.on(setCreateChiefInspectorEmbeddedEv, (_, open) => open )
    .reset(resetCreateChiefInspectorEmbeddedEv)

$createEditStreetEmbedded.on(setCreateStreetEmbeddedEv, (_, open) => open)

$createDistrictEmbeddedOpen.on(setCreateDistrictEmbeddedEv, (_, open) => open)

$createStreetTypeEmbedded.on(setCreateStTypeEmbeddedEv, (_, open) => open)

$createReoEmbeddedOpen.on(setCreateReoEmbeddedEv, (_, open) => open)

// Для CRUD таблицы IP Адресов
$ipAddressesChildren
    .on(getIpAddressesChildrenFx.doneData, (_, addresses) => addresses)
    .reset(IpAddressesChildrenGate.close)

$selectedChildIpAddressesImmutable
    .on(getIpAddressesChildrenFx.doneData, (state, addresses) => ([...state, ...addresses.data]))
    .reset([IpAddressesChildrenGate.close])

$createEditChildIpAddressEmbedded.reset(resetCreateEditChildIpAddressEmbeddedEv)

$ipAddressEmbeddedFilters.on(changeIpAddressesChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(IpAddressesChildrenGate.close)

sample({
    source: {gate: IpAddressesChildrenGate.state, filters: $ipAddressEmbeddedFilters},
    clock: [IpAddressesChildrenGate.state.updates, $ipAddressEmbeddedFilters.updates],
    filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters, [src.gate.parent]: src.gate.parentId}
        return queryString.stringify(all)
    },
    target: getIpAddressesChildrenFx
})

sample({
    source: $createdIpAddresses,
    clock: createChildIpAddressEv,
    fn: (src, newIpAddress) => {
        if (src.some(s => s.id < 0)) {
            const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
            // return [...src, {...newIpAddress, id: newId}]
            return [...src, {...newIpAddress}]
        } else {
            return [...src, {...newIpAddress, id: -1}]
        }
    },
    target: $createdIpAddresses
})

sample({
    source: {mutable: $ipAddressesChildren, original: $selectedChildIpAddressesImmutable},
    clock: editChildIpAddressEv,
    filter: (src, {id}) => id > 0,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id)
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                changedFields.push(key)
                result[existingIdx][key] = payload[key]
            } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                changedFields.splice(changedFields.indexOf(key), 1)
                result[existingIdx][key] = payload[key]
            }
        }

        result[existingIdx]['changedFields'] = changedFields
        if (payload.street_type_id < 0) {
            result[existingIdx]['streetType'] = payload?.street?.streetType
        }

        return {data: result, count: src.mutable.count}
    },
    target: $ipAddressesChildren
})

sample({
    source: $createdIpAddresses,
    clock: editChildIpAddressEv,
    filter: (src, {id}) => id < 0,
    fn: (src, {payload, id}) => {
        const result = [...src]
        const existing = result.find(i => i.id === id)
        const idx = result.findIndex(i => i.id === id)
        // Если редактируемая улица является новой, то не отражаем в ней присутствие измененных полей
        result.splice(idx, 1, {...payload, id: existing.id})
        return result
    },
    target: $createdIpAddresses
})

sample({
    source: {existing: $ipAddressesChildren, deleted: $deletedIpAddresses},
    clock: deleteChildIpAddressEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)
        if (item.id > 0) {
            return [...src.deleted, item.id]
        } else {
            return src.deleted
        }
    },
    target: $deletedIpAddresses
})

sample({
    source: $ipAddressesChildren,
    clock: deleteChildIpAddressEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $ipAddressesChildren
})

sample({
    source: $createdIpAddresses,
    clock: deleteNewIpAddressEv,
    fn: (src, id) => {
        const result = [...src]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return result
    },
    target: $createdIpAddresses
})

sample({
    source: {existing: $ipAddressesChildren, newIpAddresses: $createdIpAddresses},
    clock: setCreateEditChildIpAddressEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            let item
            let itemIdx
            if (clock.id > 0) {
                item = src.existing.data.find(i => i.id === clock.id)
                itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            } else {
                item = src.newIpAddresses.find(i => i.id === clock.id)
                itemIdx = src.newIpAddresses.findIndex(i => i.id === clock.id)
            }
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildIpAddressEmbedded
})
// конец хэндлеров для таблицы вложенных IP адресов



// Для CRUD таблицы Участков
$sectorsChildren
    .on(getSectorsChildrenFx.doneData, (_, addresses) => addresses)
    .reset(SectorsChildrenGate.close)

$selectedChildSectorsImmutable
    .on(getSectorsChildrenFx.doneData, (state, addresses) => ([...state, ...addresses.data]))
    .reset([SectorsChildrenGate.close])

$createEditChildSectorsEmbedded.reset(resetCreateEditChildSectorsEmbeddedEv)

$sectorsEmbeddedFilters.on(changeSectorsChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(SectorsChildrenGate.close)

sample({
    source: {gate: SectorsChildrenGate.state, filters: $sectorsEmbeddedFilters},
    clock: [SectorsChildrenGate.state.updates, $sectorsEmbeddedFilters.updates],
    filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters, [src.gate.parent]: src.gate.parentId}
        return queryString.stringify(all)
    },
    target: getSectorsChildrenFx
})

sample({
    source: $createdSectors,
    clock: createChildSectorsEv,
    fn: (src, newIpAddress) => {
        if (src.some(s => s.id < 0)) {
            const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
            return [...src, {...newIpAddress, id: newId}]
        } else {
            return [...src, {...newIpAddress, id: -1}]
        }
    },
    target: $createdSectors
})


sample({
    clock: selectChildSectorsEv,
    target: getOneSectorChildrenFx
})

sample({
    source: $sectorsChildren,
    clock: getOneSectorChildrenFx.doneData,
    fn: (state, payload) => {
        const newItem = {
            ...payload,
            new_item: true
        }
        const newData = {
            count: state.count,
            data: [
                ...state.data,
                newItem
            ]

        }
        return newData
    },
    target: $sectorsChildren
})


$sectorsChildren

sample({
    source: {mutable: $sectorsChildren, original: $selectedChildSectorsImmutable},
    clock: editChildSectorsEv,
    filter: (src, {id}) => id > 0,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id)
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                changedFields.push(key)
                result[existingIdx][key] = payload[key]
            } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                changedFields.splice(changedFields.indexOf(key), 1)
                result[existingIdx][key] = payload[key]
            }
        }

        result[existingIdx]['changedFields'] = changedFields
        // if (payload.street_type_id < 0) {
        //     result[existingIdx]['streetType'] = payload?.street?.streetType
        // }

        return {data: result, count: src.mutable.count}
    },
    target: $sectorsChildren
})

sample({
    source: $createdSectors,
    clock: editChildSectorsEv,
    filter: (src, {id}) => id < 0,
    fn: (src, {payload, id}) => {
        const result = [...src]
        const existing = result.find(i => i.id === id)
        const idx = result.findIndex(i => i.id === id)
        // Если редактируемая улица является новой, то не отражаем в ней присутствие измененных полей
        result.splice(idx, 1, {...payload, id: existing.id})
        return result
    },
    target: $createdSectors
})

sample({
    source: {existing: $sectorsChildren, deleted: $deletedSectors},
    clock: deleteChildSectorsEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)

        if (item.id > 0) {
            if (item.new_item == true) {
                return src.deleted
            } else {
                return [
                    ...src.deleted,
                    item
                ]
            }
        } else {
            return src.deleted
        }

    },
    target: $deletedSectors
})

sample({
    source: $sectorsChildren,
    clock: deleteChildSectorsEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $sectorsChildren
})

sample({
    source: $createdSectors,
    clock: deleteNewSectorsEv,
    fn: (src, id) => {
        const result = [...src]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return result
    },
    target: $createdSectors
})

sample({
    source: {existing: $sectorsChildren, newIpAddresses: $createdSectors},
    clock: setCreateEditChildSectorsEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            let item
            let itemIdx
            if (clock.id > 0) {
                item = src.existing.data.find(i => i.id === clock.id)
                itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            } else {
                item = src.newIpAddresses.find(i => i.id === clock.id)
                itemIdx = src.newIpAddresses.findIndex(i => i.id === clock.id)
            }
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildSectorsEmbedded
})
// конец хэндлеров для таблицы вложенных Участков


// Для CRUD таблицы Полей начислений для ЕПД
$EPDTemplateFieldChildren
    .on(getEPDTemplateFieldChildrenFx.doneData, (_, data) => data)
    .reset(EPDTemplateFieldChildrenGate.close)

$selectedChildEPDTemplateFieldImmutable
    .on(getEPDTemplateFieldChildrenFx.doneData, (state, payload) => ([...state, ...payload.data]))
    .reset([EPDTemplateFieldChildrenGate.close])

$createEditChildEPDTemplateFieldEmbedded.reset(resetCreateEditChildEPDTemplateFieldEmbeddedEv)

$EPDTemplateFieldEmbeddedFilters.on(changeEPDTemplateFieldChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(EPDTemplateFieldChildrenGate.close)

sample({
    source: {gate: EPDTemplateFieldChildrenGate.state, filters: $EPDTemplateFieldEmbeddedFilters},
    clock: [EPDTemplateFieldChildrenGate.state.updates, $EPDTemplateFieldEmbeddedFilters.updates],
    filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters, [src.gate.parent]: src.gate.parentId}
        return queryString.stringify(all)
    },
    target: getEPDTemplateFieldChildrenFx
})
//get service


sample({
    source: $createdEPDTemplateField,
    clock: createChildEPDTemplateFieldEv,
    fn: (src, newData) => {
        if (src.some(s => s.id < 0)) {
            const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
            return [...src, {...newData}]
        } else {
            return [...src, {...newData, id: -1}]
        }
    },
    target: $createdEPDTemplateField
})

sample({
    source: {mutable: $EPDTemplateFieldChildren, original: $selectedChildEPDTemplateFieldImmutable},
    clock: editChildEPDTemplateFieldEv,
    filter: (src, {id}) => id > 0,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id)
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                changedFields.push(key)
                result[existingIdx][key] = payload[key]
            } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                changedFields.splice(changedFields.indexOf(key), 1)
                result[existingIdx][key] = payload[key]
            }
        }

        result[existingIdx]['changedFields'] = changedFields
        if (payload.street_type_id < 0) {
            result[existingIdx]['streetType'] = payload?.street?.streetType
        }

        return {data: result, count: src.mutable.count}
    },
    target: $EPDTemplateFieldChildren
})

sample({
    source: $createdEPDTemplateField,
    clock: editChildEPDTemplateFieldEv,
    filter: (src, {id}) => id < 0,
    fn: (src, {payload, id}) => {
        const result = [...src]
        const existing = result.find(i => i.id === id)
        const idx = result.findIndex(i => i.id === id)
        // Если редактируемая улица является новой, то не отражаем в ней присутствие измененных полей
        result.splice(idx, 1, {...payload, id: existing.id})
        return result
    },
    target: $createdEPDTemplateField
})

sample({
    source: {existing: $EPDTemplateFieldChildren, deleted: $deletedEPDTemplateField},
    clock: deleteChildEPDTemplateFieldEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)
        if (item.id > 0) {
            return [...src.deleted, item.id]
        } else {
            return src.deleted
        }
    },
    target: $deletedEPDTemplateField
})

sample({
    source: $EPDTemplateFieldChildren,
    clock: deleteChildEPDTemplateFieldEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $EPDTemplateFieldChildren
})

sample({
    source: $createdEPDTemplateField,
    clock: deleteNewEPDTemplateFieldEv,
    fn: (src, id) => {
        const result = [...src]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return result
    },
    target: $createdEPDTemplateField
})

sample({
    source: {existing: $EPDTemplateFieldChildren, newEPDTemplateField: $createdEPDTemplateField},
    clock: setCreateEditChildEPDTemplateFieldEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            let item
            let itemIdx
            if (clock.id > 0) {
                item = src.existing.data.find(i => i.id === clock.id)
                itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            } else {
                item = src.newEPDTemplateField.find(i => i.id === clock.id)
                itemIdx = src.newEPDTemplateField.findIndex(i => i.id === clock.id)
            }
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildEPDTemplateFieldEmbedded
})
// конец хэндлеров для таблицы Полей начислений для ЕПД




// Для CRUD таблицы Услуг
$ServicesChildren
    .on(getServicesChildrenFx.doneData, (_, data) => data)
    .reset(ServicesChildrenGate.close)

$selectedChildServicesImmutable
    .on(getServicesChildrenFx.doneData, (state, payload) => ([...state, ...payload.data]))
    .reset([ServicesChildrenGate.close])

$createEditChildServicesEmbedded.reset(resetCreateEditChildServicesEmbeddedEv)

$ServicesEmbeddedFilters.on(changeServicesChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(ServicesChildrenGate.close)

sample({
    source: {gate: ServicesChildrenGate.state, filters: $ServicesEmbeddedFilters},
    clock: [ServicesChildrenGate.state.updates, $ServicesEmbeddedFilters.updates],
    filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters, [src.gate.parent]: src.gate.parentId}
        return queryString.stringify(all)
    },
    target: getServicesChildrenFx
})


sample({
    clock: createChildServicesEv,
    target: getServiceEv
})



sample({
    source: $createdServices,
    clock: getServicesByIdFx.doneData,
    fn: (src, newData) => {
        return [...src, {...newData, new_item: true}]
        // if (src.some(s => s.id < 0)) {
        //     const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
        //     return [...src, {...newData}]
        // } else {
        //     return [...src, {...newData, id: -1}]
        // }
    },
    target: $createdServices
})

sample({
    source: {mutable: $ServicesChildren, original: $selectedChildServicesImmutable},
    clock: editChildServicesEv,
    filter: (src, {payload, id}) => payload.new_item === false ? true : false,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id)
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                changedFields.push(key)
                result[existingIdx][key] = payload[key]
            } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                changedFields.splice(changedFields.indexOf(key), 1)
                result[existingIdx][key] = payload[key]
            }
        }

        result[existingIdx]['changedFields'] = changedFields
        if (payload.street_type_id < 0) {
            result[existingIdx]['streetType'] = payload?.street?.streetType
        }

        return {data: result, count: src.mutable.count}
    },
    target: $ServicesChildren
})

sample({
    source: $createdServices,
    clock: editChildServicesEv,
    filter: (src, {payload, id}) => payload.new_item === true ? true : false,
    fn: (src, {payload, id}) => {
        const result = [...src]
        const existing = result.find(i => i.id === id)
        const idx = result.findIndex(i => i.id === id)
        // Если редактируемая улица является новой, то не отражаем в ней присутствие измененных полей
        result.splice(idx, 1, {...payload, id: existing.id})
        return result
    },
    target: $createdServices
})

sample({
    source: {existing: $ServicesChildren, deleted: $deletedServices},
    clock: deleteChildServicesEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)
        if (item.id > 0) {
            return [...src.deleted, item.id]
        } else {
            return src.deleted
        }
    },
    target: $deletedServices
})

sample({
    source: $ServicesChildren,
    clock: deleteChildServicesEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $ServicesChildren
})

sample({
    source: $createdServices,
    clock: deleteNewServicesEv,
    fn: (src, id) => {
        const result = [...src]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return result
    },
    target: $createdServices
})

sample({
    source: {existing: $ServicesChildren, newServices: $createdServices},
    clock: setCreateEditChildServicesEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            let item
            let itemIdx
            // item = src.newServices.find(i => i.id === clock.id)
            // itemIdx = src.newServices.findIndex(i => i.id === clock.id)

            const check = src.newServices.find(item => item.id === clock.id)

            if (check === undefined) {
                item = src.existing.data.find(i => i.id === clock.id)
                itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            } else {
                item = src.newServices.find(i => i.id === clock.id)
                itemIdx = src.newServices.findIndex(i => i.id === clock.id)
            }

            // if (window.location.href.includes('edit')) {
            //     item = src.existing.data.find(i => i.id === clock.id)
            //     itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            // } else {
            //     item = src.newServices.find(i => i.id === clock.id)
            //     itemIdx = src.newServices.findIndex(i => i.id === clock.id)
            // }
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildServicesEmbedded
})
// конец хэндлеров для таблицы Услуг

// Для CRUD таблицы Контролеров
$InspectorsChildren
    .on(getInspectorsChildrenFx.doneData, (_, addresses) => addresses)
    .reset(InspectorsChildrenGate.close)

$selectedChildInspectorsImmutable
    .on(getInspectorsChildrenFx.doneData, (state, addresses) => ([...state, ...addresses.data]))
    .reset([InspectorsChildrenGate.close])

$createEditChildInspectorsEmbedded.reset(resetCreateEditChildInspectorsEmbeddedEv)

$InspectorsEmbeddedFilters.on(changeInspectorsChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(InspectorsChildrenGate.close)

sample({
    source: {gate: InspectorsChildrenGate.state, filters: $InspectorsEmbeddedFilters},
    clock: [InspectorsChildrenGate.state.updates, $InspectorsEmbeddedFilters.updates],
    filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters, 'chief_inspector_id': src.gate.parentId}
        return queryString.stringify(all)
    },
    target: getInspectorsChildrenFx
})

sample({
    source: $createdInspectors,
    clock: createChildInspectorsEv,
    fn: (src, newIpAddress) => {
        if (src.some(s => s.id < 0)) {
            const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
            return [...src, {...newIpAddress, id: newId}]
        } else {
            return [...src, {...newIpAddress, id: -1}]
        }
    },
    target: $createdInspectors
})


sample({
    clock: selectChildInspectorEv,
    target: getOneInspectorChildrenFx
})

sample({
    source: $InspectorsChildren,
    clock: getOneInspectorChildrenFx.doneData,
    fn: (state, payload) => {
        const newItem = {
            ...payload,
            new_item: true
        }
        const newData = {
            count: state.count,
            data: [
                ...state.data,
                newItem
            ]

        }
        return newData
    },
    target: $InspectorsChildren
})


$InspectorsChildren

sample({
    source: {mutable: $InspectorsChildren, original: $selectedChildInspectorsImmutable},
    clock: editChildInspectorsEv,
    // filter: (src, {id}) => id > 0,
    filter: (src, {id}) => id > 0,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id) ?? []
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                changedFields.push(key)
                result[existingIdx][key] = payload[key]
            } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                changedFields.splice(changedFields.indexOf(key), 1)
                result[existingIdx][key] = payload[key]
            }
        }

        result[existingIdx]['changedFields'] = changedFields
        // if (payload.street_type_id < 0) {
        //     result[existingIdx]['streetType'] = payload?.street?.streetType
        // }

        return {data: result, count: src.mutable.count}
    },
    target: $InspectorsChildren
})

sample({
    source: $createdInspectors,
    clock: editChildInspectorsEv,
    filter: (src, {id}) => id < 0,
    fn: (src, {payload, id}) => {
        const result = [...src]
        const existing = result.find(i => i.id === id)
        const idx = result.findIndex(i => i.id === id)
        // Если редактируемая улица является новой, то не отражаем в ней присутствие измененных полей
        result.splice(idx, 1, {...payload, id: existing.id})
        return result
    },
    target: $createdInspectors
})

sample({
    source: {existing: $InspectorsChildren, deleted: $deletedInspectors},
    clock: deleteChildInspectorsEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)

        if (item.id > 0) {
            if (item.new_item == true) {
                return src.deleted
            } else {
                return [
                    ...src.deleted,
                    item
                ]
            }
        } else {
            return src.deleted
        }

    },
    target: $deletedInspectors
})

sample({
    source: $InspectorsChildren,
    clock: deleteChildInspectorsEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $InspectorsChildren
})

sample({
    source: $createdInspectors,
    clock: deleteNewInspectorsEv,
    fn: (src, id) => {
        const result = [...src]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return result
    },
    target: $createdInspectors
})

sample({
    source: {existing: $InspectorsChildren, newIpAddresses: $createdInspectors},
    clock: setCreateEditChildInspectorsEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            let item
            let itemIdx
            if (clock.id > 0) {
                item = src.existing.data.find(i => i.id === clock.id)
                itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            } else {
                item = src.newIpAddresses.find(i => i.id === clock.id)
                itemIdx = src.newIpAddresses.findIndex(i => i.id === clock.id)
            }
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildInspectorsEmbedded
})
// конец хэндлеров для таблицы вложенных Контролеров




// Для CRUD таблицы Услуг поставщиков
$APSChildren
    .on(getAPSChildrenFx.doneData, (_, addresses) => addresses)
    .reset(APSChildrenGate.close)

$selectedChildAPSImmutable
    .on(getAPSChildrenFx.doneData, (state, addresses) => ([...state, ...addresses.data]))
    .reset([APSChildrenGate.close])

$createEditChildAPSEmbedded.reset(resetCreateEditChildAPSEmbeddedEv)

$APSEmbeddedFilters.on(changeAPSChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(APSChildrenGate.close)

sample({
    source: {gate: APSChildrenGate.state, filters: $APSEmbeddedFilters},
    clock: [APSChildrenGate.state.updates, $APSEmbeddedFilters.updates],
    // filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters}
        return queryString.stringify(all)
    },
    target: getAPSChildrenFx
})

sample({
    source: $createdAPS,
    clock: createChildAPSEv,
    fn: (src, newIpAddress) => {
        if (src.some(s => s.id < 0)) {
            const newId = Math.min(src.filter(i => i.id < 0).map(i => i.id)) - 1
            return [...src, {...newIpAddress, id: newId}]
        } else {
            return [...src, {...newIpAddress, id: -1}]
        }
    },
    target: $createdAPS
})


sample({
    clock: selectChildInspectorEv,
    target: getOneInspectorChildrenFx
})

sample({
    source: $APSChildren,
    clock: getOneInspectorChildrenFx.doneData,
    fn: (state, payload) => {
        const newItem = {
            ...payload,
            new_item: true
        }
        const newData = {
            count: state.count,
            data: [
                ...state.data,
                newItem
            ]

        }
        return newData
    },
    target: $APSChildren
})


$APSChildren

sample({
    source: {mutable: $APSChildren, original: $selectedChildAPSImmutable},
    clock: editChildAPSEv,
    // filter: (src, {id}) => id > 0,
    filter: (src, {id}) => id > 0,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id) ?? []
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                changedFields.push(key)
                result[existingIdx][key] = payload[key]
            } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                changedFields.splice(changedFields.indexOf(key), 1)
                result[existingIdx][key] = payload[key]
            }
        }

        result[existingIdx]['changedFields'] = changedFields
        // if (payload.street_type_id < 0) {
        //     result[existingIdx]['streetType'] = payload?.street?.streetType
        // }

        return {data: result, count: src.mutable.count}
    },
    target: $APSChildren
})

sample({
    source: $createdAPS,
    clock: editChildAPSEv,
    filter: (src, {id}) => id < 0,
    fn: (src, {payload, id}) => {
        const result = [...src]
        const existing = result.find(i => i.id === id)
        const idx = result.findIndex(i => i.id === id)
        // Если редактируемая улица является новой, то не отражаем в ней присутствие измененных полей
        result.splice(idx, 1, {...payload, id: existing.id})
        return result
    },
    target: $createdAPS
})

sample({
    source: {existing: $APSChildren, deleted: $deletedAPS},
    clock: deleteChildAPSEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)

        if (item.id > 0) {
            if (item.new_item == true) {
                return src.deleted
            } else {
                return [
                    ...src.deleted,
                    item
                ]
            }
        } else {
            return src.deleted
        }

    },
    target: $deletedAPS
})

sample({
    source: $APSChildren,
    clock: deleteChildAPSEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $APSChildren
})

sample({
    source: $createdAPS,
    clock: deleteNewAPSEv,
    fn: (src, id) => {
        const result = [...src]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return result
    },
    target: $createdAPS
})

sample({
    source: {existing: $APSChildren, newIpAddresses: $createdAPS},
    clock: setCreateEditChildAPSEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            let item
            let itemIdx
            if (clock.id > 0) {
                item = src.existing.data.find(i => i.id === clock.id)
                itemIdx = src.existing.data.findIndex(i => i.id === clock.id)
            } else {
                item = src.newIpAddresses.find(i => i.id === clock.id)
                itemIdx = src.newIpAddresses.findIndex(i => i.id === clock.id)
            }
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildAPSEmbedded
})
// конец хэндлеров для таблицы вложенных Услуг поставщиков
