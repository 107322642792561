import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const TaxBranchesListGate = createGate()
export const TaxBranchViewGate = createGate()
export const TaxBranchEditGate = createGate()
export const TaxBranchCreateGate = createGate()

export const submitCreateTaxBranchEv = createEvent()
export const submitEditTaxBranchEv = createEvent()
export const deleteTaxBranchEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
