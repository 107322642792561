import {WebSocketProvider} from './api/WebSocketProvider.jsx';
import {useEffect} from 'react';
import {useUnit} from 'effector-react';
import {Outlet, useNavigate} from 'react-router-dom';
import {$infoLoaded, AppGate} from './models/infoModel/index.js';
import {$authStatus} from './models/authModel/index.js';
import Layout from 'antd/es/layout';
import StickyBox from "react-sticky-box";
import NavMenu from './components/NavMenu/NavMenu.jsx';
import './App.css'
import BreadcrumbsGlobal from './components/BreadcrumbsGlobal/BreadcrumbsGlobal.jsx';
import ProfileDropdown from './components/ProfileDropdown/ProfileDropdown.jsx';
import CloseMonthBlockingModal
    from './components/CloseMonthBlockingModal/CloseMonthBlockingModal.jsx';
import NoPermissionModal from './components/NoPermissionModal/NoPermissionModal.jsx';
import ChangePasswordModal from "./components/ChangePasswordModal/ChangePasswordModal.jsx";
import InfoLoading from './components/InfoLoading/InfoLoading.jsx';


function App() {
    const auth = useUnit($authStatus)
    const navigate = useNavigate()
    const infoLoaded = useUnit($infoLoaded)

    window.demo_mode = window.location.href.includes('test.astana-erc.kz') ? true : false;

    useEffect(() => {
        if (!auth) {
            if (!['/', '/login'].includes(window.location.pathname)) {
                localStorage.setItem('logoutAt', window.location.pathname)
            }
            navigate('/login')
        }
    }, [auth])

    return (
        <>
            <NoPermissionModal />
            <ChangePasswordModal />
            <WebSocketProvider>
                <AppGate/>
                {infoLoaded
                    ? <Layout className={'container-global'}>
                        <Layout.Header className={'container-header'}>
                            <NavMenu/>
                            <ProfileDropdown/>
                        </Layout.Header>
                        <Layout.Content className={'container-content'}>
                            <StickyBox offsetTop={-16} style={{zIndex: 11}}>
                                <BreadcrumbsGlobal/>
                            </StickyBox>
                            <Outlet/>
                        </Layout.Content>
                    </Layout>
                    : <InfoLoading/>
                }
                <CloseMonthBlockingModal/>
            </WebSocketProvider>
        </>
    )
}

export default App
