import {useNavigate, useParams} from "react-router-dom";
import {
	$editServiceColumnStatus,
	$selectedColumn,
	getServicesColumnByIdFx,
	ServiceColumnsEditGate, submitEditServiceColumnsEv
} from "../../../models/servicesColumnsModel/index.js";
import {useTranslation} from "react-i18next";
import Form from "antd/es/form";
import {useUnit} from "effector-react";
import useFormChanged from "../../../hooks/useFormChanged.js";
import Row from "antd/es/row";
import Space from "antd/es/space";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Divider from "antd/es/divider";
import {useEffect, useState} from "react";
import {universalDictEndpointFx} from "../../../models/dictionaryUniversalModel/index.js";
import { Cropper } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css';
import {Image} from "antd";
import styles from '../ServicesColumns.module.css'
import UploadComponent from "../../../components/UploadComponent/UploadComponent.jsx";

export default function EditServicesColumn(){
	const {id} = useParams()
	const navigate = useNavigate()
	const {t} = useTranslation()
	const [columnForm] = Form.useForm()
	const loading = useUnit(getServicesColumnByIdFx.pending)
	const submit = useUnit(submitEditServiceColumnsEv)
	const submitLoading = useUnit(universalDictEndpointFx.pending)
	const [editorSrc, setEditorSrc] = useState(undefined)
	const [editedImageSrc, setEditedImageSrc] = useState(undefined)

	const selectedColumn = useUnit($selectedColumn)
	const editServiceColumnStatus = useUnit($editServiceColumnStatus)

	const [check, changedFields] = useFormChanged(selectedColumn, ['name_kz', 'name_ru'])
	const genitive_form = t(`breadcrumbs.genitive.services-columns`);

	const normFile = (info) => {
		const reader = new FileReader();
		reader.addEventListener("load", () => {
			setEditorSrc(reader.result)
		});
		reader.readAsDataURL(info.file);
		return info?.fileList
	};

	const updateImage = (cropper) => {
		setTimeout(() => {
			setEditedImageSrc(cropper.getCanvas().toDataURL());
		}, 100)
	}

	useEffect(() => {
		if(selectedColumn){
			columnForm.setFieldsValue(selectedColumn)
			if(id === '13'){
				setEditorSrc(selectedColumn.text_ru)
				setEditedImageSrc(selectedColumn.text_ru)
			}
		}
	}, [selectedColumn]);

	useEffect(() => {
		if(editServiceColumnStatus){
			columnForm.resetFields()
			navigate('/services-columns')
		}
	}, [editServiceColumnStatus]);

	const onSubmit = (values) => {
		submit({
				name_ru: selectedColumn.name_ru,
				name_kz: selectedColumn.name_kz,
				text_kz: selectedColumn.text_kz,
				...values,
				text_ru: id === '13' ? editedImageSrc : values.text_ru
		})
	}

	return <>
		<ServiceColumnsEditGate id={id}/>
		<Form form={columnForm}
			  		size={'small'}
					layout={'vertical'}
					onFinish={onSubmit}
					validateTrigger={'onBlur'}
					onValuesChange={(_, all) => check(all)}
		>
			<Card title={t('Свойства столбца')} size={'small'} loading={loading}>
				<Row justify={'space-between'}>
					{
						id === '13' ?
							<>
								<Col span={24}>
									<UploadComponent title={t('Баннер')} isForm formName={'image'} dragger multiple customNormFile={normFile}/>
									<div className={styles.edit_image_wrapper}>
										<div className={styles.edit_image_item}>
											<div>Изменить</div>
											<Cropper
											src={editorSrc}
											className={'cropper'}
											onReady={updateImage}
											onMoveEnd={updateImage}
											onResizeEnd={updateImage}
											onTransformImageEnd={updateImage}
										/>
										</div>
										<div className={styles.edit_image_item}>
											<div>Превью</div>
											<Image
											src={editedImageSrc}
										/>
										</div>
									</div>
								</Col>
							</>
							:
							<>
								<Col span={5}>
									<Form.Item name={'name_kz'}
														 label={t(`Название ${genitive_form} (каз)`)}
									>
										<Input disabled/>
									</Form.Item>
								</Col>
								<Col span={5}>
									<Form.Item name={'name_ru'}
														 label={t(`Название ${genitive_form} (рус)`)}
									>
										<Input disabled/>
									</Form.Item>
								</Col>
								<Col span={5}>
									<Form.Item name={'text_kz'}
														 label={t(`Текст ${genitive_form} (каз)`)}
														 rules={[{required: true, message: t('Обязательное поле')}]}
														 // validateStatus={changedFields['text_kz']}
									>
										<Input/>
									</Form.Item>
								</Col>
								<Col span={5}>
									<Form.Item name={'text_ru'}
														 label={t(`Текст ${genitive_form} (рус)`)}
														 rules={[{required: true, message: t('Обязательное поле')}]}
														 // validateStatus={changedFields['text_ru']}
									>
										<Input/>
									</Form.Item>
								</Col>
							</>
					}
				</Row>
			</Card>
		</Form>
		<Divider orientation={'left'} orientationMargin={0}/>
		<Row>
			<Space>
				<Button danger onClick={() => {
					columnForm.resetFields()
					navigate(-1)
				}}
				>
					{t('Отмена')}
				</Button>
				<Button loading={submitLoading}
								onClick={() => {
									columnForm.submit()
								}}
								type={'primary'}
				>
					{t('Сохранить')}
				</Button>
			</Space>
		</Row>
	</>
}
