import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const RealEstateObjectsListGate = createGate()
export const RealEstateObjectsViewGate = createGate()
export const RealEstateObjectsEditGate = createGate()
export const RealEstateObjectsCreateGate = createGate()

export const submitCreateRealEstateObjectsEv = createEvent()
export const submitEditRealEstateObjectsEv = createEvent()
export const deleteRealEstateObjectsEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()


export const setMassReoFormDataEv = createEvent()
export const resetMassReoFormDataEv = createEvent()
export const onCreateGroupEv = createEvent()

export const onCreateListSetFiltersEv = createEvent()
