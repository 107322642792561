import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getUsersListFx} from "./effects.js";

export const UsersListGate = createGate()
export const UsersViewGate = createGate()
export const UsersEditGate = createGate()
export const UsersCreateGate = createGate()
export const UsersProviderGate = createGate()

export const submitCreateUsersEv = createEvent()
export const submitEditUsersEv = createEvent()
export const deleteUsersEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()

export const openUsrPrvModalEv = createEvent()
export const dataUsrPrvModalEv = createEvent()
export const closeUsrPrvModalEv = createEvent()
export const submitChangeUsrPrvEv = createEvent()

export const addUsrPrvEv = createEvent()
export const deleteUsrPrvEv = createEvent()
