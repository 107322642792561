import {useTranslation} from 'react-i18next';
import Upload from 'antd/es/upload';
import Form from 'antd/es/form';
import Dragger from 'antd/es/upload/Dragger';
import Button from 'antd/es/button';
import {UploadOutlined} from '@ant-design/icons';

export default function UploadComponent({title, size, isForm, formName, dragger = false, multiple = false, onChange, customNormFile}) {
    const {t} = useTranslation()

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return isForm
        ? <Form.Item name={formName}
                     label={title}
                     valuePropName="fileList"
                     getValueFromEvent={customNormFile || normFile}>
            {dragger
                ? <Dragger beforeUpload={() => false} multiple={multiple}>
                    <UploadOutlined style={{fontSize: 48}}/>
                    <p>{t('Кликните чтобы выбрать файл или перетащите файл в данное поле')}</p>
                </Dragger>
                : <Upload beforeUpload={() => false} multiple={multiple}>
                    <Button
                        icon={<UploadOutlined />}
                        shape="round"
                        size={size ?? 'large'}
                    />
                </Upload>
            }
        </Form.Item>
        : <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
            <p>{title}</p>
            {
                dragger
                    ? <Dragger beforeUpload={() => false}
                               multiple={multiple}
                               onChange={(e) => onChange(e)}
                    >
                        <UploadOutlined style={{fontSize: 48}}/>
                        <p>{t('Кликните чтобы выбрать файл или перетащите файл в данное поле')}</p>
                    </Dragger>
                    : <Upload beforeUpload={() => false}
                              multiple={multiple}
                              onChange={(e) => onChange(e)}
                    >
                        <Button
                            icon={<UploadOutlined />}
                            shape="round"
                            size={size ?? 'large'}
                        />
                    </Upload>
            }
        </div>;
}
