import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useStoreMap, useUnit} from "effector-react";
import {
	$uploadAgreementsModal, $uploadAgreementsStatus,
	resetUploadAgreementsModalEv,
	uploadAgreementsFileEv, uploadAgreementsFileFx
} from "../../../models/uploadAgreementsModel/index.js";
import Form from "antd/es/form";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import Modal from "antd/es/modal";
import {Row, Select} from "antd";
import Col from "antd/es/col/index.js";
import UploadComponent from "../../../components/UploadComponent/UploadComponent.jsx";

function UploadAgreementsModal() {
	const {t} = useTranslation();
	const open = useUnit($uploadAgreementsModal)
	const onClose = useUnit(resetUploadAgreementsModalEv)
	const onUpload = useUnit(uploadAgreementsFileEv)
	const loading = useUnit(uploadAgreementsFileFx.pending)
	const status = useUnit($uploadAgreementsStatus)
	const [fileForm] = Form.useForm()

	const providerOpts = useStoreMap($dictsSelects, sel => sel.providers)

	const onReset = () => {
		fileForm.resetFields()
		onClose()
	}
	useEffect(() => {
		if(status){
			onReset()
		}
	}, [status]);

	return <Modal
		open={open}
		width={'80vw'}
		title={t('Загрузка файла')}
		onCancel={onReset}
		okText={t('Загрузить')}
		cancelText={t('Отмена')}
		onOk={() => fileForm.submit()}
		okButtonProps={{loading: loading}}
		destroyOnClose
	>
		<Form form={fileForm} onFinish={onUpload} layout={'vertical'} size={'small'}>
			<Row justify={"space-between"}>
				<Col span={11}>
					<Form.Item name={'provider_id'} label={t('Поставщик')} rules={[{required: true, message: t('Обязательное поле')}]}>
						<Select
							showSearch
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							options={providerOpts}

						/>
					</Form.Item>
				</Col>
			</Row>
			<UploadComponent title={t('Файлы')} isForm formName={'files'} dragger multiple />
		</Form>
	</Modal>
}

export default UploadAgreementsModal;
