import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import {
    $selectedOrgType,
    OrgTypeViewGate,
    getOrgTypeByIdFx
} from '../../../models/dictionaryOrgTypeModel';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import dayjs from 'dayjs';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";

export default function ViewOrgType() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getOrgTypeByIdFx.pending)
    const orgType = useUnit($selectedOrgType)
    const genitive_form = t(`breadcrumbs.genitive.organization_type`);

    const items = [
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: orgType?.name_kz
        },
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: orgType?.name_ru
        },
        {
            key: 'group_by_sector_f702',
            label: t('Разделять по районам в ф-702'),
            children: orgType?.separate_service_f702
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={orgType?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={orgType?.created_at} />
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: orgType?.updated_by ? <CreatedBy id={orgType?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: orgType?.updated_at ? dayjs(orgType?.updated_at).format('DD.MM.YYYY HH:mm:ss') : '-'
        }
    ]

    return <>
        <OrgTypeViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр категории организации')}</span> <MappedDictItem id={id} type={'organization_type'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'organization_type'}/>
        </Card>
    </>
}
