import {
    $afterCreateAction,
    $createdServiceClassId,
    $editServiceClassStatus,
    $selectedServiceClass,
    $serviceClassesList
} from './stores.js';
import {getServiceClassesListFx, getServiceClassByIdFx} from './effects.js';
import {
    deleteServiceClassEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, ServiceClassCreateGate,
    ServiceClassEditGate,
    ServiceClassesListGate,
    ServiceClassViewGate,
    submitCreateServiceClassEv,
    submitEditServiceClassEv,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createServiceClassCompleteEv = combineEvents({events: {ev: submitCreateServiceClassEv, response: triggerSuccessOpsEv}, reset: ServiceClassCreateGate.state})
const editServiceClassCompleteEv = combineEvents({ev: submitEditServiceClassEv, response: triggerSuccessOpsEv})
const deleteServiceClassCompleteEv = combineEvents({ev: deleteServiceClassEv, response: triggerSuccessOpsEv})

$serviceClassesList.on(getServiceClassesListFx.doneData, (_, sex) => sex)
    .reset(ServiceClassesListGate.close)

$selectedServiceClass.on(getServiceClassByIdFx.doneData, (_, sex) => sex)
    .reset([ServiceClassEditGate.close, ServiceClassViewGate.close])
// FIXME
$createdServiceClassId.on(createServiceClassCompleteEv, (_, {response}) => response.sex)
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editServiceClassStatus.on(editServiceClassCompleteEv, () => true)
    .reset(ServiceClassEditGate.close)

sample({
    clock: [ServiceClassEditGate.state, ServiceClassViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getServiceClassByIdFx
})

sample({
    clock: ServiceClassesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServiceClassesListFx
})

sample({
    source: ServiceClassesListGate.state,
    clock: [createServiceClassCompleteEv, editServiceClassCompleteEv, deleteServiceClassCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getServiceClassesListFx
})

sample({
    clock: submitCreateServiceClassEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'service-classes', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedServiceClass,
    clock: submitEditServiceClassEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'service-classes', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteServiceClassEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'service-classes', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
