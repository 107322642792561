import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    KbkCreateGate,
    submitCreateKbkEv,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    $createdKbkId
} from '../../../models/dictionaryKbkModel/index.js';
import {InputNumber} from "antd";

export default function CreateKbk() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [kbkForm] = Form.useForm()

    const submit = useUnit(submitCreateKbkEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdKbkId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.kbk`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/kbk/edit/${createdId}`)
            } else if (action === 'new') {
                kbkForm.resetFields()
            } else if (action === 'list') {
                kbkForm.resetFields()
                navigate(`/dictionaries/kbk?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <KbkCreateGate/>
        <Form form={kbkForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Card title={t('Свойства КБК')} size={'small'}>
                <Row justify={'space-between'} gutter={[16,0]}>
                    <Col span={4}>
                        <Form.Item name={'id'}
                                   label={t(`Код ${genitive_form}`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <InputNumber style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Короткое Название ${genitive_form} (рус)`)}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item name={'short_name_kz'}
                                   label={t(`Короткое Название ${genitive_form} (каз)`)}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    kbkForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            kbkForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующий')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            kbkForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            kbkForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
