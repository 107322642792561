import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $addressesList, AddressesListGate,
    deleteAddressEv,
    getAddressesListFx
} from '../../models/dictionaryAddressesModel';
import {Link} from 'react-router-dom';
import {useMemo} from 'react';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import Tooltip from 'antd/es/tooltip/index';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button/index';
import {
    CheckCircleOutlined, CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import {Popconfirm} from 'antd';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import MappedDictItem from '../../components/MappedDictItem/MappedDictItem.jsx';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import AddressREOSearchForm from '../../components/AddressReoSearchForms/AddressREOSearchForm.jsx';
import {$userPerms} from '../../models/infoModel/index.js';

export default function DictionaryAddressesList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($addressesList)
    const loading = useUnit(getAddressesListFx.pending)
    const deleteAddress = useUnit(deleteAddressEv)
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.address`)
    const perms = useUnit($userPerms)


    const initColumns = useMemo(() => [
        // {
        //     width: '5%',
        //     title: t(`Код ${genitive_form}`),
        //     dataIndex: 'id',
        //     sorter: true,
        //     key: 'id',
        //     render: (id) => <Link to={`/dictionaries/address/view/${id}`}>{id}</Link>
        // },
        {
            width: '5%',
            title: t(`Индекс ${genitive_form}`),
            dataIndex: 'index',
            sorter: true,
            key: 'index',
            render: (id) => <Link to={`/dictionaries/address/view/${id}`}>{id}</Link>
        },
        {
            width: 100,
            title: t('Город'),
            dataIndex: 'town_ru',
            sorter: true,
            key: 'town',
            render: (_, record) => localStorage.getItem('lang') === 'kz' && record?.town_kz ? record?.town_kz : record?.town_ru
        },
        {
            width: 150,
            title: t('Район'),
            dataIndex: 'town_distr_id',
            sorter: true,
            key: 'town_distr_id',
            render: (dist_id) => <MappedDictItem id={dist_id} type={'town_distr'}/>
        },
        {
            width: 250,
            title: t('Улица'),
            dataIndex: 'street_id',
            sorter: true,
            key: 'street_id',
            render: (st_id) => <MappedDictItem id={st_id} type={'street'}/>
        },
        {
            width: 50,
            title: t('Номер дома'),
            dataIndex: 'house',
            sorter: true,
            key: 'house',
        },
        {
            width: 50,
            title: t('Номер корпуса'),
            dataIndex: 'corpus',
            key: 'corpus',
            sorter: true,
        },
        // {
        //     width: 100,
        //     title: t('Актуален'),
        //     dataIndex: 'is_actual',
        //     key: 'is_actual',
        //     render: (is_actual) => <>{is_actual === true ? t('Да') : t('Нет')}</>
        // },
        // {
        //     width: 100,
        //     title: t('Актуален с'),
        //     dataIndex: 'actual_from',
        //     key: 'actual',
        //     sorter: true,
        //     render: (date) => date ? <CustomDates data={date} /> : '-'
        // },
        {
            width: 50,
            title: t('На перекрестке'),
            dataIndex: 'is_on_crossroad',
            key: 'is_on_crossroad',
            render: (is_on_crossroad) => <>{is_on_crossroad === true ? <CheckCircleOutlined style={{color: 'green'}}/> : <CloseCircleOutlined style={{color: 'red'}}/>}</>
        },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: 100,
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    width: 100,
                    dataIndex: 'created_by',
                    sorter: true,
                    key: 'created_by',
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    width: 100,
                    key: 'updated_at',
                    sorter: true,
                    render: (date, record) => record.updated_at && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    sorter: true,
                    width: 100,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                {perms?.address?.update && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/address/edit/${id}`}><Button icon={<EditOutlined/>}
                                                                          type={'primary'}/></Link>
                </Tooltip>}
                {perms?.address?.view && <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/address/view/${id}`}><Button
                        icon={<EyeOutlined/>}/></Link>
                </Tooltip>}
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                {perms?.address?.delete && <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                             okText={t('Да')}
                             onConfirm={() => deleteAddress(id)}
                             placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>}
            </ButtonGroup>
        }
    ], [])

    const filters = [
        {name: 'custom', type: 'custom', component: AddressREOSearchForm, span: 24,
            // styles: {town: {width: '12.8rem'}, district: {width: '12.8rem'}, street: {width: '12.8rem'}, house: {width: '19.2rem'}, corpus: {width: '12.8rem'}}
        },
        {name: 'index', label: t('Индекс'), type: 'input', span: 4,},
        {name: 'is_on_crossroad', label: t('На перекрестке'), type: 'switch', span: 3,},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true, span: 4,},
        {name: 'created_at', label: t('Дата создания'), type: 'range', span: 4,},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true, span: 4,},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range', span: 4,},
    ]



    return <>
        <AddressesListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable data={data} count={count} initColumns={initColumns} loading={loading} exportXlsAction={exportXlsAction}/>
    </>
}
