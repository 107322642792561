import {useUnit} from 'effector-react';
import {
    $closeMonthBlockingModalOpen,
    stopTaskEv
} from '../../models/closeMonthBlockingModalModel/index.js';
import Modal from 'antd/es/modal';
import Typography from 'antd/es/typography';
import {useTranslation} from 'react-i18next';
import {LoadingOutlined} from '@ant-design/icons';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';

export default function CloseMonthBlockingModal() {
    const open = useUnit($closeMonthBlockingModalOpen)
    const {t} = useTranslation()
    const stopTask = useUnit(stopTaskEv)

    return <Modal closable={false}
                  open={open}
                  width={'80%'}
                  title={<div style={{display: 'flex', gap: 16}}>
                      <span>{t('Закрытие месяца')}</span>
                      <LoadingOutlined/>
                  </div>}
                  footer={
                      <Popconfirm
                          title={t('Вы уверены, что хотите остановить задачу по закрытию месяца?')}
                          onConfirm={() => stopTask()}
                          okText={t('Да')}
                      >
                          <Button type={'primary'}>{t('Остановить задачу')}</Button>
                      </Popconfirm>
                  }
    >
            <Typography.Paragraph>
                {t('Выполняется закрытие месяца, пожалуйста подождите...')}
            </Typography.Paragraph>
    </Modal>
}