import {
    $createdProviderId,
    $bankAccountsByProvider,
    $providerDefaultBankAccounts,
    $providerServices,
    $providersList,
    $selectedProvider,
    $selectedProvService,
    $afterCreateAction,
    $providerServicesInit,
    $providerServBaREOs,
    $selectedServBaREO,
    $providerServAccs,
    $provServAccsInit,
    $deletedServAccs,
    $selectedServAcc,
    $accountsList,
    $accountsFilters,
    $selectedAccounts,
    $newProviderServAccs,
    $providerServAccsTarifs,
    $provServAccsTarifsInit,
    $provServAccsForTarifs,
    $selectedServAccTarif,
    $provServAccsFilters,
    $deletedServAccsTarifs,
    $filteredServAccs,
    $selectedSrvAccs,
    $providerEditStatus,
    $selectedBeneficiary,
    $selectedBankAccount,
    $providerAgreements,
    $providerAgreementsInit,
    $selectedAgreement,
    $provAgreementsFiles,
    $deletedAgreements,
    $embeddedBaForm,
    $afterEditAction,
    $embeddedTariffForm,
    $bankAccsModal,
    $selectedProvForBAs,
    $srvBAs,
    $newBankAccounts,
    $srvBaREOTemp,
    $beneficiaryBAs,
    $benefBAsModal, $providerKassaList, $viewAPS
} from './stores.js';
import {
    getProvidersListFx,
    getProviderByIdFx,
    getProviderBankAccountsFx,
    getProviderServicesFx,
    getProviderDefaultBankAccountFx,
    getProviderSrvPrefFx,
    getProviderSrvComsFx,
    getProviderSrvBaREOsFx,
    getProviderSrvAccsFx,
    getAccountsFx,
    getProviderSrvAccsTarifsFx,
    getProviderSrvAccsForTarifsFx,
    getProviderAgreementsFx,
    downloadAgreementFileFx,
    uploadAgreementFileFx,
    createEditBankAccountFx,
    createEditDefaultBankAccountFx,
    getProviderServiceBankAccountsFx,
    createEditSrvBankAccountFx,
    createEditProvSrvFx,
    createEditREOBankAccountFx,
    getBeneficiaryBankAccountsFx, getProviderKassaFx, deleteProvKassaFx, getViewAPSFx
} from './effects.js';
import {
    addProvServiceEv,
    editSrvParamsEv,
    deleteProviderEv,
    ProviderCreateGate,
    ProviderEditGate,
    ProviderListGate,
    ProviderViewGate,
    resetSelectedProvSrvEv,
    selectProvServiceEv,
    submitCreateProviderEv,
    submitEditProviderEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    deleteSrvEv,
    resetServBaREOEv,
    deleteServBaREOEv,
    editServBaREOEv,
    addServBaREOEv,
    selectServBaREOEv,
    deleteServAccEv,
    applyServAccEv,
    addServAccEv,
    selectServAccEv,
    resetServAccEv,
    resetAccountsEv,
    setAccountsFiltersEv,
    selectMultipleAccountsEv,
    getAccountsEv,
    resetServAccTarifEv,
    resetSrvAccsForTarifsEv,
    setSrvAccForTarifsFilterEv,
    applySrvAccForTarifsFiltersEv,
    selectServAccTarifEv,
    applyServAccTarifEv,
    addServAccTarifEv,
    deleteServAccTarifEv,
    selectMultipleSrvAccsForTarifsEv,
    setBeneficiaryEv,
    resetProvBankAccountsEv,
    selectProvBankAccountEv,
    deleteProvAgreementEv,
    applyProvAgreementEv,
    selectProvAgreementEv,
    addProvAgreementEv,
    downloadProvAgreementFileEv,
    setBaEmbeddedFormEv,
    setAfterEditActionEv,
    resetAfterEditAttrs,
    setEmbeddedTariffFormEv,
    setBAModalEv,
    resetBAModalEv,
    selectProvForBAsEv,
    addNewDefaultProvBAEv,
    selectSrvBankAccountEv,
    deleteDefaultBAEv,
    addDefaultProvBAEv,
    editDefaultProvBAEv,
    addNewProvSrvBAEv,
    addProvSrvBAEv,
    editProvSrvBAEv,
    deleteSrvBankAccountEv,
    addNewTempSrvBaREOEv,
    addTempSrvBaREOEv,
    selectTempReoBankAccountEv,
    editTempSrvBaREOEv,
    addBenefBAEv,
    setBenefBAModalEv,
    resetBenefBAModalEv,
    selectBenefBankAccountEv,
    deleteBenefBAEv,
    editBenefBAEv,
    ProviderKassaGate,
    deleteProvKassaEv,
    addProvKassaEv, getBenefBAsEv, getViewAPSEv
} from './events.js';
import {sample} from 'effector';
import {combineEvents} from 'patronum';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import filterSrvAccs, {
    addNewSrvAcc,
    addNewSrvAccTarifs,
    addNewSrvREO, cleanUpPayload, formatBankAcc,
    formatPayload,
    prepareSrv
} from './utils.js';
import {isId} from '../../utils/helpers.js';
import queryString from 'query-string';
import {$foundReos, $multiSelectedReos} from '../accountsREOSearchModel/index.js';
import {$userPerms} from "../infoModel/index.js";
import permitted from "../../utils/permitted.js";
import notification from 'antd/es/notification';
import i18n from '../../i18n.js';

const createProviderCompleteEv = combineEvents({events: {ev: submitCreateProviderEv, response: triggerSuccessOpsEv}, reset: ProviderCreateGate.state})
const editProviderCompleteEv = combineEvents({ev: submitEditProviderEv, response: triggerSuccessOpsEv})
const deleteProviderCompleteEv = combineEvents({ev: deleteProviderEv, response: triggerSuccessOpsEv})

$providersList.on(getProvidersListFx.doneData, (_, payload) => payload)
    .reset(ProviderListGate.close)

$selectedProvider.on(getProviderByIdFx.doneData, (_, provider) => provider)
    .reset([ProviderEditGate.close, ProviderViewGate.close])

$selectedBeneficiary.on(setBeneficiaryEv, (_, beneficiaryId) => beneficiaryId)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, resetAfterCreationAttrs, ProviderViewGate.close])

$providerEditStatus.on(editProviderCompleteEv, () => true)
    .reset([ProviderEditGate.close, resetAfterEditAttrs])

$createdProviderId.on(createProviderCompleteEv, (_, {response}) => {
    const prov = response.find(i => i.type === 'providers')
    return prov ? prov?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$afterEditAction.on(setAfterEditActionEv, (_, action) => action)
    .reset(resetAfterEditAttrs)


// Kassa
$providerKassaList.on(getProviderKassaFx.doneData, (_, payload) => payload)
    .reset(ProviderKassaGate.close)

sample({
    clock: deleteProvKassaEv,
    // filter: (prov) => !!prov && prov?.id >= 0,
    fn: (id) => {

        return  [{operation: 'delete', type: 'provider_kassa', value: id}]
    },
    target: deleteProvKassaFx
})

sample({
    clock: addProvKassaEv,
    // filter: (prov) => !!prov && prov?.id >= 0,
    fn: ({prov_id, bank_id}) => {
        // debugger
        return  [{
            operation: 'create', type: 'provider_kassa', value: {
                id: -1,
                bank_id: bank_id,
                provider_id: parseInt(prov_id)
            }
        }]
    },


    target: deleteProvKassaFx
})

// Bank accounts
$bankAccountsByProvider
    .on(getProviderBankAccountsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, resetProvBankAccountsEv, resetBAModalEv])

$selectedProvForBAs.on(selectProvForBAsEv, (_, payload) => payload)
    .reset(resetBAModalEv)

$providerDefaultBankAccounts.on(getProviderDefaultBankAccountFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$srvBAs.on(getProviderServiceBankAccountsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, resetSelectedProvSrvEv])

$selectedBankAccount.reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, resetBAModalEv, resetBenefBAModalEv])

$embeddedBaForm.on(setBaEmbeddedFormEv, (_, payload) => payload)
    .reset([$bankAccountsByProvider.updates])

$bankAccsModal.on(setBAModalEv, (state, payload) => ({...state, ...payload}))
    .reset(resetBAModalEv)

$newBankAccounts.reset([ProviderCreateGate.close, createEditSrvBankAccountFx.doneData])

$beneficiaryBAs.on(getBeneficiaryBankAccountsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, resetProvBankAccountsEv])

$benefBAsModal.on(setBenefBAModalEv, (state, payload) => ({...state, ...payload}))
    .reset(resetBenefBAModalEv)

sample({
    source: $providerDefaultBankAccounts,
    clock: selectProvBankAccountEv,
    fn: (ba, id) => {
        return id !== null ? ba?.find(s => s.id === id) : null
    },
    target: $selectedBankAccount
})

sample({
    source: $srvBAs,
    clock: selectSrvBankAccountEv,
    fn: (ba, id) => {
        return id !== null ? ba?.find(s => s.id === id) : null
    },
    target: $selectedBankAccount
})

sample({
    source: $srvBaREOTemp,
    clock: selectTempReoBankAccountEv,
    fn: (ba, id) => {
        return id !== null ? ba?.find(s => s.id === id) : null
    },
    target: $selectedBankAccount
})

sample({
    source: {ba: $beneficiaryBAs, prov: $selectedProvider},
    clock: selectBenefBankAccountEv,
    filter: ({prov}) => !!prov && isId(prov?.id),
    fn: ({ba}, id) => {
        return id !== null ? ba?.find(s => s.id === id) : null
    },
    target: $selectedBankAccount
})

sample({
    source: {ba: $newBankAccounts, prov: $selectedProvider},
    clock: selectBenefBankAccountEv,
    filter: ({prov}) => !prov,
    fn: ({ba}, id) => {
        return id !== null ? ba?.find(s => s.id === id) : null
    },
    target: $selectedBankAccount
})

// Create/Edit beneficiary bank accounts
sample({
    source: $selectedProvider,
    clock: [createEditBankAccountFx.doneData, $selectedProvider.updates],
    filter: (prov) => !!prov && prov?.is_beneficiary === true,
    fn: (prov) => prov?.id,
    target: getBeneficiaryBankAccountsFx
})

sample({
    source: $selectedProvider,
    clock: [getBenefBAsEv, createEditBankAccountFx.doneData],
    filter: (prov) => !!prov,
    fn: (prov) => prov?.id,
    target: getBeneficiaryBankAccountsFx
})

// for edit
sample({
    source: $selectedProvider,
    clock: addBenefBAEv,
    filter: (provider) => !!provider && provider?.id > 0,
    fn: (provider, payload) => {
        return  [{operation: 'create', type: 'bank_account', value: {...cleanUpPayload('bank_account', {...payload, provider_id: provider?.id}), id: -1}}]
    },
    target: createEditBankAccountFx
})

sample({
    source: {ba: $selectedBankAccount, provider: $selectedProvider},
    clock: editBenefBAEv,
    filter: ({ba, provider}) => !!provider && ba?.id > 0,
    fn: ({ba}, payload) => {
        return [{
            operation: 'update',
            type: 'bank_account',
            value: {
                object_id: ba.id,
                ...cleanUpPayload('bank_account', payload)
            }
        }]
    },
    target: createEditBankAccountFx
})

sample({
    source: $selectedProvider,
    clock: deleteBenefBAEv,
    filter: (prov) => !!prov && prov?.id >= 0,
    fn: (prov, id) => {
        return  [{operation: 'delete', type: 'bank_account', value: {id}}]
    },
    target: createEditBankAccountFx
})

// for create
sample({
    source: {provider: $selectedProvider, bankAccs: $newBankAccounts},
    clock: addBenefBAEv,
    filter: ({provider}) => !provider,
    fn: ({provider, bankAccs}, payload) => {
        const newState = [...bankAccs]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.findLast(i => i.id < 0)
            newState.unshift({...cleanUpPayload('bank_account', payload), id: lastNew.id - 1, provider_id: provider?.id ?? -1})
        } else {
            newState.unshift({...cleanUpPayload('bank_account', payload), id: -2, provider_id: provider?.id ?? -1})
        }
        return newState
    },
    target: $newBankAccounts
})

sample({
    source: {ba: $selectedBankAccount, prov: $selectedProvider, defAccs: $newBankAccounts},
    clock: editBenefBAEv,
    filter: ({prov}) => !prov,
    fn: ({ba, defAccs}, payload) => {
        const newState = [...defAccs]
        const idx = newState.findIndex(i => i.id === ba.id)
        newState[idx] = {...newState[idx], ...payload}
        return newState
    },
    target: $newBankAccounts
})

sample({
    source: {accs: $newBankAccounts, prov: $selectedProvider},
    clock: deleteBenefBAEv,
    filter: ({prov}) => !prov,
    fn: ({accs}, id) => {
        const newState = [...accs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $newBankAccounts
})

// Create new account then add it as default in prov edit ---
const newDefaultBACreated = combineEvents({events: {create: addNewDefaultProvBAEv, response: createEditBankAccountFx.doneData}, reset: $providerDefaultBankAccounts.updates})
// for edit
sample({
    source: $selectedProvider,
    clock: addNewDefaultProvBAEv,
    filter: (provider, payload) => !!provider && provider?.id > 0 && payload.bank_account_id < 0,
    fn: (provider, payload) => {
        return  [{operation: 'create', type: 'bank_account', value: {...cleanUpPayload('bank_account', payload), id: -1}}]
    },
    target: createEditBankAccountFx
})

sample({
    source: $selectedProvider,
    clock: addDefaultProvBAEv,
    filter: (provider, payload) => provider?.id > 0 && payload.bank_account_id > 0,
    fn: (provider, payload) => {
        return [{
            operation: 'create',
            type: 'bank_account_default',
            update_existed: true,
            value: {
                provider_id: provider?.id,
                bank_account_id: payload?.bank_account_id,
                date_from: dayjs(payload.date_from).format(),
                id: -1
            }
        }]
    },
    target: createEditDefaultBankAccountFx
})

sample({
    source: $selectedProvider,
    clock: newDefaultBACreated,
    filter: (provider) => !!provider && provider?.id >= 0,
    fn: (provider, {create, response}) => {
        const bankAcc = response?.[0]?.value
        return [{
            operation: 'create',
            type: 'bank_account_default',
            update_existed: true,
            value: {
                provider_id: provider?.id,
                bank_account_id: bankAcc?.id,
                date_from: dayjs(create.date_from).format(),
                id: -1
            }
        }]
    },
    target: createEditDefaultBankAccountFx
})

sample({
    source: {ba: $selectedBankAccount, provider: $selectedProvider},
    clock: editDefaultProvBAEv,
    filter: ({ba, provider}, payload) => !!provider && ba?.id > 0 && !!payload.date_from,
    fn: ({ba}, payload) => {
        return [{
            operation: 'update',
            type: 'bank_account_default',
            update_existed: true,
            value: {
                object_id: ba.id,
                ...formatBankAcc(payload)
            }
        }]
    },
    target: createEditDefaultBankAccountFx
})

sample({
    source: $selectedProvider,
    clock: deleteDefaultBAEv,
    filter: (prov) => !!prov && prov?.id >= 0,
    fn: (prov, id) => {
        return  [{operation: 'delete', type: 'bank_account_default', value: {id}}]
    },
    target: createEditDefaultBankAccountFx
})

// for create
sample({
    source: {provider: $selectedProvider, bankAccs: $newBankAccounts},
    clock: addNewDefaultProvBAEv,
    filter: ({provider}) => !provider,
    fn: ({provider, bankAccs}, payload) => {
        const newState = [...bankAccs]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.findLast(i => i.id < 0)
            newState.unshift({...cleanUpPayload('bank_account', payload), id: lastNew.id - 1, provider_id: provider?.id ?? -1})
        } else {
            newState.unshift({...cleanUpPayload('bank_account', payload), id: -2, provider_id: provider?.id ?? -1})
        }
        return newState
    },
    target: $newBankAccounts
})

sample({
    source: {provider: $selectedProvider, defAccs: $providerDefaultBankAccounts, newBAs: $newBankAccounts},
    clock: addNewDefaultProvBAEv,
    filter: ({provider}, payload) => !provider && payload.bank_account_id < 0,
    fn: ({provider, defAccs, newBAs}, payload) => {
        const newState = [...defAccs]
        const lastNew = newState.findLast(i => i.id < 0)
        const lastNewBA = newBAs.findLast(i => i.id < 0)
        newState.push({
            provider_id: -1,
            id: lastNew ? lastNew.id - 1 : -1,
            bank_account_id: lastNewBA?.id,
            bank_id: payload.bank_id,
            account_number: payload?.account_number,
            date_from: dayjs(payload.date_from).format(),
        })
        return newState
    },
    target: $providerDefaultBankAccounts
})

sample({
    source: {provider: $selectedProvider, defAccs: $providerDefaultBankAccounts, newBAs: $newBankAccounts, existBAs: $bankAccountsByProvider},
    clock: addDefaultProvBAEv,
    filter: ({provider}, payload) => !provider && payload.bank_account_id >= 0,
    fn: ({provider, defAccs, existBAs, newBAs}, payload) => {
        const newState = [...defAccs]
        const lastNew = newState.findLast(i => i.id < 0)
        let ba
        if (payload?.bank_account_id < 0) {
            ba = newBAs.find(i => i.id === payload?.bank_account_id)
        } else if (payload?.bank_account_id >= 0) {
            ba = existBAs.find(i => i.id === payload?.bank_account_id)
        }
        newState.push({
            provider_id: -1,
            id: lastNew ? lastNew.id - 1 : -1,
            bank_account_id: payload?.bank_account_id,
            account_number: ba?.account_number,
            bank_id: ba?.bank_id,
            date_from: dayjs(payload.date_from).format(),
        })
        return newState
    },
    target: $providerDefaultBankAccounts
})

sample({
    source: {ba: $selectedBankAccount, prov: $selectedProvider, defAccs: $providerDefaultBankAccounts},
    clock: editDefaultProvBAEv,
    filter: ({prov}) => !prov,
    fn: ({ba, defAccs}, payload) => {
        const newState = [...defAccs]
        const idx = newState.findIndex(i => i.id === ba.id)
        newState[idx] = {...newState[idx], date_from: payload.date_from}
        return newState
    },
    target: $providerDefaultBankAccounts
})

sample({
    source: {accs: $providerDefaultBankAccounts, prov: $selectedProvider},
    clock: deleteDefaultBAEv,
    filter: ({prov}) => !prov,
    fn: ({accs}, id) => {
        const newState = [...accs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerDefaultBankAccounts
})
// ---

sample({
    clock: $selectedProvForBAs.updates,
    filter: prov => !!prov && prov >= 0,
    target: getProviderBankAccountsFx
})

sample({
    clock: $selectedBeneficiary.updates,
    filter: (id) => id !== null && id !== undefined,
    fn: (id) => id,
    target: getProviderBankAccountsFx
})

// Srv bank accs ---
const newSrvBACreated = combineEvents({events: {create: addNewProvSrvBAEv, response: createEditBankAccountFx.doneData}, reset: $srvBAs.updates})

sample({
    source: {prov: $selectedProvider, srv: $selectedProvService},
    clock: [$selectedProvService.updates, createEditSrvBankAccountFx.doneData],
    filter: ({prov, srv}) => !!prov && prov?.id >= 0 && !!srv && srv?.id >= 0,
    fn: ({prov, srv}) => {
        return {provider_id: prov.id, service_id: srv.service_id}
    },
    target: getProviderServiceBankAccountsFx
})

// for edit
sample({
    source: {provider: $selectedProvider, service: $selectedProvService},
    clock: addNewProvSrvBAEv,
    filter: ({provider, service}, payload) => !!provider && provider?.id >= 0 && !!service && service?.id >= 0 && payload.bank_account_id < 0,
    fn: (provider, payload) => {
        return  [{operation: 'create', type: 'bank_account', value: {...cleanUpPayload('bank_account', payload), id: -1}}]
    },
    target: createEditBankAccountFx
})

sample({
    source: {provider: $selectedProvider, service: $selectedProvService},
    clock: newSrvBACreated,
    filter: ({provider, service}) => !!provider && provider?.id >= 0 && !!service && service?.id >= 0,
    fn: ({provider, service}, {create, response}) => {
        const bankAcc = response?.[0]?.value
        return [{
            operation: 'create',
            type: 'provider_service_bank_account',
            update_existed: true,
            value: {
                provider_service_id: service.id,
                bank_account_id: bankAcc?.id,
                date_from: dayjs(create.date_from).format(),
                date_to: create?.date_to ? dayjs(payload.date_to).format() : null,
                transfer_percent: create.transfer_percent,
                id: -1
            }
        }]
    },
    target: createEditSrvBankAccountFx
})

sample({
    source: {provider: $selectedProvider, service: $selectedProvService},
    clock: addProvSrvBAEv,
    filter: ({provider, service}, payload) => provider?.id > 0 && service?.id > 0 && payload.bank_account_id > 0,
    fn: ({provider, service}, payload) => {
        return [{
            operation: 'create',
            type: 'provider_service_bank_account',
            update_existed: true,
            value: {
                provider_service_id: service.id,
                bank_account_id: payload?.bank_account_id,
                date_from: dayjs(payload.date_from).format(),
                date_to: payload?.date_to ? dayjs(payload.date_to).format() : null,
                transfer_percent: payload.transfer_percent,
                id: -1
            }
        }]
    },
    target: createEditSrvBankAccountFx
})

sample({
    source: {ba: $selectedBankAccount, srv: $selectedProvService},
    clock: editProvSrvBAEv,
    filter: ({ba, srv}, payload) => !!srv && srv?.id >= 0 && ba?.id > 0 && !!payload.date_from,
    fn: ({ba}, payload) => {
        return [{
            operation: 'update',
            type: 'provider_service_bank_account',
            update_existed: true,
            value: {
                object_id: ba.id,
                date_from: payload?.date_from ? dayjs(payload.date_from).format() : null,
                date_to: payload?.date_to ? dayjs(payload.date_to).format() : null,
                transfer_percent: payload.transfer_percent
            }
        }]
    },
    target: createEditSrvBankAccountFx
})

sample({
    source: $selectedProvService,
    clock: deleteSrvBankAccountEv,
    filter: srv => !!srv && srv?.id >= 0,
    fn: (srv, id) => {
        return  [{operation: 'delete', type: 'provider_service_bank_account', value: {id}}]
    },
    target: createEditSrvBankAccountFx
})

// for create new srv
sample({
    source: {provider: $selectedProvider, srv: $selectedProvService, newBAs: $newBankAccounts},
    clock: addNewProvSrvBAEv,
    filter: ({provider, srv}, payload) => !!provider && provider?.id > 0 && !srv && payload.bank_account_id < 0,
    fn: ({provider, newBAs}, payload) => {
        const newState = [...newBAs]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.findLast(i => i.id < 0)
            newState.unshift({...cleanUpPayload('bank_account', payload), id: lastNew.id - 1, provider_id: provider?.id ?? -1})
        } else {
            newState.unshift({...cleanUpPayload('bank_account', payload), id: -2, provider_id: provider?.id ?? -1})
        }
        return newState
    },
    target: $newBankAccounts
})

sample({
    source: {provider: $selectedProvider, service: $selectedProvService, srvBAs: $srvBAs, newBAs: $newBankAccounts},
    clock: addNewProvSrvBAEv,
    filter: ({provider, service}, payload) => provider?.id > 0 && !service && payload.bank_account_id < 0,
    fn: ({provider, service, srvBAs, newBAs}, payload) => {
        const newState = [...srvBAs]
        const lastNew = newState.findLast(i => i.id < 0)
        const lastNewBA = newBAs.findLast(i => i.id < 0)
        newState.push({
            id: lastNew ? lastNew.id - 1 : -1,
            provider_service_id: service?.id ?? -1,
            bank_account_id: lastNewBA?.id,
            provider_id: payload?.provider_id ?? provider?.id,
            bank_id: payload?.bank_id,
            account_number: payload?.account_number,
            date_from: dayjs(payload.date_from).format(),
            date_to: payload?.date_to ? dayjs(payload.date_to).format() : null,
            transfer_percent: payload.transfer_percent
        })
        return newState
    },
    target: $srvBAs
})

sample({
    source: {provider: $selectedProvider, service: $selectedProvService, srvBAs: $srvBAs, existBAs: $bankAccountsByProvider},
    clock: addProvSrvBAEv,
    filter: ({provider, service}, payload) => provider?.id > 0 && !service && payload.bank_account_id > 0,
    fn: ({provider, service, srvBAs, existBAs}, payload) => {
        const newState = [...srvBAs]
        const lastNew = newState.findLast(i => i.id < 0)
        const ba = existBAs.find(i => i.id === payload.bank_account_id)
        newState.push({
            id: lastNew ? lastNew.id - 1 : -1,
            provider_service_id: service?.id ?? -1,
            provider_id: payload?.provider_id ?? provider?.id,
            bank_account_id: payload?.bank_account_id,
            bank_id: ba?.bank_id,
            account_number: ba?.account_number,
            date_from: dayjs(payload.date_from).format(),
            date_to: payload?.date_to ? dayjs(payload.date_to).format() : null,
            transfer_percent: payload.transfer_percent
        })
        return newState
    },
    target: $srvBAs
})

sample({
    source: {ba: $selectedBankAccount, srvBAs: $srvBAs, service: $selectedProvService},
    clock: editProvSrvBAEv,
    filter: ({ba, service}) => ba?.id < 0 && !service,
    fn: ({ba, srvBAs}, payload) => {
        const newState = [...srvBAs]
        const idx = newState.findIndex(i => i.bank_account_id === ba.id)
        if (idx >= 0) {
            newState.splice(idx, 1, {
                ...newState[idx],
                date_from: payload?.date_from ? dayjs(payload.date_from).format() : null,
                date_to: payload?.date_to ? dayjs(payload.date_to).format() : null,
                transfer_percent: payload.transfer_percent
            })
            return newState
        }
    },
    target: $srvBAs
})

sample({
    source: {srvBAs: $srvBAs, service: $selectedProvService},
    clock: deleteSrvBankAccountEv,
    filter: ({srvBAs, service}) => srvBAs?.length > 0 && !service,
    fn: ({srvBAs}, id) => {
        const newState = [...srvBAs]
        const idx = newState.findIndex(i => i.id === id)
        if (idx >= 0) {
            newState.splice(idx, 1)
            return newState
        }
    },
    target: $srvBAs
})
// ------

// Provider - Service - REO - BA handlers
$providerServBaREOs
    .on(getProviderSrvBaREOsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$srvBaREOTemp.reset([createEditREOBankAccountFx.doneData, $selectedServBaREO.updates])

$selectedServBaREO.reset([$providerServBaREOs.updates, resetServBaREOEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, createEditREOBankAccountFx.doneData])

// for create
sample({
    source: {provider: $selectedProvider, newBAs: $newBankAccounts},
    clock: addNewTempSrvBaREOEv,
    filter: ({provider}, payload) => !!provider && provider?.id > 0 && payload.bank_account_id < 0,
    fn: ({provider, newBAs}, payload) => {
        const newState = [...newBAs]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.findLast(i => i.id < 0)
            newState.unshift({...cleanUpPayload('bank_account', payload), id: lastNew.id - 1, provider_id: provider?.id ?? -1})
        } else {
            newState.unshift({...cleanUpPayload('bank_account', payload), id: -2, provider_id: provider?.id ?? -1})
        }
        return newState
    },
    target: $newBankAccounts
})

sample({
    source: {provider: $selectedProvider, tempBaReos: $srvBaREOTemp, newBAs: $newBankAccounts},
    clock: addNewTempSrvBaREOEv,
    filter: ({provider}, payload) => provider?.id > 0 && payload.bank_account_id < 0,
    fn: ({provider, tempBaReos, newBAs}, payload) => {
        const newState = [...tempBaReos]
        const lastNew = newState.findLast(i => i.id < 0)
        const lastNewBA = newBAs.findLast(i => i.id < 0)
        newState.push({
            id: lastNew ? lastNew.id - 1 : -1,
            bank_account_id: lastNewBA?.id,
            provider_id: provider?.id,
            bank_id: payload?.bank_id,
            account_number: payload?.account_number,
            date_from: dayjs(payload.date_from).format(),
        })
        return newState
    },
    target: $srvBaREOTemp
})

sample({
    source: {provider: $selectedProvider, tempBaReos: $srvBaREOTemp, existBAs: $bankAccountsByProvider},
    clock: addTempSrvBaREOEv,
    filter: ({provider}, payload) => provider?.id > 0 && payload.bank_account_id > 0,
    fn: ({provider, tempBaReos, existBAs}, payload) => {
        const newState = [...tempBaReos]
        const lastNew = newState.findLast(i => i.id < 0)
        const ba = existBAs.find(i => i.id === payload.bank_account_id)
        newState.push({
            id: lastNew ? lastNew.id - 1 : -1,
            bank_account_id: payload?.bank_account_id,
            provider_id: provider?.id,
            bank_id: ba?.bank_id,
            account_number: ba?.account_number,
            date_from: dayjs(payload.date_from).format(),
        })
        return newState
    },
    target: $srvBaREOTemp
})

sample({
    source: {ba: $selectedBankAccount, prov: $selectedProvider, tempAccs: $srvBaREOTemp},
    clock: editTempSrvBaREOEv,
    filter: ({prov}) => !prov,
    fn: ({ba, tempAccs}, payload) => {
        const newState = [...tempAccs]
        const idx = newState.findIndex(i => i.id === ba.id)
        newState[idx] = {...newState[idx], ...formatBankAcc(payload)}
        return newState
    },
    target: $srvBaREOTemp
})

sample({
    source: {accs: $srvBaREOTemp, prov: $selectedProvider},
    clock: deleteServBaREOEv,
    filter: ({prov}) => !prov,
    fn: ({accs}, id) => {
        const newState = [...accs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $srvBaREOTemp
})

sample({
    source: $providerServBaREOs,
    clock: selectServBaREOEv,
    fn: (bareos, id) => bareos.find(s => s.id === id),
    target: $selectedServBaREO
})

sample({
    source: {provider: $selectedProvider, newBAs: $newBankAccounts, tempBAs: $srvBaREOTemp, reos: $multiSelectedReos, allReos: $foundReos},
    clock: addServBaREOEv,
    fn: ({provider, newBAs, tempBAs, reos, allReos}, {type, payload}) => {
        return addNewSrvREO(provider, newBAs, tempBAs, reos, allReos, type, payload)
    },
    target: createEditREOBankAccountFx
})

sample({
    source: {provider: $selectedProvider, selReo: $selectedServBaREO},
    clock: editServBaREOEv,
    filter: ({provider}) => !!provider && provider?.id >= 0,
    fn: ({selReo}, payload) => {
        return [{
            operation: 'update',
            type: 'real_estate_object_provider_service',
            update_existed: true,
            value: {
                object_id: selReo.id,
                ...formatBankAcc(payload)
            }
        }]
    },
    target: createEditREOBankAccountFx
})

sample({
    clock: deleteServBaREOEv,
    fn: (id) => {
        return [{operation: 'delete', type: 'real_estate_object_provider_service', value: {id}}]
    },
    target: createEditREOBankAccountFx
})

// Provider - Agreements handlers
$providerAgreements.on(getProviderAgreementsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, ProviderViewGate.close])

$providerAgreementsInit.on(getProviderAgreementsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, ProviderViewGate.close])

$provAgreementsFiles
    .on(createProviderCompleteEv, (state, payload) => {
        const createdAgreements = payload.response.filter(i => i.type === 'agreement' && i.operation !== 'delete')
        if (createdAgreements.length > 0) {
            return state.map(file => ({file: file.file, number: file.number, agreement_id: createdAgreements.find(a => a.value.number === file.number)?.value?.id}))
        } else return undefined
    })
    .on(editProviderCompleteEv, (state, payload) => {
        const createdAgreements = payload.response.filter(i => i.type === 'agreement' && i.operation !== 'delete')
        if (createdAgreements.length > 0) {
            return state.map(file => ({file: file.file, number: file.number, agreement_id: createdAgreements.find(a => a.value.number === file.number)?.value?.id}))
        } else return undefined
    })
    .reset([uploadAgreementFileFx.doneData, ProviderEditGate.close, ProviderCreateGate.close])

$selectedAgreement.reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

sample({
    clock: downloadProvAgreementFileEv,
    target: downloadAgreementFileFx
})

sample({
    source: $providerAgreements,
    clock: deleteProvAgreementEv,
    fn: (agreements, id) => {
        const newState = [...agreements]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerAgreements
})

sample({
    source: {init: $providerAgreementsInit, deleted: $deletedAgreements},
    clock: $providerAgreements.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedAgreements
})

sample({
    source: $providerAgreements,
    clock: selectProvAgreementEv,
    fn: (agreements, id) => agreements.find(s => s.id === id),
    target: $selectedAgreement
})

sample({
    source: {agreements: $providerAgreements, provider: $selectedProvider},
    clock: addProvAgreementEv,
    fn: ({agreements, provider}, payload) => {
        const prep = {...payload}
        delete prep.file

        const newState = [...agreements]
        if (newState.some(i => i.id < 0)) {
            const lastNew = newState.find(i => i.id < 0)
            newState.unshift({...formatPayload(prep), provider_id: provider?.id ?? -1, id: lastNew.id - 1})
        } else {
            newState.unshift({...formatPayload(prep), provider_id: provider?.id ?? -1, id: -1})
        }
        return newState
    },
    target: $providerAgreements
})

sample({
    source: $provAgreementsFiles,
    clock: addProvAgreementEv,
    filter: (_, payload) => !!payload.file.length > 0,
    fn: (files, payload) => {
        return [...files, {number: payload.number, file: payload.file[0]}]
    },
    target: $provAgreementsFiles
})

sample({
    source: $providerAgreements,
    clock: applyProvAgreementEv,
    fn: (agreements, payload) => {
        const prep = {...payload}
        delete prep.file
        const newState = [...agreements]
        const idx = newState.findIndex(i => i.id === prep.id)
        newState[idx] = {...newState[idx], ...prep, updated: prep.id > 0}
        return newState
    },
    target: $providerAgreements
})

sample({
    source: {files: $provAgreementsFiles, selectedAgr: $selectedAgreement},
    clock: applyProvAgreementEv,
    filter: (_, payload) => !!payload.file,
    fn: ({files, selectedAgr}, payload) => {
        const idx = files.findIndex(i => i.number === payload.number)
        if (idx >= 0) {
            files.splice(idx, 1)
        }
        return [...files, {number: payload.number, file: payload.file, agreement_id: selectedAgr?.id > 0 ? selectedAgr?.id : undefined}]
    },
    target: $provAgreementsFiles
})

sample({
    source: {files: $provAgreementsFiles, agreements: $providerAgreements},
    clock: $providerAgreements.updates,
    fn: ({files, agreements}) => {
        const checkedFiles = [...files]
        for (const value of Object.values(agreements)) {
            if (files.findIndex(i => i.number === value.number) < 0) {
                const idx = checkedFiles.findIndex(i => i.number === value.number)
                if (idx >= 0) {
                    checkedFiles.splice(idx, 1)
                }
            }
        }
        return checkedFiles
    },
    target: $provAgreementsFiles
})

sample({
    source: $provAgreementsFiles,
    clock: [createProviderCompleteEv, editProviderCompleteEv],
    filter: (files) => files.some(i => !!i.file && !!i?.agreement_id),
    fn: (files) => files.filter(i => !!i.file),
    target: uploadAgreementFileFx
})

// Provider - Services handlers
$providerServices
    .on(getProviderServicesFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$providerServicesInit
    .on(getProviderServicesFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedProvService.reset([$providerServices.updates, resetSelectedProvSrvEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv, createEditProvSrvFx.doneData])

sample({
    source: $providerServices,
    clock: selectProvServiceEv,
    fn: (services, id) => services.find(s => s.service_id === id),
    target: $selectedProvService
})

sample({
    source: {provider: $selectedProvider, newBA: $newBankAccounts, newSrvBA: $srvBAs},
    clock: addProvServiceEv,
    filter: ({provider}) => !!provider && provider?.id >= 0,
    fn: ({provider, services, newBA, newSrvBA}, payload) => {
        const actions = []
        let idCounter = -1
        const tarifs = payload.tarifs?.map(t => ({...t, provider_service_id: idCounter})) ?? []
        const coms = payload?.commissions?.map(c => ({...c, provider_service_id: idCounter})).filter(o => o.id !== 'x') ?? []
        const prefs = payload.preferences?.map(p => ({...p, provider_service_id: idCounter})).filter(o => o.id !== 'x') ?? []
        delete payload.tarifs
        delete payload.commissions
        delete payload.preferences

        const checked = prepareSrv(payload)
        if (Object.hasOwn(checked, 'date_to') && !checked?.date_to) {
            checked['date_to'] = provider?.date_to ?? dayjs('2050-01-01').format()
        }

        actions.push({operation: 'create', type: 'provider_service', value: {...checked, id: idCounter, provider_id: provider?.id}})
        idCounter--

        if (tarifs.length > 0) {
            for (const tariff of tarifs) {
                if (tariff.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'tarif',
                        value: {
                            ...tariff,
                            provider_service_id: -1,
                            id: idCounter
                        }
                    })
                    idCounter--
                }
            }
        }

        if (prefs.length > 0) {
            for (const pref of prefs) {
                if (pref.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'provider_service_preference',
                        value: {
                            ...pref,
                            provider_service_id: -1,
                            id: idCounter
                        }
                    })
                    idCounter--
                }
            }
        }

        if (coms.length > 0) {
            for (const com of coms) {
                if (com.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'provider_service_bank_commission',
                        value: {
                            ...com,
                            provider_service_id: -1,
                            id: idCounter
                        }
                    })
                    idCounter--
                }
            }
        }

        const newBAMap = {}
        if (newBA?.length > 0) {
            newBA.forEach(i => {
                actions.push({operation: 'create', type: 'bank_account', value: {...cleanUpPayload('bank_account', i), provider_id: provider?.id, id: idCounter}})
                newBA[i.id] = idCounter
                idCounter--
            })
        }

        if (newSrvBA?.length > 0) {
            newSrvBA.forEach(i => {
                actions.push({
                    operation: 'create',
                    type: 'provider_service_bank_account',
                    value: {
                        provider_service_id: -1,
                        bank_account_id: i?.bank_account_id < 0 ? newBAMap[i?.bank_account_id] : i?.bank_account_id,
                        date_from: dayjs(i.date_from).format(),
                        date_to: i.date_to ? dayjs(i.date_to).format() : null,
                        transfer_percent: i.transfer_percent,
                        id: idCounter
                    }
                })
                idCounter--
            })
        }
        return actions
    },
    target: createEditProvSrvFx
})

sample({
    source: {provider: $selectedProvider, all: $providerServices, selected: $selectedProvService},
    clock: editSrvParamsEv,
    filter: ({provider}) => !!provider,
    fn: ({provider, all, selected}, payload) => {
        const actions = []
        let idCounter = -1

        const tarifs = payload?.tarifs?.map(t => ({...t, provider_service_id: selected.id})) ?? []
        const coms = payload?.commissions?.map(c => ({...c, provider_service_id: selected.id})).filter(o => o.id !== 'x') ?? []
        const prefs = payload?.preferences?.map(p => ({...p, provider_service_id: selected.id})).filter(o => o.id !== 'x') ?? []

        delete payload.tarifs
        delete payload.commissions
        delete payload.preferences

        const diff = {}
        for (const [key, value] of Object.entries(payload)) {
            if (value !== selected[key]) {
                diff[key] = value
            }
        }

        if (Object.hasOwn(diff, 'service_id') || Object.hasOwn(diff, 'date_from') || Object.hasOwn(diff, 'date_to')) {
            notification.warning({message: i18n.t('Внимание!'), description: i18n.t('Был изменен параметр, влияющий на расчеты начислений')})
        }

        if (Object.hasOwn(diff, 'date_to') && !diff?.date_to) {
            diff['date_to'] = provider?.date_to ?? dayjs('2050-01-01').format()
        }

        actions.push({
            operation: 'update',
            type: 'provider_service',
            value: {...diff, object_id: selected.id}
        })


        if (tarifs.length > 0) {
            for (const tariff of tarifs) {
                if (tariff.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'tarif',
                        value: {
                            ...tariff,
                            provider_service_id: selected.id,
                            id: idCounter
                        }
                    })
                    idCounter--
                }
            }
        }

        if (prefs.length > 0) {
            for (const pref of prefs) {
                if (pref.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'provider_service_preference',
                        value: {
                            ...pref,
                            provider_service_id: selected.id,
                            id: idCounter
                        }
                    })
                    idCounter--
                } else if (pref.id > 0 && pref.updated === true) {
                    const initPref = selected.preferences.find(i => i.id === pref.id)
                    if (!initPref) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(pref)) {
                        if (value !== initPref[key]) {
                            diff[key] = value
                        }
                    }
                    actions.push({operation: 'update', type: 'provider_service_preference', value: {...diff, object_id: pref.id}})
                }
            }
        }

        if (coms.length > 0) {
            for (const com of coms) {
                if (com.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'provider_service_bank_commission',
                        value: {
                            ...com,
                            provider_service_id: selected.id,
                            id: idCounter
                        }
                    })
                    idCounter--
                } else if (com.id > 0 && com.updated === true) {
                    const initCom = selected?.commissions?.find(i => i.id === com.id)
                    if (!initCom) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(com)) {
                        if (value !== initCom[key]) {
                            diff[key] = value
                        }
                    }
                    actions.push({operation: 'update', type: 'provider_service_bank_commission', value: {...diff, object_id: com.id}})
                }
            }
        }

        return actions
    },
    target: createEditProvSrvFx
})

sample({
    clock: deleteSrvEv,
    fn: (services, id) => {
        return [{operation: 'delete', type: 'provider_service', value: {id}}]
    },
    target: createEditProvSrvFx
})

// Provider - Service - Acc - handlers
$accountsList.on(getAccountsFx.doneData, (_, payload) => payload.data)
    .reset([resetAccountsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$accountsFilters.on(setAccountsFiltersEv, (state, change) => ({...state, ...change}))
    .reset([resetAccountsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$selectedAccounts.on(selectMultipleAccountsEv, (_, ids) => ids)
    .reset([resetAccountsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$providerServAccs
    .on(getProviderSrvAccsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$provServAccsInit
    .on(getProviderSrvAccsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$newProviderServAccs.on($providerServAccs.updates, (_, payload) => payload.filter(({id}) => id < 0))
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$selectedServAcc.reset([$providerServAccs.updates, resetServAccEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

sample({
    source: $accountsFilters.updates,
    clock: getAccountsEv,
    filter: (filt) => {
        return (isId(filt?.town_id) && isId(filt?.street_id)) || filt?.account_in || filt?.iin_name
    },
    // fn: (filters) => queryString.stringify(filters, {skipEmptyString: true, skipNull: true}),
    fn: (filters) => {
        const newFilters = {
            ...filters,
            town_id: filters.town_id === -1 ? 0 : filters.town_id
        }
        return queryString.stringify(newFilters, {skipEmptyString: true, skipNull: true})
    },
    target: getAccountsFx
})

sample({
    source: $providerServAccs,
    clock: selectServAccEv,
    fn: (accounts, id) => accounts.find(s => s.id === id),
    target: $selectedServAcc
})

sample({
    source: {init: $provServAccsInit, deleted: $deletedServAccs},
    clock: $providerServAccs.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedServAccs
})

sample({
    source: {existing: $providerServAccs, selAccs: $selectedAccounts, allAccs: $accountsList},
    clock: addServAccEv,
    fn: ({existing, selAccs, allAccs}, {type, payload}) => addNewSrvAcc(existing, selAccs, allAccs, type, payload),
    target: $providerServAccs
})

sample({
    source: $providerServAccs,
    clock: applyServAccEv,
    fn: (accounts, payload) => {
        const prep = formatPayload(payload.payload)
        const newState = [...accounts]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...prep, updated: payload.id > 0}
        return newState
    },
    target: $providerServAccs
})

sample({
    source: $providerServAccs,
    clock: deleteServAccEv,
    fn: (tarifs, id) => {
        const newState = [...tarifs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServAccs
})

// Provider - Service - Acc - Tariff - handlers
$providerServAccsTarifs
    .on(getProviderSrvAccsTarifsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])

$provServAccsTarifsInit
    .on(getProviderSrvAccsTarifsFx.doneData, (_, payload) => payload.data)
    .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])

$provServAccsForTarifs.on(getProviderSrvAccsForTarifsFx.doneData, (_, payload) => payload.data)
    .reset([resetSrvAccsForTarifsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$selectedServAccTarif.reset([$providerServAccsTarifs.updates, resetServAccTarifEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$provServAccsFilters.on(setSrvAccForTarifsFilterEv, (state, change) => ({...state, ...change}))
    .reset([resetSrvAccsForTarifsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$selectedSrvAccs.on(selectMultipleSrvAccsForTarifsEv, (_, payload) => payload)
    .reset([resetSrvAccsForTarifsEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])

$embeddedTariffForm.on(setEmbeddedTariffFormEv, (_, payload) => payload)
    .reset([$providerServices.updates])

$viewAPS.on(getViewAPSFx.doneData, (_, payload) => payload.data)
    .reset([ProviderViewGate.close])


sample({
    source: {existing: $provServAccsForTarifs, newAccs: $newProviderServAccs, filters: $provServAccsFilters},
    clock: [applySrvAccForTarifsFiltersEv, $provServAccsForTarifs.updates],
    fn: ({existing, newAccs, filters}) => {
        return [...filterSrvAccs(newAccs, filters), ...existing]
    },
    target: $filteredServAccs
})

sample({
    source: {provider: $selectedProvider, filters: $provServAccsFilters},
    clock: applySrvAccForTarifsFiltersEv,
    filter: ({provider, filters}) => {
        return provider?.id >=0 && isId(filters?.provider_service_id) &&
            ((isId(filters?.town_id) && isId(filters?.street_id)) || filters?.account_in || filters?.iin_name)
    },
    fn: ({provider, filters}) => {
        const query = queryString.stringify(filters, {skipEmptyString: true, skipNull: true})
        return {id: provider.id, filters: query}
    },
    target: getProviderSrvAccsForTarifsFx
})

sample({
    source: $providerServAccsTarifs,
    clock: selectServAccTarifEv,
    fn: (accounts, id) => accounts.find(s => s.id === id),
    target: $selectedServAccTarif
})

sample({
    source: {init: $provServAccsTarifsInit, deleted: $deletedServAccsTarifs},
    clock: $providerServAccsTarifs.updates,
    filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
    fn: ({init, deleted}, current) => {
        const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
        return [...deleted, ...del]
    },
    target: $deletedServAccsTarifs
})

sample({
    source: {existing: $providerServAccsTarifs, selSrvAccs: $selectedSrvAccs, allSrvAccs: $filteredServAccs},
    clock: addServAccTarifEv,
    fn: ({existing, selSrvAccs, allSrvAccs}, {type, payload}) => addNewSrvAccTarifs(existing, selSrvAccs, allSrvAccs, type, payload),
    target: $providerServAccsTarifs
})

sample({
    source: $providerServAccsTarifs,
    clock: applyServAccTarifEv,
    fn: (accounts, payload) => {
        const prep = formatPayload(payload.payload)
        const newState = [...accounts]
        const idx = newState.findIndex(i => i.id === payload.id)
        newState[idx] = {...newState[idx], ...prep, updated: payload.id > 0}
        return newState
    },
    target: $providerServAccsTarifs
})

sample({
    source: $providerServAccsTarifs,
    clock: deleteServAccTarifEv,
    fn: (tarifs, id) => {
        const newState = [...tarifs]
        const idx = newState.findIndex(i => i.id === id)
        newState.splice(idx, 1)
        return newState
    },
    target: $providerServAccsTarifs
})

// General samples
for (const gt of [
    ['view', ProviderEditGate], ['view', ProviderViewGate]
]) {
    const tp = gt[0]
    const gate = gt[1]
    for (const tg of [
        ['provider', getProviderByIdFx],
        ['provider_service', getProviderServicesFx],
        ['bank_account', getProviderBankAccountsFx],
        ['provider', getProviderDefaultBankAccountFx],
        ['agreement', getProviderAgreementsFx],
        ['provider_service_preference', getProviderSrvPrefFx],
        ['provider_service_bank_commission', getProviderSrvComsFx],
        ['real_estate_object_provider_service', getProviderSrvBaREOsFx],
        // ['', getProviderSrvAccsFx],
        // ['', getProviderSrvAccsTarifsFx],
    ]) {
        const ent = tg[0]
        const target = tg[1]
        sample({
            source: {
                perms: $userPerms,
                gate: gate.state,
            },
            clock: [$userPerms, gate.state],
            filter: (data) => {
                const withId = Object.hasOwn(data?.gate, 'id')
                const perms = data?.perms || {}
                const pOk = permitted([`${ent}::${tp}`], perms)
                return withId && pOk
            },
            fn: (data) => +(data?.gate?.id),
            target,
        })
    }
}
// sample({
//     source: {
//         perms: $userPerms,
//         editGate: ProviderEditGate.state,
//         viewGate: ProviderViewGate.state,
//     },
//     clock: [$userPerms, ProviderEditGate.state, ProviderViewGate.state],
//     filter: (data) => {
//         const withId = (Object.hasOwn(data?.editGate, 'id') || Object.hasOwn(data?.viewGate, 'id'))
//         const perms = data?.perms || {}
//         const pOk = permitted(['provider_service::change'], perms)
//         return withId && pOk
//     },
//     fn: (data) => {
//         return +(data?.editGate?.id || data.viewGate?.id)
//     },
//     target: [
//         getProviderByIdFx,
//         getProviderServicesFx,
//         getProviderBankAccountsFx,
//         getProviderDefaultBankAccountFx,
//         getProviderAgreementsFx,
//         getProviderSrvPrefFx,
//         getProviderSrvComsFx,
//         getProviderSrvBaREOsFx,
//         getProviderSrvAccsFx,
//         getProviderSrvAccsTarifsFx
//     ]
// })

sample({
    source: $selectedProvider,
    clock: [createEditDefaultBankAccountFx.doneData],
    filter: prov => !!prov && prov.id >= 0,
    fn: (prov) => prov.id,
    target: getProviderDefaultBankAccountFx
})

sample({
    source: $selectedProvider,
    clock: createEditProvSrvFx.doneData,
    filter: prov => !!prov && prov.id >= 0,
    fn: (prov) => prov.id,
    target: [getProviderServicesFx]
})

sample({
    source: $selectedProvider,
    clock: createEditREOBankAccountFx.doneData,
    filter: prov => !!prov && prov.id >= 0,
    fn: (prov) => prov.id,
    target: getProviderSrvBaREOsFx
})

sample({
    source: ProviderListGate.state,
    clock: [ProviderListGate.state.updates, deleteProviderCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getProvidersListFx
})

sample({
    source: ProviderKassaGate.state,
    clock: [ProviderKassaGate.state, deleteProvKassaFx.doneData],
    filter: (gate) => {
        return +gate.id
    },
    fn: (gate) => +gate.id,
    target: getProviderKassaFx
})

sample({
    clock: deleteProviderEv,
    fn: (id) => [{operation: 'delete', type: 'provider', value: {id}}],
    target: submitGlobalUpdateEv
})

sample({
    source: {
        services: $providerServices,
        bankAcs: $newBankAccounts,
        defaultBAs: $providerDefaultBankAccounts,
        agreements: $providerAgreements,
    },
    clock: submitCreateProviderEv,
    fn: ({services, bankAcs, defaultBAs, agreements}, provider) => {
        const actions = []
        let idCounter = -1

        const initBAIds = Object.fromEntries(bankAcs.map(i => [i.id, null]))

        const prepProvider = formatPayload(provider)

        if (!prepProvider['date_to']) {
            prepProvider['date_to'] = dayjs('2050-01-01').startOf('day').format()
        }

        actions.push({operation: 'create', type: 'providers', value: {...prepProvider, id: idCounter}})
        idCounter--

        if (bankAcs.length > 0) {
            for (const bankAcc of bankAcs) {
                actions.push({operation: 'create', type: 'bank_account', value: {...cleanUpPayload('bank_account', bankAcc), id: idCounter}})
                initBAIds[bankAcc.id] = idCounter
                idCounter--
            }
        }

        if (defaultBAs?.length > 0) {
            for (const bankAcc of defaultBAs) {
                actions.push({
                    operation: 'create',
                    type: 'bank_account_default',
                    value: {
                        date_from: dayjs(bankAcc.date_from).format(),
                        id: idCounter,
                        provider_id: -1,
                        bank_account_id: initBAIds[bankAcc.bank_account_id]
                    }
                })
                idCounter--
            }
        }

        if (agreements.length > 0) {
            for (const agreement of agreements) {
                actions.push({operation: 'create', type: 'agreement', value: {...agreement, id: idCounter}})
                idCounter--
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {
        current: $selectedProvider,
        agreements: $providerAgreements,
        agreementsInit: $providerAgreementsInit,
        deletedAgreements: $deletedAgreements,
        bareos: $providerServBaREOs,
        accs: $providerServAccs,
        accsInit: $provServAccsInit,
        newAccs: $newProviderServAccs,
        deletedAccs: $deletedServAccs,
        accTarifs: $providerServAccsTarifs,
        accTarifsInit: $provServAccsTarifsInit,
        deletedAccTarifs: $deletedServAccsTarifs
    },
    clock: submitEditProviderEv,
    fn: ({
             current,
             agreements,
             agreementsInit,
             deletedAgreements,
             bareos,
             accs,
             accsInit,
             newAccs,
             deletedAccs,
             accTarifs,
             accTarifsInit,
             deletedAccTarifs
         }, provider) => {
        const actions = []
        let idCounter = -1

        const initAccsIds = Object.fromEntries(newAccs.map(i => [i.id, null]))

        const providerDiff = {}

        for (const [key, value] of Object.entries(provider)) {
            if (key.includes('date') && value) {
                if (dayjs(current[key]).startOf('day').format() !== dayjs(value).startOf('day').format()) {
                    providerDiff[key] = dayjs(value).format()
                }
            } else {
                if (current[key] !== value) {
                    if (current[key] === null && (value === undefined || value === null)) {
                        continue
                    }
                    providerDiff[key] = value
                }
            }
        }

        const prepDiff = {...formatPayload(providerDiff, 'edit')}

        if (Object.hasOwn(prepDiff, 'date_to') && !prepDiff['date_to'] && !!current['date_to']) {
            if (dayjs(current['date_to']).startOf('day').format() === dayjs('2050-01-01').startOf('day').format()) {
                delete prepDiff['date_to']
            } else {
                prepDiff['date_to'] = dayjs('2050-01-01').startOf('day').format()
            }
        }

        if (Object.hasOwn(prepDiff, 'date_from')
            || Object.hasOwn(prepDiff, 'date_to')
            || Object.hasOwn(prepDiff, 'is_not_accrued')
            || Object.hasOwn(prepDiff, 'is_not_balance_transfer')
            || Object.hasOwn(prepDiff, 'not_accrued_from')
            || Object.hasOwn(prepDiff, 'not_accrued_to')
            || Object.hasOwn(prepDiff, 'not_balance_transfer_from')
            || Object.hasOwn(prepDiff, 'not_balance_transfer_to')
        ) {
            notification.warning({message: i18n.t('Внимание!'), description: i18n.t('Был изменен параметр, влияющий на расчеты начислений')})
        }

        if (Object.keys(prepDiff).length > 0) {
            actions.push({operation: 'update', type: 'providers', value: {...prepDiff, object_id: current.id}})
        }

        if (agreements.length > 0) {
            for (const agreement of agreements) {
                if (agreement.id < 0) {
                    actions.push({operation: 'create', type: 'agreement', value: {...agreement, id: idCounter}})
                    idCounter--
                } else if (agreement.id > 0 && agreement.updated === true) {
                    const initAg = agreementsInit.find(i => i.id === agreement.id)
                    if (!initAg) continue

                    const diff = {}
                    for (const [key, value] of Object.entries(agreement)) {
                        if (value !== initAg[key]) {
                            diff[key] = value
                        }
                    }
                    actions.push({operation: 'update', type: 'agreement', value: {...diff, object_id: agreement.id}})
                }
            }
        }

        if (bareos.length > 0) {
            for (const baREO of bareos) {
                if (baREO.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'real_estate_object_provider_service',
                        value: {
                            ...cleanUpPayload('real_estate_object_provider_service', baREO),
                            provider_service_id: baREO.provider_service_id > 0 ? baREO.provider_service_id : initSrvIds?.[baREO.provider_service_id] ?? null,
                            bank_account_id: baREO.bank_account_id > 0 ? baREO.bank_account_id : initBAIds?.[baREO.bank_account_id] ?? null,
                            id: idCounter
                        }
                    })
                    idCounter--
                }
                // else if (baREO.id > 0 && baREO.updated === true) {
                //     const initBaREO = bareosInit.find(i => i.id === baREO.id)
                //     if (!initBaREO) continue
                //     const diff = {}
                //     for (const [key, value] of Object.entries(baREO)) {
                //         if (value !== initBaREO[key]) {
                //             diff[key] = value
                //         }
                //         if (key === 'provider_service_id' && value < 0) {
                //             diff[key] = initSrvIds?.[value]
                //         }
                //     }
                //     actions.push({operation: 'update', type: 'real_estate_object_provider_service', value: {
                //         ...cleanUpPayload('real_estate_object_provider_service', diff),
                //         bank_account_id: baREO.bank_account_id > 0 ? baREO.bank_account_id : initBAIds?.[baREO.bank_account_id] ?? null,
                //         object_id: baREO.id
                //     }})
                // }
            }
        }

        if (accs.length > 0) {
            for (const acc of accs) {
                // for (const acc of newAccs) {
                if (acc.id > 0 && acc.updated === true) {
                    const initAcc = accsInit.find(i => i.id === acc.id)
                    if (!initAcc) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(acc)) {
                        if (value !== initAcc[key]) {
                            diff[key] = value
                        }
                    }
                    actions.push({operation: 'update', type: 'account_provider_service', value: {...cleanUpPayload('account_provider_service', diff), object_id: acc.id}})
                }
            }
        }

        if (newAccs.length > 0) {
            for (const acc of newAccs) {
                if (acc.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'account_provider_service',
                        value: {
                            ...cleanUpPayload('account_provider_service', acc),
                            provider_service_id: acc.provider_service_id,
                            id: idCounter
                        }
                    })
                    initAccsIds[acc.id] = idCounter
                    idCounter--
                }
            }
        }

        if (accTarifs.length > 0) {
            for (const accTarif of accTarifs) {
                if (accTarif.id < 0) {
                    actions.push({
                        operation: 'create',
                        type: 'account_service_tarif',
                        value: {
                            ...cleanUpPayload('account_service_tarif', accTarif),
                            account_provider_service_id: accTarif.account_provider_service_id > 0 ? accTarif.account_provider_service_id : initAccsIds?.[accTarif.account_provider_service_id] ?? null,
                            tarif_id: accTarif.tarif_id,
                            id: idCounter
                        }
                    })
                    idCounter--
                } else if (accTarif.id > 0 && accTarif.updated === true) {
                    const initAccTarif = accTarifsInit.find(i => i.id === accTarif.id)
                    if (!initAccTarif) continue
                    const diff = {}
                    for (const [key, value] of Object.entries(accTarif)) {
                        if (value !== initAccTarif[key]) {
                            diff[key] = value
                        }
                        if (key === 'account_provider_service_id' && value < 0) {
                            diff[key] = initAccsIds?.[value]
                        }
                    }
                    actions.push({operation: 'update', type: 'account_service_tarif', value: {...cleanUpPayload('account_service_tarif', diff), object_id: accTarif.id}})
                }
            }
        }

        if (deletedAgreements.length > 0) {
            for (const id of deletedAgreements) {
                actions.push({operation: 'delete', type: 'agreement', value: {id}})
            }
        }

        if (deletedAccs.length > 0) {
            for (const id of deletedAccs) {
                actions.push({operation: 'delete', type: 'account_provider_service', value: {id}})
            }
        }

        if (deletedAccTarifs.length > 0) {
            for (const id of deletedAccTarifs) {
                actions.push({operation: 'delete', type: 'account_service_tarif', value: {id}})
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})


sample({
    clock: getViewAPSEv,
    filter: ({provider_id}) => !!provider_id,
    fn: (data) => data,
    target: getViewAPSFx,
})



// Provider - Services - Tariffs handlers
// sample({
//     source: $providerServices,
//     clock: addServTariffEv,
//     fn: (services, {srv_id, payload}) => {
//         const newState = [...services]
//         const srvIdx = newState.findIndex(i => i.id === srv_id)
//
//         if (srvIdx !== -1) {
//             if (newState[srvIdx]?.tarifs.some(i => i.id < 0)) {
//                 const lastNew = newState[srvIdx]?.tarifs.findLast(i => i.id < 0)
//                 newState[srvIdx].tarifs.push({...payload, id: lastNew.id - 1})
//             } else {
//                 newState[srvIdx].tarifs.push({...payload, id: -1})
//             }
//         }
//         return newState
//     },
//     target: $providerServices
// })
//
// // Provider - Service - Preferences handlers
// $providerServPrefs
//     .on(getProviderSrvPrefFx.doneData, (_, payload) => payload.data)
//     .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])
//
// $provServPrefsInit
//     .on(getProviderSrvPrefFx.doneData, (_, payload) => payload.data)
//     .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])
//
// $selectedServPref.reset([$providerServPrefs.updates, resetServPrefEv, ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs, editProviderCompleteEv])
//
// sample({
//     source: $providerServPrefs,
//     clock: selectServPrefEv,
//     fn: (services, id) => services.find(s => s.id === id),
//     target: $selectedServPref
// })
//
// sample({
//     source: {init: $provServPrefsInit, deleted: $deletedServPrefs},
//     clock: $providerServPrefs.updates,
//     filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
//     fn: ({init, deleted}, current) => {
//         const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
//         return [...deleted, ...del]
//     },
//     target: $deletedServPrefs
// })
//
// sample({
//     source: $providerServPrefs,
//     clock: addServPrefEv,
//     fn: (prefs, payload) => {
//         const newState = [...prefs]
//         if (newState.some(i => i.id < 0)) {
//             const lastNew = newState.find(i => i.id < 0)
//             newState.unshift({...payload, id: lastNew.id - 1})
//         } else {
//             newState.unshift({...payload, id: -1})
//         }
//         return newState
//     },
//     target: $providerServPrefs
// })
//
// sample({
//     source: $providerServPrefs,
//     clock: applyServPrefEv,
//     fn: (prefs, payload) => {
//         const newState = [...prefs]
//         const idx = newState.findIndex(i => i.id === payload.id)
//         newState[idx] = {...newState[idx], ...payload, updated: payload.id > 0}
//         return newState
//     },
//     target: $providerServPrefs
// })
//
// sample({
//     source: $providerServPrefs,
//     clock: deleteServPrefEv,
//     fn: (prefs, id) => {
//         const newState = [...prefs]
//         const idx = newState.findIndex(i => i.id === id)
//         newState.splice(idx, 1)
//         return newState
//     },
//     target: $providerServPrefs
// })
//
// // Provider - Service - Bank Commission handlers
// $providerServComs
//     .on(getProviderSrvComsFx.doneData, (_, payload) => payload.data)
//     .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close, resetAfterCreationAttrs])
//
// $provServComsInit
//     .on(getProviderSrvComsFx.doneData, (_, payload) => payload.data)
//     .reset([ProviderCreateGate.close, ProviderEditGate.close, ProviderViewGate.close])
//
// $selectedServCom.reset([$providerServComs.updates, resetServComEv])
//
// sample({
//     source: $providerServComs,
//     clock: selectServComEv,
//     fn: (services, id) => services.find(s => s.id === id),
//     target: $selectedServCom
// })
//
// sample({
//     source: {init: $provServComsInit, deleted: $deletedServComs},
//     clock: $providerServComs.updates,
//     filter: ({init}, current) => !init.every((i) => current.some(c => i.id === c.id)),
//     fn: ({init, deleted}, current) => {
//         const del = init.filter(i => !current.some(c => i.id === c.id)).map(i => i.id)
//         return [...deleted, ...del]
//     },
//     target: $deletedServComs
// })
//
// sample({
//     source: $providerServComs,
//     clock: addServComEv,
//     fn: (comms, payload) => {
//         const newState = [...comms]
//         if (newState.some(i => i.id < 0)) {
//             const lastNew = newState.find(i => i.id < 0)
//             newState.unshift({...payload, id: lastNew.id - 1})
//         } else {
//             newState.unshift({...payload, id: -1})
//         }
//         return newState
//     },
//     target: $providerServComs
// })
//
// sample({
//     source: $providerServComs,
//     clock: applyServComEv,
//     fn: (comms, payload) => {
//         const newState = [...comms]
//         const idx = newState.findIndex(i => i.id === payload.id)
//         newState[idx] = {...newState[idx], ...payload, updated: payload.id > 0}
//         return newState
//     },
//     target: $providerServComs
// })
//
// sample({
//     source: $providerServComs,
//     clock: deleteServComEv,
//     fn: (comms, id) => {
//         const newState = [...comms]
//         const idx = newState.findIndex(i => i.id === id)
//         newState.splice(idx, 1)
//         return newState
//     },
//     target: $providerServComs
// })
