import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getBankApiAddressListFx = createEffect()
    .use(async (filters) => {
        return (await api().get(`/bank_api_address/${filters.length > 0 ? `?${filters}` : ''}`)).data
    })

export const getBankApiAddressByIdFx = createEffect()
    .use(async (id) => (await api().get(`/bank_api_address/${id}`)).data)
