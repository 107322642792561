import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $tasksFilters,
    changeTasksFilterEv,
    resetTasksFilterEv
} from '../../models/tasksGlobalModel';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import {DatePicker, Select} from 'antd';
import {$dictsSelects} from '../../models/infoModel/index.js';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {useEffect} from 'react';
import dayjs from 'dayjs';
import {setGenerateTypeEv} from "../../models/taskAllPdfModel/index.js";

export default function TasksFilters() {
    const {t} = useTranslation()
    const filters = useUnit($tasksFilters)
    const changeFilters = useUnit(changeTasksFilterEv)
    const resetFilters = useUnit(resetTasksFilterEv)

    const usersOpts = useStoreMap($dictsSelects, sel => sel.users)

    const setGenerateType = useUnit(setGenerateTypeEv)

    const isAllPdf = window.location.href.includes('tasks/all')

    const statusOpts = [
        {label: t('Все'), value: null},
        {label: t('taskStatuses.new'), value: 'new'},
        {label: t('taskStatuses.pending'), value: 'pending'},
        {label: t('taskStatuses.success'), value: 'success'},
        {label: t('taskStatuses.revoked'), value: 'revoked'},
        {label: t('taskStatuses.error'), value: 'error'},
        {label: t('taskStatuses.processing'), value: 'processing'},
    ]

    const generateTypeOpts = [
        {label: t('Все'), value: 'generate_epd'},
        {label: t('По участкам'), value: 'sector'},
        {label: t('По контролерам'), value: 'inspector'},
        {label: t('По ОН'), value: 'reo'},
        {label: t('По ЛС'), value: 'account'},
    ]

    const [form] = Form.useForm()

    const onApply = (values) => {
        delete values.name
        const result = []
        for (const [key, value] of Object.entries(values)) {
            if (!value) continue
            if (key === 'created_at') {
                result.push({key: `${key}_from`, value: value[0].format('YYYY-MM-DD')})
                result.push({key: `${key}_to`, value: value[1].format('YYYY-MM-DD')})
            } else {
                result.push({key, value: values[key]})
            }
        }
        changeFilters(result)
    }

    useEffect(() => {
        const payload = {}
        for (const [key, value] of Object.entries(filters)) {
            payload[key] = value
        }
        if (payload?.created_at_from && payload?.created_at_to) {
            payload.created_at = [dayjs(payload.created_at_from), dayjs(payload.created_at_to)]
            delete payload.created_at_from
            delete payload.created_at_to
        }
        form.setFieldsValue(payload)
    }, [filters])

    return <Card title={t('Фильтрация')} size={'small'} style={{marginBottom: 16}}>
        <Form form={form} layout={'vertical'} onFinish={onApply}>
            <Row justify={'space-between'} gutter={[16, 16]}>
                <Col span={6}>
                    <Form.Item name={'status'} label={t('Статус')}>
                        <Select options={statusOpts}
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        />
                    </Form.Item>
                </Col>
                {/*     */}
                {
                    isAllPdf && <Col span={6}>
                        <Form.Item name={'name'} label={t('Тип объектов')}>
                            <Select options={generateTypeOpts}
                                    allowClear
                                    showSearch
                                    onChange={(v) => setGenerateType(v)}
                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            />
                        </Form.Item>
                    </Col>
                }


                <Col span={6}>
                    <Form.Item name={'created_by'} label={t('Кем начата')}>
                        <Select options={usersOpts}
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={'created_at'} label={t('Когда начата')}>
                        <DatePicker.RangePicker style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Row>
            <ButtonGroup>
                <Button type={'primary'} onClick={() => {form.submit()}}>{t('Применить')}</Button>
                <Button onClick={() => {
                    form.resetFields()
                    resetFilters()
                }}
                >
                    {t('Сбросить')}
                </Button>
            </ButtonGroup>
        </Row>
    </Card>
}
