import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap} from 'effector-react';
import {$dictsSelects} from '../../models/infoModel/index.js';
import {isId} from '../../utils/helpers.js';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {filterOption} from '../../utils/selectUtils.jsx';

const defaultSizes = {
    town: 3,
    town_distr: 4,
    street: 5,
    house: 5,
    corpus: 1,
    flat: 1,
    account: 1,
    iin: 1,
    actions: 3,
}

const defaultSizesNoButtons = {
    town: 3,
    town_distr: 4,
    street: 4,
    house: 2,
    corpus: 1,
    flat: 2,
    account: 6,
    iin: 2,
    actions: 0,
}


export default function AccountSearchForm(
    {
        vertical,
        single,
        extForm,
        extButtons,
        onlyItems,
        itemsAsRow,
        styles,
        isCard,
        onSearch,
        onSelect,
        isCombined,
        sizes,
        houseNonRequired,
    }
) {
    const {t} = useTranslation()
    const [accountSearchForm] = Form.useForm()

    const noButtons = isCombined || extButtons
    const fSizes = {...(noButtons ? defaultSizesNoButtons : defaultSizes), ...(sizes || {})}

    const onSubmit = (values) => {
        if (single) {
            onSelect && onSelect(values)
        } else if (!extForm) {
            onSearch && onSearch(values)
        }
    }

    const onValuesChange = (cur, _all) => {
        if (Object.hasOwn(cur, 'town_id')) {
            extForm && !onlyItems
                ? extForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus', 'flat_in'])
                : accountSearchForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus', 'flat_in'])
        }
    }

    if (onlyItems) {
        return isCard ? <Card title={single ? t('Выбор ЛС') : t('Поиск ЛС')} size={'small'}>
                <FormItems single={single}
                           vertical={vertical}
                           inRow={itemsAsRow}
                           form={extForm ?? accountSearchForm}
                           extButtons={extButtons}
                           isCombined={isCombined}
                           onlyItems={onlyItems}
                           sizes={fSizes}
                           houseNonRequired={houseNonRequired}
                           styles={styles}/>
            </Card>
            : <FormItems single={single}
                         vertical={vertical}
                         inRow={itemsAsRow}
                         form={extForm ?? accountSearchForm}
                         extButtons={extButtons}
                         isCombined={isCombined}
                         onlyItems={onlyItems}
                         sizes={fSizes}
                         houseNonRequired={houseNonRequired}
                         styles={styles}
            />
    } else {
        return isCard ? <Card title={single ? t('Выбор ЛС') : t('Поиск ЛС')} size={'small'}>
                <Form form={extForm ?? accountSearchForm} layout={'vertical'} size={'small'}
                      onValuesChange={onValuesChange}
                      onFinish={onSubmit}
                >
                    <FormItems single={single}
                               vertical={vertical}
                               form={extForm ?? accountSearchForm}
                               extButtons={extButtons}
                               isCombined={isCombined}
                               sizes={fSizes}
                               houseNonRequired={houseNonRequired}
                    />
                </Form>
            </Card>
            : <Form form={extForm ?? accountSearchForm} layout={'vertical'} size={'small'}
                    onValuesChange={onValuesChange}
                    onFinish={onSubmit}
            >
                <FormItems single={single}
                           vertical={vertical}
                           form={extForm ?? accountSearchForm}
                           extButtons={extButtons}
                           isCombined={isCombined}
                           sizes={fSizes}
                           houseNonRequired={houseNonRequired}
                />
            </Form>
    }
}

function FormItems(props) {
    const {
        single, vertical, form, isCombined, extButtons, onlyItems, inRow, styles,
        sizes, houseNonRequired,
    } = props
    const {t} = useTranslation()

    const {townSelect, districtSelect, streetSelect} = useStoreMap($dictsSelects, sel => ({
        townSelect: sel.towns,
        districtSelect: sel.districts,
        streetSelect: sel.streets
    }))

    const townId = Form.useWatch('town_id', form)
    const districtId = Form.useWatch('town_distr_id', form)
    const streetId = Form.useWatch('street_id', form)
    const houseIn = Form.useWatch('house_in', form)

    const selOpts = useMemo(() => {
        return {
            distrs: isId(townId) ? districtSelect.filter(i => i.town_id === townId) : [],
            streets: isId(townId) ? streetSelect.filter(i => i.town_id === townId) : [],
        }
    }, [townId])

    return ((vertical === true || onlyItems === true) && !inRow)
        ? <>
            <Form.Item name={'town_id'} label={t('Населенный пункт')}>
                <Select options={townSelect}
                        style={styles ?? {}}
                        dropdownStyle={{width: 300}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                />
            </Form.Item>
            <Form.Item name={'street_id'} label={t('Улица')}>
                <Select options={selOpts.streets} disabled={!isId(townId)}
                        style={styles ?? {}}
                        dropdownStyle={{width: 300}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                />
            </Form.Item>
            {single
                ? <Form.Item name={'house_in'} label={t('Дом')}
                             validateTrigger={'onBlur'}
                             rules={[
                                 {pattern: /^[^\s,-]+$/, message: t('Укажите только один дом')}
                             ]}
                >
                    <Input disabled={!(isId(districtId) || isId(streetId))} style={styles ?? {}} allowClear/>
                </Form.Item>
                : <Form.Item name={'house_in'}
                             label={t('Дома')}
                             tooltip={t('Вы можете указать диапазон домов через "-" или перечислить через "," или совместить оба варианта')}
                >
                    <Input disabled={!(isId(districtId) || isId(streetId))} style={styles ?? {}} allowClear/>
                </Form.Item>
            }
            <Form.Item name={'corpus'} label={t('Корпус')}>
                <Input disable={!houseIn || houseIn?.length < 1} style={styles ?? {}} allowClear/>
            </Form.Item>
            <Form.Item name={'flat_in'} label={t('Квартиры')}
                       tooltip={t('Вы можете указать диапазон квартир через "-" или перечислить через "," или совместить оба варианта')}
            >
                <Input disabled={!houseIn || houseIn?.length < 1} style={styles ?? {}} allowClear/>
            </Form.Item>
            {single
                ? <Form.Item name={'account_in'} label={t('ЛС')}
                             validateTrigger={'onBlur'}
                             rules={[
                                 {pattern: /^[^\s,-]+$/, message: t('Укажите только один дом')}
                             ]}
                >
                    <Input style={styles ?? {}} allowClear/>
                </Form.Item>
                : <Form.Item name={'account_in'} label={t('ЛС')}
                             tooltip={t('Вы можете указать диапазон ЛС через "-" или перечислить через "," или совместить оба варианта')}
                >
                    <Input style={styles ?? {}} allowClear/>
                </Form.Item>
            }
            <Form.Item name={'iin_name'} label={t('ФИО/ИИН')}>
                <Input allowClear/>
            </Form.Item>
            <Form.Item name={'town_distr_id'} label={t('Район')}>
                <Select options={selOpts.distrs} disabled={!isId(townId)}
                        style={styles ?? {}}
                        dropdownStyle={{width: 300}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                />
            </Form.Item>
            {!isCombined && !extButtons && <Form.Item>
                <ButtonGroup>
                    <Button type={'primary'} onClick={() => form.submit()}>
                        {single ? t('Выбрать') : t('Найти')}
                    </Button>
                    <Button onClick={() => form.resetFields()}>
                        {t('Сбросить')}
                    </Button>
                </ButtonGroup>
            </Form.Item>}
        </>
        : <>
            <Row gutter={[8, 0]}>
                <Col span={sizes.town}>
                    <Form.Item name={'town_id'} label={t('Населенный пункт')}>
                        <Select options={townSelect}
                                style={styles ?? {}}
                                dropdownStyle={{width: 300}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                        />
                    </Form.Item>
                </Col>
                <Col span={sizes.street}>
                    <Form.Item name={'street_id'} label={t('Улица')}>
                        <Select disabled={!isId(townId)}
                                options={selOpts.streets}
                                style={styles ?? {}}
                                dropdownStyle={{width: 300}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                        />
                    </Form.Item>
                </Col>
                {single
                    ? <Col span={sizes.house}>
                        <Form.Item name={'house_in'} label={t('Дом')}
                                   validateTrigger={'onBlur'}
                                   rules={[
                                       {
                                           pattern: /^[^\s,-]+$/,
                                           message: t('Укажите только один дом')
                                       }
                                   ]}
                        >
                            <Input disabled={!(isId(districtId) || isId(streetId))} allowClear/>
                        </Form.Item>
                    </Col>
                    : <Col span={sizes.house}>
                        <Form.Item name={'house_in'}
                                   label={t('Дома')}
                                   tooltip={t('Вы можете указать диапазон домов через "-" или перечислить дома через "," или совместить оба варианта')}
                        >
                            <Input disabled={!(isId(districtId) || isId(streetId))} allowClear/>
                        </Form.Item>
                    </Col>
                }
                <Col span={sizes.corpus}>
                    <Form.Item name={'corpus'} label={t('Корпус')}>
                        <Input disabled={!houseIn || houseIn?.length < 1} allowClear/>
                    </Form.Item>
                </Col>
                <Col span={sizes.flat}>
                    <Form.Item name={'flat_in'} label={t('Квартиры')}
                               tooltip={t('Вы можете указать диапазон квартир через "-" или перечислить через "," или совместить оба варианта')}
                    >
                        <Input disabled={!houseIn || houseIn?.length < 1} allowClear/>
                    </Form.Item>
                </Col>
                <Col span={sizes.account}>
                    {single
                        ? <Form.Item name={'account_in'} label={t('ЛС')}
                                     validateTrigger={'onBlur'}
                                     rules={[
                                         {
                                             pattern: /^[^\s,-]+$/,
                                             message: t('Укажите только один дом')
                                         }
                                     ]}
                        >
                            <Input allowClear/>
                        </Form.Item>
                        : <Form.Item name={'account_in'} label={t('ЛС')}
                                     tooltip={t('Вы можете указать диапазон ЛС через "-" или перечислить через "," или совместить оба варианта')}
                        >
                            <Input allowClear/>
                        </Form.Item>
                    }
                </Col>
                <Col span={sizes.iin}>
                    <Form.Item name={'iin_name'} label={t('ФИО/ИИН')}>
                        <Input allowClear/>
                    </Form.Item>
                </Col>
                <Col span={sizes.town_distr}>
                    <Form.Item name={'town_distr_id'} label={t('Район')}>
                        <Select disabled={!isId(townId)}
                                options={selOpts.distrs}
                                style={styles ?? {}}
                                dropdownStyle={{width: 300}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                        />
                    </Form.Item>
                </Col>
                {!isCombined && !extButtons && <Col span={sizes.actions}>
                    <Form.Item>
                        <ButtonGroup size={'small'}>
                            <Button type={'primary'} onClick={() => form.submit()}>
                                {single ? t('Выбрать') : t('Найти')}
                            </Button>
                            <Button onClick={() => form.resetFields()}>
                                {t('Сбросить')}
                            </Button>
                        </ButtonGroup>
                    </Form.Item>
                </Col>}
            </Row>
        </>
}
