import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getFilesListFx = createEffect()
    .use(async (filters) => (await api().get(`/reports/files/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const downloadFileFx = createEffect()
    .use(async (path) => {
        const file = await api().get(`/reports/download-file/?path=${path}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        // link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
            link.download = file.headers["content-disposition"].split('filename=').length > 1 ?
                file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '') :
                // file.headers["content-disposition"].split('filename*=')[1].replace(/"/gm, '')
                decodeURIComponent(file.headers["content-disposition"].split("filename*=utf-8''")[1].replace(/"/gm, ''))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })
