import {
    $afterCreateAction,
    $createdChiefInspectorId,
    $editChiefInspectorStatus,
    $selectedChiefInspector,
    $chiefInspectorList,
} from './stores.js';
import {getChiefInspectorListFx, getChiefInspectorByIdFx} from './effects.js';
import {
    deleteChiefInspectorEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ChiefInspectorEditGate,
    ChiefInspectorListGate,
    ChiefInspectorViewGate,
    submitCreateChiefInspectorEv,
    submitEditChiefInspectorEv, ChiefInspectorCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $createdInspectors,
    $createEditChildSectorsEmbedded, $deletedSectors,
    $sectorsChildren,
    $createEditChildInspectorsEmbedded,
    $deletedInspectors,
    $InspectorsChildren
} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateChiefInspectorEv, response: triggerSuccessOpsEv}, reset: ChiefInspectorCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditChiefInspectorEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteChiefInspectorEv, response: triggerSuccessOpsEv})

$chiefInspectorList.on(getChiefInspectorListFx.doneData, (_, data) => data)
    .reset(ChiefInspectorListGate.close)

$selectedChiefInspector.on(getChiefInspectorByIdFx.doneData, (_, data) => data)
    .reset([ChiefInspectorEditGate.close, ChiefInspectorViewGate.close])

$createdChiefInspectorId.on(createCompleteEv, (_, {response}) =>{
    const data = response.find(i => i.type === 'chief_inspector')
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editChiefInspectorStatus.on(editCompleteEv, () => true)
    .reset(ChiefInspectorEditGate.close)

sample({
    clock: [ChiefInspectorEditGate.state, ChiefInspectorViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getChiefInspectorByIdFx
})

sample({
    clock: ChiefInspectorListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getChiefInspectorListFx
})

sample({
    source: ChiefInspectorListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getChiefInspectorListFx
})

sample({
    source: {inspectors: $createdInspectors, inspector: $selectedChiefInspector, updated: $InspectorsChildren, deleted: $deletedInspectors},
    clock: submitCreateChiefInspectorEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        actions.push({operation: 'create', type: 'chief_inspector', value: {...payload, id: idCounter}})
        idCounter--


        if (current?.inspectors?.length > 0) {

            const newInspectors = current.inspectors.map(item => {
                delete item.new_sector
                return item
            })

            for (const newDist of Object.values(newInspectors)) {
                idCounter--
                actions.push({operation: 'create', type: 'inspectors', value: {...newDist, id: idCounter}})
            }
        }

        if (current?.updated?.data?.length > 0) {
            const updated = current?.updated?.data.filter(item => item.changedFields)
            for (const newDist of Object.values(updated)) {
                idCounter--
                actions.push({operation: 'update', type: 'inspectors', value: {...newDist, object_id: idCounter}})
            }


            const added = current?.updated?.data.filter(item => item.new_item === true).map(item => {
                return {
                    name_kz: item.name_kz,
                    name_ru: item.name_ru,
                    object_id: item.id,
                    chief_inspector_id: -1
                    // inspector_id: -1,
                }
            })
            for (const newDist of Object.values(added)) {
                idCounter--
                actions.push({operation: 'update', type: 'inspectors', value: {...newDist}})
            }
        }

        if (current?.deleted) {
            const deleted = current?.deleted.map(item => {
                return {
                    ...item,
                    chief_inspector_id: null
                }
            })
            for (const newDist of Object.values(deleted)) {
                idCounter--
                actions.push({operation: 'update', type: 'inspectors', value: {...newDist, object_id: idCounter}})
            }
        }
        // if (current.delete)
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {inspectors: $createdInspectors, inspector: $selectedChiefInspector, updated: $InspectorsChildren, deleted: $deletedInspectors},
    clock: submitEditChiefInspectorEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current.inspector[key] !== value) {
                changedFields[key] = value
            }
        }

        if (Object.keys(changedFields).length > 0) {
            actions.push({
                operation: 'update', type: 'chief_inspector', value: {object_id: current.inspector.id, ...changedFields}
            })
        }



        if (current?.inspectors?.length > 0) {
            const newInspectors = current.inspectors.map(item => {
                delete item.new_inspector
                return {
                    ...item,
                    chief_inspector_id: current.inspector.id
                }
            })

            for (const newDist of Object.values(newInspectors)) {
                idCounter--
                actions.push({operation: 'create', type: 'inspectors', value: {...newDist, id: idCounter, town_id: payload.town_id}})
            }
        }

        if (current?.updated?.data?.length > 0) {
            const updated = current?.updated?.data.filter(item => item.changedFields).map(item => {
                return {
                    name_ru: item.name_ru,
                    name_kz: item.name_kz,
                    object_id: item.id,
                    // chief_inspector_id: current.inspector.id
                }
            })
            for (const newDist of Object.values(updated)) {
                idCounter--
                actions.push({operation: 'update', type: 'inspectors', value: newDist})
            }

            const added = current?.updated?.data.filter(item => item.new_item === true).map(item => {
                return {
                    name_kz: item.name_kz,
                    name_ru: item.name_ru,
                    object_id: item.id,
                    chief_inspector_id: current.inspector.id,
                }
            })
            for (const newDist of Object.values(added)) {
                idCounter--
                actions.push({operation: 'update', type: 'inspectors', value: {...newDist}})
            }

        }
        if (current?.deleted) {
            // const deleted = current?.deleted.map(item => {
            const deleted = current?.deleted.filter(item => item.new_item !== true).map(item => {
                return {
                    object_id: item.id,
                    chief_inspector_id: null
                }
            })
            for (const newDist of Object.values(deleted)) {
                idCounter--
                actions.push({operation: 'update', type: 'inspectors', value: {...newDist}})
            }
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteChiefInspectorEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'chief_inspector', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
