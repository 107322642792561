import {
    $afterCreateAction,
    $createdInspectorsId,
    $editInspectorsStatus,
    $selectedInspectors,
    $inspectorsList
} from './stores.js';
import {getInspectorsListFx, getInspectorsByIdFx} from './effects.js';
import {
    deleteInspectorsEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    InspectorsEditGate,
    InspectorsListGate,
    InspectorsViewGate,
    submitCreateInspectorsEv,
    submitEditInspectorsEv, InspectorsCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $createdSectors,
    $createEditChildSectorsEmbedded, $deletedSectors,
    $sectorsChildren, resetCreateChiefInspectorEmbeddedEv
} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateInspectorsEv, response: triggerSuccessOpsEv}, reset: InspectorsCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditInspectorsEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteInspectorsEv, response: triggerSuccessOpsEv})

$inspectorsList.on(getInspectorsListFx.doneData, (_, data) => data)
    .reset(InspectorsListGate.close)

$selectedInspectors.on(getInspectorsByIdFx.doneData, (_, data) => ({
    ...data,
    sector_id: (data?.sector_ids || []).length > 0 ? data.sector_ids[0] : undefined
}))
    .reset([InspectorsEditGate.close, InspectorsViewGate.close])

$createdInspectorsId.on(createCompleteEv, (_, {response}) =>{
    const data = response.find(i => i.type === 'inspectors')
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editInspectorsStatus.on(editCompleteEv, () => true)
    .reset(InspectorsEditGate.close)

sample({
    clock: [InspectorsEditGate.state, InspectorsViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getInspectorsByIdFx
})

sample({
    clock: InspectorsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getInspectorsListFx
})

sample({
    source: InspectorsListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getInspectorsListFx
})

sample({
    source: {sectors: $createdSectors, inspector: $selectedInspectors, updated: $sectorsChildren, deleted: $deletedSectors},
    clock: submitCreateInspectorsEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const chief = {
            name_ru: payload.chief_name_ru,
            name_kz: payload.chief_name_kz,
        }


        const sector = payload.sector_id || null
        const adv_sector = payload?.adv_sector ? payload?.adv_sector : null
        delete payload.sector_id
        delete payload.chief_name_ru
        delete payload.chief_name_kz



        if (adv_sector !== null) {
            delete payload.adv_sector
        }

        if (payload.chief_inspector_id === -1) {
            actions.push({operation: 'create', type: 'chief_inspector', value: {...chief, id: idCounter}})
        }




        // actions.push({operation: 'create', type: 'inspectors', value: {...payload, id: idCounter}})
        const newInspector = {...payload}
        actions.push({operation: 'create', type: 'inspectors', value: {...newInspector, id: idCounter}})
        idCounter--

        if (sector !== null) {
            if (sector < 0) {
                const newSector = {
                    id: -1,
                    town_id: payload.town_id,
                    adv_sector: adv_sector,
                    inspector_id: -1
                }
                actions.push({operation: 'create', type: 'sector', value: newSector})
            } else {
                if (sector !== ' ' && sector !== '-')
                actions.push({operation: 'update', type: 'sector', value: {inspector_id: -1, object_id: sector}})
            }
        }



        // if (current?.sectors?.length > 0) {
        //
        //     const newSectors = current.sectors.map(item => {
        //         delete item.new_sector
        //         return item
        //     })
        //
        //     for (const newDist of Object.values(newSectors)) {
        //         idCounter--
        //         actions.push({operation: 'create', type: 'sector', value: {...newDist, id: idCounter, town_id: payload.town_id}})
        //     }
        // }
        //
        // if (current?.updated?.data?.length > 0) {
        //     const updated = current?.updated?.data.filter(item => item.changedFields)
        //     for (const newDist of Object.values(updated)) {
        //         idCounter--
        //         actions.push({operation: 'update', type: 'sector', value: {...newDist, object_id: idCounter}})
        //     }
        //
        //
        //     const added = current?.updated?.data.filter(item => item.new_item === true).map(item => {
        //         return {
        //             name_kz: item.name_kz,
        //             name_ru: item.name_ru,
        //             object_id: item.id,
        //             inspector_id: -1,
        //         }
        //     })
        //     for (const newDist of Object.values(added)) {
        //         idCounter--
        //         actions.push({operation: 'update', type: 'sector', value: {...newDist}})
        //     }
        // }
        //
        // if (current?.deleted) {
        //     const deleted = current?.deleted.map(item => {
        //         return {
        //             ...item,
        //             inspector_id: null
        //         }
        //     })
        //     for (const newDist of Object.values(deleted)) {
        //         idCounter--
        //         actions.push({operation: 'update', type: 'sector', value: {...newDist, object_id: idCounter}})
        //     }
        // }
        // if (current.delete)
        return actions
    },
    target: [submitGlobalUpdateEv, resetCreateChiefInspectorEmbeddedEv]
})

sample({
    source: {sectors: $createdSectors, inspector: $selectedInspectors, updated: $sectorsChildren, deleted: $deletedSectors},
    clock: submitEditInspectorsEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const chief = {
            name_ru: payload.chief_name_ru,
            name_kz: payload.chief_name_kz,
        }

        const sector = payload.sector_id || null
        const adv_sector = payload?.adv_sector ? payload?.adv_sector : null

        delete payload.sector_id
        delete payload.chief_name_ru
        delete payload.chief_name_kz

        if (adv_sector !== null) {
            delete payload.adv_sector
        }

        if (payload.chief_inspector_id === -1) {
            actions.push({operation: 'create', type: 'chief_inspector', value: {...chief, id: idCounter}})
        }

        if (sector !== null && sector !== current.inspector.sector_id) {
            if (sector < 0) {
                const newSector = {
                    id: -1,
                    town_id: payload.town_id,
                    adv_sector: adv_sector,
                    inspector_id: current.inspector.id
                }
                actions.push({operation: 'create', type: 'sector', value: newSector})
            } else {
                if (sector === ' ' || sector === '-') {
                    actions.push({
                        operation: 'update',
                        type: 'sector',
                        value: {inspector_id: null, object_id: current.inspector.sector_id}
                    })
                } else {
                    actions.push({
                        operation: 'update',
                        type: 'sector',
                        value: {inspector_id: current.inspector.id, object_id: sector}
                    })
                }
            }
        }
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current.inspector[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({
            operation: 'update', type: 'inspectors', value: {object_id: current.inspector.id, ...changedFields}
        })


        // if (current?.sectors?.length > 0) {
        //     const newSectors = current.sectors.map(item => {
        //         delete item.new_sector
        //         return {
        //             ...item,
        //             inspector_id: current.inspector.id
        //         }
        //     })
        //
        //     for (const newDist of Object.values(newSectors)) {
        //         idCounter--
        //         actions.push({operation: 'create', type: 'sector', value: {...newDist, id: idCounter, town_id: payload.town_id}})
        //     }
        // }
        //
        // if (current?.updated?.data?.length > 0) {
        //     const updated = current?.updated?.data.filter(item => item.changedFields)
        //     for (const newDist of Object.values(updated)) {
        //         idCounter--
        //         actions.push({operation: 'update', type: 'sector', value: {
        //             ...newDist, object_id: idCounter, inspector_id: current.inspector.id
        //         }})
        //     }
        //
        //     const added = current?.updated?.data.filter(item => item.new_item === true).map(item => {
        //         return {
        //             name_kz: item.name_kz,
        //             name_ru: item.name_ru,
        //             object_id: item.id,
        //             inspector_id: current.inspector.id,
        //         }
        //     })
        //     for (const newDist of Object.values(added)) {
        //         idCounter--
        //         actions.push({operation: 'update', type: 'sector', value: {...newDist}})
        //     }
        //
        // }
        // // debugger
        // if (current?.deleted) {
        //     // const deleted = current?.deleted.map(item => {
        //     const deleted = current?.deleted.filter(item => item.new_item !== true).map(item => {
        //         return {
        //             object_id: item.id,
        //             inspector_id: null
        //         }
        //     })
        //     // debugger
        //     for (const newDist of Object.values(deleted)) {
        //         idCounter--
        //         actions.push({operation: 'update', type: 'sector', value: {...newDist}})
        //     }
        // }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteInspectorsEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'inspectors', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
