import {createStore} from 'effector';

export const $fileList = createStore({data: [], count: 0})
export const $tempUploadList = createStore([])

export const $uploadModal = createStore(false)
export const $uploadStatus = createStore(false)

export const $selectedFile = createStore(null)
export const $selectedFileCells = createStore({data: [], count: 0})
export const $selectedFileApproveCells = createStore({data: [], count: 0})
