import {sample} from "effector";
import {debouncedSaveUserSettingsEv} from "./events.js";
import {$currentUser} from '../infoModel/index.js';
import {getAllSettingsFx, saveUserSettingsFx, updateUserSettingsFx} from "./effects.js";
import {$userSettings} from "./stores.js";

$userSettings.on(getAllSettingsFx.doneData, (_, data) => data)

sample({
	source: [$currentUser, $userSettings],
	clock: debouncedSaveUserSettingsEv,
	filter: ([currentUser, userSettings], clock) => {
		return !userSettings.find(item => item.key === clock.key) && currentUser?.id
	},
	fn: ([currentUser, _], clock) => {
		return {
			user_id: currentUser.id,
			...clock
		}
	},
	target: saveUserSettingsFx
})

sample({
	source: [$currentUser, $userSettings],
	clock: debouncedSaveUserSettingsEv,
	filter: ([_, userSettings], clock) => userSettings.find(item => item.key === clock.key),
	fn: ([_, userSettings], clock) => {
		return {
			id: userSettings.find(item => item.key === clock.key).id,
			payload: {
				...clock
			},
			update_existed: true,
		}
	},
	target: updateUserSettingsFx
})

sample({
	clock: $currentUser.updates,
	filter: (u) => !!u?.id,
	fn: (u) => u.id,
	target: getAllSettingsFx
})

sample({
	source: $currentUser,
	clock: saveUserSettingsFx.doneData,
	fn: (currentUser) => currentUser.id,
	target: getAllSettingsFx
})
