import i18 from '../i18n.js'

function multiply(bin, weights) {
    return weights.reduce((sum, weight, index) => sum + weight * parseInt(bin[index]), 0);
}

export function validateBIN(bin) {
    const w1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const w2 = [3, 4, 5, 6, 7, 8, 9, 10, 11, 1, 2];
    let checkSum = multiply(bin, w1) % 11;
    if (checkSum === 10) {
        checkSum = multiply(bin, w2) % 11;
    }
    return checkSum === parseInt(bin.slice(-1));
}

export async function binValidator (rule, value, onlyIin = false) {
    if (value && (value.length !== 12 || /^\d+$/.test(value) === false)) {
        return Promise.reject(new Error(i18.t(onlyIin ? 'ИИН должен состоять из 12 цифр' : 'БИН/ИИН должен состоять из 12 цифр')));
    } else if (value
        && value.length === 12
        && value.split('').every(i => Number.isInteger(+i))
        && !validateBIN(value)
    ) {
        return Promise.reject(new Error(i18.t(onlyIin ? 'ИИН недействителен' : 'БИН/ИИН недействителен')));
    } else if (value
        && value.length === 12
        && value.split('').every(i => Number.isInteger(+i))
        && validateBIN(value)
    ) {
        return Promise.resolve();
    }
}