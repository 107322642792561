import {
    $createdStTypeId,
    $selectedStreetType,
    $streetTypeEditStatus,
    $streetTypesList,
    $afterCreateStTypeAction
} from './stores.js';
import {
    getStreetTypeByIdFx,
    getStreetTypesListFx
} from './effects.js';
import {
    deleteStreetTypeEv,
    resetAfterCreationAttrs, setAfterCreateActionEv, StreetTypeCreateGate,
    StreetTypeEditGate,
    StreetTypesListGate,
    StreetTypeViewGate, submitCreateStreetTypeEv,
    submitEditStreetTypeEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createStreetTypeCompleteEv = combineEvents({events: {ev: submitCreateStreetTypeEv, response: triggerSuccessOpsEv}, reset: StreetTypeCreateGate.state})
const editStreetTypeCompleteEv = combineEvents({ev: submitEditStreetTypeEv, response: triggerSuccessOpsEv})
const deleteStreetTypeCompleteEv = combineEvents({ev: deleteStreetTypeEv, response: triggerSuccessOpsEv})

$streetTypesList.on(getStreetTypesListFx.doneData, (_, stTypes) => stTypes)
    .reset(StreetTypesListGate.close)

$selectedStreetType.on(getStreetTypeByIdFx.doneData, (_, stTypes) => stTypes)
    .reset([StreetTypeEditGate.close, StreetTypeViewGate.close])

$createdStTypeId.on(createStreetTypeCompleteEv, (_, {response}) => {
    const stType = response.find(i => i.type === 'street_type')
    return stType ? stType?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateStTypeAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$streetTypeEditStatus.on(editStreetTypeCompleteEv, () => true)
    .reset(StreetTypeEditGate.close)

sample({
    clock: StreetTypesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getStreetTypesListFx
})

sample({
    source: StreetTypesListGate.state,
    clock: [createStreetTypeCompleteEv, editStreetTypeCompleteEv, deleteStreetTypeCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getStreetTypesListFx
})

sample({
    clock: [StreetTypeEditGate.state, StreetTypeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getStreetTypeByIdFx
})

sample({
    clock: submitCreateStreetTypeEv,
    fn: payload => {
        const actions = []
        actions.push({operation: 'create', type: 'street_type', value: {...payload, id: -1}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedStreetType,
    clock: submitEditStreetTypeEv,
    fn: (src, payload) => {
        const actions = []
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (src[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'street_type', value: {object_id: src.id, ...changedFields}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteStreetTypeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'street_type', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})