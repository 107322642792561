import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useEffect} from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import {
    $createdIpAddresses,
    $createEditChildIpAddressEmbedded, $ipAddressesChildren, resetCreateEditChildIpAddressEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import dayjs from 'dayjs';
import Col from 'antd/es/col';
import {Select} from "antd";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel/index.js";
import {$selectedBankApiInfo} from "../../../models/dictionaryBankApiInfoModel/index.js";

export default function CreateEditIpAddressEmbeddedForm({onCreate, onEdit}) {
    const embedded = useUnit($createEditChildIpAddressEmbedded)
    const reset = useUnit(resetCreateEditChildIpAddressEmbeddedEv)

    const selected = useUnit($selectedBankApiInfo)

    const {t} = useTranslation()
    const [ipAddressForm] = Form.useForm()

    const {data, count} = useUnit($ipAddressesChildren)
    const newAddresses = useUnit($createdIpAddresses)

    const [check, changedFields] = useFormChanged(embedded.item, ['is_active', 'bank_id'])


    const bankOpts = useStoreMap($dictsSelects, sel => sel.bank)

    const opt = [
        {
            label: 'Все',
            value: 'all',
        },
        {
            label: 'Да',
            value: true,
        },
        {
            label: 'Нет',
            value: false,
        }
    ]


    const onClose = () => {
        ipAddressForm.resetFields()
        reset()
    }

    useEffect(() => {
        if (embedded.open && embedded.type === 'edit' && embedded.idx !== null && embedded.item) {
            ipAddressForm.setFieldsValue({
                ...embedded.item,
                // actual_from: embedded?.item?.actual_from ? dayjs(embedded.item.actual_from) : null
            })
        }
    }, [embedded]);

    const onSubmit = (values) => {

        const id = parseInt(
            `-${newAddresses?.length + 1}`
        )

        const newData = {
            ip_address: values.ip_address,
            is_active: values.is_active === undefined ? true : values.is_active,
            bank_id: selected?.bank_id ? selected?.bank_id : 'Выбранный Банк',
            id: id
        }
        if (embedded.type === 'edit') {
            onEdit({
                payload: {
                    ...newData,
                    id: embedded.item.id
                }, id: embedded.item.id
            })
        } else if (embedded.type === 'create') {
            onCreate({...newData})
        }
        onClose()
    }



    return embedded.open &&
        <Form form={ipAddressForm}
              size={'small'}
              layout={'vertical'}
              onFinish={onSubmit}
              style={{width: '100%', marginBottom: 16}}
              onValuesChange={(_, all) => {
                  if (embedded.type === 'edit') {
                      check(all)
                  }
              }}
        >
            <Card title={t('Свойства адреса')} size={'small'} style={{height: '100%'}}>
                <Row  gutter={[16, 16]}>
                    <Col span={6}>
                        <Form.Item name={'ip_address'}
                                   label={t('IP Адрес')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['ip_address'] : undefined}
                        >
                            <Input />
                        </Form.Item>

                    </Col>
                    <Col span={6}>
                        <Form.Item name={'is_active'}
                                   label={t('Актуальный')}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['is_active'] : undefined}
                        >
                            <Select
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={opt}
                                defaultValue={true}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
            <Row style={{gap: 8, marginTop: 16}}>
                <Button onClick={onClose} danger>{t('Отмена')}</Button>
                <Button type={'primary'} onClick={ipAddressForm.submit}>{t('Сохранить')}</Button>
            </Row>
        </Form>
}
