import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import {
    $selectedSector,
    getSectorByIdFx,
    SectorViewGate
} from "../../../models/dictionarySectorModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";

export default function ViewSector() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getSectorByIdFx.pending)
    const data = useUnit($selectedSector)
    const genitive_form = t(`breadcrumbs.genitive.sector`);


    const items = [
        {
            key: 'town_id',
            label: t('Населенный пункт'),
            children: <MappedDictItem id={data?.town_id} type={'town'}/>,
        },
        {
            key: 'inspector_id',
            label: t('Контролеры'),
            children: <MappedDictItem id={data?.inspector_id} type={'inspector'}/>,
        },
        // {
        //     key: 'name_kz',
        //     label: t(`Название ${genitive_form} (каз)`),
        //     children: data?.name_kz
        // },
        // {
        //     key: 'name_ru',
        //     label: t(`Название ${genitive_form} (рус)`),
        //     children: data?.name_ru
        // },

        {
            key: 'adv_sector',
            label: t('Рекламный сектор'),
            children: data?.adv_sector
        },
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <SectorViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр участка')}</span> <MappedDictItem id={id} type={'sector'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'sector'}/>
        </Card>
    </>
}
