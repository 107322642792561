import {createEffect, createEvent, createStore, sample} from 'effector';
import api from '../../api/api.js';

const lang = localStorage.getItem('lang') ?? 'ru'

export const $distrsByCitySelect = createStore([])
export const $streetsByCitySelect = createStore([])
export const $inspectorsByCitySelect = createStore([])

export const getDistrsByCityEv = createEvent()
export const getStreetsByCityEv = createEvent()
export const getInspectorsByCityEv = createEvent()
export const resetSelectsEv = createEvent()

export const getDistrsByCityFx = createEffect()
    .use(async (id) => (await api().get(`/town_distr/?town_id=${id}&ignore_limit=true`)).data)
export const getStreetsByCityFx = createEffect()
    .use(async (id) => (await api().get(`/street/?town_id=${id}&ignore_limit=true`)).data)
export const getInspectorsByCityFx = createEffect()
    .use(async (id) => (await api().get(`/inspectors/?town_id=${id}&ignore_limit=true`)).data)

$distrsByCitySelect.on(getDistrsByCityFx.doneData, (_, response) => response?.data?.map(i => ({value: i.id, label: `${i.id} ${i?.[`name_${lang}`]}`})))
    .reset(resetSelectsEv)
$streetsByCitySelect.on(getStreetsByCityFx.doneData, (_, response) => response?.data?.map(i => ({value: i.id, label: `${i.id} ${i?.[`name_${lang}`]}`, name_kz: i.name_kz, name_ru: i.name_ru})))
    .reset(resetSelectsEv)
$inspectorsByCitySelect.on(getInspectorsByCityFx.doneData, (_, response) => response?.data?.map(i => ({value: i.id, label: `${i.id} ${i?.[`name_${lang}`]}`})))
    .reset(resetSelectsEv)

sample({
    clock: [getDistrsByCityEv, getStreetsByCityEv, getInspectorsByCityEv],
    filter: id => !(typeof id === "number"),
    target: resetSelectsEv
})

sample({
    clock: getDistrsByCityEv,
    filter: id => typeof id === "number" && id >= 0,
    target: getDistrsByCityFx
})

sample({
    clock: getStreetsByCityEv,
    filter: id => typeof id === "number" && id >= 0,
    target: getStreetsByCityFx
})

sample({
    clock: getInspectorsByCityEv,
    filter: id => typeof id === "number" && id >= 0,
    target: getInspectorsByCityFx
})
