import Card from 'antd/es/card';
import Form from 'antd/es/form';
import {useTranslation} from 'react-i18next';
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import {useUnit} from 'effector-react';
import {$authStatus, loginEv, loginFx} from '../../models/authModel/index.js';
import style from './Login.module.css'
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

export default function Login() {
    const {t} = useTranslation()
    const [loginForm] = Form.useForm()
    const loading = useUnit(loginFx.pending)
    const onLogin = useUnit(loginEv)
    const authStatus = useUnit($authStatus)
    const navigate = useNavigate()

    useEffect(() => {
        if (authStatus) {
            navigate(localStorage.getItem('logoutAt') ?? '/')
        }
    }, [authStatus])

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loginForm.submit();
        }
    };

    return <div className={style.loginCnt}>
        <Card title={t('Авторизация')} size={'small'} className={style.loginPage}>
            <Form
                form={loginForm}
                layout={'vertical'}
                onFinish={(values) => onLogin(values)}
                onKeyDown={handleKeyPress}
            >
                <Form.Item name={'username'} label={t('Логин')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item name={'password'} label={t('Пароль')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button loading={loading} type={'primary'} onClick={() => loginForm.submit()}>{t('Войти')}</Button>
                </Form.Item>
            </Form>
        </Card>
    </div>
}
