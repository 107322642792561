import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';

import {Select} from "antd";
import {$dictsSelects} from "../../../models/infoModel/index.js";

import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionarySectorModel/index.js";
import {
    $editUsersStatus,
    $selectedUsers, $userCreateErrors,
    getUsersByIdFx, submitEditUsersEv,
    UsersEditGate
} from '../../../models/dictionaryUsersModel/index.js';
import Switch from "antd/es/switch/index.js";
import InputMask from "react-input-mask";

export default function EditUsers() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedUsers)

    const [usersForm] = Form.useForm()



    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const positionOpts = useStoreMap($dictsSelects, sel => sel.position)
    const roleOpts = useStoreMap($dictsSelects, sel => sel.role)
    const usersOpts = useStoreMap($dictsSelects, sel => sel.users)

    const passErrors = useUnit($userCreateErrors)



    const submit = useUnit(submitEditUsersEv)

    const loading = useUnit(getUsersByIdFx.pending)


    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editUsersStatus)

    console.log('action', action)
    console.log('action status', status)




    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/users?code_name=${selected.id}`)
            }
        }
    }, [action, status ]);


    useEffect(() => {
        if (selected) {
            usersForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'town_id', 'town_distr_id', 'street_type_id'])

    return <>
        <UsersEditGate id={id}/>
        <Form form={usersForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Общая информация')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Row justify={'space-between'} gutter={[24, 24]}>
                            <Col span={12}>

                                <Form.Item name={'login'}
                                           label={t('Логин')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'name_ru'}
                                           label={t('ФИО')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'role_id'}
                                           label={t('Роль')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={roleOpts}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'is_active'}
                                           label={t('Активен')}
                                           valuePropName={'checked'}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'password_new'}
                                           label={t('Пароль')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                           hasFeedback
                                           validateStatus={passErrors?.length > 0 ? 'error' : null}
                                >
                                    <Input type="password"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'confirm_password'}
                                           label={t('Подтверждение пароля')}
                                           dependencies={['password']}
                                           hasFeedback
                                           rules={[
                                               {
                                                   required: false,
                                                   message: 'Пароли отличаются!',
                                               },
                                               ({ getFieldValue }) => ({
                                                   validator(_, value) {
                                                       if (!value || getFieldValue('password_new') === value) {
                                                           return Promise.resolve();
                                                       }
                                                       return Promise.reject(new Error('Пароли отличаются!'));
                                                   },
                                               }),
                                           ]}
                                >
                                    <Input type="password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            passErrors?.length > 0 && passErrors
                                .map((item, index) => <p key={index} style={{color: 'red'}}>{item}</p>)
                        }
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title={t('Свойства пользователя')} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Row justify={'space-between'} gutter={[24, 24]}>
                            <Col span={12}>

                                <Form.Item name={'chief_id'}
                                           label={t('Руководитель')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={usersOpts}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'position_id'}
                                           label={t('Должность')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={positionOpts}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'phone'}
                                           label={t('Телефон')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <InputMask mask="(999) 999-99-99">
                                        {() => <Input />}
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'email'}
                                           label={t('E-mail')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>




                        </Row>
                    </Card>
                </Col>
            </Row>


        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    usersForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            usersForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            usersForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
