import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $selectedCorrection,
    $accsFilters,
    getAccsFx, resetAccsEv, searchAccsEv
} from '../../../models/dictionaryCorrectionModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {filterOption} from '../../../utils/selectUtils.jsx';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Form from 'antd/es/form';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Row from 'antd/es/row';
import Input from 'antd/es/input';
import AccountSearchForm from '../../../components/AddressReoSearchForms/AccountSearchForm.jsx';

export default function CorrectionFilters() {
    const {t} = useTranslation()

    const correction = useUnit($selectedCorrection)

    const [searchForm] = Form.useForm();
    const searchFilters = useUnit($accsFilters)
    const accsLoading = useUnit(getAccsFx.pending)

    const selProv = Form.useWatch('provider_id', searchForm)


    const searchAccs = useUnit(searchAccsEv)
    const resetAccs = useUnit(resetAccsEv)

    const {provOpts, serviceOpts} = useStoreMap($dictsSelects, (s) => (
        {
            provOpts: s.providers,
            serviceOpts: s.services
        }
    ))

    const srvOpts = useMemo(() => {
        if (selProv) {
            return serviceOpts?.filter(s => s?.providers?.includes(selProv)) ?? []
        } else return []
    }, [selProv, serviceOpts])


    useEffect(() => {
        if (
            Object.keys(correction?.data?.filters ?? {})?.length > 0
            && JSON.stringify(correction?.data?.filters ?? {}) !== JSON.stringify(searchFilters ?? {})
        ) {
            searchForm.setFieldsValue(correction?.data?.filters)
            searchForm.submit()
        }
    }, [correction])

    const onValuesChange = (cur, _all) => {
        if (Object.hasOwn(cur, 'town_id')) {
            searchForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus'])
        } else if (Object.hasOwn(cur, 'provider_id')) {
            searchForm.resetFields(['service_id'])
        }
    }

    return <>
        <Card size={'small'}>
            <Form form={searchForm} size={'small'}
                  layout={'vertical'} onFinish={searchAccs}
                  onValuesChange={onValuesChange}
            >
                <Row gutter={[16, 0]}>
                    <Col span={6}>
                        <Form.Item name={'provider_id'} label={t('Поставщик')}>
                            <Select options={provOpts}
                                    dropdownStyle={{minWidth: 'fit-content'}}
                                    size={'small'}
                                    style={{width: '100%'}}
                                    filterOption={filterOption}
                                    showSearch
                                    allowClear
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'service_id'} label={t('Услуга')}>
                            <Select
                                disabled={!selProv}
                                options={srvOpts}
                                style={{width: '100%'}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                                size={'small'}
                                dropdownStyle={{minWidth: 'max-content'}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'external_number'} label={t('№ договора поставщика с потребителем')}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <AccountSearchForm extForm={searchForm} itemsAsRow onlyItems extButtons/>
                <Form.Item>
                    <ButtonGroup size={'small'}>
                        <Button type={'primary'} onClick={() => searchForm.submit()}
                                loading={accsLoading}>
                            {t('Поиск')}
                        </Button>
                        <Button onClick={() => {
                            resetAccs()
                            searchForm.resetFields()
                        }}
                        >
                            {t('Сброс')}
                        </Button>
                    </ButtonGroup>
                </Form.Item>
            </Form>
        </Card>
    </>
}