import {
    $districtsChildren,
    $deletedDistricts,
    $selectedChildDistrictsImmutable,
    $createEditChildDistrictEmbedded,
    $districtEmbeddedFilters
} from './stores.js';
import {
    DistrictsChildrenGate,
    changeDistrictsChildrenFiltersEv,
    deleteChildDistrictsEv,
    editChildDistrictsEv,
    resetCreateEditChildDistrictsEmbeddedEv,
    createChildDistrictsEv,
    setCreateEditChildDistrictsEmbeddedEv
} from './events.js';
import {getDistrictsChildrenFx} from './effects.js';
import {sample} from 'effector';
import queryString from 'query-string';

// Для CRUD таблицы вложенных районов
$districtsChildren
    .on(getDistrictsChildrenFx.doneData, (_, districts) => districts)
    .reset(DistrictsChildrenGate.close)

$selectedChildDistrictsImmutable
    .on(getDistrictsChildrenFx.doneData, (state, districts) => ([...state, ...districts.data]))
    .reset(DistrictsChildrenGate.close)

$createEditChildDistrictEmbedded.reset(resetCreateEditChildDistrictsEmbeddedEv)

$districtEmbeddedFilters.on(changeDistrictsChildrenFiltersEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(DistrictsChildrenGate.close)

sample({
    source: {gate: DistrictsChildrenGate.state, filters: $districtEmbeddedFilters},
    clock: [DistrictsChildrenGate.state.updates, $districtEmbeddedFilters.updates],
    filter: (src) => Object.hasOwn(src.gate, 'parent') && Object.hasOwn(src.gate, 'parentId') && (!!src.gate.parentId || src.gate.parentId === 0),
    fn: (src) => {
        const all = {...src.filters, [src.gate.parent]: src.gate.parentId}
        return queryString.stringify(all)
    },
    target: getDistrictsChildrenFx
})

sample({
    source: $districtsChildren,
    clock: createChildDistrictsEv,
    fn: (src, newDist) => {
        if (src.data.some(s => s.id < 0)) {
            const newId = Math.min(src.data.filter(i => i.id < 0).map(i => i.id)) - 1
            return {data: [{...newDist, id: newId}, ...src.data], count: src.count}
        } else {
            return {data: [{...newDist, id: -1}, ...src.data], count: src.count}
        }
    },
    target: $districtsChildren
})

sample({
    source: {mutable: $districtsChildren, original: $selectedChildDistrictsImmutable},
    clock: editChildDistrictsEv,
    fn: (src, {payload, id}) => {
        const result = [...src.mutable.data]
        const existingImmutable = src.original.find(i => i.id === id)
        const existing = result.find(i => i.id === id)
        const existingIdx = result.findIndex(i => i.id === id)
        const changedFields = existing.changedFields ?? []
        /*
        Проход по уже отредактированным улицам и по изначально существующим для этого района.
        Если поле изменилось, и улица не является ново-созданной, то добавляем его в список измененных полей для этой улицы.
        Если поле отредактировали n-ный раз и оно вернулось к оригинальному значению, то удаляем его из списка измененных.
        */
        for (const key in payload) {
            if (existingImmutable) {
                if (existing.id > 0 && existing[key] !== payload[key] && !changedFields.includes(key) && existingImmutable[key] !== payload[key]) {
                    changedFields.push(key)
                    result[existingIdx][key] = payload[key]
                } else if (existing.id > 0 && existing[key] !== payload[key] && changedFields.includes(key) && existingImmutable[key] === payload[key]) {
                    changedFields.splice(changedFields.indexOf(key), 1)
                    result[existingIdx][key] = payload[key]
                }
                result[existingIdx]['changedFields'] = changedFields
            } else {
                if (existing.id < 0 && existing[key] !== payload[key]) {
                    result[existingIdx][key] = payload[key]
                }
            }
        }

        // result[existingIdx]['changedFields'] = changedFields

        return {data: result, count: src.mutable.count}
    },
    target: $districtsChildren
})

sample({
    source: {existing: $districtsChildren, deleted: $deletedDistricts},
    clock: deleteChildDistrictsEv,
    fn: (src, id) => {
        const srcCopy = [...src.existing.data]
        const item = srcCopy.find(i => i.id === id)
        if (item.id > 0) {
            return [...src.deleted, item.id]
        } else {
            return src.deleted
        }
    },
    target: $deletedDistricts
})

sample({
    source: $districtsChildren,
    clock: deleteChildDistrictsEv,
    fn: (src, id) => {
        const result = [...src.data]
        const idx = result.findIndex(i => i.id === id)
        result.splice(idx, 1)
        return {data: result, count: src.count - 1}
    },
    target: $districtsChildren
})

sample({
    source: $districtsChildren,
    clock: setCreateEditChildDistrictsEmbeddedEv,
    fn: (src, clock) => {
        if (clock.type === 'edit') {
            const item = src.data.find(i => i.id === clock.id)
            const itemIdx = src.data.findIndex(i => i.id === clock.id)
            return {open: true, type: 'edit', idx: itemIdx, item: item}
        } else if (clock.type === 'create') {
            return {open: true, type: 'create'}
        }
    },
    target: $createEditChildDistrictEmbedded
})
