import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $psData,
    $psTarifsSL,
    $selectedAccMeta,
    $selectedAccSrv,
    $srvCreateEditDone,
    $tarifAlgoMap,
    $tarifCreated,
    createTariffEv,
    getPSTarifsEv,
    resetSelectedAccSrvEv,
    submitEditSingleCorrEv
} from '../../../../models/dictionaryCorrectionModel/index.js';
import {$dictsSelects, $infoMaps} from '../../../../models/infoModel/index.js';
import Modal from 'antd/es/modal';
import Form from 'antd/es/form';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import Input from 'antd/es/input';
import DatePicker from 'antd/es/date-picker';
import Switch from 'antd/es/switch';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import message from 'antd/es/message';
import {filterOption, optionRender} from '../../../../utils/selectUtils.jsx';
import PrefsFormTable from '../components/PrefsFormTable.jsx';

const squareTypeEnum = {
    'no_square': 'Обслуживаемая площадь',
    'full_square': 'Общая площадь',
    'living_square': 'Жилая площадь',
    'heated_square': 'Отапливаемая площадь',
    'service_square': 'Обслуживаемая площадь',
}

export default function SingleCorrModal() {
    const {t} = useTranslation()
    const selectedAcc = useUnit($selectedAccMeta)
    const selectedSrv = useUnit($selectedAccSrv)

    const [srvForm] = Form.useForm();
    const selectedTarif = Form.useWatch('tarif_id', srvForm)
    const peopleQuant = Form.useWatch('people_quantity', srvForm)

    useEffect(() => {
        if (selectedSrv) {
            for (const [key, value] of Object.entries(selectedSrv)) {
                if (['tariffs', 'tarif_id', 'preferences', 'service_square', 'people_quantity', 'ex_payments', 'sum_saldo_end', 'external_number', 'date_to', 'is_not_accrued', 'is_not_balance_transfer', 'is_not_printed'].includes(key) && value) {
                    if (key === 'date_to') {
                        srvForm.setFieldValue(key, value && dayjs(value).isValid() ? dayjs(value) : null)
                    } else if (key === 'ex_payments') {
                        srvForm.setFieldValue('ex_payment_value', value)
                    } else if (key === 'sum_saldo_end') {
                        srvForm.setFieldValue('saldo_end_value', value)
                    } else if (key === 'tariffs' && !selectedSrv?.tarif_id) {
                        const tarId = value[0]?.tarif_id ?? undefined
                        srvForm.setFieldValue('tarif_id', tarId)
                    } else if (key === 'tarif_id') {
                        srvForm.setFieldValue('tarif_id', value)
                    } else {
                        srvForm.setFieldValue(key, value)
                    }
                }
            }
        }
    }, [selectedSrv])

    const submit = useUnit(submitEditSingleCorrEv)
    const resetSrv = useUnit(resetSelectedAccSrvEv)
    const status = useUnit($srvCreateEditDone)

    const psInfo = useUnit($psData)
    const getPSTarifs = useUnit(getPSTarifsEv)
    const psTarifs = useUnit($psTarifsSL)
    const createTariff = useUnit(createTariffEv)
    const createdTariffId = useUnit($tarifCreated)

    const srvsMap = useStoreMap($infoMaps, (maps) => maps.servicesMap)
    const srvUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)
    const tarAlgoMap = useUnit($tarifAlgoMap)

    const {showPeople, showSquare} = useMemo(() => {
        return {
            showPeople: tarAlgoMap[selectedTarif]?.with_people_quantity,
            showSquare: tarAlgoMap[selectedTarif]?.with_square
        }
    }, [selectedTarif, tarAlgoMap])

    useEffect(() => {
        if (showPeople && selectedSrv) {
            srvForm.setFieldValue('people_quantity', selectedSrv?.people_quantity >= 0 ? selectedSrv?.people_quantity : selectedAcc?.people_quantity ?? 0)
        } else {
            srvForm.resetFields(['people_quantity'])
        }

        if (showSquare && selectedSrv) {
            srvForm.setFieldValue('service_square', selectedSrv?.service_square >= 0 ? selectedSrv?.service_square : selectedAcc?.service_square ?? 0)
        } else {
            srvForm.resetFields(['service_square'])
        }
    }, [showPeople, showSquare, selectedSrv]);

    useEffect(() => {
        if (!showPeople) {
            srvForm.setFieldValue('preferences', [])
        }
    }, [showPeople]);

    const onCancel = () => {
        srvForm.resetFields()
        resetSrv()
    }

    const onFinish = (values) => {
        submit(values)
        onCancel()
    }

    const onSubmitTariff = async () => {
        try {
            let values

            values = await srvForm.validateFields(['new_tarif'])
            const {tarif_value, tarif_service_unit_id} = values['new_tarif']

            if (tarif_value && tarif_service_unit_id) {
                createTariff({tarif_value, tarif_service_unit_id})
            }
        } catch (e) {
            message.error(t('Заполните необходимые поля'))
        }
    }

    useEffect(() => {
        if (status) {
            onCancel()
        }
    }, [status])

    useEffect(() => {
        if (selectedSrv && selectedSrv?.provider_id && selectedSrv?.service_id) {
            getPSTarifs({provider_id: selectedSrv?.provider_id, service_id: selectedSrv?.service_id})
        }
    }, [selectedSrv]);

    useEffect(() => {
        if (selectedSrv && createdTariffId && selectedSrv?.provider_id && selectedSrv?.service_id) {
            srvForm.setFieldValue('tarif_id', createdTariffId)
            getPSTarifs({provider_id: selectedSrv?.provider_id, service_id: selectedSrv?.service_id})
        }
    }, [createdTariffId, selectedSrv]);

    const switchIsNotAccrued = (is_not_accrued) => {
        if (is_not_accrued === false) {
            srvForm.setFieldValue('is_not_balance_transfer', false)
        }
    }

    const switchIsNotBalanceTransfer = (is_not_balance_transfer) => {
        if (is_not_balance_transfer === true) {
            srvForm.setFieldValue('is_not_accrued', true)
        }
    }

    const prefDataSource = useMemo(() => {
        if (selectedSrv?.preferences && selectedSrv?.preferences?.length > 0) {
            let prefs = selectedSrv?.preferences
            if (typeof selectedSrv?.preferences === 'string') {
                prefs = prefs?.length > 0 && prefs !== '[]' ? JSON.parse(prefs) : []
            }
            return prefs?.length > 0 ? [{id: -1}, ...prefs] : [{id: -1}]
        } else if (selectedAcc) {
            let prefs = selectedAcc?.preferences
            if (selectedSrv?.provider_id && selectedSrv?.service_id) {
                prefs = selectedAcc?.preferences?.filter(p => p.provider_id === selectedSrv?.provider_id && p.service_id === selectedSrv?.service_id)
            }
            return prefs?.length > 0 ? [{id: -1}, ...prefs] : [{id: -1}]
        } else return []
    }, [selectedAcc, selectedSrv])

    const maxPeople = useMemo(() => {
        if (peopleQuant && peopleQuant >= 0) {
            return peopleQuant
        } else {
            return 0
        }
    }, [selectedAcc, peopleQuant])

    return <Modal open={!!selectedSrv}
                  title={`${t('Изменение услуги')} ${srvsMap[selectedSrv?.service_id]}`}
                  onCancel={onCancel}
                  onOk={() => srvForm.submit()}
                  okText={t('Сохранить')}
                  okButtonProps={{disabled: selectedTarif === -1}}
                  width={'60vw'}
    >
        <Form layout={'vertical'} form={srvForm} onFinish={onFinish}>
            <Row gutter={[16, 0]}>
                <Col span={6}>
                    <Form.Item name={'tarif_id'} label={t('Тариф')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select
                            options={[...psTarifs, {label: t('Создать'), value: -1, emoji: true}]}
                            style={{width: '100%'}}
                            optionRender={optionRender}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}
                        />
                    </Form.Item>
                </Col>
                {showPeople && <Col span={6}>
                    <Form.Item name={'people_quantity'} label={t('Кол-во проживающих')}>
                        <InputNumber placeholder={'-'} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>}
                {showSquare && <Col span={6}>
                    <Form.Item name={'service_square'} label={t(squareTypeEnum[psInfo?.square_type] ?? 'Обслуживаемая площадь')}>
                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>}
                <Col span={4}>
                    <Form.Item name={'ex_payment_value'} label={t('Сторонний платеж')}>
                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={'saldo_end_value'} label={t('Конечное cальдо')}>
                        <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}} />
                    </Form.Item>
                </Col>
            </Row>
            {selectedTarif === -1 && <>
                <Form.Item name={['new_tarif', 'tarif_value']} label={t('Значение тарифа')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <InputNumber precision={2} decimalSeparator={','} parser={v => Number(v.replace(',', '.'))} placeholder={'-'} style={{width: '100%'}} />
                </Form.Item>
                <Form.Item name={['new_tarif', 'tarif_service_unit_id']} label={t('Единица измерения')}
                           rules={[{required: true, message: t('Обязательное поле')}]}
                >
                    <Select options={srvUnitOpts}
                            style={{width: '100%'}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                            dropdownStyle={{minWidth: 'max-content'}}/>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type={'primary'} onClick={onSubmitTariff}>{t('Сохранить')}</Button>
                        <Button danger
                                onClick={() => {
                                    srvForm.resetFields(['tarif_id', ['new_tarif', 'tarif_value'], ['new_tarif', 'tarif_service_unit_id']])
                                }}
                        >
                            {t('Отмена')}
                        </Button>
                    </Space>
                </Form.Item>
            </>}
            <Row gutter={[16, 0]}>
                <Col span={6}>
                    <Form.Item name={'date_to'} label={t('Действует по')}>
                        <DatePicker style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Row gutter={[16, 0]}>
                        <Col span={8}>
                            <Form.Item name={'is_not_accrued'} label={t('Не начислять')} valuePropName={'checked'}>
                                <Switch onChange={switchIsNotAccrued}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'is_not_balance_transfer'} label={t('Не переводить')} valuePropName={'checked'}>
                                <Switch onChange={switchIsNotBalanceTransfer}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name={'is_not_printed'} label={t('Не печатать')} valuePropName={'checked'}>
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                {psInfo?.use_external_number && <Col span={6}>
                    <Form.Item name={'external_number'} label={t('№ договора пост-ка с потр-ем')}>
                        <Input/>
                    </Form.Item>
                </Col>}
                {psInfo?.is_iin_provided && <Col span={6}>
                    <Form.Item name={'iin'} label={t('ИИН потребителя')}>
                        <Input/>
                    </Form.Item>
                </Col>}
            </Row>
            <Form.Item hidden name={'preferences'}/>
        </Form>
        {showPeople ? <PrefsFormTable
            prefs={prefDataSource} maxPeople={maxPeople} srvForm={srvForm}
            srvId={selectedSrv?.service_id ?? null}
        /> : null}
    </Modal>
}
