import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    CheckOutlined, CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined, SettingOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import {Popconfirm} from 'antd';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";

import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {
    $usersList,
    dataUsrPrvModalEv,
    deleteUsersEv,
    getUsersListFx, openUsrPrvModalEv,
    UsersListGate
} from "../../models/dictionaryUsersModel/index.js";
import {$userPerms} from '../../models/infoModel/index.js';
import React from "react";
import UserProviderModal from "./components/UserProviderModal.jsx";

const isActiveOpts = [
    {
        label: 'Активен',
        vaule: true
    },
    {
        label: 'Не активен',
        vaule: false
    },
]

export default function DictionaryUsersList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($usersList)
    const loading = useUnit(getUsersListFx.pending)
    const onDelete = useUnit(deleteUsersEv)
    const perms = useUnit($userPerms)

    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.users`)

    const setModalData = useUnit(dataUsrPrvModalEv)
    const openModal = useUnit(openUsrPrvModalEv)

    const onOpenModal = (payload) => {
        setModalData(payload)
        openModal()
    }

    const columns = [
        {
            width: '5%',
            title: t(`Код`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t('Логин'),
            dataIndex: 'login',
            sorter: true,
            key: 'login',
            render: (login) => login ? login : '-'
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
            sorter: true,
            key: 'name_ru',
            render: (town_id) => <MappedDictItem id={town_id} type={'town'} />
        },
        {
            title: t('Должность'),
            dataIndex: 'position_id',
            sorter: true,
            key: 'position_id',
            render: (position_id) => <MappedDictItem id={position_id} type={'position'} />
        },
        {
            title: t('Роль'),
            dataIndex: 'role_id',
            sorter: true,
            key: 'role_id',
            render: (role_id) => <MappedDictItem id={role_id} type={'role'} />
        },
        {
            title: t('Руководитель'),
            dataIndex: 'chief_id',
            sorter: true,
            key: 'chief_id',
            render: (chief_id, record) => record.chief_id === null ? '-' : <MappedDictItem id={record.chief_id} type={'users'} />
        },

        {
            title: t('Активен'),
            dataIndex: 'is_active',
            sorter: true,
            key: 'is_active',
            render: (is_active) => is_active ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
        },
        {
            title: t('E-mail'),
            dataIndex: 'email',
            sorter: true,
            key: 'email',
            render: (email) => email ? email : '-'
        },
        {
            title: t('Телефон'),
            dataIndex: 'phone',
            sorter: true,
            key: 'phone',
            render: (phone) => phone ? phone : '-'
        },


        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id,row) => <ButtonGroup>
                {perms?.users?.update && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/users/edit/${id}`}><Button icon={<EditOutlined/>}
                                                                        type={'primary'}/></Link>
                </Tooltip>}
                {perms?.users?.update && <Tooltip title={t('Назначить поставщиков')} placement={'top'}>
                    <Button icon={<SettingOutlined/>} type={'primary'} onClick={() => onOpenModal(row)}/>
                </Tooltip>}

                {perms?.users?.view && <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/users/view/${id}`}><Button
                        icon={<EyeOutlined/>}/></Link>
                </Tooltip>}
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                {perms?.users?.delete && <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                             okText={t('Да')}
                             onConfirm={() => onDelete(id)}
                             placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>}
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'code_name', label: t('Код/ФИО'), type: 'input'},
        {name: 'position_id', label: t('Должность'), type: 'select', dict: 'position'},
        {name: 'role_id', label: t('Роль'), type: 'select', dict: 'role'},
        {name: 'chief_id', label: t('Руководитель'), type: 'select', dict: 'users'},
        {name: 'login', label: t('Логин'), type: 'input'},
        {name: 'email', label: t('E-mail'), type: 'input'},
        {name: 'phone', label: t('Телефон'), type: 'input'},
        {name: 'is_active', label: t('Статус'), type: 'select', options: isActiveOpts},

        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <UsersListGate search={search.toString()}/>
        {/*<FilterBlock items={filters}/>*/}
        <UserProviderModal />
        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
        />
    </>
}
