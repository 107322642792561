import dayjs from 'dayjs';
import {useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {
    $selectedTown,
    TownViewGate,
    getTownByIdFx
} from '../../../models/dictionaryTownsModel/index.js';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import DistrictsTable from '../../../components/Embedded/DistrictsTable/DistrictsTable.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import StreetsTable from '../../../components/Embedded/StreetsTable/StreetsTable.jsx';

export default function ViewTown() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getTownByIdFx.pending)
    const city = useUnit($selectedTown)
    const genitive_form = t(`breadcrumbs.genitive.town`)

    const items = [
        {
            key: 'name_ru',
            label: t(`Название ${genitive_form} (рус)`),
            children: city?.name_ru
        },
        {
            key: 'name_kz',
            label: t(`Название ${genitive_form} (каз)`),
            children: city?.name_kz
        },
        {
            key: 'index',
            label: t('Индекс'),
            children: city?.index
        }
    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={city?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: dayjs(city?.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: city?.updated_by ? <CreatedBy id={city?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: city?.updated_at ? dayjs(city?.updated_at).format('DD.MM.YYYY HH:mm:ss') : '-'
        }
    ]

    return <>
        <TownViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр населенного пункта')}</span> <MappedDictItem id={id} type={'town'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'town'}/>
            <Divider orientation={'left'} orientationMargin={0}>{t('Районы')}</Divider>
            <DistrictsTable view parent={'town_id'} parentId={+id}/>
            <Divider orientation={'left'} orientationMargin={0}>{t('Улицы')}</Divider>
            <StreetsTable view parent={'town_id'} parentId={+id}/>
        </Card>
    </>
}
