import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $taxBranchesList, TaxBranchesListGate,
    deleteTaxBranchEv,
    getTaxBranchesListFx
} from '../../models/dictionaryTaxBranchModel';
import {Link, useSearchParams} from 'react-router-dom';
import {useMemo} from 'react';
import MappedDictItem from '../../components/MappedDictItem/MappedDictItem.jsx';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Popconfirm from 'antd/es/popconfirm';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    HistoryOutlined,
} from '@ant-design/icons';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {$userPerms} from '../../models/infoModel/index.js';

export default function DictionaryTaxBranchesList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($taxBranchesList)
    const loading = useUnit(getTaxBranchesListFx.pending)
    const deleteTaxBranch = useUnit(deleteTaxBranchEv)
    const perms = useUnit($userPerms)

    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.tax_branch`)


    const initColumns = useMemo(() => [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
            render: (id) => <Link to={`/dictionaries/tax_branch/view/${id}`}>{id}</Link>
        },
        {
            width: 100,
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            sorter: true,
            key: 'town_id',
            render: (t_id) => <MappedDictItem id={t_id} type={'town'}/>
        },
        {
            width: 100,
            title: t('Район города'),
            dataIndex: 'town_distr_id',
            sorter: true,
            key: 'town_distr_id',
            render: (town_distr_id) => <MappedDictItem id={town_distr_id} type={'town_distr'}/>
        },
        {
            width: 100,
            title: t(`Название ${genitive_form} (каз)`),
            dataIndex: 'name_kz',
            key: 'name_kz',
            sorter: true,
        },
        {
            width: 100,
            title: t(`Название ${genitive_form} (рус)`),
            dataIndex: 'name_ru',
            key: 'name_ru',
            sorter: true,
        },
        {
            width: 100,
            title: t(`Кр. Название ${genitive_form} (каз)`),
            dataIndex: 'short_name_kz',
            key: 'short_name_kz',
            sorter: true,
        },
        {
            width: 100,
            title: t(`Кр. Название ${genitive_form} (рус)`),
            dataIndex: 'short_name_ru',
            key: 'short_name_ru',
            sorter: true,
        },
        {
            width: 100,
            title: t('Адрес (каз)'),
            dataIndex: 'address_kz',
            key: 'address_kz',
            sorter: true,
        },
        {
            width: 100,
            title: t('Адрес (рус)'),
            dataIndex: 'address_ru',
            key: 'address_ru',
            sorter: true,
        },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: 100,
                    key: 'created_at',
                    sorter: true,
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    width: 100,
                    dataIndex: 'created_by',
                    sorter: true,
                    key: 'created_by',
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    width: 100,
                    key: 'updated_at',
                    sorter: true,
                    render: (date, record) => record.updated_at ? <CustomDates data={date} /> : '-'
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    sorter: true,
                    width: 100,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => <ButtonGroup>
                {perms?.tax_branch?.update && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/dictionaries/tax_branch/edit/${id}`}><Button icon={<EditOutlined/>}
                                                                             type={'primary'}/></Link>
                </Tooltip>}
                {perms?.tax_branch?.view && <Tooltip title={t('Просмотр')} placement={'top'}>
                    <Link to={`/dictionaries/tax_branch/view/${id}`}><Button icon={<EyeOutlined/>}/></Link>
                </Tooltip>}
                <Tooltip title={t('История изменений')} placement={'top'}>
                    <Button icon={<HistoryOutlined />}/>
                </Tooltip>
                {perms?.tax_branch?.delete && <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                             okText={t('Да')}
                             onConfirm={() => deleteTaxBranch(id)}
                             placement={'left'}
                >
                    <Tooltip title={t('Удалить')} placement={'top'}>
                        <Button icon={<DeleteOutlined/>} danger/>
                    </Tooltip>
                </Popconfirm>}
            </ButtonGroup>
        }
    ], [])

    const filters = [
        {name: 'code_name', label: t('Код/Название'), type: 'input'},
        {name: 'town_id', label: t('Населенный пункт'), type: 'select', dict: 'towns'},
        {name: 'town_distr_id', label: t('Район города'), type: 'select', dict: 'districts', multiple: true},
        {name: 'short_name', label: t('Кр. название'), type: 'input'},
        {name: 'address', label: t('Адрес'), type: 'input'},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]



    return <>
        <TaxBranchesListGate search={search.toString()}/>
        <FilterBlock items={filters}/>

        <DictionaryProTable data={data} initColumns={initColumns} count={count} loading={loading} exportXlsAction={exportXlsAction}/>
    </>
}
