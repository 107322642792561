import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useUnit, useStoreMap} from "effector-react";
import './components/style.css'
import {

    $paymentData,
    dataPaymentConfirmModalEv,
    exportPaymentConfirmEv,
    getPaymentByIdEv,
    getPaymentDataFx,
    openPaymentConfirmModalEv,
    PaymentGate
} from '../../models/paymentModel/index.js';
import {searchAndActions} from "../../utils/searchAndActions.js";
import FilterBlock from "../../components/FiltersBlock/FiltersBlock.jsx";
import Table from "antd/es/table";
import Divider from "antd/es/divider";
import Tabs from "antd/es/tabs";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import {Link} from "react-router-dom";
import ButtonGroup from "antd/es/button/button-group";
import Tooltip from "antd/es/tooltip";
import {
    QuestionCircleOutlined,
    CheckOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import {Popconfirm} from "antd";
import {$dictsSelects, $infoMaps} from "../../models/infoModel/index.js";
import ExcelOutlined from "../../components/ExcelIcon/EcxelIcon.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import FormatNumber from "../../components/FormatNumber/FormatNumber.jsx";
import CreatedBy from "../../components/CreatedBy/CreatedBy.jsx";
import Card from "antd/es/card";
import {paginationConfig} from "../../utils/paginationConfig.js";
import PaymentTable from "./components/PaymentTable.jsx";
import PaymentConfirmModal from "./components/PaymentConfirmModal.jsx";
import Switch from "antd/es/switch";


const options = [
    {
        label: 'Подтвержден',
        value: true,
    },
    {
        label: 'Не подтвержден',
        value: false,
    },
]


export default function PaymentPage() {
    const {t} = useTranslation()
    const {data} = useUnit($paymentData)
    const {search} = searchAndActions()
    const loading = useUnit(getPaymentDataFx.pending)

    const exportXlsAction = useUnit(exportPaymentConfirmEv)

    const keysData = data.map(item => {
        return {
            ...item,
            key: item.id
        }
    }).filter(item => !dayjs(item?.payment_date).isSame(dayjs(), 'day'));


    const dataPaymentFalse = keysData.filter(item => item.confirmation_status !== true)

    const bankOpts = useStoreMap($dictsSelects, sel => sel.bankBranch)?.filter(item => item.is_contract_signed === true)
    const bankBranch = useStoreMap($infoMaps, sel => sel.bankBranchMap)




    // const loading = useUnit(getPaymentDataFx.pending)
    const [selectedRowKey, setSelectedRowKey] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [checkStatus, setCheckStatus] = useState(false);
    const [bankDay, setBankDay] = useState(false);
    const [date, setDate] = useState(null);
    const [activeKey, setActiveKey] = useState('view');
    const [currentPayment, setCurrentPayment] = useState(null);

    const selectedSort = selectedRowData ? selectedRowData?.sort((a, b) => new Date(a.payment_date) - new Date(b.payment_date)) : []

    const lastSelected = selectedSort[selectedSort?.length - 1]

    const dataFalseSort = dataPaymentFalse?.sort((a, b) => new Date(a.payment_date) - new Date(b.payment_date))
    const lastDataFalse = dataFalseSort[dataFalseSort?.length - 1]


    const getPayment = useUnit(getPaymentByIdEv)
    const openModal = useUnit(openPaymentConfirmModalEv)
    const dataModal = useUnit(dataPaymentConfirmModalEv)


    console.log('bankBranch', bankBranch)
    console.log('data',data)


    const columns = [
        {
            title: t('Дата платежа'),
            dataIndex: 'payment_date',
            key: 'payment_date',
            defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.payment_date) - new Date(b.payment_date),
            render: (date) => <CustomDates data={date} type={'day'}/>

        },
        {
            title: t('Банк'),
            dataIndex: 'bank_id',
            key: 'bank_id',
            sorter: (a, b) => a.bank_id - b.bank_id,
            render: (bank_id) => {
                return <Link to={`/dictionaries/banks/view/${bankBranch[bank_id]?.id}`}>
                    {
                        `${bankBranch[bank_id]?.branch} ${bankBranch[bank_id]?.name}`
                    }
                </Link>
            }

        },
        {
            title: t('Банк. день'),
            dataIndex: 'bank_day',
            key: 'bank_day',
            sorter: (a, b) => new Date(a.bank_day) - new Date(b.bank_day),
            render: (date) => <CustomDates data={date} type={'day'}/>
        },
        {
            title: t('Кол-во платежей'),
            dataIndex: 'payment_count',
            key: 'payment_count',
            sorter: (a, b) => a.payment_count - b.payment_count,
        },
        {
            title: t('Сумма'),
            dataIndex: 'payment_sum',
            key: 'payment_sum',
            sorter: (a, b) => a.payment_sum - b.payment_sum,

            render: (value) => <FormatNumber value={value} type={2}/>
        },
        {
            title: t('Статус'),
            dataIndex: 'confirmation_status',
            key: 'confirmation_status',
            align: 'center',
            sorter: (a, b) => a.confirmation_status.localeCompare(b.confirmation_status),
            render: (value) => value === true ?
                <CheckOutlined style={{color: 'lightgreen', fontSize: 18}}/> :
                <CloseOutlined style={{color: 'red', fontSize: 18}}/>
        },
        {
            title: t('Тип подтв.'),
            dataIndex: 'confirmation_type',
            key: 'confirmation_type',
            sorter: (a, b) => a.confirmation_type - b.confirmation_type,
            render: (value) => value === 'auto' ? 'Автоматически' : 'Вручную'
        },
        {
            title: t('Ответственный'),
            dataIndex: 'confirmation_user_id',
            key: 'confirmation_user_id',
            sorter: (a, b) => a.confirmation_user_id - b.confirmation_user_id,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Дата действия'),
            dataIndex: 'confirmation_date',
            key: 'confirmation_date',
            sorter: (a, b) => new Date(a.confirmation_date) - new Date(b.confirmation_date),
            render: (date) => <CustomDates data={date}/>
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id, row) => <ButtonGroup>
                <Popconfirm
                    title={t('Вы принимаете на себя ответственность подтверждения в ручном режиме?')}
                    description={t('Отменить данное действие не возможно! Вы уверены что хотите подтвердить?')}
                    okText={t('Да')}
                    onConfirm={() => onOpenModal('single', row)}
                    // disabled={}
                    placement={'left'}
                    icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                >
                    <Tooltip title={t('Подтвердить эту запись')} placement={'top'}>
                        <Button
                            type={'primary'}
                            size={'small'}
                            // disabled={row.confirmation_status}
                            disabled={
                                keysData.some(item =>
                                    item?.payment_date < row?.payment_date &&
                                    item?.bank_id === row?.bank_id &&
                                    item?.confirmation_status === false
                                )
                                || row.confirmation_status
                            }
                        >
                            Подтвердить
                        </Button>
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }

    ]


    const filters = [
        // {name: 'bank_id', label: t('Банк'), type: 'select', options: bankOpts},
        {name: 'branch_name', label: t('Отделение'), type: 'input'},
        {name: 'payment_date_from', label: t('Дата от'), type: 'date'},
        {name: 'payment_date_to', label: t('Дата до'), type: 'date'},
        {name: 'confirmation_status', label: t('Статус'), type: 'select', options: options},
    ]

    const onSelectChange = (newSelectedRowKeys, newSelectedRowData) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRowData(newSelectedRowData)
        checkInBankDates(newSelectedRowData)
    };

    const checkInBankDates = (newSelected) => {
        const sorted = newSelected.sort((a, b) => new Date(a.payment_date) - new Date(b.payment_date));
        const selectedIds = newSelected.map(item => item.id)
        const last = sorted[sorted.length - 1]
        const result = data.some(item =>
            item?.payment_date < last?.payment_date &&
            item?.bank_id === last?.bank_id &&
            item?.confirmation_status === false &&
            !selectedIds.includes(item.id)
        )
        setCheckStatus(result)
    }

    const onOpenModal = (actionType, data) => {
        let payload = null
        if (actionType === 'selected' || actionType === 'all') {
            const raw = actionType === 'selected' ? selectedRowData : dataPaymentFalse
            const rawSorted = raw.sort((a, b) => new Date(a.payment_date) - new Date(b.payment_date));
            payload = {
                payment_confirm: raw?.map(item => {
                    return {
                        payment_date: item.payment_date,
                        bank_id: item.bank_id,
                    }
                }),
                bank_day: dayjs(rawSorted[rawSorted.length - 1].payment_date).format(),
            }
            if (bankDay === null) {
                payload.bank_day = null
            }
        }
        dataModal({
            page: 'confirm',
            type: actionType,
            data: actionType === 'single' ? data : payload
        })
        openModal()
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.confirmation_status,
        }),
    };

    const onChangeTab = (payload) => {
        setActiveKey(payload)
    }

    const handleRowClick = (record) => {
        setSelectedRowKey(record.key);
        getPayment(record.id)
        setCurrentPayment(record.id)
    };

    const tabs = [
        {
            key: 'view',
            label: t('Платежи'),
            children: <PaymentTable current={currentPayment}/>
        },
        {
            key: 'error',
            label: t('Ошибки'),
            children: <div/>
        },
    ]

    const rowClassName = (record) => {
        return record.key === selectedRowKey ? 'payment-selected-row' : '';
    };

    return <>
        <PaymentGate search={search.toString()}/>
        <PaymentConfirmModal resetSelected={setSelectedRowData}/>
        <FilterBlock items={filters}/>
        <Divider />
        <Row gutter={[8, 0]} style={{marginTop: '8px'}}>
            <Col span={6}>
                <Row gutter={[8, 0]} justify={'start'}>
                    <Col span={2}>
                        <Switch
                            value={bankDay}
                            size={'small'}
                            onChange={() => setBankDay(!bankDay)}
                        />
                    </Col>
                    <Col span={22}>
                        Одним банковским днем
                    </Col>
                </Row>
            </Col>
            <Col span={14}>
                <Row gutter={[16, 16]}>
                    <Col>
                        <Popconfirm
                            title={t('Вы принимаете на себя ответственность подтверждения?')}
                            description={t('Отменить данное действие не возможно! Вы уверены что хотите подтвердить?')}
                            okText={t('Да')}
                            onConfirm={() => onOpenModal('all', dataPaymentFalse)}
                            placement={'left'}
                            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                        >
                            <Button type={'primary'}
                                    disabled={dataPaymentFalse.length > 0 ? false : true}
                                    size={'small'}>

                                Подтвердить все не подтвержденные

                                {
                                    bankDay === true && dataPaymentFalse.length > 0 ? ` (${dataPaymentFalse.length} строк., датой ${dayjs(lastDataFalse.payment_date).format('DD.MM.YYYY')})` :
                                        ` (${dataPaymentFalse.length})`
                                }

                            </Button>
                        </Popconfirm>
                    </Col>
                    <Col>
                        <Popconfirm
                            title={t('Вы принимаете на себя ответственность подтверждения?')}
                            description={t('Отменить данное действие не возможно! Вы уверены что хотите подтвердить?')}
                            okText={t('Да')}
                            onConfirm={() => onOpenModal('selected', selectedRowData)}
                            placement={'left'}
                            icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
                        >
                            <Button type={'primary'}
                                    disabled={(checkStatus === false && selectedRowData?.length > 0) ? false : true}
                                    size={'small'}>
                                Подтвердить отмеченные

                                {
                                    bankDay === true && selectedRowData?.length > 0 ? ` (${selectedSort.length} строк., датой ${dayjs(lastSelected.payment_date).format('DD.MM.YYYY')})` :
                                        ` (${selectedRowData?.length})`
                                }

                            </Button>
                        </Popconfirm>
                    </Col>
                    <Col>
                        <Button
                            size={'small'}
                            onClick={() => exportXlsAction(search)}
                        >
                            <ExcelOutlined size={18}/>
                            {t('Экспорт')}
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Card size={'small'} loading={loading}>
            <div style={{color: 'red', marginLeft: '10px'}}>
                {
                    checkStatus === true ? t('Нельзя пропускать дни в распределении') : ''
                }
            </div>
            <Table
                columns={columns}
                dataSource={keysData}
                size={'small'}
                rowSelection={rowSelection}
                rowClassName={rowClassName}
                onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                })}
                pagination={{
                    ...paginationConfig,
                    pageSize: 10,
                    // current: parseInt(page),
                    // total: count
                }}
            />
        </Card>
        <Divider/>
        <Tabs
            defaultActiveKey={'view'}
            items={tabs}
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={onChangeTab}
            type={'card'}
        />
    </>
}
