import {createGate} from 'effector-react';
import {createEvent} from 'effector';


export const ServicePaymentTypeListGate = createGate()
export const ServicePaymentTypeViewGate = createGate()
export const ServicePaymentTypeEditGate = createGate()
export const ServicePaymentTypeCreateGate = createGate()

export const submitCreateServicePaymentTypeEv = createEvent()
export const submitEditServicePaymentTypeEv = createEvent()
export const deleteServicePaymentTypeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()