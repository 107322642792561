import {createStore} from 'effector';


export const $bankCommissionList = createStore({data: [], count: 0})

export const $selectedBankCommission = createStore(null)

export const $editBankCommissionStatus = createStore(false)

export const $createdBankCommissionId = createStore(null)
export const $afterCreateAction = createStore(null)
