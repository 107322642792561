import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {$templatePaymentsList} from "./stores.js";

export const TemplatePaymentsListGate = createGate()
export const TemplatePaymentsViewGate = createGate()
export const TemplatePaymentsEditGate = createGate()
export const TemplatePaymentsCreateGate = createGate()

export const submitCreateTemplatePaymentsEv = createEvent()
export const submitEditTemplatePaymentsEv = createEvent()
export const deleteTemplatePaymentsEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
