import {useState} from "react";
import {useSearchParams} from 'react-router-dom';
import Tabs from 'antd/es/tabs';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';

import {$infoMaps} from '../../models/infoModel/index.js';
import TasksStatuses from "../../components/TasksStatuses/TasksStatuses.jsx";
import FileManager from "./components/FileManager.jsx";
import EmailSubApprove from "./components/EmailSubApprove.jsx";
import {
    $emailSubApproveList
} from '../../models/emailSubApproveModel/index.js';

export default function EmailApproveContainer() {
    const {t} = useTranslation()
    const {data, count} = useUnit($emailSubApproveList)
    const users = useStoreMap($infoMaps, info => info.usersMap)

    const [activeKey, setActiveKey] = useState('files');

    const onChangeTab = (payload) => {
        setActiveKey(payload)
    }

    const [search] = useSearchParams()
    console.log('test git')

    const tabs = [
        {
            key: 'files',
            label: t('Массовая загрузка'),
            // children: <TasksStatuses name={'print_pdfs'}/>
            children: <FileManager table_name={'email_dispatch'}/>
        },
        {
            key: 'approve',
            label: t('Заявки'),
            children: <EmailSubApprove />
        },
        // {
        //     key: 'status',
        //     label: t('Статусы задач'),
        //     children: <TasksStatuses name={'email_dispatch'}/>
        //
        // }
    ]

    return <>
        <Tabs
            defaultActiveKey={'files'}
            items={tabs}
            destroyInactiveTabPane
            activeKey={activeKey}
            onChange={onChangeTab}
            type={'card'}
        />
        {/*<EmailSubApproveListGate search={search}/>*/}
        {/*<Card>*/}
        {/*    <FilterBlock items={filterItems}/>*/}
        {/*    <Divider />*/}
        {/*    <DictionaryProTable data={data} count={count} initColumns={columns} showActions={false}/>*/}
        {/*</Card>*/}
    </>
}
