import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useEffect} from "react";
import {
    CheckCircleOutlined, CheckOutlined,
    CloseCircleOutlined, CloseOutlined, EyeOutlined,
    LoadingOutlined,
    UploadOutlined,
    StopOutlined, DownloadOutlined, FileExclamationOutlined,
} from '@ant-design/icons';
import ExcelOutlined from "../../components/ExcelIcon/EcxelIcon.jsx";
import {
    $fileList, acceptFileEv, exportXlsListEv,
    FilesGate, getFilesListFx, rejectFileEv, sendMessageUploadEv,
    setUploadModalEv, stopUploadFileEv
} from '../../models/uploadCashPaymentsModel/index.js';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Divider from 'antd/es/divider';
import UploadCashPaymentsModal from './components/UploadCashPaymentsModal.jsx';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {$WsSt} from "../../models/webSocketModel/index.js";
import {useWebSocket} from "../../api/WebSocketProvider.jsx";
import ButtonGroup from 'antd/es/button/button-group';
import {Popconfirm, Progress} from 'antd';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames} from "../../utils/monthNamesDict.js";
import {downloadErrorFileEv} from "../../models/uploadCashPaymentsModel";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {$dictsSelects, $info, $infoMaps} from "../../models/infoModel/index.js";

export default function UploadCashPayments() {
    const {t} = useTranslation()
    const {data, count} = useUnit($fileList)
    const loading = useUnit(getFilesListFx.pending)
    const setModal = useUnit(setUploadModalEv)
    const { sendMessage } = useWebSocket();

    const [search] = useSearchParams()

    const exportXls = useUnit(exportXlsListEv)

    const accept = useUnit(acceptFileEv)
    const stopUpload = useUnit(stopUploadFileEv)
    const reject = useUnit(rejectFileEv)

    const bankOpts = useStoreMap($dictsSelects, sel => sel.bank)
    const kassaMap = useStoreMap($infoMaps, sel => sel.kassaMap)



    const downloadErrorFile = useUnit(downloadErrorFileEv)

    const wsStatus = useUnit($WsSt)

    useEffect(() => {
        const payload = {
            sendMessage: sendMessage,
            payload: {
                object_type: "import_provider_payments",
                action: 'get_list',
                subscribe: true
            }
        }
        sendMessageUploadEv(payload)

    }, [wsStatus]);

    const columns = [
        {
            title: t('Статус загрузки'),
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (status, record) => <Tooltip
                title={
                    status === 'new' || status === 'processing file'
                        ? t('В обработке')
                        : status === 'file processed' || status === 'file approved' || status === 'adding to the table failed' || status === 'file disapproved' || status === 'adding to the table failed'
                            // ? record.load_error
                            ? t('Успешно')
                            : status === "processing stopped"
                                ? t('Остановлено')
                                : status === "error" || status === 'processing failed'
                                    ? t('Ошибка')
                                    :  '-'
                }
                placement={'right'}
            >
                {
                    status === 'new' || status === 'processing file'
                        ? <div>
                            <Progress type="circle" size={40} percent={parseInt(record.progress)}/>
                        </div>
                        : status === "file processed" ||  status === 'file approved' || status === 'adding to the table failed' || status === 'file disapproved' || status === 'adding to the table failed'
                            ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
                            : status === "processing stopped" ? <StopOutlined style={{color: 'red', fontSize: 24}}/>
                                : status === "error" || status === 'processing failed' ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                                    : '-'
                }
            </Tooltip>
        },
        {
            title: t('Статус принятия'),
            dataIndex: 'is_approved',
            key: 'is_approved',
            align: 'center',
            width: '5%',
            render: (accepted, record) => record.stage === "Перенос данных"  ?
                <Tooltip title={record.stage}>
                    <LoadingOutlined style={{fontSize: 24}}/>
                </Tooltip> :
                accepted === true ?
                    <Tooltip title={'Файл подтвержден'}> <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>  </Tooltip>:
                    accepted === false ?
                        <Tooltip title={'Файл отклонен'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                        // accepted === null && record.status === 'adding to the table failed' ?
                        record.status === 'adding to the table failed' ?
                            <Tooltip title={'Ошибка'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                            '-'
        },
        {
            title: t('Название файла'),
            dataIndex: 'file_name',
            sorter: true,
            key: 'filename',
            render: (filename) => filename.length > 20 ?
                <Tooltip title={filename}> {filename.substring(0, 20) + '...'}</Tooltip> :
                <Tooltip title={filename}>{filename}</Tooltip>
        },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            key: 'provider_id',
            sorter: true,
            width: '25%',
            render: (provider_id, row) => <MappedDictItem id={row?.params?.provider_id} type={'providers'}/>,
        },
        {
            title: t('Касса'),
            dataIndex: 'bank_id',
            key: 'bank_id',
            sorter: true,
            width: '25%',
            render: (provider_id, row) => <MappedDictItem id={row?.params?.bank_id} type={'banks'}/>,
        },
        {
            title: t('Дата реестра'),
            dataIndex: 'current_month',
            key: 'current_month',
            sorter: true,
            width: '25%',
            render: (date, row) => row?.params?.current_month && <CustomDates data={row?.params?.current_month} />
        },
        {
            title: t('Дата загрузки'),
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем загружен'),
            dataIndex: 'created_by',
            key: 'created_by',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Дата решения'),
            dataIndex: 'decided_at',
            key: 'decided_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем решено'),
            dataIndex: 'decided_by',
            key: 'decided_by',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Всего строк'),
            dataIndex: 'imported_row_count',
            key: 'imported_row_count',
        },
        {
            title: t('Всего ошибок'),
            dataIndex: 'error_row_count',
            key: 'error_row_count',
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            key: 'actions',
            dataIndex: 'id',
            render: (id, record) => record.status === "new"  ?
                <Tooltip
                    title={t('Остановить загрузку')}
                >
                    <Popconfirm title={t('Вы уверены, что хотите остановить загрузку этого файла?')}
                                onConfirm={() => {
                                    stopUpload(id, 'stop_import')
                                }}
                                okText={t('Да')}
                    >
                        <Button
                            icon={<StopOutlined />}
                            danger
                            type={'primary'}
                            // disabled={record.status === 'processing'}

                        />
                    </Popconfirm>
                </Tooltip>
                : record.status === 'file processed' ?
                    <ButtonGroup>
                        <Tooltip title={t('Просмотр строк')}>
                            <Link to={'/upload-cash-payments/view/' + id + `?is_approved=${record.is_approved}&validation=false`}>
                                <Button icon={<EyeOutlined />}/>
                            </Link>
                        </Tooltip>
                        <Tooltip title={t('Подтвердить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите подтвердить этот файл? ')}
                                        description={'Внесены в Систему будут только валидные строки'}
                                        onConfirm={() => accept(id)}
                                        okText={t('Да')}
                            >
                                <Button icon={<CheckOutlined />} type={'primary'}/>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={t('Отклонить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите отклонить этот файл?')}
                                        onConfirm={() => reject(id)}
                                        okText={t('Да')}
                                        okButtonProps={{danger: true}}
                            >
                                <Button icon={<CloseOutlined />} danger/>
                            </Popconfirm>
                        </Tooltip>
                    </ButtonGroup>
                    : record.status === "file approved" ?
                        <ButtonGroup>
                            <Tooltip title={t('Просмотр строк')}>
                                <Link to={'/upload-cash-payments/view-approve/' + id + `?is_approved=${record.is_approved}&validation=false`}>
                                    <Button icon={<EyeOutlined />}/>
                                </Link>
                            </Tooltip>
                            {/*<Tooltip title={t('Скачать файл')}>*/}
                            {/*    <Button*/}
                            {/*        icon={<DownloadOutlined />}*/}
                            {/*        type={"primary"}*/}
                            {/*        onClick={() => download(id)}*/}
                            {/*    />*/}
                            {/*</Tooltip>*/}
                        </ButtonGroup>
                        : record.status === "processing stopped" ?
                            ''
                            : record.status === "error" || record.status === 'adding to the table failed' || record.status === 'file disapproved' ?
                                <ButtonGroup>
                                    <Tooltip title={t('Просмотр строк')}>
                                        <Link to={'/upload-cash-payments/view/' + id + `?is_approved=${record.is_approved}&validation=false`}>
                                            <Button icon={<EyeOutlined />}/>
                                        </Link>
                                    </Tooltip>
                                </ButtonGroup>
                                : ''

        }
    ]

    const filterItems = [
        {
            name: 'provider_id',
            label: t('Поставщик'),
            type: 'select',
            dict: 'providers',
            object: 'provider-kassa'
        },
        {
            name: 'bank_id',
            label: t('Касса'),
            type: 'select',
            object: 'bank-kassa',
            map: kassaMap,
            opts: bankOpts

        },
        {
            name: 'reestr_date',
            label: t('Дата реестра'),
            type: 'range'
        },
    ]

    return <>
        <UploadCashPaymentsModal />
        {/*<ViewFileModal />*/}
        <FilesGate search={search.toString()}/>
        <FilterBlock items={filterItems}/>
        <Divider />
        <DictionaryProTable
            data={data}
            count={count}
            initColumns={columns}
            loading={loading}
            showActions={false}
            tableExtraRender={() => <ButtonGroup>
                <Tooltip title={t('Загрузить')}>
                    <Button type={'primary'} icon={<UploadOutlined />} onClick={() => setModal()}>
                        {t('Загрузить')}
                    </Button>
                </Tooltip>
                <Tooltip title={t('Выгрузка')}>
                    <Button  icon={<ExcelOutlined size={18} />} onClick={() => exportXls()}>
                        {t('Выгрузка')}
                    </Button>
                </Tooltip>
            </ButtonGroup>}
        />
    </>
}
