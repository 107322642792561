import {createEffect} from "effector";
import api from "../../api/api.js";

export const getClosedMonthFx = createEffect()
	.use(async (filters) => (await api().get(`/closed_month/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const sendCloseMonthFx = createEffect()
	.use(async () => (await api().post(`/closed_month/close_month`)).data)

export const getClosedMonthByIdFx = createEffect()
	.use(async (id) => (await api().get(`/closed_month/${id}`)).data)

export const sendApproveCloseMonthFx = createEffect()
	.use(async (id) => (await api().post(`/closed_month/approve/${id}`)).data)

export const sendDeclineCloseMonthFx = createEffect()
	.use(async (id) => (await api().post(`/closed_month/decline/${id}`)).data)
