import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';

import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import CreateStTypeEmbeddedForm
    from '../../../components/Embedded/CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';


import {$createdStTypeId} from "../../../models/dictionaryStreetTypesModel/index.js";

import {
    BankApiAddressCreateGate,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, submitCreateBankApiAddressEv,
} from "../../../models/dictionaryBankApiAddressModel/index.js";

export default function CreateBankApiAddress() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [bankApiAddressForm] = Form.useForm()

    const submit = useUnit(submitCreateBankApiAddressEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdStTypeId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.bank-api-address`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/bank-api-address/edit/${createdId}`)
            } else if (action === 'new') {
                bankApiAddressForm.resetFields()
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <BankApiAddressCreateGate />
        <Card>
            <Form form={bankApiAddressForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>


                <CreateStTypeEmbeddedForm />
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col span={9}>
                        <Form.Item name={'index'}
                                   label={t('Индекс')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        bankApiAddressForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('new')
                                bankApiAddressForm.submit()
                            }}
                            type={'primary'}
                            ghost
                    >
                        {t('Сохранить и создать следующий')}
                    </Button>
                    <Button loading={loading}
                            onClick={() => {
                                setAction('edit')
                                bankApiAddressForm.submit()
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                </Space>
            </Row>
        </Card>
    </>
}
