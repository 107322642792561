import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const TaskEmailAccGate = createGate()

export const changeFiltersEv = createEvent()

export const applyFiltersEv = createEvent()

export const resetFiltersEv = createEvent()

export const changeSelectedAccountsEv = createEvent()

export const createTaskEv = createEvent()

export const resetTaskCreationStatus = createEvent()