import {useEffect, useState} from 'react';
import {Resizable} from 'react-resizable';
import 'react-resizable/css/styles.css';
import {saveUserSettingsEv} from "../models/userSettingsModel/index.js";

function ResizableCell(props) {
    const {onResize, width, ...restProps} = props
    return width === undefined
        ? <td {...restProps} />
        : <Resizable axis='x'
                     width={width}
                     height={0}
                     onResize={onResize}
        >
            <td {...restProps}></td>
        </Resizable>
}
function calcWidth(width) {
    if (typeof width === 'number') {
        return width
    } else if (typeof width === 'string' && width.includes('%')) {
        return Math.trunc(window.innerWidth * (parseInt(width) / 100));
    }
}

function prepareCols(cols, handleResize) {
    return cols.map(i => {
        if (i.width && !i.children) {
            i.width = calcWidth(i.width)
            return i
        } else if (!i.width && i.children) {
            i.children = i.children.map(c => c.width ? c : {...c, width: 100})
            return i
        } else {
            i.width = i.dataIndex?.includes('name') ? 200 : 100
            return i
        }
    }).map(col => {
        if (col.children && col.children.length > 0) {
            col.children = col.children.map(c => ({
                ...c,
                onHeaderCell: () => ({
                    width: calcWidth(c.width),
                    onResize: handleResize(c)
                }),
                onCell: () => ({
                    width: calcWidth(c.width),
                    onResize: handleResize(c)
                })
            }))
        }
        if (!['id', 'actions', 'action'].includes(col?.key)) {
            col.onHeaderCell = () => ({
                width: calcWidth(col.width),
                onResize: handleResize(col, col.key)
            })
            col.onCell = () => ({
                width: calcWidth(col.width),
                onResize: handleResize(col, col.key)
            })
        }
        return col
    })
}

export default function useResizableColumns(initCols) {
    const [columns, setColumns] = useState([])

    const handleResize = (column) => (e, {node, size, handle}) => {
        setColumns(cols => {
            const updatedCols = cols.map(i => {
                if (i.children && i.children.some(c => c.dataIndex === column.dataIndex)) {
                    i.children = i.children.map(c => {
                        if (c.dataIndex === column.dataIndex) {
                            c.width = c.width + e.movementX
                        }
                        return c
                    })
                }
                if (i.dataIndex === column.dataIndex) {
                    i.width = i.width + e.movementX
                }
                return i
            })

            saveUserSettingsEv({
                key: `${window.location.pathname.slice(1)}.table_col_size`,
                value: JSON.stringify(updatedCols)
            })

            return updatedCols
        })
    }

    useEffect(() => {
        if (initCols && initCols?.length > 0) {
            setColumns(prepareCols(initCols, handleResize))
        }
    }, [initCols]);

    const components = {body: {cell: ResizableCell}}

    return [columns, components]
}
