import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {$userPerms} from '../../models/infoModel/index.js';
import Collapse from 'antd/es/collapse';
import MenuItemCard from './components/MenuItemCard.jsx';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Typography from "antd/es/typography";
import permitted from '../../utils/permitted.js';


export default function Home() {
    const {t} = useTranslation()

    const perms = useUnit($userPerms)

    const dictsItems = [
        {
            title: t('Адресный регистр'),
            hidden: !(perms?.town || perms?.town_distr || perms?.street || perms?.street_type || perms?.real_estate_object_type || perms?.address),
            dicts: [
                {
                    label: t('Населенные пункты'),
                    link: '/dictionaries/town',
                    hidden: !perms?.town?.list
                },
                {
                    label: t('Районы'),
                    link: '/dictionaries/town_distr',
                    hidden: !perms?.town_distr?.list
                },
                // {
                //     label: t('Налоговые отделения'),
                //     link: '/dictionaries/tax_branch'
                // },
                {
                    label: t('Типы улиц'),
                    link: '/dictionaries/street_type',
                    hidden: !perms?.street_type?.list
                },
                {
                    label: t('Улицы'),
                    link: '/dictionaries/street',
                    hidden: !perms?.street?.list
                },
                {
                    label: t('Адреса'),
                    link: '/dictionaries/address',
                    hidden: !perms?.address?.list
                },
                {
                    label: t('Тип объектов недвижимости'),
                    link: '/dictionaries/real-estate-object-type',
                    hidden: !perms?.real_estate_object_type?.list
                },
            ],
            tasks: [
                    {
                        label: t('Назначение адресов на район'),
                        link: '/dictionaries/swap-address',
                        hidden: !perms?.swap_address?.update
                    },
            ]
        },
        {
            title: t('Банковская информация'),
            hidden: !(perms?.bank?.list || perms?.bank_commission?.list || perms?.bank_account_type?.list || perms?.kbe?.list || perms?.kbk?.list || perms?.knp?.list),
            dicts: [
                {
                    label: t('Банки'),
                    link: '/dictionaries/banks',
                    hidden: !perms?.bank?.list
                },
                {
                    label: t('Типы расчетных счетов'),
                    link: '/dictionaries/bank-account-type',
                    hidden: !perms?.bank_account_type?.list
                },
                {
                    label: t('КБе'),
                    link: '/dictionaries/kbe',
                    hidden: !perms?.kbe?.list
                },
                {
                    label: t('КБК'),
                    link: '/dictionaries/kbk',
                    hidden: !perms?.kbk?.list
                },
                {
                    label: t('КНП'),
                    link: '/dictionaries/knp',
                    hidden: !perms?.knp?.list
                },
                {
                    label: t('Комиссия банка'),
                    link: '/dictionaries/bank-commission',
                    hidden: !perms?.bank_commission?.list
                },
            ],
            tasks: []
        },
        {
            title: t('ЛС и ОН'),
            hidden: !(
                perms?.accounts || perms?.real_estate_objects ||
                perms?.import_file?.import_accounts || perms?.import_file?.import_personal_data ||
                perms?.import_file?.import_reo
            ),
            dicts: [
                {
                    label: t('ЛС'),
                    link: '/dictionaries/accounts',
                    hidden: !perms?.accounts?.list
                },
                {
                    label: t('Объекты недвижимости'),
                    link: '/dictionaries/real-estate-objects',
                    hidden: !perms?.real_estate_objects?.list
                },
            ],
            tasks: [
                {
                    label: t('Импорт ЛС'),
                    link: '/upload-accounts',
                    hidden: !permitted(['import_file::import_accounts'], perms),
                },
                {
                    label: t('Импорт обновления ЛС'),
                    link: '/upload-update-accounts',
                    hidden: !permitted(['import_file::import_personal_data'], perms),
                },
                {
                    label: t('Импорт ОН'),
                    link: '/upload-real-estate-objects',
                    hidden: !permitted(['import_file::import_reo'], perms)
                },
            ]
        },
        {
            title: t('Контролеры и Участки'),
            hidden: !(
                perms?.inspector || perms?.sector ||
                perms?.sector_inspector || perms?.sector_reo
            ),
            dicts: [
                {
                    label: t('Участки'),
                    link: '/dictionaries/sector',
                    hidden: !perms?.sector?.list
                },
                {
                    label: t('Контролеры'),
                    link: '/dictionaries/inspectors',
                    hidden: !perms?.inspector?.list
                },
                {
                    label: t('Старшие контролеры'),
                    link: '/dictionaries/chief-inspectors',
                    hidden: !perms?.inspector?.list
                }
            ],
            tasks: [
                {
                    label: t('Назначение контролера на участки'),
                    link: '/dictionaries/swap-sector',
                    hidden: !permitted(['sector_inspector::update'], perms)
                },
                {
                    label: t('Назначение ОН на участок'),
                    link: '/dictionaries/swap-real-estate-object',
                    hidden: !perms?.sector_reo?.update
                },
            ]
        },
        {
            title: t('Поставщики, Услуги и Тарифы'),
            hidden: !(
                perms?.provider?.list || perms?.service?.list ||
                perms?.agreement?.create || perms?.account_provider?.create ||
                perms?.preference_category?.list || perms?.calc_type?.list ||
                perms?.service_payment_type?.list || perms?.organization_type?.list ||
                perms?.service_class?.list || perms?.service_unit?.list || perms?.algorithm?.list ||
                perms?.import_file?.import_account_provider || perms?.import_file?.import_external_number
            ),
            dicts: [
                {
                    label: t('Поставщики'),
                    link: '/dictionaries/providers',
                    hidden: !perms?.provider?.list
                },
                {
                    label: t('Услуги'),
                    link: '/dictionaries/services',
                    hidden: !perms?.service?.list
                },
                {
                    label: t('Виды платежей'),
                    link: '/dictionaries/service-payment-type',
                    hidden: !perms?.service_payment_type?.list
                },
                {
                    label: t('Единицы измерения услуг'),
                    link: '/dictionaries/service-units',
                    hidden: !perms?.service_unit?.list
                },
                {
                    label: t('Типы расчета'),
                    link: '/dictionaries/calc-type',
                    hidden: !perms?.calc_type?.list
                },
                {
                    label: t('Категории льгот'),
                    link: '/dictionaries/preference-category',
                    hidden: !perms?.preference_category?.list
                },
                {
                    label: t('Алгоритмы расчетов'),
                    link: '/dictionaries/algorithm',
                    hidden: !perms?.algorithm?.list
                }
            ],
            tasks: [
                    {
                        label: t('Загрузка № договоров с потребителями'),
                        link: '/upload-agreements',
                        hidden: !permitted(['import_file::import_external_number'], perms)
                    },
            ]
        },
    ]

    const adminItems = [
        {
            title: t('Пользователи'),
            hidden: !(
                perms?.users?.list ||
                perms?.role?.list || perms?.permission?.list || perms?.position?.list
            ),
            dicts: [
                {
                    label: t('Пользователи'),
                    link: '/users',
                    hidden: !perms?.users?.list
                },
                {
                    label: t('Параметры пароля'),
                    link: '/password-settings'
                },
                {
                    label: t('Роли'),
                    link: '/role',
                    hidden: !perms?.role?.list
                },
                {
                    label: t('Разрешения'),
                    link: '/permission',
                    hidden: !perms?.permission?.list
                },
                {
                    label: t('Должности'),
                    link: '/position',
                    hidden: !perms?.position?.list
                },
            ],
            tasks: []
        },
        {
            title: t('Банки'),
            hidden: !(perms?.bank_api_info?.list),
            dicts: [
                {
                    label: t('Настройка API банка'),
                    link: '/dictionaries/bank-api-info',
                    hidden: !perms?.bank_api_info?.list
                },
            ],
            tasks: []
        },
        {
            title: t('ЕПД'),
            hidden: !(perms?.epd_template?.list || perms?.services_columns?.list),
            dicts: [
                {
                    label: t('Поля шаблонов для ЕПД'),
                    link: '/dictionaries/epd-template-fields',
                    hidden: !perms?.epd_template?.list
                },
                {
                    label: t('Заголовки таблицы в ЕПД'),
                    link: '/services-columns',
                    hidden: !perms?.services_columns?.list
                },
            ],
            tasks: []
        },
    ]

    const tasksItems = [
        {
            title: t('ЕПД'),
            hidden: !(
                perms?.epd_generator || perms?.dispatch || perms?.email_dispatch ||
                perms?.import_file?.import_email
            ),
            dicts: [],
            tasks: [
                {
                    label: t('Email рассылки'),
                    link: '/tasks/email-per-accounts',
                    hidden: !permitted(['dispatch::change'], perms)
                },
                {
                    label: t('Формирование ЕПД'),
                    link: '/tasks/all',
                    hidden: !permitted(['epd_generator::change'], perms)
                },
                {
                    label: t('Одобрение Email-рассылок'),
                    link: '/approve-emails',
                    hidden: !permitted(['email_dispatch::change', 'import_file::import_email'], perms)
                },
            ],
        },
        {
            title: t('Начисления'),
            hidden: !(
                perms?.accrual || perms?.accrual_file_template?.list ||
                perms?.closed_month?.view || perms?.import_file?.import_accruals ||
                perms?.corrections?.list
            ),
            dicts: [
                {
                    label: t('Начисления'),
                    link: '/accruals',
                    hidden: !perms?.accrual?.list
                },
            ],
            tasks: [
                {
                    label: t('Загрузка начислений'),
                    link: '/upload',
                    hidden: !permitted(['import_file::import_accruals'], perms)
                },
                {
                    label: t('Шаблоны импорта начсилений'),
                    link: '/dictionaries/template-payments',
                    hidden: !perms?.accrual_file_template?.list
                },
                {
                    label: t('Переход месяца'),
                    link: '/close-month',
                    hidden: !perms?.closed_month?.create
                },
                {
                    label: t('Корректировки'),
                    link: '/corrections',
                    hidden: !perms?.corrections?.list
                },
            ],
        },
        {
            title: t('Оплаты'),
            hidden: !(perms?.payment_distribution?.list),
            dicts: [],
            tasks: [
                {
                    label: t('Распределение платежей'),
                    link: '/payment',
                    hidden: !perms?.payment_distribution?.list
                },
            ],
        },
        {
            title: t('Отчеты'),
            hidden: !(perms?.report?.create),
            dicts: [],
            tasks: [
                {
                    label: t('Создать отчет'),
                    link: '/reports',
                    hidden: !perms?.report?.create
                },
            ],
        },
    ]

    const notificationsItems = [
        {
            title: t('Уведомления'),
            dicts: [],
            tasks: [],
        },
    ]

    const items = [
        {
            key: 'dicts',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Справочники')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {dictsItems?.filter(i => !i?.hidden)?.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        },
        {
            key: 'tasks',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Задачи')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {tasksItems?.filter(i => !i?.hidden)?.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        },
        {
            key: 'admin',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Администрирование')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {adminItems?.filter(i => !i?.hidden)?.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        },
        {
            key: 'notifications',
            label: <Typography.Title level={4} style={{margin: '0'}}>{t('Уведомления')}</Typography.Title>,
            children: <Row gutter={[24, 24]}>
                {notificationsItems?.filter(i => !i?.hidden)?.map((i,k) => <Col span={8} key={k}>
                    <MenuItemCard title={i.title} dicts={i.dicts} tasks={i.tasks}/>
                </Col>)}
            </Row>
        }
    ]

    return <>
        <Collapse size={'small'} items={items} defaultActiveKey={['dicts', 'tasks']} />
    </>
}
