import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedFile,
    $selectedFileCells, acceptFileEv, downloadErrorFileEv,
    getFileCellsFx, rejectFileEv, ViewFileCellsGate
} from '../../../models/uploadCashPaymentsModel';
// import {usePaginationControl} from '../../../hooks/usePaginationControl.js';
// import {useServerSort} from '../../../hooks/useServerSort.js';
import dayjs from 'dayjs';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import styles from '../Upload.module.css';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import React, {useState} from 'react';
import {Popconfirm, Switch} from 'antd';
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames} from "../../../utils/monthNamesDict.js";
import Collapse from "antd/es/collapse";
import TextArea from "antd/es/input/TextArea";
import {useServerSortPage} from '../../../hooks/useServerSortPage.js';
import FormatNumber from "../../../components/FormatNumber/FormatNumber.jsx";
import DictionaryProTable from "../../../components/DictionaryProTable/DictionaryProTable.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import FilterBlock from "../../../components/FiltersBlock/FiltersBlock.jsx";

export default function UploadCashPaymentsViewFileCells() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const selectedFile = useUnit($selectedFile)
    const {data, count} = useUnit($selectedFileCells)
    const loading = useUnit(getFileCellsFx.pending)
    const accept = useUnit(acceptFileEv)
    const reject = useUnit(rejectFileEv)
    const downloadErrorFile = useUnit(downloadErrorFileEv)

    const {id} = useParams()
    const [search, setSearch] = useSearchParams()
    const {exportXlsAction} = searchAndActions()

    const [curPage, limit, _field, _order, onChangeTable] = useServerSortPage()

    const [valid, setValid] = useState(!!(search.get('validation') && search.get('validation')==='true'))
    const onValidChange = (checked) => {
        setValid(!!checked)
        search.set('validation', checked)
        setSearch(search)
    }

    const confirmDisabled = !(
        selectedFile?.status === 'file processed' && selectedFile?.is_approved === null
    )

    const downloadErrors = selectedFile?.error_row_count > 0

    const textError = (data) => {
        const e = data || ''
        const se = e.split('|')
        const te = se.shift()
        const ie = se.join(', ')

        const error = t(`import.errors.${te}`)
        return <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
    }

    const filterItems = [
        {
            name: 'provider_id',
            label: t('Поставщик'),
            type: 'select',
            dict: 'providers'
        },
        {
            name: 'service_id',
            label: t('Услуга'),
            type: 'select',
            dict: 'services'
        },
        {
            name: 'current_month',
            label: t('Дата реестра'),
            type: 'range'
        },
    ]

    const columns = [
        {
            title: t('№ строки'),
            dataIndex: 'row_index',
            align: 'center',
            key: 'row_index',
            sorter: true,
        },
        {
            title: t('Статус'),
            dataIndex: 'row_is_valid',
            align: 'center',
            key: 'valid',
            sorter: true,
            render: (valid) => valid ? <CheckOutlined/> : <CloseOutlined style={{color: 'red'}}/>
        },
        {
            title: t('Банк'),
            dataIndex: 'bank_id_value',
            key: 'bank_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.bank_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.bank_id_error && textError(record?.bank_id_error)}
            </div>
        },
        {
            title: t('Дата платежа'),
            dataIndex: 'payment_date_value',
            key: 'payment_date_value',
            sorter: true,
            onCell: (record) => ({className: record?.payment_date_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.payment_date_id_error && textError(record?.payment_date_id_error)}
            </div>
        },
        {
            title: t('№ ЛС'),
            dataIndex: 'account_id_value',
            key: 'account_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.account_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>{v}</span>
                {record?.account_id_error && textError(record?.account_id_error)}
            </div>
        },
        {
            title: t('Услуга'),
            dataIndex: 'provider_service_id_value',
            key: 'provider_service_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.provider_service_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                {/*<span>{v}</span>*/}
                <MappedDictItem id={v} type={'providers'}/>
                {record?.provider_service_id_error &&
                    textError(record?.provider_service_id_error)}
            </div>
        },

        // {
        //     title: t('Отделение банка'),
        //     dataIndex: 'branch_id_value',
        //     key: 'branch_id_value',
        //     sorter: true,
        //     onCell: (record) => ({className: record?.branch_id_error && styles.invalid}),
        //     render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
        //         <span>{v}</span>
        //         {record?.branch_id_error && textError(record?.branch_id_error)}
        //     </div>
        // },


        {
            title: t('Услуга'),
            dataIndex: 'service_id_value',
            key: 'service_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.service_id_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                {/*<span>{v}</span>*/}
                <MappedDictItem id={v} type={'services'}/>
                {record?.service_id_error &&
                    textError(record?.service_id_error)}
            </div>
        },


        {
            title: t('Сумма'),
            dataIndex: 'summa_value',
            key: 'summa_value',
            sorter: true,
            onCell: (record) => ({className: record?.summa_error && styles.invalid}),
            render: (v, record) => <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <FormatNumber value={v} type={2}/>

                {record?.summa_error && textError(record?.summa_error)}
            </div>
        },

    ]

    const items = [
        {
            key: 'provider_id',
            label: t('Поставщик'),
            // children: <CreatedBy id={selectedFile?.provider_id}/>
            children: <MappedDictItem id={selectedFile?.params?.provider_id} type={'providers'}/>
        },
        {
            key: 'total_rows',
            label: t('Всего строк'),
            children: selectedFile?.total_row_count
        },
        {
            key: 'total_errors',
            label: t('Всего ошибок'),
            children: selectedFile?.error_row_count
        },
        // {
        //     key: 'file_extention',
        //     label: t('Расширение'),
        //     children: selectedFile?.file_extention
        // },
        // {
        //     key: 'file_type',
        //     label: t('Тип'),
        //     children: `${selectedFile?.file_type ?? '-'} ${t('столбцов')}`,
        // },
        {
            key: 'report_date',
            label: t('Дата'),
            children: selectedFile?.params?.current_month ? selectedFile?.params?.current_month : '-'
        }
    ]

    const dateItems = [
        {
            key: 'load_date',
            label: t('Дата загрузки'),
            children: selectedFile?.load_date && dayjs(selectedFile?.load_date).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'to_list',
            children: <Button
                onClick={() => navigate("../..", {relative: "path", replace: true})}
            >{t('К списку')}</Button>
        },
        {
            key: 'confirm',
            children: <Popconfirm
                title={t('Вы уверены, что хотите подтвердить этот файл?')}
                onConfirm={() => {
                    accept(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
            >
                <Button type={'primary'}
                        disabled={confirmDisabled}>{t('Подтвердить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'cancel',
            children: <Popconfirm
                title={t('Вы уверены, что хотите отклонить этот файл?')}
                onConfirm={() => {
                    reject(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
                okButtonProps={{danger: true}}
            >
                <Button danger disabled={confirmDisabled}>{t('Отклонить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'downloadErrors',
            children: <Button
                disabled={!downloadErrors}
                onClick={() => downloadErrorFile(id)}
            >{t('Скачать файл с ошибками')}</Button>
        },
    ]

    return <Card title={`${t('Просмотр строк файла')} ${selectedFile?.file_name}`}>
        <ViewFileCellsGate id={id} search={search}/>
        <ViewDictionaryItemCommon showNav={false} items={items} itemsDates={dateItems}/>
        <Divider/>
        {selectedFile?.load_error ? <Collapse
            size="small"
            items={[
                {
                    key: '1',
                    label: t('Лог ошибок обработки файла'),
                    children: <TextArea rows={40} value={selectedFile?.load_error} />,
                },
            ]}
        /> : null}
        <Divider/>
        {/*<FilterBlock items={filterItems}/>*/}
        {/*<br/>*/}

        <div style={{display: 'flex', gap: 4}}>
            <Switch checked={valid} onChange={onValidChange} checkedChildren={'Валидные'} unCheckedChildren={'Ошибочные'} />
        </div>
        {/*<Table dataSource={data}*/}
        {/*       loading={loading}*/}
        {/*       columns={columns}*/}
        {/*       onChange={(pagination, _filters, sorter) => {*/}
        {/*           onChangeTable({page: pagination?.current, limit: pagination?.pageSize, sort_field: sorter?.field ?? null, sort_order: sorter?.order ?? null})*/}
        {/*       }}*/}
        {/*       pagination={{*/}
        {/*           ...paginationConfig,*/}
        {/*           pageSize: parseInt(limit),*/}
        {/*           current: parseInt(curPage),*/}
        {/*           total: count*/}
        {/*       }}*/}
        {/*       size={'small'}*/}
        {/*       className={'table-container'}*/}
        {/*/>*/}



        <DictionaryProTable
            data={data}
            loading={loading}
            initColumns={columns}
            onChange={(pagination, _filters, sorter) => {
                onChangeTable({page: pagination?.current, limit: pagination?.pageSize, sort_field: sorter?.field ?? null, sort_order: sorter?.order ?? null})
            }}
            pagination={{
                ...paginationConfig,
                pageSize: parseInt(limit),
                current: parseInt(curPage),
                total: count
            }}
            size={'small'}
            className={'table-container'}
            exportXlsAction={exportXlsAction}
            // showActions={false}
            hiddenActions={'create'}



        />
    </Card>
}
