import {createStore} from 'effector';
import dayjs from 'dayjs';

export const $filters = createStore({
    recreate_pdf: false,
    has_pdf: 'all',
    report_date: dayjs(),
    town_id: null,
    town_distr_id: null,
    street_id: null,
    house: null,
    corpus: null
})

export const $reoList = createStore({data: [], count: 0})

export const $selectedReo = createStore([])

export const $taskCreatedStatus = createStore(false)

export const $townsSelect = createStore([])
export const $districtsSelect = createStore([])
export const $streetsSelect = createStore([])
export const $houseSelect = createStore([])
export const $corpusSelect = createStore([])