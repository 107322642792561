import {
    $afterCreateAction,
    $createdAccountId,
    $editAccountStatus,
    $selectedAccount,
    $accountsList,
    $sexEmbedded,
    $nationEmbedded,
    $prefCatEmbedded,
    $consCatEmbedded,
    $selectedAccountReEsObj,
    $providerServiceList,
    $accountsProviderServiceList,
    $accrualsData,
    $paymentsData,
    $paymentsElementsData,
    $selectedProvider,
    $selectedService, $providerService, $editedExternalNumber, $cashPaymentsData, $accLockedModal, $providerAccountsList
} from './stores.js';
import {
    getAccountByIdFx,
    getAccountReEsObjFx,
    getAccountsListFx,
    getAccrualsListDataFx,
    getProviderServicesFx,
    getProviderSrvAccsFx,
    getPaymentsDataFx,
    getPaymentsElementsFx,
    getProviderServiceFx,
    getAccrualsFx,
    getFileApprovedInfoFx,
    lockUnlockAccountFx, getProviderAccountFx
} from './effects.js';
import {
    deleteAccountEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    AccountEditGate,
    AccountsListGate,
    AccountViewGate,
    submitCreateAccountEv,
    submitEditAccountEv,
    setSexEmbeddedEv,
    setNationEmbeddedEv,
    setPrefCatEmbeddedEv,
    setConsCatEmbeddedEv,
    AccountCreateGate,
    AccrualsGate,
    PaymentsGate,
    selectProviderEv,
    selectServiceEv,
    editExternalNumberEv,
    getAccAccrEv,
    CashPaymentsGate,
    lockUnlockAccountEv,
    setAccLockedModalEv, resetAccLockedModalEv, ProviderAccountsGate
} from './events.js';
import {sample} from 'effector';
import {
    submitGlobalUpdateEv,
    triggerSuccessOpsEv
} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {
    $createdMissAddresses,
    $createdREO, $selectedAddress,
    $selectedREOFromAddress, resetFiltersEv, resetSelectionEv
} from '../accountsREOSearchModel/index.js';
import queryString from "query-string";
import {$userPerms} from "../infoModel/index.js";
import permitted from "../../utils/permitted.js";

const createAccountCompleteEv = combineEvents({events: {ev: submitCreateAccountEv, response: triggerSuccessOpsEv}, reset: resetAfterCreationAttrs})
const editAccountCompleteEv = combineEvents({events: {ev: submitEditAccountEv, response: triggerSuccessOpsEv}, reset: $selectedAccount.updates})
const deleteAccountCompleteEv = combineEvents({events: {ev: deleteAccountEv, response: triggerSuccessOpsEv}, reset: $selectedAccount.updates})

$accountsList.on(getAccountsListFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)

$providerAccountsList.on(getProviderAccountFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)

$providerService.on(getProviderServiceFx.doneData, (_, payload) => payload)
    .reset(AccountsListGate.close)

$selectedProvider.on(selectProviderEv, (_, payload) => payload)
    .reset(AccountsListGate.close)

$selectedService.on(selectServiceEv, (_, payload) => payload)
    .reset(AccountsListGate.close)

$providerServiceList.on(getProviderServicesFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)


$accountsProviderServiceList.on(getAccrualsListDataFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)


$accrualsData.on(getAccrualsListDataFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(AccountsListGate.close)


$paymentsData.on(getPaymentsDataFx.doneData, (_, {data, count}) => ({data: data, count}))
    .reset(PaymentsGate.close)
$paymentsElementsData.on(getPaymentsElementsFx.doneData, (_, data) => {
    return data
})
	.reset(PaymentsGate.close)



$selectedAccount.on(getAccountByIdFx.doneData, (_, account) => account)
    .reset([AccountEditGate.close, AccountViewGate.close])

$selectedAccountReEsObj.on(getAccountReEsObjFx.doneData, (_, resobj) => resobj)
    .reset([AccountEditGate.close, AccountViewGate.close])

$createdAccountId.on(createAccountCompleteEv, (_, {response}) => {
    const account = response.find(i => i.type === 'accounts')
    return account ? account?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editAccountStatus.on(editAccountCompleteEv, () => true)
    .reset([AccountEditGate.close, resetAfterCreationAttrs])

$sexEmbedded.on(setSexEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])

$nationEmbedded.on(setNationEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])

$prefCatEmbedded.on(setPrefCatEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])

$consCatEmbedded.on(setConsCatEmbeddedEv, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close])

$cashPaymentsData.on(getFileApprovedInfoFx.doneData, (_, value) => value)
    .reset([AccountCreateGate.close, AccountEditGate.close, CashPaymentsGate.close])

$accLockedModal.on(setAccLockedModalEv, (state, payload) => ({...state, ...payload}))
    .reset(resetAccLockedModalEv)

sample({
    source: $selectedAccount,
    clock: lockUnlockAccountEv,
    fn: (acc, payload) => ({
        account_id: acc?.id,
        status: payload?.status,
        status_reason: payload?.status_reason ?? ''
    }),
    target: lockUnlockAccountFx
})

sample({
    clock: [AccountViewGate.state.updates, AccountEditGate.state.updates],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [getAccountByIdFx]
})

sample({
    source: {
        perms: $userPerms,
        gate: AccountViewGate.state,
    },
    clock: [$userPerms, AccountViewGate.state.updates],
    filter: (data) => {
        const withId = Object.hasOwn(data?.gate, 'id')
        const perms = data?.perms || {}
        const pOk = permitted([`accrual::view`], perms)
        return withId && pOk
    },
    fn: ({gate}) => +gate.id,
    target: getAccrualsFx,
})

sample({
    source: AccountEditGate.state,
    clock: lockUnlockAccountFx.doneData,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: [getAccountByIdFx]
})


sample({
    source: $editedExternalNumber,
    clock: editExternalNumberEv,
    fn: (state, payload) => {
        const newData = [...state].filter(item => item.id !== payload.id)
        return [...newData, payload]
    },
    target: $editedExternalNumber
})

sample({
    source: $accountsProviderServiceList,
    clock: editExternalNumberEv,
    fn: (state, payload) => {
        let newData = [...state.data].filter(item => item.id !== payload.id)
        const newItem = [...state.data].find(item => item.id === payload.id)
        newItem.external_number = payload.external_number
        newData.push(newItem)
        return {count: state.count, data: newData}
    },
    target: $accountsProviderServiceList
})


sample({
    source: {services: $selectedService, providers: $selectedProvider},
    clock: [$selectedService.updates, $selectedProvider.updates],
    fn: (payload) => {
        const data = {
            provider_id: payload.providers,
            service_id: payload.service,
        }
        return queryString.stringify(data, {skipNull: true, skipEmptyString: true})
    },
    target: getProviderServiceFx
})

sample({
    clock: AccountViewGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getProviderServicesFx
})


// sample({
//     clock: AccrualsGate.state,
//     filter: (gate) => Object.hasOwn(gate, 'id'),
//     fn: (gate) => {
//         debugger
//         return {id: +gate.id, filters: gate.search}
//     },
//     target:  getAccrualsListDataFx
// })

sample({
    source: AccrualsGate.state,
    clock: [AccrualsGate.state, getAccAccrEv],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate, payload) => {
        console.log('SSSSSSSS', payload)
        // debugger
        return {...payload, filters: gate?.search ?? ''}
    },
    target:  getAccrualsListDataFx
})

sample({
    // source: ProviderAccountsGate.state,
    clock: ProviderAccountsGate.state,
    filter: (gate) => {
        return Object.hasOwn(gate, 'id')
    },
    fn: (gate, payload) => {
        const date = gate?.search.includes('prov_acc_current_month') ? gate?.search.split('prov_acc_current_month=')[1] : dayjs(Date.now()).format('YYYY-MM-DD')
        return {...payload, filters: date}
    },
    target:  getProviderAccountFx
})


sample({
    clock: PaymentsGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => {
        return {id: +gate.id, filters: gate.search}
    },
    target:  getPaymentsDataFx
})


sample({
    clock: AccountsListGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getAccountsListFx
})

sample({
    source: AccountsListGate.state,
    clock: [createAccountCompleteEv, editAccountCompleteEv, deleteAccountCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getAccountsListFx
})

sample({
    clock: CashPaymentsGate.state.updates,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) =>  {
        return {
            id: +gate.id, filters: gate.search
        }
    },
    target: getFileApprovedInfoFx
})

sample({
    clock: $selectedAccount.updates,
    filter: (acc) => acc && Object.hasOwn(acc, 'real_estate_object_id'),
    fn: (acc) => acc.real_estate_object_id,
    target: getAccountReEsObjFx
})

sample({
    source: {
        selectedAddr: $selectedAddress,
        selectedReo: $selectedREOFromAddress,
        createdReo: $createdREO,
        createdAddress: $createdMissAddresses
    },
    clock: submitCreateAccountEv,
    fn: ({selectedAddr, selectedReo, createdReo, createdAddress}, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        let addrId = selectedAddr ? selectedAddr.id : null
        let reoId = selectedReo ? selectedReo.real_estate_object_id : null

        if (createdAddress.length > 0) {
            actions.push({operation: 'create', type: 'address', value: {...createdAddress[0], id: idCounter}})
            addrId = idCounter
            idCounter--
        }

        if (createdReo) {
            if (createdReo.sector_id < 0) {
                actions.push({operation: 'create', type: 'sector', value: {...createdReo.sector, town_id: createdReo.town_id, id: idCounter}})
                createdReo.sector_id = idCounter
                idCounter--
                delete createdReo.sector
            }
            if (createdReo.real_estate_object_type_id < 0) {
                actions.push({operation: 'create', type: 'real_estate_object_type', value: {...createdReo.real_estate_object_type, id: idCounter}})
                createdReo.real_estate_object_type_id = idCounter
                idCounter--
                delete createdReo.real_estate_object_type
            }
            delete createdReo.town_id
            delete createdReo.town_distr_id
            delete createdReo.street_id
            delete createdReo.house
            delete createdReo.corpus
            delete createdReo.real_estate_object_id
            actions.push({operation: 'create', type: 'real_estate_objects', value: {...createdReo, address_id: addrId, id: idCounter}})
            reoId = idCounter
            idCounter--
        }
        payload.real_estate_object_id = reoId

        actions.push({operation: 'create', type: 'accounts', value: {...payload, id: idCounter}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: {
        current: $selectedAccount,
        externals: $editedExternalNumber,
        selectedAddr: $selectedAddress,
        selectedReo: $selectedREOFromAddress,
        createdReo: $createdREO,
        createdAddress: $createdMissAddresses
    },
    clock: submitEditAccountEv,
    fn: ({current, externals, selectedAddr, selectedReo, createdReo, createdAddress}, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        let addrId = selectedAddr ? selectedAddr.id : null
        let reoId = selectedReo ? selectedReo.real_estate_object_id : current.real_estate_object_id ?? null

        if (createdAddress.length > 0) {
            actions.push({operation: 'create', type: 'address', value: {...createdAddress[0], id: idCounter}})
            addrId = idCounter
            idCounter--
        }

        if (createdReo) {
            if (createdReo.sector_id < 0) {
                actions.push({operation: 'create', type: 'sector', value: {...createdReo.sector, town_id: createdReo.town_id, id: idCounter}})
                createdReo.sector_id = idCounter
                idCounter--
                delete createdReo.sector
            }
            if (createdReo.real_estate_object_type_id < 0) {
                actions.push({operation: 'create', type: 'real_estate_object_type', value: {...createdReo.real_estate_object_type, id: idCounter}})
                createdReo.real_estate_object_type_id = idCounter
                idCounter--
                delete createdReo.real_estate_object_type
            }
            delete createdReo.town_id
            delete createdReo.town_distr_id
            delete createdReo.street_id
            delete createdReo.house
            delete createdReo.corpus
            delete createdReo.real_estate_object_id
            actions.push({operation: 'create', type: 'real_estate_objects', value: {...createdReo, address_id: addrId, id: idCounter}})
            reoId = idCounter
            idCounter--
        }

        if (selectedReo || createdReo && reoId !== current?.real_estate_object_id) {
            payload.real_estate_object_id = reoId
        }

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'accounts', value: {object_id: current.id, ...changedFields}})

        for (const newDist of Object.values(externals)) {
            idCounter--
            actions.push({operation: 'update', type: 'account_provider_service', value: {external_number: newDist.external_number, object_id: newDist.id}})
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteAccountEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'accounts', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [AccountCreateGate.close, AccountEditGate.close, AccountViewGate.close],
    target: [resetSelectionEv, resetFiltersEv]
})

sample({
    clock: createAccountCompleteEv,
    target: resetSelectionEv
})

// $lastCreatedAccount.on(createAccountCompleteEv, (_, data) => {
//     const id = (data?.response || []).filter(x => x.success).map(x => x.value?.id)[0]
//     return id
// }).reset([AccountCreateGate.close, AccountEditGate.close])
