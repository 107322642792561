import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useEffect} from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Switch from 'antd/es/switch';
import DatePicker from 'antd/es/date-picker';
import {
    $createdEPDTemplateField,
    $createdIpAddresses,
    $createEditChildEPDTemplateFieldEmbedded,
    $createEditChildIpAddressEmbedded,
    $ipAddressesChildren,
    resetCreateEditChildEPDTemplateFieldEmbeddedEv,
    resetCreateEditChildIpAddressEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import dayjs from 'dayjs';
import Col from 'antd/es/col';
import {Select} from "antd";
import {$dictsSelects, $epdCellTypeDict, $epdFieldNameDict, $infoMaps} from "../../../models/infoModel/index.js";
import {$selectedEPDTemplates} from "../../../models/dictionaryEpdTemplateModel/index.js";
import {$cellType, $columnNumber, $fieldName} from "../../../models/dictionaryEpdTemplateFieldModel/index.js";

export default function CreateEditEPDTemplateFieldEmbeddedForm({onCreate, onEdit}) {
    const embedded = useUnit($createEditChildEPDTemplateFieldEmbedded)
    const reset = useUnit(resetCreateEditChildEPDTemplateFieldEmbeddedEv)

    const selected = useUnit($selectedEPDTemplates)

    const {t} = useTranslation()
    const [epdTemplatesForm] = Form.useForm()

    const {data, count} = useUnit($ipAddressesChildren)
    const newData = useUnit($createdEPDTemplateField)

    const [check, changedFields] = useFormChanged(embedded.item, ['field_name', 'cell_type', 'column_number', 'font_size' ])

    const fieldNameOpt = useUnit($fieldName)
    const cellTypeOpt = useUnit($cellType)
    const columnNumberOpt = useUnit($columnNumber)

    const fieldNameDict = useUnit($epdFieldNameDict)
    const cellTypeDict = useUnit($epdCellTypeDict)

    const fieldNameOptions = fieldNameOpt.map(item => {
        return {
            label: fieldNameDict[item.value] ? fieldNameDict[item.value] : item.value,
            value: item.value
        }
    })

    const cellTypeOptions = cellTypeOpt.map(item => {
        return {
            label: cellTypeDict[item.value] ? cellTypeDict[item.value] : item.value,
            value: item.value
        }
    })


    console.log('fieldNameOptions', fieldNameOptions)
    console.log('cellTypeOptions', cellTypeOptions)

    // debugger


    const bankOpts = useStoreMap($dictsSelects, sel => sel.bank)

    const opt = [
        {
            label: 'Все',
            value: 'all',
        },
        {
            label: 'Да',
            value: true,
        },
        {
            label: 'Нет',
            value: false,
        }
    ]


    const onClose = () => {
        epdTemplatesForm.resetFields()
        reset()
    }

    useEffect(() => {
        if (embedded.open && embedded.type === 'edit' && embedded.idx !== null && embedded.item) {
            epdTemplatesForm.setFieldsValue({
                ...embedded.item,
                // actual_from: embedded?.item?.actual_from ? dayjs(embedded.item.actual_from) : null
            })
        }
    }, [embedded]);

    const onSubmit = (values) => {

        const id = parseInt(
            `-${newData?.length + 1}`
        )


        if (embedded.type === 'edit') {
            onEdit({
                payload: {
                    ...values,
                    id: embedded.item.id
                }, id: embedded.item.id
            })
        } else if (embedded.type === 'create') {
            onCreate({...values})
        }
        onClose()
    }



    return embedded.open &&
        <Form form={epdTemplatesForm}
              layout={'vertical'}
              size={'small'}
              onFinish={onSubmit}
              style={{width: '100%', marginBottom: 16}}
              onValuesChange={(_, all) => {
                  if (embedded.type === 'edit') {
                      check(all)
                  }
              }}
        >
            <Card title={t('Свойства адреса')} size={'small'} style={{height: '100%'}}>
                <Row  gutter={[16, 16]}>
                    <Col span={6}>
                        <Form.Item name={'field_name'}
                                   label={t('Название поля')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['field_name'] : undefined}
                        >
                            <Select
                                options={fieldNameOptions}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={'cell_type'}
                                   label={t('Тип ячейки')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['cell_type'] : undefined}
                        >
                            <Select
                                options={cellTypeOptions}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name={'column_number'}
                                   label={t('Номер столбца')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['column_number'] : undefined}
                        >
                            <Select
                                options={columnNumberOpt}
                                defaultValue={true}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item name={'font_size'}
                                   label={t('Размер шрифта')}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['font_size'] : undefined}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
            <Row style={{gap: 8, marginTop: 16}}>
                <Button onClick={onClose} danger>{t('Отмена')}</Button>
                <Button type={'primary'} onClick={epdTemplatesForm.submit}>{t('Сохранить')}</Button>
            </Row>
        </Form>
}
