import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import {useUnit} from 'effector-react';
import {$createTownEmbeddedOpen} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import Card from 'antd/es/card';

export default function CreateTownEmbeddedForm() {
    const {t} = useTranslation()
    const open = useUnit($createTownEmbeddedOpen)


    return open && <Card title={t('Свойства населенного пункта')} style={{marginBottom: 16}} size={'small'}>
                    <Form.Item name={['town', 'name_ru']}
                               label={t('Название (рус)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name={['town', 'name_kz']}
                               label={t('Название (каз)')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                    {/*<Form.Item name={['town', 'index']}*/}
                    {/*           label={t('Индекс')}*/}
                    {/*           rules={[{required: true, message: t('Обязательное поле')}]}*/}
                    {/*>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
    </Card>
}
