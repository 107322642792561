import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getProvidersListFx = createEffect()
    .use(async (filters) => (await api().get(`/providers/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getProviderByIdFx = createEffect()
    .use(async (id) => (await api().get(`/providers/${id}`)).data)

export const getProviderBankAccountsFx = createEffect()
    .use(async (id) => (await api().get(`/bank_account/?provider_id=${id}&ignore_limit=true`)).data)

export const getBeneficiaryBankAccountsFx = createEffect()
    .use(async (id) => (await api().get(`/bank_account/?provider_id=${id}&ignore_limit=true`)).data)

export const getProviderDefaultBankAccountFx = createEffect()
    .use(async (id) => (await api().get(`/bank_account_default/?provider_id=${id}`)).data)

export const getProviderAgreementsFx = createEffect()
    .use(async (id) => (await api().get(`/agreement/?provider_id=${id}&ignore_limit=true`)).data)

export const getProviderServicesFx = createEffect()
    .use(async (id) => {
        try {
            const data = (await api().get(`/provider_service/?provider_id=${id}&ignore_limit=true`)).data
            return data
        } catch (e) {
            console.log('EEEEEERRRRRRR', e)
            return []
        }
    })

export const getProviderServiceBankAccountsFx = createEffect()
    .use(async ({provider_id, service_id}) => (await api().get(`/provider_service_bank_account/?provider_id=${provider_id}&service_id=${service_id}&ignore_limit=true`)).data)

// export const getProviderSrvTarifsFx = createEffect()
//     .use(async (id) => (await api().get(`/tarif/?provider_id=${id}&ignore_limit=true`)).data)

export const getProviderSrvPrefFx = createEffect()
    .use(async (id) => (await api().get(`/provider_service_preference/?provider_id=${id}&ignore_limit=true`)).data)

export const getProviderSrvComsFx = createEffect()
    .use(async (id) => (await api().get(`/provider_service_bank_commission/?provider_id=${id}&ignore_limit=true`)).data)

export const getProviderSrvBaREOsFx = createEffect()
    .use(async (id) => (await api().get(`/real_estate_object_provider_service/?provider_id=${id}&ignore_limit=true`)).data)

export const getProviderKassaFx = createEffect()
    .use(async (id) => (await api().get(`/provider_kassa/?provider_id=${id}&ignore_limit=true`)).data)

export const getProviderSrvAccsFx = createEffect()
    .use(async (id) => (await api().get(`/account_provider_service/?provider_id=${id}&ignore_limit=true`)).data)
    // .use(async (id) => (await api().get(`/account_provider_service/?ignore_limit=true`)).data)

export const getProviderSrvAccsForTarifsFx = createEffect()
    .use(async ({id, filters}) => (await api().get(`/account_provider_service/?provider_id=${id}&ignore_limit=true&${filters}`)).data)

export const getProviderSrvAccsTarifsFx = createEffect()
    .use(async (id) => (await api().get(`/account_service_tarif/?provider_id=${id}&ignore_limit=true`)).data)

export const getAccountsFx = createEffect()
    .use(async (filters) => (await api().get(`/accounts/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const uploadAgreementFileFx = createEffect()
    .use(async (files) => {
        const promises = files.map(async (file) => {
            const payload = new FormData()
            if (Array.isArray(file?.file)) {
                payload.append('file', file?.file?.[0]?.originFileObj)
            } else {
                payload.append('file', file?.file?.originFileObj)
            }
            const config = {headers: {'Content-Type': 'multipart/form-data'}}
            return (await api().post(`/agreement/load?agreement_id=${file.agreement_id}`, payload, config)).data
        })
        return Promise.allSettled(promises)
    })

export const downloadAgreementFileFx = createEffect()
    .use(async (id) => {
        const file = (await api().get(`/agreement/download?agreement_id=${id}`, {responseType: 'blob'}))
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        // link.download = `Соглашение ${id}`
        link.download = file.headers.get('content-disposition').split('filename*=')[1].replace(/"/gm, '')
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const createEditBankAccountFx = createEffect()
    .use(async (payload) => (await api().post('/operations/', payload)).data)

export const createEditDefaultBankAccountFx = createEffect()
    .use(async (payload) => (await api().post('/operations/', payload)).data)

export const createEditSrvBankAccountFx = createEffect()
    .use(async (payload) => (await api().post('/operations/', payload)).data)

export const createEditREOBankAccountFx = createEffect()
    .use(async (payload) => (await api().post('/operations/', payload)).data)

export const createEditProvSrvFx = createEffect()
    .use(async (payload) => (await api().post('/operations/', payload)).data)
export const deleteProvKassaFx = createEffect()
    .use(async (payload) => (await api().post('/operations/', payload)).data)

export const getViewAPSFx = createEffect()
	.use(async ({provider_id, provider_service_id}) => {
        let service = ''
        if (provider_service_id) {
            service = `&provider_service_id=${provider_service_id}`
        }
        return (await api().get(`/accruals/?provider_id=${provider_id}${service}`)).data
    })
