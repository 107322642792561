import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import Form from 'antd/es/form';
import Table from 'antd/es/table';
import Select from 'antd/es/select';
import InputNumber from 'antd/es/input-number';
import Button from 'antd/es/button';
import MappedDictItem from '../../../../components/MappedDictItem/MappedDictItem.jsx';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {filterOption} from '../../../../utils/selectUtils.jsx';
import {paginationConfig} from '../../../../utils/paginationConfig.js';
import {$prefsSelectOpts} from '../../../../models/dictionaryCorrectionModel/index.js';

export default function PrefsFormTable({prefs, maxPeople, srvForm, srvId}) {
    const {t} = useTranslation()
    const [prefForm] = Form.useForm()
    const selectedPref = Form.useWatch([0, 'preference_id'], prefForm)
    const selectedCnt = Form.useWatch([0, 'people_quantity'], prefForm)

    const {services: srvOpts} = useUnit($prefsSelectOpts)

    const [dataSource, setDatasource] = useState([])
    const [alreadyPrefed, setAlreadyPrefed] = useState(dataSource.map(i => i.people_quantity).reduce((acc, cur) => {
        return (acc ?? 0) + (cur ?? 0)
    }, 0))

    const calcPrefed = (all) => {
        setAlreadyPrefed(Object.values(all).reduce((acc, cur) => {
            return (acc ?? 0) + (cur?.people_quantity ?? 0)
        }, 0))
    }

    useEffect(() => {
        setDatasource(prefs)
    }, [prefs])

    useEffect(() => {
        prefForm.setFieldsValue(Object.fromEntries(
            dataSource.map(
                (values, idx) => [
                    idx,
                    {
                        people_quantity: values?.people_quantity ?? 0,
                        preference_id: values?.preference_id ?? undefined
                    }
                ]
            )
        ));
    }, [dataSource]);

    useEffect(() => {
        if (srvForm) {
            srvForm.setFieldValue('preferences', dataSource)
        }
    }, [dataSource, srvForm]);

    const onAdd = (vals) => {
        setDatasource(prev => {
            const newPref = {
                people_quantity: vals[0].people_quantity,
                preference_id: vals[0].preference_id
            }
            return prev.toSpliced(1, 0, newPref)
        })
    }

    const onEdit = (idx, value) => {
        console.log('onEdit')
        setDatasource(prev => {
            const updPref = {
                preference_id: prev[idx].preference_id,
                people_quantity: value
            }
            return prev.toSpliced(idx, 1, updPref)
        })
    }

    const onDelete = (idx) => {
        setDatasource(prev => prev.toSpliced(idx, 1))
    }

    const columnsPrefs = [
                {
                    title: t('Льгота'),
                    dataIndex: 'preference_id',
                    width: '60%',
                    render: (id, record, idx) => <>
                        {record?.id !== -1 && <MappedDictItem id={id} type={'preference_category'}/>}
                        <Form.Item name={[idx, 'preference_id']}
                                   noStyle
                                   hidden={record?.id !== -1}
                                   initialValue={id ?? undefined}
                        >
                            <Select
                                options={srvOpts.find(s => s.value === srvId)?.prefs?.filter(p => !dataSource.map(i => i.preference_id).includes(p.value)) ?? []}
                                style={{width: '100%'}}
                                filterOption={filterOption}
                                showSearch
                                allowClear
                                dropdownStyle={{minWidth: 'max-content'}}
                            />
                        </Form.Item>
                    </>
                },
                {
                    title: `${t('Кол-во чел.')} (${alreadyPrefed} из ${maxPeople})`,
                    dataIndex: 'people_quantity',
                    width: '30%',
                    render: (value, record, idx) => <Form.Item
                        name={[idx, 'people_quantity']}
                        initialValue={value ?? 0}
                        style={{margin: 0}}
                        rules={[
                            {
                                validator: (rule, value) => {
                                    if (value && (alreadyPrefed <= maxPeople)) {
                                        return Promise.resolve()
                                    } else if (value && alreadyPrefed > maxPeople) {
                                        return Promise.reject(`${t('Кол-во льготников не может превышать кол-во проживающих')} (${maxPeople})`)
                                    } else if (value === 0) {
                                        return Promise.reject(t('Кол-во льготников должно быть больше 0'))
                                    } else {
                                        return Promise.resolve()
                                    }
                                }
                            },
                        ]}
                        >
                            <InputNumber min={0} onChange={(val) => record?.id !== -1 && onEdit(idx, val)}/>
                        </Form.Item>
                },
                {
                    dataIndex: 'id',
                    align: 'center',
                    render: (id, _record, idx) => id === -1
                        ? <Button
                            icon={<PlusOutlined />}
                            onClick={() => prefForm.submit()}
                            type={'primary'}
                            disabled={!(selectedPref > 0 && selectedCnt > 0)}
                        /> : <Button
                            icon={<DeleteOutlined/>}
                            danger
                            onClick={() => onDelete(idx)}
                        />
                }
    ]

    return <Form form={prefForm} layout={'vertical'} onFinish={onAdd}
                 onValuesChange={(_cur, all) => {
                     console.log('PREFS FORM', all, selectedPref, selectedCnt)
                     calcPrefed(all)
                 }}
    >
        <Table
            size={'small'}
            bordered
            columns={columnsPrefs}
            dataSource={dataSource}
            pagination={paginationConfig}
        />
    </Form>
}
