import {useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {Popconfirm, Switch} from 'antd';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $selectedFile,
    $selectedFileCells, acceptFileEv,
    getFileCellsFx, rejectFileEv, ViewFileCellsGate
} from '../../../models/uploadREOModel';
import dayjs from 'dayjs';
import Divider from 'antd/es/divider';
import Table from 'antd/es/table';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import {CheckOutlined} from '@ant-design/icons';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import styles from '../Upload.module.css';
import Collapse from "antd/es/collapse/index.js";
import TextArea from "antd/es/input/TextArea.js";
import {useServerSortPage} from '../../../hooks/useServerSortPage.js';


export default function ViewREOFileCells() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const selectedFile = useUnit($selectedFile)
    const {data, count} = useUnit($selectedFileCells)
    const loading = useUnit(getFileCellsFx.pending)
    const accept = useUnit(acceptFileEv)
    const reject = useUnit(rejectFileEv)

    const {id} = useParams()
    const [search, setSearch] = useSearchParams()

    const [curPage, limit, _field, _order, onChangeTable] = useServerSortPage()

    const [valid, setValid] = useState(!!(search.get('validation') && search.get('validation')==='true'))
    const onValidChange = (checked) => {
        setValid(!!checked)
        search.set('validation', checked)
        setSearch(search)
    }

    const confirmDisabled = !(selectedFile?.status === 'file processed')

    const textError = (data, value) => {
        const e = data || ''
        const se = e.split('|')
        const te = se.shift()
        const ie = se.join(', ')
        const error = t(`import.errors.${te}`)
        if (value === undefined)
            return <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
        return <div>
            <div>{value}</div>
            <div style={{borderTop: '1px solid gray'}}>{error}{ie ? `: ${ie}` : ''}</div>
        </div>
    }

    const columns = [
        {
            title: t('№ строки'),
            dataIndex: 'row_index',
            align: 'center',
            key: 'row_index',
            sorter: true,
        },
        {
            title: t('Статус'),
            dataIndex: 'row_is_valid',
            align: 'center',
            key: 'row_is_valid',
            sorter: true,
            onCell: (record) => ({className: !record?.row_is_valid && styles.invalid}),
            render: (value, record) => !record?.row_is_valid ?
                ((record?.row_error || '').split('\n') || []).map(e => textError(e)) :
                <CheckOutlined/>
        },
        {
            title: t('Код улицы'),
            dataIndex: 'street_id_value',
            align: 'center',
            key: 'street_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.street_id_error && styles.invalid}),
            render: (value, record) => record?.street_id_error ?
                textError(record?.street_id_error, value) : value
        },
        {
            title: t('Дом'),
            dataIndex: 'house_value',
            align: 'center',
            key: 'house_value',
            sorter: true,
            onCell: (record) => ({className: record?.house_error && styles.invalid}),
            render: (value, record) => record?.house_error ?
                textError(record?.house_error, value) : value
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus_value',
            align: 'center',
            key: 'corpus_value',
            sorter: true,
            onCell: (record) => ({className: record?.corpus_error && styles.invalid}),
            render: (value, record) => record?.corpus_error ?
                textError(record?.corpus_error, value) : value
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id_value',
            align: 'center',
            key: 'town_distr_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.town_distr_id_error && styles.invalid}),
            render: (value, record) => record?.town_distr_id_error ?
                textError(record?.town_distr_id_error, value) : value
        },
        {
            title: t('Индекс'),
            dataIndex: 'index_value',
            align: 'center',
            key: 'index_value',
            sorter: true,
            onCell: (record) => ({className: record?.index_error && styles.invalid}),
            render: (value, record) => record?.index_error ?
                textError(record?.index_error, value) : value
        },
        {
            title: t('ЖК'),
            dataIndex: 'residential_complex_value',
            align: 'center',
            key: 'residential_complex_value',
            sorter: true,
            onCell: (record) => ({className: record?.residential_complex_error && styles.invalid}),
            render: (value, record) => record?.residential_complex_error ?
                textError(record?.residential_complex_error, value) : value
        },
        {
            title: t('Тип ОН'),
            dataIndex: 'real_estate_object_type_id_value',
            align: 'center',
            key: 'real_estate_object_type_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.real_estate_object_type_id_error && styles.invalid}),
            render: (value, record) => record?.real_estate_object_type_id_error ?
                textError(record?.real_estate_object_type_id_error, value) : value
            // render: (value, record) => <div style={{display: 'flex'}}>
            //     <div>{value}</div>
            //     {record?.real_estate_object_type_id_error ? textError(record?.real_estate_object_type_id_error) : ''}
            // </div>
        },
        {
            title: t('Кол-во этажей'),
            dataIndex: 'floor_count_value',
            align: 'center',
            key: 'floor_count_value',
            sorter: true,
            onCell: (record) => ({className: record?.floor_count_error && styles.invalid}),
            render: (value, record) => record?.floor_count_error ?
                textError(record?.floor_count_error, value) : value
        },
        {
            title: t('Кол-во подъездов'),
            dataIndex: 'entrance_count_value',
            align: 'center',
            key: 'entrance_count_value',
            sorter: true,
            onCell: (record) => ({className: record?.entrance_count_error && styles.invalid}),
            render: (value, record) => record?.entrance_count_error ?
                textError(record?.entrance_count_error, value) : value
        },
        {
            title: t('Номер участка'),
            dataIndex: 'sector_id_value',
            align: 'center',
            key: 'sector_id_value',
            sorter: true,
            onCell: (record) => ({className: record?.sector_id_error && styles.invalid}),
            render: (value, record) => record?.sector_id_error ?
                textError(record?.sector_id_error, value) : value
        },
        {
            title: t('Кол-во квартир'),
            dataIndex: 'apartment_count_value',
            align: 'center',
            key: 'apartment_count_value',
            sorter: true,
            onCell: (record) => ({className: record?.apartment_count_error && styles.invalid}),
            render: (value, record) => record?.apartment_count_error ?
                textError(record?.apartment_count_error, value) : value
        },
        {
            title: t('Флаг присутствия электросети'),
            dataIndex: 'is_electricity_network_present_value',
            align: 'center',
            key: 'is_electricity_network_present_value',
            sorter: true,
            onCell: (record) => ({className: record?.is_electricity_network_present_error && styles.invalid}),
            render: (value, record) => record?.is_electricity_network_present_error ?
                textError(record?.is_electricity_network_present_error, value) : value
        },
        {
            title: t('Флаг присутствия отопительной сети'),
            dataIndex: 'is_heat_network_present_value',
            align: 'center',
            key: 'is_heat_network_present_value',
            sorter: true,
            onCell: (record) => ({className: record?.is_heat_network_present_error && styles.invalid}),
            render: (value, record) => record?.is_heat_network_present_error ?
                textError(record?.is_heat_network_present_error, value) : value
        },
        {
            title: t('Флаг присутствия лифта'),
            dataIndex: 'is_elevator_present_value',
            align: 'center',
            key: 'is_elevator_present_value',
            sorter: true,
            onCell: (record) => ({className: record?.is_elevator_present_error && styles.invalid}),
            render: (value, record) => record?.is_elevator_present_error ?
                textError(record?.is_elevator_present_error) : value
        },
        {
            title: t('Флаг присутствия почтовых ящиков'),
            dataIndex: 'is_mailboxes_present_value',
            align: 'center',
            key: 'is_mailboxes_present_value',
            sorter: true,
            onCell: (record) => ({className: record?.is_mailboxes_present_error && styles.invalid}),
            render: (value, record) => record?.is_mailboxes_present_error ?
                textError(record?.is_mailboxes_present_error, value) : value
        },
        {
            title: t('РКА'),
            dataIndex: 'cadastral_number_value',
            align: 'center',
            key: 'cadastral_number_value',
            sorter: true,
            onCell: (record) => ({className: record?.cadastral_number_error && styles.invalid}),
            render: (value, record) => record?.cadastral_number_error ?
                textError(record?.cadastral_number_error, value) : value
        },
        {
            title: t('URL в 2gis'),
            dataIndex: 'gis_url_value',
            align: 'center',
            key: 'gis_url_value',
            sorter: true,
            onCell: (record) => ({className: record?.gis_url_error && styles.invalid}),
            render: (value, record) => record?.gis_url_error ?
                textError(record?.gis_url_error, value) : value
        },

    ]

    const items = [

        {
            key: 'total_rows',
            label: t('Всего строк'),
            children: selectedFile?.total_row_count
        },
        {
            key: 'error_row_count',
            label: t('Ошибочных строк'),
            children: selectedFile?.error_row_count
        },
        {
            key: 'imported_row_count',
            label: t('Импортировано строк'),
            children: selectedFile?.imported_row_count
        },
    ]

    const dateItems = [
        {
            key: 'load_date',
            label: t('Дата загрузки'),
            children: selectedFile?.created_at && dayjs(selectedFile?.created_at).format('DD.MM.YYYY HH:mm:ss')
        },
        {
            key: 'to_list',
            children: <Button
                onClick={() => navigate("../..", {relative: "path", replace: true})}
            >{t('К списку')}</Button>
        },
        {
            key: 'confirm',
            children: <Popconfirm
                title={t('Вы уверены, что хотите подтвердить этот файл?')}
                description={'Внесены в Систему будут только валидные строки'}
                onConfirm={() => {
                    accept(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
            >
                <Button type={'primary'} disabled={confirmDisabled}>{t('Подтвердить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'cancel',
            children: <Popconfirm
                title={t('Вы уверены, что хотите отклонить этот файл?')}
                onConfirm={() => {
                    reject(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
                okButtonProps={{danger: true}}
            >
                <Button danger>{t('Отклонить файл')}</Button>
            </Popconfirm>
        },
    ]

    console.log('selectedFile', selectedFile)

    return <Card title={`${t('Просмотр строк файла')} ${selectedFile?.file_name}`} size={'small'}>
        <ViewFileCellsGate id={id} search={search}/>
        <ViewDictionaryItemCommon showNav={false} items={items} itemsDates={dateItems}/>
        <Divider/>
        {selectedFile?.error ? <Collapse
            size="small"
            items={[
                {
                    key: '1',
                    label: t('Лог ошибок обработки файла'),
                    children: <TextArea rows={40} value={selectedFile?.error} />,
                },
            ]}
        /> : null}
        <Divider/>
        <div style={{display: 'flex', gap: 4, marginBottom: 24}}>
            <Switch checked={valid} onChange={onValidChange} checkedChildren={'Валидные'} unCheckedChildren={'Ошибочные'} />
        </div>
        <Table dataSource={data}
               loading={loading}
               columns={columns}
               onChange={(pagination, _filters, sorter) => {
                   onChangeTable({page: pagination?.current, limit: pagination?.pageSize, sort_field: sorter?.field ?? null, sort_order: sorter?.order ?? null})
               }}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(curPage),
                   total: count
               }}
               size={'small'}
               className={'table-container'}
        />
    </Card>
}
