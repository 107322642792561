import {Link, useLocation, useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap} from 'effector-react';
import {$infoMaps} from '../../models/infoModel/index.js';
import ConfigProvider from 'antd/es/config-provider';
import Breadcrumb from 'antd/es/breadcrumb';

const bStyle = {
    backgroundColor: '#f5f5f5',
    paddingBottom: '8px',
    marginBottom: '8px',
    marginLeft: '-32px',
    marginRight: '-32px',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '8px',
    marginTop: '-8px',
}


export default function BreadcrumbsGlobal() {
    const {t} = useTranslation()

    const maps = useStoreMap($infoMaps, (maps) => ({
        town: maps.townsMap,
        town_distr: maps.districtsMap,
        street: maps.streetsMap,
        nationality: maps.nationality ?? {},
        'service-classes': maps.serviceClasses ?? {},
        'preference-category': maps.preference_category ?? {},
        'consumer-category': maps.consumer_category ?? {},
        'inspectors': maps.inspectors ?? {},
        'chief-inspector': maps.chiefInspectorMap ?? {},
        'sector': maps.sector ?? {},
        'real-estate-object-type': maps.real_estate_object_type ?? {},
        street_type: maps.streetTypesMap,
        address: maps.addressesMap ?? {},
        kbe: maps.kbeMap ?? {},
        kbk: maps.kbkMap ?? {},
        knp: maps.knpMap ?? {},
        'service-epd-accrual-field': maps.accrualsMap ?? {},
        'epd-template-fields': maps.templatesMap ?? {},
        'epd-templates': maps.templatesMap ?? {},
        services: maps.servicesMap ?? {},
        banks: maps.banksMap ?? {},
        'bank-account-type': maps.bankAccountTypeMap ?? {},
        'service-payment-type': maps.servicePaymentTypeMap ?? {},
        'service-units': maps.serviceUnitsMap ?? {},
        'economy-sector': maps.economy_sector ?? {},
        'calc-type': maps.calc_type ?? {},
        'activity-type': maps.activity_type ?? {},
        'bank-api-address': maps.bank_api_address ?? {},
        'bank-api-info': maps.bank_api_info ?? {},
        tax_branch: maps.taxBranchesMap ?? {},
        currency: maps.currency ?? {},
        organization_type: maps.organizationTypeMap ?? {},
        accounts: {},
        role: maps.roleMap ?? {},
        permission: maps.permissionMap ?? {},
        position: maps.positionMap ?? {},
        'real-estate-objects': maps.realEstateObjectMap ?? {},
        'services-columns': maps.servicesColumnsMap ?? {},
        'algorithm': maps.calcTypeAlgorithmsMap ?? {},
        'bank-commission': maps.bankCommissionMap ?? {},
        providers: maps.providersMap ?? {},
        correction: maps.correction ?? {},
        upload: {},
        'upload-real-estate-objects': {},
        'upload-accounts': {},
        'upload-update-accounts': {},
        'upload-agreements': {},
        'upload-cash-payments': {},
        'close-month': {},
        'template-accruals': {},
        'users': {},
        'password-settings': {},
    }))

    const {pathname} = useLocation()
    const [searchParams, _] = useSearchParams()

    const [items, setItems] = useState([])

    console.log('BC.items', items, pathname)
    useEffect(() => {
        const splitPath = pathname.split('/')
            .filter((i) => i !== '')
        const itemsPrep = []
        const titlesPrep = []

        for (let i = 0; i < splitPath.length; i++) {
            if (splitPath[i] === splitPath.at(-1) && !isNaN(parseInt(splitPath[i]))) {
                if (splitPath.includes('dictionaries')) {
                    itemsPrep.push({
                        className: 'breadcrumb-item',
                        title: `${splitPath[i]} ${maps[splitPath[1]][splitPath[i]] ?? ''}`
                    })
                    titlesPrep.push(splitPath[i])
                } else {
                    itemsPrep.push({className: 'breadcrumb-item', title: splitPath[i]})
                }
            } else if (Object.keys(maps)?.includes(splitPath[i]) && splitPath[i] !== splitPath.at(-1)) {
                itemsPrep.push({
                    className: 'breadcrumb-item',
                    title: <Link
                        to={`${splitPath.slice(0, i + 1).join('/')}`}>{t(`breadcrumbs.${splitPath[i]}`)}</Link>
                })
                titlesPrep.push(splitPath[i])
            } else {
                if (splitPath[i] !== 'dictionaries') {
                    itemsPrep.push({
                        className: 'breadcrumb-item',
                        title: t(`breadcrumbs.${splitPath[i]}`)
                    })
                    titlesPrep.push(splitPath[i])
                }
            }
        }
        if (itemsPrep.length > 1) {
            const item = itemsPrep.at(-1)
            itemsPrep.splice(-1, 1, {...item, className: 'breadcrumb-item breadcrumb-item-last'})
        }
        if (searchParams.get('created_prev_id')) {
            const id = searchParams.get('created_prev_id')
            itemsPrep.push({
                className: 'breadcrumb-item',
                title: '(' + t(`breadcrumbs.created_prev_object`) + id + ')'
            })
        }
        if (titlesPrep.includes('role-permission')) {
            parseInt(searchParams.toString().split('role_id=')[1])
            itemsPrep.push({
                className: 'breadcrumb-item',
                title: maps?.role[parseInt(searchParams?.toString()?.split('role_id=')[1])]
            })

        }

        const titlesT = titlesPrep.map(x => isNaN(parseInt(x)) ? t(`titles.${x}`) : x)
        setItems(itemsPrep)
        document.title = titlesT.join(' : ')
    }, [pathname, maps]);

    return <ConfigProvider theme={{
        components: {Breadcrumb: {itemColor: '#222', fontSize: 16, linkColor: '#222'}}
    }}>
        <Breadcrumb items={items} separator={'>'} style={bStyle}/>
    </ConfigProvider>
}
