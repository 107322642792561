import {useStoreMap, useUnit} from 'effector-react';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Popconfirm from 'antd/es/popconfirm';
import Table from 'antd/es/table';
import {CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {$infoMaps} from '../../../models/infoModel/index.js';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import CreateEditStreetEmbeddedForm
    from '../CreateEditStreetEmbeddedForm/CreateEditStreetEmbeddedForm.jsx';
import {
    $createdStreets, $streetEmbeddedFilters,
    $streetsChildren, changeStreetsChildrenFiltersEv,
    createChildStreetEv,
    deleteChildStreetEv,
    deleteNewStreetEv,
    editChildStreetEv,
    setCreateEditChildStreetEmbeddedEv,
    StreetsChildrenGate,
    getStreetsChildrenFx
} from '../../../models/embeddedFormsModels/embeddedStreetsModel';
import Divider from 'antd/es/divider';
import StreetEmbeddedFilters from './StreetEmbeddedFilters.jsx';

export default function StreetsTable({view, parent, parentId, withTown}) {
    const {t} = useTranslation()
    const {data, count} = useUnit($streetsChildren)
    const newStreets = useUnit($createdStreets)

    const loading = useUnit(getStreetsChildrenFx.pending)

    const deleteExisting = useUnit(deleteChildStreetEv)
    const deleteNew = useUnit(deleteNewStreetEv)

    const onCreate = useUnit(createChildStreetEv)
    const onEdit = useUnit(editChildStreetEv)
    const stTypes = useStoreMap($infoMaps, maps => maps.shortStTypeMap)

    const setModal = useUnit(setCreateEditChildStreetEmbeddedEv)

    const {page, limit, sortField, sortOrder} = useUnit($streetEmbeddedFilters)
    const changeFilters = useUnit(changeStreetsChildrenFiltersEv)

    const columns = [
        {
            title: t('Код улицы'),
            dataIndex: 'id',
            width: '5%',
            align: 'center',
            sorter: (a, b) => a.id - b.id,
            key: 'id',
            render: (id) => id < 0 ? t('Новая') : id
        },
        {
            title: t('Название улицы (рус)'),
            dataIndex: 'name_ru',
            sorter: (a, b) => a.name_ru.localeCompare(b.name_ru),
            onCell: (record) => ({className: record?.changedFields?.includes('name_ru') || record?.changedFields?.includes('street_type_id') ? 'table-cell-edited' : ''}),
            render: (name, record) => record.id < 0 ? name : <Link to={`/dictionaries/street/view/${record.id}`}>
                {`${stTypes[record.street_type_id]?.short_name_ru} ${name}`}
            </Link>
        },
        {
            title: t('Название улицы (каз)'),
            dataIndex: 'name_kz',
            sorter: (a, b) => a.name_kz.localeCompare(b.name_kz),
            onCell: (record) => ({className: record?.changedFields?.includes('name_kz') || record?.changedFields?.includes('street_type_id') ? 'table-cell-edited' : ''}),
            render: (name, record) => record.id < 0 ? name : <Link to={`/dictionaries/street/view/${record.id}`}>
                {`${name} ${stTypes[record.street_type_id]?.short_name_kz}`}
            </Link>
        },
        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined />} onClick={() => setModal({type: 'create'})} type={'primary'}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={() => deleteExisting(id)}
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined />} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]

    const actionsNew = {
        title: <Tooltip title={t('Добавить')}>
            <Button icon={<PlusOutlined />} onClick={() => setModal({type: 'create'})} type={'primary'}/>
        </Tooltip>,
        width: '5%',
        align: 'center',
        dataIndex: 'id',
        key: 'actions',
        render: (id) => {
            return <ButtonGroup>
                <Tooltip title={t('Редактировать')}>
                    <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
                </Tooltip>
                <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                            okText={t('Да')}
                            onConfirm={() => deleteNew(id)}
                            placement={'left'}
                >
                    <Tooltip title={t('Удалить')}>
                        <Button icon={<CloseOutlined />} type={'primary'} danger/>
                    </Tooltip>
                </Popconfirm>
            </ButtonGroup>
        }
    }

    if (view) {
        const idx = columns.findIndex(i => i.key === 'actions')
        if (idx !== -1) {
            columns.splice(idx, 1)
        }
    }

    const columnsNew = columns.toSpliced(columns.findIndex(i => i.key === 'actions'), 1, actionsNew)

    return <>
        <StreetsChildrenGate parent={parent} parentId={+parentId} />
        <CreateEditStreetEmbeddedForm onCreate={onCreate} onEdit={onEdit} withTown={withTown}/>
        {
            (newStreets?.length > 0 || !parent) && <>
                <Table dataSource={[...newStreets]} columns={columnsNew} pagination={false}/>
                <Divider />
            </>
        }
        {(!!parentId || parentId === 0) && <StreetEmbeddedFilters/>}
        {parent && <Table dataSource={[...data]}
                loading={loading}
                columns={columns}
                bordered
                size={'small'}
                pagination={{
                    ...paginationConfig,
                    pageSize: parseInt(limit),
                    current: parseInt(page),
                    total: count
                }}
                onChange={({current, pageSize}, filters, {field, order}) => {
                    const payload = []
                    if (current !== page || pageSize !== limit) {
                        payload.push({key: 'page', value: current})
                        payload.push({key: 'limit', value: pageSize})
                    }

                    if (field !== sortField || order !== sortOrder) {
                        if (!order) {
                            payload.push({key: 'sort_field', value: null})
                            payload.push({key: 'sort_order', value: null})
                        } else {
                            payload.push({key: 'sort_field', value: field})
                            payload.push({
                                key: 'sort_order',
                                value: order === 'ascend' ? 'asc' : 'desc'
                            })
                        }
                    }
                    changeFilters(payload)
                }}
                style={{width: '100%'}}
        />}
    </>
}
