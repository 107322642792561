export function parseHouses(str) {
    const houses = str.replaceAll(' ', '').split(",");
    const result = [];

    for (let i = 0; i < houses.length; i++) {
        const part = houses[i];

        if (part.includes("-")) {
            const [start, end] = part.split("-").map(Number);
            for (let j = start; j <= end; j++) {
                result.push(j.toString());
            }
        } else {
            result.push(part);
        }
    }

    return result;
}