import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getRoleListFx = createEffect()
    .use(async (filters) => (await api().get(`/role/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getRoleByIdFx = createEffect()
    .use(async (id) => (await api().get(`/role/${id}`)).data)

export const createRoleFx = createEffect()
    .use(async (data) => (await api().post(`/role/`, data)))
