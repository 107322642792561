import {createEffect} from 'effector/compat';
import api from '../../api/api.js';
import queryString from 'query-string';
import dayjs from 'dayjs';

export const getControllersFx = createEffect()
.use(async (search) => {
    const params = queryString.stringify(search)
    return (await api().get(`/inspectors/${params.length > 0 ? `?${params}` : ''}`)).data
})


export const getAllDataFx = createEffect()
    .use(async (payload) => {
        const query = payload.data
        query.has_pdf = true
        const params = queryString.stringify(query)
        if (payload.generate_type === 'sector') {
            return (await api().get(`/sector/${params.length > 0 ? `?${params}` : ''}`)).data
        } else if (payload.generate_type === 'inspector') {
            return (await api().get(`/inspectors/${params.length > 0 ? `?${params}` : ''}`)).data
        } else if (payload.generate_type === 'reo') {
            return (await api().get(`/real_estate_objects/${params.length > 0 ? `?${params}` : ''}`)).data
        } else if (payload.generate_type === 'account') {
            const report_date = payload.report_date.replace('+', '%2B')
            return (await api().get(`/accounts/${params.length > 0 ? `?${params}` : ''}&send_to_bank=${payload.send_to_bank}&report_date=${report_date}`)).data
        }

    })


export const getForAllPdfFx = createEffect()
    .use(async (payload) => {
        const params = queryString.stringify(payload.filters)

        if (payload.type === 'all') {
            return {data: [], count: 0}
        } else if (payload.type === 'sector') {
            return (await api().get(`/sector/${params.length > 0 ? `?${params}` : ''}`)).data
        } else if (payload.type === 'inspector') {
            return (await api().get(`/inspectors/${params.length > 0 ? `?${params}` : ''}`)).data
        } else if (payload.type === 'reo') {
            return (await api().get(`/real_estate_objects/${params.length > 0 ? `?${params}` : ''}`)).data
        } else if (payload.type === 'account') {
            return (await api().get(`/accounts/${params.length > 0 ? `?${params}` : ''}`)).data
        }


        // return (await api().get(`/inspectors/${params.length > 0 ? `?${params}` : ''}`)).data
    })

export const createTaskFx = createEffect()
.use(async (data) => {
    return (await api().post(`/epd_generator/generate`, data)).data
})

export const downloadFilesFx = createEffect()
    .use(async ({ids, filters, type, send_to_bank, report_date}) => {
        let fileData = null
        if (type === 'sector') {
            if (ids.length > 0) {
                const file = (await api().post(`/sector_epd/download_files_zip`, {sector_ids: ids}, {responseType: 'blob'}))
                fileData = file
            } else {
                const file = (await api().post(`/sector_epd/download_files_zip`, {filters: filters}, {responseType: 'blob'}))
                fileData = file
            }

        } else if (type === 'inspector') {
            if (ids.length > 0) {
                const file = (await api().post(`/dispatch/download_inspector_files_zip`, {inspector_ids: ids}, {responseType: 'blob'}))
                fileData = file
            } else {
                const file = (await api().post(`/dispatch/download_inspector_files_zip`, {filters: filters}, {responseType: 'blob'}))
                fileData = file
            }
        } else if (type === 'reo') {
            if (ids.length > 0) {
                const file = (await api().post(`/real_estate_object_epd/download_files_zip`, {reo_ids: ids}, {responseType: 'blob'}))
                fileData = file
            } else {
                const file = (await api().post(`/real_estate_object_epd/download_files_zip`, {
                    filters_ids: filters,

                }, {responseType: 'blob'}))
                fileData = file
            }
        } else if (type === 'account') {
            if (ids.length > 0) {
                const file = (await api().post(`/epd/download_files_zip`, {
                    account_ids: ids,
                    send_to_bank: send_to_bank,
                    report_date: report_date
                }, {responseType: 'blob'}))
                fileData = file
            } else {
                const file = (await api().post(`/epd/download_files_zip`, {
                    filters: {
                     ...filters,
                        send_to_bank: send_to_bank,
                        report_date: report_date
                    },
                    send_to_bank: send_to_bank,
                    report_date: report_date
                }, {responseType: 'blob'}))
                fileData = file
            }
        } else {
            const file = (await api().post(`/dispatch/download_inspector_files_zip`, {inspector_ids: ids, filters, report_date}, {responseType: 'blob'}))
            fileData = file
        }
        // const file = (await api().post(`/dispatch/download_inspector_files_zip`, {inspector_ids: ids, filters, report_date}, {responseType: 'blob'}))
        const link = document.createElement('a')
        const url = URL.createObjectURL(fileData.data)
        link.href = url
        link.target = '_blank'
        link.download = `Файлы  (${ids.join(', ')}) от ${dayjs(report_date).format('DD.MM.YYYY')}.zip`
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const downloadSingleFileFx = createEffect()
.use(async data => {
    let fileData = null
    const no_cache= Math.random()
    if (data.generate_type === 'sector') {
        const file = (await api().get(`/sector_epd/download_file?file_id=${data.pdf_id}&${no_cache}`, {responseType: 'blob'}))
        fileData = file
    } else if (data.generate_type === 'inspector') {
        const file = (await api().get(`/dispatch/download_inspector_file/?file_id=${data.pdf_id}&${no_cache}`, {responseType: 'blob'}))
        fileData = file
    } else if (data.generate_type === 'reo') {
        const file = (await api().get(`/real_estate_object_epd/download_file?file_id=${data.pdf_id}&${no_cache}`, {responseType: 'blob'}))
        fileData = file
    } else if (data.generate_type === 'account') {
        const file = (await api().get(`/epd/download_file?file_id=${data.pdf_id}&send_to_bank=${data.send_to_bank}&${no_cache}`, {responseType: 'blob'}))
        fileData = file
        console.log('file', file, fileData)
    } else {
        const file = (await api().get(`/dispatch/download_inspector_file?file_id=${data.pdf_id}&${no_cache}`, {responseType: 'blob'}))
    }
    const link = document.createElement('a')

    const url = URL.createObjectURL(fileData.data)
    link.href = url
    link.target = '_blank'
    link.download = `Файл ${data.pdf_id}.pdf`

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
})

export const uploadFileFx = createEffect()
.use(async ({table_name, data}) => {
  const payload = new FormData()
  data.files.forEach(file => {
    payload.append('files', file?.originFileObj)
  })
  const config = {headers: {'Content-Type': 'multipart/form-data'}}

  return (await api().post(`/import_file/import/${table_name}`, payload, config)).data
})

export const getFileListFx = createEffect()
.use(async (table_name) => (await api().get(`/import_file/?table_name=${table_name}`)).data)
