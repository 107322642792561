import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getReportsListFx = createEffect()
    .use(async (filters) => (await api().get(`/reports/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getReportsByIdFx = createEffect()
    .use(async (id) => (await api().get(`/reports/${id}`)).data)


export const createReportFx = createEffect()
    .use(async (payload) => (await api().post(`/reports/`, payload)).data)


export const downloadReportFx = createEffect()
    .use(async (path) => (await api().get(`/reports/download-file/?path=${path}`)).data)
    .use(async (path) => {
        // const params = filters.size > 0 ? `?${filters.toString()}` : ''
        const file = await api().get(`/reports/download-file/?path=${path}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=').length > 1 ?
            file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '') :
            // file.headers["content-disposition"].split('filename*=')[1].replace(/"/gm, '')
            decodeURIComponent(file.headers["content-disposition"].split("filename*=utf-8''")[1].replace(/"/gm, ''))
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const sendMessageReportsFx = createEffect()
    .use(async (data) => {
            data.sendMessage(JSON.stringify(data.payload))
    });
