import {
    $afterCreateAction,
    $createdBankApiAddressId,
    $editBankApiAddressStatus,
    $selectedBankApiAddress,
    $bankApiAddressList
} from './stores.js';
import {getBankApiAddressListFx, getBankApiAddressByIdFx} from './effects.js';
import {
    deleteBankApiAddressEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    BankApiAddressEditGate,
    BankApiAddressListGate,
    BankApiAddressViewGate,
    submitCreateBankApiAddressEv,
    submitEditBankApiAddressEv, changeIpAddressFiltersEv, BankApiAddressCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import queryString from "query-string";

const createCompleteEv = combineEvents({events: {ev: submitCreateBankApiAddressEv, response: triggerSuccessOpsEv}, reset: BankApiAddressCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditBankApiAddressEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteBankApiAddressEv, response: triggerSuccessOpsEv})

$bankApiAddressList.on(getBankApiAddressListFx.doneData, (_, data) => data)
    .reset(BankApiAddressListGate.close)

$selectedBankApiAddress.on(getBankApiAddressByIdFx.doneData, (_, data) => data)
    .reset([BankApiAddressEditGate.close, BankApiAddressViewGate.close])
// FIXME
$createdBankApiAddressId.on(createCompleteEv, (_, {response}) => response.bank_api_address)
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editBankApiAddressStatus.on(editCompleteEv, () => true)
    .reset(BankApiAddressEditGate.close)

sample({
    clock: changeIpAddressFiltersEv,
    // filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (payload) => {
        const result = {}
        if (Array.isArray(payload)) {
            for (const change of Object.values(payload)) {
                result[change.key] = change.value
            }
        } else if (!Array.isArray(payload)) {
            result[payload.key] = payload.value
        }
        for (const key in result) {
            if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
                delete result[key]
            }
        }
        return queryString.stringify(result)
    },
    target: getBankApiAddressListFx
})

sample({
    clock: [BankApiAddressEditGate.state, BankApiAddressViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getBankApiAddressByIdFx
})

sample({
    clock: BankApiAddressListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankApiAddressListFx
})

sample({
    source: BankApiAddressListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getBankApiAddressListFx
})

sample({
    clock: submitCreateBankApiAddressEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'bank_api_address', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedBankApiAddress,
    clock: submitEditBankApiAddressEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'bank_api_address', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteBankApiAddressEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'bank_api_address', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
