import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getRolePermissionListFx = createEffect()
    .use(async (filters) => (await api().get(`/role_permission/${filters.length > 0 ? `?${filters}&without_limit=true` : ''}`)).data)

export const getRolePermissionByIdFx = createEffect()
    .use(async (id) => (await api().get(`/role_permission/${id}&without_limit=true`)).data)


export const getPermissionFx = createEffect()
    // .use(async (id) => (await api().get(`/permission/?ignore_limit=true`)).data)
    .use(async (id) => (await api().get(`/role/`)).data)
