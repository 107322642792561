import {
    $afterCreateAction,
    $createdTemplatePaymentsId,
    $editTemplatePaymentsStatus,
    $selectedTemplatePayments,
    $templatePaymentsList
} from './stores.js';
import {getTemplatePaymentsListFx, getTemplatePaymentsByIdFx} from './effects.js';
import {
    deleteTemplatePaymentsEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    TemplatePaymentsEditGate,
    TemplatePaymentsListGate,
    TemplatePaymentsViewGate,
    submitCreateTemplatePaymentsEv,
    submitEditTemplatePaymentsEv, TemplatePaymentsCreateGate,
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {resetCreateInspectorEmbeddedEv, resetCreateTownEmbeddedEv} from "../dictionaryEmbeddedFormsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateTemplatePaymentsEv, response: triggerSuccessOpsEv}, reset: TemplatePaymentsCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditTemplatePaymentsEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteTemplatePaymentsEv, response: triggerSuccessOpsEv})

$templatePaymentsList.on(getTemplatePaymentsListFx.doneData, (_, data) => data)
    .reset(TemplatePaymentsListGate.close)

$selectedTemplatePayments.on(getTemplatePaymentsByIdFx.doneData, (_, data) => data)
    .reset([TemplatePaymentsEditGate.close, TemplatePaymentsViewGate.close])
// FIXME
$createdTemplatePaymentsId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "accrual_file_template")
    const result = data ? data?.value?.id : undefined
    console.log('created templatePayments id', result)
    return result
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editTemplatePaymentsStatus.on(editCompleteEv, () => true)
    .reset(TemplatePaymentsEditGate.close)

sample({
    clock: [TemplatePaymentsEditGate.state, TemplatePaymentsViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getTemplatePaymentsByIdFx
})

sample({
    clock: TemplatePaymentsListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getTemplatePaymentsListFx
})

sample({
    source: TemplatePaymentsListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getTemplatePaymentsListFx
})

sample({
    clock: submitCreateTemplatePaymentsEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'accrual_file_template', value: {...payload, id: idCounter}})
        idCounter--


        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedTemplatePayments,
    clock: submitEditTemplatePaymentsEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []

        let idCounter = -1
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }



        actions.push({operation: 'update', type: 'accrual_file_template', value: {object_id: current.id, ...changedFields}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteTemplatePaymentsEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'accrual_file_template', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [TemplatePaymentsCreateGate.close, TemplatePaymentsEditGate.close],
    target: [resetCreateTownEmbeddedEv, resetCreateInspectorEmbeddedEv]
})

