import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $accsFilters,
    $accsTable,
    $excludedAccs,
    $foundAccs,
    $multiSelectedAccs, $selectedAcc,
    addExcludedAccEv, afterActionsEnum,
    getAccsFx,
    removeExcludedAccEv,
    resetSelectedAccEv, setMassEditModalEv,
    setMultiSelectedAccsEv,
    setSelectedAccEv,
    setTableParamsEv
} from '../../../models/dictionaryCorrectionModel/index.js';
import Table from 'antd/es/table';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import Checkbox from 'antd/es/checkbox';
import Tooltip from 'antd/es/tooltip';
import {CloseOutlined, EditOutlined, MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import styles from './CorrectionStyles.module.css';
import Card from 'antd/es/card';
import MassCorrModal from './modals/MassCorrModal.jsx';
import {isId} from '../../../utils/helpers.js';

export default function AccountsTable() {
    const {t} = useTranslation()

    const accsLoading = useUnit(getAccsFx.pending)
    const accFilters = useUnit($accsFilters)
    const {count, data: foundAccs} = useUnit($foundAccs)
    const setTable = useUnit(setTableParamsEv)
    const {page, limit, sort_field, sort_order} = useUnit($accsTable)

    const selectAcc = useUnit(setSelectedAccEv)
    const selectedAcc = useUnit($selectedAcc)
    const resetSelected = useUnit(resetSelectedAccEv)

    const selectMultiAccs = useUnit(setMultiSelectedAccsEv)
    const multiAccs = useUnit($multiSelectedAccs)

    const excludedAccs = useUnit($excludedAccs)
    const excludeAcc = useUnit(addExcludedAccEv)
    const returnAcc = useUnit(removeExcludedAccEv)

    const setMassModal = useUnit(setMassEditModalEv)

    const onChangeTable = ({current, pageSize}, filters, {field, order}) => {
        const payload = []
        if (current !== page || pageSize !== limit) {
            payload.push({key: 'page', value: current})
            payload.push({key: 'limit', value: pageSize})
        }

        if (field !== sort_field || order !== sort_order) {
            if (!order) {
                payload.push({key: 'sort_field', value: null})
                payload.push({key: 'sort_order', value: null})
            } else {
                payload.push({key: 'sort_field', value: field})
                payload.push({
                    key: 'sort_order',
                    value: order === 'ascend' ? 'asc' : 'desc'
                })
            }
        }
        setTable(payload)
        resetSelected()
    }

    const columns = [
        Table.SELECTION_COLUMN,
        {
            title: t('Номер ЛС'),
            dataIndex: 'account_id',
            sorter: true,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
            render: (id) => <MappedDictItem id={id} type={'accounts'}/>
        },
        {
            title: t('ФИО'),
            dataIndex: 'name_ru',
            sorter: true,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
        },
        {
            title: t('Населенный пункт'),
            dataIndex: 'town_id',
            key: 'town_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town'} link={false}/>,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
        },
        {
            title: t('Район'),
            dataIndex: 'town_distr_id',
            key: 'town_distr_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'town_distr'} link={false}/>,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
        },
        {
            title: t('Улица'),
            dataIndex: 'street_id',
            key: 'street_id',
            sorter: true,
            render: (id) => <MappedDictItem id={id} type={'street'} link={false}/>,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
        },
        {
            title: t('Дом'),
            dataIndex: 'house',
            key: 'house',
            sorter: true,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
        },
        {
            title: t('Корпус'),
            dataIndex: 'corpus',
            key: 'corpus',
            sorter: true,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
        },
        {
            title: t('Квартира'),
            dataIndex: 'flat',
            key: 'flat',
            sorter: true,
            onCell: (record) => selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs),
        },
        {
            dataIndex: 'account_id',
            key: 'actions',
            align: 'center',
            render: (id) => {
                    return <ButtonGroup>
                        {
                            (foundAccs?.length > 0 && isId(accFilters?.provider_id) && isId(accFilters?.service_id))
                            && <>
                                {
                                excludedAccs.includes(id)
                                    ? <Tooltip title={t('Добавить')}>
                                        <Button type={'primary'} className={styles.addButton}
                                                size={'small'} onClick={() => returnAcc(id)}
                                                icon={<PlusOutlined/>}
                                        />
                                    </Tooltip>
                                    : <Tooltip title={t('Исключить')}>
                                        <Button type={'primary'} danger size={'small'} onClick={() => excludeAcc(id)} icon={<MinusOutlined/>}/>
                                    </Tooltip>
                            }
                            </>
                        }
                        {id === selectedAcc
                            ?  <Tooltip title={t('Отмена')}>
                                <Button icon={<CloseOutlined />}
                                        onClick={() => resetSelected()}
                                />
                            </Tooltip>
                            : <Tooltip title={t('Редактировать')}>
                                <Button icon={<EditOutlined/>} type={'primary'}
                                        disabled={excludedAccs.includes(id)}
                                        onClick={() => selectAcc(id)}
                                />
                            </Tooltip>}
                    </ButtonGroup>
            }
        }
    ]

    return <Card title={t('ЛС соответствующие фильтру')}
                 extra={(foundAccs?.length > 0 && isId(accFilters?.provider_id) && isId(accFilters?.service_id))
                     ? <ButtonGroup>
                         <Button type={'primary'} disabled={!multiAccs?.length} onClick={() => setMassModal({open: true, type: afterActionsEnum.mass_selected})}>
                             {t('Групповая по выделенным')}
                         </Button>
                         <Button onClick={() => setMassModal({open: true, type: afterActionsEnum.mass_all})}>
                             {t('Групповая по отфильтрованным и не исключенным')}
                         </Button>
                     </ButtonGroup>
                     : null}
    >
        <MassCorrModal />
            <Table
            loading={accsLoading}
            size={'small'}
            bordered
            columns={columns}
            dataSource={foundAccs}
            onChange={onChangeTable}
            pagination={{
                ...paginationConfig,
                pageSize: parseInt(limit),
                current: parseInt(page),
                total: count
            }}
            rowKey={'account_id'}
            rowClassName={row => excludedAccs.includes(row.account_id) ? styles.rowInactive : null}
            rowSelection={
                (foundAccs?.length > 0 && isId(accFilters?.provider_id) && isId(accFilters?.service_id))
                    ? {
                        type: 'checkbox',
                        selectedRowKeys: multiAccs,
                        renderCell: (value, record, index, originNode) => {
                            const blocked = excludedAccs.includes(record.account_id)
                            return blocked ? null :
                                <Checkbox checked={value} onChange={originNode.props.onChange}/>
                        },
                        onChange: ids => {
                            selectMultiAccs(ids)
                        }
                    }
                    : false
            }
        />
    </Card>
}

function selectOnRowClick(record, multiAccs, excludedAccs, selectMultiAccs) {
    return {
        onClick: () => {
            if (excludedAccs.includes(record.account_id)) {
                return
            } else {
                multiAccs.includes(record.account_id) ? selectMultiAccs(multiAccs.filter(i => i !== record.account_id)) : selectMultiAccs([...multiAccs, record.account_id])
            }
        }
    }
}