import {useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import {Popconfirm, Progress} from 'antd';
import Tooltip from 'antd/es/tooltip';
import {
    CheckCircleOutlined, CheckOutlined,
    CloseCircleOutlined, CloseOutlined, EyeOutlined, LoadingOutlined,
    StopOutlined
} from '@ant-design/icons';

import {
    setUploadFileModalEv,
    $uploadedFilesData,
    getFileListFx,
    UploadedFilesGate,
    approveFileEv,
    disaproveFileEv,
    stopImportTaskEv,
    stopConfirmTaskEv,
    approveFileFx,
    disapproveFileFx,
    stopImportTaskFx, stopConfirmTaskFx
} from '../../../models/taskPdfToControllersModel';

import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import FileManagerUploadModal from "./FileManagerUploadModal.jsx";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import CreatedBy from "../../../components/CreatedBy/CreatedBy.jsx";
import ButtonGroup from "antd/es/button/button-group";
import {useEffect} from "react";
import {sendMessageUploadEv} from "../../../models/uploadREOModel";
import {$WsSt} from "../../../models/webSocketModel/index.js";
import {useWebSocket} from "../../../api/WebSocketProvider.jsx";
import {searchAndActions} from "../../../utils/searchAndActions.js";
import {Link} from "react-router-dom";


export default function FileManager({table_name = null}) {
    const {t} = useTranslation()

    const {data: filesData, count: filesCount} = useUnit($uploadedFilesData)
    const setUploadEmailFileModal = useUnit(setUploadFileModalEv)
    const filesLoading = useUnit(getFileListFx.pending)

    const approveFile = useUnit(approveFileEv)
    const approveLoading = useUnit(approveFileFx.pending)
    const disapproveFile = useUnit(disaproveFileEv)
    const disapproveLoading = useUnit(disapproveFileFx.pending)
    const stopImportTask = useUnit(stopImportTaskEv)
    const stopImportLoading = useUnit(stopImportTaskFx.pending)
    const stopConfirmTask = useUnit(stopConfirmTaskEv)
    const stopConfirmLoading = useUnit(stopConfirmTaskFx.pending)
    const loading = filesLoading || approveLoading || disapproveLoading || stopImportLoading || stopConfirmLoading
    const {search} = searchAndActions()

    const { sendMessage } = useWebSocket();
    const wsStatus = useUnit($WsSt)

    useEffect(() => {
        sendMessageUploadEv({
            sendMessage: sendMessage,
            payload: {
                object_type: "import_email",
                action: 'get_list',
                subscribe: true
            }
        })
    }, [wsStatus]);


    const columns = [
        {
            title: t('Статус загрузки'),
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (status, record) => <Tooltip
                title={
                    status === 'new'
                        ? t('В обработке')
                        : status === 'file processed' || status === 'file approved' || status === 'file disapproved' || status === 'adding_failed' || status === 'adding_stopped'
                            // ? record.load_error
                            ? t('Успешно')
                            : status === "stopped" || status === 'processing stopped'
                                ? t('Остановлено')
                                : status === "error" || status === 'processing failed'
                                    ? t('Ошибка')
                                    :  '-'
                }
                placement={'right'}
            >
                {
                    status === 'new'
                        ? <div>
                            <Progress type="circle" size={40} percent={parseInt(record.progress)}/>
                        </div>
                        : status === "file processed" ||  status === 'file approved' || status === 'file disapproved' || status === 'adding_failed' || status === 'adding_stopped'
                            ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
                            : status === "stopped" || status === 'processing stopped' ? <StopOutlined style={{color: 'red', fontSize: 24}}/>
                                : status === "error" || status === 'processing failed' ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                                    : '-'
                }
            </Tooltip>
        },
        {
            title: t('Статус принятия'),
            dataIndex: 'is_approved',
            key: 'is_approved',
            align: 'center',
            width: '5%',
            render: (accepted, record) => record.stage === "Перенос данных"  ?
                <Tooltip title={record.stage}>
                    <LoadingOutlined style={{fontSize: 24}}/>
                </Tooltip> :
                accepted === true ?
                    <Tooltip title={'Файл подтвержден'}> <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>  </Tooltip>:
                    accepted === false ?
                        <Tooltip title={'Файл отклонен'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                        record.status === 'adding_stopped' ?
                            <Tooltip title={'Остановлен'}> <StopOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                            record.status === 'adding_failed' ?
                                <Tooltip title={'Ошибка'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
                            '-'
        },
        {
            title: t('Название файла'),
            dataIndex: 'file_name',
            sorter: true,
            key: 'filename',
            render: (filename) => filename.length > 20 ?
                <Tooltip title={filename}> {filename.substring(0, 20) + '...'}</Tooltip> :
                <Tooltip title={filename}>{filename}</Tooltip>
        },

        {
            title: t('Дата загрузки'),
            dataIndex: 'created_at',
            key: 'created_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем загружен'),
            dataIndex: 'created_by',
            key: 'created_by',
            sorter: true,
            render: (id) => <CreatedBy id={parseInt(id)}/>
            // render: (id) => <p>{id}</p>
        },
        {
            title: t('Дата решения'),
            dataIndex: 'decided_at',
            key: 'decided_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем решено'),
            dataIndex: 'decided_by',
            key: 'decided_by',
            sorter: true,
            render: (id) => <CreatedBy id={parseInt(id)}/>
        },
        {
            title: t('Всего строк'),
            dataIndex: 'total_row_count',
            key: 'total_row_count',
        },
        {
            title: t('Всего ошибок'),
            dataIndex: 'total_errors',
            key: 'total_errors',
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            key: 'actions',
            dataIndex: 'id',
            render: (id, record) => record.status === "new"  ?
                <Tooltip
                    title={t('Остановить загрузку')}
                >
                    <Popconfirm title={t('Вы уверены, что хотите остановить загрузку этого файла?')}
                                onConfirm={() => stopImportTask(id)}
                                okText={t('Да')}
                    >
                        <Button
                            icon={<StopOutlined />}
                            danger
                            type={'primary'}
                            // disabled={record.status === 'processing'}

                        />
                    </Popconfirm>
                </Tooltip> :

                record.status === "adding"  ?
                    <Tooltip
                        title={t('Остановить загрузку')}
                    >
                        <Popconfirm title={t('Вы уверены, что хотите остановить подтверждение этого файла?')}
                                    onConfirm={() => stopConfirmTask(id)}
                                    okText={t('Да')}
                        >
                            <Button
                                icon={<StopOutlined />}
                                danger
                                type={'primary'}
                                // disabled={record.status === 'processing'}

                            />
                        </Popconfirm>
                    </Tooltip>

                : record.status === 'file processed' ?
                    <ButtonGroup>
                        <Tooltip title={t('Просмотр строк')}>
                            <Link to={'/approve-emails/view/' + id}>
                                <Button icon={<EyeOutlined />}/>
                            </Link>
                        </Tooltip>
                        <Tooltip title={t('Подтвердить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите подтвердить этот файл?')}
                                        onConfirm={() => approveFile(id)}
                                        okText={t('Да')}
                            >
                                <Button icon={<CheckOutlined />} type={'primary'}/>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={t('Отклонить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите отклонить этот файл?')}
                                        onConfirm={() => disapproveFile(id)}
                                        okText={t('Да')}
                                        okButtonProps={{danger: true}}
                            >
                                <Button icon={<CloseOutlined />} danger/>
                            </Popconfirm>
                        </Tooltip>
                    </ButtonGroup>
                    : record.status === "file approved" ?
                        ''
                        : record.status === "stopped" ?
                            ''
                            : record.status === "error" ?
                                <Tooltip title={t('Просмотр строк')}>
                                    <Button icon={<EyeOutlined />} href={'/approve-emails/view/' + id} />
                                </Tooltip>
                                : ''

        }
    ]

    console.log('filesData', filesData)


    return <>
        <UploadedFilesGate table_name={table_name} search={search.toString()}/>
        {
            table_name ?
              <Card>
                  <Button type={'primary'} onClick={setUploadEmailFileModal}>
                      {t('Загрузить файл')}
                  </Button>
                  <DictionaryProTable
                    style={{paddingTop: '30px'}}
                    loading={loading}
                    initColumns={columns}
                    data={filesData}
                    count={filesCount}
                    showActions={false}
                  />
              </Card>
              :
              ''
        }
        <FileManagerUploadModal table_name={table_name}/>
    </>
}
