import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {
    $createdServiceUnitsId,
    submitCreateServiceUnitsEv,
    ServiceUnitsCreateGate,
    $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
} from '../../../models/dictionaryServiceUnitsModel/index.js';
import Select from "antd/es/select/index.js";
import {$dictsSelects} from "../../../models/infoModel/index.js";

export default function CreateServiceUnits() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [serviceUnitsForm] = Form.useForm()

    const submit = useUnit(submitCreateServiceUnitsEv)

    const loading = useUnit(universalDictEndpointFx.pending)
    const algorithmOpts = useStoreMap($dictsSelects, sel => sel.algorithms)

    const createdId = useUnit($createdServiceUnitsId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const genitive_form = t(`breadcrumbs.genitive.service-units`);


    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/service-units/edit/${createdId}`)
            } else if (action === 'new') {
                serviceUnitsForm.resetFields()
            } else if (action === 'list') {
                serviceUnitsForm.resetFields()
                navigate(`/dictionaries/service-units?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);


    return <>
        <ServiceUnitsCreateGate/>
        <Form form={serviceUnitsForm} layout={'vertical'} onFinish={(v) => submit(v)} size={'small'}>
            <Card title={t('Свойства ед. изм. услуг')} size={'small'}>
                <Row gutter={[16,16]}>
                    <Col span={4}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'short_name_kz'}
                                   label={t(`Краткое название (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Краткое название (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'algorithm_id'} label={t('Алгоритм')}
                            rules={[{required: true}]}
                        >
                            <Select options={algorithmOpts} showSearch allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    serviceUnitsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            serviceUnitsForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            serviceUnitsForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            serviceUnitsForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и перейти к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
