import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    $uploadCorrectionModal,
    $uploadCorrectionStatus, resetUploadCorrModalEv,
    uploadCorrEv,
    uploadCorrFx
} from '../../../../models/dictionaryCorrectionModel/index.js';
import Form from 'antd/es/form';
import Button from 'antd/es/button';
import Popconfirm from 'antd/es/popconfirm';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Card from 'antd/es/card';
import Modal from 'antd/es/modal';
import Switch from 'antd/es/switch';
import UploadComponent from '../../../../components/UploadComponent/UploadComponent.jsx';
import {notification} from 'antd';

export default function UploadCorrectionModal({id}) {
    const {t} = useTranslation()
    const open = useUnit($uploadCorrectionModal)
    const resetModal = useUnit(resetUploadCorrModalEv)

    const onUpload = useUnit(uploadCorrEv)
    const loading = useUnit(uploadCorrFx.pending)
    const status = useUnit($uploadCorrectionStatus)

    const [fileForm] = Form.useForm()

    const onReset = () => {
        fileForm.resetFields()
        resetModal()
    }

    const onSubmit = (values) => {
        if (!values?.files || !values?.files[0]) {
            return notification.error({message: t('Ошибка'), description: t('Файл не выбран')})
        }
        onUpload({...values, id})
    }

    useEffect(() => {
        if (status) {
            onReset()
        }
    }, [status]);

    return <Modal open={open}
                  width={'80vw'}
                  title={t('Загрузка файла')}
                  onCancel={onReset}
                  okButtonProps={{loading: loading}}
                  destroyOnClose
                  footer={[
                      <Button key="back" onClick={onReset}>
                          {t('Отмена')}
                      </Button>,
                      <Popconfirm
                          placement="leftTop"
                          title={t('Загрузка файла')}
                          description={t('Вы уверены, что хотите загрузить файл?')}
                          onConfirm={() => fileForm.submit()}
                          okText={t('Да')}
                          cancelText={t('Отменить')}
                      >
                          <Button key="submit" type="primary" loading={loading}>
                              {t('Загрузить файл')}
                          </Button>
                      </Popconfirm>,
                  ]}
    >
        <Form
            form={fileForm} onFinish={onSubmit} layout={'vertical'} size={'small'}
            initialValues={{copy_quantity: true, copy_square: false}}
        >
            <Row justify={"space-between"} gutter={[8, 16]}>
                <Col span={24}>
                    <Card size={'small'} title={t('Обновить данные в ЛС:')}>
                        <Row justify={"space-between"}>
                            <Col span={11}>
                                <Form.Item
                                    name={'copy_square'} label={t('Копировать площадь')}
                                    tooltip={t('Если вы хотите обновить площадь в ЛС, т.е. записать туда данные столбца площади из загружаемого файла, то выберите в какое поле ЛС сохранять изменение')}
                                    valuePropName={'checked'}
                                >
                                    <Switch
                                        checkedChildren={t('Обновить площадь ЛС')}
                                        unCheckedChildren={t('Оставить без изменений')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name={'copy_quantity'} label={t('Копировать кол-во человек')}
                                    tooltip={t('Если вы хотите обновить кол-во человек в ЛС, т.е. записать туда данные столбца "Количество человек" из загружаемого файла, то выберите вариант "Обновить"')}
                                    valuePropName={'checked'}
                                >
                                    <Switch
                                        checkedChildren={t('Обновить')}
                                        unCheckedChildren={t('Оставить без изменений')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <UploadComponent
                        title={t('Файл')} isForm formName={'files'} dragger multiple={false}
                    />
                </Col>
            </Row>
        </Form>
    </Modal>
}
