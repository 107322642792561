import {sample} from 'effector';
import {
    $taskLogsDataModal,
    $taskLogsIdModal,
    $taskLogsModal, $taskParametrsModal, $tasksFilters,
    $tasksList
} from './stores.js';
import {
    acceptTaskFromSocketEv, changeTasksFilterEv, LogsIdModalGate, notifyCreationEv,
    resetLogsModalEv,
    resetParametrsModalEv, resetTasksFilterEv,
    setLogsModalEv, setLogsModalIdEv,
    setParametrsModalEv,
    stopTaskEv,
    TasksGate
} from './events.js';
import {
    getTaskLogsFx,
    getTasksListFx,
    stopTaskFx
} from './effects.js';
import queryString from 'query-string';
import {notification} from 'antd';
import NotificationTable from '../../components/NotificationTable/NotificationTable.jsx';
import {$generateTypeSt} from "../taskAllPdfModel/index.js";

$tasksList.on(getTasksListFx.doneData, (_, tasks) => tasks)
    .on(acceptTaskFromSocketEv, (state, update) => {
        const newList = [...state.data]
        const idx = newList.findIndex((item) => item.id === update.id)
        if (idx !== -1) {
            const item = newList[idx]
            for (const key in update) {
                item[key] = update[key]
            }
            newList[idx] = item
            return {data: newList, count: state.count}
        } else return undefined
    })
    .reset(TasksGate.close)

$taskLogsModal.on(setLogsModalEv, (_, payload) => ({open: true, logs: payload}))
    .reset(resetLogsModalEv)


$taskLogsIdModal.on(setLogsModalIdEv, (_, payload) => ({open: true, logs: payload.logs, id: payload.id}))
    .reset(resetLogsModalEv)

$taskLogsDataModal.on(getTaskLogsFx.doneData, (_, payload) => (payload))
    .reset(resetLogsModalEv)

$taskParametrsModal.on(setParametrsModalEv, (_, payload) => ({open: true, args: payload}))
    .reset(resetParametrsModalEv)

$tasksFilters.on(changeTasksFilterEv, (state, payload) => {
    const result = {...state}
    if (Array.isArray(payload)) {
        for (const change of Object.values(payload)) {
            result[change.key] = change.value
        }
    } else if (!Array.isArray(payload)) {
        result[payload.key] = payload.value
    }
    for (const key in result) {
        if (result[key] === undefined || result[key] === null || result[key]?.length === 0) {
            delete result[key]
        }
    }
    return result
})
    .reset(resetTasksFilterEv)

sample({
    clock: stopTaskEv,
    target: stopTaskFx
})

sample({
    source: {gate: TasksGate.state, filters: $tasksFilters},
    clock: [TasksGate.state.updates, $tasksFilters.updates],
    filter: ({gate}) => Object.hasOwn(gate, 'name'),
    fn: ({gate, filters}) => ({name: gate.name, filters: queryString.stringify(filters, {skipNull: true, skipEmptyString: true})}),
    target: getTasksListFx
})

sample({
    clock: LogsIdModalGate.state,
    filter: (gate) => {
        return gate.id === null ?
            false :
            gate.id === undefined ?
                false : true
    },
    fn: (gate) => {
        return gate
    },
    target: getTaskLogsFx
})

notifyCreationEv.watch(response => {
    return notification.success({
        message: response.name === 'print_pdfs' ? 'Формирование PDF по контролерам успешно создано'
            : 'Рассылка по E-mail успешно создана',
        description: <NotificationTable response={response}/>,
        placement: 'topRight',
        style: {width: '600px'}
    })
})
