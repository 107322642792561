import {createStore} from 'effector';


export const $kbkList = createStore({data: [], count: 0})

export const $selectedKbk = createStore(null)

export const $editKbkStatus = createStore(false)

export const $createdKbkId = createStore(null)
export const $afterCreateAction = createStore(null)
