import {useTranslation} from 'react-i18next';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $createInspectorEmbeddedOpen,
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import Card from 'antd/es/card';
import {Select} from "antd";
import {$dictsSelects} from "../../../models/infoModel/index.js";

export default function CreateInspectorEmbeddedForm() {
    const {t} = useTranslation()
    const open = useUnit($createInspectorEmbeddedOpen)


    const chiefInspectorOpts = useStoreMap($dictsSelects, sel => sel.chief_inspector).filter(item => item.value !== ' ').filter(item => item.value !== null)

    return open && <Card title={t('Свойства Контролера')} style={{marginBottom: 16}} size={'small'}>
                    <Form.Item name={['inspector', 'name_ru']}
                               label={t('ФИО контролера')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Input/>
                    </Form.Item>
                    {/*<Form.Item name={['inspector', 'name_kz']}*/}
                    {/*           label={t('ФИО контролера  (каз)')}*/}
                    {/*           rules={[{required: true, message: t('Обязательное поле')}]}*/}
                    {/*>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item name={['inspector', 'chief_inspector_id']}
                               label={t('Старший контролер')}
                               rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Select
                            showSearch
                            defaultValue={null}
                            options={chiefInspectorOpts}
                            filterOption={(input, option) =>
                                (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                            }

                        />
                    </Form.Item>
    </Card>
}
