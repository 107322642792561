import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Divider from 'antd/es/divider';
import {Link} from 'react-router-dom';
import {FormOutlined, ReadOutlined} from '@ant-design/icons';
import Typography from 'antd/es/typography';
import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';

import * as styles from './MenuItemCard.module.css'
import {useMemo} from 'react';
import {useUnit} from 'effector-react';
import {$currentUser} from '../../../models/infoModel/index.js';


export default function MenuItemCard({title, dicts, tasks}) {
    const {t} = useTranslation();
    const curUser = useUnit($currentUser)

    const span = dicts.length > 0 && tasks.length > 0 ? 11 : 24
    const innerSpan = dicts.length > 0 && tasks.length > 0 ? 24 : 12
    const showDivider = !!(dicts.length > 0 && tasks.length > 0)
    const {permDicts, permTasks} = useMemo(() => {
        return {
            permDicts: dicts.filter(d => !d.hidden),
            permTasks: tasks.filter(t => !t.hidden)
        }
    }, [dicts, tasks])

    return <Card className={styles.mainPageCard} title={
        <Typography.Title level={4} style={{margin: '8px 0'}}>{title}</Typography.Title>
    } size={'small'} style={{height: '100%'}} loading={!curUser}>
        <Row justify={'space-between'} gutter={[16, 16]}>
            {permDicts.length > 0 && <Col span={span}>
                <Typography.Title level={5}
                                  style={{marginTop: 0}}>{t('Справочники')}</Typography.Title>
                <Row gutter={[8,8]}>
                    {permDicts.map((d, idx) => <Col span={innerSpan} key={idx}>
                        <ReadOutlined/>
                        <Link to={d.link} style={{marginLeft: '4px'}}>{d.label}</Link>
                    </Col>)}
                </Row>
            </Col>}
            {showDivider && <Divider type={'vertical'} style={{height: 'inherit'}}/>}
            {permTasks.length > 0 && <Col span={span}>
                <Typography.Title level={5} style={{marginTop: 0}}>{t('Задачи')}</Typography.Title>
                <Row gutter={[8,8]}>
                    {permTasks.map((t, idx) => <Col span={innerSpan} key={idx}>
                        <FormOutlined/>
                        <Link to={t.link}>{t.label}</Link>
                    </Col>)}
                </Row>
            </Col>}
        </Row>
    </Card>
}
