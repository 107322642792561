import {
    $afterCreateAction,
    $createdEconomySectorId,
    $editEconomySectorStatus,
    $selectedEconomySector,
    $economySectorList
} from './stores.js';
import {getEconomySectorListFx, getEconomySectorByIdFx} from './effects.js';
import {
    deleteEconomySectorEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    EconomySectorEditGate,
    EconomySectorListGate,
    EconomySectorViewGate,
    submitCreateEconomySectorEv,
    submitEditEconomySectorEv, EconomySectorCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateEconomySectorEv, response: triggerSuccessOpsEv}, reset: EconomySectorCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditEconomySectorEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteEconomySectorEv, response: triggerSuccessOpsEv})

$economySectorList.on(getEconomySectorListFx.doneData, (_, data) => data)
    .reset(EconomySectorListGate.close)

$selectedEconomySector.on(getEconomySectorByIdFx.doneData, (_, data) => data)
    .reset([EconomySectorEditGate.close, EconomySectorViewGate.close])

$createdEconomySectorId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "economy_sector")
    return data ? data?.value?.id : undefined

})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editEconomySectorStatus.on(editCompleteEv, () => true)
    .reset(EconomySectorEditGate.close)

sample({
    clock: [EconomySectorEditGate.state, EconomySectorViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getEconomySectorByIdFx
})

sample({
    clock: EconomySectorListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getEconomySectorListFx
})

sample({
    source: EconomySectorListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getEconomySectorListFx
})

sample({
    clock: submitCreateEconomySectorEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'economy_sector', value: {...payload, id: parseInt(payload.id)}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedEconomySector,
    clock: submitEditEconomySectorEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        const value = {
            ...changedFields,
            id: parseInt(changedFields.id) ? parseInt(changedFields.id) : null,
            object_id:  current.id,
        }

        if (value.id === null) {
            delete value.id
        }
        actions.push({operation: 'update', type: 'economy_sector', value: value})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteEconomySectorEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'economy_sector', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
