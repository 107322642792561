import {combine, createStore} from 'effector';
import {$infoLoaded, $infoMaps} from '../infoModel/index.js';
import {isId} from '../../utils/helpers.js';

export const $accountsList = createStore({data: [], count: 0})
export const $providerServiceList = createStore({data: [], count: 0})
export const $accountsProviderServiceList = createStore({data: [], count: 0})
export const $providerAccountsList = createStore({data: [], count: 0})
export const $accrualsData = createStore({data: [], count: 0})
export const $paymentsData = createStore({data: [], count: 0})
export const $paymentsElementsData = createStore({data: [], count: 0})
export const $cashPaymentsData = createStore({data: [], count: 0})

export const $selectedAccount = createStore(null)
export const $selectedAccountReEsObj = createStore(null)
export const $selectedAccountReEsObjFormatted = combine($selectedAccountReEsObj, $infoMaps, $infoLoaded, (obj, maps, loaded) => {
    if (obj && loaded) {
        return `${isId(obj?.town_id) ? `г. ${maps?.townsMap?.[obj.town_id] ?? ''},` : ''} ${isId(obj?.towd_distr_id) ? `${maps?.districtsMap?.[obj?.towd_distr_id] ?? ''},` : ''} ${isId(obj?.street_id) ? `${maps?.streetsMap?.[obj?.street_id] ?? ''},` : ''} ${obj?.house ? `${obj?.house ?? ''}` : ''} ${obj?.corpus ? `, корпус ${obj?.corpus ?? ''}` : ''}`
    } else return null
})

export const $editAccountStatus = createStore(false)

export const $selectedProvider = createStore(null)
export const $selectedService = createStore(null)
export const $providerService = createStore({data: [], count: 0})

export const $editedExternalNumber = createStore([])

export const $createdAccountId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $accLockedModal = createStore({open: false, id: null, status_reason: null, page: null})


export const $sexEmbedded = createStore(false)
export const $nationEmbedded = createStore(false)
export const $prefCatEmbedded = createStore(false)
export const $consCatEmbedded = createStore(false)

// export const $lastCreatedAccount = createStore(null)
