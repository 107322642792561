import {useTranslation} from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {useUnit} from 'effector-react';
import {
    $filters,
    applyFiltersEv,
    changeFiltersEv, resetFiltersEv
} from '../../../models/taskPdfToControllersModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {DatePicker} from 'antd';

export default function Filters({forDownload}) {
    const {t} = useTranslation()
    const opts = useUnit($dictsSelects)
    const filters = useUnit($filters)
    const changeFilters = useUnit(changeFiltersEv)
    const apply = useUnit(applyFiltersEv)
    const reset = useUnit(resetFiltersEv)


    return <>
        <Row justify={'space-between'} style={{width: '100%', marginTop: -16}}>
        <Col span={4}>
            <p>{t('Код/ФИО контролера')}</p>
            <Input value={filters.code_name} onChange={({target: {value}}) => changeFilters({key: 'code_name', value})}/>
        </Col>
            <Col span={4}>
                <p>{t('Населенный пункт\n')}</p>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                    }
                    options={opts.towns}
                    mode={'multiple'}
                    value={filters.town_id}
                    onChange={(v) => changeFilters({key: 'town_id', value: v})}
                    style={{width: '100%'}}
                />
            </Col>
            <Col span={4}>
            <p>{t('Участок')}</p>
            <Select
                showSearch
                filterOption={(input, option) =>
                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                }
                options={opts.sectors} mode={'multiple'}
                value={filters.area_id}
                onChange={(v) => changeFilters({key: 'sector_id', value: v})}
                style={{width: '100%'}}
            />
        </Col>
        {/*<Col span={4}>*/}
        {/*    <p>{t('Район города')}</p>*/}
        {/*    <Select*/}
        {/*        showSearch*/}
        {/*        filterOption={(input, option) =>*/}
        {/*            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())*/}
        {/*        }*/}
        {/*        options={opts.districts}*/}
        {/*        mode={'multiple'}*/}
        {/*        value={filters.town_distr_id}*/}
        {/*        onChange={(v) => changeFilters({key: 'town_distr_id', value: v})}*/}
        {/*        style={{width: '100%'}}*/}
        {/*    />*/}
        {/*</Col>*/}

            {forDownload && <>
                <Col span={2}>
                    <p>Месяц</p>
                    <DatePicker.MonthPicker value={filters.pdf_month}
                                            onChange={(v) => changeFilters({key: 'pdf_month', value: v})}
                                            style={{width: '100%'}}
                    />
                </Col>
                <Col span={2}>
                    <p>Имеет файлы</p>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                        }
                        value={filters.has_pdf}
                        onChange={(v) => changeFilters({key: 'has_pdf', value: v})}
                        options={[{label: 'Все', value: 'all'}, {label: 'Да', value: 'true'}, {label: 'Нет', value: 'false'}]}
                        style={{width: '100%'}}
                    />
                </Col>
            </>
            }
    </Row>
        <Row justify={'space-between'} style={{width: '100%', alignItems: 'flex-end'}}>
            <Col span={4} style={{marginTop: '15px'}}>
                <ButtonGroup>
                    <Button type={'primary'} onClick={() => apply()}>{t('Применить')}</Button>
                    <Button onClick={() => reset()}>{t('Сбросить')}</Button>
                </ButtonGroup>
            </Col>
        </Row>
    </>
}
