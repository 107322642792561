import {useEffect, useState} from 'react';
import {useUnit} from 'effector-react';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {$userPerms} from '../../models/infoModel/index.js';
import {
    $editAccountStatus,
    $selectedAccount,
    AccountEditGate, getAccountByIdFx, setAccLockedModalEv
} from '../../models/dictionaryAccountsModel/index.js';
import Tabs from 'antd/es/tabs';
import EditAccount from './components/EditAccount.jsx';
import AccountLockedModal from '../../components/AccountLockedModal/AccountLockedModal.jsx';
import './EditTabsStyles.css'
import BlockAcc from './components/BlockAcc.jsx';
import {Spin} from 'antd';

export default function EditAccountContainer() {
    const {t} = useTranslation()
    const {id} = useParams()
    const loading = useUnit(getAccountByIdFx.pending)
    const [search, setSearch] = useSearchParams()
    const [activeTab, setActiveTab] = useState('general')

    useEffect(() => {
        if (search.toString().includes('tab=block')) {
            setActiveTab('block')
            setSearch()
        }
    }, [search])

    const perms = useUnit($userPerms)
    const setLockedModal = useUnit(setAccLockedModalEv)

    const status = useUnit($editAccountStatus)
    const account = useUnit($selectedAccount)

    const accountLocked = account?.status === 'closed'

    useEffect(() => {
        if (accountLocked && !perms?.accounts?.block_unblock) {
            setLockedModal({open: true, id: account?.id, page: 'edit', status_reason: account?.status_reason})
        }
    }, [accountLocked, account]);

    const tabItems = [
        {
            key: 'general',
            label: t('Основная информация'),
            children: <EditAccount disabled={accountLocked}/>
        },
        {
            key: 'block',
            label: t('Статус активности'),
            children: perms?.['accounts']?.['block_unblock'] ? <BlockAcc /> : null
        },
    ]

    return <>
        <AccountLockedModal />
        {accountLocked && <p style={{color: 'orangered'}}><strong>{t('ЛС не активен')}</strong></p>}
        {status === false && <AccountEditGate id={+id}/>}
        <Spin spinning={loading}>
        <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
            destroyInactiveTabPane
            items={tabItems}
            rootClassName={!perms?.['accounts']?.['block_unblock'] ? 'editAccTabs' : ''}
        />
        </Spin>
    </>
}