import {createEvent} from 'effector';
import {createGate} from 'effector-react';

// Ивенты для CRUD таблицы вложенных районов
export const DistrictsChildrenGate = createGate()
export const changeDistrictsChildrenFiltersEv = createEvent()

export const setCreateEditChildDistrictsEmbeddedEv = createEvent()
export const resetCreateEditChildDistrictsEmbeddedEv = createEvent()
export const createChildDistrictsEv = createEvent()
export const editChildDistrictsEv = createEvent()
export const deleteChildDistrictsEv = createEvent()