import {
    $afterCreateAction,
    $createdPermissionId,
    $editPermissionStatus,
    $selectedPermission,
    $permissionList
} from './stores.js';
import {getPermissionListFx, getPermissionByIdFx, createPermissionFx} from './effects.js';
import {
    deletePermissionEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    PermissionEditGate,
    PermissionListGate,
    PermissionViewGate,
    submitCreatePermissionEv,
    submitEditPermissionEv, PermissionCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createPermissionCompleteEv = combineEvents({events: {ev: submitCreatePermissionEv, response: triggerSuccessOpsEv}, reset: PermissionCreateGate.state})
const editPermissionCompleteEv = combineEvents({ev: submitEditPermissionEv, response: triggerSuccessOpsEv})
const deletePermissionCompleteEv = combineEvents({ev: deletePermissionEv, response: triggerSuccessOpsEv})

$permissionList.on(getPermissionListFx.doneData, (_, data) => data)
    .reset(PermissionListGate.close)

$selectedPermission.on(getPermissionByIdFx.doneData, (_, data) => data)
    .reset([PermissionEditGate.close, PermissionViewGate.close])
// FIXME
$createdPermissionId.on(createPermissionCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "permission")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editPermissionStatus.on(editPermissionCompleteEv, () => true)
    .reset(PermissionEditGate.close)

sample({
    clock: [PermissionEditGate.state, PermissionViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getPermissionByIdFx
})

sample({
    clock: PermissionListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPermissionListFx
})

sample({
    source: PermissionListGate.state,
    clock: [createPermissionCompleteEv, editPermissionCompleteEv, deletePermissionCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getPermissionListFx
})

sample({
    clock: submitCreatePermissionEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'permission', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedPermission,
    clock: submitEditPermissionEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'permission', value: {object_id: current.id, ...changedFields}})


        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deletePermissionEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'permission', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
