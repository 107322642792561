import {useTranslation} from 'react-i18next';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Select from 'antd/es/select';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {useUnit} from 'effector-react';
import {
    $filters,
    applyFiltersEv,
    changeFiltersEv, resetFiltersEv
} from '../../../models/taskEmailPerAccountsModel';
import {Switch} from 'antd';

export default function Filters() {
    const {t} = useTranslation()
    const filters = useUnit($filters)
    const changeFilters = useUnit(changeFiltersEv)
    const apply = useUnit(applyFiltersEv)
    const reset = useUnit(resetFiltersEv)



    return <>
        <Row justify={'space-between'} style={{width: '100%', marginTop: -16}}>
            <Col span={4}>
                <p>{t('ЛС / ФИО потребителя')}</p>
                <Input value={filters.code_name}
                       onChange={({target: {value}}) => changeFilters({key: 'code_name', value})}/>
            </Col>
            <Col span={4}>
                <p>{t('Email')}</p>
                <Input value={filters.email}
                       onChange={({target: {value}}) => changeFilters({key: 'email', value})}/>
            </Col>
            <Col span={4}>
                <p>{t('Наличие Email')}</p>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                    }
                    options={[
                        {value: null, label: t('Все')},
                        {value: true, label: t('Да')},
                        {value: false, label: t('Нет')}
                    ]}
                    value={filters.has_email}
                    defaultValue={true}
                    onChange={(v) => changeFilters({key: 'has_email', value: v})}
                    style={{width: '100%'}}/>
            </Col>
            <Col span={4}>
                <p>{t('Рассылка')}</p>
                <Select
                    showSearch
                    filterOption={(input, option) =>
                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                    }
                    options={[
                        {value: null, label: t('Все')},
                        {value: true, label: t('Да')},
                        {value: false, label: t('Нет')}
                    ]}
                    value={filters.is_email_delivery}
                    defaultValue={true}
                    onChange={(v) => changeFilters({key: 'is_email_delivery', value: v})}
                    style={{width: '100%'}}/>
            </Col>
        </Row>
        <Row justify={'space-between'} style={{width: '100%', alignItems: 'flex-end'}}>
            <Col span={4} style={{marginTop: '15px'}}>
                <ButtonGroup>
                    <Button type={'primary'} onClick={() => apply()}>{t('Применить')}</Button>
                    <Button onClick={() => reset()}>{t('Сбросить')}</Button>
                </ButtonGroup>
            </Col>
        </Row>
    </>
}
