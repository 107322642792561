import {
    $afterCreateAction,
    $createdKbkId,
    $editKbkStatus,
    $selectedKbk,
    $kbkList
} from './stores.js';
import {getKbkListFx, getKbkByIdFx} from './effects.js';
import {
    deleteKbkEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    KbkEditGate,
    KbkListGate,
    KbkViewGate,
    submitCreateKbkEv,
    submitEditKbkEv, KbkCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateKbkEv, response: triggerSuccessOpsEv}, reset: KbkCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditKbkEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteKbkEv, response: triggerSuccessOpsEv})

$kbkList.on(getKbkListFx.doneData, (_, data) => data)
    .reset(KbkListGate.close)

$selectedKbk.on(getKbkByIdFx.doneData, (_, data) => data)
    .reset([KbkEditGate.close, KbkViewGate.close])
// FIXME
$createdKbkId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "kbk")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editKbkStatus.on(editCompleteEv, () => true)
    .reset(KbkEditGate.close)

sample({
    clock: [KbkEditGate.state, KbkViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getKbkByIdFx
})

sample({
    clock: KbkListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getKbkListFx
})

sample({
    source: KbkListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getKbkListFx
})

sample({
    clock: submitCreateKbkEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'kbk', value: {...payload, id: parseInt(payload.id)}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedKbk,
    clock: submitEditKbkEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        const value = {
            ...changedFields,
            id: parseInt(changedFields.id) ? parseInt(changedFields.id) : null,
            object_id:  current.id,
        }

        if (value.id === null) {
            delete value.id
        }
        actions.push({operation: 'update', type: 'kbk', value: value})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteKbkEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'kbk', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
