import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {$dictsSelects} from '../../models/infoModel/index.js';
import {isId} from '../../utils/helpers.js';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import ButtonGroup from 'antd/es/button/button-group';
import Button from 'antd/es/button';
import {filterOption} from '../../utils/selectUtils.jsx';
import {
    $addressSearch,
    $createdMissAddresses,
    $selectedAddress, resetFiltersEv,
    selectAddressEv
} from '../../models/accountsREOSearchModel/index.js';
import FoundOrNot from './FoundOrNot.jsx';

const defaultSizes = {
    town: 3,
    town_distr: 4,
    street: 7,
    house: 6,
    corpus: 1,
    actions: 3,
}

const defaultSizesNoButtons = {
    town: 4,
    town_distr: 4,
    street: 8,
    house: 7,
    corpus: 1,
    actions: 0,
}

export default function AddressREOSearchForm(
    {
        vertical,
        single,
        extForm,
        extButtons,
        onlyItems,
        itemsAsRow,
        styles,
        isCard,
        cardExtra,
        cardTitle,
        onSearch,
        isCombined,
        withReo,
        sizes,
        houseNonRequired,
        onSubmitHook,
        initialValues
    }
) {
    const {t} = useTranslation()
    const [addressForm] = Form.useForm()
    const addrSearch = useUnit($addressSearch)

    useEffect(() => {
        if (!isId(addrSearch.town_id)) {
            addressForm.resetFields()
        }
    }, [addrSearch])

    const noButtons = isCombined || extButtons
    const fSizes = {...(noButtons ? defaultSizesNoButtons : defaultSizes), ...(sizes || {})}

    const onValuesChange = (cur, _all) => {
        if (Object.hasOwn(cur, 'town_id')) {
            extForm
            ? extForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus'])
            : addressForm.resetFields(['town_distr_id', 'street_id', 'house_in', 'corpus'])
        }
    }

    const onSearchSingle = useUnit(selectAddressEv)
    const searchedAddress = useUnit($selectedAddress)
    const missingAddresses = useUnit($createdMissAddresses)

    const onSubmit = (values) => {
        console.log('AREOSearch onSubmit', values, single)
        if (single) {
            onSearchSingle(values)
        } else {
            onSearch && onSearch(values)
        }
    }

    if (single && (searchedAddress || searchedAddress === false)) {
        return <FoundOrNot obj={searchedAddress} withReo={withReo} missing={missingAddresses}
                           name={withReo ? 'reo' : 'address'}/>
    } else if (onlyItems) {
        return isCard ?
            <Card title={cardTitle ? cardTitle : single ? t('Объект Недвижимости') : t('Поиск ОН')}
                  size={'small'} extra={cardExtra ?? null}>
                <FormItems single={single}
                           vertical={vertical}
                           form={extForm ?? addressForm}
                           extButtons={extButtons}
                           isCombined={isCombined}
                           onlyItems={onlyItems}
                           styles={styles}
                           asRow={itemsAsRow}
                           sizes={fSizes}
                           houseNonRequired={houseNonRequired}
                           onSubmitHook={onSubmitHook}
                />
            </Card>
            : <FormItems single={single}
                         vertical={vertical}
                         form={extForm ?? addressForm}
                         extButtons={extButtons}
                         isCombined={isCombined}
                         onlyItems={onlyItems}
                         styles={styles}
                         asRow={itemsAsRow}
                         sizes={fSizes}
                         houseNonRequired={houseNonRequired}
                         onSubmitHook={onSubmitHook}
            />
    } else {
        return isCard ?
            <Card title={cardTitle ? cardTitle : single ? t('Объект Недвижимости') : t('Поиск ОН')}
                  size={'small'} extra={cardExtra ?? null}>
                <Form form={extForm ?? addressForm} layout={'vertical'} size={'small'}
                      onFinish={onSubmit} onValuesChange={onValuesChange} initialValues={initialValues ?? {}}>
                    <FormItems single={single}
                               vertical={vertical}
                               form={extForm ?? addressForm}
                               extButtons={extButtons}
                               isCombined={isCombined}
                               asRow={itemsAsRow}
                               sizes={fSizes}
                               houseNonRequired={houseNonRequired}
                               onSubmitHook={onSubmitHook}
                    />
                </Form>
            </Card>
            : <>
                <Form form={extForm ?? addressForm} layout={'vertical'} size={'small'}
                      onFinish={onSubmit} onValuesChange={onValuesChange}>
                    <FormItems single={single}
                               vertical={vertical}
                               form={extForm ?? addressForm}
                               extButtons={extButtons}
                               isCombined={isCombined}
                               asRow={itemsAsRow}
                               sizes={fSizes}
                               houseNonRequired={houseNonRequired}
                               onSubmitHook={onSubmitHook}
                    />
                </Form>
            </>
    }
}

function FormItems(props) {
    const {
        single, vertical, form, isCombined, extButtons, onlyItems, styles, asRow, sizes,
        houseNonRequired, onSubmitHook
    } = props
    const {t} = useTranslation()
    const resetFilters = useUnit(resetFiltersEv)
    const townId = Form.useWatch('town_id', form)
    const districtId = Form.useWatch('town_distr_id', form)
    const streetId = Form.useWatch('street_id', form)
    const houseIn = Form.useWatch('house_in', form)
    const {townSelect, districtSelect, streetSelect} = useStoreMap($dictsSelects, sel => ({
        townSelect: sel.towns,
        districtSelect: sel.districts,
        streetSelect: sel.streets
    }))

    const selOpts = useMemo(() => {
        return {
            distrs: isId(townId) ? districtSelect.filter(i => i.town_id === townId) : [],
            streets: isId(townId) ? streetSelect.filter(i => i.town_id === townId) : [],
        }
    }, [townId])

    // const selOpts = {
    //     distrs: isId(townId) ? districtSelect.filter(i => i.town_id === townId) : [],
    //     streets: isId(townId) ? streetSelect.filter(i => i.town_id === townId) : [],
    // }

    const onCancel = () => {
        form.resetFields()
        resetFilters()
    }

    const searchDisabled = houseNonRequired ?
        !isId(streetId) : (!houseIn || houseIn?.length < 1)

    return ((vertical === true || onlyItems === true) && !asRow)
        ? <>
            <Form.Item name={'town_id'} label={t('Населенный пункт')}>
                <Select options={townSelect}
                        style={styles?.town ?? styles ?? {}}
                        dropdownStyle={{width: 300}}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                />
            </Form.Item>
            <Form.Item name={'street_id'} label={t('Улица')}>
                <Select options={selOpts.streets} disabled={!isId(townId)}
                        style={styles?.street ?? styles ?? {}}
                        dropdownStyle={{width: 300}}
                    // mode={single ? null : 'multiple'}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                />
            </Form.Item>
            {single
                ? <Form.Item name={'house_in'} label={t('Дом')}
                             validateTrigger={'onBlur'}
                             rules={[
                                 {pattern: /^[^\s,-]+$/, message: t('Укажите только один дом')}
                             ]}
                >
                    <Input disabled={!(isId(districtId) || isId(streetId))}
                           style={styles?.house ?? styles ?? {}} allowClear/>
                </Form.Item>
                : <Form.Item name={'house_in'}
                             label={t('Дома')}
                             tooltip={t('Вы можете указать диапазон домов через "-" или перечислить через "," или совместить оба варианта')}
                >
                    <Input disabled={!(isId(districtId) || isId(streetId))}
                           style={styles?.house ?? styles ?? {}} allowClear/>
                </Form.Item>
            }
            <Form.Item name={'corpus'} label={t('Корпус')}>
                <Input disabled={!houseIn || houseIn?.length < 1}
                       style={styles?.corpus ?? styles ?? {}} allowClear/>
            </Form.Item>
            <Form.Item name={'town_distr_id'} label={t('Район')}>
                <Select options={selOpts.distrs} disabled={!isId(townId)}
                        style={styles?.district ?? styles ?? {}}
                        dropdownStyle={{width: 300}}
                    // mode={single ? null : 'multiple'}
                        filterOption={filterOption}
                        showSearch
                        allowClear
                />
            </Form.Item>
            {!isCombined && !extButtons && <Form.Item>
                <ButtonGroup>
                    <Button type={'primary'} onClick={() => form.submit()}
                            disabled={!houseIn || houseIn?.length < 1}>
                        {single ? t('Выбрать') : t('Найти')}
                    </Button>
                    <Button onClick={onCancel}>
                        {t('Сбросить')}
                    </Button>
                </ButtonGroup>
            </Form.Item>}
        </>
        : <Row gutter={[8, 8]}>
            <Col span={sizes.town}>
                <Form.Item name={'town_id'} label={t('Населенный пункт')}>
                    <Select options={townSelect}
                            style={styles?.town ?? styles ?? {}}
                            dropdownStyle={{width: 300}}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                    />
                </Form.Item>
            </Col>
            <Col span={sizes.street}>
                <Form.Item name={'street_id'} label={t('Улица')}>
                    <Select options={selOpts.streets} disabled={!isId(townId)}
                            style={styles?.street ?? styles ?? {}}
                            dropdownStyle={{width: 300}}
                        // mode={single ? null : 'multiple'}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                    />
                </Form.Item>
            </Col>
            {single
                ? <Col span={sizes.house}>
                    <Form.Item name={'house_in'} label={t('Дом')}
                               validateTrigger={'onBlur'}
                               rules={[
                                   {pattern: /^[^\s,-]+$/, message: t('Укажите только один дом')}
                               ]}
                    >
                        <Input disabled={!(isId(districtId) || isId(streetId))}
                               style={styles?.house ?? styles ?? {}} allowClear/>
                    </Form.Item>
                </Col>
                : <Col span={sizes.house}>
                    <Form.Item name={'house_in'}
                               label={t('Дома')}
                               tooltip={t('Вы можете указать диапазон домов через "-" или перечислить через "," или совместить оба варианта')}
                    >
                        <Input disabled={!(isId(districtId) || isId(streetId))}
                               style={styles?.house ?? styles ?? {}} allowClear/>
                    </Form.Item>
                </Col>
            }
            <Col span={sizes.corpus}>
                <Form.Item name={'corpus'} label={t('Корпус')}>
                    <Input disabled={!houseIn || houseIn?.length < 1}
                           styles={styles?.corpus ?? styles ?? {}} allowClear/>
                </Form.Item>
            </Col>
            <Col span={sizes.town_distr}>
                <Form.Item name={'town_distr_id'} label={t('Район')}>
                    <Select options={selOpts.distrs} disabled={!isId(townId)}
                            style={styles?.district ?? styles ?? {}}
                            dropdownStyle={{width: 300}}
                        // mode={single ? null : 'multiple'}
                            filterOption={filterOption}
                            showSearch
                            allowClear
                    />
                </Form.Item>
            </Col>
            {!isCombined && !extButtons &&
                <Col span={sizes.actions} style={{alignSelf: 'flex-end'}}>
                    <Form.Item>
                        <ButtonGroup>
                            <Button type={'primary'} onClick={() => {
                                form.submit()
                                onSubmitHook && onSubmitHook(form)
                            }}
                                    disabled={searchDisabled}>
                                {single ? t('Выбрать') : t('Найти')}
                            </Button>
                            <Button onClick={onCancel}>
                                {t('Сбросить')}
                            </Button>
                        </ButtonGroup>
                    </Form.Item>
                </Col>}
        </Row>
}
