import {createStore} from "effector";

export const $agreementsFileList = createStore({data: [], count: 0})

export const $uploadAgreementsModal = createStore(false)

export const $uploadAgreementsStatus = createStore(false)

export const $selectedAgreementFile = createStore(null)
export const $selectedAgreementFileCells = createStore({data: [], count: 0})

