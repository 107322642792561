import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import {LogoutOutlined, UserOutlined, IdcardOutlined} from '@ant-design/icons';
import Tooltip from 'antd/es/tooltip';
import Space from 'antd/es/space';
import {$currentUser, openPasswordModalEv} from '../../models/infoModel/index.js';
import {logoutEv} from '../../models/authModel/index.js';

export default function ProfileDropdown() {
    const curUser = useUnit($currentUser)
    const onLogout = useUnit(logoutEv)
    const openPasswordModal = useUnit(openPasswordModalEv)

    const {t} = useTranslation()

    const items = [
        {
            key: '/profile',
            label: <Tooltip title={t('Смена пароля')} placement={'left'}>
                <Button  icon={<IdcardOutlined/>} onClick={openPasswordModal}>
                    Смена пароля
                </Button>
            </Tooltip>
        },
        {
            key: '/logout',
            label: <Tooltip title={t('Выход')} placement={'left'}>
                <Button type={'primary'}
                        danger
                        icon={<LogoutOutlined/>}
                        onClick={() => onLogout()}
                >
                Выход
                </Button>
            </Tooltip>
        }
    ]

    return <Space>
        <div style={{minWidth: '150px', textAlign: 'right', textOverflow: 'ellipsis'}}>{localStorage.getItem('lang') === 'kz' ? curUser?.name_kz : curUser?.name_ru ?? ''}</div>
        <Dropdown menu={{items}} placement={'bottom'}>
            <Button icon={<UserOutlined/>}/>
        </Dropdown>
    </Space>
}
