import {createStore} from 'effector';

export const $leftSectors = createStore([])
export const $rightSectors = createStore([])
export const $leftSelected = createStore([])
export const $rightSelected = createStore([])

export const $addressList = createStore({data: [], count: 0})

export const $districtsOptions = createStore([])
export const $streetsOptions = createStore([])
export const $checkTown = createStore(undefined, {skipVoid: false})
export const $selectedAddress = createStore([])
export const $addressFilters = createStore(null)

export const $addressPagination = createStore({
    defaultPageSize: 10,
    defaultCurrent: 1,
    pageSizeOptions: [10, 20, 100],
    showSizeChanger: true,
    position: ['bottomCenter'],
    limit: 10,
    page: 1
})
