import {
    $afterCreateAction,
    $createdCurrencyId,
    $editCurrencyStatus,
    $selectedCurrency,
    $currencyList
} from './stores.js';
import {getCurrencyListFx, getCurrencyByIdFx} from './effects.js';
import {
    deleteCurrencyEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    CurrencyEditGate,
    CurrencyListGate,
    CurrencyViewGate,
    submitCreateCurrencyEv,
    submitEditCurrencyEv, CurrencyCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCurrencyCompleteEv = combineEvents({events: {ev: submitCreateCurrencyEv, response: triggerSuccessOpsEv}, reset: CurrencyCreateGate.state})
const editCurrencyCompleteEv = combineEvents({ev: submitEditCurrencyEv, response: triggerSuccessOpsEv})
const deleteCurrencyCompleteEv = combineEvents({ev: deleteCurrencyEv, response: triggerSuccessOpsEv})

$currencyList.on(getCurrencyListFx.doneData, (_, data) => data)
    .reset(CurrencyListGate.close)

$selectedCurrency.on(getCurrencyByIdFx.doneData, (_, data) => data)
    .reset([CurrencyEditGate.close, CurrencyViewGate.close])

$createdCurrencyId.on(createCurrencyCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "currency")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editCurrencyStatus.on(editCurrencyCompleteEv, () => true)
    .reset(CurrencyEditGate.close)

sample({
    clock: [CurrencyEditGate.state, CurrencyViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getCurrencyByIdFx
})

sample({
    clock: CurrencyListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getCurrencyListFx
})

sample({
    source: CurrencyListGate.state,
    clock: [createCurrencyCompleteEv, editCurrencyCompleteEv, deleteCurrencyCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getCurrencyListFx
})

sample({
    clock: submitCreateCurrencyEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'currency', value: {...payload, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedCurrency,
    clock: submitEditCurrencyEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1


        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        actions.push({operation: 'update', type: 'currency', value: {object_id: current.id, ...changedFields}})


        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteCurrencyEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'currency', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
