import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getRealEstateObjectTypeListFx} from "./effects.js";

export const RealEstateObjectTypeListGate = createGate()
export const RealEstateObjectTypeViewGate = createGate()
export const RealEstateObjectTypeEditGate = createGate()
export const RealEstateObjectTypeCreateGate = createGate()

export const submitCreateRealEstateObjectTypeEv = createEvent()
export const submitEditRealEstateObjectTypeEv = createEvent()
export const deleteRealEstateObjectTypeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
