import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import CreateTownEmbeddedForm from "../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx";
import CreateInspectorEmbeddedForm
    from "../../../components/Embedded/CreateInspectorEmbeddedForm/CreateInspectorEmbeddedForm.jsx";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {
    setCreateInspectorEmbeddedEv,
    setCreateTownEmbeddedEv
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import {$inspectorsByCitySelect, getInspectorsByCityEv} from "../../../models/selectsByParentModel/index.js";
import {
    $createdPasswordSettingsId,
    PasswordSettingsCreateGate,
    submitCreatePasswordSettingsEv, $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
} from "../../../models/dictionaryPasswordSettingsModel/index.js";
import Switch from "antd/es/switch/index.js";

export default function CreatePasswordSettings() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [pwdStgForm] = Form.useForm()

    const submit = useUnit(submitCreatePasswordSettingsEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdPasswordSettingsId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)


    console.log('TEST action', action)
    console.log('TEST createdId', createdId)

    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                navigate(`/dictionaries/password-settings/edit/${createdId}`)
            } else if (action === 'new') {
                pwdStgForm.resetFields()
            } else if (action === 'list') {
                pwdStgForm.resetFields()
                navigate(`/dictionaries/password-settings?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [createdId, action]);

    return <>
        <PasswordSettingsCreateGate />
        <Form form={pwdStgForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              // onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>

                <Col span={6}>
                    <Card title={<StarredTitle title={t('Название')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'name_ru'}
                                   label={t('Название')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input />
                        </Form.Item>
                        <CreateTownEmbeddedForm form={pwdStgForm}/>


                    </Card>
                </Col>
                <Col span={6}>
                    <Card title={<StarredTitle title={t('Параметр')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}
                          rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Form.Item name={'setting'}
                                   label={t('Параметр')}
                        >
                            <Input />
                        </Form.Item>
                        <CreateInspectorEmbeddedForm form={pwdStgForm}/>

                    </Card>

                </Col>


                <Col span={6}>
                    <Card title={t('Значение')} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'value'}
                                   label={t('Значение')}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>

                </Col>

                <Col span={6}>
                    <Card title={t('Статус ')} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'active'}
                                   label={t('Статус')}
                                   valuePropName={'checked'}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Switch value={'checked'}/>
                        </Form.Item>
                    </Card>
                </Col>

            </Row>

        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    pwdStgForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            pwdStgForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            pwdStgForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            pwdStgForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и перейти к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
