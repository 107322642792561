import {
    $afterCreateAction,
    $createdReportsId,
    $editReportsStatus,
    $selectedReports,
    $reportsList
} from './stores.js';
import {getReportsListFx, getReportsByIdFx, createReportFx, downloadReportFx, sendMessageReportsFx} from './effects.js';
import {
    deleteReportsEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ReportsEditGate,
    ReportsListGate,
    ReportsViewGate,
    submitCreateReportsEv,
    submitEditReportsEv, ReportsCreateGate, downloadReportEv, sendMessageUploadEv, acceptMessageUploadEv
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import {resetCreateInspectorEmbeddedEv, resetCreateTownEmbeddedEv} from "../dictionaryEmbeddedFormsModel/index.js";
import queryString from 'query-string';
// import {acceptMessageUploadEv} from "../uploadProviderAccountsModel/index.js";

const createCompleteEv = combineEvents({events: {ev: submitCreateReportsEv, response: triggerSuccessOpsEv}, reset: ReportsCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditReportsEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteReportsEv, response: triggerSuccessOpsEv})

$reportsList.on(getReportsListFx.doneData, (_, data) => data)
    .on(acceptMessageUploadEv, (state, msg) => {
        const newList = [...state.data]
        newList.map((item) => {
            if (item.id === msg.id) {
                for (const [key, value] of Object.entries(msg)) {
                    item[key] = value
                }
            }
            return item
        })
        return {data: newList, count: state.count}
    })
    .reset(ReportsListGate.close)

$selectedReports.on(getReportsByIdFx.doneData, (_, data) => data)
    .reset([ReportsEditGate.close, ReportsViewGate.close])
// FIXME
$createdReportsId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "reports")
    const result = data ? data?.value?.id : undefined
    console.log('created reports id', result)
    return result
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editReportsStatus.on(editCompleteEv, () => true)
    .reset(ReportsEditGate.close)

sample({
    clock: [ReportsEditGate.state, ReportsViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getReportsByIdFx
})

// sample({
//     clock: ReportsListGate.state,
//     // filter: (gate) => Object.hasOwn(gate, 'search'),
//     // fn: (gate) => gate.search,
//     filter: (gate) => {
//         debugger
//         if (gate?.search?.includes('sort_field')) {
//             return Object.hasOwn(gate, 'search')
//         }
//     },
//     fn: (gate) => {
//         return gate.search
//     },
//     target: getReportsListFx
// })



sample({
    source: ReportsListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv, createReportFx.doneData, ReportsListGate.state],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => {
        const defaultParams = {
            limit: '10',
            page: '1',
            sort_field: 'created_at',
            sort_order: 'desc',
        }
        const paramsToSet = {}
        Object.entries(defaultParams).forEach(([key, value]) => {
            if (!gate.search.has(key)) {
                paramsToSet[key] = value
            }
        });
        if (Object.keys(paramsToSet).length) {
            return queryString.stringify({...queryString.parse(gate.search.toString(), {parseNumbers: true, parseBooleans: true}), ...paramsToSet})
        } else {
            return gate.search.toString()
        }
    },
    target: getReportsListFx
})

sample({
    clock: submitCreateReportsEv,
    fn: (payload) => {
        // FIXME
        const data = {
            filters: {
                report_date: payload.subtype === 'days' || payload.type === '668' ? undefined : payload.report_date,
                street_id: payload.street_id ? payload.street_id : undefined,
                house: payload.house_in ? payload.house_in : undefined,
                report_date_from: payload.subtype === 'days' ? payload.report_date_from : undefined,
                report_date_to: payload.subtype === 'days' ? payload.report_date_to : undefined,
                provider_id: payload.all_provider_id === undefined || payload.all_provider_id === false ?
                    payload.provider_id : undefined,
                bank_id: payload.all_bank_id === undefined || payload.all_bank_id === false ?
                    payload.bank_id : undefined,

            },
            format: payload.format,
            subtype: payload.subtype,
            type: payload.type
        }
        return data
    },
    target: createReportFx
})

sample({
    source: $selectedReports,
    clock: submitEditReportsEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1
        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }

        if (payload.inspector_id < 0) {
            const newInspector = {
                ...payload.inspector,
                town_id: payload.town_id
            }
            actions.push({operation: 'create', type: 'inspectors', value: {...newInspector, id: idCounter}})
            delete payload.inspector

        }

        if (changedFields.inspector_id === '-' || changedFields.inspector_id === ' ') {
            changedFields.inspector_id = null
        }

        if (changedFields.inspector) {
            changedFields.inspector_id = idCounter
            delete changedFields.inspector
        }


        actions.push({operation: 'update', type: 'reports', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteReportsEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'reports', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: [ReportsCreateGate.close, ReportsEditGate.close],
    target: [resetCreateTownEmbeddedEv, resetCreateInspectorEmbeddedEv]
})

sample({
    clock: downloadReportEv,
    target: downloadReportFx
})

sample({
    clock: sendMessageUploadEv,
    target: sendMessageReportsFx
})
