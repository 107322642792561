import {createEffect} from 'effector';
import api from '../../api/api.js';
import queryString from 'query-string';

export const uploadFileFx = createEffect()
.use(async (data) => {
    const payload = new FormData()
    data.files.forEach(file => {
        payload.append('files', file?.originFileObj)
    })
    const config = { headers: { 'Content-Type': 'multipart/form-data' } }

    const report_date = data.report_date ? data.report_date.replace('+', '%2B') : null

    const values = `${data.provider_id ?
        `&provider_id=${data.provider_id}` : ''}${data.accrual_file_template_id ?
        `&accrual_file_template_id=${data.accrual_file_template_id}` : ''}${data.copy_square ?
        `&copy_square=${data.copy_square}` : ''}${data.copy_quantity ?
        `&copy_quantity=true` : `&copy_quantity=false`}${report_date ?
        `&report_date=${report_date}` : ''}`


    return await (api().post(
        `/accruals/load_files?${values}`, payload, config
    ))
    // FIXME Backup erc-1031
    // return await (api().post(
    //     `/accruals/load_files?${data.provider_id ? `&provider_id=${data.provider_id}` : ''}
    //     ${report_date ? `&report_date=${report_date}` : ''}`, payload, config
    // ))
})

export const sendMessageUploadFx = createEffect()
    .use(async (data) => {
        data.sendMessage(JSON.stringify(data.payload))
    });

export const getFilesListFx = createEffect()
    .use(async (filters) => {
        const parsed = filters.size > 0 ? queryString.parse(filters.toString(), {parseNumbers: true, parseBooleans: true}) : {}
        for (const [key, value] of Object.entries(parsed)) {
            if (!value || (Array.isArray(value) && value?.length === 0)) {
                delete parsed[key]
            }
        }
        return (await api().get(`/accruals_load_file/${filters.size > 0 ? `?${queryString.stringify(parsed)}` : ''}`)).data
    })

export const getFileInfoFx = createEffect()
    .use(async (id) => (await api().get(`/accruals_load_file/${id}`)).data)

export const getFileCellsFx = createEffect()
    .use(async ({id, filters}) => {
        if (!filters?.get('sort_field')) filters.set('sort_field', 'row_index')
        return (await api().get(`/pre_accruals/?alf_id=${id}${filters.size > 0 ? `&${filters.toString()}` : ''}`)).data
    })

export const acceptFileFx = createEffect()
    .use(async (id) => (await api().get(`/accruals_load_file/confirm_valid/${id}`)).data)

export const rejectFileFx = createEffect()
    .use(async (id) => (await api().get(`/accruals_load_file/cancel_not_valid/${id}`)).data)

export const deleteFileFx = createEffect()
    .use(async (id) => (await api().get(`/accruals_load_file/delete/${id}`)).data)

export const stopUploadFileFx = createEffect()
    .use(async (id) => (await api().post(`/accruals_load_file/stop/${id}`)).data)


export const downloadErrorFileFx = createEffect()
    .use(async (id) => {
        const file = await api().get(`/accruals_load_file/download_errors/${id}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })
