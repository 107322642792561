import {useUnit} from 'effector-react';
import {
    $corpusSelect,
    $districtsSelect, $houseSelect,
    $filters, $streetsSelect,
    $townsSelect, changeFiltersEv
} from '../../../models/taskEPDPerRESModel';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import {useTranslation} from 'react-i18next';
import {DatePicker} from 'antd';
import Card from 'antd/es/card';

export default function REOFilters({forDownload}) {
    const {t} = useTranslation()
    const filters = useUnit($filters)
    const changeFilters = useUnit(changeFiltersEv)

    const townsSelect = useUnit($townsSelect)
    const districtsSelect = useUnit($districtsSelect)
    const streetsSelect = useUnit($streetsSelect)
    const houseSelect = useUnit($houseSelect)
    const corpusSelect = useUnit($corpusSelect)

    return <Card size={'small'}>
        <Row justify={'space-between'} gutter={[16, 16]}>
        <Col span={4}>
            <label>{t('Город')}</label>
            <Select options={townsSelect}
                    showSearch
                    allowClear
                    style={{width: '100%'}}
                    onChange={(value) => changeFilters({key: 'town_id', value: value})}
                    value={filters.town_id}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
        </Col>
        <Col span={4}>
            <label>{t('Район')}</label>
            <Select options={districtsSelect}
                    showSearch
                    allowClear
                    style={{width: '100%'}}
                    disabled={filters.town_id === null}
                    onChange={(value) => changeFilters({key: 'town_distr_id', value: value})}
                    value={filters.town_distr_id}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
        </Col>
        <Col span={4}>
            <label>{t('Улица')}</label>
            <Select options={streetsSelect}
                    showSearch
                    allowClear
                    style={{width: '100%'}}
                    disabled={filters.town_id === null}
                    onChange={(value) => changeFilters({key: 'street_id', value: value})}
                    value={filters.street_id}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
        </Col>
        <Col span={4}>
            <label>{t('Дом')}</label>
            <Select options={houseSelect}
                    showSearch
                    allowClear
                    style={{width: '100%'}}
                    disabled={!filters.street_id}
                    onChange={(value) => changeFilters({key: 'house', value: value})}
                    value={filters.house}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
        </Col>
        <Col span={4}>
            <label>{t('Корпус')}</label>
            <Select options={corpusSelect}
                    showSearch
                    allowClear
                    style={{width: '100%'}}
                    disabled={!filters.house}
                    onChange={(value) => changeFilters({key: 'corpus', value: value})}
                    value={filters.corpus}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
        </Col>
    </Row>
        {forDownload && <Row justify={'end'} gutter={[16, 16]}>
            <Col span={4}>
                <p>Месяц</p>
                <DatePicker.MonthPicker value={filters.pdf_month}
                                        onChange={(v) => changeFilters({key: 'pdf_month', value: v})}
                                        style={{width: '100%'}}
                />
            </Col>
            <Col span={4}>
                <p>Имеет файлы</p>
                <Select
                    value={filters.has_pdf}
                    onChange={(v) => changeFilters({key: 'has_pdf', value: v})}
                    options={[{label: 'Все', value: 'all'}, {label: 'Да', value: 'true'}, {label: 'Нет', value: 'false'}]}
                    style={{width: '100%'}}
                />
            </Col>
        </Row>
        }
    </Card>
}
