import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Col from 'antd/es/col'
import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {useStoreMap} from "effector-react";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import DatePicker from "antd/es/date-picker";
import Switch from "antd/es/switch";

export default function ReportComponentMassProviderAndDate({reportForm}) {
    const {t} = useTranslation()
    const providerOpt = useStoreMap($dictsSelects, sel => sel.providers)

    const allProvider = Form.useWatch('all_provider_id', reportForm)
    console.log('allProvider', allProvider)
    return <>
        <Col span={3}>
            <Form.Item name={'all_provider_id'}
                       label={t('Все поставщики')}
                       rules={[{required: false, message: t('Обязательное поле')}]}
            >
                <Switch />
            </Form.Item>
        </Col>
        <Col span={6}>
            <Form.Item name={'provider_id'}
                       label={t('Поставщик')}
                       rules={[{required: allProvider === true ? false : true, message: t('Обязательное поле')}]}
            >
                <Select
                    options={providerOpt}
                    showSearch
                    mode="multiple"
                    disabled={allProvider}
                    filterOption={(input, option) =>
                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                    }
                />
            </Form.Item>
        </Col>
        <Col span={4}>
            <Form.Item name={'report_date'}
                       label={t('Дата')}
                       rules={[{required: false, message: t('Обязательное поле')}]}
            >
                <DatePicker.MonthPicker
                    style={{width: '100%'}}
                    defaultValue={dayjs().subtract(1, 'month')}
                />
            </Form.Item>
        </Col>
    </>
}
