import {createStore} from "effector";

export const $calcTypeAlgorithmsData = createStore({data: [], count: 0})

export const $selectedAlgorithm = createStore(null)

export const $editAlgorithmStatus = createStore(false)

export const $createdCalcTypeAlgorithmId = createStore(null)
export const $afterCreateAction = createStore(null)
