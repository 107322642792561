import {combine, createStore} from 'effector';
import dayjs from 'dayjs';

export const $filters = createStore({recreate_pdf: false, has_pdf: 'all', pdf_month: dayjs()})

export const $controllersList = createStore({data: [], count: 0})

export const $selectedControllers = createStore([])
export const $createdTask = createStore([])

export const $taskCreated = createStore(false)

export const $contollersAll = createStore([])
export const $controllersMap = combine($contollersAll, cntrs => {
    const lang = localStorage.getItem('lang') ?? 'ru'
    return Object.fromEntries(cntrs.map(i => [i.id, `${i.id} ${lang === 'kz' && i?.name_kz ? i?.name_kz : i?.name_ru ?? ''}`]))
})

export const $uploadFileModal = createStore(false)
export const $uploadStatus = createStore(false)
export const $selectedByIdFile = createStore(null)

export const $uploadedFilesData = createStore({data: [], count: 0})

