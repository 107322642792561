import {sample} from 'effector';
import {
    $corpusSelect,
    $districtsSelect,
    $filters, $houseSelect, $reoList, $selectedReo, $streetsSelect, $taskCreatedStatus, $townsSelect
} from './stores.js';
import {
    changeFiltersEv, changeSelectedREOEv,
    createTaskEv, downloadFilesPerREOEv, downloadSingleREOFileEv, REOFilesGate,
    resetTaskCreatedEv, TaskEPDPerREOGate
} from './events.js';
import {
    createTaskFx,
    downloadFilesPerREOFx,
    downloadSingleREOFileFx, getDistrictsSelectFx,
    getREOListFx,
    getSelectsForREOFx,
    getTownsSelectFx,
} from './effects.js';
import queryString from 'query-string';
import {notifyCreationEv} from '../tasksGlobalModel/index.js';
import dayjs from 'dayjs';
import {notification} from 'antd';
import i18n from '../../i18n.js';

const lang = localStorage.getItem('lang') ?? 'ru'

$reoList.on(getREOListFx.doneData, (_, controllers) => controllers)
    .reset([TaskEPDPerREOGate.close, REOFilesGate.close])

$filters.on(changeFiltersEv, (state, {key, value}) => ({...state, [key]: value}))
    .reset([TaskEPDPerREOGate.close, REOFilesGate.close])

$selectedReo.on(changeSelectedREOEv, (state, v) => v)

$taskCreatedStatus.on(createTaskFx.doneData, () => true)
    .reset(resetTaskCreatedEv)

$townsSelect.reset([TaskEPDPerREOGate.close, REOFilesGate.close])
$districtsSelect.reset([TaskEPDPerREOGate.close, REOFilesGate.close])
$streetsSelect.reset([TaskEPDPerREOGate.close, REOFilesGate.close])
$houseSelect.reset([TaskEPDPerREOGate.close, REOFilesGate.close])
$corpusSelect.reset([TaskEPDPerREOGate.close, REOFilesGate.close])

sample({
    clock: [TaskEPDPerREOGate.open, REOFilesGate.open],
    target: getTownsSelectFx
})

sample({
    clock: $filters.updates,
    filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && !selected.town_distr_id && !selected.street_id && !selected.house && !selected.corpus,
    fn: (selected) => selected.town_id,
    target: getDistrictsSelectFx
})

sample({
    clock: $filters.updates,
    filter: (selected) => (selected.town_id !== null && selected.town_id !== undefined),
    fn: (clock) => {
        const filtersCheck = {...clock}
        for (const key in filtersCheck) {
            if (filtersCheck[key] === null || filtersCheck[key] === undefined || key === 'recreate_pdf' || key === 'has_pdf' || key === 'report_date') {
                delete filtersCheck[key]
            }
        }
        return queryString.stringify(filtersCheck, {skipEmptyString: true, skipNull: true})
    },
    target: getSelectsForREOFx
})

sample({
    source: $filters,
    clock: getTownsSelectFx.doneData,
    fn: (_, clock) => clock.data.map(t => ({label: `${t.id} ${t[`name_${lang}`]}`, value: t.id})),
    target: $townsSelect
})

sample({
    source: $filters,
    clock: getDistrictsSelectFx.doneData,
    fn: (_, clock) => clock.data.map(t => ({label: `${t.id} ${t[`name_${lang}`]}`, value: t.id})),
    target: $districtsSelect
})

sample({
    source: $filters,
    clock: getSelectsForREOFx.doneData,
    filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && !selected.street_id && !selected.house && !selected.corpus,
    fn: (_, clock) => clock.filter(i => Object.keys(i).includes('street')).map(i => ({label: `${i.id} ${i.street}`, value: i.id})),
    target: $streetsSelect
})

sample({
    source: $filters,
    clock: getSelectsForREOFx.doneData,
    filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && (!!selected.town_distr_id || !!selected.street_id) && !selected.house && !selected.corpus,
    fn: (_, clock) => clock.filter(i => Object.keys(i).includes('house')).map(i => ({label: i.house, value: i.house})),
    target: $houseSelect
})

sample({
    source: $filters,
    clock: getSelectsForREOFx.doneData,
    filter: (selected) => selected.town_id !== null && selected.town_id !== undefined && (!!selected.town_distr_id || !!selected.street_id) && !!selected.house && !selected.corpus,
    fn: (_, clock) => clock.filter(i => Object.keys(i).includes('corpus')).map(i => ({label: i.corpus, value: i.corpus})),
    target: $corpusSelect
})


sample({
    source: {gate: TaskEPDPerREOGate.status, filters: $filters},
    clock: $filters.updates,
    filter: ({gate, filters}) => !!(gate && filters.town_id !== null && filters.town_id !== undefined && !!(filters.town_distr_id || filters.street_id)),
    fn: ({filters}) => {
        const filtersCheck = {...filters, ignore_limit: true}
        for (const key in filtersCheck) {
            if (filtersCheck[key] === null || !filtersCheck[key] === undefined || key === 'recreate_pdf' || key === 'has_pdf' || key === 'report_date') {
                delete filtersCheck[key]
            }
            if (key.includes('id')) {
                const newKey = key.split('_')[0]
                filtersCheck[newKey] = filtersCheck[key]
                delete filtersCheck[key]
            }
            if (key === 'house') {
                filtersCheck['house_from'] = filtersCheck[key]
                filtersCheck['house_to'] = filtersCheck[key]
                delete filtersCheck[key]
            }
            if (key === 'corpus') {
                filtersCheck['corpus_from'] = filtersCheck[key]
                filtersCheck['corpus_to'] = filtersCheck[key]
                delete filtersCheck[key]
            }
        }
        return queryString.stringify(filtersCheck, {skipEmptyString: true, skipNull: true})
    },
    target: getREOListFx
})


sample({
    source: {selected: $selectedReo, filters: $filters},
    clock: changeSelectedREOEv,
    filter: ({selected, filters}) => {
        if (filters.select_all === true && selected.length > 0) {
            return true
        }
    },
    fn: ({selected, filters}) => {
        return {
            ...filters,
            select_all : false
        }
    },
    target: $filters
})

sample({
    source: {selected: $selectedReo, filters: $filters},
    clock: createTaskEv,
    fn: ({selected, filters}) => {
        const checked = {...filters, ignore_limit: true}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf' || key === 'has_pdf' || key === 'report_date') {
                delete checked[key]
            }
            if (key.includes('id')) {
                const newKey = key.split('_')[0]
                checked[newKey] = checked[key]
                delete checked[key]
            }
            if (key === 'house') {
                checked['house_from'] = checked[key]
                checked['house_to'] = checked[key]
                delete checked[key]
            }
            if (key === 'corpus') {
                checked['corpus_from'] = checked[key]
                checked['corpus_to'] = checked[key]
                delete checked[key]
            }
        }
        const payload = {
            reo_ids: selected,
            filters: checked,
            report_date: dayjs(new Date()).format(),
            recreate_pdf: filters.recreate_pdf ?? false
        }
        if (payload.reo_ids.length > 0) {
            delete payload.filters
        } else {
            delete payload.reo_ids
        }
        return payload
    },
    target: createTaskFx
})

sample({
    source: {gate: REOFilesGate.status, filters: $filters},
    clock: $filters.updates,
    filter: ({gate, filters}) => !!(gate && filters.town_id !== null && filters.town_id !== undefined && !!(filters.town_distr_id || filters.street_id)),
    fn: ({filters}) => {
        const checked = {...filters, ignore_limit: true}
        for (const [key, value] of Object.entries(checked)) {
            if (value === null || value === '' || key === 'recreate_pdf') {
                delete checked[key]
            }
            if (key === 'report_date') {
                checked[key] = dayjs(checked[key]).format()
            }
            if (key.includes('id')) {
                const newKey = key.split('_')[0]
                checked[newKey] = checked[key]
                delete checked[key]
            }
            if (key === 'house') {
                checked['house_from'] = checked[key]
                checked['house_to'] = checked[key]
                delete checked[key]
            }
            if (key === 'corpus') {
                checked['corpus_from'] = checked[key]
                checked['corpus_to'] = checked[key]
                delete checked[key]
            }
        }

        return queryString.stringify(checked, {skipEmptyString: true, skipNull: true})
    },
    target: getREOListFx
})

sample({
    clock: downloadFilesPerREOEv,
    target: downloadFilesPerREOFx
})

sample({
    clock: downloadSingleREOFileEv,
    target: downloadSingleREOFileFx
})

sample({
    clock: createTaskFx.doneData,
    target: notifyCreationEv
})

downloadFilesPerREOFx.failData.watch((err) => {
    if (err.response.status === 404) {
        notification.error({
            message: i18n.t('Ошибка'),
            description: i18n.t('Файлы по данным параметрам не найдены')
        })
    }
})

downloadSingleREOFileFx.failData.watch((err) => {
    if (err.response.status === 404) {
        notification.error({
            message: i18n.t('Ошибка'),
            description: i18n.t('Файл не найден')
        })
    }
})
