import {createEffect} from "effector";
import api from "../../api/api.js";

export const getPaymentsDataFx = createEffect()
	.use(async (filters) => (await api().get(`/payments/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getPaymentsElementsFx = createEffect()
	.use(async () => {
		return (await api().get(`/payment_elements/`)).data
	})
