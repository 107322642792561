import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getPositionListFx = createEffect()
    .use(async (filters) => (await api().get(`/position/${filters.length > 0 ? `?${filters}` : ''}`)).data)

export const getPositionByIdFx = createEffect()
    .use(async (id) => (await api().get(`/position/${id}`)).data)

export const createPositionFx = createEffect()
    .use(async (data) => (await api().post(`/position/`, data)))
