import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const BankApiAddressListGate = createGate()
export const BankApiAddressViewGate = createGate()
export const BankApiAddressEditGate = createGate()
export const BankApiAddressCreateGate = createGate()

export const submitCreateBankApiAddressEv = createEvent()
export const submitEditBankApiAddressEv = createEvent()
export const deleteBankApiAddressEv = createEvent()
export const changeIpAddressFiltersEv = createEvent()


export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
