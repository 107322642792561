import {useStoreMap, useUnit} from 'effector-react';
import Modal from 'antd/es/modal';
import {
    $taskParametrsModal,
    resetParametrsModalEv
} from '../../models/tasksGlobalModel/index.js';
import {useTranslation} from 'react-i18next';
import Table from "antd/es/table/index.js";
import {$dictsSelects, $infoMaps, $localDict} from "../../models/infoModel/index.js";
import CustomDates from "../CustomDates/CustomDates.jsx";
import Descriptions from "antd/es/descriptions";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import MappedDictItem from "../MappedDictItem/MappedDictItem.jsx";

const FiltersTable = (props) => {
    const {data} = props
    const {town_id, town_distr_id, area_id} = useStoreMap($infoMaps, maps => ({
        town_id: maps.townsMap,
        town_distr_id: maps.districtsMap,
        area_id: maps.sectorsMap
    }))

    const localDict = useUnit($localDict)

    const dict = {
        town_id: town_id,
        town_distr_id: town_distr_id,
        area_id: area_id
    }

    const dataA = Object.entries(data)


    const items = dataA?.map((item, index) => {
        return {
            key: index,
            label: localDict[item[0]],
            children: Array.isArray(item[1]) ?
                item[1]?.map(el => dict[item[0]][el] + ', ')
                :
                item[1]?.toString(),
        }
    })


    return items.length > 0 ? <>
        <Descriptions
            bordered
            size={'small'}
            column={2}
            items={items}
        />
    </> : <>-</>
}

export default function ShowParametrsModal() {
    const {t} = useTranslation()
    const {open, args} = useUnit($taskParametrsModal);
    const close = useUnit(resetParametrsModalEv)
    const argsA = Object.entries(args)
    const {inspectorsMap} = useStoreMap($infoMaps, maps => ({inspectorsMap: maps.inspectorsMap}))

    const data = argsA.map((item, index, arr) => {
        if (item[0] === 'inspector_ids' || item[0] === 'account_ids') {
            if (item[1].length === 0) {
                return false
            } else {
                return {
                    name: item[0],
                    value: item[1]

                }
            }
        } else if (item[0] === "filters") {
            if (arr?.find(item => item[0] === 'inspector_ids' || item[0] === 'account_ids')[1]?.length > 0) {
                return false
            } else {
                return {
                    name: item[0],
                    value: item[1]

                }
            }
        } else {
            return {
                name: item[0],
                value: item[1]
            }
        }

    }).filter(el => el !== false)

    const localDict = useUnit($localDict)


    const columns = [
        {
            title: 'Названине',
            dataIndex: 'name',
            key: 'name',
            render: (value, row) => {
                return localDict[value] ? localDict[value] : value
            }
        },
        {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            render: (value, row) => {
                if (Array.isArray(value)) {
                    if (row.name === 'inspector_ids') {
                        return value.length > 0 ?
                            value.map(item => <MappedDictItem id={parseInt(item)} type={'inspector'}/>) : '-'
                    } else if (row.name === 'sector_ids') {
                        return value.length > 0 ?
                            value.map(item => <MappedDictItem id={parseInt(item)} type={'sector'}/>) : '-'
                    } else if (row.name === 'reo_ids') {
                        return value.length > 0 ?
                            value.map(item => <MappedDictItem id={parseInt(item)} type={'real_estate_object'}/>) : '-'
                    } else if (
                        row.name === 'recreate_pdf' ||
                        row.name === 'send_to_bank' ||
                        row.name === 'include_payments' ||
                        row.name === 'include_personal_data' ||
                        row.name === 'include_taxes' ||
                        row.name === 'past_period'
                    ) {
                        return value === true ?
                            <span>Да</span> :
                            <span>Нет</span>
                    } else {
                        return value.join(', ')
                    }
                } else {
                    if (row.name === 'filters') {
                        return <FiltersTable data={row.value}/>
                    }
                    if (
                        row.name === 'recreate_pdf' ||
                        row.name === 'send_to_bank' ||
                        row.name === 'include_payments' ||
                        row.name === 'include_personal_data' ||
                        row.name === 'include_taxes' ||
                        row.name === 'past_period'
                    ) {
                        return value === true ?
                            <span>Да</span> :
                            <span>Нет</span>
                    }
                    if (row.name === 'generate_type') {
                        return value === 'sector' ?
                            'По участкам' : value === 'inspector' ?
                            'По контролерам' : value === 'reo' ?
                            'По ОН' : value === 'account' ?
                            'По ЛС' : value === 'all' ?
                            'Массовый' : value
                    }
                    if (row.name === 'report_date') {
                        return <CustomDates data={value}/>
                    } else {
                        return value
                    }
                }
            }

        },
    ]

    const newData = data.map(item => {
        if (item.name === 'filters') {
            delete item.value.show_list_option
            return {
                ...item,
            }
        }
        return item
    }).filter(item => item.name !== 'recreate_pdf')

    return <Modal title={t('Параметры исполнения')} open={open} width={'60%'} footer={null} onCancel={() => close()}>
        <div style={{width: '100%', height: '90%', overflow: 'auto'}}>
            <Table
                dataSource={newData}
                columns={columns}
                size={'small'}
                bordered
                rowClassName={'table-row'}
                className={'table-container'}
            />
        </div>
    </Modal>
}
