import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';
import {
    $createdTownIdModal,
    $editDistrictStatus, $selectedDistrict,
    DistrictEditGate, getDistrictByIdFx,
    submitEditDistrictEv
} from '../../../models/dictionaryDistrictsModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import {setCreateTownEmbeddedEv} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import CreateTownEmbeddedForm
    from '../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {PlusOutlined} from '@ant-design/icons';
import StarredTitle from '../../../components/StarredTitle/StarredTitle.jsx';
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryDistrictsModel/index.js";

export default function EditDistrict() {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {id} = useParams()

    const selectedDist = useUnit($selectedDistrict)
    const loading = useUnit(getDistrictByIdFx.pending)
    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const [districtForm] = Form.useForm()

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const submit = useUnit(submitEditDistrictEv)
    const status = useUnit($editDistrictStatus)
    const genitive_form = t(`breadcrumbs.genitive.town_distr`)

    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/town_distr?code_name=${selectedDist.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selectedDist) {
            districtForm.setFieldsValue(selectedDist)
        }
    }, [selectedDist]);

    const townsOpts = useStoreMap($dictsSelects, sel => sel.towns)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)

    const newTown = useUnit($createdTownIdModal)

    const [check, changedFields] = useFormChanged(selectedDist, ['name_kz', 'name_ru', 'town_id'])

    useEffect(() => {
        if (newTown) {
            districtForm.setFieldValue('town_id', newTown)
        }
    }, [newTown])


    return <>
        <DistrictEditGate id={id}/>
        <Form form={districtForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Населенный пункт')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'town_id'}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['town_id']}
                        >
                            <Select
                                options={[...townsOpts, {label: 'Создать', value: -1, emoji: true}]}
                                onChange={(v) => {
                                    if (v === -1) {
                                        setTownEmbedded(true)
                                    } else {
                                        districtForm.resetFields(['town'])
                                        setTownEmbedded(false)
                                    }
                                }}
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                }
                                optionRender={(option) => (
                                    <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                        {
                                            option?.data?.emoji ?
                                                <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                <span>
                                                    {option?.data.label}
                                                </span>
                                        }
                                    </Space>
                                )}
                            />
                        </Form.Item>
                        <CreateTownEmbeddedForm form={districtForm}/>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Свойства района города')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    districtForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            setAction('edit')
                            districtForm.submit()
                        }
                }
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            setAction('list')
                            districtForm.submit()
                        }
                        }
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
