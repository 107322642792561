import {useEffect, useMemo} from 'react';
import {useUnit} from 'effector-react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {$WsSt, sendMsgEv} from '../../models/webSocketModel/index.js';
import {$currentUser, $userPerms} from '../../models/infoModel/index.js';
import {useWebSocket} from '../../api/WebSocketProvider.jsx';
import Menu from 'antd/es/menu';
import HomeTwoTone from "@ant-design/icons/es/icons/HomeTwoTone";
import permitted from '../../utils/permitted.js';

export default function NavMenu() {
    const {t} = useTranslation()
    const {pathname} = useLocation()

    const curUser = useUnit($currentUser)
    const perms = useUnit($userPerms)

    const {sendMessage} = useWebSocket()
    const wsStatus = useUnit($WsSt)
    const sub = useUnit(sendMsgEv)

    useEffect(() => {
        const payloadCloseMonth = {
            sendMessage: sendMessage,
            payload: {
                object_type: "close_month",
                subscribe: true
            }
        }

        const payloadAccrualCalc = {
            sendMessage: sendMessage,
            payload: {
                object_type: "accrual_calculation",
                subscribe: true
            }
        }

        sub(payloadCloseMonth)
        sub(payloadAccrualCalc)
    }, [wsStatus]);

    const items = curUser ? [
        {
            key: '/',
            label: <Link to={'/'}><HomeTwoTone/></Link>
        },
        {
            key: '/accounts_and_real_estate_main',
            label: t('ЛС и ОН'),
            hidden: !(
                perms?.accounts || perms?.real_estate_objects ||
                perms?.inspector || perms?.sector ||
                perms?.sector_inspector || perms?.sector_reo ||
                perms?.import_file?.import_accounts || perms?.import_file?.import_personal_data ||
                perms?.import_file?.import_reo
            ),
            children: [
                {
                    key: '/dictionaries/accounts',
                    hidden: !perms?.accounts?.list,
                    label: <Link to={'/dictionaries/accounts'}>Лицевые счета</Link>
                },
                {
                    key: '/upload-accounts',
                    hidden: !permitted(['import_file::import_accounts'], perms),
                    label: <Link to={'/upload-accounts'}>Импорт ЛС</Link>
                },
                {
                    key: '/upload-update-accounts',
                    hidden: !permitted(['import_file::import_personal_data'], perms),
                    label: <Link to={'/upload-update-accounts'}>Импорт Обновлений ЛС</Link>
                },
                // {
                //     key: '/dictionaries/consumer-category',
                //     label: <Link to={'/dictionaries/consumer-category'}>{t('Категории потребителей')}</Link>
                // },
                {type: 'divider'},
                {
                    key: '/dictionaries/real-estate-objects',
                    label: <Link to={'/dictionaries/real-estate-objects'}>{t('Объекты недвижимости')}</Link>,
                    hidden: !perms?.real_estate_objects?.list
                },
                {
                    key: '/upload-real-estate-objects',
                    label: <Link to={'/upload-real-estate-objects'}>{t('Импорт ОН')}</Link>,
                    hidden: !permitted(['import_file::import_reo'], perms)
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/chief-inspector',
                    label: <Link to={'/dictionaries/chief-inspector'}>{t('Старшие Контролеры')}</Link>,
                    hidden: !perms?.inspector?.list
                },
                {
                    key: '/dictionaries/inspectors',
                    label: <Link to={'/dictionaries/inspectors'}>{t('Контролеры')}</Link>,
                    hidden: !perms?.inspector?.list
                },
                {
                    key: '/dictionaries/sector',
                    label: <Link to={'/dictionaries/sector'}>{t('Участки')}</Link>,
                    hidden: !perms?.sector?.list
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/swap-sector',
                    label: <Link to={'/dictionaries/swap-sector'}>{t('Назначение контролера на участки')}</Link>,
                    hidden: !permitted(['sector_inspector::update'], perms)
                },
                {
                    key: '/dictionaries/swap-real-estate-object',
                    label: <Link to={'/dictionaries/swap-real-estate-object'}>{t('Назначение ОН на участок')}</Link>,
                    hidden: !perms?.sector_reo?.update
                },
            ],
        },
        {
            key: '/providers_and_services_main',
            label: t('Поставщики и услуги'),
            hidden: !(
                perms?.provider?.list || perms?.service?.list ||
                perms?.agreement?.create || perms?.account_provider?.create ||
                perms?.preference_category?.list || perms?.calc_type?.list ||
                perms?.service_payment_type?.list || perms?.organization_type?.list ||
                perms?.service_class?.list || perms?.service_unit?.list || perms?.algorithm?.list ||
                perms?.import_file?.import_account_provider || perms?.import_file?.import_external_number
            ),
            children: [
                {
                    key: '/dictionaries/providers',
                    label: <Link to={'/dictionaries/providers'}>{t('Поставщики')}</Link>,
                    hidden: !perms?.provider?.list
                },
                {
                    key: '/upload-agreements',
                    label: <Link to={'/upload-agreements'}>{t('Загрузка № договоров с потребителями')}</Link>,
                    hidden: !permitted(['import_file::import_external_number'], perms)
                },
                {
                    key: '/upload-provider-accounts',
                    label: <Link to={'/upload-provider-accounts'}>{t('Загрузка файла связи Поставщик + ЛС\n')}</Link>,
                    hidden: !permitted(['import_file::import_account_provider'], perms)
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/preference-category',
                    label: <Link to={'/dictionaries/preference-category'}>{t('Категории льгот')}</Link>,
                    hidden: !perms?.preference_category?.list
                },
                {
                    key: '/dictionaries/calc-type',
                    label: <Link to={'/dictionaries/calc-type'}>{t('Типы расчетов')}</Link>,
                    hidden: !perms?.calc_type?.list
                },
                {
                    key: '/dictionaries/service-payment-type',
                    label: <Link to={'/dictionaries/service-payment-type'}>{t('Виды платежей')}</Link>,
                    hidden: !perms?.service_payment_type?.list
                },
                {
                    key: '/dictionaries/organization_type',
                    label: <Link to={'/dictionaries/organization_type'}>{t('Категории организаций')}</Link>,
                    hidden: !perms?.organization_type?.list
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/services',
                    label: <Link to={'/dictionaries/services'}>{t('Услуги')}</Link>,
                    hidden: !perms?.service?.list
                },
                {
                    key: '/dictionaries/service-classes',
                    label: <Link to={'/dictionaries/service-classes'}>{t('Классы услуг')}</Link>,
                    hidden: !perms?.service_class?.list
                },
                {
                    key: '/dictionaries/service-units',
                    label: <Link to={'/dictionaries/service-units'}>{t('Единицы измерений услуг')}</Link>,
                    hidden: !perms?.service_unit?.list
                },
                {
                    key: '/dictionaries/algorithm',
                    label: <Link to={'/dictionaries/algorithm'}>{t('Алгоритмы расчетов')}</Link>,
                    hidden: !perms?.algorithm?.list
                }
            ],
        },
        {
            key: '/corr_main',
            label: t('Корректировки'),
            hidden: !perms?.corrections?.list,
            children: [
                {
                    key: '/corrections',
                    label: <Link to={'/corrections'}>{t('Выбор Корректировки')}</Link>,
                    hidden: !perms?.corrections?.list
                },
                // {
                //     key: '/upload-correction',
                //     label: <Link to={'/upload-correction'}>{t('Загрузка файла корректировок')}</Link>
                // },
            ],
        },
        {
            key: '/accruals_and_payments_main',
            label: t('Начисления и платежи'),
            hidden: !(
                perms?.accrual || perms?.accrual_file_template?.list ||
                perms?.closed_month?.view || perms?.payment?.list || perms?.provider_payment ||
                perms?.import_file?.import_accruals || perms?.payment_distribution?.list ||
                perms?.import_file?.import_provider_payments
            ),
            children: [
                {
                    key: '/upload',
                    label: <Link to={'/upload'}>{t('Загрузка файлов начислений')}</Link>,
                    // hidden: !perms?.accruals_load_file?.create,
                    hidden: !permitted(['import_file::import_accruals'], perms)
                },
                {
                    key: '/dictionaries/template-accruals',
                    label: <Link to={'/dictionaries/template-accruals'}>{t('Шаблоны файлов начислений')}</Link>,
                    hidden: !perms?.accrual_file_template?.list
                },
                {
                    key: '/close-month',
                    label: <Link to={'/close-month'}>{t('Переход месяца')}</Link>,
                    hidden: !perms?.closed_month?.view
                },
                {type: 'divider'},
                {
                    key: '/accruals',
                    label: <Link to={'/accruals'}>{t('Начисления')}</Link>,
                    hidden: !perms?.accrual?.list
                },

                {
                    key: '/payment',
                    label: <Link to={'/payment'}>{t('Распределение платежей')}</Link>,
                    hidden: !perms?.payment_distribution?.list
                },
                {
                    key: '/upload-cash-payments',
                    label: <Link to={'/upload-cash-payments'}>{t('Загрузка кассового приема платежей')}</Link>,
                    hidden: !permitted(['import_file::import_provider_payments'], perms)
                }
            ],
        },
        {
            key: '/epd_main',
            label: t('ЕПД'),
            hidden: !(
                perms?.epd_generator || perms?.dispatch || perms?.email_dispatch ||
                perms?.import_file?.import_email
            ),
            children: [
                {
                    key: '/tasks/all',
                    label: <Link to={'/tasks/all'}>{t('Формирование ЕПД')}</Link>,
                    hidden: !permitted(['epd_generator::change'], perms)
                },
                {
                    key: '/approve-emails',
                    label: <Link to={'/approve-emails'}>{t('Одобрение Email-рассылок')}</Link>,
                    hidden: !permitted(['email_dispatch::change', 'import_file::import_email'], perms)
                },
                {
                    key: '/tasks/email-per-accounts',
                    label: <Link to={'/tasks/email-per-accounts'}>{t('Email по ЛС')}</Link>,
                    hidden: !permitted(['dispatch::change'], perms)
                },
            ],
        },
        {
            key: '/reports',
            label: t('Отчеты'),
            hidden: !(perms?.report?.list),
            children: [
                {
                    key: '/reports/reports',
                    label: <Link to={'/reports'}>{t('Отчеты')}</Link>,
                    hidden: !perms?.report?.list
                },
                {
                    key: '/reports/files',
                    label: <Link to={'/reports/files'}>{t('Файловый менеджер')}</Link>,
                    hidden: !perms?.report?.list
                },
            ],
        },
        {
            key: '/addresses_main',
            label: t('Адресные данные'),
            hidden: !(perms?.town || perms?.town_distr || perms?.street || perms?.street_type || perms?.real_estate_object_type || perms?.address),
            children: [
                {
                    key: '/dictionaries/town',
                    label: <Link to={'/dictionaries/town'}>{t('Населенные пункты')}</Link>,
                    hidden: !perms?.town?.list
                },
                {
                    key: '/dictionaries/town_distr',
                    label: <Link to={'/dictionaries/town_distr'}>{t('Районы города')}</Link>,
                    hidden: !perms?.town_distr?.list
                },
                {
                    key: '/dictionaries/street_type',
                    label: <Link to={'/dictionaries/street_type'}>{t('Типы улиц')}</Link>,
                    hidden: !perms?.street_type?.list
                },
                {
                    key: '/dictionaries/real-estate-object-type',
                    label: <Link to={'/dictionaries/real-estate-object-type'}>{t('Типы объектов недвижимости')}</Link>,
                    hidden: !perms?.real_estate_object_type?.list
                },
                {
                    key: '/dictionaries/street',
                    label: <Link to={'/dictionaries/street'}>{t('Улицы')}</Link>,
                    hidden: !perms?.street?.list
                },
                {
                    key: '/dictionaries/address',
                    label: <Link to={'/dictionaries/address'}>{t('Адреса')}</Link>,
                    hidden: !perms?.address?.list
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/swap-address',
                    label: <Link to={'/dictionaries/swap-address'}>{t('Назначение адресов на район')}</Link>,
                    hidden: !perms?.swap_address?.update
                },
            ],
        },
        {
            key: '/banks_main',
            label: t('Банковские данные'),
            hidden: !(perms?.bank?.list || perms?.bank_commission?.list || perms?.bank_account_type?.list || perms?.kbe?.list || perms?.kbk?.list || perms?.knp?.list),
            children: [
                {
                    key: '/dictionaries/banks',
                    label: <Link to={'/dictionaries/banks'}>{t('Банки')}</Link>,
                    hidden: !perms?.bank?.list
                },
                {
                    key: '/dictionaries/bank-commission',
                    label: <Link to={'/dictionaries/bank-commission'}>{t('Комиссия банка')}</Link>,
                    hidden: !perms?.bank_commission?.list
                },
                {
                    key: '/dictionaries/bank-account-type',
                    label: <Link to={'/dictionaries/bank-account-type'}>{t('Типы расчетных счетов')}</Link>,
                    hidden: !perms?.bank_account_type?.list
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/kbe',
                    label: <Link to={'/dictionaries/kbe'}>{t('КБе')}</Link>,
                    hidden: !perms?.kbe?.list
                },
                {
                    key: '/dictionaries/kbk',
                    label: <Link to={'/dictionaries/kbk'}>{t('КБК')}</Link>,
                    hidden: !perms?.kbk?.list
                },
                {
                    key: '/dictionaries/knp',
                    label: <Link to={'/dictionaries/knp'}>{t('КНП')}</Link>,
                    hidden: !perms?.knp?.list
                },
            ],
        },
        {
            key: '/administration_main',
            label: t('Администрирование'),
            hidden: !(
                perms?.bank_api_info?.list || perms?.service_options || perms?.services_columns ||
                perms?.epd_template?.list || perms?.users?.list ||
                perms?.role?.list || perms?.permission?.list || perms?.position?.list
            ),
            children: [
                {
                    key: '/dictionaries/bank-api-info',
                    label: <Link to={'/dictionaries/bank-api-info'}>{t('Настройка для API с банком')}</Link>,
                    hidden: !perms?.bank_api_info?.list
                },
                {
                    key: '/admin-services',
                    label: <Link to={'/admin-services'}>{t('Администрирование услуг')}</Link>,
                    hidden: !perms?.service?.update
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/epd-template-fields',
                    label: <Link to={'/dictionaries/epd-template-fields'}>{t('Поля начислений для ЕПД')}</Link>,
                    hidden: !perms?.epd_template?.list
                },
                {
                    key: '/dictionaries/epd-templates',
                    label: <Link to={'/dictionaries/epd-templates'}>{t('Шаблоны ЕПД')}</Link>,
                    hidden: !perms?.epd_template?.list
                },
                {
                    label: <Link to={'/services-columns'}>{t('Заголовки таблицы в ЕПД')}</Link>,
                    key: '/services-columns',
                    hidden: !perms?.services_columns?.list
                },
                {type: 'divider'},
                {
                    key: '/dictionaries/users',
                    label: <Link to={'/dictionaries/users'}>{t('Пользователи')}</Link>,
                    hidden: !perms?.users?.list
                },
                {
                    key: '/dictionaries/password-settings',
                    label: <Link to={'/dictionaries/password-settings'}>{t('Параметры паролей')}</Link>,
                    hidden: !perms?.users?.list
                },
                {
                    key: '/dictionaries/role',
                    label: <Link to={'/dictionaries/role'}>{t('Роли')}</Link>,
                    hidden: !perms?.role?.list
                },
                {
                    key: '/dictionaries/permission',
                    label: <Link to={'/dictionaries/permission'}>{t('Разрешения')}</Link>,
                    hidden: !perms?.permission?.list
                },
                {
                    key: '/dictionaries/position',
                    label: <Link to={'/dictionaries/position'}>{t('Должности')}</Link>,
                    hidden: !perms?.position?.list
                },
            ],
        },
    ] : [{key: '/', label: <Link to={'/'}><HomeTwoTone/></Link>}]

    const permittedItems = useMemo(() => {
        return items?.filter(i => !i.hidden)?.map((item) => {
            if (item.children) {
                item.children = item.children.filter((child) => !child.hidden);
            }
            return item;
        });
    }, [items])

    return <Menu mode={'horizontal'}
                 items={permittedItems}
                 style={{width: '100%'}}
                 selectedKeys={[pathname]}
                 theme={'dark'}
    />
}
