import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';

import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import {Select} from "antd";
import {$dictsSelects} from "../../../models/infoModel/index.js";

import {
    $createdUsersId, submitCreateUsersEv, UsersCreateGate, $afterCreateAction,
    resetAfterCreationAttrs,
    setAfterCreateActionEv, $userCreateErrors
} from '../../../models/dictionaryUsersModel/index.js';
import Switch from "antd/es/switch";
import InputMask from "react-input-mask";

export default function CreateUsers() {
    const navigate = useNavigate()
    const {t} = useTranslation()

    const [usersForm] = Form.useForm()

    const submit = useUnit(submitCreateUsersEv)

    const loading = useUnit(universalDictEndpointFx.pending)


    const createdId = useUnit($createdUsersId)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)
    const resetAttrs = useUnit(resetAfterCreationAttrs)
    const positionOpts = useStoreMap($dictsSelects, sel => sel.position)
    const roleOpts = useStoreMap($dictsSelects, sel => sel.role)
    const usersOpts = useStoreMap($dictsSelects, sel => sel.users)

    const passErrors = useUnit($userCreateErrors)




    useEffect(() => {
        if (createdId !== null) {
            if (action === 'edit') {
                usersForm.resetFields()
                navigate(`/dictionaries/users/edit/${createdId}`)
            } else if (action === 'new') {
                usersForm.resetFields()
                // location.reload()
            } else if (action === 'list') {
                usersForm.resetFields()
                navigate(`/dictionaries/users?code_name=${createdId}`)
            }
            resetAttrs()
        }
    }, [action, createdId]);

    return <>
        <UsersCreateGate />
        <Form form={usersForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              // onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={12}>
                    <Card title={<StarredTitle title={t('Общая информация')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Row justify={'space-between'} gutter={[24, 24]}>
                            <Col span={12}>

                                <Form.Item name={'login'}
                                           label={t('Логин')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'name_ru'}
                                           label={t('ФИО')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>


                            <Col span={12}>

                                <Form.Item name={'role_id'}
                                           label={t('Роль')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={roleOpts}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'is_active'}
                                           label={t('Активен')}
                                           valuePropName={'checked'}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Switch
                                        defaultValue={true}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>

                                <Form.Item name={'password'}
                                           label={t('Пароль')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           hasFeedback
                                           validateStatus={passErrors?.length > 0 ? 'error' : null}
                                >
                                    <Input type="password" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'confirm_password'}
                                           label={t('Подтверждение пароля')}
                                           dependencies={['password']}
                                           hasFeedback
                                           rules={[
                                               {
                                                   required: true,
                                                   message: 'Пароли отличаются!',
                                               },
                                               ({ getFieldValue }) => ({
                                                   validator(_, value) {
                                                       if (!value || getFieldValue('password') === value) {
                                                           return Promise.resolve();
                                                       }
                                                       return Promise.reject(new Error('Пароли отличаются!'));
                                                   },
                                               }),
                                           ]}
                                >
                                    <Input type="password" />
                                </Form.Item>
                            </Col>

                        </Row>
                        {
                            passErrors?.length > 0 && passErrors
                                .map((item, index) => <p key={index} style={{color: 'red'}}>{item}</p>)
                        }
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title={t('Свойства пользователя')} size={'small'}
                          style={{height: '100%'}} loading={loading}>
                        <Row justify={'space-between'} gutter={[24, 24]}>

                            <Col span={12}>

                                <Form.Item name={'chief_id'}
                                           label={t('Руководитель')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={usersOpts}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'position_id'}
                                           label={t('Должность')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Select
                                        options={positionOpts}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'phone'}
                                           label={t('Телефон')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    {/*<InputMask mask="(999) 999-99-99">*/}
                                    {/*    {() => <Input />}*/}
                                    {/*FIXME InputMask Rerenders and error*/}
                                    {/*</InputMask>*/}
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name={'email'}
                                           label={t('E-mail')}
                                           rules={[{required: false, message: t('Обязательное поле')}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>




                        </Row>
                    </Card>
                </Col>
            </Row>

        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    usersForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('new')
                            usersForm.submit()
                        }}
                        type={'primary'}
                        ghost
                >
                    {t('Сохранить и создать следующую')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('edit')
                            usersForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={loading}
                        onClick={() => {
                            setAction('list')
                            usersForm.submit()
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
