import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useEffect} from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import {
    $createdServices,
    $createEditChildServicesEmbedded, $ServicesChildren,
    resetCreateEditChildServicesEmbeddedEv
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import Col from 'antd/es/col';
import {InputNumber} from "antd";
import {Select} from "antd";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {$selectedServices} from "../../../models/dictionaryServicesModel/index.js";

export default function CreateEditEPDTemplateFieldEmbeddedForm({onCreate, onEdit}) {
    const embedded = useUnit($createEditChildServicesEmbedded)
    const reset = useUnit(resetCreateEditChildServicesEmbeddedEv)

    const selected = useUnit($selectedServices)

    const {t} = useTranslation()
    const [epdTemplatesForm] = Form.useForm()

    const newData = useUnit($createdServices)

    const [check, changedFields] = useFormChanged(embedded.item, [
        'service',
        'name_ru',
        'name_kz',
        'short_name_ru',
        'short_name_kz',
        'service_unit_id',
        'epd_order',
        'service_payment_type_id',
        'is_counter_service'
    ])

    const {data, count} = useUnit($ServicesChildren)


    const excludeIds = [...data, ...newData].map(item => item.id)

    const serviceOpts = useStoreMap($dictsSelects, sel => sel.services).filter(obj => !excludeIds.includes(obj.value))
    const serviceOptions = serviceOpts.filter(item => item.label !== ' ')

    const serUnitOpts = useStoreMap($dictsSelects, sel => sel.service_units)
    const paymentTypeOpts = useStoreMap($dictsSelects, sel => sel.payment_type)
    const templateOpts = useStoreMap($dictsSelects, sel => sel.template)




    const onClose = () => {
        epdTemplatesForm.resetFields()
        reset()
    }

    useEffect(() => {
        if (embedded.open && embedded.type === 'edit' && embedded.idx !== null && embedded.item) {
            epdTemplatesForm.setFieldsValue({
                ...embedded.item,
                // actual_from: embedded?.item?.actual_from ? dayjs(embedded.item.actual_from) : null
            })
        }
    }, [embedded]);

    const onSubmit = (values) => {

        const id = parseInt(
            `-${newData?.length + 1}`
        )


        if (embedded.type === 'edit') {
            onEdit({
                payload: {
                    ...values,
                    id: embedded.item.id,
                    new_item: embedded.item.new_item === true ? true : false
                }, id: embedded.item.id
            })
        } else if (embedded.type === 'create') {
            onCreate(values.service)
        }
        onClose()
    }





    return embedded.open &&
        <Form form={epdTemplatesForm}
              layout={'vertical'}
              onFinish={onSubmit}
              size={'small'}
              style={{width: '100%', marginBottom: 16}}
              onValuesChange={(_, all) => {
                  if (embedded.type === 'edit') {
                      check(all)
                  }
              }}
        >
            {
                embedded.type === 'create' ? <Card title={t('Свойства услуги')} size={'small'} style={{height: '100%'}}>
                    <Row  gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item name={'service'}
                                       label={t('Название (рус)')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={embedded.type === 'edit' ? changedFields['service'] : undefined}
                            >
                                <Select
                                    options={serviceOptions}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    style={{
                                        width: '205px'
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card> : <Col>
                        <Row  gutter={[16, 16]}>
                            <Col span={6}>
                                <Form.Item name={'name_ru'}
                                           label={t('Название (рус)')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={embedded.type === 'edit' ? changedFields['name_ru'] : undefined}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'name_kz'}
                                           label={t('Название (каз)')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={embedded.type === 'edit' ? changedFields['name_kz'] : undefined}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'short_name_ru'}
                                           label={t('Короткое Название (рус)')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={embedded.type === 'edit' ? changedFields['short_name_ru'] : undefined}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name={'short_name_kz'}
                                           label={t('Короткое Название (каз)')}
                                           rules={[{required: true, message: t('Обязательное поле')}]}
                                           validateStatus={embedded.type === 'edit' ? changedFields['short_name_kz'] : undefined}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    <Row  gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item name={'service_unit_id'}
                                       label={t('Единица измерения')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={embedded.type === 'edit' ? changedFields['service_unit_id'] : undefined}
                            >
                                <Select
                                    options={serUnitOpts}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }

                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={'epd_order'}
                                       label={t('Порядок ЕПД')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={embedded.type === 'edit' ? changedFields['epd_order'] : undefined}
                            >
                                <InputNumber style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name={'service_payment_type_id'}
                                       label={t('Вид платежа')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={embedded.type === 'edit' ? changedFields['service_payment_type_id'] : undefined}
                            >
                                <Select
                                    options={paymentTypeOpts}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }

                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name={'is_counter_service'}
                                       label={t('Услуга по счетчику')}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={embedded.type === 'edit' ? changedFields['is_counter_service'] : undefined}
                            >
                                <Select
                                    options={serviceOptions}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    options={[
                                        {label: 'Да', value: true},
                                        {label: 'Нет', value: false},
                                    ]}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            }

            <Row style={{gap: 8, marginTop: 16}}>
                <Button onClick={onClose} danger>{t('Отмена')}</Button>
                <Button type={'primary'} onClick={epdTemplatesForm.submit}>{t('Сохранить')}</Button>
            </Row>
        </Form>
}
