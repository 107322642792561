import {createEvent} from 'effector';
import {createGate} from 'effector-react';

export const TaskEPDPerREOGate = createGate()
export const REOFilesGate = createGate()

export const changeFiltersEv = createEvent()

export const changeSelectedREOEv = createEvent()

export const createTaskEv = createEvent()

export const downloadFilesPerREOEv = createEvent()
export const downloadSingleREOFileEv = createEvent()

export const resetTaskCreatedEv = createEvent()