import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {
    EPDTemplateFieldChildrenGate,
    $createdEPDTemplateField,
    $EPDTemplateFieldChildren,
    deleteChildEPDTemplateFieldEv,
    deleteNewEPDTemplateFieldEv,
    createChildEPDTemplateFieldEv,
    editChildEPDTemplateFieldEv,
    setCreateEditChildEPDTemplateFieldEmbeddedEv,
    $EPDTemplateFieldEmbeddedFilters, changeEPDTemplateFieldChildrenFiltersEv
} from '../../../models/dictionaryEmbeddedFormsModel';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';
import {CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group.js';
import Popconfirm from 'antd/es/popconfirm';
import Table from 'antd/es/table';
import {paginationConfig} from '../../../utils/paginationConfig.js';
import {
    getEPDTemplateFieldChildrenFx,
} from '../../../models/dictionaryEmbeddedFormsModel/effects.js';
import CreateEditEPDTemplateFieldEmbeddedForm from './CreateEditEPDTemplateFieldEmbedded.jsx'
import {$epdCellTypeDict, $epdFieldNameDict} from "../../../models/infoModel/index.js";

export default function EPDTemplateFieldTable({view, parent, parentId}) {
    const {t} = useTranslation()
    const {data, count} = useUnit($EPDTemplateFieldChildren)
    const loading = useUnit(getEPDTemplateFieldChildrenFx.pending)
    const newData = useUnit($createdEPDTemplateField)

    const deleteExisting = useUnit(deleteChildEPDTemplateFieldEv)
    const deleteNew = useUnit(deleteNewEPDTemplateFieldEv)

    const onCreate = useUnit(createChildEPDTemplateFieldEv)
    const onEdit = useUnit(editChildEPDTemplateFieldEv)
    const fieldNameDict = useUnit($epdFieldNameDict)
    const cellTypeDict = useUnit($epdCellTypeDict)

    const setModal = useUnit(setCreateEditChildEPDTemplateFieldEmbeddedEv)

    const {page, limit, sortField, sortOrder} = useUnit($EPDTemplateFieldEmbeddedFilters)
    console.log('limit', limit)
    const changeFilters = useUnit(changeEPDTemplateFieldChildrenFiltersEv)
    const genitive_form = t(`breadcrumbs.genitive.epd-template-fields`);


    const columns = [
        {
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'id',
        },

        {
            title: t(`Название ${genitive_form}`),
            dataIndex: 'field_name',
            sorter: true,
            // render: (field_name) => field_name ? field_name : '-'
            render: (field_name) => fieldNameDict[field_name] ? fieldNameDict[field_name] : field_name

        },
        {
            title: t('Тип ячейки'),
            dataIndex: 'cell_type',
            sorter: true,
            render: (cell_type) => cellTypeDict[cell_type] ? cellTypeDict[cell_type] : cell_type
        },
        {
            title: t('Номер столбца'),
            dataIndex: 'column_number',
            sorter: true,
            render: (column_number) => column_number ? column_number : '-'
        },
        {
            title: t('Размер шрифта'),
            dataIndex: 'font_size',
            sorter: true,
            render: (font_size) => font_size ? font_size : '-'
        },
        {
            title: <Tooltip title={t('Добавить')}>
                <Button icon={<PlusOutlined/>} onClick={() => setModal({type: 'create'})} type={'primary'}/>
            </Tooltip>,
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            hidden: view,
            key: 'actions',
            render: (id,row) => {
                return <ButtonGroup>
                    <Tooltip title={t('Редактировать')}>
                        <Button icon={<EditOutlined />} onClick={() => setModal({type: 'edit', id})}/>
                    </Tooltip>
                    <Popconfirm title={t('Вы уверены, что хотите удалить эту запись?')}
                                okText={t('Да')}
                                onConfirm={
                                    () => {
                                         if (Math.sign(row.id) === 1) {
                                             return deleteExisting(id)
                                        } else {
                                           return  deleteNew(id)
                                        }
                                    }
                                }
                                placement={'left'}
                    >
                        <Tooltip title={t('Удалить')}>
                            <Button icon={<CloseOutlined />} type={'primary'} danger/>
                        </Tooltip>
                    </Popconfirm>
                </ButtonGroup>
            }
        }
    ]

    if (view) {
        const idx = columns.findIndex(i => i.key === 'actions')
        if (idx !== -1) {
            columns.splice(idx, 1)
        }
    }

    const rowClassName = (record, index) => {
        const checkNew = Math.sign(record.id) === 1 ? false : true
        if (checkNew === true) {
            return 'custom-new-row'
        } else if (record?.changedFields) {
            return 'custom-edit-old-row'
        } else if (record.new_item) {
            return 'custom-new-select-row'
        } else {
            return 'custom-normal-row'
        }
    };



    return <>
        <EPDTemplateFieldChildrenGate parent={parent} parentId={parentId} />
        <CreateEditEPDTemplateFieldEmbeddedForm onCreate={onCreate} onEdit={onEdit} />


        <Table dataSource={[...data, ...newData]}
               columns={columns}
               loading={loading}
               bordered
               size={'small'}
               rowClassName={rowClassName}
               pagination={{
                   ...paginationConfig,
                   pageSize: parseInt(limit),
                   current: parseInt(page),
                   total: count
               }}

               onChange={({current, pageSize}, filters, {field, order}) => {
                   const payload = []
                   if (current !== page || pageSize !== limit) {
                       payload.push({key: 'page', value: current})
                       payload.push({key: 'limit', value: pageSize})
                   }

                   if (field !== sortField || order !== sortOrder) {
                       if (!order) {
                           payload.push({key: 'sort_field', value: null})
                           payload.push({key: 'sort_order', value: null})
                       } else {
                           payload.push({key: 'sort_field', value: field})
                           payload.push({
                               key: 'sort_order',
                               value: order === 'ascend' ? 'asc' : 'desc'
                           })
                       }
                   }
                   changeFilters(payload)
               }}
               style={{width: '100%'}}
        />
    </>
}
