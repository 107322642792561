import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    CheckOutlined, CloseOutlined,
    EditOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import {Link, useSearchParams} from 'react-router-dom';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';

import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {
    $passwordSettingsList, dataModalEv, deletePasswordSettingsEv,
    getPasswordSettingsListFx, openModalEv,
    PasswordSettingsListGate
} from "../../models/dictionaryPasswordSettingsModel/index.js";
import React from "react";
import EditPasswordSettingsModal from "./components/EditPasswordSettingsModal.jsx";
import {$userPerms} from '../../models/infoModel/index.js';

export default function DictionaryPasswordSettingsList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($passwordSettingsList)
    const loading = useUnit(getPasswordSettingsListFx.pending)
    const onDelete = useUnit(deletePasswordSettingsEv)

    const onOpenModal = useUnit(openModalEv)
    const setModalData = useUnit(dataModalEv)
    const perms = useUnit($userPerms)
    // const [search] = useSearchParams()
    const {search, exportXlsAction} = searchAndActions()
    const genitive_form = t(`breadcrumbs.genitive.password-settings`)


    const columns = [
        {
            width: '5%',
            title: t(`Код ${genitive_form}`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
        },
        {
            title: t(`Название ${genitive_form}`),
            dataIndex: 'name_ru',
            sorter: true,
            key: 'name_ru',
            render: (name, record) => <Link to={`/dictionaries/password-settings/view/${record.id}`}>{`${name  }`}</Link>
        },
        {
            title: t(`Значение`),
            dataIndex: 'params',
            sorter: true,
            key: 'params',
            align:  'center',
            render: (params) => params?.value ? params?.value : '-'
        },
        {
            title: t(`Статус`),
            dataIndex: 'active',
            sorter: true,
            key: 'active',
            render: (active) => active ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
        },

        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id, row) => <ButtonGroup>
                {perms?.password_settings?.update && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Button icon={<EditOutlined />} type={'primary'} onClick={() => openModal(row)}/>
                </Tooltip>}

            </ButtonGroup>
        }
    ]


    const openModal = (data) => {
        onOpenModal()
        setModalData(data)
    }



    return <>
        <PasswordSettingsListGate search={search.toString()}/>
        <EditPasswordSettingsModal />
        <DictionaryProTable
            data={data}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
            showActions={false}
        />
    </>
}
