import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';

import {$dictsSelects} from "../../../models/infoModel/index.js";
import {
    setCreateInspectorEmbeddedEv,
    setCreateTownEmbeddedEv
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import CreateTownEmbeddedForm from "../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx";
import CreateInspectorEmbeddedForm
    from "../../../components/Embedded/CreateInspectorEmbeddedForm/CreateInspectorEmbeddedForm.jsx";
import {$inspectorsByCitySelect, getInspectorsByCityEv} from "../../../models/selectsByParentModel/index.js";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionarySectorModel/index.js";
import {
    $editPasswordSettingsStatus,
    $selectedPasswordSettings, getPasswordSettingsByIdFx,
    PasswordSettingsEditGate, submitEditPasswordSettingsEv
} from "../../../models/dictionaryPasswordSettingsModel/index.js";
import Switch from "antd/es/switch";

export default function EditPasswordSettings() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedPasswordSettings)


    const [pwdStgForm] = Form.useForm()


    const getInspectorsByTown = useUnit(getInspectorsByCityEv)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)


    const submit = useUnit(submitEditPasswordSettingsEv)

    const loading = useUnit(getPasswordSettingsByIdFx.pending)

    const newTown = useUnit($createdTownIdModal)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editPasswordSettingsStatus)
    const genitive_form = t(`breadcrumbs.genitive.password_settings`);

    useEffect(() => {
        if (newTown) {
            pwdStgForm.setFieldValue('town_id', newTown)
        }
    }, [newTown])

    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/sector?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            const data = {
                ...selected,
                value: selected.params.value
            }
            pwdStgForm.setFieldsValue(data)
            getInspectorsByTown(selected.town_id)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['name_kz', 'name_ru', 'town_id', 'town_distr_id', 'street_type_id'])

    return <>
        <PasswordSettingsEditGate id={id}/>
        <Form form={pwdStgForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>

                <Col span={6}>
                    <Card title={<StarredTitle title={t('Название')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'name_ru'}
                                   label={t('Название')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                        >
                            <Input />
                        </Form.Item>
                        <CreateTownEmbeddedForm form={pwdStgForm}/>


                    </Card>
                </Col>
                <Col span={6}>
                    <Card title={<StarredTitle title={t('Параметр')}/>} size={'small'}
                          style={{height: '100%'}} loading={loading}
                          rules={[{required: true, message: t('Обязательное поле')}]}
                    >
                        <Form.Item name={'setting'}
                                   label={t('Параметр')}
                        >
                            <Input />
                        </Form.Item>
                        <CreateInspectorEmbeddedForm form={pwdStgForm}/>

                    </Card>

                </Col>


                <Col span={6}>
                    <Card title={t('Значение')} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'value'}
                                   label={t('Значение')}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>

                </Col>


                <Col span={6}>
                    <Card title={t('Статус ')} size={'small'}
                          style={{height: '100%'}} loading={loading}>

                        <Form.Item name={'active'}
                                   label={t('Статус')}
                                   rules={[{required: false, message: t('Обязательное поле')}]}
                        >
                            <Switch />
                        </Form.Item>
                    </Card>
                </Col>

            </Row>

        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    pwdStgForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            pwdStgForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            pwdStgForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
