import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $benefBAsModal,
    $selectedBankAccount,
    addBenefBAEv, createEditBankAccountFx,
    editBenefBAEv, resetBenefBAModalEv
} from '../../../models/dictionaryProvidersModel/index.js';
import {$dictsSelects} from '../../../models/infoModel/index.js';
import {useEffect} from 'react';
import Form from 'antd/es/form';
import Modal from 'antd/es/modal';
import Col from 'antd/es/col';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import {filterOption} from '../../../utils/selectUtils.jsx';
import Row from 'antd/es/row';

export default function CreateEditBenefBAModal() {
    const {t} = useTranslation()

    const {open, id} = useUnit($benefBAsModal)
    const resetModal = useUnit(resetBenefBAModalEv)
    const submitLoading = useUnit(createEditBankAccountFx.pending)

    const [baForm] = Form.useForm()
    const addAcc = useUnit(addBenefBAEv)
    const editAcc = useUnit(editBenefBAEv)
    const selectedBa = useUnit($selectedBankAccount)

    const onCancelAdd = () => {
        baForm.resetFields()
        resetModal()
    }

    const onSubmit = (values, id) => {
        if (id) {
            editAcc(values)
        } else {
            addAcc(values)
        }
        onCancelAdd()
    }

    const {bankOpts, bankAccTypeOpts} = useStoreMap($dictsSelects, sel => ({
        bankOpts: sel.bank,
        bankAccTypeOpts: sel.bank_account_types
    }))

    useEffect(() => {
        if (selectedBa && open) {
            baForm.setFieldsValue({
                provider_id: selectedBa.provider_id,
                bank_id: selectedBa?.bank_id ?? null,
                bank_account_type_id: selectedBa?.bank_account_type_id ?? null,
                account_number: selectedBa?.account_number ?? null,
            })
        }
    }, [selectedBa, open])

    return <Modal
        cancelText={t('Отменить')}
        centered={true}
        destroyOnClose={true}
        okText={!id ? t('Добавить') : t('Применить')}
        title={!id ? t('Добавление р/с') : t('Редактирование р/с')}
        open={open}
        onOk={baForm.submit}
        okButtonProps={{loading: submitLoading}}
        onCancel={onCancelAdd}
        width={800}
    >
        <Form form={baForm}
              layout={'vertical'}
              onFinish={(values) => onSubmit(values, id)}
        >
            <Row gutter={[16, 0]}>
            <Col span={8}>
                <Form.Item name={'bank_id'} label={t('Банк')}
                           rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Select
                        options={bankOpts} filterOption={filterOption} showSearch allowClear
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={'bank_account_type_id'} label={t('Тип расчетного счета')}
                           rules={[{required: true, message: t('Обязательное поле')}]}>
                    <Select
                        options={bankAccTypeOpts} filterOption={filterOption} showSearch
                        allowClear
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item name={'account_number'} label={t('Номер счета')}
                           validateTrigger={'onBlur'}
                           normalize={value => value.toUpperCase()}
                           rules={[
                               {required: true, message: t('Обязательное поле')},
                               {
                                   pattern: /^[A-Z0-9]{20}$/,
                                   message: t('20 символов, только латинские буквы и цифры')
                               },
                           ]}>
                    <Input showCount maxLength={20}/>
                </Form.Item>
            </Col>
            </Row>
        </Form>
    </Modal>
}