import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import React, {useEffect} from "react";
import {
    CheckCircleOutlined, CheckOutlined,
    CloseCircleOutlined, CloseOutlined, EyeOutlined,
    LoadingOutlined,
    UploadOutlined,
    StopOutlined, DownloadOutlined, FileExclamationOutlined
} from '@ant-design/icons';
import {
    $fileList, acceptFileEv,
    FilesGate, getFilesListFx, rejectFileEv, sendMessageUploadEv,
    setUploadModalEv, stopUploadFileEv
} from '../../models/uploadProviderAccountsModel/index.js';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Divider from 'antd/es/divider';
import UploadProviderAccountsModal from './components/UploadProviderAccountsModal.jsx';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {$WsSt} from "../../models/webSocketModel/index.js";
import {useWebSocket} from "../../api/WebSocketProvider.jsx";
import ButtonGroup from 'antd/es/button/button-group';
import {Popconfirm, Progress} from 'antd';
import DictionaryProTable from '../../components/DictionaryProTable/DictionaryProTable.jsx';
import {Link, useSearchParams} from 'react-router-dom';
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import {monthNames} from "../../utils/monthNamesDict.js";
import {downloadErrorFileEv} from "../../models/uploadProviderAccountsModel";

export default function UploadProviderAccounts() {
    const {t} = useTranslation()
    const {data, count} = useUnit($fileList)
    const loading = useUnit(getFilesListFx.pending)
    const setModal = useUnit(setUploadModalEv)
    const { sendMessage } = useWebSocket();

    const [search] = useSearchParams()

    const accept = useUnit(acceptFileEv)
    const stopUpload = useUnit(stopUploadFileEv)
    const reject = useUnit(rejectFileEv)

    const downloadErrorFile = useUnit(downloadErrorFileEv)

    const wsStatus = useUnit($WsSt)

    useEffect(() => {
        const payload = {
            sendMessage: sendMessage,
            payload: {
                object_type: "import_account_provider",
                action: 'get_list',
                subscribe: true
            }
        }
        sendMessageUploadEv(payload)

    }, [wsStatus]);

    const columns = [
        {
            title: t('Статус загрузки'),
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            sorter: true,
            key: 'status',
            render: (status, record) => <Tooltip
                title={
                    status === 'new' ||  status === 'processing file'
                        ? t('В обработке')
                        : status === 'file processed' || status === 'file approved' || status === 'adding to the table failed' || status === 'file disapproved' || status === 'adding to the table failed'
                            // ? record.load_error
                            ? t('Успешно')
                            : status === "stopped" || status === 'processing stopped'
                                ? t('Остановлено')
                                : status === "error" || status === 'processing failed'
                                    ? t('Ошибка')
                                    :  '-'
                }
                placement={'right'}
            >
                {
                    status === 'new' ||  status === 'processing file'
                        ? <div>
                            <Progress type="circle" size={40} percent={parseInt(record?.import_progress)}/>
                        </div>
                        : status === "file processed" ||  status === 'file approved' || status === 'adding to the table failed' || status === 'file disapproved' || status === 'adding to the table failed'
                            ? <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>
                            : status === "stopped" || status === 'processing stopped' ? <StopOutlined style={{color: 'red', fontSize: 24}}/>
                                : status === "error" || status === 'processing failed' ? <CloseCircleOutlined style={{color: 'red', fontSize: 24}}/>
                                    : '-'
                }
            </Tooltip>
        },
        {
            title: t('Статус принятия'),
            dataIndex: 'is_approved',
            key: 'is_approved',
            align: 'center',
            width: '5%',
            // render: (accepted, record) => record.stage === "Перенос данных"  ?
            //     <Tooltip title={record.stage}>
            //         <LoadingOutlined style={{fontSize: 24}}/>
            //     </Tooltip> :
            //     accepted === true ?
            //         <Tooltip title={'Файл подтвержден'}> <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>  </Tooltip>:
            //         accepted === false ?
            //             <Tooltip title={'Файл отклонен'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
            //             // accepted === null && record.status === 'adding to the table failed' ?
            //             record.status === 'adding to the table failed' ?
            //                 <Tooltip title={'Ошибка'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> :
            //                 '-'
            render: (is_approved, record) => is_approved === true ?
                <Tooltip title={'Файл подтвержден'}> <CheckCircleOutlined style={{color: 'lightgreen', fontSize: 24}}/>  </Tooltip> :
                is_approved === false ? <Tooltip title={'Файл отклонен'}><CloseCircleOutlined style={{color: 'red', fontSize: 24}}/> </Tooltip> : '-'
        },
        {
            title: t('Название файла'),
            dataIndex: 'file_name',
            sorter: true,
            key: 'filename',
            render: (filename) => filename.length > 20 ?
                <Tooltip title={filename}> {filename.substring(0, 20) + '...'}</Tooltip> :
                <Tooltip title={filename}>{filename}</Tooltip>
        },
        // {
        //     title: t('Тип'),
        //     dataIndex: 'file_type',
        //     key: 'file_type',
        //     sorter: true,
        //     render: (type) => type !== '-' ?
        //         <Tooltip title={`${type} ${t('столбцов')}`}> {type}</Tooltip>:
        //         <Tooltip title={'Неизвестное кол-во столбцов'}>-</Tooltip>
        // },
        {
            title: t('Поставщик'),
            dataIndex: 'provider_id',
            key: 'provider_id',
            sorter: true,
            width: '25%',
            render: (provider_id, row) => <MappedDictItem id={row?.params?.provider_id} type={'providers'}/>,
        },
        {
          title: t('Месяц'),
          dataIndex: 'report_date',
          key: 'report_date',
          sorter: true,
          width: '5%',
            render: (date, record) => {
              if(record?.params?.current_month !== '-'){
                  return t(monthNames[new Date(record?.params?.current_month).getMonth() + 1])
              }
              return date
            }
        },
        {
            title: t('Дата загрузки'),
            dataIndex: 'created_at',
            key: 'date',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем загружен'),
            dataIndex: 'created_by',
            key: 'user_id',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Дата решения'),
            dataIndex: 'decided_at',
            key: 'decided_at',
            sorter: true,
            render: (date) => date && <CustomDates data={date} />
        },
        {
            title: t('Кем решено'),
            dataIndex: 'decided_by',
            key: 'decided_by',
            sorter: true,
            render: (id) => <CreatedBy id={id}/>
        },
        {
            title: t('Всего строк'),
            dataIndex: 'total_row_count',
            key: 'total_row_count',
        },
        {
            title: t('Всего ошибок'),
            dataIndex: 'error_row_count',
            key: 'error_row_count',
        },
        {
            title: t('Действия'),
            width: '5%',
            align: 'center',
            key: 'actions',
            dataIndex: 'id',
            render: (id, record) => record.status === "new"  || record.status === 'processing file' ?
                <Tooltip
                    title={t('Остановить загрузку')}
                >
                    <Popconfirm title={t('Вы уверены, что хотите остановить загрузку этого файла?')}
                                onConfirm={() => {
                                    stopUpload(id, 'stop_import')
                                }}
                                okText={t('Да')}
                    >
                        <Button
                            icon={<StopOutlined />}
                            danger
                            type={'primary'}
                            // disabled={record.status === 'processing'}

                        />
                    </Popconfirm>
                </Tooltip>
                : record.status === 'file processed' ?
                    <ButtonGroup>
                        <Tooltip title={t('Просмотр строк')}>
                            <Link to={'/upload-provider-accounts/view/' + id + '?validation=false'}>
                                <Button icon={<EyeOutlined />}/>
                            </Link>
                        </Tooltip>
                        <Tooltip title={t('Подтвердить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите подтвердить этот файл? ')}
                                        description={'Внесены в Систему будут только валидные строки'}
                                        onConfirm={() => accept(id)}
                                        okText={t('Да')}
                            >
                                <Button icon={<CheckOutlined />} type={'primary'}/>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title={t('Отклонить файл')}>
                            <Popconfirm title={t('Вы уверены, что хотите отклонить этот файл?')}
                                        onConfirm={() => reject(id)}
                                        okText={t('Да')}
                                        okButtonProps={{danger: true}}
                            >
                                <Button icon={<CloseOutlined />} danger/>
                            </Popconfirm>
                        </Tooltip>
                    </ButtonGroup>
                    : record.status === "file approved" ?
                        <ButtonGroup>
                            <Tooltip title={t('Просмотр строк')}>
                                <Link to={'/upload-provider-accounts/view/' + id + '?validation=false'}>
                                    <Button icon={<EyeOutlined />}/>
                                </Link>
                            </Tooltip>
                            {/*<Tooltip title={t('Скачать файл')}>*/}
                            {/*    <Button*/}
                            {/*        icon={<DownloadOutlined />}*/}
                            {/*        type={"primary"}*/}
                            {/*        onClick={() => download(id)}*/}
                            {/*    />*/}
                            {/*</Tooltip>*/}
                        </ButtonGroup>
                        : record.status === "stopped" ?
                            ''
                            : record.status === "error" || record.status === 'adding to the table failed' || record.status === 'file disapproved' ?
                                <ButtonGroup>
                                    <Tooltip title={t('Просмотр строк')}>
                                        <Link to={'/upload-provider-accounts/view/' + id + '?validation=false'}>
                                            <Button icon={<EyeOutlined />}/>
                                        </Link>
                                    </Tooltip>
                                </ButtonGroup>
                                : ''

        }
    ]

    const filterItems = [
        {
            name: 'provider_id',
            label: t('Поставщик'),
            type: 'select',
            dict: 'providers'
        },
        {
            name: 'file_name',
            label: t('Название файла'),
            type: 'input',
        },
        {
            name: 'status',
            type: 'select',
            label: t('Статус загрузки'),
            options: [{label: t('Все'), value: null}, {label: t('В обработке'), value: 'processing'}, {label: t('Успешно'), value: 'processed'}, {label: t('Ошибка'), value: 'error'}]
        },
        {
            name: 'is_accepted',
            type: 'select',
            label: t('Статус принятия'),
            options: [{label: t('Принято'), value: true}, {label: t('Отклонено'), value: false}, {label: t('Все'), value: null}]
        }
    ]
    console.log('data', data)

    return <>
        <UploadProviderAccountsModal/>
        {/*<ViewFileModal />*/}
        <FilesGate search={search}/>
        <FilterBlock items={filterItems}/>
        <Divider />
        <DictionaryProTable
            data={data}
            count={count}
            initColumns={columns}
            loading={loading}
            showActions={false}
            tableExtraRender={() => <Tooltip title={t('Загрузить')}>
                <Button type={'primary'} icon={<UploadOutlined />} onClick={() => setModal()}>
                    {t('Загрузить')}
                </Button>
            </Tooltip>}
        />
    </>
}
