import {
    $afterCreateAction,
    $createdAddressId,
    $editAddressStatus,
    $selectedAddress,
    $addressesList, $secAddressList, $mainAddressList, $chosenMainAddress, $chosenSecAddress
} from './stores.js';
import {
    getAddressByIdFx,
    getAddressesListFx,
    getMainAddressesListFx,
    getSecAddressesListFx,
    getSecondaryAddressesFx
} from './effects.js';
import {
    deleteAddressEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    AddressEditGate,
    AddressesListGate,
    AddressViewGate,
    submitCreateAddressEv,
    submitEditAddressEv,
    SecondaryAddressListGate,
    getSecAddressesListEv,
    MainAddressListGate,
    getMainAddressesListEv,
    setMainAddressEv,
    setSecAddressEv,
    createGroupAddressesEv,
    resetMainAddressEv, addSecAddressEv, removeSelectedSecAddressEv, AddressCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';
import dayjs from "dayjs";

const createAddressCompleteEv = combineEvents({events: {ev: submitCreateAddressEv, response: triggerSuccessOpsEv}, reset: AddressCreateGate.state})
const createGroupAddressCompleteEv = combineEvents({events: {ev: createGroupAddressesEv, response: triggerSuccessOpsEv}, reset: AddressCreateGate.state})
const editAddressCompleteEv = combineEvents({ev: submitEditAddressEv, response: triggerSuccessOpsEv})
const deleteAddressCompleteEv = combineEvents({ev: deleteAddressEv, response: triggerSuccessOpsEv})

$addressesList.on(getAddressesListFx.doneData, (_, addresses) => addresses)
    .reset(AddressesListGate.close)

$secAddressList.on(getSecAddressesListFx.doneData, (_, addresses) => addresses)
    .reset(SecondaryAddressListGate.close)

$mainAddressList.on(getMainAddressesListFx.doneData, (_, addresses) => addresses)
    .reset(MainAddressListGate.close)
$selectedAddress.on(getAddressByIdFx.doneData, (_, address) => address)
    .reset([AddressEditGate.close, AddressViewGate.close])

$createdAddressId
    .on(createAddressCompleteEv, (_, {response}) => {
        const address = response.find(i => i.type === 'address')
        return address ? address?.value?.id : undefined
    })
    .on(createGroupAddressCompleteEv, (_, {response}) => {
        const address = response.find(i => i.type === 'address')
        return address ? address?.value?.id : undefined
    })
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editAddressStatus.on(editAddressCompleteEv, () => true)
    .reset(AddressEditGate.close)

$chosenMainAddress.on(setMainAddressEv, (_, id) => id)
    .reset([MainAddressListGate.close, resetMainAddressEv])

$chosenSecAddress.on(setSecAddressEv, (_, adresses) => adresses)
    .reset(SecondaryAddressListGate.close)

$chosenSecAddress.on(getSecondaryAddressesFx.doneData, (_, addresses) => addresses.data)
    .reset(SecondaryAddressListGate.close)

sample({
    clock: [AddressEditGate.state, AddressViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getAddressByIdFx
})

sample({
    clock: AddressesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getAddressesListFx
})

sample({
    source: AddressesListGate.state,
    clock: [createAddressCompleteEv, editAddressCompleteEv, deleteAddressCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getAddressesListFx
})

sample({
    clock: getSecAddressesListEv,
    target: getSecAddressesListFx,
})

sample({
    clock: getMainAddressesListEv,
    target: getMainAddressesListFx,
})

sample({
    source: [$chosenMainAddress, $chosenSecAddress],
    clock: submitCreateAddressEv,
    fn: ([mainAddress, secAddress], payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let town_id = payload.search_town_id
            ? payload.search_town_id : payload.town_id ? payload.town_id : null
        let idCounter = -1

        if (payload.search_town_id) {
            if (payload.search_town_id > 0 && payload.street_id > 0) {
                town_id = payload.search_town_id
                delete payload.search_town_id
            } else if (payload.search_town_id < 0) {
                actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
                town_id = idCounter
                idCounter--
                delete payload.town
            }
        }

        if (payload.street_id < 0) {
            payload.street.town_id = town_id
            // if (payload.street?.town_id < 0) {
            //     actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            //     payload.street.town_id = idCounter
            //     idCounter--
            //     delete payload.town
            // }
            if (payload.street?.street_type_id < 0) {
                actions.push({operation: 'create', type: 'street_type', value: {...payload.streetType, id: idCounter}})
                payload.street.street_type_id = idCounter
                idCounter--
                delete payload.streetType
            }
            actions.push({operation: 'create', type: 'street', value: {...payload.street, id: idCounter}})
            payload.street_id = idCounter
            idCounter--
            delete payload.street
        }

        if (payload.is_actual === undefined) {
            payload.is_actual = true
        }
        payload.actual_from = dayjs(payload.actual_from).startOf('day').format()
        delete payload.town_id

        const finalUpdate = {
            ...payload,
            id: idCounter,
            main_address_id: mainAddress ? mainAddress.id : null,
            town_distr_id: mainAddress ? mainAddress.town_distr_id : payload.town_distr_id,
            index: mainAddress ? mainAddress.index : payload.index,
        }
        actions.push({operation: 'create', type: 'address', value: finalUpdate})
        const new_address_id_counter = idCounter
        idCounter--

        if((payload.main_address_id === null || payload.main_address_id === undefined) && secAddress) {
            secAddress.forEach(address => {
                if(address.id >= 0) {
                    // FIXME район, на перекрестке, index если поменялись
                    actions.push({
                        operation: 'update',
                        type: 'address',
                        value: {
                            object_id: address.id,
                            // id: address.id,
                            main_address_id: new_address_id_counter,
                            town_distr_id: finalUpdate.town_distr_id,
                            is_on_crossroad: finalUpdate.is_on_crossroad,
                            index: finalUpdate.index,
                        }
                    })
                } else {
                    actions.push({
                        operation: 'create',
                        type: 'address',
                        value: {
                            corpus: address.corpus,
                            house: address.house,
                            is_actual: address.is_actual === undefined ? true : address.is_actual,
                            town_distr_id: finalUpdate.town_distr_id,
                            is_on_crossroad: finalUpdate.is_on_crossroad,
                            index: finalUpdate.index,
                            actual_from: dayjs(
                                address.actual_from === undefined ?
                                        new Date() : address.actual_from
                                ).startOf('day').format(),
                            street_id: address.street_id,
                            main_address_id: new_address_id_counter,
                            id: idCounter,
                        }
                    })
                    idCounter--
                }
            })
        }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: [$selectedAddress, $chosenMainAddress, $chosenSecAddress],
    clock: submitEditAddressEv,
    fn: ([current, mainAddress, secAddress], payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        let town_id = payload.search_town_id ?? null
        if (town_id === '' || town_id === ' ') town_id = null

        if (typeof payload.search_town_id === 'number') {
            if (payload.search_town_id > 0 && payload.street_id > 0) {
            } else if (payload.search_town_id < 0) {
                actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
                town_id = idCounter
                idCounter--
            }
        }
        delete payload.town
        delete payload.search_town_id

        if (payload.street_id < 0) {
            payload.street.town_id = town_id
            // if (payload.street?.town_id < 0) {
            //     actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
            //     payload.street.town_id = idCounter
            //     idCounter--
            //     delete payload.town
            // }
            if (payload.street?.street_type_id < 0) {
                actions.push({operation: 'create', type: 'street_type', value: {...payload.streetType, id: idCounter}})
                payload.street.street_type_id = idCounter
                idCounter--
                delete payload.streetType
            }
            actions.push({operation: 'create', type: 'street', value: {...payload.street, id: idCounter}})
            payload.street_id = idCounter
            idCounter--
            delete payload.street
        }

        if (current.actual_from)
            current.actual_from = dayjs(current.actual_from).startOf('day').format()

        if (typeof payload.search_town_distr_id === 'number') {
            if (payload.search_town_distr_id >= 0) {
                payload.town_distr_id = payload.search_town_distr_id
            } else if (payload.search_town_distr_id < 0) {
                actions.push({
                    operation: 'create', type: 'town_distr',
                    value: {...payload.town_distr, id: idCounter}
                })
                idCounter--
            }
        }
        delete payload.search_town_distr_id

        if (mainAddress) {
            payload.town_distr_id = mainAddress.town_distr_id
            payload.index = mainAddress.index
            payload.main_address_id = mainAddress.id
        }

        const newData = {
            ...payload,
             actual_from: dayjs(payload.wtf_actual_from).startOf('day').format(),
        }
        delete newData.wtf_actual_from


        const changedFields = {}
        for (const [key, value] of Object.entries(newData)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        const finalUpdate = {
            object_id: current.id,
            ...changedFields,
        }
        actions.push({operation: 'update', type: 'address', value: finalUpdate})
        idCounter--

        if((payload.main_address_id === null || payload.main_address_id === undefined) && secAddress){
            const new_address_id_counter = current.id
            secAddress.forEach(address => {
                if(address.id >= 0) {
                    // FIXME район, на перекрестке, index если поменялись
                    actions.push({
                        operation: 'update',
                        type: 'address',
                        value: {
                            object_id: address.id,
                            // id: address.id,
                            main_address_id: new_address_id_counter,
                            town_distr_id: newData.town_distr_id,
                            is_on_crossroad: newData.is_on_crossroad,
                            index: newData.index,
                        }
                    })
                } else {
                    actions.push({
                        operation: 'create',
                        type: 'address',
                        value: {
                            corpus: address.corpus,
                            house: address.house,
                            is_actual: address.is_actual === undefined ? true : address.is_actual,
                            town_distr_id: newData.town_distr_id,
                            is_on_crossroad: newData.is_on_crossroad,
                            index: newData.index,
                            actual_from: dayjs(address.actual_from === undefined ?
                                    new Date() : address.actual_from
                                ).startOf('day').format(),
                            street_id: address.street_id,
                            main_address_id: new_address_id_counter,
                            id: idCounter
                        }})
                    idCounter--
                }
            })
        }

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteAddressEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'address', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: SecondaryAddressListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'id') && gate.id !== undefined,
    fn: (gate) => gate.id,
    target: getSecondaryAddressesFx
})

sample({
    clock: createGroupAddressesEv,
    fn: (payload) => {
        const actions = []
        // let town_id = payload.search_town_id ?? null
        let idCounter = -1

        let rawData = payload

        const checkItem = payload[0]
        const town = checkItem.town

        let streetTypeIdCounter = 0



        let tempTownId = null
        let tempDistrictId = null
        let tempStreetId = null

        if (checkItem.town_id < 0) {

            actions.push({operation: 'create', type: 'town', value: {...town, id: idCounter}})
            rawData = rawData.map(item => {
                return {
                    ...item,
                    town_id: idCounter
                }
            })
            tempTownId = idCounter
            idCounter--
        }

        if (checkItem.town_distr_id < 0) {
            const district = checkItem.district
            actions.push({operation: 'create', type: 'town_distr', value: {...district, id: idCounter, town_id: tempTownId}})
            rawData = rawData.map(item => {
                return {
                    ...item,
                    town_distr_id: idCounter,
                }
            })
            tempDistrictId = idCounter
            idCounter--
        }

        if (checkItem.street_type !== null) {
            const streetType = checkItem.street_type
            actions.push({operation: 'create', type: 'street_type', value: {...streetType, id: idCounter}})
            streetTypeIdCounter = idCounter
            idCounter--
        }

        if (checkItem.street_id < 0) {
            const street = checkItem.street
            actions.push({operation: 'create', type: 'street', value: {
                ...street,
                    id: idCounter,
                    town_id: tempTownId,
                    street_type_id: checkItem.street.street_type_id > 0 ? checkItem.street.street_type_id : streetTypeIdCounter
            }})
            rawData = rawData.map(item => {
                return {
                    ...item,
                    street_id: idCounter,

                }
            })
            tempStreetId = idCounter
            idCounter--
        }




        const createdData = rawData.map(item => {
            return {
                // town_id: item.town_id > 0 ? item.town_id : tempTownId,
                town_distr_id: item.town_distr_id > 0 ? item.town_distr_id : tempDistrictId,
                street_id: item.street_id > 0 ? item.street_id : tempStreetId,
                house: item.house,
                corpus: item.corpus,
                is_actual: item.is_actual ? item.is_actual : true,
                actual_from: item.actual_from ?
                    dayjs(item.actual_from).startOf('day').format() :
                    dayjs(Date.now()).startOf('day').format(),
                is_on_crossroad: false,
                id: idCounter
            }
        })

        for (const newDist of Object.values(createdData)) {
            actions.push({operation: 'create', type: 'address', value: {...newDist, }})
        }






        //
        // if (payload.search_town_id) {
        //     if (payload.search_town_id > 0 && payload.street_id > 0) {
        //         town_id = payload.search_town_id
        //         delete payload.search_town_id
        //     } else if (payload.search_town_id < 0) {
        //         actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
        //         town_id = idCounter
        //         idCounter--
        //         delete payload.town
        //     }
        // }
        //
        // if (payload.street_id < 0) {
        //     payload.street.town_id = town_id
        //     // if (payload.street?.town_id < 0) {
        //     //     actions.push({operation: 'create', type: 'town', value: {...payload.town, id: idCounter}})
        //     //     payload.street.town_id = idCounter
        //     //     idCounter--
        //     //     delete payload.town
        //     // }
        //     if (payload.street?.street_type_id < 0) {
        //         actions.push({operation: 'create', type: 'street_type', value: {...payload.streetType, id: idCounter}})
        //         payload.street.street_type_id = idCounter
        //         idCounter--
        //         delete payload.streetType
        //     }
        //     actions.push({operation: 'create', type: 'street', value: {...payload.street, id: idCounter}})
        //     payload.street_id = idCounter
        //     idCounter--
        //     delete payload.street
        // }
        //
        // if (payload.is_actual === undefined) {
        //     payload.is_actual = true
        // }
        // payload.actual_from = payload.actual_from + 'T15:35:44.014Z'
        // delete payload.town_id
        //
        //
        // for(let i = +payload.house_from; i <= +payload.house_to; i++){
        //     actions.push({operation: 'create', type: 'address', value: {...payload, id: idCounter, house: i}})
        //     idCounter--
        // }
        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $chosenSecAddress,
    clock: addSecAddressEv,
    filter: (source, clock) => !source.find(address => address.id === clock.id),
    fn: (source, clock) => {
        return [
          ...source,
          clock
        ]
    },
    target: $chosenSecAddress
})

sample({
    source: $chosenSecAddress,
    clock: removeSelectedSecAddressEv,
    filter: (source, clock) => source.find(address => address.id === clock),
    fn: (source, clock) => {
        return source.filter(address => address.id !== clock)
    },
    target: $chosenSecAddress
})
