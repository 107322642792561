import {createStore} from 'effector';


// Сторы для CRUD таблицы вложенных улиц
export const $createEditChildStreetEmbedded = createStore({open: false, type: null, idx: null, item: null})
export const $streetEmbeddedFilters = createStore({page: 1, limit: 10})
export const $streetsChildren = createStore({data: [], count: 0})
export const $selectedChildStreetsImmutable = createStore([])
export const $createdStreets = createStore([])
export const $deletedStreets = createStore([])
