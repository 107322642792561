import {useTranslation} from 'react-i18next';
import {useStoreMap, useUnit} from 'effector-react';
import {useEffect} from 'react';
import Form from 'antd/es/form';
import Col from 'antd/es/col';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import Card from 'antd/es/card';
import Space from "antd/es/space";
import {PlusOutlined} from '@ant-design/icons';
import {
    resetCreateTownEmbeddedEv,
    setCreateTownEmbeddedEv,
    setCreateStTypeEmbeddedEv,
} from '../../../models/dictionaryEmbeddedFormsModel/index.js';
import {$createEditChildStreetEmbedded, resetCreateEditChildStreetEmbeddedEv} from '../../../models/embeddedFormsModels/embeddedStreetsModel'
import {$dictsSelects} from '../../../models/infoModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import CreateTownEmbeddedForm from '../CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx';
import CreateStTypeEmbeddedForm from '../CreateStTypeEmbeddedForm/CreateEditStTypeEmbeddedForm.jsx';
import StarredTitle from '../../StarredTitle/StarredTitle.jsx';

export default function CreateEditStreetEmbeddedForm({onCreate, onEdit, withTown}) {
    const embedded = useUnit($createEditChildStreetEmbedded)
    const reset = useUnit(resetCreateEditChildStreetEmbeddedEv)

    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const resetTownEmbedded = useUnit(resetCreateTownEmbeddedEv)
    const setStTypeEmbedded = useUnit(setCreateStTypeEmbeddedEv)

    const {t} = useTranslation()
    const [streetForm] = Form.useForm()

    const {townsOpts, stTypesOpts} = useStoreMap($dictsSelects, sel => ({
        townsOpts: sel.towns,
        stTypesOpts: sel.streetTypes
    }))

    const [check, changedFields] = useFormChanged(embedded.item, ['town_id', 'name_kz', 'name_ru', 'street_type_id'])


    const onClose = () => {
        streetForm.resetFields()
        resetTownEmbedded()
        reset()
    }

    useEffect(() => {
        if (embedded.open && embedded.type === 'edit' && embedded.idx !== null && embedded.item) {
            streetForm.setFieldsValue(embedded.item)
        }
    }, [embedded]);

    const onSubmit = (values) => {
        if (embedded.type === 'edit') {
            onEdit({payload: values, id: embedded.item.id})
        } else if (embedded.type === 'create') {
            onCreate(values)
        }
        onClose()
    }

    return embedded.open &&
        <Form form={streetForm}
              size={'small'}
              layout={'vertical'}
              onFinish={onSubmit}
              style={{width: '100%', marginBottom: 16}}
              onValuesChange={(_, all) => {
                  if (embedded.type === 'edit') {
                      check(all)
                  }
              }}
        >
            <Row justify={'space-between'} gutter={[24, 24]}>
                <Col span={withTown ? 8 : 0}>
                    {withTown && <>
                        <Card title={<StarredTitle title={t('Населенный пункт')} required/>} size={'small'} style={{height: '100%'}}>
                            <Form.Item name={'town_id'} rules={[{required: true, message: t('Обязательное поле')}]}>
                                <Select options={
                                    [
                                        ...townsOpts,
                                        {label: 'Создать', value: -1, emoji: true}
                                    ]
                                }
                                        optionRender={(option) => (
                                            <Space>
                                    <span role="img" aria-label={option.data.label} >
                                      {option?.data?.emoji ? <PlusOutlined style={{color: '#1677ff'}}/> :  ''}
                                    </span>
                                                {
                                                    option?.data?.emoji ?
                                                        <span style={{color: '#1677ff'}}>
                                                {option?.data.label}
                                            </span> :
                                                        <span>
                                                {option?.data.label}
                                            </span>
                                                }
                                            </Space>
                                        )}
                                        showSearch
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                        }
                                        allowClear
                                        onChange={(v) => {
                                            if (v === -1) {
                                                setTownEmbedded(true)
                                            } else {
                                                streetForm.resetFields(['town'])
                                                setTownEmbedded(false)
                                            }
                                        }}
                                />
                            </Form.Item>
                            <CreateTownEmbeddedForm/>
                        </Card>
                    </>}
                </Col>
                <Col span={withTown ? 8 : 12}>
                    <Card title={<StarredTitle title={t('Тип улицы')} required/>} size={'small'} style={{height: '100%'}}>
                        <Form.Item name={'street_type_id'} label={t('Тип улицы')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['street_type_id'] : undefined}
                        >
                            <Select options={[...stTypesOpts, {label: 'Создать', value: -1, emoji: true}]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setStTypeEmbedded(true)
                                        } else {
                                            streetForm.resetFields(['streetType'])
                                            setStTypeEmbedded(false)
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                        <span role="img" aria-label={option.data.label} >
                                          {option?.data?.emoji ? <PlusOutlined style={{color: '#1677ff'}}/> :  ''}
                                        </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                            }
                                        </Space>
                                    )}
                            />
                        </Form.Item>
                        <CreateStTypeEmbeddedForm />
                    </Card>
                </Col>
                <Col span={withTown ? 8 : 12}>
                    <Card title={t('Свойства улицы')} size={'small'} style={{height: '100%'}}>
                        <Form.Item name={'name_ru'}
                                   label={t('Название (рус)')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['name_ru'] : undefined}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'name_kz'}
                                   label={t('Название (каз)')}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={embedded.type === 'edit' ? changedFields['name_kz'] : undefined}
                        >
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
            <Row style={{gap: 8, marginTop: 16}}>
                    <Button onClick={onClose} danger>{t('Отмена')}</Button>
                    <Button type={'primary'} onClick={streetForm.submit}>{t('Сохранить')}</Button>
            </Row>
        </Form>
}
