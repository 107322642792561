import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import {
    $editServiceUnitsStatus,
    $selectedServiceUnits,
    getServiceUnitsByIdFx,
    ServiceUnitsEditGate,
    submitEditServiceUnitsEv
} from '../../../models/dictionaryServiceUnitsModel/index.js';
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryServiceUnitsModel/index.js";
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Select from 'antd/es/select';

export default function EditServiceUnits() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selectedServiceUnits = useUnit($selectedServiceUnits)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const [serviceUnitsForm] = Form.useForm()

    const submit = useUnit(submitEditServiceUnitsEv)

    const loading = useUnit(getServiceUnitsByIdFx.pending)
    const algorithmOpts = useStoreMap($dictsSelects, sel => sel.algorithms)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editServiceUnitsStatus)
    const genitive_form = t(`breadcrumbs.genitive.service-units`);


    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/service-units?code_name=${selectedServiceUnits.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selectedServiceUnits) {
            serviceUnitsForm.setFieldsValue(selectedServiceUnits)
        }
    }, [selectedServiceUnits]);

    const [check, changedFields] = useFormChanged(selectedServiceUnits, ['name_kz', 'name_ru', 'short_name_ru', 'short_name_kz'])

    return <>
        <ServiceUnitsEditGate id={id}/>
        <Form form={serviceUnitsForm}
              size={'small'}
              layout={'vertical'}
              onFinish={(v) => submit(v)}
              validateTrigger={'onBlur'}
              onValuesChange={(_, all) => check(all)}
        >
            <Card loading={loading} title={t('Свойства ед. изм. услуг')} size={'small'}>
                <Row justify={'space-between'}>
                    <Col span={4}>
                        <Form.Item name={'name_kz'}
                                   label={t(`Название ${genitive_form} (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'name_ru'}
                                   label={t(`Название ${genitive_form} (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'short_name_kz'}
                                   label={t(`Краткое название (каз)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_kz']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'short_name_ru'}
                                   label={t(`Краткое название (рус)`)}
                                   rules={[{required: true, message: t('Обязательное поле')}]}
                                   validateStatus={changedFields['short_name_ru']}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={'algorithm_id'}
                                   label={t(`Алгоритм`)}
                                   rules={[{required: true, message: t('Алгоритм')}]}
                                   validateStatus={changedFields['algorithm_id']}
                        >
                            <Select
                                options={algorithmOpts}
                                showSearch
                                allowClear
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
        <Divider orientation={'left'} orientationMargin={0}/>
        <Row>
            <Space>
                <Button danger onClick={() => {
                    serviceUnitsForm.resetFields()
                    navigate(-1)
                }}
                >
                    {t('Отмена')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            serviceUnitsForm.submit()
                            setAction('edit')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и продолжить редактирование')}
                </Button>
                <Button loading={submitLoading}
                        onClick={() => {
                            serviceUnitsForm.submit()
                            setAction('list')
                        }}
                        type={'primary'}
                >
                    {t('Сохранить и вернуться к списку')}
                </Button>
            </Space>
        </Row>
    </>
}
