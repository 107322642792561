import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Button from 'antd/es/button';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import ButtonGroup from 'antd/es/button/button-group';
import {
    BulbOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined, EyeOutlined,
    SnippetsOutlined,
    LoadingOutlined,
    StopOutlined
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import {
    $tasksFilters,
    $tasksList, changeTasksFilterEv, setLogsModalEv, setLogsModalIdEv, setParametrsModalEv,
    stopTaskEv,
    TasksGate
} from '../../models/tasksGlobalModel/index.js';
import dayjs from 'dayjs';
import ShowLogsModal from './ShowLogsModal.jsx';
import {useWebSocket} from '../../api/WebSocketProvider.jsx';
import {$WsSt, sendMsgEv} from '../../models/webSocketModel/index.js';
import {useEffect} from 'react';
import CreatedBy from '../CreatedBy/CreatedBy.jsx';
import CustomDates from "../CustomDates/CustomDates.jsx";
import ShowParametrsModal from "./ShowParametrsModal.jsx";
import DictionaryProTable from '../DictionaryProTable/DictionaryProTable.jsx';
import TasksFilters from './TasksFilters.jsx';
import {$generateTypeSt} from "../../models/taskAllPdfModel/index.js";

function getColor(status) {
    switch (status) {
        case 'new':
            return '#2dd0f5'
        case 'pending':
            return '#1890ff'
        case 'success':
            return '#52c41a'
        case 'revoked':
            return '#fa7414'
        case 'error':
            return '#ff4d4f'
    }
}

function getIcon(status) {
    switch (status) {
        case 'new':
            return <BulbOutlined style={{color: getColor(status), fontSize: 24}}/>
        case 'pending':
            return <LoadingOutlined style={{color: getColor(status), fontSize: 24}}/>
        case 'processing':
            return <LoadingOutlined style={{color: getColor(status), fontSize: 24}}/>
        case 'success':
            return <CheckCircleOutlined style={{color: getColor(status), fontSize: 24}}/>
        case 'revoked':
            return <StopOutlined style={{color: getColor(status), fontSize: 24}}/>
        case 'error':
            return <CloseCircleOutlined style={{color: getColor(status), fontSize: 24}}/>
    }
}

export default function TasksStatuses({name}) {
    const {t} = useTranslation()

    const { sendMessage } = useWebSocket()
    const wsStatus = useUnit($WsSt)
    const sub = useUnit(sendMsgEv)

    const generateType = useUnit($generateTypeSt)

    const isAllPdf = window.location.href.includes('tasks/all')
    useEffect(() => {
        const payload = {
            sendMessage: sendMessage,
            payload: {
                object_type: "tasks",
                subscribe: true
            }
        }
        sub(payload)
        return () => sub({
            sendMessage: sendMessage,
            payload: {
                object_type: "tasks",
                subscribe: false
            }
        })
    }, [wsStatus]);

    const {data, count} = useUnit($tasksList)
    const {page, limit, sort_field, sort_order} = useUnit($tasksFilters)
    const changeFilters = useUnit(changeTasksFilterEv)

    const stopTask = useUnit(stopTaskEv)

    const setLogsModal = useUnit(setLogsModalIdEv)
    const setParametrsModal = useUnit(setParametrsModalEv)

    const onChangeTable = ({current, pageSize}, filters, {field, order}) => {
        const payload = []
        if (current !== page || pageSize !== limit) {
            payload.push({key: 'page', value: current})
            payload.push({key: 'limit', value: pageSize})
        }

        if (field !== sort_field || order !== sort_order) {
            if (!order) {
                payload.push({key: 'sort_field', value: null})
                payload.push({key: 'sort_order', value: null})
            } else {
                payload.push({key: 'sort_field', value: field})
                payload.push({
                    key: 'sort_order',
                    value: order === 'ascend' ? 'asc' : 'desc'
                })
            }
        }
        changeFilters(payload)
    }




    const columns = [
        {
            key: 'id',
            title: t('Код'),
            dataIndex: 'id',
            align: 'center',
            sorter: true,
            width: '2%',
        },
        {
            key: 'status',
            title: t('Статус'),
            dataIndex: 'status',
            align: 'center',
            sorter: true,
            width: '5%',
            render: status => <Tooltip title={t(`taskStatuses.${status}`)}>
                {getIcon(status)}
            </Tooltip>
        },

        {
            key: '%',
            title: t('% завершения'),
            render: ({task_count, task_completed}) =>  {
                return isNaN(task_completed / task_count) ? task_completed / task_count * 100 : '100%'
            }
        },
        {
            key: 'started_by',
            title: t('Кем начата'),
            dataIndex: 'created_by',
            sorter: true,
            render: (u) => u ? <CreatedBy id={u}/> : ''
        },
        {
            key: 'started_at',
            title: t('Дата начала'),
            dataIndex: 'created_at',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (date) => date ? <CustomDates data={date} /> : ''
        },
        {
            key: 'finished_at',
            title: t('Дата завершения'),
            dataIndex: 'updated_at',
            sorter: true,
            render: (date, record) => date && record.status !== 'revoked' ? <CustomDates data={date} /> : ''
        },
        {
            key: 'stopped_by',
            title: t('Кем остановлена'),
            dataIndex: 'updated_by',
            sorter: true,
            render: (u, record) => record.status === 'revoked' && u ? <CreatedBy id={u}/> : ''
        },
        {
            key: 'stopped_at',
            title: t('Дата остановки'),
            dataIndex: 'updated_at',
            sorter: true,
            render: (upd, record) => record.status === 'revoked' && upd ? dayjs(upd).format('DD.MM.YYYY HH:mm') : ''
        },
        {
            title: t('Действия'),
            key: 'actions',
            dataIndex: 'id',
            width: '2%',
            align: 'center',
            render: (id, {status, logs, args}) => {
                return <ButtonGroup>
                    {(status === 'new' || status === 'pending' || status === 'processing') && <Popconfirm
                        title={t('Вы действительно хотите остановить эту задачу?')} okText={t('Да')}
                        okButtonProps={{danger: true}}
                        onConfirm={() => stopTask(id)}
                    >
                        <Tooltip title={t('Остановить задачу')}>
                            <Button type={'primary'} danger icon={<StopOutlined/>}/>
                        </Tooltip>

                    </Popconfirm>
                    }
                    {args && <Tooltip title={t('Параметры исполнения')}>
                        <Button icon={<EyeOutlined />} onClick={() => setParametrsModal(args)}/>
                    </Tooltip>}



                    {!(status === 'new' || status === 'pending' || status === 'processing') && <Tooltip title={t('Детали исполнения')}>
                        <Button icon={<SnippetsOutlined />} onClick={() => setLogsModal({logs: logs, id: id})}/>
                    </Tooltip>}
                </ButtonGroup>
            }
        }
    ]
    return <Card>
        <ShowLogsModal />
        <ShowParametrsModal />
        <TasksGate name={isAllPdf ? generateType : name}/>
        <TasksFilters />
        <DictionaryProTable data={data}
                            count={count}
                            initColumns={columns}
                            showActions={false}
                            extPage={page}
                            extLimit={limit}
                            customOnChange={onChangeTable}
        />
    </Card>
}
