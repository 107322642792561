import {useUnit} from 'effector-react';
import Modal from 'antd/es/modal';
import {
    $taskLogsDataModal,
    $taskLogsIdModal,
    $taskLogsModal, getTaskLogsFx,
    LogsIdModalGate,
    resetLogsModalEv
} from '../../models/tasksGlobalModel/index.js';
import {useTranslation} from 'react-i18next';
import Input from "antd/es/input";

export default function ShowLogsModal() {
    const {t} = useTranslation()
    const isAllTasks = window.location.href.includes('tasks/all')
    const {open, logs,  id} = useUnit(isAllTasks ? $taskLogsIdModal : $taskLogsModal)
    const close = useUnit(resetLogsModalEv)
    const { TextArea } = Input;

    const loading = useUnit(getTaskLogsFx.pending)

    const logsById = useUnit($taskLogsDataModal);

    return <>
    {isAllTasks && <LogsIdModalGate id={id}/>}
        <Modal
            title={t('Детали исполнения')}
            open={open}
            width={'60%'}
            footer={null}
            onCancel={() => close()}
            loading={isAllTasks ? loading : false}
        >
            <div style={{width: '100%', height: '90%', overflow: 'auto'}}>
                <TextArea
                    value={
                        isAllTasks ? logsById : logs?.join('\n')
                }
                    rows={8}
                />
            </div>
        </Modal>
    </>
}
