import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import {useStoreMap, useUnit} from 'effector-react';
import {Select} from 'antd';
import MappedDictItem from "../../components/MappedDictItem/MappedDictItem.jsx";
import Switch from "antd/es/switch";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {
    $rolePermissionList,
    deleteRolePermissionEv, getRolePermissionListFx,
    RolePermissionListGate, submitCreateRolePermissionEv, $createdRolePermissionId
} from "../../models/dictionaryRolePermissionModel/index.js";
import {$info, $infoMaps} from "../../models/infoModel/index.js";
import Table from "antd/es/table";
import {useEffect, useState} from "react";
import Card from "antd/es/card";
import Input from "antd/es/input";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Divider from "antd/es/divider";
import Form from "antd/es/form/index.js";

const statusOpt = [
    {
        label: 'Все',
        value: 'all',
    },
    {
        label: 'Да',
        value: true,
    },
    {
        label: 'Нет',
        value: false,
    },
]

const filterData = (data, filters) => {
    return data.filter(item => {
        return Object.keys(filters).every(key => {
            if (key === 'name_ru' && filters[key]) {
                return item[key].toLowerCase().includes(filters[key].toLowerCase());
            }
            if (key === 'active') {
                if (filters[key] === 'all') {
                    return item
                } else {
                    return item[key] === filters[key];
                }
            }
            return true;
        });
    });
};

export default function DictionaryRolePermissionList() {
    const {t} = useTranslation()
    const {data, count} = useUnit($rolePermissionList)
    const loading = useUnit(getRolePermissionListFx.pending)
    const onDelete = useUnit(deleteRolePermissionEv)
    const onCreate = useUnit(submitCreateRolePermissionEv)
    const {search} = searchAndActions()

    const [filterForm] = Form.useForm()

    const [rolePermData, setRolePermData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isFilter, setIsFilter] = useState(false);
    const [isGoRole, setIsGoRole] = useState(false);

    const permData = useStoreMap($info, item => item.permission)
    const roleId = parseInt(search.toString().split('role_id=')[1])
    const permWithRole = data.map(item => {
        return {
            perm_id: item.permission_id,
            rolePermId: item.id
        }
    })


    const createdId = useUnit($createdRolePermissionId)

    useEffect(() => {
        if (permData?.length > 0 && rolePermData.length === 0) {
            const updatedPermData = permData?.map(item => {
                const matchingRole = permWithRole?.find(role => role.perm_id === item.id);
                return matchingRole
                    ? { ...item, active: true, rolePermId: matchingRole.rolePermId }
                    : { ...item, active: false,  rolePermId: null};
            });
            setRolePermData(updatedPermData)
        } else if (isGoRole === false) {
            const updatedPermData = permData?.map(item => {
                const matchingRole = permWithRole?.find(role => role.perm_id === item.id);
                return matchingRole
                    ? { ...item, active: true, rolePermId: matchingRole.rolePermId }
                    : { ...item, active: false,  rolePermId: null};
            });
            setRolePermData(updatedPermData)
            setIsGoRole(true)
        }
    }, [data, permData]);

    useEffect(() => {
        if (rolePermData?.length > 0) {
            const newData = rolePermData?.map(item => {
                if (item.id === createdId?.permission_id) {
                    return {...item, active: true, rolePermId: createdId.newId}
                } else {
                    return item
                }
            })
            setRolePermData(newData);
        }
    }, [createdId]);

    const onCreateDeletePerm = (row) => {
        if (row.rolePermId === null) {
            onCreate({
                role_id: roleId,
                permission_id: row.id
            })

            const newData = rolePermData?.map(item => {
                if (item.id === row.id) {
                    return {...item, active: true, rolePermId: 1000+Math.random()}
                } else {
                    return item
                }
            })
            setRolePermData(newData);
        } else {
            onDelete(row?.rolePermId)
            const newData = rolePermData?.map(item => {
                if (item.id === row.id) {
                    return {...item, active: false, rolePermId: null}
                } else {
                    return item
                }
            })
            setRolePermData(newData);
        }
    }




    const columns = [
        {
            title: t('Разрешено'),
            width: '5%',
            align: 'center',
            dataIndex: 'id',
            sorter: (a, b) => a.active - b.active,
            key: 'actions',
            // defaultSortOrder: 'descend',

            render: (id, row) => <>
                <Switch
                    checked={row.active === true ? true : false}
                    onChange={() => onCreateDeletePerm(row)}
                />
            </>
        },
        {
            title: t('Действие в системе'),
            dataIndex: 'id',
            sorter: (a, b) => a?.name_ru?.localeCompare(b?.name_ru),
            key: 'id',
            defaultSortOrder: 'ascend',
            render: (permission_id) => <MappedDictItem id={permission_id} type={'permission'} link={false} withID={false}/>
        },

    ]

    const onSearch = (payload) => {
        setFilteredData(filterData(rolePermData, payload))
    }

    return <>
        <RolePermissionListGate search={search.toString()}/>
        <Card bordered={false} loading={loading}>
                <Form form={filterForm}
                      size={'small'}
                      layout={'vertical'}
                      onFinish={(v) => onSearch(v)}
                      validateTrigger={'onBlur'}
                >
                    <Row gutter={[16, 16]} >
                        <Col span={6}>
                            <Form.Item name={'name_ru'}
                                       label={t('Название действия')}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name={'active'}
                                       label={t('Разрешено')}
                            >
                                <Select
                                    options={statusOpt}
                                    showSearch
                                    defaultValue={null}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row style={{alignItems: 'center', gap: 16}}>
                        <Button
                            type={'primary'}
                            onClick={() => {
                                filterForm.submit()
                                setIsFilter(true)
                            }}
                            size={'small'}
                        >
                            {t('Применить фильтр')}
                        </Button>
                        <Button onClick={() => {
                            filterForm.resetFields()
                            setFilteredData([])
                            setIsFilter(false)
                        }}
                                size={'small'}>
                            {t('Очистить фильтр')}
                        </Button>
                    </Row>

                </Form>

        </Card>
        <Divider />
        <Table
            dataSource={isFilter === true ? filteredData : rolePermData}
            columns={columns}
            loading={!window.location.href.includes('role_id=')}
            pagination={{
                pageSize: 100,
                position: ['bottomCenter']
            }}

        />
    </>
}
