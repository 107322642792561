import Button from "antd/es/button";
import {useUnit} from "effector-react";
import {useTranslation} from "react-i18next";
import {prepareValues} from "../../../components/FiltersBlock/FiltersBlock.jsx";
import {
	$chosenSecAddress,
	$secAddressList, addSecAddressEv,
	getSecAddressesListEv
} from "../../../models/dictionaryAddressesModel/index.js";
import Table from "antd/es/table";
import {$dictsSelects, $infoMaps} from "../../../models/infoModel";
import Divider from "antd/es/divider";
import {DatePicker, Input, Row, Select} from "antd";
import {paginationConfig} from "../../../utils/paginationConfig.js";
import Form from "antd/es/form";
import queryString from "query-string";
import {filterOption} from "../../../utils/selectUtils.jsx";


export default function SelectSecondaryAddress({chosenTown, chosenDistr, resetMode, afterSubmit}){

	const {t} = useTranslation()
	const {count, data} = useUnit($secAddressList)
	const maps = useUnit($infoMaps)
	const [searchForm] = Form.useForm()
	const selectOpts = useUnit($dictsSelects)
	const getSecAddressesList = useUnit(getSecAddressesListEv)
	const secAddress = useUnit($chosenSecAddress)
	const addSecAddress = useUnit(addSecAddressEv)

	const selectObj = (address) => {
		addSecAddress(address)
		afterSubmit()
	}

	const onFinish = (values) => {
		getSecAddressesList(queryString.stringify(prepareValues({
			...values,
			town_id: chosenTown,
			town_distr_id: chosenDistr
		})))
	}

	const onReset = () => {
		searchForm.resetFields()
	}

	const onTableChange = (pagination) => {
		getSecAddressesList(queryString.stringify(prepareValues({
			...searchForm.getFieldsValue([
				'street_id',
				'actual_from',
				'house',
				'corpus',
				'is_actual',
			]),
			town_id: chosenTown,
			town_distr_id: chosenDistr,
			page: pagination.current,
			limit: pagination.pageSize
		})))
	}

	const columns = [
		{
			title: t('Город, район, улица'),
			key: 'address',
			render: (record) => `
              г. ${maps.townsMap[record.town_id]},
              ${record.town_distr_id ? `${maps.districtsMap[record.town_distr_id]},` : ''}
              ${maps.streetsMap[record.street_id]},
              дом ${record.house},
              корпус ${record.corpus}
            `
		},
		{
			key: 'actions',
			align: 'center',
			width: '10%',
			render: (record) => <Button disabled={secAddress.find(item => item.id === record.id)} type={'primary'} onClick={() => selectObj(record)}>{t('Выбрать')}</Button>
		}
	]

	return <>
		<Form
			layout={'vertical'}
			form={searchForm}
			onFinish={onFinish}
		>
			<Row style={{gap: 10}}>
				<Form.Item name={'street_id'} label={t('Улица')}>
					<Select
						options={
							selectOpts?.streets?.filter(item => item?.town_id === chosenTown)
						}
						style={{width: '12.8rem'}}
						// onChange={(v) => onChangeFilter(v, i)}
						dropdownStyle={{width: 300}}
						showSearch filterOption={filterOption}
						allowClear
					/>
				</Form.Item>
				<Form.Item name={'house'} label={t('Номер дома')}>
					<Input />
				</Form.Item>
				<Form.Item name={'corpus'} label={t('Номер корпуса')}>
					<Input />
				</Form.Item>
				<Form.Item name={'is_actual'} label={t('Актуален')} valuePropName={'checked'} >
					<Select
						options={
							selectOpts?.is_actual
						}
						style={{width: '12.8rem'}}
						// onChange={(v) => onChangeFilter(v, i)}
						dropdownStyle={{width: 300}}
						showSearch filterOption={filterOption}
						allowClear
					/>
				</Form.Item>
				<Form.Item name={'actual_from'} label={t('Актуален с')}>
					<DatePicker.RangePicker placeholder={t('Укажите период')}/>
				</Form.Item>
			</Row>
			<Row style={{alignItems: 'center', gap: 16}}>
				<Button type={'primary'} onClick={searchForm.submit}>
					{t('Применить фильтр')}
				</Button>
				<Button onClick={onReset}>
					{t('Очистить фильтр')}
				</Button>
				<Button onClick={resetMode}>
					{t('Отменить создание вспомогательного адреса')}
				</Button>
			</Row>
		</Form>
		<Divider/>
		{/*{*/}
		{/*	selectedAddresses.length > 0 ?*/}
		{/*		<div>*/}
		{/*			<div>{t('Выбранные')}:</div>*/}
		{/*			<div>*/}
		{/*				{*/}
		{/*				selectedAddresses.map((address, index) => {*/}
		{/*					return <div key={`sec-address-${index}`} className={style.chosen_address}>*/}
		{/*						{*/}
		{/*							`*/}
		{/*								г. ${maps.townsMap[address.town_id]},*/}
		{/*								${address.town_distr_id ? `${maps.districtsMap[address.town_distr_id]},` : ''}*/}
		{/*								${maps.streetsMap[address.street_id]},*/}
		{/*								дом ${address.house},*/}
		{/*								корпус ${address.corpus}*/}
		{/*							`*/}
		{/*						}*/}
		{/*						<Popconfirm*/}
		{/*							title={t('Вы уверены, что хотите удалить этот адрес?')}*/}
		{/*							onConfirm={() => deletedSelected(address.id)}*/}
		{/*						>*/}
		{/*							<Button danger icon={<CloseCircleOutlined />}/>*/}
		{/*						</Popconfirm>*/}
		{/*					</div>*/}
		{/*				})*/}
		{/*				}*/}
		{/*			</div>*/}
		{/*			<Divider/>*/}
		{/*		</div>*/}
		{/*		:*/}
		{/*		''*/}
		{/*}*/}
		{
			data.length > 0 ?
				<Table
					rowKey={'id'}
					dataSource={data}
					columns={columns}
					pagination={{
						...paginationConfig,
						pageSize: 5,
						// current: parseInt(curPage),
						total: count
					}}
					onChange={onTableChange}
					size={"small"}
				/>
				:
				''
		}
	</>
}
