import {useNavigate, useParams} from 'react-router-dom';
import {$selectedFile, ViewEmailApproveFileGate} from "../../../models/emailSubApproveModel/index.js";
import {useUnit} from "effector-react";
import ViewDictionaryItemCommon from "../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import styles from "../../UploadAccounts/Upload.module.css";
import {CheckOutlined} from "@ant-design/icons";
import MappedDictItem from "../../../components/MappedDictItem/MappedDictItem.jsx";
import {
	$selectedByIdFile,
	approveFileEv, approveFileFx, disapproveFileFx, disaproveFileEv,
	ViewFilesGate
} from '../../../models/taskPdfToControllersModel/index.js';
import Card from "antd/es/card";
import Table from "antd/es/table";
import Button from 'antd/es/button';
import {Popconfirm} from "antd";

export default function ViewEmailApproveFile (){
    const navigate = useNavigate()
	const {id} = useParams()
	const {t} = useTranslation()

	const {data} = useUnit($selectedFile)
	const selectedFile = useUnit($selectedByIdFile)
    const accept = useUnit(approveFileEv)
	const approveLoading = useUnit(approveFileFx.pending)
    const reject = useUnit(disaproveFileEv)
	const disapproveLoading = useUnit(disapproveFileFx.pending)


	const items = [

		{
			key: 'total_rows',
			label: t('Всего строк'),
			children: selectedFile?.total_row_count
		},
		{
			key: 'total_errors',
			label: t('Всего ошибок'),
			children: selectedFile?.total_errors
		},
		{
			key: 'file_extention',
			label: t('Расширение'),
			children: selectedFile?.file_extention
		},

	]
	const dateItems = [
		{
			key: 'load_date',
			label: t('Дата загрузки'),
			children: selectedFile?.created_at && dayjs(selectedFile?.created_at).format('DD.MM.YYYY HH:mm:ss')
		},
		{
			key: 'to_list',
            children: <Button
                onClick={() => navigate("../..", {relative: "path", replace: true})}
            >{t('К списку')}</Button>
		},
        {
            key: 'confirm',
            children: <Popconfirm
                title={t('Вы уверены, что хотите подтвердить этот файл?')}
                onConfirm={() => {
                    accept(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
            >
                <Button type={'primary'}>{t('Подтвердить файл')}</Button>
            </Popconfirm>
        },
        {
            key: 'cancel',
            children: <Popconfirm
                title={t('Вы уверены, что хотите отклонить этот файл?')}
                onConfirm={() => {
                    reject(id)
                    navigate("../..", {relative: "path", replace: true})
                }}
                okText={t('Да')}
                okButtonProps={{danger: true}}
            >
                <Button danger>{t('Отклонить файл')}</Button>
            </Popconfirm>
        },
	]


	const columns = [
		{
			title: t('Статус'),
			dataIndex: 'row_is_valid',
			align: 'center',
			key: 'row_is_valid',
			sorter: true,
			onCell: (record) => ({className: record?.row_error && styles.invalid}),
			render: (value, record) => record?.row_error ?
				record?.row_error :
				<CheckOutlined />
		},
		// {
		// 	title: t('Код файла'),
		// 	dataIndex: 'import_file_id',
		// 	align: 'center',
		// 	key: 'import_file_id',
		// 	sorter: true,
		// },
		{
			title: t('Код ЛС'),
			dataIndex: 'account_id_value',
			align: 'center',
			key: 'account_id_value',
			sorter: true,
			onCell: (record) => ({className: record?.account_id_error && styles.invalid}),
			render: (value, record) => record?.account_id_error ?
				record?.account_id_error :
				value
		},
		{
			title: t('Дата подтверждения'),
			dataIndex: 'approved_at_value',
			align: 'center',
			key: 'approved_at_value',
			sorter: true,
			onCell: (record) => ({className: record?.approved_at_error && styles.invalid}),
			render: (value, record) => record?.approved_at_error ?
				record?.approved_at_error :
				value
		},

		// {
		// 	title: t('Код Кем подтверждено'),
		// 	dataIndex: 'approved_by_value',
		// 	align: 'center',
		// 	key: 'approved_by_value',
		// 	sorter: true,
		// 	onCell: (record) => ({className: record?.approved_by_error && styles.invalid}),
		// 	render: (value, record) => record?.approved_by_error ?
		// 		record?.approved_by_error :
		// 		 <MappedDictItem id={parseInt(value)} type={'users'}/>
		// },
		{
			title: t('Дата создания'),
			dataIndex: 'created_at_value',
			align: 'center',
			key: 'created_at_value',
			sorter: true,
			onCell: (record) => ({className: record?.created_at_error && styles.invalid}),
			render: (value, record) => record?.created_at_error ?
				record?.created_at_error :
				value
		},
		{
			title: t('E-mail'),
			dataIndex: 'email_value',
			align: 'center',
			key: 'email_value',
			sorter: true,
			onCell: (record) => ({className: record?.email_error && styles.invalid}),
			render: (value, record) => record?.email_error ?
				record?.email_error :
				value
		},
		{
			title: t('Отправитель'),
			dataIndex: 'sender_name_value',
			align: 'center',
			key: 'sender_name_value',
			sorter: true,
			onCell: (record) => ({className: record?.sender_name_error && styles.invalid}),
			render: (value, record) => record?.sender_name_error ?
				record?.sender_name_error :
				value
		},
		{
			title: t('Источник'),
			dataIndex: 'source_value',
			align: 'center',
			key: 'source_value',
			sorter: true,
			onCell: (record) => ({className: record?.source_error && styles.invalid}),
			render: (value, record) => record?.source_error ?
				record?.source_error :
				value
		},

	]

	return <>
		<ViewEmailApproveFileGate id={id}/>
		<ViewFilesGate id={id}/>
		<Card loading={approveLoading || disapproveLoading}>
			<ViewDictionaryItemCommon showNav={false} items={items} itemsDates={dateItems}/>
			<br/>
			<Table dataSource={data}
				   columns={columns}
				   size={'small'}
				   className={'table-container'}
			/>
		</Card>
	</>
}
