import {createEffect} from 'effector/compat';
import api from '../../api/api.js';
import queryString from 'query-string';

export const getAccountsFx = createEffect()
.use(async (search) => {
    const params = queryString.stringify(search)
    return (await api().get(`/accounts/${params.length > 0 ? `?${params}` : ''}`)).data
})

export const createTaskFx = createEffect()
.use(async (data) => {
    return (await api().post(`/dispatch/email`, data)).data
})
