import {createEffect} from 'effector/compat';
import api from '../../api/api.js';
import queryString from 'query-string';
import dayjs from 'dayjs';

export const getControllersFx = createEffect()
.use(async (search) => {
    const params = queryString.stringify(search)
    return (await api().get(`/inspectors/${params.length > 0 ? `?${params}` : ''}`)).data
})

export const createTaskFx = createEffect()
.use(async (data) => {
    return (await api().post(`/dispatch/print`, data)).data
})

export const downloadFilesPerControllersFx = createEffect()
    .use(async ({ids, filters, report_date}) => {
        const file = (await api().post(`/dispatch/download_inspector_files_zip`, {inspector_ids: ids, filters, report_date}, {responseType: 'blob'}))
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = `Файлы по контролерам (${ids.join(', ')}) от ${dayjs(report_date).format('DD.MM.YYYY')}.zip`
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const downloadSingleFileFx = createEffect()
.use(async id => {
    const file = (await api().get(`/dispatch/download_inspector_file/?file_id=${id}`, {responseType: 'blob'}))
    const link = document.createElement('a')
    const url = URL.createObjectURL(file.data)
    link.href = url
    link.target = '_blank'
    link.download = `Файл по контролеру ${id}.pdf`
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
})

export const uploadFileFx = createEffect()
.use(async ({table_name, data}) => {
  const payload = new FormData()
  data.files.forEach(file => {
    payload.append('files', file?.originFileObj)
  })
  const config = {headers: {'Content-Type': 'multipart/form-data'}}

  return (await api().post(`/import_file/import/${table_name}`, payload, config)).data
})

export const getFileListFx = createEffect()
    .use(async (data) => {
        return (await api().get(`/import_file/?table_name=${data.table_name}&${data.search}`)).data
    })


export const approveFileFx = createEffect()
    .use(async (data) => {
        return (await api().get(`/import_file/confirm/${data}`)).data
    })

export const disapproveFileFx = createEffect()
    .use(async (data) => {
        return (await api().get(`/import_file/cancel/${data}`)).data
    })

export const getFileInfoFx = createEffect()
    .use(async (id) => (await api().get(`/import_file/${id}`)).data)


export const stopImportTaskFx = createEffect()
    .use(async (id) => {
        return (await api().post(`/import_file/stop_import/${id}`)).data
    })

export const stopConfirmTaskFx = createEffect()
    .use(async (id) => {
        return (await api().post(`/import_file/stop_confirm/${id}`)).data
    })
