import {createEffect} from 'effector';
import api from '../../api/api.js';

export const getCorrectionListFx = createEffect()
    .use(async (filters) => (await api().get(`/correction/${filters.length > 0 ? `?${filters}` : ''}`)).data)


export const getCorrectionByIdFx = createEffect()
    // .use(async (filters) => (await api().get(`/correction/${filters.length > 0 ? `?${filters}` : ''}`)).data)
    .use(async (filters) => {
        // debugger
        return await api().get(`/correction/${filters > 0 ? `${filters}` : ''}`)
    })

export const createCorrectionFx = createEffect()
    .use(async (data) => (await api().post(`/correction/`, data)).data)

export const cancelCorrectionFx = createEffect()
    .use(async (id) => {
        return await api().post(`/correction/cancel?correction_id=${id}`)
    })

export const finishCorrectionFx = createEffect()
    .use(async (id) => {
        return await api().post(`/correction/finish?correction_id=${id}`)
    })

export const deleteCorrectionFx = createEffect()
    .use(async (id) => (await api().delete(`/correction/${id}`)).data)

export const rollbackCorrectionFx = createEffect()
    .use(async (id) => (await api().post(`/correction/rollback?correction_id=${id}`)).data)


export const getPSTarifsFx = createEffect()
    .use(async ({provider_id, service_id}) => (await api().get(
        `/provider_service/?provider_id=${provider_id}&service_id=${service_id}&ignore_limit=true`
    )).data)

export const getAccsFx = createEffect()
    .use(async (filters) => (await api().get(`/correction_aps/account_list?${filters}`)).data)

export const getPrefsSelectsFx = createEffect()
    .use(async () => (await api().get(`/provider_service_preference/groupped`)).data)

export const submitTempCorrectionFx = createEffect()
    .use(async payload => (await api().post(`/correction_aps/create_aps`, payload)).data)

export const submitAccountTempCorrFx = createEffect()
    .use(async payload => (await api().post(`/operations/`, payload)).data)

export const getCorrectionHistoryFx = createEffect()
    .use(async ({id, table}) => (await api().get(`/correction_aps/history?correction_id=${id}${table?.length > 0 ? `&${table}` : ''}`)).data)

export const downloadHistoryFx = createEffect()
    .use(async (id) => {
        const file = await api().get(`/correction_aps/download_history?correction_id=${id}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const uploadCorrFx = createEffect()
    .use(async ({selPeriod, formValues}) => {
        const payload = new FormData()
        formValues.files.forEach(file => {
            payload.append('files', file?.originFileObj)
        })
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }

        const values = `correction_id=${formValues.id}&copy_square=${formValues.copy_square}&copy_quantity=${formValues.copy_quantity}&period=${selPeriod}`
        return (await api().post(`/correction_load_file/load_files?${values}`, payload, config)).data
    })

export const getUploadCorrMetaFx = createEffect()
    .use(async (id) => (await api().get(`/correction_load_file/${id}`)).data)

export const downloadCorrUploadErrorsFx = createEffect()
    .use(async (id) => {
        const file = await api().get(`/correction_load_file/download_errors/${id}`, {responseType: 'blob'})
        const link = document.createElement('a')
        const url = URL.createObjectURL(file.data)
        link.href = url
        link.target = '_blank'
        link.download = file.headers["content-disposition"].split('filename=')[1].replace(/"/gm, '')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    })

export const uploadCorrConfirmFx = createEffect()
    .use(async (id) => (await api().post(`/correction_load_file/confirm_valid/${id}`)).data)

export const uploadCorrCancelFx = createEffect()
    .use(async (id) => (await api().post(`/correction_load_file/cancel_not_valid/${id}`)).data)
