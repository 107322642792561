import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import {useStoreMap} from 'effector-react';
import {$infoMaps} from '../../models/infoModel/index.js';
import Button from 'antd/es/button';
import Tooltip from "antd/es/tooltip";

export default function ShowAddressCard({formated, raw, title, textOnly = false, button, buttonConfig}) {
    const {t} = useTranslation()
    const maps = useStoreMap($infoMaps, info => ({
        towns: info.townsMap,
        distrs: info.districtsMap,
        streets: info.streetsMap
    }))

    if (textOnly) {
        return formated
            ? formated
            : `${raw?.town_id ? `г. ${maps?.towns?.[raw?.town_id] ?? ''},` : ''} ${raw?.towd_distr_id ? `(${maps?.distrs?.[raw?.towd_distr_id] ?? ''}),` : ''} ${raw?.street_id ? `${maps?.streets?.[raw?.street_id] ?? ''}, ` : ''} ${raw?.house ? `${t('дом')} ${raw?.house}` : ''} ${raw?.corpus ? `, корпус ${raw?.corpus}` : ''}`
    } else {
        let buttonComponent = null
        const title = (buttonConfig || {}).title
        const tooltip = (buttonConfig || {}).tooltip
        if (buttonConfig) {
            delete buttonConfig.title
            delete buttonConfig.tooltip
        }
        if (button) {
            buttonComponent = <Button {...buttonConfig}>{title}</Button>
        }
        if (tooltip) {
            buttonComponent = <Tooltip title={tooltip}>{buttonComponent}</Tooltip>
        }
        return <Card title={title} size={'small'}
                     extra={buttonComponent}
        >
            {formated
                ? formated
                : `${raw?.town_id ? `г. ${maps?.towns?.[raw?.town_id] ?? ''},` : ''} ${raw?.towd_distr_id ? `(${maps?.distrs?.[raw?.towd_distr_id] ?? ''}),` : ''} ${raw?.street_id ? `${maps?.streets?.[raw?.street_id] ?? ''}, ` : ''} ${raw?.house ? `${t('дом')} ${raw?.house}` : ''} ${raw?.corpus ? `, корпус ${raw?.corpus}` : ''}`
            }
        </Card>
    }
}
