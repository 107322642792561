import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const ServicesListGate = createGate()
export const ServicesViewGate = createGate()
export const ServicesEditGate = createGate()
export const ServicesCreateGate = createGate()

export const submitCreateServicesEv = createEvent()
export const submitEditServicesEv = createEvent()
export const deleteServicesEv = createEvent()
export const getServiceEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()

export const setServiceTypeEmbedded = createEvent()
