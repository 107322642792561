import {useTranslation} from 'react-i18next';
import Button from 'antd/es/button';
import ButtonGroup from 'antd/es/button/button-group';
import Tooltip from 'antd/es/tooltip';
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    EditOutlined,
    EyeOutlined, RollbackOutlined,
    StopOutlined,
} from '@ant-design/icons';
import {useUnit} from 'effector-react';
import FilterBlock from '../../components/FiltersBlock/FiltersBlock.jsx';
import {Link, useNavigate} from 'react-router-dom';
import CreatedBy from '../../components/CreatedBy/CreatedBy.jsx';
import DictionaryProTable from "../../components/DictionaryProTable/DictionaryProTable.jsx";
import CustomDates from "../../components/CustomDates/CustomDates.jsx";
import {searchAndActions} from "../../utils/searchAndActions.js";
import {
    $correctionList,
    CorrectionListGate,
    getCorrectionListFx,
    $createdCorrectionId,
    resetCreatedCorrectionEv, createCorrectionFx
} from '../../models/dictionaryCorrectionModel/index.js';
import {useEffect} from "react";
import {$userPerms} from '../../models/infoModel/index.js';


export default function DictionaryCorrectionList() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {data, count} = useUnit($correctionList)
    const loading = useUnit(getCorrectionListFx.pending)
    const createLoading = useUnit(createCorrectionFx.pending)
    const resetCreated = useUnit(resetCreatedCorrectionEv)
    const perms = useUnit($userPerms)

    const createdCorrection = useUnit($createdCorrectionId)

    const statusOpts = [
        {label: t('Отменена'), value: 'cancelled'},
        {label: t('В работе'), value: 'process'},
        {label: t('Завершена'), value: 'success'},
        {label: t('Откат'), value: 'rollback'},
    ]

    useEffect(() => {
        if (createdCorrection) {
            navigate(`/corrections/edit/${createdCorrection}`)
        }
        return () => resetCreated()
    }, [createdCorrection]);

    const {search, exportXlsAction} = searchAndActions()



    const columns = [
        {
            title: t(`Код`),
            dataIndex: 'id',
            sorter: true,
            key: 'id',
            width: '1%'
        },
        // {
        //     title: t(`Объекты`),
        //     dataIndex: 'object_type',
        //     sorter: true,
        //     key: 'object_type',
        //     render: (value, row) => {
        //         if (row.mass === true) {
        //             return <>
        //                 <MappedDictItem id={row.provider_id} type={'providers'} />
        //                 <br/>
        //                 <MappedDictItem id={row.service_id} type={'services'} />
        //             </>
        //         } else {
        //             return <Link to={`/dictionaries/accounts/view/${row?.account_id}`}>{row?.account_id} </Link>
        //         }
        //     }
        // },
        {
            title: t(`Обоснование`),
            dataIndex: 'justification',
            sorter: true,
            width: '50%',
            key: 'justification',
        },
        {
            title: t(`Статус`),
            dataIndex: 'status',
            sorter: true,
            key: 'status',
            width: '2%',
            align: 'center',
            render: (status) => {
                if (status === 'cancelled') {
                    return <Tooltip title={t('Отменена')}>
                        <StopOutlined style={{color: 'red', fontSize: 20}}/>
                    </Tooltip>
                } else if (status === 'process') {
                    return <Tooltip title={t('В процессе')}>
                        <ClockCircleOutlined style={{fontSize: 20}}/>
                    </Tooltip>
                } else if (status === 'success') {
                    return <Tooltip title={t('Завершена')}>
                        <CheckCircleOutlined style={{color: 'limegreen', fontSize: 20}}/>
                    </Tooltip>
                } else  if (status === 'rollback') {
                    return <Tooltip title={t('Откат')}>
                        <RollbackOutlined style={{color: 'orangered', fontSize: 20}}/>
                    </Tooltip>
                } else return status
            }
        },
        {
            title: t('Создан'),
            key: 'created',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'created_at',
                    width: '10%',
                    key: 'created_at',
                    sorter: true,
                    // defaultSortOrder: 'descend',
                    render: (date) => <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'created_by',
                    width: '10%',
                    key: 'created_by',
                    sorter: true,
                    render: (id) => <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Обновлен'),
            key: 'updated',
            children: [
                {
                    title: t('Дата'),
                    dataIndex: 'updated_at',
                    key: 'updated_at',
                    width: '10%',
                    sorter: true,
                    render: (date) => date && <CustomDates data={date} />
                },
                {
                    title: t('Кем'),
                    dataIndex: 'updated_by',
                    width: '10%',
                    sorter: true,
                    key: 'updated_by',
                    render: (id) => id && <CreatedBy id={id}/>
                }
            ]
        },
        {
            title: t('Действия'),
            width: 1,
            align: 'center',
            dataIndex: 'id',
            key: 'actions',
            render: (id,row) => <ButtonGroup>
                {perms?.corrections?.view && <Tooltip title={t('Просмотр изменений')} placement={'top'}>
                    <Link to={`/corrections/view/${id}`}>
                        <Button icon={<EyeOutlined/>}/>
                    </Link>
                </Tooltip>}
                {row?.status === 'process' && perms?.corrections?.update && <Tooltip title={t('Редактировать')} placement={'top'}>
                    <Link to={`/corrections/edit/${id}`}>
                        <Button icon={<EditOutlined />} type={'primary'} />
                    </Link>
                </Tooltip>
                }
            </ButtonGroup>
        }
    ]

    const filters = [
        {name: 'status', label: t('Статус'), type: 'select', options: statusOpts, multiple: true},
        {name: 'created_by', label: t('Кем создан'), type: 'select', dict: 'users', multiple: true},
        {name: 'created_at', label: t('Дата создания'), type: 'range'},
        {name: 'updated_by', label: t('Кем обновлен'), type: 'select', dict: 'users', multiple: true},
        {name: 'updated_at', label: t('Дата обновления'), type: 'range'},
    ]


    return <>
        <CorrectionListGate search={search}/>
        <FilterBlock items={filters}/>
        <DictionaryProTable
            data={data}
            hiddenActions={['create']}
            count={count}
            loading={loading}
            initColumns={columns}
            exportXlsAction={exportXlsAction}
            createLoading={createLoading}
        />
    </>
}
