import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const PositionListGate = createGate()
export const PositionViewGate = createGate()
export const PositionEditGate = createGate()
export const PositionCreateGate = createGate()

export const submitCreatePositionEv = createEvent()
export const submitEditPositionEv = createEvent()
export const deletePositionEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
