import {
    $afterCreateAction,
    $createdEPDTemplateFieldId,
    $editEPDTemplateFieldStatus,
    $selectedEPDTemplateField,
    $epdTemplateFieldList
} from './stores.js';
import {getEPDTemplateFieldListFx, getEPDTemplateFieldByIdFx} from './effects.js';
import {
    deleteEPDTemplateFieldEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    EPDTemplateFieldEditGate,
    EPDTemplateFieldListGate,
    EPDTemplateFieldViewGate,
    submitCreateEPDTemplateFieldEv,
    submitEditEPDTemplateFieldEv, EPDTemplateFieldCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateEPDTemplateFieldEv, response: triggerSuccessOpsEv}, reset: EPDTemplateFieldCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditEPDTemplateFieldEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteEPDTemplateFieldEv, response: triggerSuccessOpsEv})

$epdTemplateFieldList.on(getEPDTemplateFieldListFx.doneData, (_, data) => data)
    .reset(EPDTemplateFieldListGate.close)

$selectedEPDTemplateField.on(getEPDTemplateFieldByIdFx.doneData, (_, data) => data)
    .reset([EPDTemplateFieldEditGate.close, EPDTemplateFieldViewGate.close])
// FIXME
$createdEPDTemplateFieldId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "epd_template_fields")
    return data ? data?.value?.id : undefined
})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editEPDTemplateFieldStatus.on(editCompleteEv, () => true)
    .reset(EPDTemplateFieldEditGate.close)

sample({
    clock: [EPDTemplateFieldEditGate.state, EPDTemplateFieldViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getEPDTemplateFieldByIdFx
})

sample({
    clock: EPDTemplateFieldListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getEPDTemplateFieldListFx
})

sample({
    source: EPDTemplateFieldListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getEPDTemplateFieldListFx
})

sample({
    clock: submitCreateEPDTemplateFieldEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const template_data = {
            name_kz: payload.name_kz,
            name_ru: payload.name_ru,
        }
        if (payload.template_id < 0) {
            actions.push({operation: 'create', type: 'epd_templates', value: {...template_data, id: idCounter}})
            payload.template_id = idCounter
            idCounter--
        }
        delete payload?.name_kz
        delete payload?.name_ru

        const data = {
            ...payload,
            font_size: parseInt(payload.font_size),
            column_number: parseInt(payload.column_number),
        }

        actions.push({operation: 'create', type: 'epd_template_fields', value: {...data, id: idCounter}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedEPDTemplateField,
    clock: submitEditEPDTemplateFieldEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        actions.push({operation: 'update', type: 'epd_template_fields', value: {object_id: current.id, ...changedFields}})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteEPDTemplateFieldEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'epd_template_fields', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
