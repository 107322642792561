import {useTranslation} from 'react-i18next';
import Card from 'antd/es/card';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Button from 'antd/es/button';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import Switch from 'antd/es/switch';
import {
    $reoList,
    $selectedReo,
    changeSelectedREOEv,
    createTaskEv, getREOListFx,
    TaskEPDPerREOGate, changeFiltersEv, $filters
} from '../../../models/taskEPDPerRESModel';
import {useUnit} from 'effector-react';
import DictionaryProTable from '../../../components/DictionaryProTable/DictionaryProTable.jsx';
import {$infoMaps} from '../../../models/infoModel/index.js';
import REOFilters from './REOFilters.jsx';
import Space from 'antd/es/space';

function getSelectedObjectAddress (id, data, maps) {
    const obj = data.find(d => d.id === id)
    return `г. ${maps.townsMap[obj.town_id]}, ${obj.towd_distr_id ? `(${maps.districtsMap[obj.towd_distr_id]}),` : ''} ${maps.streetsMap[obj.street_id]}, ${obj.house}, ${obj.corpus ? `корпус ${obj.corpus}` : ''}`
}

export default function CreateTaskPerREO() {
    const {t} = useTranslation()

    const {data, count} = useUnit($reoList)
    const loading = useUnit(getREOListFx.pending)
    const onChangeSelect = useUnit(changeSelectedREOEv)
    const selected = useUnit($selectedReo)
    const createTask = useUnit(createTaskEv)

    const filters = useUnit($filters)
    const changeFilters = useUnit(changeFiltersEv)

    const maps = useUnit($infoMaps)

    const columns = [
        {
            title: t('Код'),
            dataIndex: 'id',
            width: '5%',
            key: 'id',
        },
        {
            title: t('Адрес'),
            key: 'address',
            width: '80%',
            render: (record) => `г. ${maps.townsMap[record.town_id]}, ${record.towd_distr_id ? `(${maps.districtsMap[record.towd_distr_id]}),` : ''} ${maps.streetsMap[record.street_id]}, ${record.house}, ${record.corpus ? `корпус ${record.corpus}` : ''}`
        }
    ]

    const onCloseTag = (e) => {
        const data = selected.filter(item => item !== e)
        onChangeSelect(data)
    }

    const onCreateTask = () => {
        createTask()
        onChangeSelect([])

    }

    const onCreateTaskAll = () => {
        changeFilters({key: 'show_list_option', value: 'only_active'})

        createTask()
        onChangeSelect([])
    }


    return <>
        <TaskEPDPerREOGate />
        <Space direction={'vertical'}>
        <REOFilters />
        <Card>
            <Divider orientationMargin={0} orientation={'left'}>{t('Выбранные объекты недвижимости')}</Divider>
            <Row>
                {selected.length > 0
                    ? selected.map((i, idx) => <Tag
                            closable
                            onClose={(e) => {
                                e.preventDefault()
                                onCloseTag(i);
                            }}
                            key={idx}
                        >
                            {getSelectedObjectAddress(i, data, maps)}
                        </Tag>
                    )
                    : filters.select_all === true
                        ? t('Все объеты недвижимости') + ` (${count})`
                        : t('Нет')
                }
            </Row>
        </Card>
        <Card>


        <div style={{position: 'relative'}}>
            <Row
                style={{
                    position: 'absolute',
                    zIndex: 10,
                    top: '6px',
                    left: '25px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
                justify={'space-between'}
            >
                {
                    selected.length > 0 ?
                        <Popconfirm
                            title={'Вы уверены, что хотите выбрать всех? Ранее выбранные объекты сбросятся'}
                            okText={t('Да')}
                            placement={'left'}
                            cancelText={t('Нет')}
                            onConfirm={onCreateTaskAll}
                        >
                            <Button type={'primary'}
                                    style={{marginRight: '10px', marginBottom: '5px'}}
                            >
                                {t(`Сформировать ЕПД по всем отфильтрованным объектам (${count})`)}
                            </Button>
                        </Popconfirm>
                        :
                        <Button type={'primary'}
                                style={{marginRight: '10px', marginBottom: '5px'}}
                                onClick={onCreateTaskAll}
                        >
                            {t(`Сформировать ЕПД по всем отфильтрованным объектам (${count})`)}
                        </Button>
                }
                <Tooltip
                    title={!(filters?.select_all === true || selected.length > 0) ? t('Необходимо выбрать Объекты недвижимости') : null}>
                    <Button type={'primary'}
                            style={{marginRight: '10px', marginBottom: '5px'}}
                            onClick={onCreateTask}
                            disabled={!(filters?.select_all === true || selected.length > 0)}
                    >
                        {t('Сформировать PDF по выбранным объектам недвижимости')}
                    </Button>
                </Tooltip>

                <div style={{display: 'flex', gap: 4, marginLeft: 16}}>
                <span>Пересоздать PDF</span>
                <Switch value={filters.recreate_pdf} onChange={(checked) => changeFilters({
                    key: 'recreate_pdf',
                    value: checked
                })}/>
                </div>

            </Row>
            <DictionaryProTable
                style={{paddingTop: '30px'}}
                data={data}
                count={count}
                initColumns={columns}
                    loading={loading}
                    showActions={false}
                    rowKey={'id'}
                    rowSelection={{
                        onChange: onChangeSelect,
                        preserveSelectedRowKeys: true,
                        selectedRowKeys: selected
                    }}
                pagination={false}
                />
            </div>
        </Card>
        </Space>
    </>
}

