import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $editInspectorsStatus,
    $selectedInspectors, getInspectorsByIdFx,
    InspectorsEditGate,
    submitEditInspectorsEv
} from "../../../models/dictionaryInspectorsModel/index.js";
import StarredTitle from "../../../components/StarredTitle/StarredTitle.jsx";
import Select from "antd/es/select/index.js";
import {PlusOutlined} from "@ant-design/icons";
import CreateTownEmbeddedForm from "../../../components/Embedded/CreateTownEmbeddedForm/CreateTownEmbeddedForm.jsx";
import {$dictsSelects} from "../../../models/infoModel/index.js";
import {
    $deletedSectors, setCreateChiefInspectorEmbeddedEv,
    setCreateSectorEmbeddedEv,
    setCreateTownEmbeddedEv
} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import {$createdTownIdModal} from "../../../models/dictionaryDistrictsModel/index.js";
import SectorsTable from "../../../components/Embedded/SectorsTable/SectorsTable.jsx";
import CreateSectorEmbeddedForm
    from "../../../components/Embedded/CreateSectorEmbeddedForm/CreateSectorEmbeddedForm.jsx";
import CreateChiefInspectorEmbeddedForm
    from "../../../components/Embedded/CreateChiefInspectorEmbeddedForm/CreateChiefInspectorEmbeddedForm.jsx";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryInspectorsModel/index.js";

export default function EditInspectors() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedInspectors)

    const [inspectorsForm] = Form.useForm()

    const submit = useUnit(submitEditInspectorsEv)

    const loading = useUnit(getInspectorsByIdFx.pending)

    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const status = useUnit($editInspectorsStatus)
    const townsOpts = useStoreMap($dictsSelects, sel => sel.towns)
    const sectorOpts = useStoreMap($dictsSelects, sel => sel.sector)
    const chiefInspectorOpts = useStoreMap($dictsSelects, sel => sel.chief_inspector)
    const setTownEmbedded = useUnit(setCreateTownEmbeddedEv)
    const newTown = useUnit($createdTownIdModal)
    const setChiefInspectorEmbedded = useUnit(setCreateChiefInspectorEmbeddedEv)
    const setSectorEmbedded = useUnit(setCreateSectorEmbeddedEv)

    const deleted = useUnit($deletedSectors)
    const genitive_form = t(`breadcrumbs.genitive.inspectors`);

    console.log('deleted', deleted)



    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/inspectors?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            inspectorsForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, ['town_kz','name_kz', 'name_ru', 'sector_id', 'chief_inspector_id'])

    const onSubmit = (values) => {
        values['name_kz'] = values['name_ru']
        return submit(values)
    }

    return <>
        <InspectorsEditGate id={id}/>
            <Form form={inspectorsForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={onSubmit}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <Row justify={'space-between'} gutter={[24, 24]}>
                    <Col span={12}>
                        <Card title={<StarredTitle title={t('Населенный пункт')}/>} size={'small'}
                              style={{height: '100%'}} loading={loading}>
                            <Form.Item name={'town_id'}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['town_id']}
                            >
                                <Select
                                    options={[...townsOpts, {label: 'Создать', value: -1, emoji: true}]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setTownEmbedded(true)
                                        } else {
                                            inspectorsForm.resetFields(['town'])
                                            setTownEmbedded(false)
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                            }
                                        </Space>
                                    )}
                                />
                            </Form.Item>
                            <CreateTownEmbeddedForm form={inspectorsForm}/>

                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title={<StarredTitle title={t('Свойства Контролера')}/>} size={'small'}
                              style={{height: '100%'}} loading={loading}>
                            {/*<Form.Item name={'name_kz'}*/}
                            {/*           label={t(`ФИО ${genitive_form} (каз)`)}*/}
                            {/*           rules={[{required: true, message: t('Обязательное поле')}]}*/}
                            {/*           validateStatus={changedFields['name_kz']}*/}
                            {/*>*/}
                            {/*    <Input/>*/}
                            {/*</Form.Item>*/}
                            <Form.Item name={'name_ru'}
                                       label={t(`ФИО ${genitive_form}`)}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['name_ru']}
                            >
                                <Input/>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>

                <Row justify={'space-between'} gutter={[24, 24]}>
                    <Col span={12}>
                        <Card title={<StarredTitle title={t('Старший контролер')}/>} size={'small'}
                              style={{height: '100%'}} loading={loading}>
                            <Form.Item name={'chief_inspector_id'}
                                       rules={[{required: true, message: t('Обязательное поле')}]}
                                       validateStatus={changedFields['town_id']}
                            >
                                <Select
                                    options={[...chiefInspectorOpts, {label: 'Создать', value: -1, emoji: true}]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setChiefInspectorEmbedded(true)
                                        } else {
                                            inspectorsForm.resetFields(['town'])
                                            setChiefInspectorEmbedded(false)
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                            }
                                        </Space>
                                    )}
                                />
                            </Form.Item>
                            <CreateChiefInspectorEmbeddedForm form={inspectorsForm}/>

                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title={t('Участок')} size={'small'}
                              style={{height: '100%'}} loading={loading}>
                            <Form.Item name={'sector_id'}
                                       label={'Участок'}
                                       rules={[{required: false, message: t('Обязательное поле')}]}
                                       // validateStatus={changedFields['sector_id']}
                            >
                                <Select
                                    options={[...sectorOpts, {label: 'Создать', value: -1, emoji: true}]}
                                    onChange={(v) => {
                                        if (v === -1) {
                                            setSectorEmbedded(true)
                                        } else {
                                            // realEstateObjectsForm.resetFields(['sector_id'])
                                            setSectorEmbedded(false)
                                        }
                                    }}
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                    }
                                    optionRender={(option) => (
                                        <Space>
                                        <span role="img" aria-label={option.data.label}>
                                          {option?.data?.emoji ?
                                              <PlusOutlined style={{color: '#1677ff'}}/> : ''}
                                        </span>
                                            {
                                                option?.data?.emoji ?
                                                    <span style={{color: '#1677ff'}}>
                                                    {option?.data.label}
                                                </span> :
                                                    <span>
                                                    {option?.data.label}
                                                </span>
                                            }
                                        </Space>
                                    )}
                                />
                            </Form.Item>
                            <CreateSectorEmbeddedForm form={inspectorsForm}/>

                        </Card>
                    </Col>
                </Row>

            </Form>
        <br/>
            {/*<SectorsTable*/}
            {/*    parent={'inspector_id'}*/}
            {/*    parentId={+id}*/}
            {/*/>*/}



            <Row>
                <Space>
                    <Button danger onClick={() => {
                        inspectorsForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                inspectorsForm.submit()
                                setAction('edit')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                inspectorsForm.submit()
                                setAction('list')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и вернуться к списку')}
                    </Button>
                </Space>
            </Row>
    </>
}
