import {createGate} from 'effector-react';
import {createEvent} from 'effector';
import {getCalcTypeListFx} from "./effects.js";

export const CalcTypeListGate = createGate()
export const CalcTypeViewGate = createGate()
export const CalcTypeEditGate = createGate()
export const CalcTypeCreateGate = createGate()

export const submitCreateCalcTypeEv = createEvent()
export const submitEditCalcTypeEv = createEvent()
export const deleteCalcTypeEv = createEvent()

export const setAfterCreateActionEv = createEvent()
export const resetAfterCreationAttrs = createEvent()
