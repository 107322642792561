import {useTranslation} from "react-i18next";
import Form from "antd/es/form";
import Select from "antd/es/select/index.js";
import Space from "antd/es/space/index.js";
import {PlusOutlined} from "@ant-design/icons";
import CreateStreetEmbeddedForm
	from "../../../components/Embedded/CreateStreetEmbeddedForm/CreateStreetEmbeddedForm.jsx";
import {useUnit} from "effector-react";
import {$streetsByCitySelect, getStreetsByCityEv} from "../../../models/selectsByParentModel/index.js";
import {setCreateStreetEmbeddedEv} from "../../../models/dictionaryEmbeddedFormsModel/index.js";
import Input from "antd/es/input/index.js";
import Switch from "antd/es/switch/index.js";
import DatePicker from "antd/es/date-picker/index.js";
import {Row} from "antd";
import Col from "antd/es/col";
import Button from "antd/es/button";
import {$chosenSecAddress, addSecAddressEv, setSecAddressEv} from "../../../models/dictionaryAddressesModel/index.js";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function CreateSecondaryAddress(
	{
		chosenTown, chosenDistr, resetMode, fakeID, afterSubmit
	}
){

	const {t} = useTranslation()
	const [createSecAddressForm] = Form.useForm()
	const setStreetEmbedded = useUnit(setCreateStreetEmbeddedEv)
	const streetId = Form.useWatch('street_id', createSecAddressForm)
	const addSecAddress = useUnit(addSecAddressEv)
	const streetsByTown = useUnit($streetsByCitySelect)
	const getStByTown = useUnit(getStreetsByCityEv)

	const [localStrEmbedded, setLocalStrEmbedded] = useState(false)

	const onFinish = (values) => {

		const chosenStreet = streetsByTown.find(i => i.value === values.street_id)

		addSecAddress({
			...values,
			street_kz: chosenStreet ? chosenStreet.name_kz : values.street.name_kz,
			street_ru: chosenStreet ? chosenStreet.name_ru : values.street.name_ru,
			id: fakeID,
		})
		afterSubmit()
	}

	return <Form
		form={createSecAddressForm}
		onFinish={onFinish}
		style={{marginBottom: '20px'}}
		initialValues={{
			actual_from: dayjs(new Date()),
			is_actual: true,
		}}
	>
		<Row justify={'space-between'} gutter={[24, 24]}>
			<Col span={12}>
				<Form.Item name={'street_id'} label={t('Улица')}
									 rules={[{required: true, message: t('Обязательное поле')}]}>
					<Select allowClear
									options={[...streetsByTown, {
										label: 'Создать',
										value: -1,
										emoji: true
									}]}
									onChange={(v) => {
										if (v === -1) {
											setLocalStrEmbedded(true)
											// setStreetEmbedded(true)
										} else {
											createSecAddressForm.resetFields(['street', 'house', 'corpus'])
											setLocalStrEmbedded(false)
											// setStreetEmbedded(false)
										}
									}}
									showSearch
									filterOption={(input, option) =>
										(option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
									}
									optionRender={(option) => (
										<Space>
									<span role="img" aria-label={option.data.label}>
										{option?.data?.emoji ?
											<PlusOutlined style={{color: '#1677ff'}}/> : ''}
									</span>
											{
												option?.data?.emoji ?
													<span style={{color: '#1677ff'}}>
													{option?.data.label}
											</span> :
													<span>
												{option?.data.label}
										</span>
											}
										</Space>
									)}
									disabled={!(typeof chosenTown === 'number')}
					/>
				</Form.Item>
				<CreateStreetEmbeddedForm form={createSecAddressForm} searchedTown={chosenTown} unique={localStrEmbedded}/>
				<Form.Item name={'house'}
									 label={t('Номер дома')}
									 rules={[{required: true, message: t('Обязательное поле')}]}
				>
					<Input
						disabled={!(typeof chosenTown === 'number')}
					/>
				</Form.Item>
				<Form.Item name={'corpus'} label={t('Номер корпуса')}>
					<Input
						disabled={!(typeof chosenTown === 'number')}
					/>
				</Form.Item>

			</Col>
			<Col span={12}>
				<Form.Item name={'is_actual'}
									 label={t('Актуален')}
									 valuePropName={'checked'}

				>
					<Switch value={'checked'}/>
				</Form.Item>
				<Form.Item name={'actual_from'}
									 label={t('Дата начала актуальности')}
									 rules={[{required: true, message: t('Обязательное поле')}]}
				>
					<DatePicker style={{width: '100%'}}/>
				</Form.Item>
			</Col>
		</Row>
		<Row>
			<Space>
				<Button type={'primary'} onClick={createSecAddressForm.submit}>
					{t('Добавить адрес как вспомогательный')}
				</Button>
				<Button onClick={resetMode}>
					{t('Отменить добавление вспомогательного адреса')}
				</Button>
			</Space>
		</Row>
	</Form>
}
