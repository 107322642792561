import {
    $afterCreateAction,
    $createdActivityTypeId,
    $editActivityTypeStatus,
    $selectedActivityType,
    $activityTypeList,
} from './stores.js';
import {getActivityTypeListFx, getActivityTypeByIdFx} from './effects.js';
import {
    deleteActivityTypeEv,
    resetAfterCreationAttrs,
    setAfterCreateActionEv,
    ActivityTypeEditGate,
    ActivityTypeListGate,
    ActivityTypeViewGate,
    submitCreateActivityTypeEv,
    submitEditActivityTypeEv, ActivityTypeCreateGate
} from './events.js';
import {sample} from 'effector';
import {submitGlobalUpdateEv, triggerSuccessOpsEv} from '../dictionaryUniversalModel/index.js';
import {combineEvents} from 'patronum';

const createCompleteEv = combineEvents({events: {ev: submitCreateActivityTypeEv, response: triggerSuccessOpsEv}, reset: ActivityTypeCreateGate.state})
const editCompleteEv = combineEvents({ev: submitEditActivityTypeEv, response: triggerSuccessOpsEv})
const deleteCompleteEv = combineEvents({ev: deleteActivityTypeEv, response: triggerSuccessOpsEv})

$activityTypeList.on(getActivityTypeListFx.doneData, (_, data) => data)
    .reset(ActivityTypeListGate.close)

$selectedActivityType.on(getActivityTypeByIdFx.doneData, (_, data) => data)
    .reset([ActivityTypeEditGate.close, ActivityTypeViewGate.close])
// FIXME
$createdActivityTypeId.on(createCompleteEv, (_, {response}) => {
    const data = response.find(i => i.type === "activity_type")
    return data ? data?.value?.id : undefined

})
    .reset(resetAfterCreationAttrs)

$afterCreateAction.on(setAfterCreateActionEv, (_, action) => action)
    .reset(resetAfterCreationAttrs)

$editActivityTypeStatus.on(editCompleteEv, () => true)
    .reset(ActivityTypeEditGate.close)

sample({
    clock: [ActivityTypeEditGate.state, ActivityTypeViewGate.state],
    filter: (gate) => Object.hasOwn(gate, 'id'),
    fn: (gate) => +gate.id,
    target: getActivityTypeByIdFx
})

sample({
    clock: ActivityTypeListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getActivityTypeListFx
})

sample({
    source: ActivityTypeListGate.state,
    clock: [createCompleteEv, editCompleteEv, deleteCompleteEv],
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getActivityTypeListFx
})

sample({
    clock: submitCreateActivityTypeEv,
    fn: (payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        actions.push({operation: 'create', type: 'activity_type', value: {...payload, id: parseInt(payload.id)}})
        idCounter--

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    source: $selectedActivityType,
    clock: submitEditActivityTypeEv,
    fn: (current, payload) => {
        // Логика описана в src/models/dictionaryTownsModel/handlers.js
        const actions = []
        let idCounter = -1

        const changedFields = {}
        for (const [key, value] of Object.entries(payload)) {
            if (current[key] !== value) {
                changedFields[key] = value
            }
        }
        const value = {
            ...changedFields,
            id: parseInt(changedFields.id) ? parseInt(changedFields.id) : null,
            object_id:  current.id,
        }

        if (value.id === null) {
            delete value.id
        }
        actions.push({operation: 'update', type: 'activity_type', value: value})

        return actions
    },
    target: submitGlobalUpdateEv
})

sample({
    clock: deleteActivityTypeEv,
    fn: (id) => {
        const actions = []
        actions.push({operation: 'delete', type: 'activity_type', value: {id}})
        return actions
    },
    target: submitGlobalUpdateEv
})
