export function prepareMassPayload(values) {
    const checked = {...values}
    for (const [key, value] of Object.entries(checked)) {
        if (value === null || value === undefined) {
            delete checked[key]
        } else if ((key.includes('_from') || key.includes('_to')) && value && dayjs(value).isValid()) {
            if (key.startsWith('not_')) {
                if (key.includes('_from')) {
                    checked[key] = dayjs(value).date(dayjs(value).startOf('month').date()).format()
                } else if (key.includes('_to')) {
                    checked[key] = dayjs(value).date(dayjs(value).endOf('month').date()).format()
                }
            } else {
                checked[key] = dayjs(value).format()
            }
        } else if (key === 'tarif_id' && value < 0) {
            delete checked[key]
        } else if (key === 'saldo_end_value' && value && checked['correction_value']) {
            delete checked['correction_value']
        }
    }
    return checked
}

export function preparePrefsSelects(providers) {
    const result = {providers: [], services: []}
    const lang = localStorage.getItem('lang') ?? 'ru'
    for (const p of providers) {
        result.providers.push({value: p.provider_id, label: `${p.provider_id} ${p?.[`provider_name_${lang}`]}`})
        for (const s of p.services) {
            result.services.push({
                value: s.service_id,
                label: `${s.service_id} ${s?.[`service_name_${lang}`]}`,
                provider_id: p.provider_id,
                prefs: s.preferences.map(pref => ({value: pref.preference_id, label: `${pref.preference_id} ${pref?.[`preference_name_${lang}`]}/${pref.discount}%`, service_id: s.service_id}))
            })
        }
    }
    return result
}