import {$filesList} from './stores.js';
import {downloadFileFx, getFilesListFx} from './effects.js';
import {FilesListGate, downloadFileEv} from './events.js';
import {sample} from 'effector';

$filesList.on(getFilesListFx.doneData, (_, data) => data)
    .reset(FilesListGate.close)

sample({
    clock: FilesListGate.state,
    filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (gate) => gate.search,
    target: getFilesListFx,
})

sample({
    clock: downloadFileEv,
    // filter: (gate) => Object.hasOwn(gate, 'search'),
    fn: (path) => encodeURIComponent(path),
    target: downloadFileFx,
})
