import {useStoreMap, useUnit} from 'effector-react';
import {$infoMaps} from '../../models/infoModel/index.js';
import {Link} from 'react-router-dom';
import Tooltip from "antd/es/tooltip";
import {isId} from "../../utils/helpers.js";
import {MinusOutlined} from "@ant-design/icons";

const wrappedStyle = {textWrap: 'wrap'}
const nonWrappedStyle = {textWrap: 'nowrap'}

export default function MappedDictItem(
    {
        id, type, link = true, name,
        withID = true, wrap=true,
        asTooltip = false,
    }
) {
    const maps = useStoreMap($infoMaps, (maps) => ({
        town: maps.townsMap ?? {},
        town_distr: maps.districtsMap ?? {},
        bank: maps.bankMap ?? {},
        banks: maps.bankMap ?? {},
        banksBranch: maps.bankBranchMap ?? {},
        kbk: maps.kbkMap ?? {},
        knp: maps.knpMap ?? {},
        kbe: maps.kbeMap ?? {},
        street: maps.streetsMap ?? {},
        street_types: maps.streetTypesMap ?? {},
        address: maps.addressesMap ?? {},
        sector: maps.sectorsMap ?? {},
        tax_branch: maps.taxBranchesMap ?? {},
        bank_api_address: maps.bankApiAddressMap ?? {},
        real_estate_object: maps.realEstateObjectMap ?? {},
        'real-estate-object-type': maps.realEstateObjectTypeMap ?? {},
        'service-classes': maps.serviceClassesMap ?? {},
        inspector: maps.inspectorsMap ?? {},
        nationality: maps.nationalityMap ?? {},
        preference_category: maps.preferenceCategoryMap ?? {},
        consumer_category: maps.consumerCategoryMap ?? {},
        'service-units': maps.serviceUnitsShortMap ?? {},
        'service-unit-st': maps.serviceUnitsShortMap ?? {},
        'service-payment-type': maps.servicePaymentTypeMap ?? {},
        providers: maps.providersMap ?? {},
        services: maps.servicesMap ?? {},
        templates: maps.templatesMap ?? {},
        organization_type: maps.organizationTypeMap ?? {},
        'bank-account-type': maps.bankAccountTypeMap ?? {},
        currency: maps.currencyMap ?? {},
        'chief-inspector': maps.chiefInspectorMap,
        // calc_type: maps.calcTypeMap ?? {},
        'calc-type': maps.calcTypeMap ?? {},
        economy_sector: maps.economySectorMap ?? {},
        bank_commission: maps.bankCommissionMap ?? {},
        users: maps.usersMap ?? {},
        role: maps.roleMap ?? {},
        permission: maps.permissionMap ?? {},
        algorithm: maps.algorithmsMap ?? {},
        position: maps.positionMap
    }))


    const style = wrap ? wrappedStyle : nonWrappedStyle
    if (type === 'inspector') {
        // debugger
        const obj = maps?.inspector[id] || {}
        const name = obj.name || obj.name_ru || obj.name_kz

        return id
            ? link && id !== '-'
                ? <Link to={`/dictionaries/inspectors/view/${id}`} target={'_blank'}>
                    <span>
                        {`${obj.chief_inspector_id}-${obj.id} ${name ?? ''}`}
                    </span>
                </Link>
                : <span>
                    {
                        id === '-' ? 'Без контролера' :
                            `${obj.chief_inspector_id}-${obj.id} ${name ?? ''}`
                    }
                </span>
            : '-'
    } else {
        if (!isId(id)) return <MinusOutlined />
        if (asTooltip && isId(id) && maps?.[type]?.[id] !== undefined)
            return link
                ? (
                    <Tooltip title={name ?? maps?.[type]?.[id] ?? ''}>
                    <Link to={`/dictionaries/${type}/view/${id}`} target={'_blank'}>
                        <span style={style}>{id}</span>
                    </Link>
                    </Tooltip>
                ) : (
                    <Tooltip title={name ?? maps?.[type]?.[id] ?? ''}>
                        <span style={style}>{id}</span>
                    </Tooltip>
                )
        return (isId(id) && maps?.[type]?.[id] !== undefined)
            ? link
                ? <Link to={`/dictionaries/${type}/view/${id}`} target={'_blank'}>
                    <span style={style}>{`${withID ? id + ' ' : ''} ${name ?? maps?.[type]?.[id] ?? ''}`}</span>
                </Link>
                : <span style={style}>{`${withID ? id + ' ' : ''} ${name ?? maps?.[type]?.[id] ?? ''}`}</span>
            : id !== null && type
                ? link
                    ? <Link to={`/dictionaries/${type}/view/${id}`} target={'_blank'}><span style={style}>{id}</span></Link>
                    : <span style={style}>{id}</span>
                : '-'
    }


    // return id
    //     ? link
    //         ? <Link to={`/dictionaries/${type}/view/${id}`}>
    //             <span>{`${id} ${name ?? maps?.[type]?.[id] ?? ''}`}</span>
    //         </Link>
    //         : <span>{`${id} ${name ?? maps?.[type]?.[id] ?? ''}`}</span>
    //     : '-'
}
