import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useUnit} from 'effector-react';
import dayjs from 'dayjs';
import Card from 'antd/es/card';
import MappedDictItem from '../../../components/MappedDictItem/MappedDictItem.jsx';
import ViewDictionaryItemCommon
    from '../../../components/ViewDictionaryItemCommon/ViewDictionaryItemCommon.jsx';
import CreatedBy from '../../../components/CreatedBy/CreatedBy.jsx';


import {
    $selectedSector,
    getSectorByIdFx,
    SectorViewGate
} from "../../../models/dictionarySectorModel/index.js";
import CustomDates from "../../../components/CustomDates/CustomDates.jsx";
import {$selectedUsers, getUsersByIdFx, UsersViewGate} from "../../../models/dictionaryUsersModel/index.js";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import React from "react";

export default function ViewUsers() {
    const {t} = useTranslation();
    const {id} = useParams()
    const loading = useUnit(getUsersByIdFx.pending)
    const data = useUnit($selectedUsers)
    const genitive_form = t(`breadcrumbs.genitive.users`);


    const items = [
        {
            key: 'login',
            label: t('Логин'),
            children: data?.login,
        },
        {
            key: 'name_ru',
            label: t('ФИО'),
            children: <MappedDictItem id={data?.name_ru} type={'users'}/>,
        },
        {
            key: 'position_id',
            label: t('Должность'),
            children: <MappedDictItem id={data?.position_id} type={'position'}/>,
        },
        {
            key: 'role_id',
            label: t('Роль'),
            children: <MappedDictItem id={data?.role_id} type={'role'}/>,
        },
        {
            key: 'chief_id',
            label: t('Руководитель'),
            children: <MappedDictItem id={data?.chief_id} type={'users'}/>,
        },
        {
            key: 'is_active',
            label: t('Активен'),
            children: data?.is_active ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>
        },
        {
            key: 'email',
            label: t('E-mail'),
            children: data?.email ? data?.email : '-',
        },
        {
            key: 'phone',
            label: t('Телефон'),
            children: data?.phone ? data?.phone : '-',
        },

    ]

    const itemsDates = [
        {
            key: 'created_by',
            label: t('Кем создан'),
            children: <CreatedBy id={data?.created_by}/>
        },
        {
            key: 'created_at',
            label: t('Дата создания'),
            children: <CustomDates data={data?.created_at}/>
        },
        {
            key: 'updated_by',
            label: t('Кем изменен'),
            children: data?.updated_by ? <CreatedBy id={data?.updated_by}/> : '-'
        },
        {
            key: 'updated_at',
            label: t('Дата изменения'),
            children: data?.updated_at ? <CustomDates data={data?.updated_at}/> : '-'
        }
    ]

    return <>
        <UsersViewGate id={id}/>
        <Card
            title={<div><span>{t('Просмотр пользователя')}</span> <MappedDictItem id={id} type={'users'} link={false}/></div>}
            loading={loading}
        >
            <ViewDictionaryItemCommon items={items} itemsDates={itemsDates} id={id} href={'users'}/>
        </Card>
    </>
}
