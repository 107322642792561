import {createBrowserRouter} from "react-router-dom";
import App from "../App.jsx";
import Upload from '../pages/Upload/Upload.jsx';
import UploadREO from "../pages/UploadREO/UploadREO.jsx";
import DictionaryTownsList from '../pages/DictionaryTowns/DictionaryTownsList.jsx';
import DictionaryDistrictsList from '../pages/DictionaryDistricts/DictionaryDistrictsList.jsx';
import DictionaryStreetTypesList
    from '../pages/DictionaryStreetTypes/DictionaryStreetTypesList.jsx';
import DictionaryStreetsList from '../pages/DictionaryStreets/DictionaryStreetsList.jsx';
import CreateTown from '../pages/DictionaryTowns/components/CreateTown.jsx';
import EditTown from '../pages/DictionaryTowns/components/EditTown.jsx';
import ViewTown from '../pages/DictionaryTowns/components/ViewTown.jsx';
import CreateStreetType from '../pages/DictionaryStreetTypes/components/CreateStreetType.jsx';
import EditStreetType from '../pages/DictionaryStreetTypes/components/EditStreetType.jsx';
import ViewStreetType from '../pages/DictionaryStreetTypes/components/ViewStreetType.jsx';
import CreateDistrict from '../pages/DictionaryDistricts/components/CreateDistrict.jsx';
import EditDistrict from '../pages/DictionaryDistricts/components/EditDistrict.jsx';
import ViewDistrict from '../pages/DictionaryDistricts/components/ViewDistrict.jsx';
import ViewStreet from '../pages/DictionaryStreets/components/ViewStreet.jsx';
import Controllers from "../pages/Controllers/Controllers.jsx";
import CreateStreet from '../pages/DictionaryStreets/components/CreateStreet.jsx';
import EditStreet from '../pages/DictionaryStreets/components/EditStreet.jsx';
import TaskPDFToControllers from '../pages/TaskPDFToControllers/TaskPDFToControllers.jsx';
import CreateAddress from '../pages/DictionaryAddresses/components/CreateAddress.jsx';
import DictionaryAddressesList from '../pages/DictionaryAddresses/DictionaryAddressesList.jsx';
import DictionaryTaxBranchesList
    from '../pages/DictionaryTaxBranches/DictionaryTaxBranchesList.jsx';
import EditAddress from '../pages/DictionaryAddresses/components/EditAddress.jsx';
import ViewAddress from '../pages/DictionaryAddresses/components/ViewAddress.jsx';
import CreateTaxBranch from '../pages/DictionaryTaxBranches/components/CreateTaxBranch.jsx';
import ViewTaxBranch from '../pages/DictionaryTaxBranches/components/ViewTaxBranch.jsx';
import EditTaxBranch from '../pages/DictionaryTaxBranches/components/EditTaxBranch.jsx';
import DictionaryNationalityList from "../pages/DictionaryNationality/DictionaryNationalityList.jsx";
import CreateNationality from "../pages/DictionaryNationality/components/CreateNationality.jsx";
import EditNationality from "../pages/DictionaryNationality/components/EditNationality.jsx";
import ViewNationality from "../pages/DictionaryNationality/components/ViewNationality.jsx";
import DictionaryServiceClassesList from "../pages/DictionaryServiceClasses/DictionaryServiceClassesList.jsx";
import CreateServiceClass from "../pages/DictionaryServiceClasses/components/CreateServiceClass.jsx";
import EditServiceClass from "../pages/DictionaryServiceClasses/components/EditServiceClass.jsx";
import ViewServiceClass from "../pages/DictionaryServiceClasses/components/ViewServiceClass.jsx";
import DictionaryPreferenceCategoryList
    from "../pages/DictionaryPreferenceCategory/DictionaryPreferenceCategoryList.jsx";
import CreatePreferenceCategory from "../pages/DictionaryPreferenceCategory/components/CreatePreferenceCategory.jsx";
import EditPreferenceCategory from "../pages/DictionaryPreferenceCategory/components/EditPreferenceCategory.jsx";
import ViewPreferenceCategory from "../pages/DictionaryPreferenceCategory/components/ViewPreferenceCategory.jsx";
import DictionaryConsumerCategoryList from "../pages/DictionaryConsumerCategory/DictionaryConsumerCategoryList.jsx";
import CreateConsumerCategory from "../pages/DictionaryConsumerCategory/components/CreateConsumerCategory.jsx";
import EditConsumerCategory from "../pages/DictionaryConsumerCategory/components/EditConsumerCategory.jsx";
import ViewConsumerCategory from "../pages/DictionaryConsumerCategory/components/ViewConsumerCategory.jsx";
import DictionaryRealEstateObjectTypeList
    from "../pages/DictionaryRealEstateObjectType/DictionaryRealEstateObjectTypeList.jsx";
import CreateRealEstateObjectType
    from "../pages/DictionaryRealEstateObjectType/components/CreateRealEstateObjectType.jsx";
import EditRealEstateObjectType from "../pages/DictionaryRealEstateObjectType/components/EditRealEstateObjectType.jsx";
import ViewRealEstateObjectType from "../pages/DictionaryRealEstateObjectType/components/ViewRealEstateObjectType.jsx";
import DictionarySectorList from "../pages/DictionarySector/DictionarySectorList.jsx";
import CreateSector from "../pages/DictionarySector/components/CreateSector.jsx";
import EditSector from "../pages/DictionarySector/components/EditSector.jsx";
import ViewSector from "../pages/DictionarySector/components/ViewSector.jsx";
import DictionaryInspectorsList from "../pages/DictionaryInspectors/DictionaryInspectorsList.jsx";
import CreateInspectors from "../pages/DictionaryInspectors/components/CreateInspectors.jsx";
import EditInspectors from "../pages/DictionaryInspectors/components/EditInspectors.jsx";
import ViewInspectors from "../pages/DictionaryInspectors/components/ViewInspectors.jsx";
import DictionaryKBeList from "../pages/DictionaryKBe/DictionaryKBeList.jsx";
import CreateKBe from "../pages/DictionaryKBe/components/CreateKBe.jsx";
import EditKBe from "../pages/DictionaryKBe/components/EditKBe.jsx";
import ViewKBe from "../pages/DictionaryKBe/components/ViewKBe.jsx";
import DictionaryKbkList from "../pages/DictionaryKbk/DictionaryKbkList.jsx";
import DictionaryActivityTypeList from "../pages/DictionaryActivityType/DictionaryActivityTypeList.jsx";
import CreateKbk from "../pages/DictionaryKbk/components/CreateKbk.jsx";
import EditKbk from "../pages/DictionaryKbk/components/EditKbk.jsx";
import ViewKbk from "../pages/DictionaryKbk/components/ViewKbk.jsx";
import DictionaryEconomySectorList from "../pages/DictionaryEconomySector/DictionaryEconomySectorList.jsx";
import CreateEconomySector from "../pages/DictionaryEconomySector/components/CreateEconomySector.jsx";
import EditEconomySector from "../pages/DictionaryEconomySector/components/EditEconomySector.jsx";
import ViewEconomySector from "../pages/DictionaryEconomySector/components/ViewEconomySector.jsx";
import DictionaryBanksList from "../pages/DictionaryBanks/DictionaryBanksList.jsx";
import CreateBanks from "../pages/DictionaryBanks/components/CreateBanks.jsx";
import EditBanks from "../pages/DictionaryBanks/components/EditBanks.jsx";
import ViewBanks from "../pages/DictionaryBanks/components/ViewBanks.jsx";
import TaskEmailPerAccounts from '../pages/TaskEmailPerAccounts/TaskEmailPerAccounts.jsx';
import DictionaryCalcTypeList from "../pages/DictionaryCalcType/DictionaryCalcTypeList.jsx";
import CreateCalcType from "../pages/DictionaryCalcType/components/CreateCalcType.jsx";
import EditCalcType from "../pages/DictionaryCalcType/components/EditCalcType.jsx";
import ViewCalcType from "../pages/DictionaryCalcType/components/ViewCalcType.jsx";
import DictionaryBankApiAddressList from "../pages/DictionaryBankApiAddress/DictionaryBankApiAddressList.jsx";
import CreateBankApiAddress from "../pages/DictionaryBankApiAddress/components/CreateBankApiAddress.jsx";
import EditBankApiAddress from "../pages/DictionaryBankApiAddress/components/EditBankApiAddress.jsx";
import ViewBankApiAddress from "../pages/DictionaryBankApiAddress/components/ViewBankApiAddress.jsx";
import DictionaryBankApiInfoList from "../pages/DictionaryBankApiInfo/DictionaryBankApiInfoList.jsx";
import CreateBankApiInfo from "../pages/DictionaryBankApiInfo/components/CreateBankApiInfo.jsx";
import EditBankApiInfo from "../pages/DictionaryBankApiInfo/components/EditBankApiInfo.jsx";
import ViewBankApiInfo from "../pages/DictionaryBankApiInfo/components/ViewBankApiInfo.jsx";
import DictionaryServicesList from "../pages/DictionaryServices/DictionaryServicesList.jsx";
import CreateServices from "../pages/DictionaryServices/components/CreateServices.jsx";
import EditServices from "../pages/DictionaryServices/components/EditServices.jsx";
import ViewServices from "../pages/DictionaryServices/components/ViewServices.jsx";
import DictionaryKnpList from "../pages/DictionaryKnp/DictionaryKnpList.jsx";
import CreateKnp from "../pages/DictionaryKnp/components/CreateKnp.jsx";
import EditKnp from "../pages/DictionaryKnp/components/EditKnp.jsx";
import ViewKnp from "../pages/DictionaryKnp/components/ViewKnp.jsx";
import CreateBankAccountType from "../pages/DictionaryBankAccountType/components/CreateBankAccountType.jsx";
import EditBankAccountType from "../pages/DictionaryBankAccountType/components/EditBankAccountType.jsx";
import ViewBankAccountType from "../pages/DictionaryBankAccountType/components/ViewBankAccountType.jsx";
import DictionaryBankAccountTypesList from "../pages/DictionaryBankAccountType/DictionaryBankAccountTypeList.jsx";
import DictionaryServicePaymentTypesList from "../pages/DictionaryServicePaymentType/DictionaryServicePaymentTypeList.jsx";
import CreateServicePaymentType from "../pages/DictionaryServicePaymentType/components/CreateServicePaymentType.jsx";
import EditServicePaymentType from "../pages/DictionaryServicePaymentType/components/EditServicePaymentType.jsx";
import ViewServicePaymentType from "../pages/DictionaryServicePaymentType/components/ViewServicePaymentType.jsx";
import DictionaryServiceUnitsList from "../pages/DictionaryServiceUnits/DictionaryServiceUnitsList.jsx";
import CreateServiceUnits from "../pages/DictionaryServiceUnits/components/CreateServiceUnits.jsx";
import EditServiceUnits from "../pages/DictionaryServiceUnits/components/EditServiceUnits.jsx";
import ViewServiceUnits from "../pages/DictionaryServiceUnits/components/ViewServiceUnits.jsx";
import DictionaryAccountsList from '../pages/DictionaryAccounts/DictionaryAccountsList.jsx';
import CreateAccount from '../pages/DictionaryAccounts/CreateAccount.jsx';
import EditAccountContainer from '../pages/DictionaryAccounts/EditAccountContainer.jsx';
import ViewAccount from '../pages/DictionaryAccounts/ViewAccount.jsx';
import ViewFileCells from '../pages/Upload/components/ViewFileCells.jsx';
import CreateActivityType from "../pages/DictionaryActivityType/components/CreateActivityType.jsx";
import EditActivityType from "../pages/DictionaryActivityType/components/EditActivityType.jsx";
import ViewActivityType from "../pages/DictionaryActivityType/components/ViewActivityType.jsx";
import Home from '../pages/Home/Home.jsx';
import SwapSector from "../pages/SwapSector/SwapSector.jsx";
import DictionaryEpdTemplateFieldList
    from "../pages/DictionaryEpdTemplateField/DictionaryEpdTemplateFieldList.jsx";
import CreateEpdTemplateField
    from "../pages/DictionaryEpdTemplateField/components/CreateEpdTemplateField.jsx";
import EditEpdTemplateField
    from "../pages/DictionaryEpdTemplateField/components/EditEpdTemplateField.jsx";
import ViewEpdTemplateField
    from "../pages/DictionaryEpdTemplateField/components/ViewEpdTemplateField.jsx";
import Login from '../pages/Login/Login.jsx';
import DictionaryEpdTemplatesList from "../pages/DictionaryEpdTemplates/DictionaryEpdTemplatesList.jsx";
import CreateEpdTemplates from "../pages/DictionaryEpdTemplates/components/CreateEpdTemplates.jsx";
import EditEpdTemplates from "../pages/DictionaryEpdTemplates/components/EditEpdTemplates.jsx";
import ViewEpdTemplates from "../pages/DictionaryEpdTemplates/components/ViewEpdTemplates.jsx";
import DictionaryOrganizationTypeList
    from '../pages/DictionaryOrganizationType/DictionaryOrganizationTypeList.jsx';
import CreateOrgType from '../pages/DictionaryOrganizationType/components/CreateOrgType.jsx';
import EditOrgType from '../pages/DictionaryOrganizationType/components/EditOrgType.jsx';
import ViewOrgType from '../pages/DictionaryOrganizationType/components/ViewOrgType.jsx';
import DictionaryCurrencyList from "../pages/DictionaryCurrency/DictionaryCurrencyList.jsx";
import CreateCurrency from "../pages/DictionaryCurrency/components/CreateCurrency.jsx";
import EditCurrency from "../pages/DictionaryCurrency/components/EditCurrency.jsx";
import ViewCurrency from "../pages/DictionaryCurrency/components/ViewCurrency.jsx";
import CreateRole from "../pages/DictionaryRole/components/CreateRole.jsx";
import EditRole from "../pages/DictionaryRole/components/EditRole.jsx";
import ViewRole from "../pages/DictionaryRole/components/ViewRole.jsx";
import DictionaryRoleList from "../pages/DictionaryRole/DictionaryRoleList.jsx";
import DictionaryPermissionList from "../pages/DictionaryPermission/DictionaryPermissionList.jsx";
import CreatePermission from "../pages/DictionaryPermission/components/CreatePermission.jsx";
import EditPermission from "../pages/DictionaryPermission/components/EditPermission.jsx";
import ViewPermission from "../pages/DictionaryPermission/components/ViewPermission.jsx";
import CreatePosition from "../pages/DictionaryPosition/components/CreatePosition.jsx";
import EditPosition from "../pages/DictionaryPosition/components/EditPosition.jsx";
import ViewPosition from "../pages/DictionaryPosition/components/ViewPosition.jsx";
import DictionaryPositionList from "../pages/DictionaryPosition/DictionaryPositionList.jsx";
import TaskPrintEPDPerREO from '../pages/TaskPrintEPDPerReEsObj/TaskPrintEPDPerREO.jsx';
import DictionaryRealEstateObjectsList from "../pages/DictionaryRealEstateObjects/DictionaryRealEstateObjectsList.jsx";
import CreateRealEstateObjects from "../pages/DictionaryRealEstateObjects/components/CreateRealEstateObjects.jsx";
import EditRealEstateObjects from "../pages/DictionaryRealEstateObjects/components/EditRealEstateObjects.jsx";
import ViewRealEstateObjects from "../pages/DictionaryRealEstateObjects/components/ViewRealEstateObjects.jsx";
import EmailApproveContainer from "../pages/EmailSubApprove/EmailApproveContainer.jsx";
import ServicesColumns from "../pages/ServicesColumns/ServicesColumns.jsx";
import EditServicesColumn from "../pages/ServicesColumns/components/EditServicesColumn.jsx";
import CalcTypeAlgorithms from "../pages/CalcTypeAlgorithms/CalcTypeAlgorithms.jsx";
import CreateCalcTypeAlgorithms from "../pages/CalcTypeAlgorithms/components/CreateCalcTypeAlgorithms.jsx";
import EditCalcTypeAlgorithms from "../pages/CalcTypeAlgorithms/components/EditCalcTypeAlgorithms.jsx";
import ViewCalcTypeAlgorithms from "../pages/CalcTypeAlgorithms/components/ViewCalcTypeAlgorithms.jsx";
import UploadAccounts from "../pages/UploadAccounts/UploadAccounts.jsx";
import CloseMonth from "../pages/CloseMonth/CloseMonth.jsx";
import ViewCloseMonth from "../pages/CloseMonth/components/ViewCloseMonth.jsx";
import DictionaryChiefInspectorList from "../pages/DictionaryChiefInspector/DictionaryChiefInspectorList.jsx";
import CreateChiefInspector from "../pages/DictionaryChiefInspector/components/CreateChiefInspector.jsx";
import EditChiefInspector from "../pages/DictionaryChiefInspector/components/EditChiefInspector.jsx";
import ViewChiefInspector from "../pages/DictionaryChiefInspector/components/ViewChiefInspector.jsx";
import CreateGroupAddress from "../pages/DictionaryAddresses/components/CreateGroupAddress.jsx";
import DictionaryProvidersList from '../pages/DictionaryProviders/DictionaryProvidersList.jsx';
import DictionaryBankCommissionList from "../pages/DictionaryBankCommission/DictionaryBankCommissionList.jsx";
import CreateBankCommission from "../pages/DictionaryBankCommission/components/CreateBankCommission.jsx";
import EditBankCommission from "../pages/DictionaryBankCommission/components/EditBankCommission.jsx";
import ViewBankCommission from "../pages/DictionaryBankCommission/components/ViewBankCommission.jsx";
import CreateProvider from '../pages/DictionaryProviders/components/CreateProvider.jsx';
import SwapAddress from "../pages/SwapAddress/SwapAddress.jsx";
import LaunchRecalculations from "../pages/LaunchRecalculations/LaunchRecalculations.jsx";
import AccrualsPage from "../pages/AccrualsPage/AccrualsPage.jsx";
import SwapRealEstateObject from "../pages/SwapRealEstateObject/SwapRealEstateObject.jsx";
import CreateGroupREO from "../pages/DictionaryRealEstateObjects/components/CreateGroupREO.jsx";
import SaldoPage from "../pages/SaldoPage/SaldoPage.jsx";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage.jsx";
import ViewREOFileCells from "../pages/UploadREO/components/ViewREOFileCells.jsx";
import ViewAccountsFileCells from "../pages/UploadAccounts/components/ViewAccountsFileCells.jsx";
import ViewEmailApproveFile from "../pages/EmailSubApprove/components/ViewEmailApproveFile.jsx";
import TaskAllPdf from "../pages/TaskAllPdf/TaskAllPdf.jsx";
import EditProvider from '../pages/DictionaryProviders/components/EditProvider.jsx';
import ViewProvider from '../pages/DictionaryProviders/components/ViewProvider.jsx';
import PaymentsPage from "../pages/PaymentsPage/PaymentsPage.jsx";
import UploadAgreements from "../pages/UploadAgreements/UploadAgreements.jsx";
import ViewAgreementsFileCells from "../pages/UploadAgreements/components/ViewAgreementsFileCells.jsx";
import AdminServices from "../pages/AdminServices/AdminServices.jsx";
import DictionaryCorrectionList from "../pages/Corrections/DictionaryCorrectionList.jsx";
import CorrectionProvAccServ from '../pages/Corrections/ProvAccSrv/CorrectionProvAccServ.jsx';
import PaymentContainer from "../pages/PaymentPage/PaymentContainer.jsx";
import ViewUpdateAccountsFileCells from "../pages/UploadUpdateAccounts/components/ViewUpdateAccountsFileCells.jsx";
import UploadUpdateAccounts from "../pages/UploadUpdateAccounts/UploadUpdateAccounts.jsx";
import LogsPaymentsList from "../pages/LogsPayments/LogsPaymentsList.jsx";
import UploadCashPayments from "../pages/UploadCashPayments/UploadCashPayments.jsx";
import UploadCashPaymentsViewFileCells
    from "../pages/UploadCashPayments/components/UploadCashPaymentsViewFileCells.jsx";
import ReportsList from "../pages/Reports/ReportsList.jsx";
import UploadCorrection from "../pages/UploadCorrection/UploadCorrection.jsx";
import FilesList from "../pages/FileManager/FilesList.jsx";
import ViewFileCorrectionCells from "../pages/UploadCorrection/components/ViewFileCells.jsx";
import UploadProviderAccounts from "../pages/UploadProviderAccounts/UploadProviderAccounts.jsx";
import DictionaryUsersList from "../pages/DictionaryUsers/DictionaryUsersList.jsx";
import CreateUsers from "../pages/DictionaryUsers/components/CreateUsers.jsx";
import EditUsers from "../pages/DictionaryUsers/components/EditUsers.jsx";
import ViewUsers from "../pages/DictionaryUsers/components/ViewUsers.jsx";
import TemplateAccrualsList from "../pages/TemplateAccruals/TemplateAccrualsList.jsx";
import CreateTemplateAccruals from "../pages/TemplateAccruals/components/CreateTemplateAccruals.jsx";
import EditTemplateAccruals from "../pages/TemplateAccruals/components/EditTemplateAccruals.jsx";
import ViewTemplateAccruals from "../pages/TemplateAccruals/components/ViewTemplateAccruals.jsx";
import CorrectionView from '../pages/Corrections/CorrectionView.jsx';
import UploadProviderAccountsViewFileCells
    from "../pages/UploadProviderAccounts/components/UploadProviderAccountsViewFileCells.jsx";
import PaymentDeductionList from "../pages/PaymentDeduction/PaymentDeductionList.jsx";
import UploadCashPaymentsApproveViewFileCells
    from "../pages/UploadCashPayments/components/UploadCashPaymentsApproveViewFileCells.jsx";
import DictionaryPasswordSettingsList from "../pages/DictionaryPasswordSettings/DictionaryPasswordSettingsList.jsx";
import CreatePasswordSettings from "../pages/DictionaryPasswordSettings/components/CreatePasswordSettings.jsx";
import EditPasswordSettings from "../pages/DictionaryPasswordSettings/components/EditPasswordSettings.jsx";
import ViewPasswordSettings from "../pages/DictionaryPasswordSettings/components/ViewPasswordSettings.jsx";
import DictionaryRolePermissionList from "../pages/DictionaryRolePermission/DictionaryRolePermissionList.jsx";
import CreateRolePermission from "../pages/DictionaryRolePermission/components/CreateRolePermission.jsx";
import EditRolePermission from "../pages/DictionaryRolePermission/components/EditRolePermission.jsx";
import ViewRolePermission from "../pages/DictionaryRolePermission/components/ViewRolePermission.jsx";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {path: '/', element: <Home />},
            {path: '/controllers/', element: <Controllers />},
            {path: '/upload', element: <Upload />},
            {path: '/upload/view/:id', element: <ViewFileCells />},
            {path: '/upload-cash-payments', element: <UploadCashPayments />},
            {path: '/upload-cash-payments/view/:id', element: <UploadCashPaymentsViewFileCells />},
            {path: '/upload-cash-payments/view-approve/:id', element: <UploadCashPaymentsApproveViewFileCells />},
            {path: '/upload-real-estate-objects', element: <UploadREO />},
            {path: '/upload-real-estate-objects/view/:id', element: <ViewREOFileCells />},
            {path: '/upload-accounts', element: <UploadAccounts />},
            {path: '/upload-accounts/view/:id', element: <ViewAccountsFileCells />},
            {path: '/upload-update-accounts', element: <UploadUpdateAccounts />},
            {path: '/upload-update-accounts/view/:id', element: <ViewUpdateAccountsFileCells />},
            {path: '/approve-emails', element: <EmailApproveContainer />},
            {path: '/approve-emails/view/:id', element: <ViewEmailApproveFile />},
            {path: '/tasks/pdf-to-controllers', element: <TaskPDFToControllers />},
            {path: '/tasks/all', element: <TaskAllPdf />},
            {path: '/tasks/email-per-accounts', element: <TaskEmailPerAccounts />},
            {path: '/tasks/epd-per-reo', element: <TaskPrintEPDPerREO />},
            {path: '/dictionaries/town', element: <DictionaryTownsList />},
            {path: '/dictionaries/town_distr', element: <DictionaryDistrictsList />},
            {path: '/dictionaries/street_type', element: <DictionaryStreetTypesList />},
            {path: '/dictionaries/street', element: <DictionaryStreetsList />},
            {path: '/dictionaries/address', element: <DictionaryAddressesList />},
            {path: '/dictionaries/tax_branch', element: <DictionaryTaxBranchesList />},
            {path: '/dictionaries/nationality', element: <DictionaryNationalityList />},
            {path: '/dictionaries/preference-category', element: <DictionaryPreferenceCategoryList />},
            {path: '/dictionaries/consumer-category', element: <DictionaryConsumerCategoryList />},
            {path: '/dictionaries/service-classes', element: <DictionaryServiceClassesList />},
            {path: '/dictionaries/bank-account-type', element: <DictionaryBankAccountTypesList />},
            {path: '/dictionaries/service-payment-type', element: <DictionaryServicePaymentTypesList />},
            {path: '/dictionaries/service-units', element: <DictionaryServiceUnitsList />},
            {path: '/dictionaries/epd-template-fields', element: <DictionaryEpdTemplateFieldList />},
            {path: '/dictionaries/epd-templates', element: <DictionaryEpdTemplatesList />},
            {path: '/dictionaries/sector', element: <DictionarySectorList />},
            {path: '/dictionaries/swap-sector', element: <SwapSector />},
            {path: '/dictionaries/swap-address', element: <SwapAddress />},
            {path: '/dictionaries/swap-real-estate-object', element: <SwapRealEstateObject />},
            {path: '/dictionaries/inspectors', element: <DictionaryInspectorsList />},
            {path: '/dictionaries/chief-inspector', element: <DictionaryChiefInspectorList />},
            {path: '/dictionaries/bank-api-info', element: <DictionaryBankApiInfoList />},
            {path: '/dictionaries/kbe', element: <DictionaryKBeList />},
            {path: '/dictionaries/kbk', element: <DictionaryKbkList />},
            {path: '/dictionaries/knp', element: <DictionaryKnpList />},
            {path: '/dictionaries/bank-commission', element: <DictionaryBankCommissionList />},
            {path: '/dictionaries/currency', element: <DictionaryCurrencyList />},
            {path: '/dictionaries/role', element: <DictionaryRoleList />},
            {path: '/dictionaries/permission', element: <DictionaryPermissionList />},
            {path: '/dictionaries/position', element: <DictionaryPositionList />},
            {path: '/dictionaries/services', element: <DictionaryServicesList />},
            {path: '/dictionaries/calc-type', element: <DictionaryCalcTypeList />},
            {path: '/dictionaries/real-estate-objects', element: <DictionaryRealEstateObjectsList />},
            {path: '/dictionaries/activity-type', element: <DictionaryActivityTypeList />},
            {path: '/dictionaries/banks', element: <DictionaryBanksList />},
            {path: '/dictionaries/bank-api-address', element: <DictionaryBankApiAddressList />},
            {path: '/dictionaries/economy-sector', element: <DictionaryEconomySectorList />},
            {path: '/dictionaries/real-estate-object-type', element: <DictionaryRealEstateObjectTypeList />},
            {path: '/dictionaries/town/create', element: <CreateTown />},
            {path: '/dictionaries/town/edit/:id', element: <EditTown />},
            {path: '/dictionaries/town/view/:id', element: <ViewTown />},
            {path: '/dictionaries/town_distr/create', element: <CreateDistrict />},
            {path: '/dictionaries/town_distr/edit/:id', element: <EditDistrict />},
            {path: '/dictionaries/town_distr/view/:id', element: <ViewDistrict />},
            {path: '/dictionaries/street/create', element: <CreateStreet />},
            {path: '/dictionaries/street/edit/:id', element: <EditStreet />},
            {path: '/dictionaries/street/view/:id', element: <ViewStreet />},
            {path: '/dictionaries/street_type/create', element: <CreateStreetType />},
            {path: '/dictionaries/street_type/edit/:id', element: <EditStreetType />},
            {path: '/dictionaries/street_type/view/:id', element: <ViewStreetType />},
            {path: '/dictionaries/address/create', element: <CreateAddress />},
            {path: '/dictionaries/address/edit/:id', element: <EditAddress />},
            {path: '/dictionaries/address/view/:id', element: <ViewAddress />},
            {path: '/dictionaries/address/create-address-group', element: <CreateGroupAddress />},
            {path: '/dictionaries/tax_branch/create', element: <CreateTaxBranch />},
            {path: '/dictionaries/tax_branch/edit/:id', element: <EditTaxBranch />},
            {path: '/dictionaries/tax_branch/view/:id', element: <ViewTaxBranch />},
            {path: '/dictionaries/nationality/create', element: <CreateNationality />},
            {path: '/dictionaries/nationality/edit/:id', element: <EditNationality />},
            {path: '/dictionaries/nationality/view/:id', element: <ViewNationality />},
            {path: '/dictionaries/service-classes/create', element: <CreateServiceClass />},
            {path: '/dictionaries/service-classes/edit/:id', element: <EditServiceClass />},
            {path: '/dictionaries/service-classes/view/:id', element: <ViewServiceClass />},
            {path: '/dictionaries/bank-account-type/create', element: <CreateBankAccountType />},
            {path: '/dictionaries/bank-account-type/edit/:id', element: <EditBankAccountType />},
            {path: '/dictionaries/bank-account-type/view/:id', element: <ViewBankAccountType />},
            {path: '/dictionaries/service-payment-type/create', element: <CreateServicePaymentType />},
            {path: '/dictionaries/service-payment-type/edit/:id', element: <EditServicePaymentType />},
            {path: '/dictionaries/service-payment-type/view/:id', element: <ViewServicePaymentType />},
            {path: '/dictionaries/service-units/create', element: <CreateServiceUnits />},
            {path: '/dictionaries/service-units/edit/:id', element: <EditServiceUnits />},
            {path: '/dictionaries/service-units/view/:id', element: <ViewServiceUnits />},
            {path: '/dictionaries/preference-category/create', element: <CreatePreferenceCategory />},
            {path: '/dictionaries/preference-category/edit/:id', element: <EditPreferenceCategory />},
            {path: '/dictionaries/preference-category/view/:id', element: <ViewPreferenceCategory />},
            {path: '/dictionaries/consumer-category/create', element: <CreateConsumerCategory />},
            {path: '/dictionaries/consumer-category/edit/:id', element: <EditConsumerCategory />},
            {path: '/dictionaries/consumer-category/view/:id', element: <ViewConsumerCategory />},
            {path: '/dictionaries/real-estate-object-type/create', element: <CreateRealEstateObjectType />},
            {path: '/dictionaries/real-estate-object-type/edit/:id', element: <EditRealEstateObjectType />},
            {path: '/dictionaries/real-estate-object-type/view/:id', element: <ViewRealEstateObjectType />},
            {path: '/dictionaries/sector/create', element: <CreateSector />},
            {path: '/dictionaries/sector/edit/:id', element: <EditSector />},
            {path: '/dictionaries/sector/view/:id', element: <ViewSector />},
            {path: '/dictionaries/inspectors/create', element: <CreateInspectors />},
            {path: '/dictionaries/inspectors/edit/:id', element: <EditInspectors />},
            {path: '/dictionaries/inspectors/view/:id', element: <ViewInspectors />},
            {path: '/dictionaries/chief-inspector/create', element: <CreateChiefInspector />},
            {path: '/dictionaries/chief-inspector/edit/:id', element: <EditChiefInspector />},
            {path: '/dictionaries/chief-inspector/view/:id', element: <ViewChiefInspector />},
            {path: '/dictionaries/kbe/create', element: <CreateKBe />},
            {path: '/dictionaries/kbe/edit/:id', element: <EditKBe />},
            {path: '/dictionaries/kbe/view/:id', element: <ViewKBe />},
            {path: '/dictionaries/kbk/create', element: <CreateKbk />},
            {path: '/dictionaries/kbk/edit/:id', element: <EditKbk />},
            {path: '/dictionaries/kbk/view/:id', element: <ViewKbk />},
            {path: '/dictionaries/bank-commission/create', element: <CreateBankCommission />},
            {path: '/dictionaries/bank-commission/edit/:id', element: <EditBankCommission />},
            {path: '/dictionaries/bank-commission/view/:id', element: <ViewBankCommission />},
            {path: '/dictionaries/currency/create', element: <CreateCurrency />},
            {path: '/dictionaries/currency/edit/:id', element: <EditCurrency />},
            {path: '/dictionaries/currency/view/:id', element: <ViewCurrency />},
            {path: '/dictionaries/role/create', element: <CreateRole />},
            {path: '/dictionaries/role/edit/:id', element: <EditRole />},
            {path: '/dictionaries/role/view/:id', element: <ViewRole />},
            {path: '/dictionaries/permission/create', element: <CreatePermission />},
            {path: '/dictionaries/permission/edit/:id', element: <EditPermission />},
            {path: '/dictionaries/permission/view/:id', element: <ViewPermission />},
            {path: '/dictionaries/position/create', element: <CreatePosition />},
            {path: '/dictionaries/position/edit/:id', element: <EditPosition />},
            {path: '/dictionaries/position/view/:id', element: <ViewPosition />},
            {path: '/dictionaries/activity-type/create', element: <CreateActivityType />},
            {path: '/dictionaries/activity-type/edit/:id', element: <EditActivityType />},
            {path: '/dictionaries/activity-type/view/:id', element: <ViewActivityType />},
            {path: '/dictionaries/economy-sector/create', element: <CreateEconomySector />},
            {path: '/dictionaries/economy-sector/edit/:id', element: <EditEconomySector />},
            {path: '/dictionaries/economy-sector/view/:id', element: <ViewEconomySector />},
            {path: '/dictionaries/banks/create', element: <CreateBanks />},
            {path: '/dictionaries/banks/edit/:id', element: <EditBanks />},
            {path: '/dictionaries/banks/view/:id', element: <ViewBanks />},
            {path: '/dictionaries/calc-type/create', element: <CreateCalcType />},
            {path: '/dictionaries/calc-type/edit/:id', element: <EditCalcType />},
            {path: '/dictionaries/calc-type/view/:id', element: <ViewCalcType />},
            {path: '/dictionaries/bank-api-address/create', element: <CreateBankApiAddress />},
            {path: '/dictionaries/bank-api-address/edit/:id', element: <EditBankApiAddress />},
            {path: '/dictionaries/bank-api-address/view/:id', element: <ViewBankApiAddress />},
            {path: '/dictionaries/bank-api-info/create', element: <CreateBankApiInfo />},
            {path: '/dictionaries/bank-api-info/edit/:id', element: <EditBankApiInfo />},
            {path: '/dictionaries/bank-api-info/view/:id', element: <ViewBankApiInfo />},
            {path: '/dictionaries/services/create', element: <CreateServices />},
            {path: '/dictionaries/services/edit/:id', element: <EditServices />},
            {path: '/dictionaries/services/view/:id', element: <ViewServices />},
            {path: '/dictionaries/epd-template-fields/create', element: <CreateEpdTemplateField />},
            {path: '/dictionaries/epd-template-fields/edit/:id', element: <EditEpdTemplateField />},
            {path: '/dictionaries/epd-template-fields/view/:id', element: <ViewEpdTemplateField />},
            {path: '/dictionaries/epd-templates/create', element: <CreateEpdTemplates />},
            {path: '/dictionaries/epd-templates/edit/:id', element: <EditEpdTemplates />},
            {path: '/dictionaries/epd-templates/view/:id', element: <ViewEpdTemplates />},
            {path: '/dictionaries/knp/create', element: <CreateKnp />},
            {path: '/dictionaries/knp/edit/:id', element: <EditKnp />},
            {path: '/dictionaries/knp/view/:id', element: <ViewKnp />},
            {path: '/dictionaries/accounts', element: <DictionaryAccountsList />},
            {path: '/dictionaries/accounts/create', element: <CreateAccount />},
            {path: '/dictionaries/accounts/edit/:id', element: <EditAccountContainer />},
            {path: '/dictionaries/accounts/view/:id', element: <ViewAccount />},
            {path: '/dictionaries/real-estate-objects/create', element: <CreateRealEstateObjects />},
            {path: '/dictionaries/real-estate-objects/edit/:id', element: <EditRealEstateObjects />},
            {path: '/dictionaries/real-estate-objects/view/:id', element: <ViewRealEstateObjects />},
            {path: '/dictionaries/real-estate-objects/create-group', element: <CreateGroupREO />},

            {path: '/dictionaries/organization_type', element: <DictionaryOrganizationTypeList />},
            {path: '/dictionaries/organization_type/create', element: <CreateOrgType />},
            {path: '/dictionaries/organization_type/edit/:id', element: <EditOrgType />},
            {path: '/dictionaries/organization_type/view/:id', element: <ViewOrgType />},
            {path: '/dictionaries/users', element: <DictionaryUsersList />},
            {path: '/dictionaries/users/create', element: <CreateUsers />},
            {path: '/dictionaries/users/edit/:id', element: <EditUsers />},
            {path: '/dictionaries/users/view/:id', element: <ViewUsers />},

            {path: '/services-columns', element: <ServicesColumns />},
            {path: '/services-columns/edit/:id', element: <EditServicesColumn />},
            {path: '/dictionaries/algorithm', element: <CalcTypeAlgorithms />},
            {path: '/dictionaries/algorithm/create', element: <CreateCalcTypeAlgorithms />},
            {path: '/dictionaries/algorithm/create/:id', element: <CreateCalcTypeAlgorithms />},
            {path: '/dictionaries/algorithm/edit/:id', element: <EditCalcTypeAlgorithms />},
            {path: '/dictionaries/algorithm/view/:id', element: <ViewCalcTypeAlgorithms />},
            {path: '/dictionaries/providers', element: <DictionaryProvidersList />},
            {path: '/dictionaries/providers/create', element: <CreateProvider />},
            {path: '/dictionaries/providers/edit/:id', element: <EditProvider />},
            {path: '/dictionaries/providers/view/:id', element: <ViewProvider />},
            {path: '/corrections', element: <DictionaryCorrectionList />},
            // {path: '/corrections/create', element: <DictionaryCorrectionCreateStepOne />},
            {path: '/corrections/edit/:id', element: <CorrectionProvAccServ />},
            {path: '/corrections/view/:id', element: <CorrectionView />},
            {path: '/close-month', element: <CloseMonth />},
            {path: '/close-month/view/:id', element: <ViewCloseMonth />},
            {path: '/launch-recalculations', element: <LaunchRecalculations />},
            {path: '/accruals', element: <AccrualsPage />},
            {path: '/saldo', element: <SaldoPage />},
            {path: '/admin-services', element: <AdminServices />},
            {path: '/payments', element: <PaymentsPage />},
            {path: '/payment', element: <PaymentContainer />},
            {path: '/payment-deduction', element: <PaymentDeductionList />},
            {path: '/logs-payments', element: <LogsPaymentsList />},
            {path: '/reports', element: <ReportsList />},
            {path: '/reports/files', element: <FilesList />},
            {path: '/upload-agreements', element: <UploadAgreements />},
            {path: '/upload-correction', element: <UploadCorrection />},
            {path: '/upload-provider-accounts', element: <UploadProviderAccounts />},
            {path: '/upload-provider-accounts/view/:id', element: <UploadProviderAccountsViewFileCells />},
            {path: '/upload-correction/view/:id', element: <ViewFileCorrectionCells />},
            {path: '/upload-agreements/view/:id', element: <ViewAgreementsFileCells />},
            {path: '/dictionaries/template-accruals', element: <TemplateAccrualsList />},
            {path: '/dictionaries/template-accruals/create', element: <CreateTemplateAccruals />},
            {path: '/dictionaries/template-accruals/edit/:id', element: <EditTemplateAccruals />},
            {path: '/dictionaries/template-accruals/view/:id', element: <ViewTemplateAccruals />},
            {path: '/dictionaries/password-settings', element: <DictionaryPasswordSettingsList />},
            {path: '/dictionaries/password-settings/create', element: <CreatePasswordSettings />},
            {path: '/dictionaries/password-settings/edit/:id', element: <EditPasswordSettings />},
            {path: '/dictionaries/password-settings/view/:id', element: <ViewPasswordSettings />},
            {path: '/dictionaries/role-permission', element: <DictionaryRolePermissionList />},

            {path: '/not-found', element: <NotFoundPage />},
            //FIXME Sasha's main page dnd + resize research
						// {path: '/main', element: <MainPage />},
				]
    },
    {
        path: '/login',
        element: <Login />
    }
])
