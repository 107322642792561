import {createEffect} from "effector";
import api from "../../api/api.js";

export const saveUserSettingsFx = createEffect()
	.use(async (payload) => (await api().post(`/user_settings/`, payload)).data)

export const updateUserSettingsFx = createEffect()
	.use(async ({id, payload}) => (await api().put(`/user_settings/${id}`, payload)).data)

export const getAllSettingsFx = createEffect()
	.use(async (id) => (await api().get(`/user_settings/get_all_settings?user_id=${id}`)).data)
