import {useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import queryString from "query-string";
import {saveUserSettingsEv} from "../models/userSettingsModel/index.js";

export const useServerSortPage = () => {
    const [search, setSearch] = useSearchParams()

    const [sortField, setSortField] = useState(search.get('sort_field') ?? null)
    const [sortOrder, setSortOrder] = useState(search.get('sort_order') ?? null)
    const [curPage, setCurPage] = useState(search.get('page') ? parseInt(search.get('page')) : 1)
    const [limit, setLimit] = useState(search.get('limit') ? parseInt(search.get('limit')) : 10)

    useEffect(() => {
        if (!search.get('sort_field')) {
            setSortField(null)
        }
        if (!search.get('sort_order')) {
            setSortOrder(null)
        }
        if (!search.get('limit')) {
            setLimit(10)
        }
        if (!search.get('page')) {
            setCurPage(1)
        }
    }, [search]);

    const onChangeTable = ({page, limit, sort_field, sort_order}) => {
        let result = {}
        const filter = queryString.parse(search.toString(), {parseBooleans: true, parseNumbers: true})
        if (!sort_field || !sort_order) {
            delete filter['sort_field']
            delete filter['sort_order']
            result = {...filter, page, limit}
        } else {
            result = {...filter, sort_field, sort_order: sort_order === 'ascend' || sort_order === 'asc' ? 'asc' : 'desc', page, limit}
        }
        setCurPage(page)
        setLimit(limit)
        setSortField(sort_field)
        setSortOrder(sort_order)

        saveUserSettingsEv({
            key: `${window.location.pathname.slice(1)}.table_sort`,
            value: JSON.stringify(result)
        })

        setSearch(result)
    }

    return [curPage, limit, sortField, sortOrder, onChangeTable, search]
}
