import {createStore} from 'effector';


export const $epdTemplatesList = createStore({data: [], count: 0})

export const $selectedEPDTemplates = createStore(null)

export const $editEPDTemplatesStatus = createStore(false)

export const $createdEPDTemplatesId = createStore(null)
export const $afterCreateAction = createStore(null)

export const $fieldName = createStore([
    {
        label: 'service_name',
        value: 'service_name',
    },
    {
        label: 'sum_saldo_begin',
        value: 'sum_saldo_begin',
    },
    {
        label: 'sum_pay',
        value: 'sum_pay',
    },
    {
        label: 'pay_count',
        value: 'pay_count',
    },
    {
        label: 'check_count',
        value: 'check_count',
    },
    {
        label: 'consumption',
        value: 'consumption',
    },
    {
        label: 'tarif_value',
        value: 'tarif_value',
    },
    {
        label: 'sum_calc',
        value: 'sum_calc',
    },
    {
        label: 'sum_fine',
        value: 'sum_fine',
    },
    {
        label: 'sum_recalc',
        value: 'sum_recalc',
    },
    {
        label: 'sum_saldo_end',
        value: 'sum_saldo_end',
    }
])

export const $cellType = createStore([
    {
        label: 'normal',
        value: 'normal',
    },
    {
        label: 'merge',
        value: 'merge',
    },
    {
        label: 'sum_column',
        value: 'sum_column',
    },
    {
        label: 'bold',
        value: 'bold',
    }
])

export const $columnNumber = createStore([
    {
        label: '0',
        value: '0',
    },
    {
        label: '1',
        value: '1',
    },
    {
        label: '2',
        value: '2',
    },
    {
        label: '3',
        value: '3',
    },
    {
        label: '4',
        value: '4',
    },
    {
        label: '5',
        value: '5',
    },
    {
        label: '6',
        value: '6',
    },
    {
        label: '7',
        value: '7',
    },
    {
        label: '8',
        value: '8',
    },
    {
        label: '9',
        value: '9',
    },
    {
        label: '10',
        value: '10',
    },
    {
        label: '11',
        value: '11',
    }

])
