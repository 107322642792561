import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useStoreMap, useUnit} from 'effector-react';
import Card from 'antd/es/card';
import Form from 'antd/es/form'
import Row from 'antd/es/row'
import Col from 'antd/es/col'
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import {universalDictEndpointFx} from '../../../models/dictionaryUniversalModel/index.js';
import useFormChanged from '../../../hooks/useFormChanged.js';
import {
    $editBanksStatus,
    $selectedBanks,
    BanksEditGate, getBanksByIdFx, submitEditBanksEv,
} from "../../../models/dictionaryBanksModel/index.js";
import {validateBIN} from '../../../utils/validateBIN.js';
import Select from "antd/es/select/index.js";
import {$dictsSelects, $info} from "../../../models/infoModel/index.js";
import {Switch} from "antd";
import {$afterCreateAction, setAfterCreateActionEv} from "../../../models/dictionaryBanksModel/index.js";

export default function EditBanks() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const selected = useUnit($selectedBanks)

    const [banksForm] = Form.useForm()

    const submit = useUnit(submitEditBanksEv)
    const action = useUnit($afterCreateAction)
    const setAction = useUnit(setAfterCreateActionEv)


    const loading = useUnit(getBanksByIdFx.pending)

    const submitLoading = useUnit(universalDictEndpointFx.pending)

    const bankCommissionOpts = useStoreMap($dictsSelects, sel => sel.bank_commission)

    const status = useUnit($editBanksStatus)
    const genitive_form = t(`breadcrumbs.genitive.banks`);

    const branch_all = Object.fromEntries(
        useStoreMap($info, maps => (maps.bank || [])).map(item => [item.branch, item.id])
    )

    useEffect(() => {
        if (status) {
            if (action === 'list') {
                navigate(`/dictionaries/banks?code_name=${selected.id}`)
            }
        }
    }, [status, action]);


    useEffect(() => {
        if (selected) {
            banksForm.setFieldsValue(selected)
        }
    }, [selected]);

    const [check, changedFields] = useFormChanged(selected, [
        'name_kz', 'bank_bin_iin', 'bik',
    ])

    return <>
        <BanksEditGate id={id}/>
            <Form form={banksForm}
                  size={'small'}
                  layout={'vertical'}
                  onFinish={(v) => submit(v)}
                  validateTrigger={'onBlur'}
                  onValuesChange={(_, all) => check(all)}
            >
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Card title={t('Свойства банка')} size={'small'} loading={loading}
                              style={{height: '100%'}}
                        >
                            <Row gutter={[16, 0]}>
                                <Col span={12}>
                                    <Form.Item name={'name_ru'}
                                               label={t(`Название ${genitive_form} (рус)`)}
                                               rules={[{required: true, message: t('Обязательное поле')}]}
                                               validateStatus={changedFields['name']}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'name_kz'}
                                               label={t(`Название ${genitive_form} (каз)`)}
                                               rules={[{required: true, message: t('Обязательное поле')}]}
                                               validateStatus={changedFields['name']}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'bik'}
                                               label={t('БИК')}
                                               validateTrigger={['onBlur']}
                                               rules={[
                                                   {required: true, message: t('Обязательное поле')},
                                                   {
                                                       pattern: /^[A-Z]{8}$/,
                                                       message: 'Только заглавные латинские буквы, 8 букв',
                                                   },
                                               ]}
                                               validateStatus={changedFields['bik']}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'bank_bin_iin'}
                                               label={t('БИН')}
                                               validateTrigger={['onBlur']}
                                               rules={[
                                                   {required: true, message: t('Обязательное поле')},
                                                   {validator: async (rule, value) => {
                                                           if (value && (value.length !== 12 || /^\d+$/.test(value) === false)) {
                                                               return Promise.reject(new Error(t('БИН должен состоять из 12 цифр')));
                                                           } else if (value
                                                               && value.length === 12
                                                               && value.split('').every(i => Number.isInteger(+i))
                                                               && !validateBIN(value)
                                                           ) {
                                                               return Promise.reject(new Error(t('БИН недействителен')));
                                                           } else if (value
                                                               && value.length === 12
                                                               && value.split('').every(i => Number.isInteger(+i))
                                                               && validateBIN(value)
                                                           ) {
                                                               return Promise.resolve();
                                                           }
                                                       }
                                                   }
                                               ]}
                                               validateStatus={changedFields['bank_bin_iin']}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'branch'}
                                               label={t('Отделение')}
                                               validateTrigger={['onBlur']}
                                               rules={[
                                                   {required: true, message: t('Обязательное поле')},
                                                   {
                                                       validator: async (rule, value) => {
                                                           const check = (branch_all[value] !== undefined) && (+branch_all[value] !== +id)
                                                           if (check === true) {
                                                               return Promise.reject(new Error(t('Отделение уже занято')));
                                                           } {
                                                               return Promise.resolve();
                                                           }
                                                       }
                                                   }
                                               ]}
                                    >
                                        <InputNumber style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card title={t('Договор с ЕРЦ')} size={'small'} loading={loading}
                              style={{height: '100%'}}
                        >
                            <Row gutter={[16, 0]}>
                                <Col span={5}>
                                    <Form.Item name={'is_contract_signed'}
                                               label={t('Договор с ЕРЦ')}
                                               rules={[{
                                                   required: false,
                                                   message: t('Обязательное поле')
                                               }]}
                                               // validateStatus={changedFields['is_contract_signed']}
                                    >
                                        <Switch/>
                                    </Form.Item>
                                </Col>
                                <Col span={14}>
                                    <Form.Item name={'agreement'}
                                               label={t('Номер и дата договора')}
                                               rules={[{required: false, message: t('Обязательное поле')}]}
                                               // validateStatus={changedFields['agreement']}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item name={'bank_commission_id'}
                                               label={t('Комиссия банка')}
                                               rules={[{required: false, message: t('Обязательное поле')}]}
                                               // validateStatus={changedFields['bank_commission_id']}
                                    >
                                        <Select
                                            options={bankCommissionOpts}
                                            showSearch
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }

                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name={'address'}
                                               label={t('Адрес')}
                                               rules={[{
                                                   required: false,
                                                   message: t('Обязательное поле')
                                               }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'chief_contacts'}
                                               label={t('Контакты руководителя')}
                                               rules={[{
                                                   required: false,
                                                   message: t('Обязательное поле')
                                               }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name={'accountant_contacts'}
                                               label={t('Контакты бухгалтера')}
                                               rules={[{
                                                   required: false,
                                                   message: t('Обязательное поле')
                                               }]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
            <Divider orientation={'left'} orientationMargin={0}/>
            <Row>
                <Space>
                    <Button danger onClick={() => {
                        banksForm.resetFields()
                        navigate(-1)
                    }}
                    >
                        {t('Отмена')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                banksForm.submit()
                                setAction('edit')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и продолжить редактирование')}
                    </Button>
                    <Button loading={submitLoading}
                            onClick={() => {
                                banksForm.submit()
                                setAction('list')
                            }}
                            type={'primary'}
                    >
                        {t('Сохранить и вернуться к списку')}
                    </Button>
                </Space>
            </Row>
    </>
}
