import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useUnit} from 'effector-react';
import {useNavigate, useParams} from 'react-router-dom';
import dayjs from 'dayjs';
import {$selectedProvider, getProviderByIdFx, ProviderViewGate} from '../../../models/dictionaryProvidersModel/index.js';
import Tabs from 'antd/es/tabs';
import ProviderMainView from './tabComponents/ProviderMainView.jsx';
import ProvServView from './tabComponents/ProvServView.jsx';
import ProvServAccForm from './tabComponents/ProvServAccForm.jsx';
import ProvServREOForm from './tabComponents/ProvServREOForm.jsx';
import Divider from 'antd/es/divider';
import Row from 'antd/es/row';
import Form from 'antd/es/form';
import Space from 'antd/es/space';
import Button from 'antd/es/button';
import Skeleton from 'antd/es/skeleton';
import {HomeOutlined} from "@ant-design/icons";
import StickyBox from "react-sticky-box";
import {$createdCorrectionId, createCorrectionEv} from "../../../models/dictionaryCorrectionModel/index.js";
import {$userPerms} from "../../../models/infoModel/index.js";
import permitted from "../../../utils/permitted.js";
import ProviderKassaForm from "./tabComponents/ProviderKassaForm.jsx";

export default function ViewProvider() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {id} = useParams()

    const selectedProvider = useUnit($selectedProvider)
    const perms = useUnit($userPerms)

    const [providerForm] = Form.useForm()
    const loading = useUnit(getProviderByIdFx.pending)

    const createdCorrectionId = useUnit($createdCorrectionId)

    useEffect(() => {
        if (selectedProvider) {
            const prep = {...selectedProvider}
            for (const [key, value] of Object.entries(prep)) {
                if (key.endsWith('_from') || key.endsWith('_to')) {
                    prep[key] = value ? dayjs(value) : null
                }
            }
            providerForm.setFieldsValue(prep)
        }
    }, [selectedProvider])

    useEffect(() => {
        if (createdCorrectionId) {
            navigate(`/corrections/edit/${createdCorrectionId}`)
        }
    }, [createdCorrectionId]);

    const tabs = [
        {
            key: 'main',
            label: t('Основная информация'),
            children: <ProviderMainView form={providerForm} isView/>
        },
        {
            key: 'provServ',
            label: t('Услуги и тарифы'),
            disabled: selectedProvider?.is_beneficiary || !permitted(
                ['provider_service::change', 'provider_service::view']
                , perms),
            children: <ProvServView/>
        },
        {
            key: 'provServAcc',
            disabled: selectedProvider?.is_beneficiary,
            label: t('ЛС'),
            children: <ProvServAccForm isView provider_id={selectedProvider?.id}/>
        },
        {
            key: 'provKassa',
            disabled: selectedProvider?.is_beneficiary,
            label: t('Кассы'),
            children: <ProviderKassaForm id={selectedProvider?.id} isView={true}/>
        },
        {
            key: 'provServReo',
            disabled: selectedProvider?.is_beneficiary || !permitted(['real_estate_object_provider_service::change'], perms),
            label: <HomeOutlined />,
            children: <ProvServREOForm isView/>
        },
    ]

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox offsetTop={24} style={{zIndex: 11}}>
            <DefaultTabBar
                {...props}
                style={{
                    background: '#f5f5f5',
                    borderBottom: '1px solid #ffffff',
                    padding: '0 32px',
                    margin: '0 -32px 8px',
                }}
            />
        </StickyBox>
    )

    return <>
        <ProviderViewGate id={+id}/>
        {loading ? <Skeleton /> : <Tabs
            defaultActiveKey="main" items={tabs} type={'card'} renderTabBar={renderTabBar}
        />}
        <Divider />
        <Row>
            <Space>
                <Button onClick={() => {
                    providerForm.resetFields()
                    navigate('/dictionaries/providers')
                }}
                >
                    {t('К списку')}
                </Button>
                <Button onClick={() => navigate(`/dictionaries/providers/edit/${id}`)}
                        type={'primary'}
                >
                    {t('Редактировать')}
                </Button>
            </Space>
        </Row>
    </>
}
