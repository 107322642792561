import {createStore} from 'effector';


export const $realEstateObjectsList = createStore({data: [], count: 0})

export const $selectedRealEstateObjects = createStore(null)

export const $editRealEstateObjectsStatus = createStore(false)

export const $createdRealEstateObjectsId = createStore(null)
export const $afterCreateAction = createStore(null)
export const $accontsList = createStore({accounts: [], count: 0})

export const $massReoFormData = createStore(null)
export const $generatedReos = createStore([])
export const $massCreateStatus = createStore(false)

export const $onCreateList = createStore({data: [], count: 0})
