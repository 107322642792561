import { $WsSt,} from './stores.js';
import {sendMsgEv, WsOpen} from './events.js';
import {sample} from 'effector';
import {sendMsgFx} from './effects.js';


$WsSt.on(WsOpen, (_, open) => open)

sample({
    clock: sendMsgEv,
    target: sendMsgFx
})






