import {createGate} from 'effector-react';
import {createEvent} from 'effector';

export const LeftTableClickEv = createEvent()
export const RightTableClickEv = createEvent()
export const SwapSectorGate = createGate()

export const selectTownEv = createEvent()
export const selectDistrictEv = createEvent()
export const selectStreetEv = createEvent()
export const getAddressEv = createEvent()
export const selectAddressEv = createEvent()
export const setPaginationEv = createEvent()
export const onSwapEv = createEvent()

export const SwapSelectedSectorsEv = createEvent()
